import React from "react";
import styled from "styled-components";
import { AlarmStatusIndicator } from "./status/AlarmStatusIndicator";
import { AltitudeFilterStatusIndicator } from "./status/AltitudeFilterStatusIndicator";
import { ClassificationFilterStatusIndicator } from "./status/ClassificationFilterStatusIndicator";
import { RainStatusIndicator } from "./status/RainStatusIndicator";
import { DataSimulationIndicator } from "./status/DataSimulationIndicator";
import { TimeSyncStatusIndicator } from "./status/TimeSyncStatusIndicator";

const IconGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: ${({ theme }) => theme.spacing.x2};
    margin-top: ${({ theme }) => theme.spacing.x2};
`;

export const StatusView = (): JSX.Element => {
    // Render

    return (
        <IconGroup>
            <TimeSyncStatusIndicator />
            <DataSimulationIndicator />
            <RainStatusIndicator />
            <AltitudeFilterStatusIndicator />
            <ClassificationFilterStatusIndicator />
            <AlarmStatusIndicator />
        </IconGroup>
    );
};
