import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { InputField } from "./InputField";

const Columns = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.x2};
    align-items: center;

    ${InputField} {
        min-width: 70px;
    }
`;

const Slider = styled.input`
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */

    :focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    /* Styling the thumb */
    ::-webkit-slider-thumb {
        margin-top: -4px;
        height: ${({ theme }) => theme.spacing.x4};
        width: ${({ theme }) => theme.spacing.x4};
        background: ${({ theme }) => theme.colors.status.info};
        border-radius: ${({ theme }) => theme.spacing.x3};
        cursor: pointer;
        -webkit-appearance: none;
    }
    :focus::-webkit-slider-thumb {
        background: ${({ theme }) => theme.colors.status.infoActive};
    }
    ::-moz-range-thumb {
        height: ${({ theme }) => theme.spacing.x4};
        width: ${({ theme }) => theme.spacing.x4};
        background: ${({ theme }) => theme.colors.status.info};
        border-radius: ${({ theme }) => theme.spacing.x3};
        cursor: pointer;
    }
    :focus::-moz-range-thumb {
        background: ${({ theme }) => theme.colors.status.infoActive};
    }
    ::-ms-thumb {
        height: ${({ theme }) => theme.spacing.x4};
        width: ${({ theme }) => theme.spacing.x4};
        background: ${({ theme }) => theme.colors.status.info};
        border-radius: ${({ theme }) => theme.spacing.x3};
        cursor: pointer;
    }
    :focus::-ms-thumb {
        background: ${({ theme }) => theme.colors.status.infoActive};
    }

    /* Styling the track */
    ::-webkit-slider-runnable-track {
        width: 100%;
        height: ${({ theme }) => theme.spacing.x2};
        background: ${({ theme }) => theme.colors.interactive.input};
        border-radius: 2px;
        cursor: pointer;
    }
    :focus::-webkit-slider-runnable-track {
        background: ${({ theme }) => theme.colors.interactive.input90};
    }
    ::-moz-range-track {
        width: 100%;
        height: ${({ theme }) => theme.spacing.x2};
        background: ${({ theme }) => theme.colors.interactive.input};
        border-radius: 2px;
        cursor: pointer;
    }
    :focus::-moz-range-track {
        background: ${({ theme }) => theme.colors.interactive.input90};
    }
    ::-ms-track {
        width: 100%;
        height: ${({ theme }) => theme.spacing.x2};
        background: transparent;
        border-color: transparent;
        border-width: ${({ theme }) => theme.spacing.x2} 0;
        color: transparent;
        cursor: pointer;
    }
    ::-ms-fill-lower {
        background: ${({ theme }) => theme.colors.interactive.input};
        border-radius: 2px;
    }
    ::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.interactive.input90};
        border-radius: 2px;
    }
    :focus {
        ::-ms-fill-lower {
            background: ${({ theme }) => theme.colors.interactive.input90};
        }
        ::-ms-fill-upper {
            background: ${({ theme }) => theme.colors.interactive.input90};
        }
    }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    showTextInput?: boolean;
}

export const FormSlider = (props: Props): JSX.Element => {
    props = {
        max: 100,
        min: 0,
        step: 10,
        ...props,
    };

    const SliderComponent = <Slider {...props} type="range" />;
    if (props.showTextInput) {
        return (
            <Columns>
                {SliderComponent}
                <InputField
                    min={props.min}
                    max={props.max}
                    onChange={props.onChange}
                    step={props.step}
                    type="number"
                    value={props.value}
                />
            </Columns>
        );
    } else {
        return SliderComponent;
    }
};
