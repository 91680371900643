export const S = {
    x1: "4px",
    x2: "8px",
    x3: "12px",
    x4: "16px",
    x5: "20px",
    x6: "24px",
    x7: "28px",
    x8: "32px",
    x9: "36px",
    x10: "40px",
    x20: "80px",
    x32: "128px",
};
export const OVERLAY_SPACING = S.x5;
export const INPUT_HEIGHT = "30px";
export const PANEL_HEADER_HEIGHT = "92px";
export const LINE_HEIGHT_LARGE = S.x6;
export const LINE_HEIGHT_SMALL = "14px";
