import React from "react";
import { getColorAndOpacity } from "../../../utils/ColorUtils";
import { Classification, ClassificationIcon, EmitterCategory } from "../../../domain/model";
import {
    generateTriangleIcon,
    generateCircleIcon,
    generateSquareIcon,
    generateAircraftIcon,
    generateDroneIcon,
    generateFixedWingIcon,
    generateQuestionMarkIcon,
    generateHelicopterIcon,
    getADSBFlightIconProps,
    getComponentFromProps,
} from "../../legend/LegendIcon";
import { TrackSymbolGenerator } from "./TrackSymbolGenerator";
import { Colors, CustomColors } from "../../appearance/Colors";
import { encodeSvg } from "../../../utils/MapUtils";
import { generateSelectionSymbol } from "./SelectionSymbol";

export class CommonTrackSymbolGenerator implements TrackSymbolGenerator {
    public generateSymbolBase64(
        classificationOrEmitterCategory: Classification | EmitterCategory,
        scale: float,
    ): string {
        if (classificationOrEmitterCategory instanceof Classification) {
            return this.generateSymbolBase64FromClassification(classificationOrEmitterCategory, scale);
        } else {
            return this.generateSymbolBase64FromEmitterCategory(classificationOrEmitterCategory, scale);
        }
    }

    public generateSelectionSymbolBase64(classificationOrEmitterCategory: Classification | EmitterCategory): string {
        const color =
            classificationOrEmitterCategory instanceof Classification
                ? getColorAndOpacity(classificationOrEmitterCategory.defaultColor)[0]
                : Colors.secondary.blue;
        return generateSelectionSymbol({
            color: color,
            scale: 1,
        });
    }

    private generateSymbolBase64FromClassification(classification: Classification, scale: float): string {
        const color = getColorAndOpacity(classification.defaultColor)[0];
        let element: React.ReactElement;
        switch (classification.icon) {
            case ClassificationIcon.CIRCLE:
                element = generateCircleIcon({
                    isCircleIconProps: true,
                    innerCircleColor: color,
                    outerCircleColor: color,
                    scale: scale,
                });
                break;
            case ClassificationIcon.RING:
                element = generateCircleIcon({
                    isCircleIconProps: true,
                    innerCircleColor: CustomColors.trackDefaults.birdInnerCircles,
                    outerCircleColor: color,
                    scale: scale,
                });
                break;
            case ClassificationIcon.SQUARE:
                element = generateSquareIcon({
                    isSquareIconProps: true,
                    color: color,
                    scale: scale,
                });
                break;
            case ClassificationIcon.TRIANGLE:
                element = generateTriangleIcon({
                    isOtherIconProps: true,
                    color: classification.defaultColor,
                    scale: scale,
                });
                break;
            case ClassificationIcon.AIRCRAFT:
                element = generateAircraftIcon({
                    isAircraftIconProps: true,
                    color: color,
                    chevronColor: CustomColors.trackDefaults.aircraftChevron,
                    scale: scale,
                });
                break;
            case ClassificationIcon.HELICOPTER:
                element = generateHelicopterIcon({
                    isHelicopterIconProps: true,
                    color: color,
                    scale: scale,
                });
                break;
            case ClassificationIcon.DRONE:
                element = generateDroneIcon({
                    isDroneIconProps: true,
                    color: color,
                    scale: scale,
                });
                break;
            case ClassificationIcon.FIXED_WING:
                element = generateFixedWingIcon({
                    isFixedWingIconProps: true,
                    color: color,
                    scale: scale,
                });
                break;
            case ClassificationIcon.QUESTION_MARK:
            default:
                element = generateQuestionMarkIcon({
                    isQuestionMarkIconProps: true,
                    color: getColorAndOpacity(classification.defaultColor)[0],
                    scale: scale,
                });
                break;
        }
        return encodeSvg(element);
    }

    private generateSymbolBase64FromEmitterCategory(emitterCategory: EmitterCategory, scale: float): string {
        const props = getADSBFlightIconProps(emitterCategory, scale);
        const element = getComponentFromProps(props, false);
        return encodeSvg(element);
    }
}
