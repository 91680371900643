import React from "react";
import styled from "styled-components";
import { OldColors } from "./Colors";

const Group = styled.div`
    display: flex;
    align-items: center;
`;

const InputPairConnectionLine = styled.div`
    width: 30px;
    height: 1px;
    margin-top: 10px;
    background-color: ${OldColors.primaryTint};
`;

const InputContainer = styled.span`
    display: flex;
    flex-direction: column;
    padding-bottom: 14px;
    span {
        font-size: 12px;
        line-height: 1.83;
        color: ${OldColors.primaryTint};
        padding-bottom: 4px;
    }
`;

interface InputGroupProps {}

/**
 * @deprecated Use `forms/FormControl` instead.
 */
export const InputGroup = (props: React.PropsWithChildren<InputGroupProps>): JSX.Element => {
    const children = (Array.isArray(props.children) ? props.children : [props.children])
        .map((child, index, array) => {
            if (index === array.length - 1) {
                return child;
            }
            return [child, <InputPairConnectionLine key={index} />];
        })
        .flat();
    return <Group>{children}</Group>;
};

interface InputGroupItemProps {
    label?: string;
}

export const InputGroupItem = ({ label, children }: React.PropsWithChildren<InputGroupItemProps>): JSX.Element => {
    return (
        <InputContainer>
            {label && <span>{label}</span>}
            {children}
        </InputContainer>
    );
};
