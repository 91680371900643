export interface FunnelViewSectorRates {
    top?: number;
    bottomMiddleLeft: number;
    bottomMiddleRight: number;
    bottomLeft: number;
    bottomRight: number;
}

export function getFunnelViewSectorRatesFromArray(rates: number[]): FunnelViewSectorRates {
    return {
        top: rates[4],
        bottomLeft: rates[0],
        bottomMiddleLeft: rates[1],
        bottomMiddleRight: rates[2],
        bottomRight: rates[3],
    };
}
