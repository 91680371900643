import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { TracksHistogram } from "../../../../domain/model";
import { TracksHistogramRequest as TracksHistogramRequestProto } from "../../../../domain/model/proto/generated/tracklist3_pb";
import { TracksHistogramRepository } from "../../../../domain/repositories/TracksHistogramRepository";

export class BirdViewerTracksHistogramRepository implements TracksHistogramRepository {
    public constructor(private readonly api: BirdViewerAPI) {}

    // Public functions

    public getTracksHistogram(
        startTimestamp: long,
        endTimestamp: long,
        interval: long,
    ): Rx.Observable<TracksHistogram> {
        const request = new TracksHistogramRequestProto();
        request.setStarttimestamp(Math.round(startTimestamp));
        request.setEndtimestamp(Math.round(endTimestamp));
        request.setInterval(Math.round(interval));
        return this.api
            .getTracksHistogram(request)
            .pipe(RxOperators.map((h) => ({ counts: h.getCountList(), timestamps: h.getTimestampList() })));
    }
}
