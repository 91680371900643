import React from "react";
import styled from "styled-components";
import { OldColors } from "../../../appearance/Colors";

const AttributeGroupTitle = styled.div`
    color: ${({ theme }) => theme.colors.text.text};
    font-weight: 700;
    margin-bottom: 8px;
`;

const AttributeListItemInternal = styled.li`
    font-size: 14px;
    color: ${OldColors.textSecondaryLighter};
    margin: 4px 0 4px 0;
`;

export const AttributeList = styled.ul`
    list-style: none;
    margin: 0 0 0 0;
    padding: 0;

    > ul {
        margin: 0 0 0 16px;
    }
`;

export const AttributeListSpace = styled.li`
    height: 16px;
`;

interface AttributeListItemProps {
    condition?: boolean;
    label: string | JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: Function | string | number | JSX.Element;
    boldLabel?: boolean;
}

export const AttributeListItem = ({ condition, label, value, boldLabel }: AttributeListItemProps): JSX.Element => {
    if (condition === false) {
        return <></>;
    }

    const calculatedValue = typeof value === "function" ? value() : value;
    const v = calculatedValue ? `: ${calculatedValue}` : "";

    return (
        <AttributeListItemInternal>
            {boldLabel ? <AttributeGroupTitle>{label}</AttributeGroupTitle> : label}
            {v}
        </AttributeListItemInternal>
    );
};

const LabelWithIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > div:first-child {
        flex: 1;
    }
`;

export const AttributeListItemWithIcon = ({
    condition,
    label,
    boldLabel,
    icon,
}: Omit<AttributeListItemProps, "value"> & { icon: JSX.Element }): JSX.Element => {
    return (
        <AttributeListItem
            condition={condition}
            label={
                <LabelWithIconContainer>
                    <div>{label}</div>
                    <div>{icon}</div>
                </LabelWithIconContainer>
            }
            boldLabel={boldLabel}
        />
    );
};
