import React from "react";
import { encodeSvg } from "../../../utils/MapUtils";

export function generateSelectionSymbol(props: { color: string; scale: number }): string {
    const svg: React.ReactElement = (
        <svg
            xmlns={"http://www.w3.org/2000/svg"}
            width={49 * props.scale}
            height={49 * props.scale}
            viewBox={"0 0 49 49"}
        >
            <g fill={"none"} stroke={props.color} strokeWidth={"4"} strokeDasharray={"12"}>
                <circle cx={"24.5"} cy={"24.5"} r={"24.5"} stroke={"none"} />
                <circle cx={"24.5"} cy={"24.5"} r={"22.5"} fill={"none"} />
            </g>
        </svg>
    );
    return encodeSvg(svg);
}
