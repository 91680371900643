import React from "react";
import styled, { useTheme } from "styled-components";
import { BASE_TEXT_STYLE } from "./theme/GlobalStyles";
import { Icon, IconColors, IconType } from "./Icon";

export const TabBar = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.x2};
    margin-bottom: ${({ theme }) => theme.spacing.x5};
    width: 100%;
    border-bottom: 3px solid ${({ theme }) => theme.colors.secondary.blue};
`;

const TabStyle = styled.div<{ active: boolean; iconColor: string }>`
    ${BASE_TEXT_STYLE};
    display: flex;
    flex-direction: column;
    ${({ active }) => active && "flex-grow: 1;"}
    justify-content: flex-start;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.x2} 0;
    min-width: 54px;
    text-align: center;
    background-color: ${({ active, theme }) => (active ? theme.colors.secondary.blue : "transparent")};
    ${({ active }) => !active && "cursor: pointer;"}
    border-top-left-radius: ${({ theme }) => theme.spacing.x2};
    border-top-right-radius: ${({ theme }) => theme.spacing.x2};
    transition: all ${({ theme }) => theme.transitionDuration} ease-out;
    color: ${({ active, iconColor, theme }) => (active ? theme.colors.status.neutral : iconColor)};
    font-weight: 500;

    label {
        display: inline-block;
        max-width: 50%;
        text-align: center;
        font-size: 10px;
        cursor: inherit;
        transition: all ${({ theme }) => theme.transitionDuration} ease-out;
    }

    :hover {
        background-color: ${({ active, theme }) => !active && theme.colors.interactive.textbuttonHover};
        color: ${({ theme }) => theme.colors.text.text};
    }
`;

interface Props {
    active: boolean;
    iconColor?: IconColors;
    iconType: IconType;
    label: string;
    onClick: () => void;
}

export const Tab = ({ active, iconColor = "neutral", iconType, label, onClick }: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <TabStyle active={active} iconColor={theme.colors.status[iconColor]} onClick={onClick}>
            <Icon iconType={iconType} large={true} />
            <label>{label}</label>
        </TabStyle>
    );
};
