export enum UserPermission {
    CanManageUsers,
    CanManageRadar,
    CanManageOverlays,
    CanEditUsers,
}

export enum UserManagementPermission {
    None = "None",
    ReadOnly = "ReadOnly",
    ReadWrite = "ReadWrite",
}
