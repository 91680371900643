import React from "react";
import styled from "styled-components";
import { StatusColorsType } from "./Colors";
import ReactTooltip from "react-tooltip";
import { PropsWithTooltip } from "./Tooltip";
import { ReactComponent as IconAltitudeFilter } from "../../res/images/icons/altitude_filter.svg";
import { ReactComponent as IconBellDisabled } from "../../res/images/icons/bell_disabled.svg";
import { ReactComponent as IconBird } from "../../res/images/icons/bird.svg";
import { ReactComponent as IconCar } from "../../res/images/icons/car.svg";
import { ReactComponent as IconLineGraph } from "../../res/images/icons/linegraph.svg";
import { ReactComponent as IconCheck } from "../../res/images/icons/check.svg";
import { ReactComponent as IconChevronDown } from "../../res/images/icons/chevron_down.svg";
import { ReactComponent as IconChevronLeft } from "../../res/images/icons/chevron_left.svg";
import { ReactComponent as IconChevronLeftDouble } from "../../res/images/icons/chevron_left_double.svg";
import { ReactComponent as IconChevronRight } from "../../res/images/icons/chevron_right.svg";
import { ReactComponent as IconChevronRightDouble } from "../../res/images/icons/chevron_right_double.svg";
import { ReactComponent as IconChevronUp } from "../../res/images/icons/chevron_up.svg";
import { ReactComponent as IconClassificationBird } from "../../res/images/icons/classification_bird.svg";
import { ReactComponent as IconClassificationUnknown } from "../../res/images/icons/classification_unknown.svg";
import { ReactComponent as IconClassificationVehicle } from "../../res/images/icons/classification_vehicle.svg";
import { ReactComponent as IconClock } from "../../res/images/icons/clock.svg";
import { ReactComponent as IconCog } from "../../res/images/icons/cog.svg";
import { ReactComponent as IconCross } from "../../res/images/icons/cross.svg";
import { ReactComponent as IconDataSimulation } from "../../res/images/icons/data_simulation.svg";
import { ReactComponent as IconDrone } from "../../res/images/icons/drone.svg";
import { ReactComponent as IconExclamationTriangle } from "../../res/images/icons/exclamation_triangle.svg";
import { ReactComponent as IconEye } from "../../res/images/icons/eye.svg";
import { ReactComponent as IconEyeDisabled } from "../../res/images/icons/eye_disabled.svg";
import { ReactComponent as IconFixedWing } from "../../res/images/icons/fixed_wing.svg";
import { ReactComponent as IconInfo } from "../../res/images/icons/info.svg";
import { ReactComponent as IconList } from "../../res/images/icons/list.svg";
import { ReactComponent as IconListRadar } from "../../res/images/icons/list_radar.svg";
import { ReactComponent as IconPause } from "../../res/images/icons/pause.svg";
import { ReactComponent as IconPencil } from "../../res/images/icons/pencil.svg";
import { ReactComponent as IconPlay } from "../../res/images/icons/play.svg";
import { ReactComponent as IconOverlays } from "../../res/images/icons/overlays.svg";
import { ReactComponent as IconQuestion } from "../../res/images/icons/question.svg";
import { ReactComponent as IconRadar } from "../../res/images/icons/radar.svg";
import { ReactComponent as IconRainCloud } from "../../res/images/icons/rain_cloud.svg";
import { ReactComponent as IconReplay } from "../../res/images/icons/replay.svg";
import { ReactComponent as IconScarecrow } from "../../res/images/icons/scarecrow.svg";
import { ReactComponent as IconShapeCircle } from "../../res/images/icons/shape_circle.svg";
import { ReactComponent as IconShapePolygon } from "../../res/images/icons/shape_polygon.svg";
import { ReactComponent as IconShapePolyline } from "../../res/images/icons/shape_polyline.svg";
import { ReactComponent as IconShapes } from "../../res/images/icons/shapes.svg";
import { ReactComponent as IconTrack } from "../../res/images/icons/track.svg";
import { ReactComponent as IconCopy } from "../../res/images/icons/copy.svg";
import { ReactComponent as IconReset } from "../../res/images/ic_reset.svg";
import { LINE_HEIGHT_LARGE, LINE_HEIGHT_SMALL } from "./theme/Spacing";

const ICONS = {
    ALTITUDE_FILTER: <IconAltitudeFilter />,
    BELL_DISABLED: <IconBellDisabled />,
    BIRD: <IconBird />,
    CAR: <IconCar />,
    LINE_GRAPH: <IconLineGraph />,
    CHECK: <IconCheck />,
    CHEVRON_DOWN: <IconChevronDown />,
    CHEVRON_LEFT: <IconChevronLeft />,
    CHEVRON_LEFT_DOUBLE: <IconChevronLeftDouble />,
    CHEVRON_RIGHT: <IconChevronRight />,
    CHEVRON_RIGHT_DOUBLE: <IconChevronRightDouble />,
    CHEVRON_UP: <IconChevronUp />,
    CLASSIFICATION_BIRD: <IconClassificationBird />,
    CLASSIFICATION_DRONE: <IconDrone />,
    CLASSIFICATION_FIXED_WING: <IconFixedWing />,
    CLASSIFICATION_UNKNOWN: <IconClassificationUnknown />,
    CLASSIFICATION_VEHICLE: <IconClassificationVehicle />,
    CLOCK: <IconClock />,
    COG: <IconCog />,
    CROSS: <IconCross />,
    DATA_SIMULATION: <IconDataSimulation />,
    EXCLAMATION_TRIANGLE: <IconExclamationTriangle />,
    EYE: <IconEye />,
    EYE_DISABLED: <IconEyeDisabled />,
    INFO: <IconInfo />,
    LIST: <IconList />,
    LIST_RADAR: <IconListRadar />,
    PAUSE: <IconPause />,
    PENCIL: <IconPencil />,
    PLAY: <IconPlay />,
    OVERLAYS: <IconOverlays />,
    QUESTION: <IconQuestion />,
    RADAR: <IconRadar />,
    RAIN_CLOUD: <IconRainCloud />,
    REPLAY: <IconReplay />,
    SCARECROW: <IconScarecrow />,
    SHAPE_CIRCLE: <IconShapeCircle />,
    SHAPE_POLYGON: <IconShapePolygon />,
    SHAPE_POLYLINE: <IconShapePolyline />,
    SHAPES: <IconShapes />,
    TRACK: <IconTrack />,
    COPY: <IconCopy />,
    RESET: <IconReset />,
};

export type IconType = keyof typeof ICONS | (() => JSX.Element);
export type IconColors = StatusColorsType;

export const IconStyle = styled.span<{ color?: IconColors; size: string; opacity?: number }>`
    height: ${({ size }) => size};
    text-align: center;
    line-height: ${({ size }) => size};
    opacity: ${({ opacity }) => (opacity !== undefined ? opacity : 1)};

    svg {
        width: ${({ size }) => size};
        height: ${({ size }) => size};
        fill: ${({ theme, color }) => (color ? theme.colors.status[color] : "currentColor")};
        transition: fill ${({ theme }) => theme.transitionDuration} ease-out;
    }
`;

interface Props {
    color?: IconColors;
    iconType: IconType;
    large?: boolean;
    opacity?: number;
}

export const Icon = ({
    color,
    iconType,
    large,
    tooltip,
    tooltipPosition,
    opacity,
}: PropsWithTooltip<Props>): JSX.Element => {
    // Properties

    const tooltipId = "image-tooltip-" + String(Math.random());

    // Render

    return (
        <IconStyle
            color={color}
            data-for={tooltipId}
            data-tip={tooltip}
            data-iscapture="true"
            size={large ? LINE_HEIGHT_LARGE : LINE_HEIGHT_SMALL}
            opacity={opacity}
        >
            {typeof iconType == "function" ? iconType() : ICONS[iconType]}
            {tooltip && <ReactTooltip id={tooltipId} effect={"solid"} place={tooltipPosition ?? "bottom"} />}
        </IconStyle>
    );
};
