import { enumValueOrDefault } from "../../utils/EnumUtils";

export enum TimeDisplayMode {
    UTC = "utc",
    LOCAL = "local",
}

export const DEFAULT_TIME_DISPLAY_MODE = TimeDisplayMode.UTC;

export const getTimeDisplayMode = (value: string | null): TimeDisplayMode =>
    enumValueOrDefault(TimeDisplayMode, value, DEFAULT_TIME_DISPLAY_MODE);
