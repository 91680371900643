import { Chart } from "chart.js";
import React from "react";
import { fromUnixTime, subMinutes } from "date-fns";
import styled, { withTheme } from "styled-components";
import Texts from "../appearance/Texts";
import { Runway, RunwayCrossingData } from "../../domain/model";
import DI from "../../di/DI";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { TYPES } from "../../di/Types";
import { RunwayCrossingChartViewModel } from "./RunwayCrossingChartViewModel";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { CanvasContainer } from "../appearance/CanvasContainer";
import * as Rx from "rxjs";
import { t } from "i18next";
import { Theme } from "../appearance/theme/Theme";

const Root = styled.div`
    position: relative;
    background: ${({ theme }) => theme.colors.backgrounds.panel};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 15px 24px 10px 36px;
`;

const ChartAndTitleContainer = styled.div`
    width: 100%;
    flex-direction: column;
    text-align: center;
`;

const CurrentValueContaienr = styled.div<{ reachedThreshold: boolean }>`
    color: ${({ theme, reachedThreshold }) => (reachedThreshold ? theme.colors.secondary.red : theme.colors.text.text)};
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;

    span {
        :nth-child(1) {
            font-weight: 100;
            font-size: 48px;
        }
    }
`;

// We want to show 2 hours of data
const TOTAL_MINUTES = 120;

interface Props {
    runway: Runway;
    theme: Theme;
}

interface State {
    threshold: number;
    currentValue: number;
    values: RunwayCrossingData[];
}

const DATASET_CROSSING_AXIS_X = "crossing_x";
const DATASET_THRESHOLD_AXIS_X = "threshold_x";

class RunwayCrossingsChartComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: RunwayCrossingChartViewModel = DI.get(TYPES.RunwayCrossingChartViewModel);
    private dateFormatter = DI.get<DateFormatter>(TYPES.DateFormatter);
    private canvasElement = React.createRef<HTMLCanvasElement>();
    private chart: Chart | null = null;

    // Lifecycle

    public constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            threshold: 0,
            currentValue: 0,
            values: [],
        };
    }

    public componentDidMount(): void {
        // Setup the chart
        const context2D = this.canvasElement.current!.getContext("2d")!;
        this.chart = new Chart(context2D, this.getChartConfiguration());

        // Setup subscriptions
        this.collectSubscriptions(
            Rx.combineLatest([this.viewModel.runwayCrossingHistory, this.viewModel.timeDisplayMode]).subscribe(
                ([data]) => {
                    const items = data.get(this.props.runway.id);
                    if (items == undefined) {
                        this.setValues([]);
                    } else {
                        this.setValues(items);
                    }
                },
            ),
            this.viewModel
                .observeCurrentCount(this.props.runway.id)
                .subscribe((currentValue) => this.setState({ currentValue })),
            this.viewModel.runwayCrossingThreshold.subscribe((threshold) => this.setThreshold(threshold)),
        );
    }

    public componentDidUpdate(): void {
        this.getThresholdDataset().data = Array(TOTAL_MINUTES).fill(this.state.threshold);
        this.getCrossingDataset().data = this.state.values.map((v) => v && v.count);
        this.chart!.update();
    }

    public render(): React.ReactNode {
        return (
            <Root>
                <ChartAndTitleContainer>
                    <Texts.BottomSheetTitles>{t("funnelViewRunwayCrossings.runwayCrossings")}</Texts.BottomSheetTitles>
                    <CanvasContainer>
                        <canvas ref={this.canvasElement} />
                    </CanvasContainer>
                </ChartAndTitleContainer>
                <CurrentValueContaienr reachedThreshold={this.isAboveThreshold()}>
                    <span>{this.state.currentValue}</span>
                </CurrentValueContaienr>
            </Root>
        );
    }

    // Private functions

    private setThreshold(threshold: number): void {
        if (!this.chart) {
            return;
        }

        this.setState({ threshold: threshold });
    }

    private setValues(crossingCount: RunwayCrossingData[]): void {
        if (!this.chart) {
            return;
        }

        // Last item is the most recent data
        const currentValue = crossingCount[crossingCount.length - 1];

        // create values
        const values: RunwayCrossingData[] = Array(TOTAL_MINUTES).fill(null);
        if (crossingCount.length > TOTAL_MINUTES) {
            crossingCount = crossingCount.slice(
                Math.max(0, crossingCount.length - TOTAL_MINUTES),
                crossingCount.length,
            );
        }
        const startIndex = Math.max(TOTAL_MINUTES - crossingCount.length, 0);
        crossingCount.forEach((value, index) => {
            const finalIndex = startIndex + index;
            values[finalIndex] = value;
        });

        // Set data

        const lastUpdate: Date = fromUnixTime(currentValue.timestamp / 1000);
        const labels = values.map((_, i) =>
            this.dateFormatter.formatTime(subMinutes(lastUpdate, values.length - i - 1)),
        );
        this.chart.data!.labels = labels;
        this.setState({ values: values });
    }

    private getCrossingDataset(): Chart.ChartDataSets {
        return this.chart!.data.datasets!.find((d) => d.xAxisID === DATASET_CROSSING_AXIS_X)!;
    }

    private getThresholdDataset(): Chart.ChartDataSets {
        return this.chart!.data.datasets!.find((d) => d.xAxisID === DATASET_THRESHOLD_AXIS_X)!;
    }

    private getChartConfiguration(): Chart.ChartConfiguration {
        return {
            type: "line",
            data: {
                labels: [],
                datasets: [
                    {
                        xAxisID: DATASET_CROSSING_AXIS_X,
                        order: 2,
                        data: [],
                        borderColor: this.props.theme.colors.secondary.blue,
                        borderWidth: 2,
                        fill: false,
                        pointHitRadius: 0,
                        pointRadius: 0,
                        lineTension: 0,
                    },
                    {
                        xAxisID: DATASET_THRESHOLD_AXIS_X,
                        order: 1,
                        data: [],
                        borderColor: this.props.theme.colors.secondary.red,
                        borderWidth: 2,
                        borderDash: [5, 5],
                        fill: false,
                        pointHitRadius: 0,
                        pointRadius: 0,
                        lineTension: 0,
                    },
                ],
            },
            plugins: [this.createThreholdLabelPlugin()],
            options: {
                maintainAspectRatio: false,
                animation: {
                    duration: 0,
                },
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            id: DATASET_CROSSING_AXIS_X,
                            ticks: {
                                callback: function (value, index) {
                                    if (index !== 0 && (index + 1) % (TOTAL_MINUTES / 4) !== 0) {
                                        return null;
                                    }
                                    return value;
                                },
                                maxRotation: 0,
                                minRotation: 0,
                                autoSkip: false,
                                fontColor: this.props.theme.colors.text.text,
                                fontFamily: "Roboto",
                                fontSize: 16,
                            },
                            gridLines: {
                                color: this.props.theme.colors.text.text400,
                                zeroLineColor: this.props.theme.colors.text.text200,
                            },
                        },
                        {
                            id: DATASET_THRESHOLD_AXIS_X,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                                maxTicksLimit: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                maxTicksLimit: 5,
                                fontColor: this.props.theme.colors.text.text200,
                            },
                            gridLines: {
                                color: this.props.theme.colors.text.text400,
                                zeroLineColor: this.props.theme.colors.text.text200,
                            },
                        },
                    ],
                },
            },
        };
    }

    private createThreholdLabelPlugin(): Chart.PluginServiceRegistrationOptions {
        return {
            /**
             * Draws the threshold number above the line on the far left side of the chart
             */
            afterDraw: (chart: Chart) => {
                const meta = chart.getDatasetMeta(1).data;
                const context = chart.ctx;
                if (context == null || meta.length === 0) {
                    return;
                }
                const point = meta[0];
                const offsetX = 10;
                const offsetY = -10;
                const px = point._model.x + offsetX;
                const py = Math.max(18, point._model.y + offsetY);
                context.save();
                // Make sure when threshold line and text overlap, it's still readable
                context.globalCompositeOperation = "difference";
                context.font = "normal 16px 'Roboto'";
                context.fillStyle = this.props.theme.colors.secondary.red;
                context.fillText(this.state.threshold.toString(), px, py);
                context.restore();
            },
        };
    }

    private isAboveThreshold(): boolean {
        return this.state.currentValue >= this.state.threshold;
    }
}

export const RunwayCrossingsChart = withTheme(RunwayCrossingsChartComponent);
