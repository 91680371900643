import React from "react";
import styled from "styled-components";
import DI from "../../di/DI";
import * as Rx from "rxjs";
import { TYPES } from "../../di/Types";
import { OldColors } from "../appearance/Colors";
import { ToggleSwitch } from "../appearance/ToggleSwitch";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { AltitudeFilterSettingsViewModel } from "./AltitudeFilterSettingsViewModel";
import { t } from "i18next";
import { Panel } from "../appearance/panels/Panel";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { SectionHeader } from "../appearance/SectionHeader";
import { AltitudeFilterSection } from "./AltitudeFilterSection";
import { Collapse } from "react-collapse";

const ClassificationFilterItemsContainer = styled.div`
    color: ${OldColors.disabledColor};
    margin-top: 4px;
`;

const FilterItemsContainer = styled.div`
    color: ${OldColors.disabledColor};
    font-size: 14px;
`;

const IconContainer = styled.span`
    width: 22px;
    height: 22px;
`;

const FilterItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-top: 14px;

    label {
        font-size: 14px;
    }
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

export interface FilterItemProps {
    icon?: React.ReactElement;
    tooltip?: string;
    title: string;
    observableValue: Rx.Observable<boolean>;
    onChange: (isChecked: boolean) => void;
}

export const FilterItem = ({ icon, title, tooltip, observableValue, onChange }: FilterItemProps): JSX.Element => {
    return (
        <FilterItemContainer title={tooltip}>
            {icon && <IconContainer>{icon}</IconContainer>}
            <label>{title}</label>
            <Spacer />
            <ToggleSwitch observableValue={observableValue} onChange={onChange} />
        </FilterItemContainer>
    );
};

interface State {
    filterItems: FilterItemProps[];
    filterSections: AltitudeFilterSection[];
    allSectionsFiltered: Record<string, boolean>;
}

interface Props {
    onClose: () => void;
}

export class AltitudeFilterSettings extends BaseSubscriptionHandlerComponent<Props, State> {
    private viewModel: AltitudeFilterSettingsViewModel = DI.get(TYPES.AltitudeFilterSettingsViewModel);

    public constructor(props: Props) {
        super(props);
        this.state = {
            filterItems: [],
            filterSections: [],
            allSectionsFiltered: {},
        };
    }

    public componentDidMount(): void {
        this.collectSubscriptions(
            this.viewModel.classificationSwitchItems.subscribe({
                next: (filterItems) => this.setState({ filterItems }),
            }),
            this.viewModel.filterSections.subscribe({
                next: (filterSections) => this.setState({ filterSections }),
            }),
            this.viewModel.allSectionEntriesFiltered.subscribe({
                next: (allSectionsFiltered) => this.setState({ allSectionsFiltered }),
            }),
        );
    }

    private onSectionExpand(sectionId: string): void {
        const sectionIds = this.state.filterSections.map(({ id }) => id);
        sectionIds.forEach((id) => this.viewModel.setSectionVisibility(id, sectionId === id));
    }

    public render(): React.ReactNode {
        return (
            <Panel small>
                <PanelHeader label={t("altitudeFilter.filterSettings")} onClose={() => this.props.onClose()} />
                <PanelSection>
                    <FilterItemsContainer>
                        <FilterItem
                            title={t("altitudeFilter.showTracksWithoutAltitude")}
                            tooltip={t("altitudeFilter.showTracksWithoutAltitude")}
                            observableValue={this.viewModel.showTracksWithoutAltitudeObservable}
                            onChange={(value) => this.viewModel.onChangeShowTracksWithoutAltitude(value)}
                        />
                    </FilterItemsContainer>
                    <ClassificationFilterItemsContainer>
                        {this.state.filterSections.map((section) => (
                            <React.Fragment key={section.id}>
                                <SectionHeader
                                    onClick={() => this.onSectionExpand(section.id)}
                                    isOpen={section.isOpen}
                                    label={t(section.label)}
                                >
                                    <ToggleSwitch
                                        value={this.state.allSectionsFiltered[section.id]}
                                        onChange={(value) => section.entries.forEach((item) => item.onChange(value))}
                                    />
                                </SectionHeader>
                                <Collapse isOpened={section.isOpen}>
                                    {section.entries.map((itemProps) => (
                                        <FilterItem
                                            key={itemProps.title}
                                            {...itemProps}
                                            tooltip={t("altitudeFilter.applyFilterTo") + " " + itemProps.title}
                                        />
                                    ))}
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </ClassificationFilterItemsContainer>
                </PanelSection>
            </Panel>
        );
    }
}
