import React from "react";
import { Modal, SharedModalProps } from "../appearance/modal/Modal";
import { AboutViewModel } from "./AboutViewModel";
import { TYPES } from "../../di/Types";
import { SelectableText } from "../appearance/SelectableText";
import { ReactComponent as WarningIcon } from "../../res/images/warning.svg";
import styled from "styled-components";
import { t } from "i18next";
import { ModalBodyText } from "../appearance/modal/ModalText";
import { useViewModel } from "../../hooks/useViewModel";
import { useObservable } from "../../hooks";
import { BASE_TEXT_STYLE } from "../appearance/theme/GlobalStyles";

const WarningTitle = styled.div`
    ${BASE_TEXT_STYLE}
    display: flex;
    margin-top: 20px;
    margin-bottom: 4px;
    gap: 6px;
    color: ${({ theme }) => theme.colors.status.warning};
    align-items: center;
`;

const BlueText = styled.text`
    color: ${({ theme }) => theme.colors.secondary.blue};
`;

export const About = ({ onClosed }: SharedModalProps): JSX.Element => {
    // Properties

    const viewModel: AboutViewModel = useViewModel(TYPES.AboutViewModel);
    const versionInfo = useObservable(viewModel.versionInfo, undefined);
    const hasVersionMismatch = useObservable(viewModel.hasVersionMismatch, false);

    // Render

    if (!versionInfo) {
        return <></>;
    }

    return (
        <Modal title={t("about.about")} onClosed={onClosed}>
            <ModalBodyText>
                <SelectableText>
                    {`${t("about.webviewerVersion")}: `}
                    <BlueText>{viewModel.webviewerVersion}</BlueText>
                </SelectableText>
                <br />
                <SelectableText>
                    {`${t("about.radarSoftwareVersion")}: `}
                    <BlueText>{versionInfo.versionTag}</BlueText>
                </SelectableText>
                <br />
                {versionInfo.licenseExpiration && (
                    <SelectableText>
                        {`${t("about.licenseExpiryDate")}: `}
                        <BlueText>{versionInfo.licenseExpiration}</BlueText>
                    </SelectableText>
                )}
                {hasVersionMismatch && (
                    <>
                        <WarningTitle>
                            <WarningIcon /> {t("about.versionMismatchTitle")}
                        </WarningTitle>
                        {t("about.versionMismatchMessage", { viewer: viewModel.appName })}
                    </>
                )}
            </ModalBodyText>
        </Modal>
    );
};
