import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { Colors } from "../Colors";

export const ModalBodyText = styled.span`
    ${BASE_TEXT_STYLE}
    min-height: 1.43em;
    line-height: 1.43;
    letter-spacing: 0.01px;
    color: ${Colors.text.text200};
`;

export const ModalUppercaseText = styled.span`
    ${BASE_TEXT_STYLE}
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
`;

export const ModalBodyTextCenter = styled(ModalBodyText)`
    text-align: center;
    margin-top: 18px;
    max-width: 300px;
`;

export const ModalBodyMessageText = styled(ModalBodyText)`
    margin-top: 14px;
    max-width: 300px;
`;
