import React from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as TracksOutline } from "../../res/images/tracks_outline.svg";

const Container = styled.div`
    height: 100px;
    width: 100%;
    max-width: 800px; // Max width to prevent the loader from being wider than the svg large on large screens (1190.6 / 145.5 = 8.17 * 100px ~ 800px)
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
`;

const slide = keyframes`
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
`;

const Loader = styled(TracksOutline)<{ initial: number }>`
    width: 100%;
    height: 100%;
    left: ${(props) => props.initial}%;
    position: absolute;

    animation: ${slide} 3s linear infinite;
`;

const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        90deg,
        ${({ theme }) => theme.colors.backgrounds.page} 0%,
        transparent 50%,
        ${({ theme }) => theme.colors.backgrounds.page} 100%
    );
`;

export const RobinLoader = (props: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
    return (
        <Container {...props}>
            <Loader initial={0} />
            <Loader initial={100} />
            <GradientOverlay />
        </Container>
    );
};
