import { StatusMsg } from "./proto/generated/common3_pb";

enum StatusCode {
    Ok = 200,
}

export class StatusResponse {
    // Properties

    public get isOk(): boolean {
        return this.code === StatusCode.Ok;
    }

    // Static functions

    public static fromProto(model: StatusMsg): StatusResponse {
        return new StatusResponse(model.getStatuscode(), model.getStatusmessage());
    }

    public constructor(public readonly code: int, public readonly message: string) {}
}
