import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { RadarRepository, UserLocationRepository } from ".";
import { MeasurementPoint } from "../model";
import { t } from "i18next";

export class MeasurementPointsRepository {
    constructor(
        private readonly radarRepository: RadarRepository,
        private readonly userLocationRepository: UserLocationRepository,
    ) {}

    public get measurementPoints(): Rx.Observable<MeasurementPoint[]> {
        return Rx.combineLatest([
            this.radarRepository.radars,
            this.userLocationRepository.userLocationPosition.pipe(RxOperators.startWith(undefined)),
        ]).pipe(
            RxOperators.map(([radars, userLocationPosition]) => {
                const measurementPoints = radars.map((radar) => ({
                    name: radar.name,
                    position: radar.position.toGeoJSONLocation(),
                }));
                if (userLocationPosition) {
                    measurementPoints.push({
                        name: t("general.currentLocation"),
                        position: [userLocationPosition.coords.longitude, userLocationPosition.coords.latitude],
                    });
                }
                return measurementPoints;
            }),
        );
    }
}
