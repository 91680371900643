import { PlusMinusItemViewModel } from "../../SettingItemViewModel";
import FunnelViewThreholdIcon from "../../../../res/images/settings/funnel_view_threshold.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { t } from "i18next";

export class FunnelViewThresholdSettingItemViewModel extends PlusMinusItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.alertThresholdFunnel"),
            SettingItemIcon.withUrl(FunnelViewThreholdIcon),
            localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.charts.funnelViewThreshold),
            [1, 100],
            1,
            (value) =>
                localPreferencesRepository.setPreference(LocalUserPreferenceKeys.charts.funnelViewThreshold, value),
            (value) => value.toString(),
        );
    }
}
