import { PlusMinusItemViewModel } from "../../SettingItemViewModel";
import FinishedTrackOpacityIcon from "../../../../res/images/settings/finished_track_opacity.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { t } from "i18next";

export class FinishedTrackOpacitySettingItemViewModel extends PlusMinusItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.finishedTrackOpacity"),
            SettingItemIcon.withUrl(FinishedTrackOpacityIcon),
            localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.appearance.finishedTrackOpacity,
            ),
            [0.1, 0.9],
            0.1,
            (v) => localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.finishedTrackOpacity, v),
            (value) => Math.round(value * 100).toString() + "%",
        );
    }
}
