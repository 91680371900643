import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { LocationInfoRepository, TrackRepository } from "../../domain/repositories";
import { Classification, DistanceUnit, EmitterCategoryGroup } from "../../domain/model";
import { LocalPreferencesRepository } from "../../domain/repositories/LocalPreferencesRepository";
import { getClassificationVisibilityKey, LocalUserPreferenceKeys } from "../../domain/model/PreferencesData";
import { combinedBooleanObservable, nonNullObservable } from "../../utils/RxUtils";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { LegendEntryProps, VisibilityProps } from "./LegendEntry";
import * as LegendIcon from "./LegendIcon";
import { DistanceFormatter } from "../../domain/DistanceFormatter";
import { t } from "i18next";
import { LegendSection } from "./LegendSection";

const CUSTOM_LEGEND_ORDERS = {
    RADAR: 0,
    USER_LOCATION: 1,
    ADSB_AIRCRAFT: 1001,
    ADSB_VEHICLE: 1002,
    ADSB_DRONE: 1003,
    ADSB_ROTORCRAFT: 1004,
    ADSB_OTHER: 1005,
};

export class LegendViewModel extends BaseViewModel {
    // Properties

    public get hasReliableAltitudeInfo(): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasReliableAltitudeInfo;
    }

    public get altitudeUnitNameObservable(): Rx.Observable<string> {
        return this.distanceFormatter.selectedDistanceUnitObservable.pipe(
            RxOperators.map((altitudeUnitName) => {
                switch (altitudeUnitName) {
                    case DistanceUnit.IMPERIAL:
                        return t("unit.foot_other");
                    case DistanceUnit.METRIC:
                        return t("unit.meter_other");
                }
            }),
        );
    }

    public get allSectionsEntriesVisible(): Rx.Observable<Record<string, boolean>> {
        return Rx.combineLatest([
            this.classificationsSubject.pipe(
                RxOperators.switchMap((classifications) =>
                    combinedBooleanObservable(
                        this.localPreferencesRepository.observePreferences<boolean>(
                            this.getAllVisibilityPreferenceKeysClassifications(classifications),
                            true,
                        ),
                    ),
                ),
            ),
            combinedBooleanObservable(
                this.localPreferencesRepository.observePreferences<boolean>(
                    this.getAllVisibilityPreferenceKeysADSB(),
                    true,
                ),
            ),
        ]).pipe(
            RxOperators.map(([classifications, adsb]) => {
                return {
                    [this.classificationsSectionId]: classifications,
                    [this.adsbSectionId]: adsb,
                };
            }),
        );
    }

    public readonly additionalLegendEntries: LegendEntryProps[] = [
        {
            name: t("legend.radar"),
            iconType: LegendIcon.newRadarIconComponent,
            order: CUSTOM_LEGEND_ORDERS.RADAR,
        },
        {
            name: t("legend.yourLocation"),
            iconType: LegendIcon.newUserLocationIconComponent,
            order: CUSTOM_LEGEND_ORDERS.USER_LOCATION,
        },
    ];

    private readonly adsbPreferenceKeys: string[] = [];
    private readonly classificationsSubject = new Rx.BehaviorSubject<Classification[]>([]);
    private readonly classificationsSectionId = "classifications";
    private readonly adsbSectionId = "adsb";

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
        if (DI.isBound(TYPES.ADSBFlightRepository)) {
            this.adsbPreferenceKeys = [
                LocalUserPreferenceKeys.filters.showADSBAircraft,
                LocalUserPreferenceKeys.filters.showADSBVehicles,
                LocalUserPreferenceKeys.filters.showADSBDrones,
                LocalUserPreferenceKeys.filters.showADSBRotorcraft,
                LocalUserPreferenceKeys.filters.showADSBOther,
            ];
        }
        this.collectSubscription(
            this.trackRepository.classificationsMap
                .pipe(RxOperators.map((map) => Array.from(map.values())))
                .subscribe(this.classificationsSubject),
        );
    }

    // Public functions

    public getLegendEntries(): Rx.Observable<LegendEntryProps[]> {
        return this.classificationsSubject.pipe(
            RxOperators.map((classifications) => this.getClassificationEntries(classifications)),
            RxOperators.map((classificationEntries) =>
                classificationEntries.sort((a, b) => (a.order > b.order ? 1 : -1)),
            ),
        );
    }

    public getLegendAdsbEntries(): Rx.Observable<LegendEntryProps[]> {
        const adsbEntries = this.adsbPreferenceKeys.map((key) => this.getEntryFromAdsbPreferenceKey(key));
        return Rx.of(adsbEntries.sort((a, b) => (a.order > b.order ? 1 : -1)));
    }

    public getLegendSections(): Rx.Observable<LegendSection[]> {
        return Rx.combineLatest([
            this.getLegendEntries(),
            this.getLegendAdsbEntries(),
            this.isSectionOpen(this.classificationsSectionId),
            this.isSectionOpen(this.adsbSectionId),
        ]).pipe(
            RxOperators.map(([classificationEntries, adsbEntries, isClassificationOpen, isAdsbOpen]) => {
                return [
                    {
                        id: this.classificationsSectionId,
                        label: "legend.classifications",
                        entries: classificationEntries,
                        isOpen: !!isClassificationOpen,
                    },
                    {
                        id: this.adsbSectionId,
                        label: "map.adsb",
                        entries: adsbEntries,
                        isOpen: !!isAdsbOpen,
                    },
                ];
            }),
        );
    }

    public setClassificationVisibility(sectionId: string, isVisible: boolean): void {
        let keys: string[] = [];
        switch (sectionId) {
            case this.adsbSectionId:
                keys = this.getAllVisibilityPreferenceKeysADSB();
                break;
            case this.classificationsSectionId:
                keys = this.getAllVisibilityPreferenceKeysClassifications(this.classificationsSubject.getValue());
                break;
            default:
                break;
        }
        this.localPreferencesRepository.setPreferences(keys, isVisible);
    }

    public setSectionOpen(sectionId: string, open: boolean): void {
        const key = LocalUserPreferenceKeys.legendSectionOpenPrefix + sectionId;
        this.localPreferencesRepository.setPreference<boolean>(key, open);
    }

    // Private functions

    private isSectionOpen(sectionId: string): Rx.Observable<boolean> {
        const key = LocalUserPreferenceKeys.legendSectionOpenPrefix + sectionId;
        return nonNullObservable(this.localPreferencesRepository.observePreference<boolean>(key));
    }

    private getClassificationEntries(classifications: Classification[]): LegendEntryProps[] {
        return classifications
            .map((c) => this.getEntryFromClassification(c))
            .filter((item) => item != null)
            .map((item) => item!);
    }

    private getEntryFromClassification(classification: Classification): LegendEntryProps | null {
        const iconComponent = LegendIcon.getComponentFromClassification(classification, 1);
        if (iconComponent == null) {
            return null;
        }
        const key = getClassificationVisibilityKey(classification.name);
        return {
            name: classification.prettyName,
            iconType: () => iconComponent,
            order: classification.zOrder,
            visibility: this.getVisibilityProps(key),
        };
    }

    private getLegendEntryProps(
        name: string,
        order: number,
        emitterGroup: EmitterCategoryGroup,
        localPreferencesKey: string,
    ): LegendEntryProps {
        return {
            name,
            order,
            iconType: () => LegendIcon.getComponentFromProps(LegendIcon.getADSBGroupIconProps(emitterGroup, 1)),
            visibility: this.getVisibilityProps(localPreferencesKey),
        };
    }

    private getEntryFromAdsbPreferenceKey(localPreferencesKey: string): LegendEntryProps {
        switch (localPreferencesKey) {
            case LocalUserPreferenceKeys.filters.showADSBVehicles:
                return this.getLegendEntryProps(
                    t("classification.ADSBVehicle"),
                    CUSTOM_LEGEND_ORDERS.ADSB_VEHICLE,
                    EmitterCategoryGroup.VehicleEmitterCategory,
                    LocalUserPreferenceKeys.filters.showADSBVehicles,
                );
            case LocalUserPreferenceKeys.filters.showADSBDrones:
                return this.getLegendEntryProps(
                    t("classification.drone"),
                    CUSTOM_LEGEND_ORDERS.ADSB_DRONE,
                    EmitterCategoryGroup.DroneEmitterCategory,
                    LocalUserPreferenceKeys.filters.showADSBDrones,
                );
            case LocalUserPreferenceKeys.filters.showADSBRotorcraft:
                return this.getLegendEntryProps(
                    t("classification.ADSBRotorcraft"),
                    CUSTOM_LEGEND_ORDERS.ADSB_ROTORCRAFT,
                    EmitterCategoryGroup.RotorcraftEmitterCategory,
                    LocalUserPreferenceKeys.filters.showADSBRotorcraft,
                );
            case LocalUserPreferenceKeys.filters.showADSBAircraft:
                return this.getLegendEntryProps(
                    t("classification.ADSBAircraft"),
                    CUSTOM_LEGEND_ORDERS.ADSB_AIRCRAFT,
                    EmitterCategoryGroup.AircraftEmitterCategory,
                    LocalUserPreferenceKeys.filters.showADSBAircraft,
                );
            default:
                return this.getLegendEntryProps(
                    t("classification.other"),
                    CUSTOM_LEGEND_ORDERS.ADSB_OTHER,
                    EmitterCategoryGroup.OtherEmitterCategory,
                    LocalUserPreferenceKeys.filters.showADSBOther,
                );
        }
    }

    private getVisibilityProps(localPreferencesKey: string): VisibilityProps {
        return {
            isVisible: this.localPreferencesRepository.getPreference<boolean>(localPreferencesKey, true)!,
            isVisibleObservable: nonNullObservable(
                this.localPreferencesRepository.observePreference<boolean>(localPreferencesKey),
                true,
            ),
            setIsVisible: (value: boolean) => this.localPreferencesRepository.setPreference(localPreferencesKey, value),
        };
    }

    private getAllVisibilityPreferenceKeysClassifications(classifications: Classification[]): string[] {
        const classificationKeys = classifications.map((classification) =>
            getClassificationVisibilityKey(classification.name),
        );
        return classificationKeys;
    }

    private getAllVisibilityPreferenceKeysADSB(): string[] {
        return this.adsbPreferenceKeys;
    }
}
