import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { AbstractStartableRepository, LocalPreferencesRepository } from "../../../../domain/repositories";
import { Overlay, LocalUserPreferenceKeys } from "../../../../domain/model";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { OverlayRepository } from "../../../../domain/repositories/OverlayRepository";
import { kebabify } from "../../../../utils/StringUtils";

export class BirdViewerOverlayRepository extends AbstractStartableRepository implements OverlayRepository {
    // Properties
    public readonly overlays: Rx.Observable<Map<string, Overlay>>;
    private readonly overlaysSubject = new Rx.BehaviorSubject<Map<string, Overlay> | null>(null);
    private subscriptions: Rx.Subscription | undefined;

    public constructor(
        private readonly api: BirdViewerAPI,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
        this.overlays = nonNullObservable(this.overlaysSubject.asObservable());
    }

    // Public functions
    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.getOverlayList();
    }
    public stop(): void {
        this.subscriptions!.unsubscribe();
    }
    public getOverlayVisibility(overlayName: string): boolean {
        return (
            this.localPreferencesRepository.getPreference<boolean>(this.getOverlayVisibilityKey(overlayName)) || false
        );
    }
    public observeOverlayVisibility(overlayName: string): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(this.getOverlayVisibilityKey(overlayName)),
            false,
        );
    }
    public setOverlayVisibility(overlayName: string, visible: boolean): void {
        this.localPreferencesRepository.setPreference(this.getOverlayVisibilityKey(overlayName), visible);
    }
    // Private functions
    private getOverlayList(): void {
        this.api
            .getOverlayList()
            .pipe(
                RxOperators.map((response) => {
                    const overlays = new Map<string, Overlay>();
                    response
                        .getOverlayList()
                        .reverse()
                        .forEach((overlay) => overlays.set(overlay.getName(), Overlay.fromProto(overlay)));
                    return overlays;
                }),
            )
            .subscribe(
                (overlays) => this.overlaysSubject.next(overlays),
                (error) => {
                    console.error(error);
                    setTimeout(() => {}, parseInt(process.env.REACT_APP_REPEAT_DELAY_ON_ERROR_MSEC!));
                },
            );
    }
    private getOverlayVisibilityKey(overlayName: string): string {
        return LocalUserPreferenceKeys.filters.overlayVisibilityPrefix + kebabify(overlayName);
    }
}
