export const enumValueOrDefault = <T extends string, K extends string>(
    enumObj: { [P in K]: T },
    value: string | null,
    defaultValue: T,
): T => {
    if (value === null) {
        return defaultValue;
    }
    return enumValueOrUndefined(enumObj, value) || defaultValue;
};

export const enumValueOrUndefined = <T extends string, K extends string>(
    enumObj: { [P in K]: T },
    value: string,
): T | undefined => {
    if (Object.values(enumObj).includes(value)) {
        return value as T;
    }
    return undefined;
};
