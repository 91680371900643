import { AlarmData, AlarmDataType } from ".";
import { TrackJSON } from "./json";

export enum TrackAlarmState {
    NOT_ALARMING = 0,
    ALARMING = 1,
    OTHER_TRACK_ALARMING = 2,
}

export type AlarmingTrackRecord = { [key in AlarmDataType | "adsb"]?: number[] };

interface AlarmTrackData {
    databaseId: number;
    startTime: long | null;
    endTime: long | null;
}

export class Alarm {
    public static fromJson(track: TrackJSON): Alarm {
        const hasEstimates = track.trackUpdates.length > 0;
        const startTime = hasEstimates ? track.trackUpdates[0].timestampMsec : null;
        const endTime = hasEstimates ? track.trackUpdates[track.trackUpdates.length - 1].timestampMsec : null;
        return new Alarm(
            {
                databaseId: track.databaseId,
                startTime,
                endTime,
            },
            {
                trackId: track.trackid,
                type: AlarmDataType.DRONE,
            },
        );
    }

    public constructor(public track: AlarmTrackData, public alarmData: AlarmData) {}
}
