import { BlankingSectorJSON } from "./json";
import { BlankingSector as BlankingSectorProto } from "./proto/generated/blankingsectors3_pb";

export class BlankingSector {
    // Static functions

    public static fromProto(model: BlankingSectorProto): BlankingSector {
        let enabled = !(model.getStartangledeg() === 0 && model.getSpandeg() === 0);
        if (model.getEnabled() !== undefined) {
            enabled = model.getEnabled();
        }
        return new BlankingSector(model.getStartangledeg(), model.getSpandeg(), enabled);
    }

    public static fromJson(model: BlankingSectorJSON): BlankingSector {
        let enabled = model.enabled !== undefined ? model.enabled : false;
        if (model.enabled === undefined) {
            enabled = !(model.startAngle === 0 && model.span === 0);
        }

        return new BlankingSector(model.startAngle, model.span, enabled);
    }

    public constructor(
        public readonly startAngle: number,
        public readonly span: number,
        public readonly enabled: boolean,
    ) {}
}
