import React from "react";
import { TYPES } from "../../di/Types";
import { LanguageSelectionViewModel } from "./LanguageSelectionViewModel";
import { useViewModel } from "../../hooks/useViewModel";
import { Modal, SharedModalProps } from "../appearance/modal/Modal";
import { t } from "i18next";
import { Card, CardGroup } from "../appearance/Card";

export const LanguageSelection = ({ onClosed }: SharedModalProps): JSX.Element => {
    // Properties

    const viewModel: LanguageSelectionViewModel = useViewModel(TYPES.LanguageSelectionViewModel);

    // Local functions

    const changeLanguage = (code: string): void => {
        if (code === viewModel.activeLanguage.code) {
            return;
        }
        viewModel.setActiveLanguage(code);
        window.location.reload();
    };

    // Render

    return (
        <Modal title={t("languageSelection.language")} onClosed={onClosed}>
            <CardGroup>
                {viewModel.availableLanguages.map((l) => (
                    <Card
                        label={viewModel.languageResources[l].languageName}
                        onClick={() => changeLanguage(viewModel.languageResources[l].code)}
                        selected={viewModel.activeLanguage.code === viewModel.languageResources[l].code}
                        smallLabel={viewModel.languageResources[l].countryName}
                        key={viewModel.languageResources[l].code}
                    />
                ))}
            </CardGroup>
        </Modal>
    );
};
