import React, { useState } from "react";
import styled from "styled-components";
import { RadarOperatingMode, RadarOperatingModeStatus } from "../../../domain/model";
import { PanelDescription, PanelParagraph } from "../../appearance/panels/PanelText";
import { ReactComponent as WarningIcon } from "../../../res/images/warning.svg";
import { t } from "i18next";
import { ButtonGroup, ButtonGroupOutsideRounded } from "../../appearance/button/ButtonGroup";
import { Button } from "../../appearance/button/Button";
import { Form } from "../../appearance/forms/Form";
import { FormControl } from "../../appearance/forms/FormControl";

const Warning = styled(WarningIcon)`
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.status.warning};
`;

interface OperatingModeSwitcherProps {
    activeMode: RadarOperatingModeStatus | undefined;
    setNewMode: (modeId: string) => void;
    modes: RadarOperatingMode[];
}

export const OperatingModeSwitcher = ({
    activeMode,
    setNewMode,
    modes,
}: OperatingModeSwitcherProps): React.ReactElement => {
    // Properties

    const [targetModeId, setTargetModeId] = useState<string>("");
    const [isSwitchingMode, setIsSwitchingMode] = useState<boolean>(false);

    // Local functions

    const setTargetMode = (modeId: string): void => {
        if (!isSwitchingMode && activeMode && activeMode.id !== modeId) {
            setIsSwitchingMode(true);
            setTargetModeId(modeId);
        }
    };
    const confirmModeSwitch = (): void => {
        setNewMode(targetModeId);
        cancelModeSwitch();
    };
    const cancelModeSwitch = (): void => {
        setTargetModeId("");
        setIsSwitchingMode(false);
    };

    // Render

    return (
        <Form vertical>
            <PanelDescription>{t("radarControlPanel.operatingModeSwitchExplanation")}</PanelDescription>
            <ButtonGroupOutsideRounded>
                {modes.map((mode) => (
                    <Button
                        color={activeMode?.id === mode.id ? "default" : "defaultDark"}
                        disabled={isSwitchingMode}
                        label={mode.displayName}
                        onClick={() => setTargetMode(mode.id)}
                        key={mode.id}
                    />
                ))}
            </ButtonGroupOutsideRounded>
            {isSwitchingMode && (
                <FormControl title={t("radarControlPanel.operatingModeSwitchWarningTitle")} vertical>
                    <PanelParagraph>{t("radarControlPanel.operatingModeSwitchWarningDescription")}</PanelParagraph>
                    <ButtonGroup>
                        <Button color="warning" label={t("general.yes")} onClick={() => confirmModeSwitch()} />
                        <Button label={t("general.no")} onClick={() => cancelModeSwitch()} />
                    </ButtonGroup>
                </FormControl>
            )}
            {activeMode && !activeMode.ready && (
                <FormControl>
                    <PanelParagraph>
                        <Warning /> {t("radarControlPanel.busySwitchingOperatingMode")}
                    </PanelParagraph>
                </FormControl>
            )}
        </Form>
    );
};
