export enum TrackUpdateType {
    NEW_TRACK = "NEW_TRACK",
    FINISH_TRACK = "FINISH_TRACK",
    DROP_TRACK = "DROP_TRACK",
    ADD_ESTIMATE = "ADD_ESTIMATE",
    ADD_PLOT = "ADD_PLOT",
    CLASSIFICATION_CHANGE = "CLASSIFICATION_CHANGE",
    DRONEALARM_INFO_CHANGE = "DRONEALARM_INFO_CHANGE",
    FLIGHT_INFO_CHANGE = "FLIGHT_INFO_CHANGE",
}
