import { BaseViewModel } from "../../BaseViewModel";
import { LocalPreferencesRepository, ReplayRepository } from "../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../domain/model";
import { PlaybackScene, PlaybackState } from "../../../domain/PlaybackScene";
import { nonNullObservable } from "../../../utils/RxUtils";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { TimeDisplayMode } from "../../../domain/model/TimeDisplayMode";

export class ReplayControlsViewModel extends BaseViewModel {
    // Properties

    private playbackScene: PlaybackScene | null = null;

    public get speed(): number {
        return this.localPreferencesRepository.getPreference<number>(LocalUserPreferenceKeys.replay.speed)!;
    }

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
            RxOperators.distinctUntilChanged(),
        );
    }

    public get time(): Rx.Observable<long> {
        return nonNullObservable(this.replayRepository.currentPlaybackScene).pipe(
            RxOperators.switchMap((scene) => scene.tracks),
            RxOperators.map((snapshot) => snapshot.timestamp),
        );
    }

    public get replayRange(): Rx.Observable<ReplayRange> {
        return nonNullObservable(this.replayRepository.currentPlaybackScene).pipe(
            RxOperators.map((scene) => ({ startTimestamp: scene.startTimestamp, endTimestamp: scene.endTimestamp })),
        );
    }

    public get timeDisplayMode(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.appearance.timeDisplayMode),
        );
    }

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly replayRepository: ReplayRepository,
    ) {
        super();
        replayRepository.currentPlaybackScene.subscribe((scene) => {
            this.playbackScene = scene;
        });
    }

    // Public functions

    public updateSpeed(speed: number): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.replay.speed, speed);
    }

    public playFrom(timestamp: long): void {
        if (this.playbackScene == null) {
            return;
        }

        this.playbackScene.playFrom(timestamp, this.speed);
    }

    public pause(): void {
        if (this.playbackScene == null) {
            return;
        }

        this.playbackScene.pause();
    }

    public stop(): void {
        if (this.playbackScene == null) {
            return;
        }

        this.playbackScene.stop();
    }
}

export interface ReplayRange {
    startTimestamp: long;
    endTimestamp: long;
}
