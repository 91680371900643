import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { TileProvider, LocalUserPreferenceKeys } from "../../../../domain/model";
import { LocalPreferencesRepository, AbstractStartableRepository } from "../../../../domain/repositories";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { MapRepository } from "../../../../domain/repositories/MapRepository";

export class BirdViewerMapRepository extends AbstractStartableRepository implements MapRepository {
    // Properties

    public get tileProviders(): Rx.Observable<TileProvider[]> {
        return this.tileProvidersSubject.asObservable();
    }
    private readonly tileProvidersSubject = new Rx.BehaviorSubject<TileProvider[]>([]);

    public constructor(
        private readonly api: BirdViewerAPI,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
    }

    // Public functions

    public start(): void {
        this.fetchTileProviders();
    }
    public stop(): void {}
    public getSelectedTileProvider(): Rx.Observable<TileProvider> {
        const selectedTileProviderId = this.localPreferencesRepository
            .observePreference(LocalUserPreferenceKeys.selections.selectedTileProviderId)
            .pipe(RxOperators.distinctUntilChanged());
        return Rx.combineLatest([this.tileProviders, selectedTileProviderId]).pipe(
            RxOperators.map(([providers, selectedProviderId]) => {
                return providers.find((provider) => provider.id === selectedProviderId) || providers[0] || null;
            }),
            RxOperators.filter((v) => v != null),
        );
    }
    public setSelectedTileProviderId(id: int): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.selections.selectedTileProviderId, id);
    }

    // Private functions

    private fetchTileProviders(): void {
        this.api
            .getTileProviders()
            .pipe(
                RxOperators.switchMap((r) => r.getTileproviderList()),
                RxOperators.map((tp) => TileProvider.fromProto(tp)),
                RxOperators.toArray(),
            )
            .subscribe({
                next: (response) => {
                    this.tileProvidersSubject.next(response.length === 0 ? [TileProvider.DEFAULT] : response);
                },
                error: (error) => {
                    this.tileProvidersSubject.next([TileProvider.DEFAULT]);
                    this.retryIfStartedAndImplemented(() => this.fetchTileProviders(), error);
                },
            });
    }
}
