import * as Rx from "rxjs";
import { RadarLocation } from "../../domain/model";
import { DeterrenceRepository, LocationInfoRepository } from "../../domain/repositories";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../infrastructure/AppConfig";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";
import { BaseViewModel } from "../BaseViewModel";

export class ToolbarViewModel extends BaseViewModel {
    // Properties

    public get isRadarLocationListAvailable(): boolean {
        return this.radarLocationList.length > 0;
    }

    public get isTrackListEnabled(): Rx.Observable<boolean> {
        return Rx.of(this.flavorConfig.trackListEnabled);
    }

    public get hasReliableAltitudeInfo(): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasReliableAltitudeInfo;
    }

    public get isDeterrenceActionAllowed(): Rx.Observable<boolean> {
        if (!this.deterrenceRepository) {
            return Rx.of(false);
        }
        return this.deterrenceRepository.getDeterrenceActionAllowed();
    }

    // Directly using getRuntimeConfig() here instead of getAppConfig(), because we need the return value to be an array
    private readonly radarLocationList =
        getRuntimeConfig<RadarLocation[]>(APP_CONFIG_KEYS.API_MULTIPLE_RADAR_LOCATIONS) || [];

    constructor(
        private readonly flavorConfig: FlavorConfig,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly deterrenceRepository?: DeterrenceRepository,
    ) {
        super();
    }
}
