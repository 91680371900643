import mapboxgl from "mapbox-gl";
import { LayerManager } from "./LayerManager";

export class MapBoxLayerManager implements LayerManager {
    // Properties

    private storedState = new Map<string, boolean>();

    public constructor(private readonly map: mapboxgl.Map) {}

    // Public functions

    public setAllEnabled(
        enabled: boolean,
        excludeLayerIds: string[],
        storeState: boolean,
        overrideStoredState = false,
    ): void {
        const allLayers = this.map.getStyle().layers || [];

        const layerIds = allLayers
            .map((l) => l.id)
            .filter((l) => l.startsWith("layer-") && !excludeLayerIds.includes(l));
        if (storeState && (this.storedState.size === 0 || overrideStoredState)) {
            const state = new Map<string, boolean>();
            layerIds.forEach((id) => {
                const visibilityProp = this.map.getLayoutProperty(id, "visibility");
                const visible = visibilityProp == null || visibilityProp === "visible" ? true : false;
                state.set(id, visible);
            });
            this.storedState = state;
        }
        const visibility = enabled ? "visible" : "none";
        layerIds.forEach((id) => {
            this.map.setLayoutProperty(id, "visibility", visibility);
        });
    }

    public restoreState(): boolean {
        if (this.storedState.size === 0) {
            return false;
        }

        const entries = Array.from(this.storedState.entries());
        entries.forEach(([id, enabled]) => {
            const visibility = enabled ? "visible" : "none";
            this.map.setLayoutProperty(id, "visibility", visibility);
        });
        this.storedState = new Map();
        return true;
    }
}
