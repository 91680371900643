import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { RadarControlPanelViewModel } from "./RadarControlPanelViewModel";
import Loading from "../appearance/Loading";
import { Radar, Location, BlankingSector, RadarOperatingMode, RadarOperatingModeStatus } from "../../domain/model";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { RadarReposition } from "./modules/RadarReposition";
import * as RxOperators from "rxjs/operators";
import * as Rx from "rxjs";
import { RadarStartStop } from "./RadarStartStop";
import { RadarAlignment } from "./modules/RadarAlignment";
import { showError } from "../../utils/MessageUtils";
import { RadarBlankingSectors } from "./modules/RadarBlankingSectors";
import { t } from "i18next";
import { OperatingModeSwitcher } from "./modules/OperatingModeSwitcher";
import { Panel } from "../appearance/panels/Panel";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelFooter } from "../appearance/panels/PanelFooter";
import { Form } from "../appearance/forms/Form";
import { Button } from "../appearance/button/Button";

const LoadingContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1001;
`;

export enum Section {
    Overview,
    Reposition,
    Alignment,
    OperatingMode,
    BlankingSectors,
    Loading,
}

interface RadarNameEditState {
    radarId: number;
    newName: string;
    saving: boolean;
}

interface Props {
    onClose: () => void;
}

interface State {
    activeOperatingMode: RadarOperatingModeStatus | undefined;
    activePanel: Section;
    availableOperatingModes: RadarOperatingMode[];
    canShowRepositionPanel: boolean;
    canShowAlignmentPanel: boolean;
    hasMultipleRadars: boolean;
    isAutoAligning: boolean;
    isAutoPositioning: boolean;
    radarNameEditState: RadarNameEditState | null;
    selectedRadar: Radar | undefined;
}

export class RadarControlPanel extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: RadarControlPanelViewModel = DI.get(TYPES.RadarControlPanelViewModel);

    private get onRequestAutoPosition(): Rx.Observable<void> {
        return Rx.combineLatest([
            this.viewModel.autoPositionRadar(this.state.selectedRadar!.id),
            this.viewModel.selectedRadarObservable.pipe(RxOperators.take(2)),
        ]).pipe(RxOperators.ignoreElements());
    }

    public constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            activeOperatingMode: undefined,
            activePanel: Section.Loading,
            availableOperatingModes: [],
            canShowRepositionPanel: this.viewModel.canShowRepositionPanel,
            canShowAlignmentPanel: this.viewModel.canShowAlignmentPanel,
            hasMultipleRadars: false,
            isAutoAligning: false,
            isAutoPositioning: false,
            radarNameEditState: null,
            selectedRadar: undefined,
        };
    }

    public componentDidMount(): void {
        this.subscribeToRadars();
        this.subscribeToOperatingModes();
        this.subscribeToIsDynamicPositioningEnabled();
        this.subscribeToErrors();
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();

        this.viewModel.canShowRepositionPanel && this.viewModel.hideRepositioningPreview();
        this.viewModel.canShowAlignmentPanel && this.viewModel.hideAlignmentPreview();
        this.viewModel.hideBlankingSectorsPreview();
    }

    public render(): React.ReactNode {
        return (
            <Panel>
                {this.renderPanelHeader()}
                <PanelSection>
                    {(this.state.activePanel === Section.Loading || this.state.selectedRadar === undefined) && (
                        <LoadingContainer>
                            <Loading />
                        </LoadingContainer>
                    )}
                    {this.state.activePanel === Section.Overview && this.state.selectedRadar && (
                        <Form>
                            {this.state.canShowRepositionPanel && this.renderPanelLinkButton(Section.Reposition)}
                            {this.state.canShowAlignmentPanel && this.renderPanelLinkButton(Section.Alignment)}
                            {this.state.availableOperatingModes.length > 1 &&
                                this.renderPanelLinkButton(Section.OperatingMode)}
                            {this.renderPanelLinkButton(Section.BlankingSectors)}
                        </Form>
                    )}
                    {this.state.activePanel === Section.Reposition && this.state.selectedRadar && (
                        <RadarReposition
                            altitudeUnitObservable={this.viewModel.altitudeUnitObservable}
                            distanceFormatter={this.viewModel.distanceFormatter}
                            isAutoPositioning={this.state.isAutoPositioning}
                            onRequestPreviewNewPosition={(position) =>
                                this.viewModel.showRepositioningPreview(position)
                            }
                            onRequestSaveNewPosition={this.savePositionForRadar.bind(this)}
                            previewLocationFromMapObservable={this.viewModel.previewPositionFromDraggingMap}
                            radar={this.state.selectedRadar}
                            showAltitudeExplanationModal={() => this.viewModel.showAltitudeExplanationModal()}
                        />
                    )}
                    {this.state.activePanel === Section.Alignment && this.state.selectedRadar && (
                        <RadarAlignment
                            contrast={this.viewModel.getAlignmentImageContrast()}
                            isAutoAligning={this.state.isAutoAligning}
                            onContrastSet={(value) => this.viewModel.setAlignmentImageContrast(value)}
                            onRequestPreviewNewAlignment={(dAngle, contrast) =>
                                this.viewModel.showAlignmentPreview(dAngle, this.state.selectedRadar!.id, contrast)
                            }
                            onRequestSaveNewAlignment={this.saveNewAlignment.bind(this)}
                            radar={this.state.selectedRadar}
                        />
                    )}
                    {this.state.activePanel === Section.OperatingMode && this.state.selectedRadar && (
                        <OperatingModeSwitcher
                            activeMode={this.state.activeOperatingMode}
                            modes={this.state.availableOperatingModes}
                            setNewMode={this.setOperatingMode.bind(this)}
                        />
                    )}
                    {this.state.activePanel === Section.BlankingSectors && this.state.selectedRadar && (
                        <RadarBlankingSectors
                            blankingSectors={this.viewModel.getBlankingSectors(this.state.selectedRadar.id)}
                            maxSectors={this.viewModel.getMaxNumberOfBlankingSectors(this.state.selectedRadar.id)}
                            blankingSectorsState={this.viewModel.getBlankingSectorsState()}
                            onRequestPreview={(blankingSectors) =>
                                this.viewModel.showBlankingSectorsPreview(this.state.selectedRadar!.id, blankingSectors)
                            }
                            onRequestSave={(blankingSectors) =>
                                this.saveNewBlankingSectors(this.state.selectedRadar!.id, blankingSectors)
                            }
                            radar={this.viewModel.getRadarById(this.state.selectedRadar.id)}
                        />
                    )}
                </PanelSection>
                {this.renderPanelFooter()}
            </Panel>
        );
    }

    // Private functions

    private renderPanelHeader(): React.ReactNode | null {
        switch (this.state.activePanel) {
            case Section.Overview:
                return <PanelHeader label={t("radarControlPanel.radarControlPanel")} onClose={this.props.onClose} />;
            case Section.Reposition:
            case Section.Alignment:
            case Section.OperatingMode:
            case Section.BlankingSectors:
                return (
                    <PanelHeader
                        label={this.getNameForPanel(this.state.activePanel)}
                        onBack={() => this.showSection(Section.Overview)}
                        onBackLabel={t("radarControlPanel.backToOverview")}
                        onClose={this.props.onClose}
                    />
                );
            case Section.Loading:
                return null;
        }
    }

    private getNameForPanel(panel: Section): string {
        switch (panel) {
            case Section.Reposition:
                return t("radarControlPanel.positioning");
            case Section.Alignment:
                return t("radarControlPanel.alignment");
            case Section.OperatingMode:
                return t("radarControlPanel.operatingMode");
            case Section.BlankingSectors:
                return t("radarControlPanel.blankingSectors");
            default:
                return "";
        }
    }

    private renderPanelLinkButton(panel: Section): React.ReactNode {
        return (
            <Button
                iconType="CHEVRON_RIGHT"
                label={this.getNameForPanel(panel)}
                layout="inline"
                onClick={() => this.showSection(panel)}
                hasAction={true}
            />
        );
    }

    private renderPanelFooter(): React.ReactNode | null {
        if (!this.state.selectedRadar) {
            return null;
        }

        if (this.state.activePanel === Section.Overview) {
            return (
                <PanelFooter>
                    {this.state.selectedRadar.status && (
                        <RadarStartStop
                            onRequestToggleRadar={() => this.viewModel.toggleRadar(this.state.selectedRadar!.id)}
                            radarStatus={this.state.selectedRadar.status}
                        />
                    )}
                </PanelFooter>
            );
        }

        if (this.state.activePanel === Section.Reposition) {
            return (
                <PanelFooter>
                    {this.state.selectedRadar.status!.canPerformAutoPosition && (
                        <Button
                            disabled={this.state.isAutoPositioning}
                            label={t("radarControlPanel.autoPosition")}
                            onClick={this.autoPosition.bind(this)}
                        />
                    )}
                </PanelFooter>
            );
        }

        if (this.state.activePanel === Section.Alignment) {
            return (
                <PanelFooter>
                    {this.state.selectedRadar.status!.canPerformAutoAlignment && (
                        <Button
                            disabled={this.state.isAutoAligning}
                            label={t("radarControlPanel.autoAlign")}
                            onClick={this.autoAlign.bind(this)}
                        />
                    )}
                </PanelFooter>
            );
        }
    }

    private showSection(section: Section): void {
        if (section !== Section.Reposition && this.viewModel.canShowRepositionPanel) {
            this.viewModel.hideRepositioningPreview();
        }
        if (section !== Section.Alignment && this.viewModel.canShowAlignmentPanel) {
            this.viewModel.hideAlignmentPreview();
        }
        if (section !== Section.BlankingSectors) {
            this.viewModel.hideBlankingSectorsPreview();
        }
        this.setState({ activePanel: section });
    }

    private savePositionForRadar(position: Location, groundLevel: number | null): Rx.Observable<void> {
        return this.viewModel.savePositionForRadar(this.state.selectedRadar!.id, position, groundLevel).pipe(
            RxOperators.tap({
                complete: () => this.showSection(Section.Overview),
            }),
        );
    }

    private saveNewAlignment(angle: number): Rx.Observable<void> {
        return this.viewModel.saveNewAlignment(this.state.selectedRadar!.id, angle).pipe(
            RxOperators.tap({
                complete: () => this.showSection(Section.Overview),
            }),
        );
    }

    private saveNewBlankingSectors(radarId: number, sectors: BlankingSector[]): Rx.Observable<void> {
        return this.viewModel.saveBlankingSectors(radarId, sectors).pipe(
            RxOperators.tap({
                complete: () => this.showSection(Section.Overview),
            }),
        );
    }

    private subscribeToErrors(): void {
        const subscription = this.viewModel.errorObservable.subscribe((error) => showError(error));
        this.collectSubscription(subscription);
    }

    private subscribeToRadars(): void {
        this.collectSubscriptions(
            this.viewModel.hasMultipleRadarsObservable.subscribe((hasMultipleRadars) => {
                this.setState({
                    hasMultipleRadars,
                });
            }),
            this.viewModel.selectedRadarObservable.subscribe((radar) => {
                if (radar === undefined) {
                    console.warn("List of radars is empty");
                    this.setState({
                        activePanel: Section.Loading,
                        selectedRadar: undefined,
                    });
                } else if (this.state.activePanel === Section.Loading) {
                    this.setState({ activePanel: Section.Overview, selectedRadar: radar });
                } else {
                    this.setState({ selectedRadar: radar });
                }
                if (radar && radar.status && radar.status.operatingMode) {
                    this.setState({ activeOperatingMode: radar.status.operatingMode });
                } else {
                    this.setState({ activeOperatingMode: undefined });
                }
            }),
        );
    }

    private subscribeToOperatingModes(): void {
        const subscription = this.viewModel.availableOperatingModesObservable.subscribe((availableOperatingModes) =>
            this.setState({ availableOperatingModes }),
        );
        this.collectSubscription(subscription);
    }

    private setOperatingMode(modeId: string): void {
        this.viewModel.setOperatingMode(modeId).subscribe();
    }

    private subscribeToIsDynamicPositioningEnabled(): void {
        const subscription = this.viewModel.isDynamicPositioningEnabled.subscribe((isDynamicPositioningEnabled) =>
            this.setState({
                canShowRepositionPanel: this.viewModel.canShowRepositionPanel && !isDynamicPositioningEnabled,
                canShowAlignmentPanel: this.viewModel.canShowAlignmentPanel && !isDynamicPositioningEnabled,
            }),
        );
        this.collectSubscription(subscription);
    }

    private autoPosition(): void {
        this.setState({ isAutoPositioning: true });
        const subscription = this.onRequestAutoPosition.subscribe({
            complete: () => this.setState({ isAutoPositioning: false }),
            error: (error) => {
                showError(error);
                this.setState({ isAutoPositioning: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private autoAlign(): void {
        this.setState({ isAutoAligning: true });
        const subscription = this.viewModel.autoAlignRadar(this.state.selectedRadar!.id).subscribe({
            complete: () => {
                this.setState({ isAutoAligning: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isAutoAligning: false });
            },
        });
        this.collectSubscription(subscription);
    }
}
