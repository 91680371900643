// source: atcinfo3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
goog.exportSymbol('proto.robin.proto3.atcinfo.Airbase', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.AirbaseData', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.AirbaseRequest', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.AirspaceInfringementAlert', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.AirspaceInfringementMsg', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.AtcAlertData', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.AtcAlertRequest', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.Runway', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayFunnel', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayIncursionAlert', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayIncursionMsg', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayIncursionMsg.AdditionaldataCase', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayTraffic', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayTrafficData', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayTrafficRequest', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.RunwayTrafficRequest.DateCase', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.TracksOnRunway', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.TracksOnRunwayData', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.TracksOnRunwayItem', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.TracksOnRunwayRequest', null, global);
goog.exportSymbol('proto.robin.proto3.atcinfo.TracksOnRunwayRequest.DateCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.robin.proto3.atcinfo.RunwayIncursionMsg.oneofGroups_);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayIncursionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayIncursionMsg.displayName = 'proto.robin.proto3.atcinfo.RunwayIncursionMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.displayName = 'proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.AirspaceInfringementMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.AirspaceInfringementMsg.displayName = 'proto.robin.proto3.atcinfo.AirspaceInfringementMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.Airbase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.Airbase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.Airbase.displayName = 'proto.robin.proto3.atcinfo.Airbase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.Runway = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.Runway, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.Runway.displayName = 'proto.robin.proto3.atcinfo.Runway';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayFunnel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayFunnel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayFunnel.displayName = 'proto.robin.proto3.atcinfo.RunwayFunnel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayTraffic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.RunwayTraffic.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayTraffic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayTraffic.displayName = 'proto.robin.proto3.atcinfo.RunwayTraffic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.displayName = 'proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.AtcAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.AtcAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.AtcAlertRequest.displayName = 'proto.robin.proto3.atcinfo.AtcAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.AtcAlertData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.AtcAlertData.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.AtcAlertData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.AtcAlertData.displayName = 'proto.robin.proto3.atcinfo.AtcAlertData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.AirbaseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.AirbaseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.AirbaseRequest.displayName = 'proto.robin.proto3.atcinfo.AirbaseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.AirbaseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.AirbaseData.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.AirbaseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.AirbaseData.displayName = 'proto.robin.proto3.atcinfo.AirbaseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.RunwayTrafficRequest.repeatedFields_, proto.robin.proto3.atcinfo.RunwayTrafficRequest.oneofGroups_);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayTrafficRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayTrafficRequest.displayName = 'proto.robin.proto3.atcinfo.RunwayTrafficRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.RunwayTrafficData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.RunwayTrafficData.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.RunwayTrafficData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.RunwayTrafficData.displayName = 'proto.robin.proto3.atcinfo.RunwayTrafficData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.TracksOnRunwayRequest.repeatedFields_, proto.robin.proto3.atcinfo.TracksOnRunwayRequest.oneofGroups_);
};
goog.inherits(proto.robin.proto3.atcinfo.TracksOnRunwayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.TracksOnRunwayRequest.displayName = 'proto.robin.proto3.atcinfo.TracksOnRunwayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.atcinfo.TracksOnRunwayItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.TracksOnRunwayItem.displayName = 'proto.robin.proto3.atcinfo.TracksOnRunwayItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.TracksOnRunway = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.TracksOnRunway.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.TracksOnRunway, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.TracksOnRunway.displayName = 'proto.robin.proto3.atcinfo.TracksOnRunway';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.atcinfo.TracksOnRunwayData.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.atcinfo.TracksOnRunwayData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.atcinfo.TracksOnRunwayData.displayName = 'proto.robin.proto3.atcinfo.TracksOnRunwayData';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.oneofGroups_ = [[15]];

/**
 * @enum {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.AdditionaldataCase = {
  ADDITIONALDATA_NOT_SET: 0,
  FINALAPPROACH: 15
};

/**
 * @return {proto.robin.proto3.atcinfo.RunwayIncursionMsg.AdditionaldataCase}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getAdditionaldataCase = function() {
  return /** @type {proto.robin.proto3.atcinfo.RunwayIncursionMsg.AdditionaldataCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.atcinfo.RunwayIncursionMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayIncursionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: jspb.Message.getFieldWithDefault(msg, 1, 0),
    runwayid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subjecttrackid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    subjecttrackicao: jspb.Message.getFieldWithDefault(msg, 11, 0),
    incursiontrackid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    incursiontrackicao: jspb.Message.getFieldWithDefault(msg, 13, 0),
    finalapproach: (f = msg.getFinalapproach()) && proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayIncursionMsg;
  return proto.robin.proto3.atcinfo.RunwayIncursionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.robin.proto3.atcinfo.RunwayIncursionAlert} */ (reader.readEnum());
      msg.setAlert(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRunwayid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSubjecttrackid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSubjecttrackicao(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setIncursiontrackid(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setIncursiontrackicao(value);
      break;
    case 15:
      var value = new proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData;
      reader.readMessage(value,proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.deserializeBinaryFromReader);
      msg.setFinalapproach(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayIncursionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRunwayid();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getSubjecttrackid();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getSubjecttrackicao();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
  f = message.getIncursiontrackid();
  if (f !== 0) {
    writer.writeSint32(
      12,
      f
    );
  }
  f = message.getIncursiontrackicao();
  if (f !== 0) {
    writer.writeSint32(
      13,
      f
    );
  }
  f = message.getFinalapproach();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.toObject = function(includeInstance, msg) {
  var f, obj = {
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData;
  return proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHeading(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float heading = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float distance = 2;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional RunwayIncursionAlert alert = 1;
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionAlert}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getAlert = function() {
  return /** @type {!proto.robin.proto3.atcinfo.RunwayIncursionAlert} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionAlert} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setAlert = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sint32 runwayId = 2;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getRunwayid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setRunwayid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 subjectTrackId = 10;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getSubjecttrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setSubjecttrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional sint32 subjectTrackIcao = 11;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getSubjecttrackicao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setSubjecttrackicao = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional sint32 incursionTrackId = 12;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getIncursiontrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setIncursiontrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional sint32 incursionTrackIcao = 13;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getIncursiontrackicao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setIncursiontrackicao = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional FinalApproachData finalApproach = 15;
 * @return {?proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.getFinalapproach = function() {
  return /** @type{?proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData, 15));
};


/**
 * @param {?proto.robin.proto3.atcinfo.RunwayIncursionMsg.FinalApproachData|undefined} value
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
*/
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.setFinalapproach = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.robin.proto3.atcinfo.RunwayIncursionMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.clearFinalapproach = function() {
  return this.setFinalapproach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.RunwayIncursionMsg.prototype.hasFinalapproach = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.AirspaceInfringementMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: jspb.Message.getFieldWithDefault(msg, 1, 0),
    aircrafttrackid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    aircrafttrackicao: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.AirspaceInfringementMsg;
  return proto.robin.proto3.atcinfo.AirspaceInfringementMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.robin.proto3.atcinfo.AirspaceInfringementAlert} */ (reader.readEnum());
      msg.setAlert(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAircrafttrackid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAircrafttrackicao(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.AirspaceInfringementMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAircrafttrackid();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getAircrafttrackicao();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
};


/**
 * optional AirspaceInfringementAlert alert = 1;
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementAlert}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.getAlert = function() {
  return /** @type {!proto.robin.proto3.atcinfo.AirspaceInfringementAlert} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.robin.proto3.atcinfo.AirspaceInfringementAlert} value
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg} returns this
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.setAlert = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sint32 aircraftTrackId = 10;
 * @return {number}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.getAircrafttrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg} returns this
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.setAircrafttrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional sint32 aircraftTrackIcao = 11;
 * @return {number}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.getAircrafttrackicao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg} returns this
 */
proto.robin.proto3.atcinfo.AirspaceInfringementMsg.prototype.setAircrafttrackicao = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.Airbase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.Airbase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.Airbase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    airbasename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    referenceposition: (f = msg.getReferenceposition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    ctrshapeid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hasfull3d: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.Airbase}
 */
proto.robin.proto3.atcinfo.Airbase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.Airbase;
  return proto.robin.proto3.atcinfo.Airbase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.Airbase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.Airbase}
 */
proto.robin.proto3.atcinfo.Airbase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirbasename(value);
      break;
    case 6:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setReferenceposition(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtrshapeid(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasfull3d(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.Airbase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.Airbase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.Airbase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getAirbasename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReferenceposition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getCtrshapeid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHasfull3d();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.Airbase} returns this
 */
proto.robin.proto3.atcinfo.Airbase.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string airBaseName = 2;
 * @return {string}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.getAirbasename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.atcinfo.Airbase} returns this
 */
proto.robin.proto3.atcinfo.Airbase.prototype.setAirbasename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional robin.proto3.LatLonAltPosition referencePosition = 6;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.getReferenceposition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 6));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.atcinfo.Airbase} returns this
*/
proto.robin.proto3.atcinfo.Airbase.prototype.setReferenceposition = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.atcinfo.Airbase} returns this
 */
proto.robin.proto3.atcinfo.Airbase.prototype.clearReferenceposition = function() {
  return this.setReferenceposition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.hasReferenceposition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string ctrShapeId = 10;
 * @return {string}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.getCtrshapeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.atcinfo.Airbase} returns this
 */
proto.robin.proto3.atcinfo.Airbase.prototype.setCtrshapeid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool hasFull3D = 11;
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.Airbase.prototype.getHasfull3d = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.atcinfo.Airbase} returns this
 */
proto.robin.proto3.atcinfo.Airbase.prototype.setHasfull3d = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.Runway.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.Runway.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.Runway} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.Runway.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    airbaseid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    runwayname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    runwayshapeid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.Runway}
 */
proto.robin.proto3.atcinfo.Runway.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.Runway;
  return proto.robin.proto3.atcinfo.Runway.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.Runway} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.Runway}
 */
proto.robin.proto3.atcinfo.Runway.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setAirbaseid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunwayname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunwayshapeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.Runway.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.Runway.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.Runway} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.Runway.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getAirbaseid();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getRunwayname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRunwayshapeid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.Runway.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.Runway} returns this
 */
proto.robin.proto3.atcinfo.Runway.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 airbaseId = 2;
 * @return {number}
 */
proto.robin.proto3.atcinfo.Runway.prototype.getAirbaseid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.Runway} returns this
 */
proto.robin.proto3.atcinfo.Runway.prototype.setAirbaseid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string runwayName = 3;
 * @return {string}
 */
proto.robin.proto3.atcinfo.Runway.prototype.getRunwayname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.atcinfo.Runway} returns this
 */
proto.robin.proto3.atcinfo.Runway.prototype.setRunwayname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string runwayShapeId = 5;
 * @return {string}
 */
proto.robin.proto3.atcinfo.Runway.prototype.getRunwayshapeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.atcinfo.Runway} returns this
 */
proto.robin.proto3.atcinfo.Runway.prototype.setRunwayshapeid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayFunnel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayFunnel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayFunnel.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwayid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pointa: (f = msg.getPointa()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    pointb: (f = msg.getPointb()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    runwayheight: jspb.Message.getFieldWithDefault(msg, 6, 0),
    runwaywidth: jspb.Message.getFieldWithDefault(msg, 8, 0),
    funnelheightmin: jspb.Message.getFieldWithDefault(msg, 10, 0),
    funnelheightmax: jspb.Message.getFieldWithDefault(msg, 11, 0),
    funnelwidth: jspb.Message.getFieldWithDefault(msg, 12, 0),
    funnellength: jspb.Message.getFieldWithDefault(msg, 13, 0),
    circuitheightmin: jspb.Message.getFieldWithDefault(msg, 21, 0),
    circuitheightmax: jspb.Message.getFieldWithDefault(msg, 22, 0),
    circuitwidth: jspb.Message.getFieldWithDefault(msg, 23, 0),
    circuitlength: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayFunnel;
  return proto.robin.proto3.atcinfo.RunwayFunnel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayFunnel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRunwayid(value);
      break;
    case 3:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPointa(value);
      break;
    case 4:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPointb(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRunwayheight(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRunwaywidth(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setFunnelheightmin(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setFunnelheightmax(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setFunnelwidth(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setFunnellength(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCircuitheightmin(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCircuitheightmax(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCircuitwidth(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCircuitlength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayFunnel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayFunnel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayFunnel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwayid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getPointa();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getPointb();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getRunwayheight();
  if (f !== 0) {
    writer.writeSint32(
      6,
      f
    );
  }
  f = message.getRunwaywidth();
  if (f !== 0) {
    writer.writeSint32(
      8,
      f
    );
  }
  f = message.getFunnelheightmin();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getFunnelheightmax();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
  f = message.getFunnelwidth();
  if (f !== 0) {
    writer.writeSint32(
      12,
      f
    );
  }
  f = message.getFunnellength();
  if (f !== 0) {
    writer.writeSint32(
      13,
      f
    );
  }
  f = message.getCircuitheightmin();
  if (f !== 0) {
    writer.writeSint32(
      21,
      f
    );
  }
  f = message.getCircuitheightmax();
  if (f !== 0) {
    writer.writeSint32(
      22,
      f
    );
  }
  f = message.getCircuitwidth();
  if (f !== 0) {
    writer.writeSint32(
      23,
      f
    );
  }
  f = message.getCircuitlength();
  if (f !== 0) {
    writer.writeSint32(
      24,
      f
    );
  }
};


/**
 * optional sint32 runwayId = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getRunwayid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setRunwayid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional robin.proto3.LatLonAltPosition pointA = 3;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getPointa = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 3));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
*/
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setPointa = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.clearPointa = function() {
  return this.setPointa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.hasPointa = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional robin.proto3.LatLonAltPosition pointB = 4;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getPointb = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 4));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
*/
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setPointb = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.clearPointb = function() {
  return this.setPointb(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.hasPointb = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sint32 runwayHeight = 6;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getRunwayheight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setRunwayheight = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional sint32 runwayWidth = 8;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getRunwaywidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setRunwaywidth = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional sint32 funnelHeightMin = 10;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getFunnelheightmin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setFunnelheightmin = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional sint32 funnelHeightMax = 11;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getFunnelheightmax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setFunnelheightmax = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional sint32 funnelWidth = 12;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getFunnelwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setFunnelwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional sint32 funnelLength = 13;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getFunnellength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setFunnellength = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional sint32 circuitHeightMin = 21;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getCircuitheightmin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setCircuitheightmin = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional sint32 circuitHeightMax = 22;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getCircuitheightmax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setCircuitheightmax = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional sint32 circuitWidth = 23;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getCircuitwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setCircuitwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional sint32 circuitLength = 24;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.getCircuitlength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel} returns this
 */
proto.robin.proto3.atcinfo.RunwayFunnel.prototype.setCircuitlength = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.RunwayTraffic.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayTraffic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTraffic.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwayid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sectortrafficrateList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    tracksonrunway: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tracksonrunwaylastminute: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sectortracksList: jspb.Message.toObjectList(msg.getSectortracksList(),
    proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayTraffic;
  return proto.robin.proto3.atcinfo.RunwayTraffic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRunwayid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSectortrafficrate(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTracksonrunway(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTracksonrunwaylastminute(value);
      break;
    case 4:
      var value = new proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg;
      reader.readMessage(value,proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.deserializeBinaryFromReader);
      msg.addSectortracks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayTraffic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTraffic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwayid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSectortrafficrateList();
  if (f.length > 0) {
    writer.writePackedFloat(
      3,
      f
    );
  }
  f = message.getTracksonrunway();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getTracksonrunwaylastminute();
  if (f !== 0) {
    writer.writeSint32(
      6,
      f
    );
  }
  f = message.getSectortracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    tracksList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    aircrafttracksList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg;
  return proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTracks(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAircrafttracks(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTracksList();
  if (f.length > 0) {
    writer.writePackedSint32(
      1,
      f
    );
  }
  f = message.getAircrafttracksList();
  if (f.length > 0) {
    writer.writePackedSint32(
      2,
      f
    );
  }
};


/**
 * repeated sint32 tracks = 1;
 * @return {!Array<number>}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.getTracksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.setTracksList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.addTracks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.clearTracksList = function() {
  return this.setTracksList([]);
};


/**
 * repeated sint32 aircraftTracks = 2;
 * @return {!Array<number>}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.getAircrafttracksList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.setAircrafttracksList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.addAircrafttracks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg.prototype.clearAircrafttracksList = function() {
  return this.setAircrafttracksList([]);
};


/**
 * optional sint32 runwayId = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.getRunwayid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.setRunwayid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timestamp_msec = 2;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated float sectorTrafficRate = 3;
 * @return {!Array<number>}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.getSectortrafficrateList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.setSectortrafficrateList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.addSectortrafficrate = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.clearSectortrafficrateList = function() {
  return this.setSectortrafficrateList([]);
};


/**
 * optional sint32 tracksOnRunway = 5;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.getTracksonrunway = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.setTracksonrunway = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional sint32 tracksOnRunwayLastMinute = 6;
 * @return {number}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.getTracksonrunwaylastminute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.setTracksonrunwaylastminute = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated SectorTracksMsg sectorTracks = 4;
 * @return {!Array<!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg>}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.getSectortracksList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg, 4));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg>} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
*/
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.setSectortracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg}
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.addSectortracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.robin.proto3.atcinfo.RunwayTraffic.SectorTracksMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic} returns this
 */
proto.robin.proto3.atcinfo.RunwayTraffic.prototype.clearSectortracksList = function() {
  return this.setSectortracksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.AtcAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.AtcAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.AtcAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AtcAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.AtcAlertRequest}
 */
proto.robin.proto3.atcinfo.AtcAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.AtcAlertRequest;
  return proto.robin.proto3.atcinfo.AtcAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.AtcAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.AtcAlertRequest}
 */
proto.robin.proto3.atcinfo.AtcAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.AtcAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.AtcAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.AtcAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AtcAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.AtcAlertData.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.AtcAlertData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.AtcAlertData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AtcAlertData.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwayincursionList: jspb.Message.toObjectList(msg.getRunwayincursionList(),
    proto.robin.proto3.atcinfo.RunwayIncursionMsg.toObject, includeInstance),
    airspaceinfringementList: jspb.Message.toObjectList(msg.getAirspaceinfringementList(),
    proto.robin.proto3.atcinfo.AirspaceInfringementMsg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.AtcAlertData}
 */
proto.robin.proto3.atcinfo.AtcAlertData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.AtcAlertData;
  return proto.robin.proto3.atcinfo.AtcAlertData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.AtcAlertData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.AtcAlertData}
 */
proto.robin.proto3.atcinfo.AtcAlertData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.atcinfo.RunwayIncursionMsg;
      reader.readMessage(value,proto.robin.proto3.atcinfo.RunwayIncursionMsg.deserializeBinaryFromReader);
      msg.addRunwayincursion(value);
      break;
    case 2:
      var value = new proto.robin.proto3.atcinfo.AirspaceInfringementMsg;
      reader.readMessage(value,proto.robin.proto3.atcinfo.AirspaceInfringementMsg.deserializeBinaryFromReader);
      msg.addAirspaceinfringement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.AtcAlertData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.AtcAlertData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AtcAlertData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwayincursionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.atcinfo.RunwayIncursionMsg.serializeBinaryToWriter
    );
  }
  f = message.getAirspaceinfringementList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.atcinfo.AirspaceInfringementMsg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RunwayIncursionMsg runwayIncursion = 1;
 * @return {!Array<!proto.robin.proto3.atcinfo.RunwayIncursionMsg>}
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.getRunwayincursionList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.RunwayIncursionMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.RunwayIncursionMsg, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.RunwayIncursionMsg>} value
 * @return {!proto.robin.proto3.atcinfo.AtcAlertData} returns this
*/
proto.robin.proto3.atcinfo.AtcAlertData.prototype.setRunwayincursionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.RunwayIncursionMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayIncursionMsg}
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.addRunwayincursion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.atcinfo.RunwayIncursionMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.AtcAlertData} returns this
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.clearRunwayincursionList = function() {
  return this.setRunwayincursionList([]);
};


/**
 * repeated AirspaceInfringementMsg airspaceInfringement = 2;
 * @return {!Array<!proto.robin.proto3.atcinfo.AirspaceInfringementMsg>}
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.getAirspaceinfringementList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.AirspaceInfringementMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.AirspaceInfringementMsg, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.AirspaceInfringementMsg>} value
 * @return {!proto.robin.proto3.atcinfo.AtcAlertData} returns this
*/
proto.robin.proto3.atcinfo.AtcAlertData.prototype.setAirspaceinfringementList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.AirspaceInfringementMsg}
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.addAirspaceinfringement = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.atcinfo.AirspaceInfringementMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.AtcAlertData} returns this
 */
proto.robin.proto3.atcinfo.AtcAlertData.prototype.clearAirspaceinfringementList = function() {
  return this.setAirspaceinfringementList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.AirbaseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.AirbaseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.AirbaseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AirbaseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.AirbaseRequest}
 */
proto.robin.proto3.atcinfo.AirbaseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.AirbaseRequest;
  return proto.robin.proto3.atcinfo.AirbaseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.AirbaseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.AirbaseRequest}
 */
proto.robin.proto3.atcinfo.AirbaseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.AirbaseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.AirbaseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.AirbaseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AirbaseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.AirbaseData.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.AirbaseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.AirbaseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AirbaseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    airbaseList: jspb.Message.toObjectList(msg.getAirbaseList(),
    proto.robin.proto3.atcinfo.Airbase.toObject, includeInstance),
    runwayList: jspb.Message.toObjectList(msg.getRunwayList(),
    proto.robin.proto3.atcinfo.Runway.toObject, includeInstance),
    runwayfunnelList: jspb.Message.toObjectList(msg.getRunwayfunnelList(),
    proto.robin.proto3.atcinfo.RunwayFunnel.toObject, includeInstance),
    preferedairbaseid: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.AirbaseData}
 */
proto.robin.proto3.atcinfo.AirbaseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.AirbaseData;
  return proto.robin.proto3.atcinfo.AirbaseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.AirbaseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.AirbaseData}
 */
proto.robin.proto3.atcinfo.AirbaseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.atcinfo.Airbase;
      reader.readMessage(value,proto.robin.proto3.atcinfo.Airbase.deserializeBinaryFromReader);
      msg.addAirbase(value);
      break;
    case 2:
      var value = new proto.robin.proto3.atcinfo.Runway;
      reader.readMessage(value,proto.robin.proto3.atcinfo.Runway.deserializeBinaryFromReader);
      msg.addRunway(value);
      break;
    case 3:
      var value = new proto.robin.proto3.atcinfo.RunwayFunnel;
      reader.readMessage(value,proto.robin.proto3.atcinfo.RunwayFunnel.deserializeBinaryFromReader);
      msg.addRunwayfunnel(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setPreferedairbaseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.AirbaseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.AirbaseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.AirbaseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirbaseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.atcinfo.Airbase.serializeBinaryToWriter
    );
  }
  f = message.getRunwayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.atcinfo.Runway.serializeBinaryToWriter
    );
  }
  f = message.getRunwayfunnelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.atcinfo.RunwayFunnel.serializeBinaryToWriter
    );
  }
  f = message.getPreferedairbaseid();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
};


/**
 * repeated Airbase airbase = 1;
 * @return {!Array<!proto.robin.proto3.atcinfo.Airbase>}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.getAirbaseList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.Airbase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.Airbase, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.Airbase>} value
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
*/
proto.robin.proto3.atcinfo.AirbaseData.prototype.setAirbaseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.Airbase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.Airbase}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.addAirbase = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.atcinfo.Airbase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.clearAirbaseList = function() {
  return this.setAirbaseList([]);
};


/**
 * repeated Runway runway = 2;
 * @return {!Array<!proto.robin.proto3.atcinfo.Runway>}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.getRunwayList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.Runway>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.Runway, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.Runway>} value
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
*/
proto.robin.proto3.atcinfo.AirbaseData.prototype.setRunwayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.Runway=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.Runway}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.addRunway = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.atcinfo.Runway, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.clearRunwayList = function() {
  return this.setRunwayList([]);
};


/**
 * repeated RunwayFunnel runwayFunnel = 3;
 * @return {!Array<!proto.robin.proto3.atcinfo.RunwayFunnel>}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.getRunwayfunnelList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.RunwayFunnel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.RunwayFunnel, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.RunwayFunnel>} value
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
*/
proto.robin.proto3.atcinfo.AirbaseData.prototype.setRunwayfunnelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.RunwayFunnel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayFunnel}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.addRunwayfunnel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.atcinfo.RunwayFunnel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.clearRunwayfunnelList = function() {
  return this.setRunwayfunnelList([]);
};


/**
 * optional sint32 preferedAirbaseId = 10;
 * @return {number}
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.getPreferedairbaseid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.AirbaseData} returns this
 */
proto.robin.proto3.atcinfo.AirbaseData.prototype.setPreferedairbaseid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.DateCase = {
  _DATE_NOT_SET: 0,
  DATE: 3
};

/**
 * @return {proto.robin.proto3.atcinfo.RunwayTrafficRequest.DateCase}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.getDateCase = function() {
  return /** @type {proto.robin.proto3.atcinfo.RunwayTrafficRequest.DateCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.atcinfo.RunwayTrafficRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayTrafficRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwayidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    tracksinsectors: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    date: (f = msg.getDate()) && common3_pb.DateTimeRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayTrafficRequest;
  return proto.robin.proto3.atcinfo.RunwayTrafficRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRunwayid(values[i]);
      }
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTracksinsectors(value);
      break;
    case 3:
      var value = new common3_pb.DateTimeRange;
      reader.readMessage(value,common3_pb.DateTimeRange.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayTrafficRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwayidList();
  if (f.length > 0) {
    writer.writePackedSint32(
      1,
      f
    );
  }
  f = message.getTracksinsectors();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common3_pb.DateTimeRange.serializeBinaryToWriter
    );
  }
};


/**
 * repeated sint32 runwayId = 1;
 * @return {!Array<number>}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.getRunwayidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} returns this
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.setRunwayidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} returns this
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.addRunwayid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} returns this
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.clearRunwayidList = function() {
  return this.setRunwayidList([]);
};


/**
 * optional bool tracksInSectors = 2;
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.getTracksinsectors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} returns this
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.setTracksinsectors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional robin.proto3.DateTimeRange date = 3;
 * @return {?proto.robin.proto3.DateTimeRange}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.getDate = function() {
  return /** @type{?proto.robin.proto3.DateTimeRange} */ (
    jspb.Message.getWrapperField(this, common3_pb.DateTimeRange, 3));
};


/**
 * @param {?proto.robin.proto3.DateTimeRange|undefined} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} returns this
*/
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.setDate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.robin.proto3.atcinfo.RunwayTrafficRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficRequest} returns this
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.RunwayTrafficRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.RunwayTrafficData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.RunwayTrafficData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwaytrafficList: jspb.Message.toObjectList(msg.getRunwaytrafficList(),
    proto.robin.proto3.atcinfo.RunwayTraffic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficData}
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.RunwayTrafficData;
  return proto.robin.proto3.atcinfo.RunwayTrafficData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.RunwayTrafficData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficData}
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.atcinfo.RunwayTraffic;
      reader.readMessage(value,proto.robin.proto3.atcinfo.RunwayTraffic.deserializeBinaryFromReader);
      msg.addRunwaytraffic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.RunwayTrafficData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.RunwayTrafficData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwaytrafficList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.atcinfo.RunwayTraffic.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RunwayTraffic runwayTraffic = 1;
 * @return {!Array<!proto.robin.proto3.atcinfo.RunwayTraffic>}
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.prototype.getRunwaytrafficList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.RunwayTraffic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.RunwayTraffic, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.RunwayTraffic>} value
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficData} returns this
*/
proto.robin.proto3.atcinfo.RunwayTrafficData.prototype.setRunwaytrafficList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.RunwayTraffic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.RunwayTraffic}
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.prototype.addRunwaytraffic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.atcinfo.RunwayTraffic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.RunwayTrafficData} returns this
 */
proto.robin.proto3.atcinfo.RunwayTrafficData.prototype.clearRunwaytrafficList = function() {
  return this.setRunwaytrafficList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.repeatedFields_ = [1];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.DateCase = {
  _DATE_NOT_SET: 0,
  DATE: 3
};

/**
 * @return {proto.robin.proto3.atcinfo.TracksOnRunwayRequest.DateCase}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.getDateCase = function() {
  return /** @type {proto.robin.proto3.atcinfo.TracksOnRunwayRequest.DateCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.atcinfo.TracksOnRunwayRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.TracksOnRunwayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwayidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    date: (f = msg.getDate()) && common3_pb.DateTimeRange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.TracksOnRunwayRequest;
  return proto.robin.proto3.atcinfo.TracksOnRunwayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRunwayid(values[i]);
      }
      break;
    case 3:
      var value = new common3_pb.DateTimeRange;
      reader.readMessage(value,common3_pb.DateTimeRange.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.TracksOnRunwayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwayidList();
  if (f.length > 0) {
    writer.writePackedSint32(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common3_pb.DateTimeRange.serializeBinaryToWriter
    );
  }
};


/**
 * repeated sint32 runwayId = 1;
 * @return {!Array<number>}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.getRunwayidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.setRunwayidList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.addRunwayid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.clearRunwayidList = function() {
  return this.setRunwayidList([]);
};


/**
 * optional robin.proto3.DateTimeRange date = 3;
 * @return {?proto.robin.proto3.DateTimeRange}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.getDate = function() {
  return /** @type{?proto.robin.proto3.DateTimeRange} */ (
    jspb.Message.getWrapperField(this, common3_pb.DateTimeRange, 3));
};


/**
 * @param {?proto.robin.proto3.DateTimeRange|undefined} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} returns this
*/
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.setDate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.robin.proto3.atcinfo.TracksOnRunwayRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayRequest} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.TracksOnRunwayItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tracksonrunway: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayItem}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.TracksOnRunwayItem;
  return proto.robin.proto3.atcinfo.TracksOnRunwayItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayItem}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTracksonrunway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.TracksOnRunwayItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTracksonrunway();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayItem} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 tracksOnRunway = 2;
 * @return {number}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.prototype.getTracksonrunway = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayItem} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayItem.prototype.setTracksonrunway = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.TracksOnRunway.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.TracksOnRunway.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunway} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunway.toObject = function(includeInstance, msg) {
  var f, obj = {
    runwayid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tracksonrunwayitemsList: jspb.Message.toObjectList(msg.getTracksonrunwayitemsList(),
    proto.robin.proto3.atcinfo.TracksOnRunwayItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunway}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.TracksOnRunway;
  return proto.robin.proto3.atcinfo.TracksOnRunway.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunway} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunway}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRunwayid(value);
      break;
    case 2:
      var value = new proto.robin.proto3.atcinfo.TracksOnRunwayItem;
      reader.readMessage(value,proto.robin.proto3.atcinfo.TracksOnRunwayItem.deserializeBinaryFromReader);
      msg.addTracksonrunwayitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.TracksOnRunway.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunway} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunway.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunwayid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getTracksonrunwayitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.atcinfo.TracksOnRunwayItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint32 runwayId = 1;
 * @return {number}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.getRunwayid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunway} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.setRunwayid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated TracksOnRunwayItem tracksOnRunwayItems = 2;
 * @return {!Array<!proto.robin.proto3.atcinfo.TracksOnRunwayItem>}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.getTracksonrunwayitemsList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.TracksOnRunwayItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.TracksOnRunwayItem, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.TracksOnRunwayItem>} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunway} returns this
*/
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.setTracksonrunwayitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayItem}
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.addTracksonrunwayitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.atcinfo.TracksOnRunwayItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunway} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunway.prototype.clearTracksonrunwayitemsList = function() {
  return this.setTracksonrunwayitemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.atcinfo.TracksOnRunwayData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.toObject = function(includeInstance, msg) {
  var f, obj = {
    tracksonrunwaysList: jspb.Message.toObjectList(msg.getTracksonrunwaysList(),
    proto.robin.proto3.atcinfo.TracksOnRunway.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayData}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.atcinfo.TracksOnRunwayData;
  return proto.robin.proto3.atcinfo.TracksOnRunwayData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayData}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.robin.proto3.atcinfo.TracksOnRunway;
      reader.readMessage(value,proto.robin.proto3.atcinfo.TracksOnRunway.deserializeBinaryFromReader);
      msg.addTracksonrunways(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.atcinfo.TracksOnRunwayData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunwayData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTracksonrunwaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.atcinfo.TracksOnRunway.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TracksOnRunway tracksOnRunways = 2;
 * @return {!Array<!proto.robin.proto3.atcinfo.TracksOnRunway>}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.prototype.getTracksonrunwaysList = function() {
  return /** @type{!Array<!proto.robin.proto3.atcinfo.TracksOnRunway>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.atcinfo.TracksOnRunway, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.atcinfo.TracksOnRunway>} value
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayData} returns this
*/
proto.robin.proto3.atcinfo.TracksOnRunwayData.prototype.setTracksonrunwaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.atcinfo.TracksOnRunway=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunway}
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.prototype.addTracksonrunways = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.atcinfo.TracksOnRunway, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.atcinfo.TracksOnRunwayData} returns this
 */
proto.robin.proto3.atcinfo.TracksOnRunwayData.prototype.clearTracksonrunwaysList = function() {
  return this.setTracksonrunwaysList([]);
};


/**
 * @enum {number}
 */
proto.robin.proto3.atcinfo.RunwayIncursionAlert = {
  NORIALERT: 0,
  BLUEALERT: 1,
  REDALERT: 2,
  NOADSBCAUTIONALERT: 10,
  NOADSBTAXIWAYALERT: 11,
  INCURSIONALERT: 20,
  FINALAPPROACHALERT: 21
};

/**
 * @enum {number}
 */
proto.robin.proto3.atcinfo.AirspaceInfringementAlert = {
  NOAIALERT: 0,
  ADSBTRANSPONDERALERT: 1
};

goog.object.extend(exports, proto.robin.proto3.atcinfo);
