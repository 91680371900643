import { DeterrenceDevice } from ".";
import { DeterrenceDeviceList as DeterrenceDeviceListProto } from "./proto/generated/deterrencedevices3_pb";

export class DeterrenceDeviceList {
    // Static functions

    public static fromProto(model: DeterrenceDeviceListProto): DeterrenceDeviceList {
        return new DeterrenceDeviceList(
            model.getDeviceList().map(DeterrenceDevice.fromProto),
            model.getAllowDeterrenceAction(),
        );
    }

    // Public functions

    public constructor(
        public readonly deterrenceDevices: DeterrenceDevice[],
        public readonly allowDeterrenceAction: boolean,
    ) {}
}
