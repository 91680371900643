import styled from "styled-components";

/**
 * @deprecated Use `FormGroup` instead
 */
export const PanelSubtitle = styled.span<{ color?: string }>`
    margin-top: 5px;
    font-size: 14px;
    line-height: 2.14;
    font-weight: 500;
    color: ${({ color, theme }) => color || theme.colors.text.text};
    text-transform: uppercase;
`;
