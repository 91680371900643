import React, { useEffect } from "react";
import { FullscreenLoading } from "../appearance/FullscreenLoading";
import { useBasicAuth } from "./useBasicAuth";

/**
 * Renders a loading page while calling login with empty username and password.
 */
export const NoAuthForm = (): JSX.Element => {
    const { login } = useBasicAuth({});

    useEffect(() => {
        login("", "");
    }, []);

    return <FullscreenLoading />;
};
