import styled from "styled-components";

/**
 * @deprecated Use `forms/FormControl` instead.
 */
export const FormInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    input {
        width: 100%;
    }
`;
