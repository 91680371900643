import styled from "styled-components";
import { SIDEBAR_WIDTH } from "../Sidebar";
import { TOOLBAR_WIDTH } from "../../toolbar/Toolbar";

export const OverlaysRoot = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-left: ${TOOLBAR_WIDTH};
    margin-right: ${SIDEBAR_WIDTH};
`;
