import { SettingsItemSection } from "../SettingsItemSection";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SettingItemViewModel } from "../SettingItemViewModel";
import { LocalPreferencesRepository, RadarRepository } from "../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../domain/model";
import { t } from "i18next";
import { IconSwitchItemViewModel } from "../SettingItemViewModel";
import { SettingItemIcon } from "../SettingItemIcon";
import RingsIcon from "../../../res/images/settings/range_rings.svg";
import { ReactComponent as EyeIcon } from "../../../res/images/icons/eye.svg";
import { combinedBooleanObservable, nonNullObservable } from "../../../utils/RxUtils";
import { cleanupRadarsByDistance } from "../../../utils/CleanUpRadarsByDistance";

export class CommonRangeRingsSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "range-rings-settings";
    public readonly label: string | null = t("settings.rangeRings");

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly radarRepository: RadarRepository,
    ) {}

    // Public functions

    public generate(): Rx.Observable<SettingItemViewModel[]> {
        return this.radarRepository.radars.pipe(
            RxOperators.map(cleanupRadarsByDistance),
            RxOperators.map((radars) => {
                const itemKeys: string[] = [];
                const itemNames: string[] = [];
                radars.forEach((radar) => {
                    itemKeys.push(LocalUserPreferenceKeys.filters.showRadarRange + "-" + radar.id);
                    itemNames.push(radar.name);
                });
                itemKeys.push(LocalUserPreferenceKeys.filters.showUserLocationRange);
                itemNames.push(t("settings.showUserRangeRings"));
                return [
                    this.makeRangeRingsToggleAllViewModel(itemKeys),
                    ...itemKeys.map((key, index) =>
                        this.makeRangeRingsToggleViewModel(itemNames[index], key, itemKeys),
                    ),
                ];
            }),
        );
    }

    private makeRangeRingsToggleViewModel(
        title: string,
        preferenceKey: string,
        allPreferenceKeys: string[],
    ): IconSwitchItemViewModel {
        return new IconSwitchItemViewModel(title, SettingItemIcon.withUrl(RingsIcon), [
            {
                key: "rangeRingsEnabled",
                onChange: (value: boolean) => {
                    // Set this specific value to the value of the toggle
                    this.localPreferencesRepository.setPreference(preferenceKey, value);
                    // If the new value is false, make sure the global value is also false
                    if (!value) {
                        this.localPreferencesRepository.setPreference(
                            LocalUserPreferenceKeys.filters.showRadarRange,
                            false,
                        );
                        return;
                    }
                    // If the new value is true, check if all values are true and set the global value to true if so
                    const allValues = [...this.localPreferencesRepository.getPreferences(allPreferenceKeys).values()];
                    if (allValues.every((value) => value)) {
                        this.localPreferencesRepository.setPreference(
                            LocalUserPreferenceKeys.filters.showRadarRange,
                            true,
                        );
                    }
                },
                icon: EyeIcon,
                // Fall back to the global value if the specific value is not set
                observableValue: combinedBooleanObservable(
                    this.localPreferencesRepository.observePreferences<boolean>([
                        preferenceKey,
                        LocalUserPreferenceKeys.filters.showRadarRange,
                    ]),
                    ([radarValue, globalValue]) => radarValue ?? globalValue ?? false,
                ),
            },
        ]);
    }

    private makeRangeRingsToggleAllViewModel(allPreferenceKeys: string[]): IconSwitchItemViewModel {
        return new IconSwitchItemViewModel(t("settings.showRangeRings"), SettingItemIcon.withUrl(RingsIcon), [
            {
                key: "rangeRingsEnabled",
                onChange: (value: boolean) => {
                    // Set the global value and all individual values to the value of the toggle
                    this.localPreferencesRepository.setPreferences(
                        [LocalUserPreferenceKeys.filters.showRadarRange, ...allPreferenceKeys],
                        value,
                    );
                },
                icon: EyeIcon,
                observableValue: nonNullObservable(
                    this.localPreferencesRepository.observePreference<boolean>(
                        LocalUserPreferenceKeys.filters.showRadarRange,
                    ),
                ),
            },
        ]);
    }
}
