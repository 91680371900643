import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { MapModuleViewModel } from "../MapModuleViewModel";
import * as Rx from "rxjs";

export class DimColorModuleViewModel extends MapModuleViewModel {
    // Properties

    public get mapBrightness(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.mapBrightness),
        );
    }

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {
        super();
    }
}
