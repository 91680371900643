import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { DefaultValueProvider } from "../../DefaultValueProvider";
import { LocalPreferencesRepository, PreferenceUpdate } from "../LocalPreferencesRepository";

export class CommonLocalPreferencesRepository implements LocalPreferencesRepository {
    // Properties

    private readonly updateSubject = new Rx.Subject<PreferenceUpdate>();

    public constructor(private readonly defaultValueProvider: DefaultValueProvider) {}

    // Public functions

    public getAllKeys(): string[] {
        const keys: string[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key != null) {
                keys.push(key);
            }
        }
        return keys;
    }

    public getAll(): Map<string, string> {
        const items = new Map<string, string>();
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key != null) {
                items.set(key, localStorage.getItem(key)!);
            }
        }
        return items;
    }

    public getPreference<T>(key: string, overrideDefaultValue?: T | null | undefined): T | null {
        const resultString = localStorage.getItem(key);
        if (resultString == null) {
            if (overrideDefaultValue == null) {
                return this.defaultValueProvider.getValue(key);
            }
            return overrideDefaultValue;
        }
        return JSON.parse(resultString) as T | null;
    }

    public getPreferences<T>(keys: string[], overrideDefaultValue?: T): Map<string, T | null> {
        const items = new Map<string, T | null>();
        keys.forEach((key) => {
            items.set(key, this.getPreference<T>(key, overrideDefaultValue));
        });
        return items;
    }

    public getPreferencesMatching<T>(keyMatcher: RegExp, overrideDefaultValue?: T): Map<string, T | null> {
        const keys = this.getAllKeys().filter((key) => keyMatcher.test(key));
        return this.getPreferences(keys, overrideDefaultValue);
    }

    public setPreference<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
        this.updateSubject.next({ key: key, value: value });
    }

    public setPreferences<T>(keys: string[], value: T): void {
        keys.forEach((key) => {
            localStorage.setItem(key, JSON.stringify(value));
            this.updateSubject.next({ key: key, value: value });
        });
    }

    public setPreferencesMatching<T>(keyMatcher: RegExp, value: T): void {
        const keys = this.getAllKeys().filter((key) => keyMatcher.test(key));
        this.setPreferences(keys, value);
    }

    public removePreference(key: string): void {
        localStorage.removeItem(key);
        this.updateSubject.next({ key: key, value: null });
    }

    public clearAllPreferences(): void {
        let i = 0;
        while (i < localStorage.length) {
            const key = localStorage.key(i);
            if (key == null) {
                break;
            }
            this.updateSubject.next({ key: key, value: null });
            i++;
        }
        localStorage.clear();
    }

    public observePreference<T>(key: string, defaultValue?: T): Rx.Observable<T | null> {
        const broadcastSubject = this.updateSubject.asObservable().pipe(
            RxOperators.filter((update) => update.key === key),
            RxOperators.map((p) => p.value as T | null),
            RxOperators.distinctUntilChanged(),
        );
        const initialValue = new Rx.Observable<T | null>((emitter) => {
            emitter.next(this.getPreference<T>(key));
            emitter.complete();
        });
        const preferenceObservable = Rx.concat(initialValue, broadcastSubject);
        if (defaultValue === undefined) {
            return preferenceObservable;
        }
        return preferenceObservable.pipe(RxOperators.map((v) => (v == null ? defaultValue : v)));
    }

    public observePreferences<T>(keys: string[], defaultValue?: T): Rx.Observable<(T | null)[]> {
        return Rx.combineLatest(keys.map((key) => this.observePreference<T>(key, defaultValue)));
    }
}
