import { Species as SpeciesProto } from "./proto/generated/logger3_pb";

export class Species {
    // Static functions

    public static fromProto(model: SpeciesProto): Species {
        return new Species(model.getSpeciesid(), model.getCommonname());
    }

    public constructor(public readonly id: int, public readonly name: string) {}
}
