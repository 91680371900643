import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
    return input !== null && input !== undefined;
}

//todo: if nonNullobservale relies on default value to provide non-null value, then default value should not be optional
export function nonNullObservable<T>(source: Rx.Observable<null | undefined | T>, defaultValue?: T): Rx.Observable<T> {
    return source.pipe(
        RxOperators.map((v) => (v == null ? defaultValue : v)),
        RxOperators.filter(inputIsNotNullOrUndefined),
        RxOperators.map((v) => v!),
    );
}

/**
 * Publishes the first element immediately and debounces every element after that
 */
export function debounceTimeExceptFirst<T>(source: Rx.Observable<T>, durationMillis: number): Rx.Observable<T> {
    return Rx.merge(
        source.pipe(RxOperators.take(1)), // Immediately publish the first element
        source.pipe(RxOperators.skip(1), RxOperators.debounceTime(durationMillis)), // Debounce the rest
    );
}

/**
 * Combines the latest boolean values from the source observable and applies the result selector to them.
 * By default the result selector returns true if all values are true and false otherwise.
 */
export function combinedBooleanObservable(
    source: Rx.Observable<(boolean | null)[]>,
    resultSelector: (values: (boolean | null)[]) => boolean = (values) => values.every((value) => value),
): Rx.Observable<boolean> {
    return source.pipe(
        RxOperators.map((values) => resultSelector(values)),
        RxOperators.distinctUntilChanged(),
    );
}
