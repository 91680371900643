// source: overlaylist3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
goog.exportSymbol('proto.robin.proto3.overlaylist.CircleShape', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.Overlay', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.OverlayList', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.PointShape', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.PolygonShape', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.PolylineShape', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.Shape', null, global);
goog.exportSymbol('proto.robin.proto3.overlaylist.Shape.SubshapeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.PointShape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.overlaylist.PointShape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.PointShape.displayName = 'proto.robin.proto3.overlaylist.PointShape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.CircleShape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.overlaylist.CircleShape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.CircleShape.displayName = 'proto.robin.proto3.overlaylist.CircleShape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.PolylineShape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.overlaylist.PolylineShape.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.overlaylist.PolylineShape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.PolylineShape.displayName = 'proto.robin.proto3.overlaylist.PolylineShape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.PolygonShape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.overlaylist.PolygonShape.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.overlaylist.PolygonShape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.PolygonShape.displayName = 'proto.robin.proto3.overlaylist.PolygonShape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.Shape = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.robin.proto3.overlaylist.Shape.oneofGroups_);
};
goog.inherits(proto.robin.proto3.overlaylist.Shape, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.Shape.displayName = 'proto.robin.proto3.overlaylist.Shape';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.Overlay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.overlaylist.Overlay.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.overlaylist.Overlay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.Overlay.displayName = 'proto.robin.proto3.overlaylist.Overlay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.overlaylist.OverlayList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.overlaylist.OverlayList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.overlaylist.OverlayList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.overlaylist.OverlayList.displayName = 'proto.robin.proto3.overlaylist.OverlayList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.PointShape.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.PointShape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.PointShape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.PointShape.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.PointShape}
 */
proto.robin.proto3.overlaylist.PointShape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.PointShape;
  return proto.robin.proto3.overlaylist.PointShape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.PointShape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.PointShape}
 */
proto.robin.proto3.overlaylist.PointShape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.PointShape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.PointShape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.PointShape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.PointShape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional robin.proto3.LatLonAltPosition position = 1;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.overlaylist.PointShape.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 1));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.overlaylist.PointShape} returns this
*/
proto.robin.proto3.overlaylist.PointShape.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.overlaylist.PointShape} returns this
 */
proto.robin.proto3.overlaylist.PointShape.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.overlaylist.PointShape.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.CircleShape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.CircleShape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.CircleShape.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.CircleShape}
 */
proto.robin.proto3.overlaylist.CircleShape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.CircleShape;
  return proto.robin.proto3.overlaylist.CircleShape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.CircleShape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.CircleShape}
 */
proto.robin.proto3.overlaylist.CircleShape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.CircleShape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.CircleShape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.CircleShape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional robin.proto3.LatLonAltPosition position = 1;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 1));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.overlaylist.CircleShape} returns this
*/
proto.robin.proto3.overlaylist.CircleShape.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.overlaylist.CircleShape} returns this
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float radius = 2;
 * @return {number}
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.overlaylist.CircleShape} returns this
 */
proto.robin.proto3.overlaylist.CircleShape.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.overlaylist.PolylineShape.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.PolylineShape.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.PolylineShape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.PolylineShape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.PolylineShape.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionList: jspb.Message.toObjectList(msg.getPositionList(),
    common3_pb.LatLonAltPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.PolylineShape}
 */
proto.robin.proto3.overlaylist.PolylineShape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.PolylineShape;
  return proto.robin.proto3.overlaylist.PolylineShape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.PolylineShape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.PolylineShape}
 */
proto.robin.proto3.overlaylist.PolylineShape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.addPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.PolylineShape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.PolylineShape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.PolylineShape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.PolylineShape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated robin.proto3.LatLonAltPosition position = 1;
 * @return {!Array<!proto.robin.proto3.LatLonAltPosition>}
 */
proto.robin.proto3.overlaylist.PolylineShape.prototype.getPositionList = function() {
  return /** @type{!Array<!proto.robin.proto3.LatLonAltPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, common3_pb.LatLonAltPosition, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.LatLonAltPosition>} value
 * @return {!proto.robin.proto3.overlaylist.PolylineShape} returns this
*/
proto.robin.proto3.overlaylist.PolylineShape.prototype.setPositionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.LatLonAltPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.overlaylist.PolylineShape.prototype.addPosition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.LatLonAltPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.overlaylist.PolylineShape} returns this
 */
proto.robin.proto3.overlaylist.PolylineShape.prototype.clearPositionList = function() {
  return this.setPositionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.overlaylist.PolygonShape.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.PolygonShape.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.PolygonShape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.PolygonShape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.PolygonShape.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionList: jspb.Message.toObjectList(msg.getPositionList(),
    common3_pb.LatLonAltPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.PolygonShape}
 */
proto.robin.proto3.overlaylist.PolygonShape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.PolygonShape;
  return proto.robin.proto3.overlaylist.PolygonShape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.PolygonShape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.PolygonShape}
 */
proto.robin.proto3.overlaylist.PolygonShape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.addPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.PolygonShape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.PolygonShape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.PolygonShape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.PolygonShape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated robin.proto3.LatLonAltPosition position = 1;
 * @return {!Array<!proto.robin.proto3.LatLonAltPosition>}
 */
proto.robin.proto3.overlaylist.PolygonShape.prototype.getPositionList = function() {
  return /** @type{!Array<!proto.robin.proto3.LatLonAltPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, common3_pb.LatLonAltPosition, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.LatLonAltPosition>} value
 * @return {!proto.robin.proto3.overlaylist.PolygonShape} returns this
*/
proto.robin.proto3.overlaylist.PolygonShape.prototype.setPositionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.LatLonAltPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.overlaylist.PolygonShape.prototype.addPosition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.LatLonAltPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.overlaylist.PolygonShape} returns this
 */
proto.robin.proto3.overlaylist.PolygonShape.prototype.clearPositionList = function() {
  return this.setPositionList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.overlaylist.Shape.oneofGroups_ = [[13,14,15,16]];

/**
 * @enum {number}
 */
proto.robin.proto3.overlaylist.Shape.SubshapeCase = {
  SUBSHAPE_NOT_SET: 0,
  POINTSHAPE: 13,
  POLYLINESHAPE: 14,
  POLYGONSHAPE: 15,
  CIRCLESHAPE: 16
};

/**
 * @return {proto.robin.proto3.overlaylist.Shape.SubshapeCase}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getSubshapeCase = function() {
  return /** @type {proto.robin.proto3.overlaylist.Shape.SubshapeCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.overlaylist.Shape.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.Shape.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.Shape.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.Shape} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.Shape.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linecolor: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fillcolor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shapetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pointshape: (f = msg.getPointshape()) && proto.robin.proto3.overlaylist.PointShape.toObject(includeInstance, f),
    polylineshape: (f = msg.getPolylineshape()) && proto.robin.proto3.overlaylist.PolylineShape.toObject(includeInstance, f),
    polygonshape: (f = msg.getPolygonshape()) && proto.robin.proto3.overlaylist.PolygonShape.toObject(includeInstance, f),
    circleshape: (f = msg.getCircleshape()) && proto.robin.proto3.overlaylist.CircleShape.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.Shape}
 */
proto.robin.proto3.overlaylist.Shape.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.Shape;
  return proto.robin.proto3.overlaylist.Shape.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.Shape} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.Shape}
 */
proto.robin.proto3.overlaylist.Shape.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinecolor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFillcolor(value);
      break;
    case 5:
      var value = /** @type {!proto.robin.proto3.ShapeType} */ (reader.readEnum());
      msg.setShapetype(value);
      break;
    case 13:
      var value = new proto.robin.proto3.overlaylist.PointShape;
      reader.readMessage(value,proto.robin.proto3.overlaylist.PointShape.deserializeBinaryFromReader);
      msg.setPointshape(value);
      break;
    case 14:
      var value = new proto.robin.proto3.overlaylist.PolylineShape;
      reader.readMessage(value,proto.robin.proto3.overlaylist.PolylineShape.deserializeBinaryFromReader);
      msg.setPolylineshape(value);
      break;
    case 15:
      var value = new proto.robin.proto3.overlaylist.PolygonShape;
      reader.readMessage(value,proto.robin.proto3.overlaylist.PolygonShape.deserializeBinaryFromReader);
      msg.setPolygonshape(value);
      break;
    case 16:
      var value = new proto.robin.proto3.overlaylist.CircleShape;
      reader.readMessage(value,proto.robin.proto3.overlaylist.CircleShape.deserializeBinaryFromReader);
      msg.setCircleshape(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.Shape.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.Shape.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.Shape} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.Shape.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinecolor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFillcolor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShapetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPointshape();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.robin.proto3.overlaylist.PointShape.serializeBinaryToWriter
    );
  }
  f = message.getPolylineshape();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.robin.proto3.overlaylist.PolylineShape.serializeBinaryToWriter
    );
  }
  f = message.getPolygonshape();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.robin.proto3.overlaylist.PolygonShape.serializeBinaryToWriter
    );
  }
  f = message.getCircleshape();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.robin.proto3.overlaylist.CircleShape.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lineColor = 3;
 * @return {string}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getLinecolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.setLinecolor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fillColor = 4;
 * @return {string}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getFillcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.setFillcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional robin.proto3.ShapeType shapeType = 5;
 * @return {!proto.robin.proto3.ShapeType}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getShapetype = function() {
  return /** @type {!proto.robin.proto3.ShapeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.robin.proto3.ShapeType} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.setShapetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PointShape pointShape = 13;
 * @return {?proto.robin.proto3.overlaylist.PointShape}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getPointshape = function() {
  return /** @type{?proto.robin.proto3.overlaylist.PointShape} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.overlaylist.PointShape, 13));
};


/**
 * @param {?proto.robin.proto3.overlaylist.PointShape|undefined} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
*/
proto.robin.proto3.overlaylist.Shape.prototype.setPointshape = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.robin.proto3.overlaylist.Shape.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.clearPointshape = function() {
  return this.setPointshape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.overlaylist.Shape.prototype.hasPointshape = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PolylineShape polylineShape = 14;
 * @return {?proto.robin.proto3.overlaylist.PolylineShape}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getPolylineshape = function() {
  return /** @type{?proto.robin.proto3.overlaylist.PolylineShape} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.overlaylist.PolylineShape, 14));
};


/**
 * @param {?proto.robin.proto3.overlaylist.PolylineShape|undefined} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
*/
proto.robin.proto3.overlaylist.Shape.prototype.setPolylineshape = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.robin.proto3.overlaylist.Shape.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.clearPolylineshape = function() {
  return this.setPolylineshape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.overlaylist.Shape.prototype.hasPolylineshape = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PolygonShape polygonShape = 15;
 * @return {?proto.robin.proto3.overlaylist.PolygonShape}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getPolygonshape = function() {
  return /** @type{?proto.robin.proto3.overlaylist.PolygonShape} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.overlaylist.PolygonShape, 15));
};


/**
 * @param {?proto.robin.proto3.overlaylist.PolygonShape|undefined} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
*/
proto.robin.proto3.overlaylist.Shape.prototype.setPolygonshape = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.robin.proto3.overlaylist.Shape.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.clearPolygonshape = function() {
  return this.setPolygonshape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.overlaylist.Shape.prototype.hasPolygonshape = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional CircleShape circleShape = 16;
 * @return {?proto.robin.proto3.overlaylist.CircleShape}
 */
proto.robin.proto3.overlaylist.Shape.prototype.getCircleshape = function() {
  return /** @type{?proto.robin.proto3.overlaylist.CircleShape} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.overlaylist.CircleShape, 16));
};


/**
 * @param {?proto.robin.proto3.overlaylist.CircleShape|undefined} value
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
*/
proto.robin.proto3.overlaylist.Shape.prototype.setCircleshape = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.robin.proto3.overlaylist.Shape.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.overlaylist.Shape} returns this
 */
proto.robin.proto3.overlaylist.Shape.prototype.clearCircleshape = function() {
  return this.setCircleshape(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.overlaylist.Shape.prototype.hasCircleshape = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.overlaylist.Overlay.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.Overlay.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.Overlay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.Overlay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.Overlay.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shapesList: jspb.Message.toObjectList(msg.getShapesList(),
    proto.robin.proto3.overlaylist.Shape.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.Overlay}
 */
proto.robin.proto3.overlaylist.Overlay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.Overlay;
  return proto.robin.proto3.overlaylist.Overlay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.Overlay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.Overlay}
 */
proto.robin.proto3.overlaylist.Overlay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.robin.proto3.overlaylist.Shape;
      reader.readMessage(value,proto.robin.proto3.overlaylist.Shape.deserializeBinaryFromReader);
      msg.addShapes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.Overlay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.Overlay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.Overlay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.Overlay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShapesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.overlaylist.Shape.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.robin.proto3.overlaylist.Overlay.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.overlaylist.Overlay} returns this
 */
proto.robin.proto3.overlaylist.Overlay.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Shape shapes = 2;
 * @return {!Array<!proto.robin.proto3.overlaylist.Shape>}
 */
proto.robin.proto3.overlaylist.Overlay.prototype.getShapesList = function() {
  return /** @type{!Array<!proto.robin.proto3.overlaylist.Shape>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.overlaylist.Shape, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.overlaylist.Shape>} value
 * @return {!proto.robin.proto3.overlaylist.Overlay} returns this
*/
proto.robin.proto3.overlaylist.Overlay.prototype.setShapesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.overlaylist.Shape=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.overlaylist.Shape}
 */
proto.robin.proto3.overlaylist.Overlay.prototype.addShapes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.overlaylist.Shape, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.overlaylist.Overlay} returns this
 */
proto.robin.proto3.overlaylist.Overlay.prototype.clearShapesList = function() {
  return this.setShapesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.overlaylist.OverlayList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.overlaylist.OverlayList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.overlaylist.OverlayList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.overlaylist.OverlayList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.OverlayList.toObject = function(includeInstance, msg) {
  var f, obj = {
    overlayList: jspb.Message.toObjectList(msg.getOverlayList(),
    proto.robin.proto3.overlaylist.Overlay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.overlaylist.OverlayList}
 */
proto.robin.proto3.overlaylist.OverlayList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.overlaylist.OverlayList;
  return proto.robin.proto3.overlaylist.OverlayList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.overlaylist.OverlayList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.overlaylist.OverlayList}
 */
proto.robin.proto3.overlaylist.OverlayList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.overlaylist.Overlay;
      reader.readMessage(value,proto.robin.proto3.overlaylist.Overlay.deserializeBinaryFromReader);
      msg.addOverlay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.overlaylist.OverlayList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.overlaylist.OverlayList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.overlaylist.OverlayList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.overlaylist.OverlayList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverlayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.overlaylist.Overlay.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Overlay overlay = 1;
 * @return {!Array<!proto.robin.proto3.overlaylist.Overlay>}
 */
proto.robin.proto3.overlaylist.OverlayList.prototype.getOverlayList = function() {
  return /** @type{!Array<!proto.robin.proto3.overlaylist.Overlay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.overlaylist.Overlay, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.overlaylist.Overlay>} value
 * @return {!proto.robin.proto3.overlaylist.OverlayList} returns this
*/
proto.robin.proto3.overlaylist.OverlayList.prototype.setOverlayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.overlaylist.Overlay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.overlaylist.Overlay}
 */
proto.robin.proto3.overlaylist.OverlayList.prototype.addOverlay = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.overlaylist.Overlay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.overlaylist.OverlayList} returns this
 */
proto.robin.proto3.overlaylist.OverlayList.prototype.clearOverlayList = function() {
  return this.setOverlayList([]);
};


goog.object.extend(exports, proto.robin.proto3.overlaylist);
