import DE_DE from "../../language/de_de.json";
import EN_US from "../../language/en_us.json";
import ES_ES from "../../language/es_es.json";
import NL_NL from "../../language/nl_nl.json";
import ZH_CN from "../../language/zh_cn.json";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import nl from "date-fns/locale/nl";
import zhCN from "date-fns/locale/zh-CN";

export const LANGUAGE_RESOURCES: LanguageResource = {
    "de-DE": {
        code: "de-DE",
        name: "Deutsch (Deutschland)",
        shortName: "DE (DE)",
        languageName: "Deutsch",
        countryName: "Deutschland",
        translation: DE_DE,
        datepickerLocale: "de",
        intlLocale: "de",
        languagePack: de,
    },
    "en-US": {
        code: "en-US",
        name: "English (United States)",
        shortName: "EN (US)",
        languageName: "English",
        countryName: "United States",
        translation: EN_US,
        datepickerLocale: "enUS",
        intlLocale: "en-US",
        languagePack: enUS,
    },
    "es-ES": {
        code: "es-ES",
        name: "Español (España)",
        shortName: "ES (ES)",
        languageName: "Español",
        countryName: "España",
        translation: ES_ES,
        datepickerLocale: "es",
        intlLocale: "es",
        languagePack: es,
    },
    "nl-NL": {
        code: "nl-NL",
        name: "Nederlands (Nederland)",
        shortName: "NL (NL)",
        languageName: "Nederlands",
        countryName: "Nederland",
        translation: NL_NL,
        datepickerLocale: "nl",
        intlLocale: "nl",
        languagePack: nl,
    },
    "zh-CN": {
        code: "zh-CN",
        name: "简体中文",
        shortName: "ZH (CN)",
        languageName: "简体中文",
        countryName: "中国",
        translation: ZH_CN,
        datepickerLocale: "zhCN",
        intlLocale: "zh-CN",
        languagePack: zhCN,
    },
};

export interface Language {
    // Properties

    code: string;
    name: string;
    shortName: string;
    languageName: string;
    countryName: string;

    translation: TranslationNode;
    datepickerLocale: string;
    intlLocale: string;
    languagePack: Locale;
}

export type LanguageResource = { [key: string]: Language };

export interface TranslationNode {
    [key: string]: string | TranslationNode;
}
