import React from "react";
import { TYPES } from "../../../../../di/Types";
import { AltitudeFilterStatusIndicatorViewModel } from "./AltitudeFilterStatusIndicatorViewModel";
import { t } from "i18next";
import { useViewModel } from "../../../../../hooks/useViewModel";
import { useObservable } from "../../../../../hooks";
import { Icon } from "../../../../appearance/Icon";

export const AltitudeFilterStatusIndicator = (): JSX.Element | null => {
    // Properties

    const viewModel: AltitudeFilterStatusIndicatorViewModel = useViewModel(
        TYPES.AltitudeFilterStatusIndicatorViewModel,
    );
    const isActive = useObservable(viewModel.isAltitudeFilterActive, false);

    // Render

    if (!isActive) {
        return null;
    }

    return (
        <Icon
            color="danger"
            iconType="ALTITUDE_FILTER"
            large={true}
            tooltip={t("status.altitudeFilterIsActive")}
            tooltipPosition="left"
        />
    );
};
