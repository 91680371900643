import React from "react";
import styled from "styled-components";
import { OldColors } from "./Colors";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";

interface Props {
    style?: {};
    color?: string;
    disabled: boolean;
    value: boolean | null;
    observableValue: Rx.Observable<boolean> | null;
    onChange: (isOn: boolean) => void;
}

const ANIMATION_DURATION = "300ms";

const Container = styled.span<{ color: string }>`
    width: 18px;
    height: 18px;
    position: relative;
    label {
        cursor: pointer;
        width: 18px;
        height: 18px;
        background: #000;
        cursor: pointer;
        position: relative;
        display: inline-block;
        border: 2px solid #fff;
        border-radius: 2px;
        animation: checkbox-out ${ANIMATION_DURATION};
        &:after {
            content: "";
            width: 8px;
            height: 4px;
            position: absolute;
            top: 2px;
            left: 2px;
            border: 2px solid #000;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
            animation: checkmark-out ${ANIMATION_DURATION};
            transition: opacity ${ANIMATION_DURATION};
        }
        &:hover::after {
            opacity: 1;
            border: 2px solid #333;
            border-top: none;
            border-right: none;
            transition: opacity ${ANIMATION_DURATION};
        }
    }
    input[type="checkbox"] {
        visibility: hidden;
        display: none;
        &:checked + label {
            background: ${(props) => props.color};
            border: 2px solid ${(props) => props.color};
            animation: checkbox-in ${ANIMATION_DURATION};
        }
        &:checked + label:after {
            opacity: 1;
            animation: checkmark-in ${ANIMATION_DURATION};
        }
    }
    @keyframes checkmark-in {
        0% {
            opacity: 0;
            border-width: 0px;
        }
        100% {
            opacity: 1;
            border-width: 2px;
        }
    }
    @keyframes checkmark-out {
        0% {
            opacity: 1;
            border-width: 2px;
        }
        100% {
            opacity: 0;
            border-width: 0px;
        }
    }
    @keyframes checkbox-in {
        0% {
            background: #000;
        }
        100% {
            background: ${(props) => props.color};
        }
    }
    @keyframes checkbox-out {
        0% {
            background: ${(props) => props.color};
        }
        100% {
            background: #000;
        }
    }
`;

export class CheckBox extends BaseSubscriptionHandlerComponent<Props, State> {
    // Static properties

    public static defaultProps = {
        disabled: false,
        value: null,
        observableValue: null,
        color: OldColors.primaryTint,
    };

    // Properties

    private randomId = "CheckBox" + Math.random().toString(36);

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    // Public functions

    public componentDidMount(): void {
        this.subscribeToObservableValueIfNeeded();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    public render(): JSX.Element {
        return (
            <Container style={this.props.style} color={this.props.color!}>
                <input
                    disabled={this.props.disabled}
                    checked={this.state.value === true}
                    type={"checkbox"}
                    id={this.randomId}
                    onChange={this.onChange.bind(this)}
                />
                <label htmlFor={this.randomId} />
            </Container>
        );
    }

    // Private functions

    private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onChange(event.target.checked);
        this.setState({ value: event.target.checked });
    }

    private subscribeToObservableValueIfNeeded(): void {
        if (this.props.observableValue == null) {
            return;
        }
        const subscription = this.props.observableValue.subscribe((value) => {
            this.setState({ value: value });
        });
        this.collectSubscription(subscription);
    }
}

interface State {
    value: boolean | null;
}
