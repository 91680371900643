import { AirbaseData as AirBaseDataProto } from "./proto/generated/atcinfo3_pb";
import { RunwayFunnel } from "./RunwayFunnel";
import { AirBase } from "./Airbase";
import { Runway } from "./Runway";

export class AirBaseData {
    // Static functions
    public static from(model: AirBaseDataProto): AirBaseData {
        return new AirBaseData(
            model.getPreferedairbaseid(),
            model.getAirbaseList().map((value) => AirBase.fromProto(value)),
            model.getRunwayList().map((value) => Runway.from(value)),
            model.getRunwayfunnelList().map((value) => RunwayFunnel.from(value)),
        );
    }

    public constructor(
        public readonly preferredAirbaseId: number,
        public readonly airbases: AirBase[],
        public readonly runways: Runway[],
        public readonly runwayFunnel: RunwayFunnel[],
    ) {}
}
