import * as mapboxgl from "mapbox-gl";
import { LocalUserPreferenceKeys } from "../../domain/model";
import { LocalPreferencesRepository } from "../../domain/repositories";
import "./MeasurementControl.css";
import { t } from "i18next";

export class MeasurementControl implements mapboxgl.IControl {
    // Properties

    private container: HTMLElement | undefined;
    private get isActive(): boolean {
        return (
            this.localPreferencesRepository.getPreference(LocalUserPreferenceKeys.appearance.showTrackMeasurements) ||
            false
        );
    }

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {}

    // Public functions

    public onAdd(): HTMLElement {
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        const button = document.createElement("button");
        button.className = this.getButtonClassName(this.isActive);
        this.subscribeIsActive((isActive) => (button.className = this.getButtonClassName(isActive)));
        button.title = t("map.measureDistance");
        button.setAttribute("aria-label", t("map.measureDistance"));
        button.addEventListener("click", () => {
            this.toggleIsActive();
        });
        this.container.appendChild(button);
        return this.container;
    }

    public onRemove(): void {
        if (this.container && this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
        }
    }

    // Private functions

    private getButtonClassName(isActive: boolean): string {
        return `mapboxgl-ctrl-icon measurement-control${isActive ? " measurement-control-active" : ""}`;
    }

    private setIsActive(isActive: boolean): void {
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.appearance.showTrackMeasurements,
            isActive,
        );
    }

    private toggleIsActive(): void {
        this.setIsActive(!this.isActive);
    }

    private subscribeIsActive(action: (isActive: boolean) => void): void {
        this.localPreferencesRepository
            .observePreference<boolean>(LocalUserPreferenceKeys.appearance.showTrackMeasurements)
            .subscribe((isActive) => action(isActive || false));
    }
}
