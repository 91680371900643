import styled from "styled-components";
import { OVERLAY_SPACING } from "./theme/Spacing";

interface AlertViewFrameProps {
    backgroundColor?: string;
    cursor?: string;
}

interface AlertViewContainerProps {
    visible: boolean;
    extraSpaceBelow?: string | "0px";
    bottomInset?: number;
    position?: "top" | "bottom" | "center" | "none";
    index?: number;
}

export const AlertViewFrame = styled.div<AlertViewFrameProps>`
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 18px 45px;
    transition: all 200ms ease-in-out;
    background-color: ${(props) => props.backgroundColor || "#ffffff"};
    cursor: ${(props) => props.cursor || "default"};

    :not(:first-child) {
        margin-top: 24px;
    }
`;

export const AlertViewContainer = styled.div<AlertViewContainerProps>`
    ${({ position, visible, bottomInset }) => {
        switch (position) {
            case "bottom":
                return `bottom: ${visible ? `${40 + (bottomInset || 0)}px` : "-100%"};`;
            case "top":
                return `top: ${visible ? OVERLAY_SPACING : "-100%"};`;
            case "center":
                return `top: ${visible ? "50%" : "-100%"}; transform: translate(-50%, -50%);`;
        }
    }}

    margin-bottom: ${({ extraSpaceBelow }) => extraSpaceBelow || "0px"};
    left: 50%;
    transform: translate(-50%, 0%);
    transition: all 200ms ease-in-out;
    ${({ position }) => (position === "none" ? "" : "position: fixed;")}
    z-index: 1500;
`;
