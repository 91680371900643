import mapboxgl from "mapbox-gl";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { MapModule } from "../MapModule";
import { ORDER_LAYER_GROUND_OBSERVATIONS } from "../Orders";
import { Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";
import { GroundObservationsLayer } from "../../layers/GroundObservationsLayer";
import { GroundObservationsModuleViewModel } from "./GroundObservationsModuleViewModel";

export class GroundObservationsModule extends MapModule<GroundObservationsModuleViewModel> {
    // Properties

    public constructor(protected readonly viewModel: GroundObservationsModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const groundObservationsLayer = GroundObservationsLayer.attachedTo(
            map,
            ORDER_LAYER_GROUND_OBSERVATIONS.id,
            this.onGroundObservationClicked,
            this.onMapLongPressedInObservation,
            this.onCancelLongPress,
        );

        this.collectSubscriptions(
            this.viewModel.shouldShowGroundObservations.subscribe((value) => groundObservationsLayer.setEnabled(value)),
            this.viewModel.groundObservationMode.subscribe((value) =>
                groundObservationsLayer.setGroundObservationMode(value),
            ),
            this.viewModel.groundObservationStates.subscribe((value) =>
                groundObservationsLayer.setGroundObservationStates(value),
            ),
            this.viewModel.selectedLocations.subscribe((value) => groundObservationsLayer.setSelectedLocations(value)),
            this.viewModel.shouldSuppressClick.subscribe(
                (value) => (groundObservationsLayer.shouldSuppressClick = value),
            ),
            Rx.NEVER.pipe(RxOperators.finalize(() => groundObservationsLayer.onModuleDispose())).subscribe(),
        );
    }

    // Private functions

    private onGroundObservationClicked = (id: int): void => {
        this.viewModel.toggleGroundObservation(id);
    };

    private onMapLongPressedInObservation = (location: Location): void => {
        this.viewModel.selectGroundObservationLocation(location);
    };

    private onCancelLongPress = (longPressTriggered: boolean): void => {
        // If the long press is triggered, prevent it it from also triggering a click event by temporarily suppressing clicks
        longPressTriggered && this.viewModel.suppressClickTemporarily();
    };
}
