import { BlankingSector } from "../../../domain/model";
import { degToRad } from "../../../utils/MathUtils";
import { SECTORS_ROTATION_OFFSET_RADIANS } from "./BlankingSectorsImage";

export class BlankingSectorsPreviewImage {
    // Properties

    private imageSize = this.radius * 2;
    private context: CanvasRenderingContext2D;
    private innerCircleRadius = 20;
    private clipPath?: Path2D;
    private blankingSectors: BlankingSector[] = [];

    public constructor(private readonly radius: number) {
        const canvas = document.createElement("canvas");
        canvas.width = this.imageSize;
        canvas.height = this.imageSize;
        this.context = canvas.getContext("2d")!;
    }

    public getCanvas(): HTMLCanvasElement {
        return this.context.canvas;
    }

    public update(blankingSectors?: BlankingSector[]): void {
        this.blankingSectors = blankingSectors || [];
        this.render();
    }

    /**
     * Called once before every frame where the icon will be used
     */
    public render(): void {
        const context = this.context;
        if (context == null) {
            return;
        }

        const r = this.radius;
        const cx = this.imageSize / 2;
        const cy = this.imageSize / 2;

        // Clip everything inside the inner circle to keep the radar icon visible
        if (this.clipPath == null) {
            this.clipPath = new Path2D();
            this.clipPath.rect(0, 0, this.imageSize, this.imageSize);
            this.clipPath.arc(cx, cy, this.innerCircleRadius, 0, Math.PI * 2);
        }
        context.clip(this.clipPath, "evenodd");

        context.clearRect(0, 0, this.imageSize, this.imageSize);

        // Draw blanking sectors
        this.blankingSectors.forEach((sector) => {
            if (!sector.enabled) {
                return;
            }

            context.beginPath();
            context.moveTo(cx, cy);
            context.arc(
                cx,
                cy,
                r,
                degToRad(sector.startAngle) + SECTORS_ROTATION_OFFSET_RADIANS,
                degToRad(sector.startAngle + sector.span) + SECTORS_ROTATION_OFFSET_RADIANS,
            );
            context.lineTo(cx, cy);
            context.fillStyle = "rgba(86, 119, 252, 0.5)";
            context.fill();
        });
    }
}
