import * as mapboxgl from "mapbox-gl";
import { RangeLayer } from "./RangeLayer";

const RADAR_RANGE_SOURCE_ID = "source-radar-range";
const RADAR_RANGE_LAYER_ID = "layer-radar-range";

export class RadarRangeLayer extends RangeLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): RadarRangeLayer {
        return new RadarRangeLayer(map, orderLayer, RADAR_RANGE_SOURCE_ID, RADAR_RANGE_LAYER_ID);
    }
}
