import React, { useEffect, useRef, useState } from "react";
import * as Rx from "rxjs";
import { StatusValueContainer } from "./RadarStatus";
import { t } from "i18next";
import { showError } from "../../../../utils/MessageUtils";
import Tooltip from "../../../appearance/Tooltip";
import { Button } from "../../../appearance/button/Button";
import styled from "styled-components";

const ResetLabel = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const RADAR_SENSITIVITY_TOOLTIP_ID = "radar-sensitivity-tooltip";

interface Props {
    onResetSensitivityRequest: () => Rx.Observable<void>;
    status: number;
}

export const RadarSensitivity = ({ onResetSensitivityRequest, status }: Props): JSX.Element => {
    // Properties

    const [isResettingSensitivity, setIsResettingSensitivity] = useState(false);

    const subscriptionRef = useRef<Rx.Subscription>();
    useEffect(() => {
        const subscription = new Rx.Subscription();
        subscriptionRef.current = subscription;
        return () => subscription.unsubscribe();
    }, []);

    // Local functions

    const resetSensitivity = (): void => {
        setIsResettingSensitivity(true);

        subscriptionRef.current!.add(
            onResetSensitivityRequest().subscribe({
                complete: () => setIsResettingSensitivity(false),
                error: (error) => {
                    showError(error);
                    setIsResettingSensitivity(false);
                },
            }),
        );
    };

    // Render

    const stringValue = status != null ? status + " %" : "NA";

    return (
        <StatusValueContainer>
            <ResetLabel>
                <label>{t("radarStatus.sensitivity")}</label>
                <Button
                    data-for={RADAR_SENSITIVITY_TOOLTIP_ID}
                    data-tip={t("radarStatus.resetSensitivity")}
                    disabled={isResettingSensitivity}
                    loading={isResettingSensitivity}
                    layout="inline"
                    iconType={"RESET"}
                    onClick={() => resetSensitivity()}
                />

                <Tooltip id={RADAR_SENSITIVITY_TOOLTIP_ID} effect={"solid"} />
            </ResetLabel>
            <strong>{stringValue}</strong>
        </StatusValueContainer>
    );
};
