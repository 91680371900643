import mapboxgl from "mapbox-gl";
import { MeasurementsLayer } from "../../layers";
import { MapModule } from "../MapModule";
import { MeasurementsModuleViewModel } from "./MeasurementsModuleViewModel";
import { ORDER_LAYER_MEASUREMENTS } from "../Orders";
import { Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";

export class MeasurementsModule extends MapModule<MeasurementsModuleViewModel> {
    public constructor(protected readonly viewModel: MeasurementsModuleViewModel) {
        super();
    }

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const layer = MeasurementsLayer.attachedTo(map, ORDER_LAYER_MEASUREMENTS.id);

        this.collectSubscriptions(
            this.viewModel.shouldShowMeasurements.subscribe((value) => layer.setEnabled(value)),
            this.viewModel.measurementsData.subscribe((value) => layer.updateMeasurementsData(value)),
        );
    }
}
