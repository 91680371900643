import React from "react";
import { Inclination, Radar } from "../../../domain/model";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { showError } from "../../../utils/MessageUtils";
import { parseNumberOrZero } from "../../../utils/StringUtils";
import { PanelDescription } from "../../appearance/panels/PanelText";
import { RadarInclination } from "./RadarInclination";
import { t } from "i18next";
import { Button } from "../../appearance/button/Button";
import { ButtonGroup } from "../../appearance/button/ButtonGroup";
import { FormControl } from "../../appearance/forms/FormControl";
import { Form } from "../../appearance/forms/Form";
import { FormSlider } from "../../appearance/forms/FormSlider";

interface Props {
    contrast: float;
    isAutoAligning: boolean;
    onContrastSet: (value: float) => void;
    onRequestPreviewNewAlignment: (dAngle: number, imageContrast: float) => void;
    onRequestSaveNewAlignment: (angle: number) => Rx.Observable<void>;
    radar: Radar;
}

interface State {
    angle: number;
    canApplyChanges: boolean;
    contrast: float;
    inclination?: Inclination;
    isSaving: boolean;
}

export class RadarAlignment extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly angleInput: React.RefObject<HTMLInputElement>;

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.angleInput = React.createRef();

        this.state = {
            isSaving: false,
            canApplyChanges: false,
            angle: this.props.radar.orientation.rotationAngle,
            contrast: this.props.contrast,
            inclination: this.props.radar.status ? this.props.radar.status.inclination : undefined,
        };
    }

    public componentDidMount(): void {
        this.props.onRequestPreviewNewAlignment(0, this.state.contrast);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.radar.orientation.rotationAngle !== this.props.radar.orientation.rotationAngle) {
            this.onChange(this.props.radar.orientation.rotationAngle);
        }
    }

    public render(): React.ReactNode {
        return (
            <Form vertical>
                <PanelDescription>{t("radarControlPanel.descriptionAlignment")}</PanelDescription>
                <FormControl title={t("radarControlPanel.rotation")} vertical>
                    <FormSlider
                        onChange={this.onAngleInputChange.bind(this)}
                        max={180}
                        min={-180}
                        showTextInput={true}
                        step={0.1}
                        value={this.roundAngle(this.state.angle)}
                    />
                </FormControl>
                <FormControl
                    helpText={t("radarControlPanel.contrastTooltip")}
                    title={t("radarControlPanel.contrast")}
                    vertical
                >
                    <FormSlider
                        onChange={this.onContrastChange.bind(this)}
                        showTextInput={true}
                        step={5}
                        value={Math.round(this.state.contrast * 100)}
                    />
                </FormControl>
                {this.state.inclination && (
                    <FormControl
                        helpText={t("radarControlPanel.inclinationStatus", {
                            withinLimits: this.state.inclination.withinLimits
                                ? t("radarControlPanel.inclinationStatusWithinLimits")
                                : t("radarControlPanel.inclinationStatusOutsideLimits"),
                        })}
                        title={t("radarControlPanel.inclination")}
                        vertical
                    >
                        <RadarInclination inclination={this.state.inclination} />
                    </FormControl>
                )}
                <ButtonGroup>
                    <Button
                        disabled={this.props.isAutoAligning || this.state.isSaving || !this.state.canApplyChanges}
                        loading={this.state.isSaving}
                        label={t("radarControlPanel.applyChanges")}
                        onClick={this.saveChanges.bind(this)}
                    />
                </ButtonGroup>
            </Form>
        );
    }

    // Private functions

    private saveChanges(): void {
        this.setState({ isSaving: true });
        const subscription = this.props.onRequestSaveNewAlignment(this.state.angle).subscribe({
            complete: () => {
                this.setState({ isSaving: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isSaving: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private onAngleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newValue = parseNumberOrZero(e.target.value);
        if (this.state.angle !== newValue) {
            this.onChange(newValue);
        }
    }

    private onContrastChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newValue = parseNumberOrZero(e.target.value);
        const floatValue = newValue / 100;
        if (this.state.contrast !== floatValue) {
            this.onChange(this.state.angle, floatValue);
            this.props.onContrastSet(floatValue);
        }
    }

    private onChange(angle: number, contrast: number = this.state.contrast): void {
        angle = Math.max(angle, -180);
        angle = Math.min(angle, 180);
        let canApplyChanges = false;
        if (angle !== this.props.radar.orientation.rotationAngle) {
            canApplyChanges = true;
        }
        this.setState({ angle, canApplyChanges, contrast });
        this.props.onRequestPreviewNewAlignment(this.props.radar.orientation.rotationAngle - angle, contrast);
    }

    private roundAngle(angle: number): string {
        return (Math.round(angle * 10) / 10).toString();
    }
}
