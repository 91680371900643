import { SettingItemIcon } from "../SettingItemIcon";
import {
    SettingItemViewModel,
    PlusMinusItemViewModel,
    ActionItemViewModel,
    SwitchItemViewModel,
} from "../SettingItemViewModel";
import { MapRepository } from "../../../domain/repositories/MapRepository";
import { LocalPreferencesRepository } from "../../../domain/repositories/LocalPreferencesRepository";
import IconSizeIcon from "../../../res/images/settings/icon_size.svg";
import MapBrightnessIcon from "../../../res/images/settings/map_brightness.svg";
import MapStyleIcon from "../../../res/images/settings/map_style.svg";
import RangeRingsOpacityIcon from "../../../res/images/settings/range_rings_opacity.svg";
import DisplayNegativeAltitudesIcon from "../../../res/images/settings/display_negative_altitudes.svg";
import ClockIcon from "../../../res/images/settings/clock.svg";
import { SettingsUIActionRequest } from "../SettingsUIActionRequest";
import { LocalUserPreferenceKeys } from "../../../domain/model/PreferencesData";
import { SettingsItemSection } from "../SettingsItemSection";
import { SettingsErrorHandler } from "../SettingsErrorHandler";
import { SettingsUIActionRequestHandler } from "../SettingsUIActionRequestHandler";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import * as RxUtils from "../../../utils/RxUtils";
import { TimeDisplayMode } from "../../../domain/model/TimeDisplayMode";
import { DistanceUnitSettingItemViewModel } from "./items/DistanceUnitSettingItemViewModel";
import { t } from "i18next";
import { LocationInfoRepository, RadarRepository, ServerConfigRepository } from "../../../domain/repositories";
import { isVerticalRadar } from "../../../domain/model";
import { FlavorConfig } from "../../../infrastructure/FlavorConfig";
import { VRAzimuthLayerToggleSettingItemViewModel } from "./items/VRAzimuthLayerToggleSettingItemViewModel";
import { VelocityUnitSettingItemViewModel } from "./items/VelocityUnitSettingItemViewModel";
import { VROnlyTracksToggleSettingItemViewModel } from "./items/VROnlyTracksToggleSettingItemViewModel";
import BlankingSectorsIcon from "../../../res/images/settings/blanking_sectors.svg";

export class CommonMapSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "map-settings";
    public readonly label: string | null = t("settings.map");

    public constructor(
        protected readonly localPreferencesRepository: LocalPreferencesRepository,
        protected readonly mapRepository: MapRepository,
        protected readonly locationInfoRepository: LocationInfoRepository,
        protected readonly radarRepository: RadarRepository,
        protected readonly flavorConfig: FlavorConfig,
        protected readonly serverConfigRepository: ServerConfigRepository,
    ) {}

    // Public functions

    public generate(
        _: SettingsErrorHandler,
        uiActionRequestHandler: SettingsUIActionRequestHandler,
    ): Rx.Observable<SettingItemViewModel[]> {
        const viewModels: SettingItemViewModel[] = [
            new PlusMinusItemViewModel(
                t("settings.rangeRingsOpacity"),
                SettingItemIcon.withUrl(RangeRingsOpacityIcon),
                this.localPreferencesRepository.observePreference<number>(
                    LocalUserPreferenceKeys.appearance.rangeRingsOpacity,
                ),
                [0.1, 0.9],
                0.1,
                (value) =>
                    this.localPreferencesRepository.setPreference(
                        LocalUserPreferenceKeys.appearance.rangeRingsOpacity,
                        Math.round(value * 10) / 10, // value gets updated to a lot of decimals after changing the value about 3 times, hence the rounding here
                    ),
                (value) => Math.round(value * 100).toString() + "%",
            ),
            new ActionItemViewModel(
                t("settings.mapType"),
                SettingItemIcon.withUrl(MapStyleIcon),
                () => uiActionRequestHandler.onUIActionRequested(SettingsUIActionRequest.SHOW_TILE_PROVIDER_DIALOG),
                RxUtils.nonNullObservable(this.mapRepository.getSelectedTileProvider()).pipe(
                    RxOperators.map((tileProvider) => tileProvider.name),
                    RxOperators.map((value) => value.toUpperCase()),
                ),
            ),
            new PlusMinusItemViewModel(
                t("settings.mapBrightness"),
                SettingItemIcon.withUrl(MapBrightnessIcon),
                this.localPreferencesRepository.observePreference<number>(
                    LocalUserPreferenceKeys.appearance.mapBrightness,
                ),
                [1, 10],
                1,
                (value) =>
                    this.localPreferencesRepository.setPreference(
                        LocalUserPreferenceKeys.appearance.mapBrightness,
                        value,
                    ),
                (value) => value.toString(),
            ),
            new PlusMinusItemViewModel(
                t("settings.iconScale"),
                SettingItemIcon.withUrl(IconSizeIcon),
                this.localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.iconScale),
                [0.5, 2],
                0.25,
                (value) =>
                    this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.iconScale, value),
                (value) => value.toFixed(2) + " x",
            ),
            new DistanceUnitSettingItemViewModel(this.localPreferencesRepository),
            new VelocityUnitSettingItemViewModel(
                this.localPreferencesRepository,
                "velocityUnit",
                t("settings.velocityUnit"),
            ),
            new SwitchItemViewModel(
                t("settings.showBlankingSectors"),
                SettingItemIcon.withUrl(BlankingSectorsIcon),
                RxUtils.nonNullObservable(
                    this.localPreferencesRepository.observePreference<boolean>(
                        LocalUserPreferenceKeys.appearance.showBlankingSectors,
                    ),
                    false,
                ),
                (value) =>
                    this.localPreferencesRepository.setPreference(
                        LocalUserPreferenceKeys.appearance.showBlankingSectors,
                        value,
                    ),
            ),
        ];

        return Rx.combineLatest([
            this.getLocalTimeSettings(),
            this.getOnTheMoveSettings(),
            this.generateAltitudeSettings(),
            this.getVRAzimuthLayerSettings(),
        ]).pipe(
            RxOperators.map(([localTimeSettings, onTheMoveViewModels, altitudeViewModels, vrAzimuthViewModels]) => [
                ...localTimeSettings,
                ...viewModels,
                ...onTheMoveViewModels,
                ...altitudeViewModels,
                ...vrAzimuthViewModels,
            ]),
        );
    }

    // Private functions

    private getLocalTimeSettings(): Rx.Observable<SettingItemViewModel[]> {
        return this.serverConfigRepository.radarTimezoneOffsetFromUTC.pipe(
            RxOperators.map((offset) => {
                return [
                    new SwitchItemViewModel(
                        t(offset !== null ? "settings.showLocalRadarTime" : "settings.showLocalTime"),
                        SettingItemIcon.withUrl(ClockIcon),
                        RxUtils.nonNullObservable(
                            this.localPreferencesRepository.observePreference<TimeDisplayMode>(
                                LocalUserPreferenceKeys.appearance.timeDisplayMode,
                            ),
                        ).pipe(RxOperators.map((value) => value === TimeDisplayMode.LOCAL)),
                        (value) =>
                            this.localPreferencesRepository.setPreference(
                                LocalUserPreferenceKeys.appearance.timeDisplayMode,
                                value ? TimeDisplayMode.LOCAL : TimeDisplayMode.UTC,
                            ),
                        [],
                        undefined,
                    ),
                ];
            }),
        );
    }

    private getOnTheMoveSettings(): Rx.Observable<SettingItemViewModel[]> {
        return this.radarRepository.isDynamicPositioningEnabled.pipe(
            RxOperators.map((isDynamicPositioningEnabled) => {
                if (!isDynamicPositioningEnabled) {
                    return [];
                }

                return [
                    new VelocityUnitSettingItemViewModel(
                        this.localPreferencesRepository,
                        "vehicleVelocityUnit",
                        t("settings.vehicleVelocityUnit"),
                    ),
                ];
            }),
        );
    }

    private getVRAzimuthLayerSettings(): Rx.Observable<SettingItemViewModel[]> {
        const isOptionEnabledInFlavor = this.flavorConfig.vrAzimuthLayerEnabled;
        if (!isOptionEnabledInFlavor) {
            return Rx.of([]);
        }

        return this.radarRepository.radars.pipe(
            RxOperators.map((radars) =>
                radars.some((r) => isVerticalRadar(r))
                    ? [
                          new VRAzimuthLayerToggleSettingItemViewModel(this.localPreferencesRepository),
                          new VROnlyTracksToggleSettingItemViewModel(this.localPreferencesRepository),
                      ]
                    : [],
            ),
        );
    }

    private generateAltitudeSettings(): Rx.Observable<SettingItemViewModel[]> {
        const viewModels: SettingItemViewModel = new SwitchItemViewModel(
            t("settings.showNegativeAltitudes"),
            SettingItemIcon.withUrl(DisplayNegativeAltitudesIcon),
            RxUtils.nonNullObservable(
                this.localPreferencesRepository.observePreference<boolean>(
                    LocalUserPreferenceKeys.appearance.displayNegativeAltitudeAsZero,
                ),
            ),
            (value) =>
                this.localPreferencesRepository.setPreference(
                    LocalUserPreferenceKeys.appearance.displayNegativeAltitudeAsZero,
                    value,
                ),
        );
        // Start with false in case hasReliableAltitudeInfo has not yet emitted a value. This may be
        // the case if there is a delay fetching the radars list in DV.
        return this.locationInfoRepository.hasReliableAltitudeInfo.pipe(
            RxOperators.startWith(false),
            RxOperators.map((hasReliableAltitudeInfo) => (hasReliableAltitudeInfo ? [viewModels] : [])),
        );
    }
}
