import React from "react";
import styled from "styled-components";
import {
    Classification,
    ClassificationIcon,
    EmitterCategory,
    EmitterCategoryGroup,
    getEmitterCategoryGroup,
} from "../../domain/model";
import { Colors, CustomColors, OldColors } from "../appearance/Colors";
import { getColorAndOpacity } from "../../utils/ColorUtils";

const IconFrame = styled.span`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
`;

interface BaseIconProps {
    scale: float;
}

interface CircleIconProps extends BaseIconProps {
    isCircleIconProps: boolean; // Helper for type casting
    outerCircleColor: string;
    innerCircleColor: string;
    customBorderRadiusPixels?: int;
}

interface SquareIconProps extends BaseIconProps {
    isSquareIconProps: boolean; // Helper for type casting
    color: string;
    borderColor?: string;
}

interface TriangleIconProps extends BaseIconProps {
    isOtherIconProps: boolean; // Helper for type casting
    color: string;
}

interface AircraftIconProps extends BaseIconProps {
    isAircraftIconProps: boolean; // Helper for type casting
    color: string;
    chevronColor: string;
    borderColor?: string;
}

interface HelicopterIconProps extends BaseIconProps {
    isHelicopterIconProps: boolean; // Helper for type casting
    color: string;
}

interface DroneIconProps extends BaseIconProps {
    isDroneIconProps: boolean; // Helper for type casting
    color: string;
}

interface FixedWingIconProps extends BaseIconProps {
    isFixedWingIconProps: boolean; // Helper for type casting
    color: string;
}

interface QuestionMarkIconProps extends BaseIconProps {
    isQuestionMarkIconProps: boolean; // Helper for type casting
    color: string;
}

interface RadarIconProps extends BaseIconProps {
    isRadarIconProps: boolean; // Helper for type casting
}

export function generateCircleIcon(props: CircleIconProps): JSX.Element {
    const br = props.customBorderRadiusPixels;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15 * props.scale}
            height={14 * props.scale}
            viewBox="0 0 15 14"
            style={{ flex: "auto" }}
        >
            <g fill="none" fillRule="evenodd" transform="translate(.126)">
                <circle cx="7" cy="7" r="7" fill={props.outerCircleColor} />
                <circle cx="7" cy="7" r={br ? 7 - br : "4"} fill={props.innerCircleColor} />
            </g>
        </svg>
    );
}

export function generateSquareIcon(props: SquareIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
            style={{ flex: "auto" }}
        >
            <rect fill={props.borderColor || props.color} x="4" y="4" width="14" height="14" />
            <rect fill={props.color} x="6" y="6" width="10" height="10" />
        </svg>
    );
}

export function generateTriangleIcon(props: TriangleIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
            style={{ flex: "auto" }}
        >
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M11.868 4.52l6.277 10.984c.274.48.107 1.09-.372 1.364-.151.087-.322.132-.496.132H4.723c-.552 0-1-.448-1-1 0-.174.046-.345.132-.496l6.277-10.985c.274-.48.885-.646 1.364-.372.155.089.284.217.372.372z"
            />
        </svg>
    );
}

export function generateAircraftIcon(props: AircraftIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
            style={{ flex: "auto" }}
        >
            <g fill="none" fillRule="evenodd">
                <circle cx="11" cy="11" r="11" fill={props.borderColor || props.color} />
                <circle cx="11" cy="11" r="9" fill={props.color} />
                <path
                    fill={props.chevronColor}
                    d="M11.572 5.594l3.318 10.478c.1.316-.075.653-.39.753-.19.06-.397.023-.554-.1l-2.574-2.033c-.218-.172-.526-.172-.744 0l-2.575 2.032c-.26.206-.637.161-.842-.099-.123-.156-.161-.363-.101-.553l3.318-10.478c.1-.316.437-.49.753-.39.186.058.332.204.39.39z"
                />
            </g>
        </svg>
    );
}

export function generateHelicopterIcon(props: HelicopterIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
            style={{ flex: "auto" }}
        >
            <g fill="none" fillRule="evenodd">
                <circle cx="11" cy="11" r="11" fill={props.color} />
                <path
                    fill="#000"
                    d="M11 18c.368 0 .702-2.167 1-6.5 0-.271.5-.666.5-.929C12.495 7.36 11.925 5 11 5c-.911 0-1.493 2.426-1.5 5.571 0 .307.5.61.5.929.298 4.333.632 6.5 1 6.5z"
                />
                <path
                    fill="#000"
                    d="M8 10c0 .552-.5 1 3 1s3-.448 3-1c0-.368-1-.702-3-1-2 .298-3 .632-3 1zM11 16l-1.447.724c-.339.169-.553.515-.553.894V18l2-.5 2 .5v-.382c0-.379-.214-.725-.553-.894L11 16z"
                />
                <path stroke="#000" strokeLinecap="round" d="M6 5L16 15M6 15L16 5" />
            </g>
        </svg>
    );
}

export function generateDroneIcon(props: DroneIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
            style={{ flex: "auto" }}
        >
            <g fill="none" fillRule="evenodd">
                <circle cx="11" cy="11" r="11" fill={props.color} />
                <circle cx="14" cy="8" r="2" fill="#000" />
                <circle cx="8" cy="8" r="2" fill="#000" />
                <circle cx="8" cy="14" r="2" fill="#000" />
                <circle cx="14" cy="14" r="2" fill="#000" />
            </g>
        </svg>
    );
}

export function generateFixedWingIcon(props: FixedWingIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
            style={{ flex: "auto" }}
        >
            <g fill="none" fillRule="evenodd">
                <circle cx="11" cy="11" r="11" fill={props.color} />
                <path
                    fill="#000"
                    d="M11 5c.552 0 1 .448 1 1v.714l5.162 3.688c.526.375.838.981.838 1.627V14l-6-2.571V14l.121.121c.563.563.879 1.326.879 2.122V17l-1.553-.776c-.281-.141-.613-.141-.894 0L9 17v-.757c0-.796.316-1.56.879-2.122L10 14v-2.571L4 14v-1.97c0-.647.312-1.253.838-1.628L10 6.714V6c0-.552.448-1 1-1z"
                />
            </g>
        </svg>
    );
}

export function generateQuestionMarkIcon(props: QuestionMarkIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={22 * props.scale}
            height={22 * props.scale}
            viewBox="0 0 22 22"
        >
            <defs>
                <path
                    id="b"
                    d="M12 18h-2v-2h2v2zm2.1-7.7l-.9.9c-.8.7-1.2 1.3-1.2 2.8h-2v-.5c0-1.1.4-2.1 1.2-2.8l1.2-1.3c.4-.3.6-.8.6-1.4 0-1.1-.9-2-2-2s-2 .9-2 2H7c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .9-.4 1.7-.9 2.3z"
                />
                <filter id="a" width="212.5%" height="164.3%" x="-56.2%" y="-32.1%" filterUnits="objectBoundingBox">
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                    <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                <use fill={props.color} xlinkHref="#b" />
            </g>
        </svg>
    );
}

export function generateRadarIcon(props: RadarIconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26 * props.scale}
            height={26 * props.scale}
            viewBox="0 0 26 26"
            style={{ flex: "auto" }}
        >
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M13.096 25.919c7.1 0 12.863-5.762 12.863-12.864a12.819 12.819 0 0 0-3.77-9.095l-1.812 1.814a10.313 10.313 0 0 1 3.01 7.28c0 5.687-4.606 10.291-10.291 10.291-5.686 0-10.293-4.604-10.293-10.29 0-5.25 3.925-9.572 9.006-10.202v2.599c-3.653.618-6.433 3.783-6.433 7.603 0 4.257 3.46 7.718 7.72 7.718 4.258 0 7.718-3.461 7.718-7.718A7.65 7.65 0 0 0 18.55 7.6l-1.813 1.814a5.182 5.182 0 0 1 1.506 3.641 5.144 5.144 0 0 1-5.146 5.145 5.144 5.144 0 0 1-5.146-5.145c0-2.393 1.647-4.387 3.86-4.966v2.754a2.542 2.542 0 0 0-1.287 2.212 2.58 2.58 0 0 0 2.573 2.572 2.58 2.58 0 0 0 2.571-2.572 2.53 2.53 0 0 0-1.286-2.212V.19h-1.286C5.995.19.232 5.953.232 13.055c0 7.102 5.763 12.864 12.864 12.864z"
            />
        </svg>
    );
}

export type IconProps =
    | CircleIconProps
    | SquareIconProps
    | AircraftIconProps
    | HelicopterIconProps
    | DroneIconProps
    | FixedWingIconProps
    | TriangleIconProps
    | QuestionMarkIconProps
    | RadarIconProps;

export function getComponentFromProps(iconProps: IconProps, withFrame = true): JSX.Element {
    let iconComponent: JSX.Element;
    if ((iconProps as CircleIconProps).isCircleIconProps) {
        iconComponent = generateCircleIcon(iconProps as CircleIconProps);
    } else if ((iconProps as SquareIconProps).isSquareIconProps) {
        iconComponent = generateSquareIcon(iconProps as SquareIconProps);
    } else if ((iconProps as TriangleIconProps).isOtherIconProps) {
        iconComponent = generateTriangleIcon(iconProps as TriangleIconProps);
    } else if ((iconProps as AircraftIconProps).isAircraftIconProps) {
        iconComponent = generateAircraftIcon(iconProps as AircraftIconProps);
    } else if ((iconProps as HelicopterIconProps).isHelicopterIconProps) {
        iconComponent = generateHelicopterIcon(iconProps as HelicopterIconProps);
    } else if ((iconProps as DroneIconProps).isDroneIconProps) {
        iconComponent = generateDroneIcon(iconProps as DroneIconProps);
    } else if ((iconProps as FixedWingIconProps).isFixedWingIconProps) {
        iconComponent = generateFixedWingIcon(iconProps as FixedWingIconProps);
    } else if ((iconProps as QuestionMarkIconProps).isQuestionMarkIconProps) {
        iconComponent = generateQuestionMarkIcon(iconProps as QuestionMarkIconProps);
    } else if ((iconProps as RadarIconProps).isRadarIconProps) {
        iconComponent = generateRadarIcon(iconProps as RadarIconProps);
    } else {
        throw new Error("Icon type is not expected");
    }

    if (withFrame) {
        return <IconFrame>{iconComponent}</IconFrame>;
    } else {
        return iconComponent;
    }
}

export function getPropsFromClassification(classification: Classification, scale: float): IconProps | null {
    const color = getColorAndOpacity(classification.defaultColor)[0];
    switch (classification.icon) {
        case ClassificationIcon.AIRCRAFT:
            return {
                isAircraftIconProps: true,
                color: color,
                chevronColor: CustomColors.trackDefaults.aircraftChevron,
                scale: scale,
            };
        case ClassificationIcon.HELICOPTER:
            return {
                isHelicopterIconProps: true,
                color: color,
                scale: scale,
            };
        case ClassificationIcon.CIRCLE:
            return {
                isCircleIconProps: true,
                innerCircleColor: color,
                outerCircleColor: color,
                scale: scale,
            };
        case ClassificationIcon.RING:
            return {
                isCircleIconProps: true,
                innerCircleColor: CustomColors.trackDefaults.birdInnerCircles,
                outerCircleColor: color,
                scale: scale,
            };
        case ClassificationIcon.SQUARE:
            return {
                isSquareIconProps: true,
                color: color,
                scale: scale,
            };
        case ClassificationIcon.DRONE:
            return {
                isDroneIconProps: true,
                color: color,
                scale: scale,
            };
        case ClassificationIcon.FIXED_WING:
            return {
                isFixedWingIconProps: true,
                color: color,
                scale: scale,
            };
        case ClassificationIcon.TRIANGLE:
            return {
                isOtherIconProps: true,
                color: color,
                scale: scale,
            };
        case ClassificationIcon.QUESTION_MARK:
        default:
            return {
                isQuestionMarkIconProps: true,
                color: color,
                scale: scale,
            };
    }
}

export function getComponentFromClassification(classification: Classification, scale: float): JSX.Element | null {
    const props = getPropsFromClassification(classification, scale);
    if (props == null) {
        return null;
    }
    return getComponentFromProps(props);
}

export function newRadarIconComponent(): JSX.Element {
    return getComponentFromProps({ isRadarIconProps: true, scale: 1 });
}

export function newUserLocationIconComponent(): JSX.Element {
    return getComponentFromProps({
        isCircleIconProps: true,
        customBorderRadiusPixels: 2,
        innerCircleColor: Colors.secondary.blue,
        outerCircleColor: Colors.secondary.white,
        scale: 1.5,
    });
}

export const getADSBAircraftIconProps: (scale: float) => AircraftIconProps = (scale) => ({
    isAircraftIconProps: true,
    color: OldColors.black,
    chevronColor: Colors.secondary.blue,
    borderColor: Colors.secondary.blue,
    scale: scale,
});

export const getADSBVehicleIconProps: (scale: float) => SquareIconProps = (scale) => ({
    isSquareIconProps: true,
    color: OldColors.black,
    borderColor: Colors.secondary.blue,
    scale: scale,
});

export const getADSBGroupIconProps = (
    group: EmitterCategoryGroup,
    scale: number,
): AircraftIconProps | SquareIconProps | CircleIconProps | DroneIconProps | HelicopterIconProps => {
    switch (group) {
        case EmitterCategoryGroup.AircraftEmitterCategory:
            return getADSBAircraftIconProps(scale);
        case EmitterCategoryGroup.VehicleEmitterCategory:
            return getADSBVehicleIconProps(scale);
        case EmitterCategoryGroup.DroneEmitterCategory:
            return {
                isDroneIconProps: true,
                color: Colors.secondary.blue,
                scale: 1,
            } as DroneIconProps;
        case EmitterCategoryGroup.RotorcraftEmitterCategory:
            return {
                isHelicopterIconProps: true,
                color: Colors.secondary.blue,
                scale: scale,
            };
        case EmitterCategoryGroup.OtherEmitterCategory:
        default:
            return {
                isCircleIconProps: true, // Helper for type casting
                outerCircleColor: Colors.secondary.blue,
                innerCircleColor: Colors.secondary.blue,
                scale,
            };
    }
};

export const getADSBFlightIconProps: (
    emitterCategory: EmitterCategory,
    scale: float,
) => AircraftIconProps | SquareIconProps | CircleIconProps | DroneIconProps | HelicopterIconProps = (
    emitterCategory,
    scale,
) => {
    const group = getEmitterCategoryGroup(emitterCategory);
    return getADSBGroupIconProps(group, scale);
};
