import { ActionItemViewModel } from "../../SettingItemViewModel";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { SettingItemIcon } from "../../SettingItemIcon";
import DistanceUnitIcon from "../../../../res/images/settings/distance_unit.svg";
import * as RxUtils from "../../../../utils/RxUtils";
import { LocalUserPreferenceKeys } from "../../../../domain/model/PreferencesData";
import * as RxOperators from "rxjs/operators";
import { DistanceUnit, getDistanceUnit } from "../../../../domain/model/DistanceUnit";
import { t } from "i18next";

export class DistanceUnitSettingItemViewModel extends ActionItemViewModel {
    // Properties

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.distanceUnits"),
            SettingItemIcon.withUrl(DistanceUnitIcon),
            () => this.toggleDistanceUnit(),
            RxUtils.nonNullObservable(
                localPreferencesRepository.observePreference<string>(
                    LocalUserPreferenceKeys.selections.selectedDistanceUnitName,
                ),
            ).pipe(
                RxOperators.map((value) => getDistanceUnit(value)),
                RxOperators.map((unitName) => this.translateUnitNames(unitName).toUpperCase()),
            ),
        );
    }

    // Private functions

    private toggleDistanceUnit(): void {
        this.setDistanceUnit(
            this.getDistanceUnit() === DistanceUnit.METRIC ? DistanceUnit.IMPERIAL : DistanceUnit.METRIC,
        );
    }

    private getDistanceUnit(): DistanceUnit {
        const value = this.localPreferencesRepository.getPreference<string>(
            LocalUserPreferenceKeys.selections.selectedDistanceUnitName,
        );
        return getDistanceUnit(value);
    }

    private setDistanceUnit(unit: DistanceUnit): void {
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.selections.selectedDistanceUnitName,
            unit as string,
        );
    }

    private translateUnitNames(distanceUnit: DistanceUnit): string {
        switch (distanceUnit) {
            case DistanceUnit.IMPERIAL:
                return t("settings.distanceUnitsImperial");
            case DistanceUnit.METRIC:
            default:
                return t("settings.distanceUnitsMetric");
        }
    }
}
