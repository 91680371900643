import { Archetype as ArchetypeProto } from "./proto/generated/classificationlist3_pb";
import { ClassificationJSON } from "./json";

export enum Archetype {
    BIRD,
    DRONE,
    AIRCRAFT,
    VEHICLE,
    OTHER,
    UNKNOWN,
}

export function archetypeFromProto(model: ArchetypeProto): Archetype {
    switch (model) {
        case ArchetypeProto.BIRD:
            return Archetype.BIRD;
        case ArchetypeProto.DRONE:
            return Archetype.DRONE;
        case ArchetypeProto.AIRCRAFT:
            return Archetype.AIRCRAFT;
        case ArchetypeProto.VEHICLE:
            return Archetype.VEHICLE;
        case ArchetypeProto.OTHER:
            return Archetype.OTHER;
        case ArchetypeProto.UNKNOWN:
        default:
            return Archetype.UNKNOWN;
    }
}

export function archetypeFromClassificationJson(model: ClassificationJSON): Archetype {
    switch (model.classification) {
        case "DRONE":
        case "SUSPECTED_DRONE":
        case "FIXED_WING":
        case "SUSPECTED_FIXED_WING":
            return Archetype.DRONE;
        case "AIRCRAFT":
        case "HELICOPTER":
            return Archetype.AIRCRAFT;
        case "VEHICLE":
            return Archetype.VEHICLE;
        case "OTHER":
            return Archetype.OTHER;
        default:
            return Archetype.UNKNOWN;
    }
}
