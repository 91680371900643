import React from "react";
import * as Rx from "rxjs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class BaseSubscriptionHandlerComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
    protected subscriptions: Rx.Subscription = new Rx.Subscription();

    public componentWillUnmount(): void {
        this.subscriptions.unsubscribe();
    }

    public collectSubscription(subscription: Rx.Subscription): void {
        this.subscriptions.add(subscription);
    }

    public collectSubscriptions(...subscriptions: Rx.Subscription[]): void {
        subscriptions.forEach((subscription) => this.collectSubscription(subscription));
    }
}
