import { MapModuleViewModel } from "../MapModuleViewModel";
import * as Rx from "rxjs";
import {
    OverlayRepository,
    OverlayEditorRepository,
    EditState,
    EditMode,
    OverlaySelection,
} from "../../../../domain/repositories";
import { Overlay } from "../../../../domain/model";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";

export class OverlaysModuleViewModel extends MapModuleViewModel {
    // Properties

    public get editState(): Rx.Observable<EditState> {
        return this.overlayEditorRepository.editState;
    }

    public get isolatedOverlays(): Rx.Observable<string[]> {
        return this.overlayEditorRepository.isolatedOverlays;
    }

    public get overlayUpdates(): Rx.Observable<Map<string, Overlay>> {
        return this.overlayRepository.overlays;
    }
    public readonly isOverlayEditingEnabled = this.flavorConfig.overlayEditingEnabled;

    public constructor(
        private readonly overlayRepository: OverlayRepository,
        private readonly overlayEditorRepository: OverlayEditorRepository,
        private readonly flavorConfig: FlavorConfig,
    ) {
        super();
    }

    // Public functions

    public observeOverlayVisibility(overlayName: string): Rx.Observable<boolean> {
        return this.overlayRepository.observeOverlayVisibility(overlayName);
    }

    public selectShape(selection: OverlaySelection, selected: boolean): void {
        this.overlayEditorRepository.setMode(EditMode.SELECT, selected ? [selection] : []);
    }
}
