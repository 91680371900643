import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import { UserManagementRepository } from "../../domain/repositories/UserManagementRepository";
import { User } from "../../domain/model/User";
import { UserRole } from "../../domain/model/UserRole";
import * as RxOperators from "rxjs/operators";
import { SessionRepository } from "../../domain/repositories";
import { UserPermission } from "../../domain/model/UserPermission";

enum Action {
    Edit,
    Add,
    Delete,
}

export class UserManagementViewModel extends BaseViewModel {
    // Properties

    public get errorObservable(): Rx.Observable<Error> {
        return this.error.asObservable();
    }
    public get usersObservable(): Rx.Observable<User[]> {
        return this.userManagementRepository.usersObservable;
    }
    public get rolesObservable(): Rx.Observable<UserRole[]> {
        return this.userManagementRepository.rolesObservable;
    }
    private error = new Rx.Subject<Error>();
    private currentUser: User | null = null;

    // Lifecycle

    public constructor(
        private readonly userManagementRepository: UserManagementRepository,
        private readonly sessionRepository: SessionRepository,
    ) {
        super();

        this.sessionRepository.session.subscribe((session) => {
            this.currentUser = session.user;
        });
    }

    // Public functions

    public fetchInitialData(): Rx.Observable<void> {
        return this.userManagementRepository.fetchInitialData().pipe(
            RxOperators.tap({
                error: (error) => this.error.next(error),
            }),
        );
    }

    public editUser(user: User): Rx.Observable<void> {
        return this.performActionForUser(user, Action.Edit);
    }

    public addUser(user: User): Rx.Observable<void> {
        return this.performActionForUser(user, Action.Add);
    }

    public deleteUser(user: User): Rx.Observable<void> {
        return this.performActionForUser(user, Action.Delete);
    }

    public isSelf(user: User): boolean {
        return this.currentUser != null && this.currentUser.id === user.id;
    }

    public canCurrentUserEditUsers(): boolean {
        return (
            this.currentUser != null &&
            this.currentUser.role.permissions &&
            this.currentUser.role.permissions.includes(UserPermission.CanEditUsers)
        );
    }

    // Private functions

    private performActionForUser(user: User, action: Action): Rx.Observable<void> {
        let completable: Rx.Observable<void>;
        switch (action) {
            case Action.Add:
                completable = this.userManagementRepository.addUser(user);
                break;
            case Action.Edit:
                completable = this.userManagementRepository.editUser(user);
                break;
            case Action.Delete:
                completable = this.userManagementRepository.deleteUser(user);
                break;
        }
        completable = completable!.pipe(
            RxOperators.tap({
                error: (error) => this.error.next(error),
            }),
        );
        return completable;
    }
}
