import {
    getAvailableConfiguredLanguages,
    Language,
    LanguageResource,
    LANGUAGE_RESOURCES,
} from "../../infrastructure/localization";
import { ACTIVE_LANGUAGE, storeActiveLanguageCode } from "../../infrastructure/localization";
import { BaseViewModel } from "../BaseViewModel";

export class LanguageSelectionViewModel extends BaseViewModel {
    // Properties

    public get availableLanguages(): string[] {
        return getAvailableConfiguredLanguages();
    }

    public get activeLanguage(): Language {
        return ACTIVE_LANGUAGE;
    }

    public get languageResources(): LanguageResource {
        return LANGUAGE_RESOURCES;
    }

    // Public functions

    public setActiveLanguage(code: string): void {
        storeActiveLanguageCode(code);
    }
}
