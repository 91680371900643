import { RadarOperatingModeStatusJSON as RadarOperatingModeStatusJSON } from "./json";

export class RadarOperatingModeStatus {
    public static fromJson(model: RadarOperatingModeStatusJSON): RadarOperatingModeStatus {
        return new RadarOperatingModeStatus(model.id, model.ready, model.errorDetail);
    }

    public constructor(
        public readonly id: string,
        public readonly ready: boolean,
        public readonly errorDetail?: string,
    ) {}
}
