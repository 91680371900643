import { UserJSON } from "./json";
import { UserManagementPermission } from "./UserPermission";
import { UserRole } from "./UserRole";
import { User as UserProto } from "./proto/generated/users3_pb";

export class User {
    // Static functions

    public static fromProto(model?: UserProto, userManagementPermission?: UserManagementPermission): User {
        if (!model) {
            return User.birdViewerObserverUser();
        }

        return new User(
            // replace with model.id when it's provided by api
            String(model.getId()),
            model.getNicename(),
            model.getLoginname(),
            UserRole.fromProto(model.getRole(), userManagementPermission),
            null,
            // replace with model.email when it's provided by api
            model.getEmail(),
            model.getActive(),
        );
    }

    public static fromJson(model: UserJSON, userManagementPermission?: UserManagementPermission): User {
        return new User(
            model.id,
            model.name,
            model.username,
            UserRole.fromJson(model.userRole, userManagementPermission),
            null,
            model.email,
            model.active,
        );
    }

    public static birdViewerObserverUser(username = ""): User {
        return new User(username, username, username, UserRole.birdViewerObserverRole(), null, "", true);
    }

    // Public functions

    public constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly username: string,
        public readonly role: UserRole,
        public readonly password: string | null,
        public readonly email: string,
        public readonly isActive: boolean,
    ) {}

    public toJson(): UserJSON {
        let password = "";
        if (this.password) {
            password = this.password;
        }
        return {
            id: this.id,
            name: this.name,
            username: this.username,
            userRole: this.role.toJson(),
            password: password,
            email: this.email,
            active: this.isActive,
        };
    }

    public toProto(ignoreUserId?: boolean): UserProto {
        let password = "";
        if (this.password) {
            password = this.password;
        }
        const user = new UserProto();
        !ignoreUserId && user.setId(Number(this.id));
        user.setNicename(this.name);
        user.setLoginname(this.username);
        user.setPassword(password);
        user.setActive(this.isActive);
        user.setEmail(this.email);
        user.setRole(this.role.toProto());
        return user;
    }
}
