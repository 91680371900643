import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalUserPreferenceKeys, GeolocationPosition, DistanceUnit } from "../../../../domain/model";
import { LocalPreferencesRepository, UserLocationRepository, UserLocationState } from "../../../../domain/repositories";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";
import * as RxUtils from "../../../../utils/RxUtils";
import { MapModuleViewModel } from "../MapModuleViewModel";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";

export class UserLocationModuleViewModel extends MapModuleViewModel {
    // Properties

    public get userLocationPosition(): Rx.Observable<GeolocationPosition | undefined> {
        return this.userLocationRespository.userLocationPosition;
    }

    public get shouldShowUserLocation(): Rx.Observable<boolean> {
        return this.userLocationRespository.userLocationState.pipe(
            RxOperators.map((s) => s !== UserLocationState.INACTIVE),
        );
    }

    public get shouldShowUserLocationRange(): Rx.Observable<boolean> {
        return RxUtils.nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.filters.showUserLocationRange,
            ),
        );
    }

    public get userLocationRangeRingsOpacity(): Rx.Observable<number> {
        return RxUtils.nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.appearance.rangeRingsOpacity,
            ),
        );
    }

    public get distanceUnit(): Rx.Observable<DistanceUnit> {
        return this.distanceFormatter.selectedDistanceUnitObservable;
    }

    public constructor(
        private readonly userLocationRespository: UserLocationRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly flavorConfig: FlavorConfig,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
    }
}
