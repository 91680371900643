import { useEffect, useRef, useState } from "react";
import { LocalPreferencesRepository, ServerConfigRepository, SessionRepository } from "../../domain/repositories";
import { useDI } from "../../hooks/useDI";
import { generateUUID } from "../../utils/UUID";
import { AuthMethod, LocalUserPreferenceKeys } from "../../domain/model";
import { TYPES } from "../../di/Types";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

interface Result {
    triggerOauthRedirect: () => void;
    authenticate: () => Rx.Observable<void>;
    getHasOauthError: () => boolean;
    isBusy: boolean;
}

export const useOauth = (): Result => {
    // Properties

    const localPreferencesRepository = useDI<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository);
    const sessionRepository = useDI<SessionRepository>(TYPES.SessionRepository);
    const serverConfigRepository = useDI<ServerConfigRepository>(TYPES.ServerConfigRepository);
    const subscriptionRef = useRef<Rx.Subscription>();
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        const subscription = new Rx.Subscription();
        subscriptionRef.current = subscription;
        return () => subscription.unsubscribe();
    }, []);

    // Local functions

    function getOauthRedirectUrl(): string {
        // No need to add `.port` here because the port is already included in the host
        const url = window.location.protocol + "//" + window.location.host + "/oauth";
        return url;
    }

    /**
     * Validates the oauth callback data and authenticates the user
     * @returns Completes when the user is authenticated
     */
    function authenticate(): Rx.Observable<void> {
        return serverConfigRepository.config.pipe(
            RxOperators.switchMap((config) => {
                // If the auth method is not oauth, we don't need to authenticate (the redirect will be handled in the App component)
                if (config.authMethod !== AuthMethod.OpenIdConnect) {
                    return Rx.EMPTY;
                }
                try {
                    const { code, state } = getOauthCallbackData();
                    return authenticateOpenIdConnect(code, state);
                } catch (error) {
                    return Rx.throwError(() => error);
                }
            }),
            RxOperators.tap({
                error: (error) => sessionRepository.setOauthError(error),
            }),
        );
    }

    /**
     * Authenticates the user using the oauth code and state
     * @param code The code received from the oauth callback
     * @param state The state received from the oauth callback
     * @returns An observable that completes when the user is authenticated
     */
    function authenticateOpenIdConnect(code: string, state: string): Rx.Observable<void> {
        const storedOauthState = retrieveOauthState();
        removeOauthState();
        if (state !== storedOauthState) {
            return Rx.throwError(() => new Error("Oauth state does not match stored value"));
        }
        const nonce = retrieveNonce();
        const oauthRedirectUrl = getOauthRedirectUrl();
        return sessionRepository.loginOpenIdConnect(code, oauthRedirectUrl, nonce).pipe(RxOperators.ignoreElements());
    }

    /**
     * Gets the oauth authorize url and redirects the user to it
     */
    function triggerOauthRedirect(): void {
        setIsBusy(true);
        subscriptionRef.current!.add(
            getOauthAuthorizeUrl().subscribe({
                next: (authorizeUrl) => {
                    setIsBusy(false);
                    window.location.href = authorizeUrl;
                },
            }),
        );
    }

    /**
     * Constructs the url to redirect the user to in order to authenticate
     * @returns a string representing the url
     */
    function getOauthAuthorizeUrl(): Rx.Observable<string> {
        sessionRepository.clearOauthError();
        const nonce = generateNonce();
        const oauthState = generateOauthState();
        const oauthRedirectUrl = getOauthRedirectUrl();
        return serverConfigRepository.config.pipe(
            RxOperators.map((config) => {
                let params = [
                    `client_id=${config.clientId}`,
                    `scope=openid email`,
                    `response_type=code`,
                    `nonce=${nonce}`,
                    `redirect_uri=${oauthRedirectUrl}`,
                    `state=${oauthState}`,
                ].join("&");

                if (
                    config.ssoProvider === "MICROSOFT_ADFS" ||
                    (config.ssoProvider === "MICROSOFT_AZURE" && config.ssoVersion === 1)
                ) {
                    params += `&resource=${config.clientId}`;
                }
                if (config.ssoProvider !== "MICROSOFT_AZURE") {
                    params += `&prompt=consent`;
                }
                return `${config.authorizeUrl}?${params}`;
            }),
        );
    }

    function getOauthCallbackData(): { code: string; state: string } {
        const urlParams = new URLSearchParams(window.location.search);
        const oAuthError = urlParams.get("error");
        if (oAuthError) {
            throw oAuthError;
        }
        const code = urlParams.get("code") ?? "";
        const state = urlParams.get("state") ?? "";
        return { code, state };
    }

    function generateNonce(): string {
        const nonce = generateUUID();
        localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.nonce, nonce);
        return nonce;
    }

    function retrieveNonce(): string {
        const nonce = localPreferencesRepository.getPreference<string>(LocalUserPreferenceKeys.user.nonce);
        return nonce || generateNonce();
    }

    function generateOauthState(): string {
        const state = generateUUID();
        localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.oauthState, state);
        return state;
    }

    function retrieveOauthState(): string {
        const state = localPreferencesRepository.getPreference<string>(LocalUserPreferenceKeys.user.oauthState);
        return state || generateOauthState();
    }

    function removeOauthState(): void {
        localPreferencesRepository.removePreference(LocalUserPreferenceKeys.user.oauthState);
    }

    function getHasOauthError(): boolean {
        return sessionRepository.getOauthError() !== undefined;
    }

    return {
        triggerOauthRedirect,
        authenticate,
        getHasOauthError,
        isBusy,
    };
};
