import { clamp } from "./MathUtils";

export interface RGB {
    r: int;
    g: int;
    b: int;
}

export function hexToRGB(hex: string): RGB {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        c = ("0x" + c.join("")) as any;
        return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255 };
    }
    throw new Error("Bad Hex");
}

export function getColorAndOpacity(originalColor: string): [string, number] {
    let fillColor: string;
    let opacity: number;
    if (originalColor.length > 7) {
        fillColor = "#" + originalColor.substring(3, 9);
        opacity = parseInt("0x" + originalColor.substring(1, 3)) / 255;
    } else if (originalColor === "") {
        fillColor = "#ffffff";
        opacity = 0;
    } else {
        fillColor = originalColor;
        opacity = 1;
    }
    return [fillColor, opacity];
}

export const hexColorWithAlpha = (hex: string, alpha: number): string =>
    `${hex}${Math.floor(clamp(alpha, 0, 1) * 255)
        .toString(16)
        .padStart(2, "0")}`;
