import * as Rx from "rxjs";
import { UserManagementRepository } from "../../../../domain/repositories/UserManagementRepository";
import { User } from "../../../../domain/model/User";
import { UserRole } from "../../../../domain/model/UserRole";
import * as RxOperators from "rxjs/operators";
import { BirdViewerGrpcAPI } from "../../infrastructure/api/BirdViewerGrpcAPI";

export class BirdViewerUserManagementRepository implements UserManagementRepository {
    // Properties

    public get usersObservable(): Rx.Observable<User[]> {
        return this.users.asObservable();
    }

    public get rolesObservable(): Rx.Observable<UserRole[]> {
        return this.userRoles.asObservable();
    }

    private users = new Rx.BehaviorSubject<User[]>([]);
    private userRoles = new Rx.BehaviorSubject<UserRole[]>([]);

    // Public functions

    public constructor(private readonly api: BirdViewerGrpcAPI) {}

    public fetchInitialData(): Rx.Observable<void> {
        return this.refreshData();
    }

    public addUser(user: User): Rx.Observable<void> {
        return this.api.addUser(user.toProto(true)).pipe(
            RxOperators.tap({
                complete: () => this.refreshData().subscribe(),
            }),
            RxOperators.ignoreElements(),
        );
    }

    public editUser(user: User): Rx.Observable<void> {
        return this.api.editUser(user.toProto()).pipe(
            RxOperators.tap({
                complete: () => this.refreshData().subscribe(),
            }),
            RxOperators.ignoreElements(),
        );
    }

    public deleteUser(user: User): Rx.Observable<void> {
        return this.api.deleteUser(user.toProto()).pipe(
            RxOperators.tap({
                complete: () => this.refreshData().subscribe(),
            }),
            RxOperators.ignoreElements(),
        );
    }

    public refreshData(): Rx.Observable<void> {
        return Rx.zip(this.api.getUserList(), this.api.getRolesList()).pipe(
            RxOperators.tap(([users, roles]) => {
                this.users.next(users.getUserList().map((user) => User.fromProto(user)));
                this.userRoles.next(
                    roles
                        .getRoleList()
                        .map((role) => UserRole.fromProto(role))
                        .sort(),
                );
            }),
            RxOperators.ignoreElements(),
        );
    }
}
