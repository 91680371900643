import styled from "styled-components";
import { PanelSection } from "./panels/PanelSection";

export const SIDEBAR_WIDTH = "280px";
const CLOCK_HEIGHT_DEFAULT = "110px";
const CLOCK_HEIGHT_LARGE = "140px";
const TOOLBAR_HEIGHT = "60px";

export const SidebarStyle = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${SIDEBAR_WIDTH};
    height: 100%;
    background-color: ${({ theme }) => theme.colors.backgrounds.panel};
    z-index: 1000;
`;

export const ClockContainer = styled(PanelSection)`
    min-height: ${CLOCK_HEIGHT_DEFAULT};
    &:has(.clock-title.hasDateText) {
        min-height: ${CLOCK_HEIGHT_LARGE};
    }
`;

export const SidebarContentContainer = styled.div<{ largeClock: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${({ theme }) => theme.spacing.x5};
    width: ${SIDEBAR_WIDTH};
    background-color: ${({ theme }) => theme.colors.backgrounds.panelSection};
    overflow-y: auto;
`;

export const SidebarToolbar = styled.div`
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.x1};
    padding: ${({ theme }) => theme.spacing.x2} ${({ theme }) => theme.spacing.x5};
    width: ${SIDEBAR_WIDTH};
    height: ${TOOLBAR_HEIGHT};
    background-color: ${({ theme }) => theme.colors.backgrounds.panel};
`;

export const ScrollableSidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    width: 240px;
`;
