import { DeterrenceDeviceUpdate } from ".";
import { DeterrenceDeviceUpdateList as DeterrenceDeviceUpdateListProto } from "./proto/generated/deterrencedevices3_pb";

export class DeterrenceDeviceUpdateList {
    // Static functions

    public static fromProto(model: DeterrenceDeviceUpdateListProto): DeterrenceDeviceUpdateList {
        return new DeterrenceDeviceUpdateList(
            model.getUpdatesList().map((value) => DeterrenceDeviceUpdate.fromProto(value)),
            model.getResync(),
        );
    }

    public constructor(public readonly updates: DeterrenceDeviceUpdate[], public readonly resync: boolean) {}
}
