import { Overlay as OverlayProto, Shape as ShapeProto } from "./proto/generated/overlaylist3_pb";
import { Location, locationToJson } from ".";
import { ShapeJSON, ShapeType as ShapeTypeJSON, OverlayJSON, CoordinateJSON } from "./json";
import { uppercasedFirstLetter } from "../../utils/StringUtils";

export enum ShapeType {
    POINT = 0,
    POLYLINE = 1,
    POLYGON = 2,
    CIRCLE = 3,
    UNKNOWN = 4,
}

export abstract class PrimitiveShape {}

export class PointShape extends PrimitiveShape {
    public constructor(public readonly location: Location) {
        super();
    }
}

export class PolyLineShape extends PrimitiveShape {
    public constructor(public readonly locations: Location[], public readonly width: float) {
        super();
    }
}

export class PolyLineContourShape extends PrimitiveShape {
    public constructor(public readonly contour: Location[], public readonly width: float) {
        super();
    }
}

export class PolygonShape extends PrimitiveShape {
    public constructor(public readonly locations: Location[]) {
        super();
    }
}

export class CircleShape extends PrimitiveShape {
    public constructor(public readonly location: Location, public readonly radius: float) {
        super();
    }
}

export class UnknownShape extends PrimitiveShape {}

export class Shape {
    // Static functions

    public static primitiveShapeFromProto(model: ShapeProto): PrimitiveShape {
        switch (model.getSubshapeCase()) {
            case ShapeProto.SubshapeCase.POINTSHAPE:
                return new PointShape(Location.fromProto(model.getPointshape()!.getPosition()));
            case ShapeProto.SubshapeCase.POLYLINESHAPE:
                const polylinePoints = model
                    .getPolylineshape()!
                    .getPositionList()
                    .map((p) => Location.fromProto(p));
                return new PolyLineShape(polylinePoints, 0);
            case ShapeProto.SubshapeCase.POLYGONSHAPE:
                const polygonPoints = model
                    .getPolygonshape()!
                    .getPositionList()
                    .map((p) => Location.fromProto(p));
                return new PolygonShape(polygonPoints);
            case ShapeProto.SubshapeCase.CIRCLESHAPE:
                return new CircleShape(
                    Location.fromProto(model.getCircleshape()!.getPosition()),
                    model.getCircleshape()!.getRadius(),
                );
            case ShapeProto.SubshapeCase.SUBSHAPE_NOT_SET:
                return new UnknownShape();
        }
    }

    public static primitiveShapeFromJson(model: ShapeJSON): PrimitiveShape {
        switch (model.type) {
            case ShapeTypeJSON.SHAPE_POINT:
                return new PointShape(Location.fromJson(model.points[0]));
            case ShapeTypeJSON.SHAPE_POLYLINE:
                const hasFill = model.width > 0;
                const source = hasFill ? model.contour : model.points;
                const points = source.map((p) => Location.fromJson(p));
                return hasFill ? new PolyLineContourShape(points, model.width) : new PolyLineShape(points, model.width);
            case ShapeTypeJSON.SHAPE_POLYGON:
                const polygonPoints = model.points.map((p) => Location.fromJson(p));
                return new PolygonShape(polygonPoints);
            case ShapeTypeJSON.SHAPE_CIRCLE:
                return new CircleShape(Location.fromJson(model.points[0]), model.radius);
            case ShapeTypeJSON.SHAPE_UNKNOWN:
            default:
                return new UnknownShape();
        }
    }

    public static fromProto(model: ShapeProto): Shape {
        return new Shape(
            model.getId().toString(),
            model.getLabel(),
            model.getLinecolor(),
            model.getFillcolor(),
            Shape.primitiveShapeFromProto(model),
        );
    }

    public static fromJson(model: ShapeJSON): Shape {
        return new Shape(
            model.id.toString(),
            model.label,
            model.lineColor,
            model.fillColor,
            Shape.primitiveShapeFromJson(model),
            model.minAltitude,
            model.maxAltitude,
        );
    }

    public constructor(
        public readonly id: string,
        public readonly label: string,
        public readonly lineColor: string,
        public readonly fillColor: string,
        public readonly shape: PrimitiveShape,
        public readonly minAltitude?: number,
        public readonly maxAltitude?: number,
    ) {}
}

export class Overlay {
    // Static functions

    public static fromProto(model: OverlayProto): Overlay {
        return new Overlay(
            model.getName(),
            model.getShapesList().map((s) => Shape.fromProto(s)),
        );
    }

    public static fromJson(model: OverlayJSON): Overlay {
        return new Overlay(
            model.name,
            model.shapes.map((s) => Shape.fromJson(s as ShapeJSON)),
        );
    }

    public constructor(public readonly name: string, public readonly shapes: Shape[]) {}
}

export function shapeToJson(shape: Shape): ShapeJSON {
    let points: CoordinateJSON[] = [];
    let type = ShapeTypeJSON.SHAPE_UNKNOWN;
    let radius = 0;
    let width = 0;

    const primitiveShape = shape.shape;
    if (primitiveShape instanceof PointShape) {
    } else if (primitiveShape instanceof PointShape) {
        points = [locationToJson(primitiveShape.location)];
        type = ShapeTypeJSON.SHAPE_POINT;
    } else if (primitiveShape instanceof PolyLineShape) {
        points = primitiveShape.locations.map((l) => locationToJson(l));
        type = ShapeTypeJSON.SHAPE_POLYLINE;
        width = primitiveShape.width || 0;
    } else if (primitiveShape instanceof PolygonShape) {
        points = primitiveShape.locations.map((l) => locationToJson(l));
        type = ShapeTypeJSON.SHAPE_POLYGON;
    } else if (primitiveShape instanceof CircleShape) {
        points = [locationToJson(primitiveShape.location)];
        radius = primitiveShape.radius;
        type = ShapeTypeJSON.SHAPE_CIRCLE;
    }

    return {
        points,
        radius,
        type,
        fillColor: shape.fillColor,
        lineColor: shape.lineColor,
        id: parseInt(shape.id),
        label: shape.label,
        width: width,
        contour: [],
        minAltitude: shape.minAltitude,
        maxAltitude: shape.maxAltitude,
    };
}

export function overlayToJson(overlay: Overlay): OverlayJSON {
    return {
        name: overlay.name,
        prettyname: overlay.name,
        shapes: overlay.shapes.map((s) => shapeToJson(s)),
    };
}

export function cleanUpOverlayName(name: string, removePrefix: boolean): string {
    let result: string;
    if (removePrefix && name.includes("_") && name.indexOf("_") < name.length - 1) {
        result = name.substring(name.indexOf("_") + 1);
    } else {
        result = name;
    }
    result = result.replace(/_/g, " ");
    result = uppercasedFirstLetter(result);
    return result;
}
