import React, { Children, Component, PropsWithChildren, ReactFragment, ReactNode, ReactPortal } from "react";
import styled from "styled-components";
import { OldColors } from "../Colors";
import { OVERLAY_SPACING } from "../theme/Spacing";

const CORNER_RADIUS = "10px";
const FADE_DURATION_MILLISECONDS = 300;

interface OverlayViewGroupProps {
    isVisible?: boolean;
    isChildVisible?: boolean[];
    animationDurationMillis?: long;
    side: "left" | "right" | "center";
}

interface OverlayViewGroupState {
    shouldShowChildren: boolean;
}

const BaseOverlayViewGroupStyle = styled.div<OverlayViewGroupProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    max-height: calc(var(--dvh-unit) * 100 - ${OVERLAY_SPACING} * 2);
`;

const InternalOverlayViewGroupCenter = styled(BaseOverlayViewGroupStyle)`
    border-radius: ${CORNER_RADIUS};
`;

const InternalOverlayViewGroupLeft = styled(BaseOverlayViewGroupStyle)`
    margin-left: ${({ theme }) => theme.spacing.x3};
`;

const InternalOverlayViewGroupRight = styled(BaseOverlayViewGroupStyle)`
    margin-right: ${({ theme }) => theme.spacing.x3};
`;

const AnimatingWrapper = styled.div<OverlayViewGroupProps>`
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity ${(props) => props.animationDurationMillis || FADE_DURATION_MILLISECONDS}ms ease-out;
`;

const BaseOverlayViewStyle = styled.div`
    background-color: ${OldColors.black};
    position: relative;
    pointer-events: all;
`;

const InternalOverlayView = styled(BaseOverlayViewStyle)`
    border-radius: ${CORNER_RADIUS};
    box-shadow: ${({ theme }) =>
        `${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1} ${theme.colors.backgrounds.panel}}`};
    overflow: hidden;
`;

export class OverlayViewGroup extends Component<PropsWithChildren<OverlayViewGroupProps>, OverlayViewGroupState> {
    // Properties

    private lastTimeoutHandle: NodeJS.Timeout | undefined;

    public constructor(props: Readonly<OverlayViewGroupProps>) {
        super(props);
        this.state = { shouldShowChildren: true };
    }

    // Public functions

    public componentWillUnmount(): void {
        if (this.lastTimeoutHandle) {
            clearTimeout(this.lastTimeoutHandle);
        }
    }

    public componentDidUpdate(): void {
        if (this.lastTimeoutHandle) {
            clearTimeout(this.lastTimeoutHandle);
        }
        if (this.props.isVisible === this.state.shouldShowChildren) {
            return;
        }
        const delay = this.props.isVisible ? 0 : this.props.animationDurationMillis || FADE_DURATION_MILLISECONDS;
        this.lastTimeoutHandle = setTimeout(() => {
            this.setState({ shouldShowChildren: this.props.isVisible || false });
        }, delay);
    }

    public render(): ReactNode {
        return (
            <AnimatingWrapper {...this.props}>
                {this.state.shouldShowChildren && this.getWrappedOverlayViewGroup()}
            </AnimatingWrapper>
        );
    }

    // Private functions

    private getWrappedOverlayViewGroup(): ReactNode {
        if (this.props.isVisible === false) {
            return <div></div>;
        }
        switch (this.props.side) {
            case "right":
                return (
                    <InternalOverlayViewGroupRight {...this.props}>
                        {this.getWrappedOverlayViews()}
                    </InternalOverlayViewGroupRight>
                );
            case "left":
                return (
                    <InternalOverlayViewGroupLeft {...this.props}>
                        {this.getWrappedOverlayViews()}
                    </InternalOverlayViewGroupLeft>
                );
            case "center":
                return (
                    <InternalOverlayViewGroupCenter {...this.props}>
                        {this.getWrappedOverlayViews()}
                    </InternalOverlayViewGroupCenter>
                );
        }
    }

    private getWrappedOverlayViews(
        wrapFirstChild?: (child: ReactNode | ReactFragment | ReactPortal) => ReactNode,
    ): ReactNode {
        let isFirstChild = true;
        return Children.map(Children.toArray(this.props.children), (child, index) => {
            if (this.props.isChildVisible && !this.props.isChildVisible[index]) {
                return;
            }
            if (wrapFirstChild && isFirstChild) {
                isFirstChild = false;
                return wrapFirstChild(child);
            }
            return <InternalOverlayView>{child}</InternalOverlayView>;
        });
    }
}
