import React, { useState } from "react";
import { RadarStatus } from "../../domain/model";
import * as Rx from "rxjs";
import { t } from "i18next";
import { Button } from "../appearance/button/Button";

interface Props {
    onRequestToggleRadar: () => Rx.Observable<void>;
    radarStatus: RadarStatus;
}

export const RadarStartStop = ({ onRequestToggleRadar, radarStatus }: Props): JSX.Element => {
    // Properties

    const [isTogglingRadar, setIsTogglingRadar] = useState(false);

    // Local functions

    const isRadarRunning = (): boolean => {
        return radarStatus != null && radarStatus.driveRunning;
    };

    const toggleSelectedRadar = (): (() => void) => {
        setIsTogglingRadar(true);
        const subscription = onRequestToggleRadar().subscribe({
            complete: () => {
                setIsTogglingRadar(false);
            },
            error: (e) => {
                console.error(e);
                setIsTogglingRadar(false);
            },
        });
        return () => {
            subscription.unsubscribe();
        };
    };

    // Render

    const label = t(`radarControlPanel.${isRadarRunning() ? "stopRadar" : "startRadar"}`);
    const tooltip = radarStatus.isOnAutoStart ? t("radarControlPanel.radarAutoStartEnabled") : undefined;
    return (
        <Button
            color={isRadarRunning() ? "error" : "default"}
            disabled={radarStatus.isOnAutoStart || isTogglingRadar}
            loading={isTogglingRadar}
            label={label}
            onClick={toggleSelectedRadar}
            tooltip={tooltip}
        />
    );
};
