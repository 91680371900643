import styled, { css } from "styled-components";

interface Props {
    vertical?: boolean;
}

const FormStyling = css<Props>`
    display: flex;
    flex-direction: column;
    gap: ${({ theme, vertical }) => (vertical ? theme.spacing.x5 : theme.spacing.x2)};
    width: 100%;
    & + form {
        margin-top: ${({ theme }) => theme.spacing.x4};
    }
`;

/**
 * @description Styling of form elements
 * @param vertical Vertical layout adds more space between form control elements
 */
export const Form = styled.form<Props>`
    ${FormStyling}
`;

/**
 * @description Styling of sub-sections of a form (e.g. a collapsible section)
 * @param vertical Vertical layout adds more space between form control elements
 */
export const FormSection = styled.div<Props>`
    ${FormStyling}
`;
