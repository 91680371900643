import React from "react";
import { t } from "i18next";
import { ComponentStatus } from "./ComponentStatus";
import { RadarStatusImageQuality } from "../../../../domain/model";
import { Colors } from "../../../appearance/Colors";

interface Props {
    status: RadarStatusImageQuality;
}

export const ImageQualityStatus = ({ status }: Props): JSX.Element => {
    // Local functions

    const getColorFromImageQuality = (): string => {
        switch (status) {
            case RadarStatusImageQuality.Bad:
                return Colors.status.danger;
            case RadarStatusImageQuality.Degraded:
                return Colors.status.warning;
            case RadarStatusImageQuality.Good:
                return Colors.status.success;
            case RadarStatusImageQuality.Unknown:
                return Colors.status.disabled;
        }
    };

    const getTooltipFromImageQuality = (): string => {
        switch (status) {
            case RadarStatusImageQuality.Bad:
                return t("radarStatus.imageQualityBad");
            case RadarStatusImageQuality.Degraded:
                return t("radarStatus.imageQualityDegraded");
            case RadarStatusImageQuality.Good:
                return t("radarStatus.imageQualityGood");
            case RadarStatusImageQuality.Unknown:
                return t("radarStatus.imageQualityUnknown");
        }
    };

    // Render

    return (
        <ComponentStatus
            color={getColorFromImageQuality()}
            hasMessage={false}
            label={t("radarStatus.imageQuality")}
            tooltip={getTooltipFromImageQuality()}
        />
    );
};
