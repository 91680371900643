import { isUnimplementedError } from "../../apps/birdviewer/infrastructure/api/BirdViewerGrpcAPI";
import { SessionState } from "../model";
import * as grpcWeb from "grpc-web";

export interface BaseRepository {}

export interface StartableRepository extends BaseRepository {
    start(): void;
    stop(): void;
}

export abstract class AbstractStartableRepository implements StartableRepository {
    // Properties

    private internalIsStarted = false;

    abstract start(): void;

    abstract stop(): void;

    // Public functions

    public isStarted(): boolean {
        return this.internalIsStarted;
    }

    public safelyUpdateStateWithSessionState(state: SessionState): void {
        switch (state) {
            case SessionState.LoggedIn:
                this.startInternal();
                break;
            case SessionState.LoggedOut:
            case SessionState.LoggingOutDueToUnauthorisedResponse:
                this.stopInternal();
                break;
        }
    }

    // Protected functions

    protected retryIfStartedAndImplemented(
        action: () => void,
        error?: grpcWeb.Error,
        delay: int = parseInt(process.env.REACT_APP_REPEAT_DELAY_ON_ERROR_MSEC!),
    ): void {
        if (error && isUnimplementedError(error)) {
            console.warn(error);
            return;
        }

        if (error) {
            console.error(error);
        }

        if (this.isStarted()) {
            setTimeout(() => action(), delay);
        }
    }

    // Private functions

    private startInternal(): void {
        if (this.internalIsStarted) {
            return;
        }
        this.internalIsStarted = true;
        this.start();
    }

    private stopInternal(): void {
        if (!this.internalIsStarted) {
            return;
        }
        this.internalIsStarted = false;
        this.stop();
    }
}
