import React from "react";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { TYPES } from "../../di/Types";
import DI from "./../../di/DI";
import { TrackListViewModel } from "./TrackListViewModel";
import { TrackListEntryProps } from "./TrackListEntry";
import { TrackListEntryViewModel } from "./TrackListEntryViewModel";
import * as LegendIcon from "../legend/LegendIcon";
import { TrackListSection } from "./TrackListSection";
import { t } from "i18next";
import { Panel } from "../appearance/panels/Panel";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { PanelSection } from "../appearance/panels/PanelSection";

interface Props {
    onClose: () => void;
}

interface State {
    live: TrackListEntryProps[];
    finished: TrackListEntryProps[];
    finishedTracksEnabled: boolean;
}

export class TrackList extends BaseSubscriptionHandlerComponent<Props, State> {
    private readonly viewModel: TrackListViewModel = DI.get(TYPES.TrackListViewModel);

    public constructor(props: Props) {
        super(props);
        this.state = {
            live: [],
            finished: [],
            finishedTracksEnabled: true,
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel>
                <PanelHeader label="Track list" onClose={() => this.props.onClose()} />
                <PanelSection>
                    <TrackListSection
                        trackProps={this.state.live}
                        headerLabel={`${t("trackList.liveTracks")} (${this.state.live.length})`}
                        message={this.state.live.length === 0 ? t("trackList.noLiveTracks") : undefined}
                    />
                    <TrackListSection
                        trackProps={this.state.finished}
                        headerLabel={t("trackList.finishedTracks", { count: this.state.finished.length })}
                        message={
                            this.state.finishedTracksEnabled
                                ? this.state.finished.length === 0
                                    ? t("trackList.noFinishedTracks")
                                    : undefined
                                : t("trackList.finishedTracksDisabled")
                        }
                    />
                </PanelSection>
            </Panel>
        );
    }

    public componentDidMount(): void {
        this.collectSubscriptions(
            this.viewModel.tracklistEntries.subscribe(
                (tracks) => this.updateTracks(tracks.live, tracks.finished),
                (error) => console.error(error),
            ),
            this.viewModel.finishedTracksEnabled.subscribe((finishedTracksEnabled) =>
                this.setState({ finishedTracksEnabled }),
            ),
        );
    }

    private updateTracks(liveTracks: TrackListEntryViewModel[], finishedTracks: TrackListEntryViewModel[]): void {
        this.setState({
            live: liveTracks.map((t) => this.getTrackListEntryProps(t)),
            finished: finishedTracks.map((t) => this.getTrackListEntryProps(t)),
        });
    }

    private getTrackListEntryProps(t: TrackListEntryViewModel): TrackListEntryProps {
        const iconComponent = t.classification && LegendIcon.getComponentFromClassification(t.classification, 1);
        return {
            name: `#${t.id}`,
            iconNode: iconComponent,
            isAlarm: t.isAlarm,
            defaultColor: t.classification ? t.classification.defaultColor : undefined,
            isSelected: t.isSelected,
            onClick: () => this.viewModel.toggleTrackSelection(t.id),
        };
    }
}
