// source: loginresult3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var users3_pb = require('./users3_pb.js');
goog.object.extend(proto, users3_pb);
goog.exportSymbol('proto.robin.proto3.login.BasicAuthLoginRequest', null, global);
goog.exportSymbol('proto.robin.proto3.login.BasicAuthLoginResult', null, global);
goog.exportSymbol('proto.robin.proto3.login.LoginRequest', null, global);
goog.exportSymbol('proto.robin.proto3.login.LoginResult', null, global);
goog.exportSymbol('proto.robin.proto3.login.OpenIdConnectLoginRequest', null, global);
goog.exportSymbol('proto.robin.proto3.login.OpenIdConnectLoginResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.login.LoginResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.login.LoginResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.login.LoginResult.displayName = 'proto.robin.proto3.login.LoginResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.login.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.login.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.login.LoginRequest.displayName = 'proto.robin.proto3.login.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.login.BasicAuthLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.login.BasicAuthLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.login.BasicAuthLoginRequest.displayName = 'proto.robin.proto3.login.BasicAuthLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.login.BasicAuthLoginResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.login.BasicAuthLoginResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.login.BasicAuthLoginResult.displayName = 'proto.robin.proto3.login.BasicAuthLoginResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.login.OpenIdConnectLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.login.OpenIdConnectLoginRequest.displayName = 'proto.robin.proto3.login.OpenIdConnectLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.login.OpenIdConnectLoginResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.login.OpenIdConnectLoginResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.login.OpenIdConnectLoginResult.displayName = 'proto.robin.proto3.login.OpenIdConnectLoginResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.login.LoginResult.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.login.LoginResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.login.LoginResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.LoginResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    magicnumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    versioncode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    versionname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statuscode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    statusmessage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userlist: (f = msg.getUserlist()) && users3_pb.UserList.toObject(includeInstance, f),
    nicename: jspb.Message.getFieldWithDefault(msg, 7, ""),
    user: (f = msg.getUser()) && users3_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.login.LoginResult}
 */
proto.robin.proto3.login.LoginResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.login.LoginResult;
  return proto.robin.proto3.login.LoginResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.login.LoginResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.login.LoginResult}
 */
proto.robin.proto3.login.LoginResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setMagicnumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersioncode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionname(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatuscode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusmessage(value);
      break;
    case 6:
      var value = new users3_pb.UserList;
      reader.readMessage(value,users3_pb.UserList.deserializeBinaryFromReader);
      msg.setUserlist(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNicename(value);
      break;
    case 8:
      var value = new users3_pb.User;
      reader.readMessage(value,users3_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.login.LoginResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.login.LoginResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.login.LoginResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.LoginResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMagicnumber();
  if (f !== 0) {
    writer.writeFixed32(
      1,
      f
    );
  }
  f = message.getVersioncode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getVersionname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatuscode();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getStatusmessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserlist();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      users3_pb.UserList.serializeBinaryToWriter
    );
  }
  f = message.getNicename();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      users3_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional fixed32 magicNumber = 1;
 * @return {number}
 */
proto.robin.proto3.login.LoginResult.prototype.getMagicnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.setMagicnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 versionCode = 2;
 * @return {number}
 */
proto.robin.proto3.login.LoginResult.prototype.getVersioncode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.setVersioncode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string versionName = 3;
 * @return {string}
 */
proto.robin.proto3.login.LoginResult.prototype.getVersionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.setVersionname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 statusCode = 4;
 * @return {number}
 */
proto.robin.proto3.login.LoginResult.prototype.getStatuscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.setStatuscode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string statusMessage = 5;
 * @return {string}
 */
proto.robin.proto3.login.LoginResult.prototype.getStatusmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.setStatusmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional robin.proto3.users.UserList userList = 6;
 * @return {?proto.robin.proto3.users.UserList}
 */
proto.robin.proto3.login.LoginResult.prototype.getUserlist = function() {
  return /** @type{?proto.robin.proto3.users.UserList} */ (
    jspb.Message.getWrapperField(this, users3_pb.UserList, 6));
};


/**
 * @param {?proto.robin.proto3.users.UserList|undefined} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
*/
proto.robin.proto3.login.LoginResult.prototype.setUserlist = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.clearUserlist = function() {
  return this.setUserlist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.login.LoginResult.prototype.hasUserlist = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string niceName = 7;
 * @return {string}
 */
proto.robin.proto3.login.LoginResult.prototype.getNicename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.setNicename = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional robin.proto3.users.User user = 8;
 * @return {?proto.robin.proto3.users.User}
 */
proto.robin.proto3.login.LoginResult.prototype.getUser = function() {
  return /** @type{?proto.robin.proto3.users.User} */ (
    jspb.Message.getWrapperField(this, users3_pb.User, 8));
};


/**
 * @param {?proto.robin.proto3.users.User|undefined} value
 * @return {!proto.robin.proto3.login.LoginResult} returns this
*/
proto.robin.proto3.login.LoginResult.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.login.LoginResult} returns this
 */
proto.robin.proto3.login.LoginResult.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.login.LoginResult.prototype.hasUser = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.login.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.login.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.login.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loginpassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loginoptions: jspb.Message.getFieldWithDefault(msg, 3, ""),
    versionname: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.login.LoginRequest}
 */
proto.robin.proto3.login.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.login.LoginRequest;
  return proto.robin.proto3.login.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.login.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.login.LoginRequest}
 */
proto.robin.proto3.login.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginpassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginoptions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.login.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.login.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.login.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoginpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoginoptions();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersionname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string loginName = 1;
 * @return {string}
 */
proto.robin.proto3.login.LoginRequest.prototype.getLoginname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginRequest} returns this
 */
proto.robin.proto3.login.LoginRequest.prototype.setLoginname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loginPassword = 2;
 * @return {string}
 */
proto.robin.proto3.login.LoginRequest.prototype.getLoginpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginRequest} returns this
 */
proto.robin.proto3.login.LoginRequest.prototype.setLoginpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loginOptions = 3;
 * @return {string}
 */
proto.robin.proto3.login.LoginRequest.prototype.getLoginoptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginRequest} returns this
 */
proto.robin.proto3.login.LoginRequest.prototype.setLoginoptions = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string versionName = 6;
 * @return {string}
 */
proto.robin.proto3.login.LoginRequest.prototype.getVersionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.LoginRequest} returns this
 */
proto.robin.proto3.login.LoginRequest.prototype.setVersionname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.login.BasicAuthLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.login.BasicAuthLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.BasicAuthLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loginpassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    versionname: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.login.BasicAuthLoginRequest}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.login.BasicAuthLoginRequest;
  return proto.robin.proto3.login.BasicAuthLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.login.BasicAuthLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.login.BasicAuthLoginRequest}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginpassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.login.BasicAuthLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.login.BasicAuthLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.BasicAuthLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoginpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersionname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string loginName = 1;
 * @return {string}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.getLoginname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginRequest} returns this
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.setLoginname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loginPassword = 2;
 * @return {string}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.getLoginpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginRequest} returns this
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.setLoginpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string versionName = 3;
 * @return {string}
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.getVersionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginRequest} returns this
 */
proto.robin.proto3.login.BasicAuthLoginRequest.prototype.setVersionname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.login.BasicAuthLoginResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.login.BasicAuthLoginResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.BasicAuthLoginResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && users3_pb.User.toObject(includeInstance, f),
    accesstoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    idtoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult}
 */
proto.robin.proto3.login.BasicAuthLoginResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.login.BasicAuthLoginResult;
  return proto.robin.proto3.login.BasicAuthLoginResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.login.BasicAuthLoginResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult}
 */
proto.robin.proto3.login.BasicAuthLoginResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new users3_pb.User;
      reader.readMessage(value,users3_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.login.BasicAuthLoginResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.login.BasicAuthLoginResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.BasicAuthLoginResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users3_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdtoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult} returns this
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional robin.proto3.users.User user = 2;
 * @return {?proto.robin.proto3.users.User}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.getUser = function() {
  return /** @type{?proto.robin.proto3.users.User} */ (
    jspb.Message.getWrapperField(this, users3_pb.User, 2));
};


/**
 * @param {?proto.robin.proto3.users.User|undefined} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult} returns this
*/
proto.robin.proto3.login.BasicAuthLoginResult.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult} returns this
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string accessToken = 3;
 * @return {string}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult} returns this
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string idToken = 4;
 * @return {string}
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.getIdtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.BasicAuthLoginResult} returns this
 */
proto.robin.proto3.login.BasicAuthLoginResult.prototype.setIdtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.login.OpenIdConnectLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.login.OpenIdConnectLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authenticationcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nonce: jspb.Message.getFieldWithDefault(msg, 2, ""),
    redirecturi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    versionname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginRequest}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.login.OpenIdConnectLoginRequest;
  return proto.robin.proto3.login.OpenIdConnectLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.login.OpenIdConnectLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginRequest}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthenticationcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonce(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirecturi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.login.OpenIdConnectLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.login.OpenIdConnectLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthenticationcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRedirecturi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersionname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string authenticationCode = 1;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.getAuthenticationcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginRequest} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.setAuthenticationcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nonce = 2;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.getNonce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginRequest} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.setNonce = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string redirectUri = 3;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.getRedirecturi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginRequest} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.setRedirecturi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string versionName = 4;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.getVersionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginRequest} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginRequest.prototype.setVersionname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.login.OpenIdConnectLoginResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.login.OpenIdConnectLoginResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && users3_pb.User.toObject(includeInstance, f),
    accesstoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    idtoken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.login.OpenIdConnectLoginResult;
  return proto.robin.proto3.login.OpenIdConnectLoginResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.login.OpenIdConnectLoginResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new users3_pb.User;
      reader.readMessage(value,users3_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.login.OpenIdConnectLoginResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.login.OpenIdConnectLoginResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      users3_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdtoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional robin.proto3.users.User user = 2;
 * @return {?proto.robin.proto3.users.User}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.getUser = function() {
  return /** @type{?proto.robin.proto3.users.User} */ (
    jspb.Message.getWrapperField(this, users3_pb.User, 2));
};


/**
 * @param {?proto.robin.proto3.users.User|undefined} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult} returns this
*/
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string accessToken = 3;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string idToken = 4;
 * @return {string}
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.getIdtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.login.OpenIdConnectLoginResult} returns this
 */
proto.robin.proto3.login.OpenIdConnectLoginResult.prototype.setIdtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.robin.proto3.login);
