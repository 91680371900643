import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { INPUT_HEIGHT } from "../theme/Spacing";

/*
 * @todo: copy attributes from RoundInput that we want to keep
 */
export const InputField = styled.input<{ hasError?: boolean; large?: boolean }>`
    ${BASE_TEXT_STYLE}
    flex: 1;
    padding: ${({ large, theme }) => theme.spacing[large ? "x3" : "x1"]}
        ${({ large, theme }) => theme.spacing[large ? "x6" : "x2"]};
    width: 100%;
    height: ${INPUT_HEIGHT};
    background-color: ${({ theme }) => theme.colors.interactive.input};
    border: 1px solid ${({ hasError, theme }) => (hasError ? `${theme.colors.status.warning}` : "transparent")};
    border-radius: ${({ theme }) => theme.spacing.x2};
    color: ${({ hasError, theme }) => theme.colors.status[hasError ? "warning" : "neutral"]};

    transition: background-color ${({ theme }) => theme.transitionDuration} ease-out;
    ${({ large }) => large && "font-size: 16px;"}

    :is(:hover, :focus) {
        background-color: ${({ theme }) => theme.colors.interactive.input90};
    }
    :invalid {
        box-shadow: none;
    }
    :disabled {
        background-color: ${({ theme }) => theme.colors.interactive.inputDisabled};
        color: ${({ theme }) => theme.colors.text.text400};
        cursor: not-allowed;
    }
`;
