import * as mapboxgl from "mapbox-gl";
import turfBuffer from "@turf/buffer";
import turfCircle from "@turf/circle";
import * as turfHelpers from "@turf/helpers";
import { Feature, Geometry, GeoJsonProperties } from "geojson";
import {
    CircleShape,
    Overlay,
    PointShape,
    PolyLineContourShape,
    PolyLineShape,
    PolygonShape,
    Shape,
} from "./../../../domain/model";
import { getColorAndOpacity } from "../../../utils/ColorUtils";
import { OldColors } from "../../appearance/Colors";
import { OverlaySelection } from "../../../domain/repositories";
import { kebabify } from "../../../utils/StringUtils";
import { InteractiveMapLayer } from "./InteractiveMapLayer";
import { DistanceFormatter } from "../../../domain/DistanceFormatter";
import LocationIcon from "../../../res/images/location_outlined.svg";
import { t } from "i18next";
import { loadImageFromSVG } from "../../../utils/MapUtils";

const OVERLAYS_LAYER_ID_PREFIX = "layer-overlays-";
const OVERLAYS_SOURCE_ID_PREFIX = "source-overlays-";
const OVERLAYS_FILL_LAYER_ID_POSTFIX = "-fill";
const OVERLAYS_LINE_SYMBOL_LAYER_ID_POSTFIX = "-symbol-l";

const PROPERTY_OVERLAY_ID = "overlay-id";
const PROPERTY_SHAPE_ID = "shape-id";
const PROPERTY_SELECTED = "selected";
const PROPERTY_OUTLINE_STYLE = "outline-style";
const PROPERTY_FEATURE_LABEL = "label";

const OUTLINE_STYLE_SOLID = "outline-style-solid";
const OUTLINE_STYLE_DASHED = "outline-style-dashed";

const OVERLAY_POINT_SYMBOL_ID = "overlay-point-symbol";

export class OverlaysLayer extends InteractiveMapLayer {
    // Static functions

    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        distanceFormatter: DistanceFormatter,
    ): OverlaysLayer {
        return new OverlaysLayer(map, orderLayer, distanceFormatter);
    }

    // Properties

    public onShapeClicked?: (selection: OverlaySelection, isSelected: boolean) => void;

    private currentSourceIds: string[] = [];
    private currentLayerIds: string[] = [];
    private currentOverlays = new Map<string, Overlay>();
    private visibilities = new Map<string, boolean>();
    private isolatedOverlays: string[] = [];
    private isSelectionEnabled = false;

    // Create popup instance to be moved around when hovering shapes
    private popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
    });

    private constructor(
        readonly map: mapboxgl.Map,
        private orderLayer: string,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super(map);
        loadImageFromSVG(LocationIcon, (image) => this.map.addImage(OVERLAY_POINT_SYMBOL_ID, image));
    }

    // Public functions

    public setOverlays(overlays: Map<string, Overlay>): void {
        this.currentOverlays = overlays;
        this.currentLayerIds.forEach((id) => {
            if (this.map.getLayer(id)) {
                this.map.removeLayer(id);
            }
        });
        this.currentSourceIds.forEach((id) => {
            if (this.map.getSource(id)) {
                this.map.removeSource(id);
            }
        });
        overlays.forEach((overlay, name) => this.addLayerAndSourceForOverlay(name, overlay));
        this.restoreSelectionState();
    }

    public setOverlayVisibility(overlayName: string, visible: boolean): void {
        this.setOverlayVisibilityInternally(overlayName, visible, true);
        this.isolateOverlays(this.isolatedOverlays);
    }

    public isolateOverlays(overlayNames: string[]): void {
        this.isolatedOverlays = overlayNames;
        if (overlayNames.length === 0) {
            this.restoreOverlayVisibilitiesState();
            return;
        }

        Array.from(this.currentOverlays.values())
            .flatMap((o) =>
                o.shapes.map((shape) => ({
                    overlayName: o.name,
                    shape: shape,
                    visible: overlayNames.includes(o.name),
                })),
            )
            .forEach((data) => {
                this.setShapeVisibility(data.overlayName, data.shape, data.visible ? "visible" : "none");
            });
    }

    public setSelectionEnabled(enabled: boolean): void {
        this.isSelectionEnabled = enabled;
        Array.from(this.currentOverlays.values()).forEach((overlay) => {
            overlay.shapes.forEach((shape) => {
                const layerId = this.getLayerIdForShape(overlay.name, shape);
                if (enabled) {
                    this.addLayerEventListener({ type: "click", layer: layerId, listener: this.onFeatureClicked });
                    this.addLayerEventListener({
                        type: "click",
                        layer: layerId + OVERLAYS_FILL_LAYER_ID_POSTFIX,
                        listener: this.onFeatureClicked,
                    });
                } else {
                    this.removeLayerEventListener({ type: "click", layer: layerId, listener: this.onFeatureClicked });
                    this.removeLayerEventListener({
                        type: "click",
                        layer: layerId + OVERLAYS_FILL_LAYER_ID_POSTFIX,
                        listener: this.onFeatureClicked,
                    });
                }
            });
        });
    }

    public setShapeSelection(selection: OverlaySelection[]): void {
        Array.from(this.currentOverlays.values())
            .flatMap((o) => o.shapes.map((shape) => ({ overlayName: o.name, shape: shape })))
            .forEach((data) => {
                const selected = selection.some(
                    (s) => s.overlayName === data.overlayName && s.shape.id === data.shape.id,
                );
                this.updateFeature(data.overlayName, data.shape, selected);
            });
    }

    // Private functions

    private setOverlayVisibilityInternally(overlayName: string, visible: boolean, saveSate: boolean): void {
        const overlay = this.currentOverlays.get(overlayName);
        if (overlay == null) {
            return;
        }
        if (saveSate) {
            this.visibilities.set(overlayName, visible);
        }
        const visibility = visible ? "visible" : "none";
        overlay.shapes.forEach((shape) => this.setShapeVisibility(overlayName, shape, visibility));
    }

    private setShapeVisibility(overlayName: string, shape: Shape, visibility: "visible" | "none"): void {
        const layerId = this.getLayerIdForShape(overlayName, shape);
        if (this.map.getLayer(layerId)) {
            this.map.setLayoutProperty(layerId, "visibility", visibility);
        }
        const fillLayerId = layerId + OVERLAYS_FILL_LAYER_ID_POSTFIX;
        if (this.map.getLayer(fillLayerId)) {
            this.map.setLayoutProperty(fillLayerId, "visibility", visibility);
        }

        const lineSymbolLayerId = layerId + OVERLAYS_LINE_SYMBOL_LAYER_ID_POSTFIX;
        if (this.map.getLayer(lineSymbolLayerId)) {
            this.map.setLayoutProperty(lineSymbolLayerId, "visibility", visibility);
        }
    }

    private restoreOverlayVisibilitiesState(): void {
        this.visibilities.forEach((visible, overlayName) =>
            this.setOverlayVisibilityInternally(overlayName, visible, false),
        );
    }

    private restoreSelectionState(): void {
        this.setSelectionEnabled(this.isSelectionEnabled);
    }

    private addLayerAndSourceForOverlay(overlayName: string, overlay: Overlay): void {
        overlay.shapes.forEach((shape) => {
            const sourceId = this.getSourceIdForShape(overlayName, shape);
            const feature = this.getFeatureForShape(shape, overlayName, false);
            if (feature == null || feature.geometry == null) {
                return;
            }

            this.map.addSource(sourceId, {
                type: "geojson",
                data: feature as Feature<Geometry, GeoJsonProperties>,
            });
            this.currentSourceIds.push(sourceId);

            // Generate popup html
            const popupInnerHtml = this.generatePopupInnterHtml(overlayName, shape);

            const layerId = this.getLayerIdForShape(overlayName, shape);
            if (shape.shape instanceof PointShape) {
                this.map.addLayer(this.getPointLayer(layerId, sourceId), this.orderLayer);
                this.currentLayerIds.push(layerId);
            } else {
                this.map.addLayer(this.getPolylineLayer(layerId, sourceId, shape), this.orderLayer);
                this.currentLayerIds.push(layerId);

                const fillLayerId = layerId + OVERLAYS_FILL_LAYER_ID_POSTFIX;
                this.map.addLayer(this.getPolygonLayer(fillLayerId, sourceId, shape), layerId);
                this.currentLayerIds.push(fillLayerId);

                const symbolLayerId = layerId + OVERLAYS_LINE_SYMBOL_LAYER_ID_POSTFIX;
                this.map.addLayer(this.getLineSymbolLayer(symbolLayerId, sourceId, shape), fillLayerId);
                this.currentLayerIds.push(symbolLayerId);

                /* If this is a polyline shape with width == 0, we want it to look line a thicker line,
                 * otherwise, show the outline of the shape as dashed line, just like polygon borders.
                 */
                if (!(shape.shape instanceof PolyLineShape && shape.shape.width === 0)) {
                    this.map.setPaintProperty(layerId, "line-dasharray", [4, 4]);
                }

                // Add events for the fill layer
                this.addLayerEventListeners(fillLayerId, popupInnerHtml);
            }

            // Add events for the line layer
            this.addLayerEventListeners(layerId, popupInnerHtml);
        });
    }

    private generatePopupInnterHtml(overlayName: string, shape: Shape): string {
        let popupInnerHtml = `<strong>${shape.label || t("map.noLabelAdded")}</strong><br />${t(
            "map.overlay",
        )}: ${overlayName}`;

        if (shape.shape instanceof PolyLineContourShape) {
            const width = this.distanceFormatter.formatValueWithCurrentUnit(shape.shape.width);
            popupInnerHtml += `<br />${t("map.width")}: ${width}`;
        }

        const maxAltitude = shape.maxAltitude
            ? this.distanceFormatter.formatValueWithCurrentUnit(shape.maxAltitude)
            : null;

        if (shape.minAltitude || maxAltitude) {
            popupInnerHtml += `<br />${t("map.altitude")}:`;
        }
        if (shape.minAltitude && maxAltitude) {
            // Don't display unit for minAltitude
            const minAltitude = this.distanceFormatter.convertValueToCurrentUnit(shape.minAltitude);
            popupInnerHtml += ` ${minAltitude} - ${maxAltitude}`;
        } else if (shape.minAltitude) {
            // Display unit for minAltitude
            const minAltitude = this.distanceFormatter.formatValueWithCurrentUnit(shape.minAltitude);
            popupInnerHtml += ` ${t("general.min").toLowerCase()} ${minAltitude}`;
        } else if (maxAltitude) {
            popupInnerHtml += ` ${t("general.max").toLowerCase()} ${maxAltitude}`;
        }

        return popupInnerHtml;
    }

    private addLayerEventListeners(layerId: string, popupInnerHtml: string): void {
        // Add events for the line layer
        this.addLayerEventListener({
            type: "mouseenter",
            layer: layerId,
            listener: (e) => this.onMouseEnterShape(popupInnerHtml, e.lngLat),
        });
        this.addLayerEventListener({
            type: "mousemove",
            layer: layerId,
            listener: (e) => this.onMouseMoveInShape(e.lngLat),
        });
        this.addLayerEventListener({
            type: "mouseleave",
            layer: layerId,
            listener: () => this.onMouseLeaveShape(),
        });
    }

    private getPointLayer(layerId: string, sourceId: string): mapboxgl.SymbolLayer {
        return {
            id: layerId,
            type: "symbol",
            source: sourceId,
            layout: {
                "icon-image": OVERLAY_POINT_SYMBOL_ID,
                "icon-allow-overlap": true,
                "icon-offset": [0, -14],
                "icon-size": {
                    stops: [
                        [0, 0.1],
                        [10, 0.4],
                        [16, 1],
                    ],
                },
            },
        };
    }

    private getPolylineLayer(layerId: string, sourceId: string, shape: Shape): mapboxgl.LineLayer {
        const [lineColor, lineOpacity] = getColorAndOpacity(shape.lineColor);
        return {
            id: layerId,
            type: "line",
            source: sourceId,
            layout: {},
            paint: {
                "line-color": ["case", ["==", ["get", PROPERTY_SELECTED], true], OldColors.primaryTint, lineColor],
                "line-width": [
                    "case",
                    ["==", ["get", PROPERTY_OUTLINE_STYLE], OUTLINE_STYLE_SOLID],
                    shape.shape instanceof PolyLineShape ? 4 : 1,
                    1,
                ],
                "line-opacity": lineOpacity,
                "line-offset": -1,
            },
        };
    }

    private getPolygonLayer(layerId: string, sourceId: string, shape: Shape): mapboxgl.FillLayer {
        const [fillColor, fillOpacity] = getColorAndOpacity(shape.fillColor);
        return {
            id: layerId,
            type: "fill",
            source: sourceId,
            filter: ["!=", "$type", "LineString"],
            layout: {},
            paint: {
                "fill-color": ["case", ["==", ["get", PROPERTY_SELECTED], true], OldColors.primaryTint, fillColor],
                "fill-opacity": fillOpacity,
                "fill-antialias": true,
            },
        };
    }

    private getLineSymbolLayer(layerId: string, sourceId: string, shape: Shape): mapboxgl.SymbolLayer {
        const [color] = getColorAndOpacity(shape.lineColor);
        return {
            id: layerId,
            type: "symbol",
            source: sourceId,
            layout: {
                "text-field": ["get", PROPERTY_FEATURE_LABEL],
                "symbol-placement": "line",
                "text-size": 12,
                "text-allow-overlap": true,
                "text-anchor": "top",
            },
            paint: {
                "text-color": color,
            },
        };
    }

    private onMouseEnterShape = (html: string, location: mapboxgl.LngLat): void => {
        this.popup.setLngLat([location.lng, location.lat]).setHTML(html).addTo(this.map);
    };

    private onMouseMoveInShape = (location: mapboxgl.LngLat): mapboxgl.Popup =>
        this.popup.setLngLat([location.lng, location.lat]);

    private onMouseLeaveShape = (): mapboxgl.Popup => this.popup.remove();

    private getFeatureForShape(shape: Shape, overlayName: string, selected: boolean): Feature<Geometry | null> | null {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const extraProperties = new Map<string, any>();
        const primitiveShape = shape.shape;
        if (primitiveShape instanceof PolyLineShape && primitiveShape.locations.length === 1) {
            // We must have more than 1 location to draw a polyline
            return null;
        }

        let feature: Feature<Geometry | null>;

        if (primitiveShape instanceof PointShape) {
            feature = turfHelpers.point(primitiveShape.location.toGeoJSONLocation());
        } else if (primitiveShape instanceof PolyLineShape) {
            const lineString = turfHelpers.lineString(primitiveShape.locations.map((l) => l.toGeoJSONLocation()));

            if (primitiveShape.width === 0) {
                feature = lineString;
                extraProperties.set(PROPERTY_OUTLINE_STYLE, OUTLINE_STYLE_SOLID);
            } else {
                extraProperties.set(PROPERTY_OUTLINE_STYLE, OUTLINE_STYLE_DASHED);
                feature = turfBuffer(lineString, primitiveShape.width, { units: "meters" });
            }
        } else if (primitiveShape instanceof PolyLineContourShape) {
            const locations = Array.from(primitiveShape.contour);
            locations.push(primitiveShape.contour[0]);
            feature = turfHelpers.polygon(Array.of(locations.map((l) => l.toGeoJSONLocation())));
        } else if (primitiveShape instanceof PolygonShape) {
            const locations = Array.from(primitiveShape.locations);
            locations.push(primitiveShape.locations[0]);
            feature = turfHelpers.polygon(Array.of(locations.map((l) => l.toGeoJSONLocation())));
        } else if (primitiveShape instanceof CircleShape) {
            feature = turfCircle(primitiveShape.location.toGeoJSONLocation(), primitiveShape.radius, {
                units: "meters",
            });
        } else {
            feature = {
                type: "Feature",
                properties: [],
                geometry: {
                    type: "GeometryCollection",
                    geometries: [],
                },
            };
        }

        const props = feature.properties ?? {};

        props[PROPERTY_SHAPE_ID] = shape.id;
        props[PROPERTY_OVERLAY_ID] = overlayName;
        props[PROPERTY_SELECTED] = selected;
        props[PROPERTY_FEATURE_LABEL] = shape.label;
        extraProperties.forEach((value, key) => (props[key] = value));

        feature.properties = props;

        return feature;
    }

    private updateFeature(overlayName: string, shape: Shape, selected: boolean): void {
        const feature = this.getFeatureForShape(shape, overlayName, selected);
        const data = feature as Feature<Geometry, GeoJsonProperties>;
        const source = this.map.getSource(this.getSourceIdForShape(overlayName, shape)) as mapboxgl.GeoJSONSource;
        source.setData(data);
    }

    private onFeatureClicked = <T extends keyof mapboxgl.MapLayerEventType>(
        event: mapboxgl.MapLayerEventType[T] & mapboxgl.EventData,
    ): void => {
        const features = event.features || [];
        const wantedFeature = features.find((f) => f.properties && f.properties[PROPERTY_SHAPE_ID] != null);
        if (!wantedFeature) {
            return;
        }
        const overlayName = wantedFeature.properties![PROPERTY_OVERLAY_ID];
        const shapeId = wantedFeature.properties![PROPERTY_SHAPE_ID];
        const selected = wantedFeature.properties![PROPERTY_SELECTED] || false;

        const overlay = this.currentOverlays.get(overlayName);
        if (overlay == null) {
            return;
        }
        const shape = overlay.shapes.find((s) => s.id === shapeId)!;

        if (shape == null) {
            return;
        }

        if (this.onShapeClicked) {
            this.onShapeClicked({ overlayName, shape }, selected);
        }
    };

    private getLayerIdForShape(overlayName: string, shape: Shape): string {
        return OVERLAYS_LAYER_ID_PREFIX + kebabify(overlayName + "-" + shape.id);
    }

    private getSourceIdForShape(overlayName: string, shape: Shape): string {
        return OVERLAYS_SOURCE_ID_PREFIX + kebabify(overlayName + "-" + shape.id);
    }
}
