import React from "react";
import styled from "styled-components";
import { OldColors } from "../appearance/Colors";

const TrackListEntryRoot = styled.div<{ clickable?: boolean; selected?: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 5px;
    margin-bottom: 4px;
    font-size: 14px;
    align-items: center;
    line-height: 16px;
    letter-spacing: normal;
    color: ${OldColors.textSecondary};
    border-radius: 1000px;
    ${({ clickable, theme }) =>
        clickable &&
        `
        cursor: pointer;
        :hover {
            color: ${theme.colors.text.text};
        }
    `}
    ${({ selected }) =>
        selected &&
        `
        background-color: ${OldColors.backgroundPrimaryDark};
    `}
`;

const TrackItemIconPlaceholder = styled.div`
    width: 22px;
    height: 22px;
`;

const TrackItemName = styled.span`
    margin-left: 10px;
`;

const TrackItemAlarmTag = styled.span<{ color?: string }>`
    color: ${({ color, theme }) => color || theme.colors.status.danger};
    margin-left: 10px;
    font-weight: 500;
`;

export interface TrackListEntryProps {
    name: string;
    iconNode?: React.ReactNode;
    isAlarm?: boolean;
    defaultColor?: string;
    isSelected?: boolean;
    onClick?: () => void;
}

export class TrackListEntry extends React.Component<TrackListEntryProps> {
    public render(): React.ReactNode {
        return (
            <TrackListEntryRoot
                onClick={this.props.onClick}
                clickable={this.props.onClick && true}
                selected={this.props.isSelected}
            >
                {this.props.iconNode ? this.props.iconNode : <TrackItemIconPlaceholder />}
                <TrackItemName>{this.props.name}</TrackItemName>
                {this.props.isAlarm && (
                    <>
                        <TrackItemName>-</TrackItemName>
                        <TrackItemAlarmTag color={this.props.defaultColor}>ALARM</TrackItemAlarmTag>
                    </>
                )}
            </TrackListEntryRoot>
        );
    }
}
