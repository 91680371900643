import { GridAnalysis as GridAnalysisProto } from "./proto/generated/gridanalysis3_pb";
import { Location } from ".";

export class Grid {
    // Static functions

    public static fromProto(model: GridAnalysisProto, chunks: GridDataChunk[]): Grid {
        const defaultColor = model.getTrackcountGridcolor() || "#000";
        return new Grid(
            model.getId(),
            model.getUuid(),
            model.getName(),
            defaultColor,
            model.getCellresolution(),
            model.getTimeresolution(),
            Location.fromProto(model.getCenter()),
            model.getTrackcountGridmaxvalue(),
            chunks,
        );
    }

    public constructor(
        public readonly id: long,
        public readonly uuid: string,
        public readonly name: string,
        public readonly defaultColor: string,
        public readonly cellResolution: int,
        public readonly timeResolution: int,
        public readonly center: Location,
        public readonly maxValue: int,
        public readonly data: GridDataChunk[],
    ) {}
}

export class GridDataChunk {
    public constructor(
        public readonly row: int,
        public readonly col: int,
        public readonly trackCountValue: int,
        public readonly vectorValue: int,
        public readonly vectorLength: int,
    ) {}
}

export class GridsSnapshot {
    // Static functions

    public static from(grids: Grid[]): GridsSnapshot {
        const gridsMap = new Map<long, Grid>();
        grids.forEach((grid) => {
            gridsMap.set(grid.id, grid);
        });
        return new GridsSnapshot(Date.now(), gridsMap);
    }

    public constructor(public readonly timestamp: long, public readonly grids: Map<long, Grid>) {}
}
