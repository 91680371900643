import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { AlarmRepository, LocalPreferencesRepository, ReplayRepository } from "../../domain/repositories";
import { AlarmData, AlarmDataType, ALARM_SOUND_ENABLED_KEYS, ALARMS_ENABLED_KEYS } from "../../domain/model";

export class AlarmViewModel extends BaseViewModel {
    // Properties

    public alarms: Rx.Observable<AlarmData[]>;
    public isTestingAlarm: Rx.Observable<boolean>;
    public isReplay: Rx.Observable<boolean>;

    public get enabledAlarmTypes(): Rx.Observable<AlarmDataType[]> {
        return Rx.combineLatest(
            ALARMS_ENABLED_KEYS.map((key) => this.localPreferencesRepository.observePreference<boolean>(key)),
        ).pipe(
            RxOperators.map(([trackEnabled, areaEntryEnabled, crossingEnabled, funnelEnabled]) => {
                const enabledAlarmTypes: AlarmDataType[] = [];
                trackEnabled && enabledAlarmTypes.push(AlarmDataType.DRONE);
                areaEntryEnabled && enabledAlarmTypes.push(AlarmDataType.AREA_ENTRY);
                crossingEnabled && enabledAlarmTypes.push(AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD);
                funnelEnabled && enabledAlarmTypes.push(AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD);
                return enabledAlarmTypes;
            }),
        );
    }

    public get soundEnabledForAlarmType(): Rx.Observable<AlarmDataType[]> {
        return Rx.combineLatest(
            ALARM_SOUND_ENABLED_KEYS.map((key) => this.localPreferencesRepository.observePreference<boolean>(key)),
        ).pipe(
            RxOperators.map(([trackEnabled, areaEntry, runwayCrossing, funnelTraffic]) => {
                const enabledAlarmTypes: AlarmDataType[] = [];
                trackEnabled && enabledAlarmTypes.push(AlarmDataType.DRONE);
                areaEntry && enabledAlarmTypes.push(AlarmDataType.AREA_ENTRY);
                runwayCrossing && enabledAlarmTypes.push(AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD);
                funnelTraffic && enabledAlarmTypes.push(AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD);
                return enabledAlarmTypes;
            }),
        );
    }

    // Private properties

    private alarmBoxHoveringSubject = new Rx.BehaviorSubject<AlarmDataType | null>(null);

    // Lifecycle

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        alarmRepository: AlarmRepository,
        replayRepository: ReplayRepository,
    ) {
        super();
        this.alarms = alarmRepository.activeRealtime;
        this.isTestingAlarm = alarmRepository.activeTest;
        this.isReplay = replayRepository.currentPlaybackScene.pipe(RxOperators.map((v) => v != null));
        this.alarmBoxHoveringSubject.subscribe((value) => alarmRepository.setAlarmBoxHovering(value));
    }

    /**
     * Toggles the alarm activation for all alarm types.
     *
     * @remarks
     *  - If any of the alarms are muted, unmute all alarms.
     *  - If no alarm is muted, mute them all.
     *  - If all alarms are muted, unmute them all.
     */
    public toggleSound(): void {
        const allAlarmValues = ALARM_SOUND_ENABLED_KEYS.map((key) =>
            this.localPreferencesRepository.getPreference<boolean>(key),
        );
        const isAnyAlarmMuted = allAlarmValues.some((value) => !value);
        ALARM_SOUND_ENABLED_KEYS.forEach((key) => this.localPreferencesRepository.setPreference(key, isAnyAlarmMuted));
    }

    public onMouseOverAlertBox(key: AlarmDataType): void {
        this.alarmBoxHoveringSubject.next(key);
    }
    public onMouseOutAlertBox(key?: AlarmDataType): void {
        if (!key || this.alarmBoxHoveringSubject.value === key) {
            this.alarmBoxHoveringSubject.next(null);
        }
    }
}
