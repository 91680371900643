import React, { PropsWithChildren, useEffect, useRef } from "react";
import { ScreenDimmer } from "../../dimmer/ScreenDimmer";
import styled from "styled-components";
import { OverlayViewGroup } from "../overlays/OverlayViewGroup";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { Button } from "../button/Button";
import { t } from "i18next";

const FADE_IN_ANIMATION_DURATION_MILLIS = 300;

const Container = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1000;
`;

const CenterOnScreen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const ContentRoot = styled.div`
    display: flex;
    flex-direction: column;

    // Subtracting 250px keeps extra space for any toast messages that might be shown, also on iPad
    max-height: calc(100vh - 250px);
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.x3};
    padding: ${({ theme }) => theme.spacing.x6};
`;

const Title = styled.span`
    ${BASE_TEXT_STYLE}
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text.text};
`;

const HeaderMessage = styled.span`
    ${BASE_TEXT_STYLE}
    min-height: 1.43em;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01px;
    color: ${({ theme }) => theme.colors.text.text200};
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.x6};
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.backgrounds.panelSection};
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.x6};
`;

export interface SharedModalProps {
    onClosed: () => void;
}

interface Props extends SharedModalProps {
    isBlocking?: boolean;
    message?: string;
    title: string;
}

/**
 * Renders a modal dialog at the center of the screen with an optional screen dimmer.
 * @param title Required. Title of the modal dialog.
 * @param onClosed Required. Callback that is invoked when the modal dialog is closed. When set, a close button is rendered.
 * @param isBlocking Optional. When set to true, the modal dialog cannot be closed by clicking the area around the modal.
 * @param message Optional. A message below the title.
 */
export const Modal = ({ children, isBlocking, message, onClosed, title }: PropsWithChildren<Props>): JSX.Element => {
    // Properties

    const screenDimmerRef = useRef<ScreenDimmer>(null);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        });
    }, []);

    // Local functions

    const close = (): void => {
        if (isBlocking) {
            return;
        }
        if (screenDimmerRef.current) {
            screenDimmerRef.current.hide();
        }
        setIsVisible(false);
        onClosed && setTimeout(() => onClosed(), FADE_IN_ANIMATION_DURATION_MILLIS);
    };

    // Render

    return (
        <Container>
            <ScreenDimmer
                ref={screenDimmerRef}
                onClick={() => close()}
                animationDurationMillis={FADE_IN_ANIMATION_DURATION_MILLIS}
            />
            <CenterOnScreen>
                <OverlayViewGroup
                    side="center"
                    isVisible={isVisible}
                    animationDurationMillis={FADE_IN_ANIMATION_DURATION_MILLIS}
                >
                    <ContentRoot>
                        <Header>
                            <Title>{title}</Title>
                            {message && <HeaderMessage>{message}</HeaderMessage>}
                        </Header>
                        <Body>{children}</Body>
                        {!isBlocking && (
                            <Footer>
                                <Button label={t("general.close")} onClick={() => close()} />
                            </Footer>
                        )}
                    </ContentRoot>
                </OverlayViewGroup>
            </CenterOnScreen>
        </Container>
    );
};
