import { SelectItemViewModel } from "../../SettingItemViewModel";
import AltitudeIcon from "../../../../res/images/settings/altitude.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import * as RxOperators from "rxjs";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { t } from "i18next";
import { AltitudeConfig, convertAltitudeConfigToOption } from "../../../../domain/model/Altitude";

export class TrackAltitudeConfigSettingItemViewModel extends SelectItemViewModel {
    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        altitudeConfigOptions: AltitudeConfig[],
    ) {
        super(
            t("settings.altitudeConfig"),
            SettingItemIcon.withUrl(AltitudeIcon),
            nonNullObservable(
                localPreferencesRepository.observePreference<AltitudeConfig>(
                    LocalUserPreferenceKeys.appearance.altitudeConfig,
                ),
            ).pipe(RxOperators.map(convertAltitudeConfigToOption)),
            altitudeConfigOptions.map(convertAltitudeConfigToOption),
            localPreferencesRepository.getPreference(LocalUserPreferenceKeys.appearance.altitudeConfig),
            (value) => this.setValue(value),
        );
    }

    // Private functions

    private setValue(value: string): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.altitudeConfig, value);
    }
}
