import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { PositionOnScreen, UIControlOptions, UIControlRepository } from "../";
import {
    ALL_LEFT_PANELS_CLOSED,
    ALL_MODALS_CLOSED,
    ALL_RIGHT_PANELS_CLOSED,
    INITIAL_HOME_UI_STATE,
    HomeUIState,
    INITIAL_SIDEBAR_UI_STATE,
    SidebarUIState,
    UIState,
    ALL_SIDEBAR_SECTIONS_HIDDEN,
} from "../../model";

export class CommonUIControlRepository implements UIControlRepository {
    // Properties

    public get homeUIState(): Rx.Observable<HomeUIState> {
        return this.homeUIStateSubject.asObservable();
    }

    public get sidebarUIState(): Rx.Observable<SidebarUIState> {
        return this.sidebarUIStateSubject.asObservable();
    }

    public get uiState(): Rx.Observable<UIState> {
        return Rx.combineLatest([this.homeUIState, this.sidebarUIState]).pipe(
            RxOperators.map(([homeUIState, sidebarUIState]) => ({ ...homeUIState, ...sidebarUIState })),
        );
    }

    public get isUIReady(): Rx.Observable<boolean> {
        return this.uiReadySubject.asObservable();
    }

    private homeUIStateSubject = new Rx.BehaviorSubject<HomeUIState>(INITIAL_HOME_UI_STATE);
    private sidebarUIStateSubject = new Rx.BehaviorSubject<SidebarUIState>(INITIAL_SIDEBAR_UI_STATE);
    private uiReadySubject = new Rx.BehaviorSubject<boolean>(false);

    // Public functions

    public toggleHomeUIComponent(key: keyof HomeUIState, options?: UIControlOptions): void {
        const hideComponentSet = this.getHomeComponentSetByPosition(options?.hideHomeComponentSet);
        // Use the isVisible option if it is provided, otherwise invert the current state
        const isVisible = options?.isVisible ?? !this.homeUIStateSubject.value[key as keyof HomeUIState];
        this.homeUIStateSubject.next({ ...this.homeUIStateSubject.value, ...hideComponentSet, [key]: isVisible });
    }

    public hideHomeUIComponentSets(...positions: PositionOnScreen[]): void {
        const hideComponentSet: Partial<HomeUIState> = positions.reduce(
            (acc, position) => ({ ...acc, ...this.getHomeComponentSetByPosition(position) }),
            {},
        );
        this.homeUIStateSubject.next({ ...this.homeUIStateSubject.value, ...hideComponentSet });
    }

    public toggleSidebarUIComponent(key: keyof SidebarUIState, options?: UIControlOptions): void {
        // Use the isVisible option if it is provided, otherwise invert the current state
        const isVisible = options?.isVisible ?? !this.sidebarUIStateSubject.value[key as keyof SidebarUIState];
        this.sidebarUIStateSubject.next({
            ...this.sidebarUIStateSubject.value,
            ...ALL_SIDEBAR_SECTIONS_HIDDEN,
            [key]: isVisible,
        });
    }

    public toggleUIComponent(key: keyof UIState, options?: UIControlOptions): void {
        if (key in INITIAL_HOME_UI_STATE) {
            this.toggleHomeUIComponent(key as keyof HomeUIState, options);
        } else {
            this.toggleSidebarUIComponent(key as keyof SidebarUIState, options);
        }
    }

    public setUIReady(isReady: boolean): void {
        this.uiReadySubject.next(isReady);
    }

    public resetUI(): void {
        this.homeUIStateSubject.next(INITIAL_HOME_UI_STATE);
    }

    // Private functions

    private getHomeComponentSetByPosition(position?: PositionOnScreen): Partial<HomeUIState> {
        switch (position) {
            case "left":
                return ALL_LEFT_PANELS_CLOSED;
            case "right":
                return ALL_RIGHT_PANELS_CLOSED;
            case "cover":
                return { ...ALL_LEFT_PANELS_CLOSED, ...ALL_RIGHT_PANELS_CLOSED };
            case "modal":
                return ALL_MODALS_CLOSED;
            default:
                return {};
        }
    }
}
