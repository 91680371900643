import { Trackable } from "./Track";
import { Location } from "./Location";
import { AdsbFlight as AdsbFlightProto } from "./proto/generated/adsbflightlist3_pb";
import { BaseEstimate } from "./BaseEstimate";
import { FlightJSON as AdsbFlightJSON } from "./json";
import { ADSBUtils } from "../../utils/ADSBUtils";
import { EmitterCategory, emitterCategoryFromJSON, emitterCategoryFromProto } from "./EmitterCategory";

export interface ADSBFlightEstimate extends BaseEstimate {}

export class ADSBFlight implements Trackable {
    public static fromProto(model: AdsbFlightProto): ADSBFlight {
        return new ADSBFlight(
            model.getFlightId(),
            model.getIcao(),
            model.getBearing(),
            model.getGroundspeed(),
            model
                .getPositionList()
                .map((p) => ({ timestamp: p.getTimestampMsec(), location: Location.fromProto(p.getPosition()) })),
            model.getFlightId(),
            model.getSquawk(),
            model.getHasspi(),
            model.getHasemergency(),
            model.getHasalert(),
            model.getIsonsurface(),
            model.getIcao(),
            emitterCategoryFromProto(model.getEmittercategory()),
        );
    }

    public static fromJSON(model: AdsbFlightJSON): ADSBFlight {
        return new ADSBFlight(
            model.flightId,
            model.icao,
            model.bearingRad,
            model.groundSpeed,
            model.positions.map((p) => ({ timestamp: p.timestamp * 1000, location: Location.fromJson(p.position) })),
            model.flightId || "",
            model.squawk,
            model.hasSPI,
            model.hasEmergency,
            model.hasAlert,
            model.isOnSurface,
            model.icao,
            emitterCategoryFromJSON(model.emitterCategory),
        );
    }

    public static newEmpty(icao: number, alert: boolean): ADSBFlight {
        return new ADSBFlight(null, icao, 0, 0, [], "", 0, false, false, alert, false, icao, EmitterCategory.NONE);
    }

    public constructor(
        public name: string | null,
        public readonly id: number,
        public readonly bearing: float,
        public readonly velocity: float,
        public readonly estimates: ADSBFlightEstimate[],
        public readonly flightId: string,
        public readonly squawk: int,
        public readonly hasSPI: boolean,
        public readonly hasEmergency: boolean,
        public readonly hasAlert: boolean,
        public readonly isOnSurface: boolean,
        public readonly icao: int,
        public readonly emitterCategory: EmitterCategory,
    ) {}

    public clone(overrideParams: Partial<ADSBFlight> = {}): ADSBFlight {
        const params = { ...this, ...overrideParams };
        // @ts-ignore
        const newFlight = new ADSBFlight();
        Object.assign(newFlight, params);
        return newFlight;
    }

    public getCurrentLocation(): Location | null {
        return ADSBUtils.getCurrentADSBFlightLocation(this);
    }

    public isFinished = false;
}
