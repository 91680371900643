import { useEffect, useState } from "react";
import * as Rx from "rxjs";

export function useObservable<T>(observableValue: Rx.Observable<T>, initialValue: T): T {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        const subscription = observableValue.subscribe((v) => setValue(v));
        return () => subscription.unsubscribe();
    }, []);
    return value;
}
