import mapboxgl from "mapbox-gl";
import { MapModule } from "../MapModule";
import { DimColorModuleViewModel } from "./DimColorModuleViewModel";
import { ORDER_LAYER_COLORS } from "../Orders";
import { ColorLayer } from "../../layers/ColorLayer";
import { Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";

export const LAYER_ID_BACKGROUND_DIM = "layer-background-dim";

export class DimColorModule extends MapModule<DimColorModuleViewModel> {
    public constructor(protected readonly viewModel: DimColorModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const dimColorLayer = ColorLayer.attachedTo(map, ORDER_LAYER_COLORS.id, LAYER_ID_BACKGROUND_DIM, "#000", 0.0);

        const subscription = this.viewModel.mapBrightness.subscribe((value) =>
            dimColorLayer.setOpacity((10 - value) / 10),
        );
        this.collectSubscription(subscription);
    }
}
