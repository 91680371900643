import React from "react";
import styled from "styled-components";
import { BASE_TEXT_STYLE } from "./theme/GlobalStyles";

/**
 * Component that wraps a set of cards in one row.
 */
export const CardGroup = styled.div`
    display: flex;
    flex-direction: row;
    place-content: center space-between;
    gap: ${({ theme }) => theme.spacing.x3};
    width: 100%;
`;

const Container = styled.div<{ thumbnailUrl?: string; selected: boolean }>`
    position: relative;
    flex: 1;
    padding: ${({ theme }) => theme.spacing.x4};
    box-sizing: border-box;
    width: 120px;
    background-color: ${({ selected, theme }) =>
        selected ? theme.colors.status.info : theme.colors.interactive.input};
    border-radius: ${({ theme }) => theme.spacing.x2};
    cursor: ${({ selected }) => (selected ? "default" : "pointer")};

    ${({ selected, theme, thumbnailUrl }) => {
        /* Show thumbnail if provided */
        if (thumbnailUrl) {
            return `
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 120px;
                border: 3px solid ${selected ? theme.colors.status.info : theme.colors.interactive.input};
                background-image: url(${thumbnailUrl});
                background-size: contain;
                transition: border-color ${theme.transitionDuration} ease-out;

                :hover {
                    border-color: ${!selected && theme.colors.status.info};
                }

                label {
                    text-shadow: -1px -1px 3px ${theme.colors.backgrounds.panel}, 1px -1px 3px ${
                theme.colors.backgrounds.panel
            }, -1px 1px 3px ${theme.colors.backgrounds.panel}, 1px 1px 3px ${theme.colors.backgrounds.panel};
                }
            `;
        } else {
            return `
                transition: background-color ${theme.transitionDuration} ease-out;
                :hover {
                    background-color: ${theme.colors.secondary.blue};
                }
            `;
        }
    }}
`;

const Label = styled.label`
    ${BASE_TEXT_STYLE}
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text.text};
    cursor: inherit;
`;

const SubLabel = styled(Label)`
    margin-top: ${({ theme }) => theme.spacing.x2};
    font-size: 12px;
`;

interface Props {
    label: string;
    thumbnailUrl?: string;
    onClick: () => void;
    selected: boolean;
    smallLabel?: string;
}

/**
 *
 * @param label Required. The main text to display on the card.
 * @param onClick Required. The function to call when the card is clicked.
 * @param selected Required. Whether the card is shown as selected or not. Onclick won't be called if selected is true.
 * @param smallLabel Optional. A smaller text to display below the main text.
 * @param thumbnailUrl Optional. Give the card a background image.
 */
export const Card = ({ label, thumbnailUrl, onClick, selected, smallLabel }: Props): JSX.Element => {
    return (
        <Container thumbnailUrl={thumbnailUrl} onClick={() => !selected && onClick()} selected={selected}>
            <Label>{label}</Label>
            {smallLabel && <SubLabel>{smallLabel}</SubLabel>}
        </Container>
    );
};
