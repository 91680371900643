import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { SwitchItemViewModel } from "../../SettingItemViewModel";
import { t } from "i18next";
import { SettingItemIcon } from "../../SettingItemIcon";
import Icon from "../../../../res/images/settings/show_vr_only_tracks.svg";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { LocalUserPreferenceKeys } from "../../../../domain/model";

export class VROnlyTracksToggleSettingItemViewModel extends SwitchItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.showVROnlyTracks"),
            SettingItemIcon.withUrl(Icon),
            nonNullObservable(
                localPreferencesRepository.observePreference<boolean>(
                    LocalUserPreferenceKeys.appearance.showVrOnlyTracks,
                ),
            ),
            (value) =>
                localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.showVrOnlyTracks, value),
        );
    }
}
