import {
    Classification,
    TrackObservation,
    Species,
    StatusResponse,
    Track,
    locationToGeoLocationProto,
    TrackObservationMode,
} from "../../../../domain/model";
import { AbstractStartableRepository, TrackObservationRepository } from "../../../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import {
    LoggerCommandMsg as LoggerCommandMsgProto,
    LogObservationMsg as LogObservationMsgProto,
    ObservationType as ObservationTypeProto,
} from "../../../../domain/model/proto/generated/logger3_pb";
import { t } from "i18next";

export class BirdViewerTrackObservationRepository
    extends AbstractStartableRepository
    implements TrackObservationRepository
{
    // Properties

    public get selectedTracks(): Rx.Observable<Track[]> {
        return this.selectedTracksSubject.asObservable();
    }

    public get selectedTracksClassification(): Rx.Observable<Classification | undefined> {
        return this.selectedTracks.pipe(
            RxOperators.map((tracks) => {
                const timestamp = this.observationTimestamp;
                if (!timestamp) {
                    return [];
                }
                return tracks.map((t) => t.getClosestEstimateTo(timestamp)).map((e) => (e ? e.classification : null));
            }),
            RxOperators.map((classifications) => {
                if (classifications.length === 0) {
                    return undefined;
                }
                const classification = classifications[0];
                if (!classification || classifications.some((c) => c !== classification)) {
                    return undefined;
                }
                return classification;
            }),
        );
    }

    public get observedTrackIds(): Rx.Observable<number[]> {
        return this.observedTrackIdsSubject.asObservable();
    }

    public get mode(): Rx.Observable<TrackObservationMode> {
        return this.modeSubject.asObservable();
    }

    public get speciesList(): Rx.Observable<Species[]> {
        return this.speciesListSubject.asObservable();
    }

    private readonly selectedTracksSubject = new Rx.BehaviorSubject<Track[]>([]);
    private readonly observedTrackIdsSubject = new Rx.BehaviorSubject<number[]>([]);
    private readonly modeSubject = new Rx.BehaviorSubject<TrackObservationMode>(TrackObservationMode.None);
    private readonly speciesListSubject = new Rx.BehaviorSubject<Species[]>([]);
    private observationTimestamp?: long;
    private subscriptions = new Rx.Subscription();

    public constructor(private api: BirdViewerAPI) {
        super();
    }

    // Public functions

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.fetchLoggerSettingsList();
    }

    public stop(): void {
        this.subscriptions.unsubscribe();
        this.speciesListSubject.next([]);
    }

    public setMode(mode: TrackObservationMode): void {
        this.modeSubject.next(mode);
        this.clearSelectedTracks();
    }

    public toggleTrack(track: Track, replayTimestamp?: long): void {
        const mode = this.modeSubject.value;
        const currentTracks = Array.from(this.selectedTracksSubject.value);
        switch (mode) {
            case TrackObservationMode.SingleTrackObservation:
                // Either deselect all tracks, or select this track and set timestamp
                currentTracks.some((t) => t.id === track.id)
                    ? this.clearSelectedTracks()
                    : this.selectFirstTrackAndSetTimestamp(track, replayTimestamp);
                break;
            case TrackObservationMode.MultiTrackObservation:
                const trackIndex = currentTracks.findIndex((t) => t.id === track.id);
                if (trackIndex >= 0) {
                    // If this is the only selected track, deselect all. Otherwise just remove this one.
                    if (currentTracks.length === 1) {
                        this.clearSelectedTracks();
                    } else {
                        currentTracks.splice(trackIndex, 1);
                        this.selectedTracksSubject.next(currentTracks);
                    }
                } else if (currentTracks.length === 0) {
                    // If this is the first track to be selected, set timestamp
                    this.selectFirstTrackAndSetTimestamp(track, replayTimestamp);
                } else {
                    // If this track is being added to multiple selection, just add this track.
                    currentTracks.push(track);
                    this.selectedTracksSubject.next(currentTracks);
                }
                break;
        }
    }

    public submitSelectedTrackObservation(observation: TrackObservation): Rx.Observable<void> {
        const timestamp = this.observationTimestamp;
        if (!timestamp) {
            console.error("Server timestamp not set");
            return Rx.throwError(() => new Error(t("observation.errorSubmitTrackObservation")));
        }
        const tracks = this.selectedTracksSubject.value;
        if (tracks.length === 0) {
            return Rx.throwError(
                () =>
                    new Error(
                        `${t("observation.errorSubmitTrackObservation")}: ${t(
                            "observation.errorSubmitTrackObservationNoTracks",
                        )}`,
                    ),
            );
        }
        return this.addTrackObservation(tracks, observation, timestamp).pipe(
            RxOperators.map((r) => {
                if (!r.isOk) {
                    throw new Error(`${t("observation.errorSubmitTrackObservation")} [${r.code}]: ${r.message}`);
                }
                tracks.forEach((t) => this.toggleTrack(t));
                this.observedTrackIdsSubject.next([...this.observedTrackIdsSubject.value, ...tracks.map((t) => t.id)]);
            }),
        );
    }

    public clearSelectedTracks(): void {
        this.observationTimestamp = undefined;
        this.selectedTracksSubject.next([]);
    }

    // Private functions

    private fetchLoggerSettingsList(): void {
        const subscription = this.api.getLoggerSettingsList().subscribe({
            next: (value) => {
                const speciesList = value.getSpeciesList().map((s) => Species.fromProto(s));
                this.speciesListSubject.next(speciesList);
            },
            error: (error) => console.error(`Error fetching logger settings list: ${error}`),
        });
        this.subscriptions.add(subscription);
    }

    private selectFirstTrackAndSetTimestamp(track: Track, replayTimestamp?: long): void {
        if (replayTimestamp) {
            this.observationTimestamp = replayTimestamp;
            this.selectedTracksSubject.next([track]);
        } else {
            const subscription = this.setObservationTimestampFromServer().subscribe({
                error: (error) => console.error(`Error fetching server time: ${error}`),
                complete: () => this.selectedTracksSubject.next([track]),
            });
            this.subscriptions.add(subscription);
        }
    }

    private setObservationTimestampFromServer(): Rx.Observable<void> {
        return this.api.getServerTime().pipe(
            RxOperators.tap((value) => (this.observationTimestamp = value.getTimeMsec())),
            RxOperators.ignoreElements(),
        );
    }

    private addTrackObservation(
        tracks: Track[],
        observation: TrackObservation,
        timestamp: long,
    ): Rx.Observable<StatusResponse> {
        const message = this.createLogObservationMessage(tracks, observation, timestamp);
        const request = new LoggerCommandMsgProto();
        request.setLogobservation(message);
        return this.sendLoggerCommandMessage(request);
    }

    private createLogObservationMessage(
        tracks: Track[],
        observation: TrackObservation,
        timestamp: long,
    ): LogObservationMsgProto {
        const logObservationMessage = new LogObservationMsgProto();
        logObservationMessage.setTrackidsList(tracks.map((t) => t.id));
        logObservationMessage.setTrackdatabaseidsList(tracks.map((t) => t.databaseId));

        /**
         * @todo: This is a bit of a hack. We should be able to add the location for each track, but the server
         * doesn't support that yet. For now we use the location of the first track, to make multi-track observations
         * work again. A Jira issue is created to deal with this in a later release.
         */
        const trackLocation = tracks[0].getCurrentLocation();
        if (trackLocation) {
            logObservationMessage.setPosition(locationToGeoLocationProto(trackLocation));
        }

        logObservationMessage.setObservationtype(ObservationTypeProto.OB_SITTING);
        logObservationMessage.setTimestampMsec(timestamp);
        logObservationMessage.setComment(observation.notes);
        // Setting the bird count to 0 results in a null value which the server won't accept, so we use -1 instead
        logObservationMessage.setBirdcount(observation.birdCount || -1);
        if (observation.speciesId) {
            logObservationMessage.setSpeciesid(observation.speciesId);
        }
        return logObservationMessage;
    }

    private sendLoggerCommandMessage(request: LoggerCommandMsgProto): Rx.Observable<StatusResponse> {
        return this.api
            .handleLoggerCommand(request)
            .pipe(RxOperators.map((response) => StatusResponse.fromProto(response)));
    }
}
