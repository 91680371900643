import { ActionItemViewModel } from "../../SettingItemViewModel";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { SettingItemIcon } from "../../SettingItemIcon";
import { getVelocityUnit, VelocityUnit } from "../../../../domain/model/VelocityUnit";
import VelocityIcon from "../../../../res/images/settings/velocity.svg";
import * as RxUtils from "../../../../utils/RxUtils";
import { LocalUserPreferenceKeys } from "../../../../domain/model/PreferencesData";
import * as RxOperators from "rxjs/operators";
import { t } from "i18next";

export class VelocityUnitSettingItemViewModel extends ActionItemViewModel {
    // Properties

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly preferenceKey: keyof typeof LocalUserPreferenceKeys.appearance,
        title: string,
    ) {
        super(
            title,
            SettingItemIcon.withUrl(VelocityIcon),
            () => this.toggleVelocityUnit(),
            RxUtils.nonNullObservable(
                localPreferencesRepository.observePreference<string>(LocalUserPreferenceKeys.appearance[preferenceKey]),
            ).pipe(
                RxOperators.map((value) => getVelocityUnit(value)),
                RxOperators.map((unitName) => this.getVelocityUnitName(unitName)),
            ),
        );
    }

    // Private functions

    private toggleVelocityUnit(): void {
        const velocityUnits = Object.values(VelocityUnit);
        const oldUnitIndex = velocityUnits.indexOf(this.velocityUnit);
        const newUnitIndex = (oldUnitIndex + 1) % velocityUnits.length;

        this.setVelocityUnit(velocityUnits[newUnitIndex]);
    }

    private get velocityUnit(): VelocityUnit {
        const value = this.localPreferencesRepository.getPreference<string>(
            LocalUserPreferenceKeys.appearance[this.preferenceKey],
        );
        return getVelocityUnit(value);
    }

    private setVelocityUnit(unit: VelocityUnit): void {
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.appearance[this.preferenceKey],
            unit as string,
        );
    }

    private getVelocityUnitName(unit: VelocityUnit): string {
        switch (unit) {
            case VelocityUnit.KMH:
                return `${t("unit.kilometerPerHour")}`;
            case VelocityUnit.MPH:
                return `${t("unit.milePerHour")}`;
            case VelocityUnit.MSEC:
            default:
                return `${t("unit.meterPerSecond")}`;
        }
    }
}
