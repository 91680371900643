import * as Rx from "rxjs";
import {
    OverlayEditorRepository,
    EditMode,
    EditState,
    EditModePayload,
    OverlaySelection,
} from "../../../../domain/repositories";
import { PrimitiveShape } from "../../../../domain/model";

export class BirdViewerOverlayEditorRepository implements OverlayEditorRepository {
    // Properties

    public editState: Rx.Observable<EditState> = Rx.NEVER;
    public isolatedOverlays: Rx.Observable<string[]> = Rx.NEVER;

    // Public functions

    public setMode(_mode: EditMode, _payload?: EditModePayload): void {
        throw new Error("Method not implemented.");
    }

    public beginShape(): void {
        throw new Error("Method not implemented.");
    }

    public updateActiveShape(_update: PrimitiveShape): void {
        throw new Error("Method not implemented.");
    }

    public updateMinMaxAltitude(_minAltitude?: number, _maxAltitude?: number): void {
        throw new Error("Method not implemented.");
    }

    public discardShape(): void {
        throw new Error("Method not implemented.");
    }

    public endShape(_overlay: string): Rx.Observable<never> {
        throw new Error("Method not implemented.");
    }

    public isolateOverlays(_overlayIds: string[]): void {
        throw new Error("Method not implemented.");
    }

    public deleteShapes(_selection: OverlaySelection[]): Rx.Observable<never> {
        throw new Error("Method not implemented.");
    }
}
