const TOGGLE_ALARM_SOUND = "toggle-alarm-sound";

const KEYBOARD_SHORTCUTS_BINDING = new Map([[TOGGLE_ALARM_SOUND, "ctrl+m"]]);

function getBinding(action: string): string {
    return KEYBOARD_SHORTCUTS_BINDING.get(action)!;
}

function getBindingDisplayValue(action: string): string {
    const binding = getBinding(action);
    return binding.toUpperCase().replace("+", " + ");
}

export default {
    actions: {
        TOGGLE_ALARM_SOUND,
    },
    getBinding,
    getBindingDisplayValue,
};
