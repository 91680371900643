import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { SettingItemIcon } from "../../SettingItemIcon";
import { SwitchItemViewModel } from "../../SettingItemViewModel";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import Icon from "../../../../res/images/settings/vr_azimuth_visibility.svg";
import { t } from "i18next";

export class VRAzimuthLayerToggleSettingItemViewModel extends SwitchItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.showRadarOrientation"),
            SettingItemIcon.withUrl(Icon),
            nonNullObservable(
                localPreferencesRepository.observePreference<boolean>(LocalUserPreferenceKeys.appearance.showVRAzimuth),
            ),
            (value) =>
                localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.showVRAzimuth, value),
        );
    }
}
