import { BaseViewModel } from "../BaseViewModel";
import { DeterrenceRepository, LocalPreferencesRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { DeterrenceDeviceState, DeterrenceEvent, LocalUserPreferenceKeys } from "../../domain/model";
import { DateFormatter } from "../../infrastructure/DateFormatter";
import { TimeDisplayMode } from "../../domain/model/TimeDisplayMode";
import { nonNullObservable } from "../../utils/RxUtils";
import { t } from "i18next";

export interface DeterrenceDeviceViewModel {
    id: int;
    prettyName: string;
    used: int;
    remaining: int;
    state: DeterrenceDeviceState;
}

export interface DeterrenceEventViewModel {
    id: int;
    message: string;
    isError: boolean;
}

export class DeterrenceViewModel extends BaseViewModel {
    // Properties

    public get deterrenceEventsObservable(): Rx.Observable<DeterrenceEventViewModel[]> {
        return Rx.combineLatest([this.deterrenceRepository.observeDeterrenceEvents(), this.timeDisplayMode]).pipe(
            RxOperators.map(([events]) => this.createEventViewModels(events)),
        );
    }

    public get deviceViewModelsObservable(): Rx.Observable<DeterrenceDeviceViewModel[]> {
        return this.deterrenceRepository
            .observeDeterrenceDevices()
            .pipe(RxOperators.map((deviceStates) => deviceStates.map(([device, state]) => ({ ...device, state }))));
    }

    public get activateDeviceButtonDisabledObservable(): Rx.Observable<boolean> {
        return this.deterrenceRepository
            .observeDeterrenceDevices()
            .pipe(
                RxOperators.map(
                    (deviceStates) => !deviceStates.map(([, state]) => state).includes(DeterrenceDeviceState.Selected),
                ),
            );
    }

    public get activateDeviceButtonTextObservable(): Rx.Observable<string> {
        return this.deterrenceRepository.observeDeterrenceDevices().pipe(
            RxOperators.map(
                (deviceStates) => deviceStates.filter(([, state]) => state === DeterrenceDeviceState.Selected).length,
            ),
            RxOperators.map((count) =>
                count === 0
                    ? t("deterrence.selectDevices")
                    : `${t("deterrence.activate", { count, device: t("deterrence.device", { count }) })}`,
            ),
        );
    }

    public get activatedDeviceTextObservable(): Rx.Observable<string | null> {
        const activatedDeviceCountObservable = this.deterrenceRepository
            .observeDeterrenceDevices()
            .pipe(
                RxOperators.map(
                    (deviceStates) =>
                        deviceStates.filter(([, state]) => state === DeterrenceDeviceState.Activated).length,
                ),
            );
        return Rx.combineLatest([this.showDevicesActivated, activatedDeviceCountObservable]).pipe(
            RxOperators.map(([showDevicesActivated, count]) =>
                showDevicesActivated ? t("deterrence.deviceActivated", { count }) : null,
            ),
        );
    }

    public get timeDisplayMode(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.appearance.timeDisplayMode),
        );
    }

    private showDevicesActivated = new Rx.Subject<boolean>();

    // Public functions

    public constructor(
        private localPreferencesRepository: LocalPreferencesRepository,
        private deterrenceRepository: DeterrenceRepository,
        private dateFormatter: DateFormatter,
    ) {
        super();
    }

    public setDeterrenceVisible(visible: boolean): void {
        this.deterrenceRepository.setDeterrenceVisible(visible);
    }

    public onDeterrenceDeviceClicked(device: DeterrenceDeviceViewModel): void {
        switch (device.state) {
            case DeterrenceDeviceState.Selected:
            case DeterrenceDeviceState.Available:
                this.deterrenceRepository.toggleSelectedState(device.id);
        }
    }

    public activateSelectedDevices(): Rx.Observable<void> {
        return this.deterrenceRepository.fireSelectedDevices().pipe(
            RxOperators.tap((result) => {
                console.info(
                    `${result.isSuccess ? "Successfully activated" : "Failed to activate"} deterrence device with id ${
                        result.deterrenceDeviceId
                    }`,
                );
            }),
            RxOperators.ignoreElements(),
        );
    }

    private createEventViewModels(events: DeterrenceEvent[]): DeterrenceEventViewModel[] {
        return events.map((event) => ({
            ...event,
            message: `${this.dateFormatter.formatFullDate(new Date(event.timestamp))} - [${
                event.deviceName || `id: ${event.deviceId}`
            }] ${event.isError ? event.message : t("deterrence.activated")}`,
        }));
    }
}
