import { APP_CONFIG_KEYS, getAppConfig } from "../AppConfig";
import { Language, LANGUAGE_RESOURCES } from "./Language";
import { registerLocale } from "react-datepicker";

const LANGUAGES_IN_CONFIG = getAppConfig(APP_CONFIG_KEYS.AVAILABLE_LANGUAGES);
const DEFAULT_LANGUAGE_CODE = getAppConfig(APP_CONFIG_KEYS.DEFAULT_LANGUAGE_CODE);
const ACTIVE_LANGUAGE_STORAGE_KEY = "language";
export const ACTIVE_LANGUAGE: Language = getActiveLanguage();

function getAvailableLanguagesFromConfig(): string[] {
    return LANGUAGES_IN_CONFIG.split(",").map((l) => l.trim());
}

export function getAvailableConfiguredLanguages(): string[] {
    const languageList = getAvailableLanguagesFromConfig();
    return languageList.filter((l) => l in LANGUAGE_RESOURCES);
}

function getStoredLanguageCode(): string {
    const languageCode = localStorage.getItem(ACTIVE_LANGUAGE_STORAGE_KEY);
    if (languageCode) {
        return languageCode;
    }
    return DEFAULT_LANGUAGE_CODE;
}

export function storeActiveLanguageCode(code: string): void {
    localStorage.setItem(ACTIVE_LANGUAGE_STORAGE_KEY, code);
}

function getActiveLanguage(): Language {
    const availableConfiguredLanguages = getAvailableConfiguredLanguages();
    const storedCode = getStoredLanguageCode();

    if (availableConfiguredLanguages.includes(storedCode)) {
        return LANGUAGE_RESOURCES[storedCode];
    } else if (availableConfiguredLanguages.includes(DEFAULT_LANGUAGE_CODE)) {
        return LANGUAGE_RESOURCES[DEFAULT_LANGUAGE_CODE];
    } else {
        return LANGUAGE_RESOURCES["en-US"];
    }
}

// `datepickerLocale` is being used for the `date-fns` package, used in the replay modal datepicker
// `intlLocale` is used for the JS localization, used in the `DateFormatter` class
export function registerDatepickerLocale(): void {
    registerLocale(ACTIVE_LANGUAGE.datepickerLocale, ACTIVE_LANGUAGE.languagePack);
}
