import { Classification as ClassificationProto } from "./proto/generated/classificationlist3_pb";
import { ClassificationJSON } from "./json";
import { Archetype, archetypeFromProto, archetypeFromClassificationJson } from ".";
import { ClassificationIcon, classificationIconfromProto, classificationIconFromClassificationJson } from ".";

export const CLASSIFICATION_MAX_Z_ORDER = 999;

function zOrderFromClassificationJson(model: ClassificationJSON): int {
    switch (model.classification) {
        case "DRONE":
            return 100;
        case "FIXED_WING":
            return 101;
        case "SUSPECTED_DRONE":
            return 102;
        case "SUSPECTED_FIXED_WING":
            return 103;
        case "HELICOPTER":
            return 104;
        case "AIRCRAFT":
            return 105;
        case "VEHICLE":
            return 106;
        case "OTHER":
            return 200;
        default:
            return CLASSIFICATION_MAX_Z_ORDER;
    }
}

export class Classification {
    // Static functions

    public static fromProto(model: ClassificationProto, override?: Partial<Classification>): Classification {
        return new Classification(
            (override && override.id) || model.getId(),
            (override && override.name) || model.getClassification(),
            (override && override.prettyName) || model.getPrettyname(),
            (override && override.defaultColor) || model.getDefaultcolor(),
            (override && override.archetype) || archetypeFromProto(model.getArchetype()),
            (override && override.icon) || classificationIconfromProto(model.getIcon()),
            (override && override.zOrder) || model.getZorder(),
        );
    }

    public static fromJson(model: ClassificationJSON): Classification {
        return new Classification(
            model["id"],
            model["classification"],
            model["prettyName"],
            model["defaultColor"],
            archetypeFromClassificationJson(model),
            classificationIconFromClassificationJson(model),
            zOrderFromClassificationJson(model),
        );
    }

    // Properties

    public get isBird(): boolean {
        return this.archetype === Archetype.BIRD;
    }

    public get isAircraft(): boolean {
        return this.archetype === Archetype.AIRCRAFT;
    }
    public get isVehicle(): boolean {
        return this.archetype === Archetype.VEHICLE;
    }
    public get isDrone(): boolean {
        return this.archetype === Archetype.DRONE;
    }

    public constructor(
        public readonly id: int,
        public readonly name: string,
        public readonly prettyName: string,
        public readonly defaultColor: string,
        public readonly archetype: Archetype,
        public readonly icon: ClassificationIcon,
        // Lower number -> higher priority
        public readonly zOrder: int,
    ) {}
}
