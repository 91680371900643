export class Rect {
    public constructor(
        public readonly left: number = 0,
        public readonly top: number = 0,
        public readonly right: number = 0,
        public readonly bottom: number = 0,
    ) {}

    public width(): number {
        return this.right - this.left;
    }

    public height(): number {
        return this.bottom - this.top;
    }

    public centerX(): number {
        return (this.left + this.right) * 0.5;
    }

    public centerY(): number {
        return (this.top + this.bottom) * 0.5;
    }

    public contains(x: number, y: number): boolean {
        return (
            this.left < this.right &&
            this.top < this.bottom &&
            x >= this.left &&
            x < this.right &&
            y >= this.top &&
            y < this.bottom
        );
    }
}
