import { DistanceFormatter, DistanceFormatType } from "../domain/DistanceFormatter";
import { DistanceUnit } from "../domain/model";
import { VelocityUnit } from "../domain/model/VelocityUnit";
import { t } from "i18next";

interface VelocityOptions {
    velocityUnit: VelocityUnit;
    displayUnit: boolean;
}

export const formatVelocity = (velocity: number, options: VelocityOptions): string => {
    const valueString = getVelocityValue(velocity, options.velocityUnit);

    if (!options.displayUnit) {
        return valueString;
    }

    const unitString = getVelocityUnitString(options.velocityUnit);

    return `${valueString} ${unitString}`;
};

export const formatTrackAltitude = (
    distanceFormatter: DistanceFormatter,
    altitude?: number,
    displayNegativeAltitudeAsZero?: boolean,
    distanceFormatType?: DistanceFormatType,
): string | null => {
    if (altitude == null || isNaN(altitude)) {
        return null;
    }

    let sanitizedAltitude = altitude;
    if (displayNegativeAltitudeAsZero) {
        sanitizedAltitude = Math.max(Math.floor(sanitizedAltitude), 0);
    }
    const altitudeString = distanceFormatter.formatValueWithCurrentUnit(sanitizedAltitude, DistanceUnit.METRIC, {
        formatType: distanceFormatType || DistanceFormatType.NONE,
    });
    return altitudeString;
};

const getVelocityValue = (velocity: number, velocityUnit: VelocityUnit): string => {
    switch (velocityUnit) {
        case VelocityUnit.KMH:
            return ((60 * 60 * velocity) / 1000).toFixed(1);
        case VelocityUnit.MPH:
            return ((60 * 60 * velocity) / 1609.344).toFixed(1);
        case VelocityUnit.MSEC:
        default:
            return velocity.toFixed(1);
    }
};

const getVelocityUnitString = (velocityUnit: VelocityUnit): string => {
    switch (velocityUnit) {
        case VelocityUnit.KMH:
            return t("unit.kilometerPerHour");
        case VelocityUnit.MPH:
            return t("unit.milePerHour");
        case VelocityUnit.MSEC:
        default:
            return t("unit.meterPerSecond");
    }
};
