import { UserManagementPermission, UserPermission } from "./UserPermission";
import { UserRoleJSON } from "./json";
import { UserRole as UserRoleProto } from "./proto/generated/users3_pb";

export class UserRole {
    // Public static functions

    public static fromProto(model?: UserRoleProto, userManagementPermission?: UserManagementPermission): UserRole {
        if (!model) {
            return UserRole.birdViewerObserverRole();
        }

        const permissions: UserPermission[] = [];

        if (model.getIsadmin()) {
            permissions.push(...this.getUserManagementPermissions(userManagementPermission));
        }

        if (!model.getIsobserver()) {
            permissions.push(UserPermission.CanManageRadar);
        }

        return new UserRole(
            /**
             * @todo replace name with `model.getId()` when it's provided by api
             */
            model.getName(),
            model.getName(),
            permissions,
            model.getIsadmin(),
            model.getIsobserver(),
        );
    }

    public static fromJson(model: UserRoleJSON, userManagementPermission?: UserManagementPermission): UserRole {
        const permissions: UserPermission[] = [];

        if (!model.isObserver) {
            permissions.push(UserPermission.CanManageRadar, UserPermission.CanManageOverlays);
        }

        if (model.isAdmin) {
            permissions.push(...this.getUserManagementPermissions(userManagementPermission));
        }

        return new UserRole(
            /**
             * @todo replace name with `model.id` when it's provided by api
             */
            model.name,
            model.name,
            permissions,
            model.isAdmin,
            model.isObserver,
        );
    }

    public static birdViewerObserverRole(): UserRole {
        return new UserRole("", "", [], false, true);
    }

    // Public functions

    public constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly permissions: UserPermission[],
        private readonly isAdmin: boolean,
        private readonly isObserver: boolean,
    ) {}

    public toJson(): UserRoleJSON {
        return {
            name: this.name,
            isAdmin: this.isAdmin,
            isObserver: this.isObserver,
        };
    }

    public toProto(): UserRoleProto {
        const role = new UserRoleProto();
        role.setIsadmin(this.isAdmin);
        role.setIsobserver(this.isObserver);
        role.setName(this.name);
        return role;
    }

    // Private functions

    private static getUserManagementPermissions(userManagementPermission?: UserManagementPermission): UserPermission[] {
        if (!userManagementPermission) {
            // If server does not provide a value for 'userManagementPermission', admins get access to user management by the role only
            return [UserPermission.CanManageUsers, UserPermission.CanEditUsers];
        }

        if (userManagementPermission === UserManagementPermission.ReadOnly) {
            return [UserPermission.CanManageUsers];
        }

        if (userManagementPermission === UserManagementPermission.ReadWrite) {
            return [UserPermission.CanManageUsers, UserPermission.CanEditUsers];
        }

        return [];
    }
}
