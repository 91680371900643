import styled from "styled-components";

export const OverlayHorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;

    > :first-child > div {
        margin-left: 0;
        margin-right: 0;
    }
`;
