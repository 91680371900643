import _minBy from "lodash/minBy";
import _takeWhile from "lodash/takeWhile";
import _dropWhile from "lodash/dropWhile";
import { ADSBFlight, ADSBFlightEstimate, EmitterCategory, Location, checkEstimateExpiration } from "../domain/model";

export const ADSBUtils = {
    getCurrentADSBFlightLocation(flight: ADSBFlight): Location | null {
        const lastPosition = flight.estimates[flight.estimates.length - 1];
        return lastPosition && lastPosition.location;
    },
    isAircraftADSBFlight(flight: ADSBFlight): boolean {
        return ![EmitterCategory.VEHICLE, EmitterCategory.KNOWN_VEHICLE].includes(flight.emitterCategory);
    },
    getClosestEstimateTo(flight: ADSBFlight, timestamp: long): ADSBFlightEstimate | null {
        return _minBy(flight.estimates, (e) => Math.abs(e.timestamp - timestamp)) || null;
    },
    getADSBFlightEstimatesWithinPeriod(flight: ADSBFlight, timestamp: long, lifeTime: number): ADSBFlightEstimate[] {
        const reversedEstimates = Array.from(flight.estimates).reverse();
        const endTrimmedEstimates = _takeWhile(reversedEstimates, (estimate) =>
            checkEstimateExpiration(estimate, timestamp, lifeTime),
        );
        const startTrimmedEstimates = _dropWhile(endTrimmedEstimates, (estimate) => estimate.timestamp > timestamp);
        const processedEstimates = startTrimmedEstimates.filter((estimate) => estimate != null);
        return processedEstimates;
    },
    getLastEstimate(flight: ADSBFlight): ADSBFlightEstimate {
        return flight.estimates[flight.estimates.length - 1];
    },
    getFlightEndTime(flight: ADSBFlight): long {
        return this.getLastEstimate(flight).timestamp;
    },
};
