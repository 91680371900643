import React from "react";
import { TimeViewModel } from "./TimeViewModel";
import { TYPES } from "../../../../di/Types";
import { useObservable } from "../../../../hooks";
import { Clock } from "./Clock";
import { useViewModel } from "../../../../hooks/useViewModel";
import { StatusView } from "./StatusView";
import { ClockContainer } from "../../../appearance/Sidebar";

export const TimeStatusView = (): JSX.Element => {
    // Properties

    const viewModel: TimeViewModel = useViewModel(TYPES.TimeViewModel);
    const currentTime = useObservable(viewModel.time, "");
    const radarTimezoneOffsetFromUtc = useObservable(viewModel.radarTimezoneOffsetFromUTC, null);

    // Render

    return (
        <ClockContainer>
            <Clock time={currentTime} timezoneOffset={radarTimezoneOffsetFromUtc} />
            <StatusView />
        </ClockContainer>
    );
};
