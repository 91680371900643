import React from "react";
import { encodeSvg } from "../../../utils/MapUtils";

export function generateDeterrenceDeviceSymbol(props: { color: string }): string {
    const svg: React.ReactElement = (
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="icon_deterrence_device" fillRule="nonzero" fill={props.color}>
                    <rect id="Rectangle" x="0.5" y="0.5" width="29" height="29" rx="4"></rect>
                </g>
            </g>
        </svg>
    );
    return encodeSvg(svg);
}

export function generateActivatedDeterrenceDeviceSymbol(props: { color: string }): string {
    const svg: React.ReactElement = (
        <svg xmlns="http://www.w3.org/2000/svg" width="56px" height="56px" viewBox="0 0 56 56" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="icon_deterrence_device_activated">
                    <g id="Group" transform="translate(14.000000, 14.000000)" fill={props.color} fillRule="nonzero">
                        <g id="b-link">
                            <rect id="b" x="0" y="0" width="28" height="28" rx="4"></rect>
                        </g>
                    </g>
                    <g
                        id="Group"
                        opacity="0.8"
                        transform="translate(5.000000, 5.000000)"
                        stroke={props.color}
                        strokeWidth="2"
                    >
                        <rect id="Rectangle" x="1" y="1" width="44" height="44" rx="8"></rect>
                    </g>
                    <g id="Group" opacity="0.6" stroke={props.color} strokeWidth="2">
                        <rect id="Rectangle" x="1" y="1" width="54" height="54" rx="12"></rect>
                    </g>
                </g>
            </g>
        </svg>
    );
    return encodeSvg(svg);
}
