import * as mapboxgl from "mapbox-gl";
import { RangeLayer } from "./RangeLayer";

const USER_LOCATION_RANGE_SOURCE_ID = "source-user-location-range";
const USER_LOCATION_RANGE_LAYER_ID = "layer-user-location-range";

export class UserLocationRangeLayer extends RangeLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): UserLocationRangeLayer {
        return new UserLocationRangeLayer(map, orderLayer, USER_LOCATION_RANGE_SOURCE_ID, USER_LOCATION_RANGE_LAYER_ID);
    }
}
