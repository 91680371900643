import { SettingItemViewModel, TextItemViewModel } from "../SettingItemViewModel";
import { SettingsItemSection } from "../SettingsItemSection";
import * as Rx from "rxjs";
import { t } from "i18next";
import { SettingItemIcon } from "../SettingItemIcon";
import AboutIcon from "../../../res/images/settings/about.svg";

export class CommonClassificationSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "classifications";
    public readonly label: string | null = t("settings.classifications");

    public constructor() {}

    // Public functions

    public generate(): Rx.Observable<SettingItemViewModel[]> {
        return Rx.of([
            new TextItemViewModel(t("settings.showClassificationLabel"), SettingItemIcon.withUrl(AboutIcon)),
        ]);
    }
}
