import * as Rx from "rxjs";
import { SettingsItemSection } from "../SettingsItemSection";
import { SettingsErrorHandler } from "../SettingsErrorHandler";
import { SettingsUIActionRequestHandler } from "../SettingsUIActionRequestHandler";
import { SettingItemIcon } from "../SettingItemIcon";
import { SettingItemViewModel, ActionItemViewModel } from "../SettingItemViewModel";
import { SettingsUIActionRequest } from "../SettingsUIActionRequest";
import UserManagementIcon from "../../../res/images/settings/user_management.svg";
import { UserPermission } from "../../../domain/model/UserPermission";
import { t } from "i18next";

export class CommonUserManagementSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "user-management";
    public readonly label: string | null = t("settings.userManagement");

    // Public functions

    public generate(
        _: SettingsErrorHandler,
        uiActionRequestHandler: SettingsUIActionRequestHandler,
    ): Rx.Observable<SettingItemViewModel[]> {
        const userManagementViewModel = new ActionItemViewModel(
            t("settings.userManagement"),
            SettingItemIcon.withUrl(UserManagementIcon),
            () => uiActionRequestHandler.onUIActionRequested(SettingsUIActionRequest.SHOW_USER_MANAGEMENT_DIALOG),
            undefined,
            [UserPermission.CanManageUsers],
        );
        return Rx.of([userManagementViewModel]);
    }
}
