import React, { useRef } from "react";
import styled, { useTheme } from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { INPUT_HEIGHT } from "../theme/Spacing";
import { ButtonColorsType } from "../Colors";
import { Icon, IconType } from "../Icon";
import ReactTooltip from "react-tooltip";
import { PropsWithTooltip } from "../Tooltip";
import { ScaleLoader } from "react-spinners";
import { showError } from "../../../utils/MessageUtils";

const getColorType = (color?: ButtonColorsType): ButtonColorsType => color ?? "default";

const TOOLTIP_ID = "button-tooltip_";

const DefaultButton = styled.button<{
    color?: ButtonColorsType;
    fillBg?: boolean;
    hasAction?: boolean;
}>`
    ${BASE_TEXT_STYLE};
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: ${({ hasAction }) => (hasAction ? "space-between" : "center")};
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x2};
    border: none;
    border-radius: ${({ theme }) => theme.spacing.x2};
    text-transform: uppercase;
    cursor: ${({ color }) => (color === "disabled" ? "not-allowed" : "pointer")};
    transition: background-color ${({ theme }) => theme.transitionDuration} ease-out,
        color ${({ theme }) => theme.transitionDuration} ease-out;
    font-weight: 500;
`;

const LargeButton = styled(DefaultButton)`
    padding: ${({ theme }) => theme.spacing.x2}
        ${({ theme, fillBg }) => (fillBg ? theme.spacing.x2 : theme.spacing.x10)};
    background-color: ${({ color, theme }) => theme.colors.button[getColorType(color)].bg};
    color: ${({ theme, color }) => (color === "disabled" ? theme.colors.text.text200 : theme.colors.text.text)};
    border-radius: ${({ theme }) => theme.spacing.x2};

    :is(:hover, :focus) {
        background-color: ${({ color, theme }) => theme.colors.button[getColorType(color)].bgHover};
    }
`;

const InlineButton = styled(DefaultButton)`
    padding: ${({ theme }) => theme.spacing.x1} ${({ theme }) => theme.spacing.x2};
    min-height: ${INPUT_HEIGHT};
    text-align: left;
    background-color: transparent;
    color: ${({ color, theme }) => theme.colors.button[getColorType(color)].bg};
    border-radius: ${({ theme }) => theme.spacing.x1};

    :is(:hover, :focus) {
        background-color: ${({ color, theme }) => theme.colors.button[getColorType(color)].inlineBgHover};
    }
`;

interface Props {
    color?: ButtonColorsType;
    disabled?: boolean;
    loading?: boolean;
    iconType?: IconType;
    iconPosition?: "left" | "right";
    label?: string;
    layout?: "inline" | "inline-fill";
    hasAction?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const RawButton = ({
    color,
    disabled,
    iconType,
    iconPosition = "right",
    label,
    layout,
    loading,
    onClick,
    tooltip,
    hasAction,
    tooltipPosition,
    ...otherProps
}: Omit<PropsWithTooltip<Props & React.HTMLAttributes<HTMLButtonElement>>, "children">): JSX.Element => {
    const theme = useTheme();

    const buttonRef = useRef<HTMLButtonElement>(null);
    let Component = LargeButton;
    switch (layout) {
        default:
            Component = LargeButton;
            break;
        case "inline":
            Component = InlineButton;
            break;
    }
    const showLabel = label !== undefined;
    const showIcon = iconType !== undefined;
    // Show a large icon if there is no label and a large button layout is used (which is default)
    const largeIcon = layout === undefined && !showLabel;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        try {
            // Prevent the button from submitting a form
            event.preventDefault();
            // Remove focus from the button
            buttonRef.current?.blur();
            // Proceed with the onClick handler
            onClick?.(event);
        } catch (error) {
            if (error instanceof Error) {
                showError(error);
            } else {
                console.error(error);
                showError({ message: "Check logs and notify support please.", name: "Unhandled Error" });
            }
        }
    };

    // Make sure the tooltip has a unique id otherwise it will render multiple tooltips
    const tooltipId = TOOLTIP_ID + Math.random().toString(36).substring(7);

    const contentOpacity = loading ? 0 : 1;

    return (
        <Component
            color={disabled ? "disabled" : color}
            data-for={tooltipId}
            data-tip={tooltip}
            data-iscapture="true"
            disabled={disabled}
            fillBg={layout === "inline-fill"}
            onClick={handleClick}
            ref={buttonRef}
            hasAction={hasAction}
            {...otherProps}
        >
            <div style={{ position: "absolute", opacity: Number(!contentOpacity), minWidth: "25px" }}>
                <ScaleLoader color={theme.colors.text.text} height="10px" width="1px" loading={true} />
            </div>
            {showIcon && iconPosition === "left" && (
                <Icon iconType={iconType} large={largeIcon} opacity={contentOpacity} />
            )}
            {showLabel && <span style={{ opacity: contentOpacity }}>{label}</span>}
            {showIcon && iconPosition === "right" && (
                <Icon iconType={iconType} large={largeIcon} opacity={contentOpacity} />
            )}
            <ReactTooltip id={tooltipId} effect={"solid"} place={tooltipPosition ?? "bottom"} />
        </Component>
    );
};

export const Button = styled(RawButton)``;
