import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { ReplayRepository } from "../domain/repositories";
import { PlaybackScene } from "../domain/PlaybackScene";

export function replayHandler<T>(
    replayRepository: ReplayRepository,
    observable: Rx.Observable<T>,
    handleReplay: (scene: PlaybackScene) => Rx.Observable<T>,
): Rx.Observable<T> {
    return replayRepository.currentPlaybackScene.pipe(
        RxOperators.switchMap((scene) => (scene == null ? observable : handleReplay(scene))),
    );
}
