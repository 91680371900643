// source: deterrencedevices3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceCommandMsg', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceCommandMsg.SubcommandCase', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceDevice', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceDeviceList', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceDeviceMeans', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceDeviceType', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceDeviceUpdate', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceEvent', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceEventsList', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceEventsListRequest', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceStartMsg', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceStopMsg', null, global);
goog.exportSymbol('proto.robin.proto3.deterrence.DeterrenceVolumeMsg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceDeviceMeans, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceDeviceMeans.displayName = 'proto.robin.proto3.deterrence.DeterrenceDeviceMeans';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.deterrence.DeterrenceDevice.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceDevice.displayName = 'proto.robin.proto3.deterrence.DeterrenceDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.deterrence.DeterrenceDeviceList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceDeviceList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceDeviceList.displayName = 'proto.robin.proto3.deterrence.DeterrenceDeviceList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceDeviceUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.displayName = 'proto.robin.proto3.deterrence.DeterrenceDeviceUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.displayName = 'proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceEvent.displayName = 'proto.robin.proto3.deterrence.DeterrenceEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceEventsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.deterrence.DeterrenceEventsList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceEventsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceEventsList.displayName = 'proto.robin.proto3.deterrence.DeterrenceEventsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceStartMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceStartMsg.displayName = 'proto.robin.proto3.deterrence.DeterrenceStartMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceStopMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceStopMsg.displayName = 'proto.robin.proto3.deterrence.DeterrenceStopMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceVolumeMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceVolumeMsg.displayName = 'proto.robin.proto3.deterrence.DeterrenceVolumeMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.displayName = 'proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceCommandMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceCommandMsg.displayName = 'proto.robin.proto3.deterrence.DeterrenceCommandMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.deterrence.DeterrenceEventsListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.deterrence.DeterrenceEventsListRequest.displayName = 'proto.robin.proto3.deterrence.DeterrenceEventsListRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceDeviceMeans.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    prettyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 5, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceDeviceMeans;
  return proto.robin.proto3.deterrence.DeterrenceDeviceMeans.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrettyname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceDeviceMeans.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrettyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string prettyname = 3;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.getPrettyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.setPrettyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string filename = 5;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool active = 6;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceMeans.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.deterrence.DeterrenceDevice.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicetype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    prettyname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    meansList: jspb.Message.toObjectList(msg.getMeansList(),
    proto.robin.proto3.deterrence.DeterrenceDeviceMeans.toObject, includeInstance),
    usedToday: jspb.Message.getFieldWithDefault(msg, 10, 0),
    remaining: jspb.Message.getFieldWithDefault(msg, 11, 0),
    capacity: jspb.Message.getFieldWithDefault(msg, 17, 0),
    statuscode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    statusmessage: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceDevice;
  return proto.robin.proto3.deterrence.DeterrenceDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.robin.proto3.deterrence.DeterrenceDeviceType} */ (reader.readEnum());
      msg.setDevicetype(value);
      break;
    case 4:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBearing(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRange(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrettyname(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 7:
      var value = new proto.robin.proto3.deterrence.DeterrenceDeviceMeans;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceDeviceMeans.deserializeBinaryFromReader);
      msg.addMeans(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setUsedToday(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRemaining(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCapacity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setStatuscode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicetype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPrettyname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMeansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.robin.proto3.deterrence.DeterrenceDeviceMeans.serializeBinaryToWriter
    );
  }
  f = message.getUsedToday();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getRemaining();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeSint32(
      17,
      f
    );
  }
  f = message.getStatuscode();
  if (f !== 0) {
    writer.writeSint32(
      12,
      f
    );
  }
  f = message.getStatusmessage();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeterrenceDeviceType devicetype = 3;
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceType}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getDevicetype = function() {
  return /** @type {!proto.robin.proto3.deterrence.DeterrenceDeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceType} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 4;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 4));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
*/
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double bearing = 5;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double range = 6;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string prettyname = 8;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getPrettyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setPrettyname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool connected = 14;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool active = 9;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated DeterrenceDeviceMeans means = 7;
 * @return {!Array<!proto.robin.proto3.deterrence.DeterrenceDeviceMeans>}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getMeansList = function() {
  return /** @type{!Array<!proto.robin.proto3.deterrence.DeterrenceDeviceMeans>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.deterrence.DeterrenceDeviceMeans, 7));
};


/**
 * @param {!Array<!proto.robin.proto3.deterrence.DeterrenceDeviceMeans>} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
*/
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setMeansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceMeans}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.addMeans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.robin.proto3.deterrence.DeterrenceDeviceMeans, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.clearMeansList = function() {
  return this.setMeansList([]);
};


/**
 * optional sint32 used_today = 10;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getUsedToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setUsedToday = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional sint32 remaining = 11;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional sint32 capacity = 17;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional sint32 statusCode = 12;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getStatuscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setStatuscode = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string statusMessage = 13;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.getStatusmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDevice.prototype.setStatusmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceDeviceList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceList: jspb.Message.toObjectList(msg.getDeviceList(),
    proto.robin.proto3.deterrence.DeterrenceDevice.toObject, includeInstance),
    allowDeterrenceAction: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceList}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceDeviceList;
  return proto.robin.proto3.deterrence.DeterrenceDeviceList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceList}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.deterrence.DeterrenceDevice;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceDevice.deserializeBinaryFromReader);
      msg.addDevice(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDeterrenceAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceDeviceList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.deterrence.DeterrenceDevice.serializeBinaryToWriter
    );
  }
  f = message.getAllowDeterrenceAction();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated DeterrenceDevice device = 1;
 * @return {!Array<!proto.robin.proto3.deterrence.DeterrenceDevice>}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.getDeviceList = function() {
  return /** @type{!Array<!proto.robin.proto3.deterrence.DeterrenceDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.deterrence.DeterrenceDevice, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.deterrence.DeterrenceDevice>} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceList} returns this
*/
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.setDeviceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.deterrence.DeterrenceDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.deterrence.DeterrenceDevice}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.addDevice = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.deterrence.DeterrenceDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceList} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.clearDeviceList = function() {
  return this.setDeviceList([]);
};


/**
 * optional bool allow_deterrence_action = 2;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.getAllowDeterrenceAction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceList} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceList.prototype.setAllowDeterrenceAction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    activemeansList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    usedToday: jspb.Message.getFieldWithDefault(msg, 10, 0),
    remaining: jspb.Message.getFieldWithDefault(msg, 11, 0),
    capacity: jspb.Message.getFieldWithDefault(msg, 17, 0),
    statuscode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    statusmessage: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceDeviceUpdate;
  return proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivemeans(values[i]);
      }
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setUsedToday(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRemaining(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCapacity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setStatuscode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getActivemeansList();
  if (f.length > 0) {
    writer.writePackedSint32(
      7,
      f
    );
  }
  f = message.getUsedToday();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getRemaining();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeSint32(
      17,
      f
    );
  }
  f = message.getStatuscode();
  if (f !== 0) {
    writer.writeSint32(
      12,
      f
    );
  }
  f = message.getStatusmessage();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool connected = 14;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool active = 9;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated sint32 activeMeans = 7;
 * @return {!Array<number>}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getActivemeansList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setActivemeansList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.addActivemeans = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.clearActivemeansList = function() {
  return this.setActivemeansList([]);
};


/**
 * optional sint32 used_today = 10;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getUsedToday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setUsedToday = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional sint32 remaining = 11;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional sint32 capacity = 17;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional sint32 statusCode = 12;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getStatuscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setStatuscode = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string statusMessage = 13;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.getStatusmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.prototype.setStatusmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatesList: jspb.Message.toObjectList(msg.getUpdatesList(),
    proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.toObject, includeInstance),
    resync: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList;
  return proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.deterrence.DeterrenceDeviceUpdate;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.deserializeBinaryFromReader);
      msg.addUpdates(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.deterrence.DeterrenceDeviceUpdate.serializeBinaryToWriter
    );
  }
  f = message.getResync();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated DeterrenceDeviceUpdate updates = 1;
 * @return {!Array<!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate>}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.getUpdatesList = function() {
  return /** @type{!Array<!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.deterrence.DeterrenceDeviceUpdate, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate>} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList} returns this
*/
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.setUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdate}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.addUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.deterrence.DeterrenceDeviceUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.clearUpdatesList = function() {
  return this.setUpdatesList([]);
};


/**
 * optional bool resync = 2;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.getResync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList} returns this
 */
proto.robin.proto3.deterrence.DeterrenceDeviceUpdateList.prototype.setResync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 2, 0),
    user: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 15, 0),
    meansid: jspb.Message.getFieldWithDefault(msg, 16, 0),
    triggerposition: (f = msg.getTriggerposition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    birdposition: (f = msg.getBirdposition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    iserror: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    errormessage: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceEvent;
  return proto.robin.proto3.deterrence.DeterrenceEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setDeviceid(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMeansid(value);
      break;
    case 8:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setTriggerposition(value);
      break;
    case 17:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setBirdposition(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setDuration(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIserror(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeSint32(
      15,
      f
    );
  }
  f = message.getMeansid();
  if (f !== 0) {
    writer.writeSint32(
      16,
      f
    );
  }
  f = message.getTriggerposition();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getBirdposition();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getIserror();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timestamp_msec = 2;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user = 3;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional sint32 deviceId = 15;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional sint32 meansId = 16;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getMeansid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setMeansid = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional robin.proto3.LatLonAltPosition triggerPosition = 8;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getTriggerposition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 8));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
*/
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setTriggerposition = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.clearTriggerposition = function() {
  return this.setTriggerposition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.hasTriggerposition = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional robin.proto3.LatLonAltPosition birdPosition = 17;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getBirdposition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 17));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
*/
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setBirdposition = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.clearBirdposition = function() {
  return this.setBirdposition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.hasBirdposition = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional sint32 duration = 10;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool isError = 12;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getIserror = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setIserror = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string errorMessage = 14;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEvent.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceEventsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceEventsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventList: jspb.Message.toObjectList(msg.getEventList(),
    proto.robin.proto3.deterrence.DeterrenceEvent.toObject, includeInstance),
    resync: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsList}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceEventsList;
  return proto.robin.proto3.deterrence.DeterrenceEventsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceEventsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsList}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.deterrence.DeterrenceEvent;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceEvent.deserializeBinaryFromReader);
      msg.addEvent(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceEventsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceEventsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.deterrence.DeterrenceEvent.serializeBinaryToWriter
    );
  }
  f = message.getResync();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated DeterrenceEvent event = 1;
 * @return {!Array<!proto.robin.proto3.deterrence.DeterrenceEvent>}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.getEventList = function() {
  return /** @type{!Array<!proto.robin.proto3.deterrence.DeterrenceEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.deterrence.DeterrenceEvent, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.deterrence.DeterrenceEvent>} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsList} returns this
*/
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.setEventList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.deterrence.DeterrenceEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.deterrence.DeterrenceEvent}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.addEvent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.deterrence.DeterrenceEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsList} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.clearEventList = function() {
  return this.setEventList([]);
};


/**
 * optional bool resync = 2;
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.getResync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsList} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEventsList.prototype.setResync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceStartMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceStartMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    meansid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceStartMsg}
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceStartMsg;
  return proto.robin.proto3.deterrence.DeterrenceStartMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceStartMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceStartMsg}
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setDeviceid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMeansid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceStartMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceStartMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getMeansid();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
};


/**
 * optional sint32 deviceId = 4;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceStartMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional sint32 meansId = 5;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.prototype.getMeansid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceStartMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceStartMsg.prototype.setMeansid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceStopMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceStopMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    meansid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceStopMsg}
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceStopMsg;
  return proto.robin.proto3.deterrence.DeterrenceStopMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceStopMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceStopMsg}
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setDeviceid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMeansid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceStopMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceStopMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getMeansid();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
};


/**
 * optional sint32 deviceId = 4;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceStopMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional sint32 meansId = 5;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.prototype.getMeansid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceStopMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceStopMsg.prototype.setMeansid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceVolumeMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    volume: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deviceid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg}
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceVolumeMsg;
  return proto.robin.proto3.deterrence.DeterrenceVolumeMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg}
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setVolume(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceVolumeMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolume();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
};


/**
 * optional sint32 volume = 2;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 deviceId = 4;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceVolumeMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceVolumeMsg.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    setting: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg;
  return proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSetting(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getSetting();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional sint32 deviceId = 1;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string setting = 2;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.getSetting = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.setSetting = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.SubcommandCase = {
  SUBCOMMAND_NOT_SET: 0,
  DETERRENCESTART: 1,
  DETERRENCESTOP: 2,
  DETERRENCEVOLUME: 3,
  DETERRENCECHANGESETTING: 4
};

/**
 * @return {proto.robin.proto3.deterrence.DeterrenceCommandMsg.SubcommandCase}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.getSubcommandCase = function() {
  return /** @type {proto.robin.proto3.deterrence.DeterrenceCommandMsg.SubcommandCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceCommandMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    deterrencestart: (f = msg.getDeterrencestart()) && proto.robin.proto3.deterrence.DeterrenceStartMsg.toObject(includeInstance, f),
    deterrencestop: (f = msg.getDeterrencestop()) && proto.robin.proto3.deterrence.DeterrenceStopMsg.toObject(includeInstance, f),
    deterrencevolume: (f = msg.getDeterrencevolume()) && proto.robin.proto3.deterrence.DeterrenceVolumeMsg.toObject(includeInstance, f),
    deterrencechangesetting: (f = msg.getDeterrencechangesetting()) && proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceCommandMsg;
  return proto.robin.proto3.deterrence.DeterrenceCommandMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.deterrence.DeterrenceStartMsg;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceStartMsg.deserializeBinaryFromReader);
      msg.setDeterrencestart(value);
      break;
    case 2:
      var value = new proto.robin.proto3.deterrence.DeterrenceStopMsg;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceStopMsg.deserializeBinaryFromReader);
      msg.setDeterrencestop(value);
      break;
    case 3:
      var value = new proto.robin.proto3.deterrence.DeterrenceVolumeMsg;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceVolumeMsg.deserializeBinaryFromReader);
      msg.setDeterrencevolume(value);
      break;
    case 4:
      var value = new proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg;
      reader.readMessage(value,proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.deserializeBinaryFromReader);
      msg.setDeterrencechangesetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceCommandMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeterrencestart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.robin.proto3.deterrence.DeterrenceStartMsg.serializeBinaryToWriter
    );
  }
  f = message.getDeterrencestop();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.robin.proto3.deterrence.DeterrenceStopMsg.serializeBinaryToWriter
    );
  }
  f = message.getDeterrencevolume();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.robin.proto3.deterrence.DeterrenceVolumeMsg.serializeBinaryToWriter
    );
  }
  f = message.getDeterrencechangesetting();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeterrenceStartMsg deterrenceStart = 1;
 * @return {?proto.robin.proto3.deterrence.DeterrenceStartMsg}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.getDeterrencestart = function() {
  return /** @type{?proto.robin.proto3.deterrence.DeterrenceStartMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.deterrence.DeterrenceStartMsg, 1));
};


/**
 * @param {?proto.robin.proto3.deterrence.DeterrenceStartMsg|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
*/
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.setDeterrencestart = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.clearDeterrencestart = function() {
  return this.setDeterrencestart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.hasDeterrencestart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeterrenceStopMsg deterrenceStop = 2;
 * @return {?proto.robin.proto3.deterrence.DeterrenceStopMsg}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.getDeterrencestop = function() {
  return /** @type{?proto.robin.proto3.deterrence.DeterrenceStopMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.deterrence.DeterrenceStopMsg, 2));
};


/**
 * @param {?proto.robin.proto3.deterrence.DeterrenceStopMsg|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
*/
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.setDeterrencestop = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.clearDeterrencestop = function() {
  return this.setDeterrencestop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.hasDeterrencestop = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeterrenceVolumeMsg deterrenceVolume = 3;
 * @return {?proto.robin.proto3.deterrence.DeterrenceVolumeMsg}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.getDeterrencevolume = function() {
  return /** @type{?proto.robin.proto3.deterrence.DeterrenceVolumeMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.deterrence.DeterrenceVolumeMsg, 3));
};


/**
 * @param {?proto.robin.proto3.deterrence.DeterrenceVolumeMsg|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
*/
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.setDeterrencevolume = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.clearDeterrencevolume = function() {
  return this.setDeterrencevolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.hasDeterrencevolume = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DeterrenceChangeSettingMsg deterrenceChangeSetting = 4;
 * @return {?proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.getDeterrencechangesetting = function() {
  return /** @type{?proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg, 4));
};


/**
 * @param {?proto.robin.proto3.deterrence.DeterrenceChangeSettingMsg|undefined} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
*/
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.setDeterrencechangesetting = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.robin.proto3.deterrence.DeterrenceCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.deterrence.DeterrenceCommandMsg} returns this
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.clearDeterrencechangesetting = function() {
  return this.setDeterrencechangesetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.deterrence.DeterrenceCommandMsg.prototype.hasDeterrencechangesetting = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.deterrence.DeterrenceEventsListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.deterrence.DeterrenceEventsListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    days: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsListRequest}
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.deterrence.DeterrenceEventsListRequest;
  return proto.robin.proto3.deterrence.DeterrenceEventsListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.deterrence.DeterrenceEventsListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsListRequest}
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.deterrence.DeterrenceEventsListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.deterrence.DeterrenceEventsListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDays();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 days = 1;
 * @return {number}
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.prototype.getDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.deterrence.DeterrenceEventsListRequest} returns this
 */
proto.robin.proto3.deterrence.DeterrenceEventsListRequest.prototype.setDays = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.robin.proto3.deterrence.DeterrenceDeviceType = {
  ACOUSTIC_PANIC_YELL: 0,
  ACOUSTIC_AIR_CANNON: 1,
  FLASHLIGHT: 2,
  PISTOL_CARTRIDGE: 3,
  CAR: 4,
  LASER: 5,
  CAR_HORN: 6,
  NO_DETERRENCE: 100
};

goog.object.extend(exports, proto.robin.proto3.deterrence);
