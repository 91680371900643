import { Location } from ".";
import { RunwayFunnel as RunwayFunnelProto } from "./proto/generated/atcinfo3_pb";

export class RunwayFunnel {
    // Static functions
    public static from(model: RunwayFunnelProto): RunwayFunnel {
        return new RunwayFunnel(
            model.getRunwayid(),
            Location.fromProto(model.getPointa()),
            Location.fromProto(model.getPointb()),
            model.getRunwayheight(),
            model.getRunwaywidth(),
            model.getFunnelheightmin(),
            model.getFunnelheightmax(),
            model.getFunnelwidth(),
            model.getFunnellength(),
            model.getCircuitheightmin(),
            model.getCircuitheightmax(),
            model.getCircuitwidth(),
            model.getCircuitlength(),
        );
    }

    public constructor(
        public readonly runwayId: int,
        public readonly pointA: Location,
        public readonly pointB: Location,
        public readonly runwayHeight: int,
        public readonly runwayWidth: int,
        public readonly funnelHeightMin: int,
        public readonly funnelHeightMax: int,
        public readonly funnelWidth: int,
        public readonly funnelLength: int,
        public readonly circuitHeightMin: int,
        public readonly circuitHeightMax: int,
        public readonly circuitWidth: int,
        public readonly circuitLength: int,
    ) {}
}
