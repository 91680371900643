export const TYPES = {
    FlavorConfig: Symbol.for("FlavorConfig"),
    API: Symbol.for("API"),
    ClientDataProvider: Symbol.for("ClientDataProvider"),
    ServerURL: Symbol.for("ServerURL"),
    DefaultValueProvider: Symbol.for("DefaultValueProvider"),
    DateFormatter: Symbol.for("DateFormatter"),
    MapModules: Symbol.for("MapModules"),
    DistanceFormatter: Symbol.for("DistanceFormatter"),
    TrackSymbolGenerator: Symbol.for("TrackSymbolGenerator"),
    ADSBFlightsSnapshotDiffCalculator: Symbol.for("ADSBFlightsSnapshotDiffCalculator"),
    TracksSnapshotDiffCalculator: Symbol.for("TracksSnapshotDiffCalculator"),
    // Repositories
    TrackRepository: Symbol.for("TrackRepository"),
    LocationInfoRepository: Symbol.for("LocationInfoRepository"),
    AlarmRepository: Symbol.for("AlarmRepository"),
    AlarmHistoryRepository: Symbol.for("AlarmHistoryRepository"),
    VersionRepository: Symbol.for("VersionRepository"),
    RadarRepository: Symbol.for("RadarRepository"),
    RunwayTrafficRepository: Symbol.for("RunwayTrafficRepository"),
    GridAnalysisRepository: Symbol.for("GridAnalysisRepository"),
    LocalPreferencesRepository: Symbol.for("LocalPreferencesRepository"),
    ServerPreferencesRepository: Symbol.for("ServerPreferencesRepository"),
    SessionRepository: Symbol.for("SessionRepository"),
    ReplayRepository: Symbol.for("ReplayRepository"),
    MapRepository: Symbol.for("MapRepository"),
    OverlayRepository: Symbol.for("OverlayRepository"),
    OverlayEditorRepository: Symbol.for("OverlayEditorRepository"),
    UserManagementRepository: Symbol.for("UserManagementRepository"),
    ConfigRepository: Symbol.for("ConfigRepository"),
    TrackObservationRepository: Symbol.for("TrackObservationRepository"),
    ServerConfigRepository: Symbol.for("ServerConfigRepository"),
    DeterrenceRepository: Symbol.for("DeterrenceRepository"),
    GroundObservationRepository: Symbol.for("GroundObservationRepository"),
    ADSBFlightRepository: Symbol.for("ADSBFlightRepository"),
    UserLocationRepository: Symbol.for("UserLocationRepository"),
    TracksHistogramRepository: Symbol.for("TracksHistogramRepository"),
    MeasurementPointsRepository: Symbol.for("MeasurementPointsRepository"),
    TrackSelectionRepository: Symbol.for("TrackSelectionRepository"),
    UIControlRepository: Symbol.for("UIControlRepository"),
    // ViewModels
    HomeViewModel: Symbol.for("HomeViewModel"),
    MapViewViewModel: Symbol.for("MapViewViewModel"),
    TimeViewModel: Symbol.for("TimeViewModel"),
    AltitudeFilterStatusIndicatorViewModel: Symbol.for("AltitudeFilterStatusIndicatorViewModel"),
    ClassificationFilterStatusIndicatorViewModel: Symbol.for("ClassificationFilterStatusIndicatorViewModel"),
    TimeStatusIndicatorViewModel: Symbol.for("TimeStatusIndicatorViewModel"),
    RainStatusIndicatorViewModel: Symbol.for("RainStatusIndicatorViewModel"),
    AlarmStatusIndicatorViewModel: Symbol.for("AlarmStatusIndicatorViewModel"),
    ReplayTimeViewModel: Symbol.for("ReplayTimeViewModel"),
    LoginViewModel: Symbol.for("LoginViewModel"),
    TileProvidersViewModel: Symbol.for("TileProvidersViewModel"),
    LegendViewModel: Symbol.for("LegendViewModel"),
    SettingsViewModel: Symbol.for("SettingsViewModel"),
    SettingsSectionsProvider: Symbol.for("SettingsSectionsProvider"),
    AltitudeSliderViewModel: Symbol.for("AltitudeSliderViewModel"),
    AltitudeFilterSettingsViewModel: Symbol.for("AltitudeFilterSettingsViewModel"),
    OverlayManagementViewModel: Symbol.for("OverlayManagementViewModel"),
    OverlayEditorViewModel: Symbol.for("OverlayEditorViewModel"),
    OverlayShapeRemoverViewModel: Symbol.for("OverlayShapeRemoverViewModel"),
    AlarmHistoryViewModel: Symbol.for("AlarmHistoryViewModel"),
    ReplayControlsViewModel: Symbol.for("ReplayControlsViewModel"),
    UserManagementViewModel: Symbol.for("UserManagementViewModel"),
    RadarControlPanelViewModel: Symbol.for("RadarControlPanelViewModel"),
    AlarmViewModel: Symbol.for("AlarmViewModel"),
    AboutViewModel: Symbol.for("AboutViewModel"),
    ObservationViewModel: Symbol.for("ObservationViewModel"),
    OpenIdConnectViewModel: Symbol.for("OpenIdConnectViewModel"),
    RunwayCrossingChartViewModel: Symbol.for("RunwayCrossingChartViewModel"),
    FunnelViewViewModel: Symbol.for("FunnelViewViewModel"),
    DetailedFunnelViewViewModel: Symbol.for("DetailedFunnelViewViewModel"),
    DeterrenceViewModel: Symbol.for("DeterrenceViewModel"),
    RunwayTrafficBottomSheetViewModel: Symbol.for("RunwayTrafficBottomSheetViewModel"),
    TrackListViewModel: Symbol.for("TrackListViewModel"),
    LanguageSelectionViewModel: Symbol.for("LanguageSelectionViewModel"),
    ReplayRequestViewModel: Symbol.for("ReplayRequestViewModel"),
    SidebarViewModel: Symbol.for("SidebarViewModel"),
    VehicleInfoViewModel: Symbol.for("VehicleInfoViewModel"),
    EdgeGlowViewModel: Symbol.for("EdgeGlowViewModel"),
    ClockViewModel: Symbol.for("ClockViewModel"),
    TimeSyncStatusIndicatorViewModel: Symbol.for("TimeSyncStatusIndicatorViewModel"),
    ToolbarViewModel: Symbol.for("ToolbarViewModel"),
    ToolbarButtonViewModel: Symbol.for("ToolbarButtonViewModel"),
    RunwayTrafficButtonViewModel: Symbol.for("RunwayTrafficButtonViewModel"),
    RadarStatusViewModel: Symbol.for("RadarStatusViewModel"),
    TrackInfoViewModel: Symbol.for("TrackInfoViewModel"),
    RadarNameViewModel: Symbol.for("RadarNameViewModel"),
    EventListViewModel: Symbol.for("EventListViewModel"),
    // Debug
    TracksDebugPanelViewModel: Symbol.for("TracksDebugPanelViewModel"),
};
