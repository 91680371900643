// source: adsbflightlist3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlight', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightList', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightPosition', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.UpdatetypeCase', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdates', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection', null, global);
goog.exportSymbol('proto.robin.proto3.adsbflightlist.Course', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.Course = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.Course, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.Course.displayName = 'proto.robin.proto3.adsbflightlist.Course';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightPosition.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.adsbflightlist.AdsbFlight.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlight.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.adsbflightlist.AdsbFlightList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightList.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.displayName = 'proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.Course.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.Course} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.Course.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    velocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.Course}
 */
proto.robin.proto3.adsbflightlist.Course.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.Course;
  return proto.robin.proto3.adsbflightlist.Course.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.Course} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.Course}
 */
proto.robin.proto3.adsbflightlist.Course.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVelocity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBearing(value);
      break;
    case 10:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.Course.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.Course} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.Course.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVelocity();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.Course} returns this
 */
proto.robin.proto3.adsbflightlist.Course.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float velocity = 4;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.getVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.Course} returns this
 */
proto.robin.proto3.adsbflightlist.Course.prototype.setVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float bearing = 5;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.Course} returns this
 */
proto.robin.proto3.adsbflightlist.Course.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 10;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 10));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.Course} returns this
*/
proto.robin.proto3.adsbflightlist.Course.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.Course} returns this
 */
proto.robin.proto3.adsbflightlist.Course.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.Course.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightPosition;
  return proto.robin.proto3.adsbflightlist.AdsbFlightPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 2:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 2;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 2));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightPosition.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.toObject = function(includeInstance, msg) {
  var f, obj = {
    icao: jspb.Message.getFieldWithDefault(msg, 1, 0),
    course: (f = msg.getCourse()) && proto.robin.proto3.adsbflightlist.Course.toObject(includeInstance, f),
    trajectoryList: jspb.Message.toObjectList(msg.getTrajectoryList(),
    common3_pb.LatLonAltPosition.toObject, includeInstance),
    positionList: jspb.Message.toObjectList(msg.getPositionList(),
    proto.robin.proto3.adsbflightlist.AdsbFlightPosition.toObject, includeInstance),
    groundspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    verticalspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    flightId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    squawk: jspb.Message.getFieldWithDefault(msg, 11, 0),
    isonsurface: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    hasspi: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    hasemergency: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    hasalert: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    emittercategory: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlight;
  return proto.robin.proto3.adsbflightlist.AdsbFlight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setIcao(value);
      break;
    case 2:
      var value = new proto.robin.proto3.adsbflightlist.Course;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.Course.deserializeBinaryFromReader);
      msg.setCourse(value);
      break;
    case 4:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.addTrajectory(value);
      break;
    case 5:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightPosition;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightPosition.deserializeBinaryFromReader);
      msg.addPosition(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundspeed(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBearing(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticalspeed(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSquawk(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsonsurface(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasspi(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasemergency(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasalert(value);
      break;
    case 16:
      var value = /** @type {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory} */ (reader.readEnum());
      msg.setEmittercategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcao();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getCourse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.robin.proto3.adsbflightlist.Course.serializeBinaryToWriter
    );
  }
  f = message.getTrajectoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getPositionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightPosition.serializeBinaryToWriter
    );
  }
  f = message.getGroundspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getVerticalspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSquawk();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
  f = message.getIsonsurface();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getHasspi();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getHasemergency();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getHasalert();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getEmittercategory();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory = {
  NONE: 0,
  LIGHTAIRCRAFT: 1,
  SMALLAIRCRAFT: 2,
  LARGEAIRCRAFT: 3,
  HIGHVORTEXLARGEAIRCRAFT: 4,
  HEAVYAIRCRAFT: 5,
  HIGHPERFORMANCEAIRCRAFT: 6,
  ROTORCRAFT: 10,
  GLIDER: 11,
  LIGHTERTHANAIR: 12,
  UNMANNEDAERIALVEHICLE: 13,
  SPACEVEHICLE: 14,
  ULTRALIGHT: 15,
  PARACHUTIST: 16,
  SURFACEEMERGENCYVEHICLE: 20,
  SURFACESERVICEVEHICLE: 21,
  POINTOBSTACLE: 22,
  CLUSTEROBSTACLE: 23,
  LINEOBSTACLE: 24,
  AIRCRAFT: 31,
  VEHICLE: 32,
  KNOWNVEHICLE: 33
};

/**
 * optional sint32 icao = 1;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getIcao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setIcao = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Course course = 2;
 * @return {?proto.robin.proto3.adsbflightlist.Course}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getCourse = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.Course} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.Course, 2));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.Course|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setCourse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.clearCourse = function() {
  return this.setCourse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.hasCourse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated robin.proto3.LatLonAltPosition trajectory = 4;
 * @return {!Array<!proto.robin.proto3.LatLonAltPosition>}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getTrajectoryList = function() {
  return /** @type{!Array<!proto.robin.proto3.LatLonAltPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, common3_pb.LatLonAltPosition, 4));
};


/**
 * @param {!Array<!proto.robin.proto3.LatLonAltPosition>} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setTrajectoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.robin.proto3.LatLonAltPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.addTrajectory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.robin.proto3.LatLonAltPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.clearTrajectoryList = function() {
  return this.setTrajectoryList([]);
};


/**
 * repeated AdsbFlightPosition position = 5;
 * @return {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightPosition>}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getPositionList = function() {
  return /** @type{!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightPosition, 5));
};


/**
 * @param {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightPosition>} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setPositionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.addPosition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.robin.proto3.adsbflightlist.AdsbFlightPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.clearPositionList = function() {
  return this.setPositionList([]);
};


/**
 * optional float groundSpeed = 6;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getGroundspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setGroundspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float bearing = 7;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float verticalSpeed = 8;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getVerticalspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setVerticalspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string flight_id = 10;
 * @return {string}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional sint32 squawk = 11;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getSquawk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setSquawk = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool isOnSurface = 12;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getIsonsurface = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setIsonsurface = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool hasSPI = 13;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getHasspi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setHasspi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool hasEmergency = 14;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getHasemergency = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setHasemergency = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool hasAlert = 15;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getHasalert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setHasalert = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional EmitterCategory emitterCategory = 16;
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory}
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.getEmittercategory = function() {
  return /** @type {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlight.prototype.setEmittercategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_ = [[5,6,7,8,9,10,11,12,13,14,15,20]];

/**
 * @enum {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.UpdatetypeCase = {
  UPDATETYPE_NOT_SET: 0,
  NEWFLIGHT: 5,
  DROPPEDFLIGHT: 6,
  ALERTCHANGED: 7,
  EMERGENCYCHANGED: 8,
  ISONSURFACECHANGED: 9,
  SPICHANGED: 10,
  FLIGHTIDCHANGED: 11,
  SQUAWKCHANGED: 12,
  NEWPOSITION: 13,
  NEWCOURSE: 14,
  EMITTERCATEGORYCHANGED: 15,
  RESYNCFLIGHTLIST: 20
};

/**
 * @return {proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.UpdatetypeCase}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getUpdatetypeCase = function() {
  return /** @type {proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.UpdatetypeCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    icao: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 2, 0),
    newflight: (f = msg.getNewflight()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.toObject(includeInstance, f),
    droppedflight: (f = msg.getDroppedflight()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.toObject(includeInstance, f),
    alertchanged: (f = msg.getAlertchanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.toObject(includeInstance, f),
    emergencychanged: (f = msg.getEmergencychanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.toObject(includeInstance, f),
    isonsurfacechanged: (f = msg.getIsonsurfacechanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.toObject(includeInstance, f),
    spichanged: (f = msg.getSpichanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.toObject(includeInstance, f),
    flightidchanged: (f = msg.getFlightidchanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.toObject(includeInstance, f),
    squawkchanged: (f = msg.getSquawkchanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.toObject(includeInstance, f),
    newposition: (f = msg.getNewposition()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.toObject(includeInstance, f),
    newcourse: (f = msg.getNewcourse()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.toObject(includeInstance, f),
    emittercategorychanged: (f = msg.getEmittercategorychanged()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.toObject(includeInstance, f),
    resyncflightlist: (f = msg.getResyncflightlist()) && proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setIcao(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 5:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.deserializeBinaryFromReader);
      msg.setNewflight(value);
      break;
    case 6:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.deserializeBinaryFromReader);
      msg.setDroppedflight(value);
      break;
    case 7:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.deserializeBinaryFromReader);
      msg.setAlertchanged(value);
      break;
    case 8:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.deserializeBinaryFromReader);
      msg.setEmergencychanged(value);
      break;
    case 9:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.deserializeBinaryFromReader);
      msg.setIsonsurfacechanged(value);
      break;
    case 10:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.deserializeBinaryFromReader);
      msg.setSpichanged(value);
      break;
    case 11:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.deserializeBinaryFromReader);
      msg.setFlightidchanged(value);
      break;
    case 12:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.deserializeBinaryFromReader);
      msg.setSquawkchanged(value);
      break;
    case 13:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.deserializeBinaryFromReader);
      msg.setNewposition(value);
      break;
    case 14:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.deserializeBinaryFromReader);
      msg.setNewcourse(value);
      break;
    case 15:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.deserializeBinaryFromReader);
      msg.setEmittercategorychanged(value);
      break;
    case 20:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.deserializeBinaryFromReader);
      msg.setResyncflightlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcao();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getNewflight();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.serializeBinaryToWriter
    );
  }
  f = message.getDroppedflight();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.serializeBinaryToWriter
    );
  }
  f = message.getAlertchanged();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.serializeBinaryToWriter
    );
  }
  f = message.getEmergencychanged();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.serializeBinaryToWriter
    );
  }
  f = message.getIsonsurfacechanged();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.serializeBinaryToWriter
    );
  }
  f = message.getSpichanged();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.serializeBinaryToWriter
    );
  }
  f = message.getFlightidchanged();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.serializeBinaryToWriter
    );
  }
  f = message.getSquawkchanged();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.serializeBinaryToWriter
    );
  }
  f = message.getNewposition();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.serializeBinaryToWriter
    );
  }
  f = message.getNewcourse();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.serializeBinaryToWriter
    );
  }
  f = message.getEmittercategorychanged();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.serializeBinaryToWriter
    );
  }
  f = message.getResyncflightlist();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string flight_id = 2;
 * @return {string}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    squawk: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSquawk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSquawk();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * optional sint32 squawk = 2;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.prototype.getSquawk = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged.prototype.setSquawk = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional robin.proto3.LatLonAltPosition position = 2;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 2));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groundspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    verticalspeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundspeed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBearing(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVerticalspeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroundspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getVerticalspeed();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional float groundSpeed = 4;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.getGroundspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.setGroundspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float bearing = 5;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float verticalSpeed = 6;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.getVerticalspeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse.prototype.setVerticalspeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    emittercategory: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory} */ (reader.readEnum());
      msg.setEmittercategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmittercategory();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AdsbFlight.EmitterCategory emitterCategory = 1;
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.prototype.getEmittercategory = function() {
  return /** @type {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlight.EmitterCategory} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged.prototype.setEmittercategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional sint32 icao = 1;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getIcao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setIcao = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timestamp_msec = 2;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional NewFlight newflight = 5;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getNewflight = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight, 5));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewFlight|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setNewflight = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearNewflight = function() {
  return this.setNewflight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasNewflight = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DroppedFlight droppedflight = 6;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getDroppedflight = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight, 6));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.DroppedFlight|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setDroppedflight = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearDroppedflight = function() {
  return this.setDroppedflight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasDroppedflight = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AlertChanged alertchanged = 7;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getAlertchanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged, 7));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.AlertChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setAlertchanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearAlertchanged = function() {
  return this.setAlertchanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasAlertchanged = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EmergencyChanged emergencychanged = 8;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getEmergencychanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged, 8));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmergencyChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setEmergencychanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearEmergencychanged = function() {
  return this.setEmergencychanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasEmergencychanged = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional IsOnSurfaceChanged isonsurfacechanged = 9;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getIsonsurfacechanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged, 9));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.IsOnSurfaceChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setIsonsurfacechanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearIsonsurfacechanged = function() {
  return this.setIsonsurfacechanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasIsonsurfacechanged = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SPIChanged spichanged = 10;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getSpichanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged, 10));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SPIChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setSpichanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearSpichanged = function() {
  return this.setSpichanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasSpichanged = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional FlightIdChanged flightidchanged = 11;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getFlightidchanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged, 11));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.FlightIdChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setFlightidchanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearFlightidchanged = function() {
  return this.setFlightidchanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasFlightidchanged = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional SquawkChanged squawkchanged = 12;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getSquawkchanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged, 12));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.SquawkChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setSquawkchanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearSquawkchanged = function() {
  return this.setSquawkchanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasSquawkchanged = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NewPosition newposition = 13;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getNewposition = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition, 13));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewPosition|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setNewposition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearNewposition = function() {
  return this.setNewposition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasNewposition = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NewCourse newcourse = 14;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getNewcourse = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse, 14));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.NewCourse|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setNewcourse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearNewcourse = function() {
  return this.setNewcourse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasNewcourse = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional EmitterCategoryChanged emitterCategoryChanged = 15;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getEmittercategorychanged = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged, 15));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.EmitterCategoryChanged|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setEmittercategorychanged = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearEmittercategorychanged = function() {
  return this.setEmittercategorychanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasEmittercategorychanged = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ResyncFlightList resyncflightlist = 20;
 * @return {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.getResyncflightlist = function() {
  return /** @type{?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList, 20));
};


/**
 * @param {?proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.ResyncFlightList|undefined} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.setResyncflightlist = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.clearResyncflightlist = function() {
  return this.setResyncflightlist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.prototype.hasResyncflightlist = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightList: jspb.Message.toObjectList(msg.getFlightList(),
    proto.robin.proto3.adsbflightlist.AdsbFlight.toObject, includeInstance),
    lastupdateid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightList}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightList;
  return proto.robin.proto3.adsbflightlist.AdsbFlightList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightList}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlight;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlight.deserializeBinaryFromReader);
      msg.addFlight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setLastupdateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlight.serializeBinaryToWriter
    );
  }
  f = message.getLastupdateid();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
};


/**
 * repeated AdsbFlight flight = 3;
 * @return {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlight>}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.getFlightList = function() {
  return /** @type{!Array<!proto.robin.proto3.adsbflightlist.AdsbFlight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlight, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlight>} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightList} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.setFlightList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlight}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.addFlight = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.adsbflightlist.AdsbFlight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightList} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.clearFlightList = function() {
  return this.setFlightList([]);
};


/**
 * optional sint32 lastupdateid = 5;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.getLastupdateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightList} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightList.prototype.setLastupdateid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightupdateList: jspb.Message.toObjectList(msg.getFlightupdateList(),
    proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.toObject, includeInstance),
    updateid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdates;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdate;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.deserializeBinaryFromReader);
      msg.addFlightupdate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setUpdateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightupdateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdate.serializeBinaryToWriter
    );
  }
  f = message.getUpdateid();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
};


/**
 * repeated AdsbFlightUpdate flightupdate = 1;
 * @return {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate>}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.getFlightupdateList = function() {
  return /** @type{!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate>} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.setFlightupdateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdate}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.addFlightupdate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.adsbflightlist.AdsbFlightUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.clearFlightupdateList = function() {
  return this.setFlightupdateList([]);
};


/**
 * optional sint32 updateid = 5;
 * @return {number}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.getUpdateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.prototype.setUpdateid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightupdatesList: jspb.Message.toObjectList(msg.getFlightupdatesList(),
    proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection;
  return proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.robin.proto3.adsbflightlist.AdsbFlightUpdates;
      reader.readMessage(value,proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.deserializeBinaryFromReader);
      msg.addFlightupdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightupdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.adsbflightlist.AdsbFlightUpdates.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdsbFlightUpdates flightupdates = 3;
 * @return {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates>}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.prototype.getFlightupdatesList = function() {
  return /** @type{!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.adsbflightlist.AdsbFlightUpdates, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates>} value
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection} returns this
*/
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.prototype.setFlightupdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdates}
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.prototype.addFlightupdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.adsbflightlist.AdsbFlightUpdates, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection} returns this
 */
proto.robin.proto3.adsbflightlist.AdsbFlightUpdatesCollection.prototype.clearFlightupdatesList = function() {
  return this.setFlightupdatesList([]);
};


goog.object.extend(exports, proto.robin.proto3.adsbflightlist);
