import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import {
    LoginRequest,
    BasicAuthLoginRequest,
    OpenIdConnectLoginRequest,
} from "../../../../domain/model/proto/generated/loginresult3_pb";
import {
    AuthMethod,
    EMPTY_SERVER_VERSIONS,
    LocalUserPreferenceKeys,
    ServerConfig,
    ServerVersions,
    SessionState,
    User,
    UserSession,
} from "../../../../domain/model";
import { SessionRepository, UNAUTHORIZED_ERROR_CODE } from "../../../../domain/repositories/SessionRepository";
import { LocalPreferencesRepository, ServerConfigRepository } from "../../../../domain/repositories";
import { t } from "i18next";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../../../infrastructure/AppConfig";
import { User as UserProto } from "../../../../domain/model/proto/generated/users3_pb";
import { GRPCLoginResult } from "../../../../domain/model/GRPCLoginResult";
import { GRPCLoginRequest } from "../../../../domain/model/GRPCLoginRequest";

const NICE_NAME = `BirdViewer ${process.env.REACT_APP_VERSION}`;

export class BirdViewerSessionRepository implements SessionRepository {
    // Properties

    public get session(): Rx.Observable<UserSession> {
        return this.stateSubject.asObservable();
    }

    public get useExternalLogout(): boolean {
        const externalLogoutUrl = getRuntimeConfig<string>(APP_CONFIG_KEYS.EXTERNAL_LOGOUT_URL);
        // External logout is only supported when a url is provided
        if (!externalLogoutUrl || externalLogoutUrl.length < 1) {
            return false;
        }
        return true || this.serverConfig.authMethod === AuthMethod.NoAuth;
    }

    public get usernameForReauth(): string | undefined {
        return this.lastLoggedInUsername;
    }

    private serverConfig!: ServerConfig;
    private serverVersion: ServerVersions = EMPTY_SERVER_VERSIONS;
    private lastLoggedInUsername?: string;
    private oauthError?: Error;
    private readonly stateSubject: Rx.BehaviorSubject<UserSession>;
    private subscriptions = new Rx.Subscription();

    public constructor(
        private readonly api: BirdViewerAPI,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly serverConfigRepository: ServerConfigRepository,
    ) {
        this.api = api;
        this.stateSubject = new Rx.BehaviorSubject<UserSession>(
            new UserSession(
                api.hasSessionId ? SessionState.LoggedIn : SessionState.LoggedOut,
                User.birdViewerObserverUser(
                    localPreferencesRepository.getPreference(LocalUserPreferenceKeys.user.name) || "",
                ),
            ),
        );
        this.subscribeToServerConfig();
        this.resumeSession();
        this.subscribeToAPIErrors();
    }

    // Public functions

    public loginBasicAuth(username: string, password: string): Rx.Observable<void> {
        const initialState = this.stateSubject.value.state;
        this.api.clearSessionId();
        this.stateSubject.next(new UserSession(SessionState.LoggingIn));

        const loginRequest: GRPCLoginRequest = {
            loginname: username,
            loginpassword: password,
            versionname: NICE_NAME,
        };

        // From api version 32304000 (REL 22.04), the login method is changed to basic auth
        const loginMethod =
            this.serverVersion.apiVersion < 32304000
                ? () => this.handleLogin(loginRequest)
                : () => this.handleLoginBasicAuth(loginRequest);

        return loginMethod().pipe(
            RxOperators.tap({
                next: (value) => {
                    if (!value || !value.userProto) {
                        return;
                    }
                    const user = this.getUserFromLoginResult(value.userProto);

                    this.stateSubject.next(new UserSession(SessionState.LoggedIn, user));
                },
                error: () => {
                    this.stateSubject.next(new UserSession(initialState));
                },
            }),
            RxOperators.catchError((error) => {
                console.warn("Login request failed", error);
                throw new Error(error.message || t("login.loginFailedAtHost"));
            }),
            RxOperators.tap((value) => {
                if (!value || !this.api.hasSessionId) {
                    throw Error(value.message || t("login.loginFailedWrongCredentials"));
                }
            }),
            RxOperators.ignoreElements(),
        );
    }

    public loginOpenIdConnect(oauthCode: string, redirectUri: string, nonce: string): Rx.Observable<void> {
        this.api.clearSessionId();
        this.stateSubject.next(new UserSession(SessionState.LoggingIn));

        const loginRequest = new OpenIdConnectLoginRequest();
        loginRequest.setAuthenticationcode(oauthCode);
        loginRequest.setNonce(nonce);
        loginRequest.setRedirecturi(redirectUri);

        return this.api.loginOpenIdConnect(loginRequest, this.serverConfig.tokenIssuer).pipe(
            RxOperators.tap({
                next: (value) => {
                    const user = value.getUser();
                    if (!value || !this.api.hasSessionId || !user) {
                        return;
                    }
                    this.stateSubject.next(
                        new UserSession(
                            SessionState.LoggedIn,
                            User.fromProto(user, this.serverConfig.userManagementPermission),
                        ),
                    );
                },
                error: () => this.stateSubject.next(new UserSession(SessionState.LoggedOut)),
            }),
            RxOperators.mergeMap((value) => {
                if (!value || !this.api.hasSessionId) {
                    return Rx.throwError(() => new Error(value.getMessage()));
                }
                return Rx.of(value);
            }),
            RxOperators.ignoreElements(),
        );
    }

    public logout(): Rx.Observable<void> {
        this.stateSubject.next(new UserSession(SessionState.LoggingOut));
        return this.api
            .logout()
            .pipe(
                RxOperators.tap(() => {
                    this.clearSessionIdAndChangeState();
                }),
            )
            .pipe(RxOperators.ignoreElements());
    }

    public subscribeToNewAccessTokens(): void {
        console.warn("subscribeToNewAccessTokens not implemented");
    }

    public getOauthError(): Error | undefined {
        return this.oauthError;
    }

    public setOauthError(error: Error): void {
        this.oauthError = error;
    }

    public clearOauthError(): void {
        this.oauthError = undefined;
    }

    // Private functions

    private subscribeToAPIErrors(): void {
        const subscription = this.api.errorObservable.subscribe((error) => {
            if (error.code === UNAUTHORIZED_ERROR_CODE) {
                this.clearSessionIdAndChangeState(SessionState.LoggingOutDueToUnauthorisedResponse);
            }
        });
        this.subscriptions.add(subscription);
    }

    private clearSessionIdAndChangeState(sessionState?: SessionState): void {
        // Don't update session state again if we are already logged out, or in the process of logging in
        switch (this.stateSubject.value.state) {
            case SessionState.LoggedOut:
            case SessionState.ExternalLogout:
            case SessionState.LoggingIn:
                return;
        }
        if (sessionState === SessionState.LoggingOutDueToUnauthorisedResponse && this.stateSubject.value.user) {
            // Store username before clearing session id
            this.lastLoggedInUsername = this.stateSubject.value.user.username;
        }
        // Clear session id and user
        this.api.clearSessionId();
        this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.user.user);
        this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.version.apiVersionName);
        this.stateSubject.next(
            new UserSession(
                this.useExternalLogout ? SessionState.ExternalLogout : sessionState ?? SessionState.LoggedOut,
            ),
        );
    }

    private resumeSession(): void {
        const userStr: string | null = this.localPreferencesRepository.getPreference(LocalUserPreferenceKeys.user.user);
        const state = this.api.hasSessionId ? SessionState.LoggedIn : SessionState.LoggedOut;
        if (!userStr) {
            return;
        }
        const user: User = JSON.parse(userStr);
        this.stateSubject.next(new UserSession(state, user));
        // Trigger an api request to see if session is still valid. If not, clear session id and change state
        // NOTE: We use the getTileProviders call here because it is an authenticated call. Ideally we would use
        // a resume session endpoint here but that does not exist in the api yet.
        const subscription = this.api.getTileProviders().subscribe({
            error: (error) => {
                if (error.code === UNAUTHORIZED_ERROR_CODE) {
                    this.clearSessionIdAndChangeState();
                }
            },
        });
        this.subscriptions.add(subscription);
    }

    private getUserFromLoginResult(userProto: UserProto): User {
        const user = User.fromProto(userProto, this.serverConfig.userManagementPermission);

        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.user, JSON.stringify(user));

        // Cleanup from old versions
        this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.user.name);

        return user;
    }

    /**
     * @deprecated This method is deprecated since api version 32304000 and will be removed in the future.
     * Use `handleLoginBasicAuth()` instead.
     */
    private handleLogin(grpcLoginRequest: GRPCLoginRequest): Rx.Observable<GRPCLoginResult> {
        const loginRequest = new LoginRequest();
        loginRequest.setLoginname(grpcLoginRequest.loginname);
        loginRequest.setLoginpassword(grpcLoginRequest.loginpassword);
        loginRequest.setVersionname(NICE_NAME);

        return this.api.login(loginRequest).pipe(
            Rx.map((result) => {
                // Older versions of the GRPC api provide the radar software version name here,
                // instead of in the server config
                this.serverConfigRepository.setRadarSoftwareVersionName(result.getVersionname() || "");
                return new GRPCLoginResult(result.getUser(), result.getStatusmessage());
            }),
        );
    }

    private handleLoginBasicAuth(grpcLoginRequest: GRPCLoginRequest): Rx.Observable<GRPCLoginResult> {
        const loginRequest = new BasicAuthLoginRequest();
        loginRequest.setLoginname(grpcLoginRequest.loginname);
        loginRequest.setLoginpassword(grpcLoginRequest.loginpassword);
        loginRequest.setVersionname(NICE_NAME);

        return this.api
            .loginBasicAuth(loginRequest)
            .pipe(Rx.map((result) => new GRPCLoginResult(result.getUser(), result.getMessage())));
    }

    private subscribeToServerConfig(): void {
        this.subscriptions.add(
            this.serverConfigRepository.config.subscribe((serverConfig) => (this.serverConfig = serverConfig)),
        );
        this.subscriptions.add(
            this.serverConfigRepository.serverVersions.subscribe((versions) => (this.serverVersion = versions)),
        );
    }
}
