import React from "react";
import styled from "styled-components";
import { t } from "i18next";
import { Icon } from "../../../appearance/Icon";
import Tooltip from "../../../appearance/Tooltip";

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 9px 0 9px 0;
    flex: 1;
    label,
    strong {
        padding-left: 16px;
        color: white;
        font-size: 14px;
    }
`;

const StatusDot = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    width: 16px;
    height: 16px;
    border-radius: 8px;
`;

interface Props {
    color: string;
    hasMessage: boolean;
    label: string;
    tooltip: string;
}

export const ComponentStatus = ({ color, hasMessage, label, tooltip }: Props): JSX.Element => {
    // Render

    const tooltipId = "radar-component-status-tooltip-" + String(Math.random());

    return (
        <div key={label + color}>
            <StatusContainer data-for={tooltipId} data-tip={tooltip}>
                <StatusDot color={color} />
                <label>{label}</label>
                {hasMessage && <Icon color="info" iconType="INFO" tooltip={t("radarStatus.showMoreInfo")} />}
            </StatusContainer>
            <Tooltip id={tooltipId} effect={"solid"} />
        </div>
    );
};
