import React from "react";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import DI from "./../../../src/di/DI";
import { TYPES } from "../../di/Types";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { UserManagementViewModel } from "./UserManagementViewModel";
import { UserList } from "./UserList";
import { User } from "../../domain/model/User";
import { AddEditUser } from "./AddEditUser";
import { ScreenDimmer } from "../dimmer/ScreenDimmer";
import { UserRole } from "../../domain/model/UserRole";
import { showError } from "../../utils/MessageUtils";
import { Modal } from "../appearance/modal/Modal";
import { t } from "i18next";

const FADING_ANIMATION_DURATION_MILLIS = 300;

enum Panel {
    UserList,
    AddUser,
    EditUser,
}

interface Props {
    onClosed: () => void;
}

interface State {
    editingUser: User | null;
    users: User[];
    roles: UserRole[];
    activePanel: Panel;
}

export class UserManagement extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private get title(): string {
        switch (this.state.activePanel) {
            case Panel.UserList:
                return t("userManagement.userManagement");
            case Panel.AddUser:
                return t("userManagement.addUser");
            case Panel.EditUser:
                return t("userManagement.editUser");
        }
    }

    private readonly screenDimmer = React.createRef<ScreenDimmer>();
    private viewModel: UserManagementViewModel = DI.get(TYPES.UserManagementViewModel);

    // Lifecycle

    public constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            editingUser: null,
            users: [],
            roles: [],
            activePanel: Panel.UserList,
        };
    }

    public componentDidMount(): void {
        this.subscribeToUsers();
        this.subscribeToRoles();
        this.subscribeToErrors();
        this.fetchInitialData();
    }

    // Public functions

    public render(): React.ReactNode {
        // This solved issues with eslint indentation below
        const isAddingOrEditing = this.state.activePanel === Panel.AddUser || this.state.activePanel === Panel.EditUser;

        return (
            <Modal title={this.title} onClosed={this.props.onClosed}>
                {this.state.activePanel === Panel.UserList && (
                    <UserList
                        users={this.state.users}
                        onRequestEdit={this.showEdit.bind(this)}
                        onRequestAdd={this.showAdd.bind(this)}
                        onClosed={this.close.bind(this)}
                        canEditUsers={this.viewModel.canCurrentUserEditUsers()}
                    />
                )}
                {this.viewModel.canCurrentUserEditUsers() && isAddingOrEditing && (
                    <AddEditUser
                        roles={this.state.roles}
                        user={this.state.editingUser}
                        isSelf={this.state.editingUser != null && this.viewModel.isSelf(this.state.editingUser)}
                        onRequestSubmit={this.submitUserForm.bind(this)}
                        onClosed={this.showList.bind(this)}
                        onRequestDelete={this.deleteUser.bind(this)}
                    />
                )}
            </Modal>
        );
    }

    // Private functions

    private showList(): void {
        this.setState({ activePanel: Panel.UserList });
    }

    private showEdit(user: User): void {
        this.setState({
            editingUser: user,
            activePanel: Panel.EditUser,
        });
    }

    private submitUserForm(user: User): Rx.Observable<void> {
        if (this.state.editingUser === null) {
            return this.viewModel.addUser(user).pipe(
                RxOperators.tap({
                    complete: this.showList.bind(this),
                }),
            );
        } else {
            return this.viewModel.editUser(user).pipe(
                RxOperators.tap({
                    complete: this.showList.bind(this),
                }),
            );
        }
    }

    private deleteUser(): Rx.Observable<void> {
        if (this.state.editingUser === null) {
            return Rx.EMPTY;
        }
        return this.viewModel.deleteUser(this.state.editingUser).pipe(
            RxOperators.tap({
                complete: this.showList.bind(this),
            }),
        );
    }

    private showAdd(): void {
        this.setState({
            editingUser: null,
            activePanel: Panel.AddUser,
        });
    }

    private close(): void {
        if (this.screenDimmer.current) {
            this.screenDimmer.current.hide();
        }
        setTimeout(() => this.props.onClosed(), FADING_ANIMATION_DURATION_MILLIS);
    }

    private fetchInitialData(): void {
        const subscription = this.viewModel.fetchInitialData().subscribe({
            complete: this.showList.bind(this),
        });
        this.collectSubscription(subscription);
    }

    private subscribeToErrors(): void {
        const subscription = this.viewModel.errorObservable.subscribe((error) => showError(error));
        this.collectSubscription(subscription);
    }

    private subscribeToUsers(): void {
        const subscription = this.viewModel.usersObservable.subscribe((users) =>
            this.setState({
                users: users,
            }),
        );
        this.collectSubscription(subscription);
    }

    private subscribeToRoles(): void {
        const subscription = this.viewModel.rolesObservable.subscribe((roles) =>
            this.setState({
                roles: roles,
            }),
        );
        this.collectSubscription(subscription);
    }
}
