import React from "react";
import styled from "styled-components";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import mapboxgl, { GeoJSONSourceRaw, GeolocateControl, NavigationControl, ScaleControl } from "mapbox-gl";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { MapViewViewModel } from "./MapViewViewModel";
import { TileProvider, Location, GeolocationPosition, DistanceUnit, locationToLngLatLike } from "../../domain/model";
import { LogoControl } from "./LogoControl";
import { CenterToRadarControl } from "./CenterToRadarControl";
import { CustomFullScreenControl } from "./CustomFullScreenControl";
import { TYPES } from "../../di/Types";
import DI from "./../../di/DI";
import { ORDER_LAYERS } from "./modules/Orders";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapModule } from "./modules/MapModule";
import { MapModuleViewModel } from "./modules/MapModuleViewModel";
import { showErrorWithOptions } from "../../utils/MessageUtils";
import RadarCrossHairIcon from "../../res/images/radar_crosshair.svg";
import { MapBoxLayerManager } from "./modules/MapBoxLayerManager";
import { UserLocationState } from "../../domain/repositories";
import { MeasurementControl } from "./MeasurementControl";
import { isInStandaloneMode } from "../../utils/PwaUtils";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../infrastructure/AppConfig";
import { DEFAULT_MAP_STYLE_URL } from "../../utils/MapUtils";
import { DistanceFormatter } from "../../domain/DistanceFormatter";
import { PlaybackState } from "../../domain/PlaybackScene";
import { t } from "i18next";
import { SIDEBAR_WIDTH } from "../appearance/Sidebar";
import { EdgeGlow } from "../edgeglow/EdgeGlow";

const MapContentContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    margin-right: ${SIDEBAR_WIDTH};
`;

const StyledMap = styled.div`
    flex: 1;
`;

const RadarCrossHair = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: url(${RadarCrossHairIcon});
    background-repeat: no-repeat;
    background-position: center;
`;

interface State {
    isRepositioning: boolean;
}

export class MapView extends BaseSubscriptionHandlerComponent<{}, State> {
    // Properties

    private readonly viewModel: MapViewViewModel = DI.get(TYPES.MapViewViewModel);
    private readonly modules: MapModule<MapModuleViewModel>[] = DI.get(TYPES.MapModules);
    private readonly distanceFormatter: DistanceFormatter = DI.get(TYPES.DistanceFormatter);
    private map: mapboxgl.Map | undefined;
    private currentTileProviderUrl: string | null = null;

    public constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            isRepositioning: false,
        };
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <MapContentContainer>
                <StyledMap id="map" />
                {this.state.isRepositioning && <RadarCrossHair />}
                <EdgeGlow />
            </MapContentContainer>
        );
    }

    // Lifecycle

    public componentDidMount(): void {
        this.collectSubscriptions(
            // Only the initial update is required to setup the map
            this.viewModel.tileProviderAndAirbaseInfo.pipe(RxOperators.take(1)).subscribe({
                next: (info) => this.setup(info.selectedTileProvider, info.referenceLocation),
                error: (error) => console.warn("Initial map setup failed", error),
            }),
            // Skip the initial update and use the rest to update the map state
            this.viewModel.tileProviderAndAirbaseInfo.pipe(RxOperators.skip(1)).subscribe({
                next: (info) => this.update(info.selectedTileProvider.url),
                error: (error) => console.warn("Failed to get map updates", error),
            }),
            this.viewModel.isRepositioning.subscribe({
                next: (value) => this.setState({ isRepositioning: value }),
                error: (error) => console.warn("Failed to get repositioning state", error),
            }),
        );
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.viewModel.unsubscribeFromObservables();
        this.modules.forEach((module) => module.dispose());
        this.viewModel.setMapLoaded(false);
    }

    public resize(): void {
        this.map && this.map.resize();
    }

    // Private functions

    private setup(tileProvider: TileProvider, referenceLocation: Location): void {
        this.currentTileProviderUrl = tileProvider.url;
        mapboxgl.accessToken = tileProvider.apiKey;
        if (this.map) {
            this.map.remove();
        }

        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!;
        this.map = new mapboxgl.Map({
            container: "map",
            zoom: 14.5,
            center: locationToLngLatLike(referenceLocation),
            bearing: 0,
            pitch: 0,
            style: this.currentTileProviderUrl,
            hash: true,
            attributionControl: false,
            locale: this.prepareMapboxTranslations(),
        });

        this.map.addControl(new mapboxgl.AttributionControl(), "bottom-left");
        const showBranding = !getRuntimeConfig<boolean>(APP_CONFIG_KEYS.HIDE_ROBIN_BRANDING);
        if (showBranding) {
            this.map.addControl(new LogoControl(), "bottom-left");
        }
        const scaleControl = new ScaleControl({
            unit: this.scaleControlUnit(this.distanceFormatter.selectedDistanceUnit),
        });
        this.map.addControl(scaleControl, "bottom-right");
        this.collectSubscription(
            this.distanceFormatter.selectedDistanceUnitObservable.subscribe((unit) =>
                scaleControl.setUnit(this.scaleControlUnit(unit)),
            ),
        );
        if (!isInStandaloneMode()) {
            this.map.addControl(
                new CustomFullScreenControl({ container: document.getElementById("root") }),
                "bottom-right",
            );
        }
        // The typescript bindings for mapbox are not up-to-date with js definitions
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.map.addControl(this.createCompassControl(this.map), "bottom-right");
        this.map.addControl(new NavigationControl({ showCompass: false }), "bottom-right");
        this.map.addControl(this.createGeolocateControl(this.map), "bottom-right");
        this.map.addControl(this.createCenterToRadarControl(), "bottom-right");
        this.map.addControl(new MeasurementControl(DI.get(TYPES.LocalPreferencesRepository)), "bottom-right");

        this.map.on("style.load", () => {
            const map = this.map!;
            this.setupOrderedMapLayers(map);
            const layerManager = new MapBoxLayerManager(map);
            this.modules.forEach((module) => {
                module.dispose();
                module.setup(map, referenceLocation, layerManager);
            });
        });
        this.map.on("error", (ev) => {
            console.error(ev.error);
            showErrorWithOptions({
                title: t("messages.mapStyleErrorTitle"),
                message: t("messages.mapStyleErrorMessage"),
            });
            this.update(DEFAULT_MAP_STYLE_URL);
        });
        this.map.once("load", () => {
            this.viewModel.setMapLoaded(true);
        });

        this.map.easeTo({ center: locationToLngLatLike(referenceLocation) }, { automated: true });

        this.subscribeMapCenterAndBearing();
    }

    private update(tileProviderUrl: string): void {
        if (tileProviderUrl !== this.currentTileProviderUrl) {
            this.currentTileProviderUrl = tileProviderUrl;
            this.map!.setStyle(tileProviderUrl, { diff: false });
        }
    }

    private setupOrderedMapLayers(map: mapboxgl.Map): void {
        const emptySource: GeoJSONSourceRaw = { type: "geojson", data: { type: "FeatureCollection", features: [] } };
        ORDER_LAYERS.forEach((orderLayer) => map.addLayer({ type: "line", id: orderLayer.id, source: emptySource }));
    }

    private createCompassControl(map: mapboxgl.Map): mapboxgl.NavigationControl {
        const control = new NavigationControl({ showZoom: false, visualizePitch: true });
        const defaultMinPitch = map.getMinPitch();
        const defaultMaxPitch = map.getMaxPitch();
        const subscription = this.viewModel.playbackState.subscribe({
            next: (state) => {
                if (state == null || state === PlaybackState.STOPPED) {
                    map.setMinPitch(defaultMinPitch);
                    map.setMaxPitch(defaultMaxPitch);
                } else {
                    map.setMinPitch(0);
                    map.setMaxPitch(0);
                }
            },
            error: (error) => console.warn("Failed to get playback state", error),
        });
        this.collectSubscription(subscription);
        return control;
    }

    private createGeolocateControl(map: mapboxgl.Map): GeolocateControl {
        const geolocateControl = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            showUserLocation: false,
            trackUserLocation: true,
        });

        geolocateControl.on("geolocate", (object?: Object) =>
            this.viewModel.onGeolocateEvent(object as GeolocationPosition),
        );

        map.on("load", () => {
            const geolocateControl: Element | null = window.document
                .getElementsByClassName("mapboxgl-ctrl-geolocate")
                .item(0);
            if (!geolocateControl) {
                return;
            }
            const observer = new MutationObserver((mutations) =>
                this.viewModel.onGeolocateStateChange(this.getUserLocationState(mutations)),
            );
            observer.observe(geolocateControl, {
                attributes: true,
                attributeFilter: ["class"],
            });
        });

        return geolocateControl;
    }

    private getUserLocationState(mutations: MutationRecord[]): UserLocationState {
        const hasClass = (mutations: MutationRecord[], className: string): boolean =>
            mutations.some((m) => (m.target as Element).classList.contains(className));
        if (hasClass(mutations, "mapboxgl-ctrl-geolocate-active")) {
            return UserLocationState.TRACKING;
        } else if (hasClass(mutations, "mapboxgl-ctrl-geolocate-background")) {
            return UserLocationState.BACKGROUND;
        } else {
            return UserLocationState.INACTIVE;
        }
    }

    private createCenterToRadarControl(): CenterToRadarControl {
        return new CenterToRadarControl({
            onStartTracking: () => this.viewModel.onStartTrackingRadarLocation(),
            onStopTracking: () => this.viewModel.onStopTrackingRadarLocation(),
        });
    }

    private scaleControlUnit(distanceUnit: DistanceUnit): "metric" | "imperial" {
        switch (distanceUnit) {
            case DistanceUnit.METRIC:
                return "metric";
            case DistanceUnit.IMPERIAL:
                return "imperial";
        }
    }

    private prepareMapboxTranslations(): { [key: string]: string } | undefined {
        // Refer to https://github.com/mapbox/mapbox-gl-js/blob/main/src/ui/default_locale.js for more translations
        return {
            "FullscreenControl.Enter": t("map.enterFullscreen"),
            "FullscreenControl.Exit": t("map.exitFullscreen"),
            "GeolocateControl.FindMyLocation": t("map.findMyLocation"),
            "NavigationControl.ResetBearing": t("map.resetBearingToNorth"),
        };
    }

    private subscribeMapCenterAndBearing(): void {
        // Ease to the center and/or bearing if new data is emitted
        this.collectSubscription(
            Rx.combineLatest([this.viewModel.mapCenter, this.viewModel.mapBearing])
                .pipe(
                    // Debounce to avoid receiving center or bearing once when both tracking states are cancelled
                    RxOperators.debounceTime(50),
                    // Don't do anything if nothing to update
                    RxOperators.filter(([center, bearing]) => center != null || bearing != null),
                    // Create an options object for the easeTo method with the changed values
                    RxOperators.map(([center, bearing]) => {
                        const options: mapboxgl.EaseToOptions = { duration: 2000 };
                        if (center != null) {
                            options.center = locationToLngLatLike(center);
                        }
                        if (bearing != null) {
                            options.bearing = bearing;
                        }
                        return options;
                    }),
                )
                .subscribe((options) => {
                    this.map?.easeTo(options, { automated: true });
                }),
        );

        // Stop following the bearing if the user drags, rotates or changes the pitch of the map
        const onUserMapMovement = (
            e: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | undefined> & mapboxgl.EventData,
        ): void => {
            if (e.automated) {
                return;
            }
            this.viewModel.onUserMapMovement();
        };
        this.map?.on("dragstart", onUserMapMovement);
        this.map?.on("rotatestart", onUserMapMovement);
        this.map?.on("pitchstart", onUserMapMovement);
    }
}
