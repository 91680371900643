import React, { useEffect, useState } from "react";
import { AlertViewFrame, AlertViewContainer } from "../appearance/AlertView";
import { Colors } from "../appearance/Colors";
import styled from "styled-components";
import { VerticalSpace } from "../appearance/Space";
import { isInStandaloneMode } from "../../utils/PwaUtils";
import { t } from "i18next";
import { SelectableText } from "../appearance/SelectableText";
import { Button } from "../appearance/button/Button";

const commonStyling = `
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
`;

const PrimaryText = styled.div`
    ${commonStyling}
    color: ${({ theme }) => theme.colors.text.text};
`;

const SecondaryText = styled.div`
    ${commonStyling}
    color: ${({ theme }) => theme.colors.text.text300};
`;

export interface ErrorViewProps {
    primaryText: string;
    secondaryText?: string;
    allowRefresh?: boolean;
}

export const ErrorView = ({ primaryText, secondaryText, allowRefresh }: ErrorViewProps): React.ReactElement | null => {
    const [isPwa, setIsPwa] = useState(false);

    useEffect(() => {
        setIsPwa(isInStandaloneMode());
    }, []);

    return (
        <AlertViewContainer position="center" visible={true}>
            <AlertViewFrame backgroundColor={Colors.backgrounds.panel}>
                <PrimaryText>
                    <SelectableText>{primaryText}</SelectableText>
                </PrimaryText>
                {secondaryText && (
                    <>
                        <VerticalSpace height={10} />
                        <SecondaryText>
                            <SelectableText>{secondaryText}</SelectableText>
                        </SecondaryText>
                    </>
                )}
                {(allowRefresh || isPwa) && (
                    <>
                        <VerticalSpace height={10} />
                        <Button label={t("general.refresh")} onClick={() => window.location.reload()} />
                    </>
                )}
            </AlertViewFrame>
        </AlertViewContainer>
    );
};
