import * as Rx from "rxjs";
import { SerialDisposable } from "./SerialDisposable";

export class RepeatableCallSubscription {
    private counterSubscription = new SerialDisposable();
    private apiCallSubscription = new SerialDisposable();

    public dispose(): void {
        this.counterSubscription.dispose();
        this.apiCallSubscription.dispose();
    }

    public set counter(subscription: Rx.Subscription) {
        this.counterSubscription.disposable = subscription;
    }

    public set apiCall(subscription: Rx.Subscription) {
        this.apiCallSubscription.disposable = subscription;
    }
}
