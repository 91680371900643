import { LocalPreferencesRepository } from "./repositories";
import { generateUUID } from "../utils/UUID";
import { LocalUserPreferenceKeys } from "./model";

export interface ClientDataProvider {
    getDeviceId(): string;
    getClientId(): string;
}

export class SessionBasedClientDataProvider implements ClientDataProvider {
    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {}

    // Functions

    public getClientId(): string {
        let clientId = sessionStorage.getItem("client-id");
        if (!clientId) {
            clientId = Math.floor(100000 * Math.random()).toString();
            sessionStorage.setItem("client-id", clientId);
        }
        return clientId;
    }

    public getDeviceId(): string {
        let deviceId = this.localPreferencesRepository.getPreference<string>(LocalUserPreferenceKeys.user.deviceId);
        if (!deviceId) {
            deviceId = generateUUID();
            this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.deviceId, deviceId);
        }
        return deviceId;
    }
}
