import { BaseViewModel } from "../../../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Radar } from "../../../../domain/model";
import { RadarRepository } from "../../../../domain/repositories";

export class RadarNameViewModel extends BaseViewModel {
    // Properties

    public get hasMultipleRadars(): Rx.Observable<boolean> {
        return this.radarRepository.radars.pipe(RxOperators.map((radars) => radars.length > 1));
    }

    public get selectedRadar(): Rx.Observable<Radar | undefined> {
        return Rx.combineLatest([this.radarRepository.radars, this.radarRepository.selectedRadarId]).pipe(
            RxOperators.map(([radars, selectedRadarId]) => {
                this.radars = radars;
                this.selectedRadarIndex = radars.findIndex((radar) => radar.id === selectedRadarId);
                if (this.selectedRadarIndex < 0) {
                    return undefined;
                }
                this.isFirstRadarSubject.next(this.selectedRadarIndex < 1);
                this.isLastRadarSubject.next(this.selectedRadarIndex >= radars.length - 1);
                return radars[this.selectedRadarIndex];
            }),
        );
    }

    public get isFirstRadar(): Rx.Observable<boolean> {
        return this.isFirstRadarSubject.asObservable();
    }

    public get isLastRadar(): Rx.Observable<boolean> {
        return this.isLastRadarSubject.asObservable();
    }

    private radars: Radar[] = [];
    private selectedRadarIndex = -1;
    private isFirstRadarSubject = new Rx.BehaviorSubject(false);
    private isLastRadarSubject = new Rx.BehaviorSubject(false);

    constructor(private readonly radarRepository: RadarRepository) {
        super();
    }

    // Public functions

    public goToPreviousRadar(): void {
        if (this.isFirstRadarSubject.value) {
            return;
        }
        const previousRadar = this.radars[(this.selectedRadarIndex - 1 + this.radars.length) % this.radars.length];
        this.radarRepository.selectRadar(previousRadar.id);
    }

    public goToNextRadar(): void {
        if (this.isLastRadarSubject.value) {
            return;
        }
        const nextRadar = this.radars[(this.selectedRadarIndex + 1) % this.radars.length];
        this.radarRepository.selectRadar(nextRadar.id);
    }

    public saveRadarName(radarId: number, value: string): Rx.Observable<void> {
        return this.radarRepository.setRadarName(radarId, value);
    }
}
