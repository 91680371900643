import { hexColorWithAlpha } from "../../../utils/ColorUtils";
import { Colors } from "../../appearance/Colors";

const HEADING_RADIUS = 40;
const ANIMATION_PULSE_WIDTH = 20;
const ANIMATION_DURATION_MILLIS = 800;

export class UserHeadingImage {
    // Properties
    public width = HEADING_RADIUS * 2;
    public height = HEADING_RADIUS * 2;
    public data: Uint8Array | Uint8ClampedArray = new Uint8ClampedArray(this.width * this.height * 4);

    private context: CanvasRenderingContext2D | null = null;
    private cx = this.width / 2;
    private cy = this.height / 2;
    private startAngle = this.fillCircle ? 0 : Math.PI * (5 / 4);
    private endAngle = this.fillCircle ? Math.PI * 2 : Math.PI * (7 / 4);

    public constructor(private map: mapboxgl.Map, private fillCircle?: boolean) {}

    // Public functions

    public onAdd(): void {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
    }

    // Called once before every frame where the icon will be used
    public render(): boolean {
        const ctx = this.context;
        if (ctx == null) {
            return false;
        }

        const t = (performance.now() % ANIMATION_DURATION_MILLIS) / ANIMATION_DURATION_MILLIS;

        const r = HEADING_RADIUS * t;

        // Clear previous render
        ctx.clearRect(0, 0, this.width, this.height);

        // Draw heading with constant translucent background
        this.fillSector(ctx, 0, HEADING_RADIUS, hexColorWithAlpha(Colors.secondary.white, 0.1));

        // Draw heading with animated gradient
        const gradient = ctx.createRadialGradient(this.cx, this.cy, 0, this.cx, this.cy, r);
        gradient.addColorStop(0, hexColorWithAlpha(Colors.secondary.white, 1 - t));
        gradient.addColorStop(1, hexColorWithAlpha(Colors.secondary.white, 0));
        this.fillSector(ctx, Math.max(0, r - ANIMATION_PULSE_WIDTH), r, gradient);

        this.data = ctx.getImageData(0, 0, this.width, this.height).data;

        // For a smooth animation
        this.map.triggerRepaint();

        // Return `true` to let the map know that the image was updated
        return true;
    }

    private fillSector(
        ctx: CanvasRenderingContext2D,
        innerRadius: number,
        outerRadius: number,
        fillStyle: string | CanvasGradient | CanvasPattern,
    ): void {
        ctx.beginPath();
        ctx.arc(this.cx, this.cy, outerRadius, this.startAngle, this.endAngle);
        ctx.arc(this.cx, this.cy, innerRadius, this.endAngle, this.startAngle, true);
        ctx.fillStyle = fillStyle;
        ctx.fill();
    }
}
