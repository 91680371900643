import React from "react";

export class SettingItemIcon {
    // Static functions

    public static withUrl(url: string): SettingItemIcon {
        return new SettingItemIcon(url, null);
    }

    public static withIconComponent(icon: React.ReactElement): SettingItemIcon {
        return new SettingItemIcon(null, icon);
    }

    private constructor(private readonly url: string | null, private readonly icon: React.ReactElement | null) {}

    public render(alt: string): React.ReactNode {
        if (this.url) {
            return <img src={this.url} alt={alt} />;
        }

        if (this.icon) {
            return this.icon;
        }
    }
}
