import React from "react";
import styled, { useTheme } from "styled-components";
import { BASE_TEXT_STYLE } from "../../../appearance/theme/GlobalStyles";
import { INPUT_HEIGHT } from "../../../appearance/theme/Spacing";
import { Icon } from "../../../appearance/Icon";
import { t } from "i18next";
import { Button } from "../../../appearance/button/Button";
import { AlarmData } from "../../../../domain/model";
import { generateAlarmMessage } from "../../../../utils/MessageUtils";
import { CheckBox } from "../../../appearance/CheckBox";
import { InteractionMode } from "./AlarmHistory";

const ActionButtons = styled.div`
    width: 0;
    opacity: 0;
    transition: opacity ${({ theme }) => theme.transitionDuration} ease-out;
    overflow: hidden;
`;

const EventTime = styled.span`
    ${BASE_TEXT_STYLE};
    padding-left: ${({ theme }) => theme.spacing.x2};
    transition: color ${({ theme }) => theme.transitionDuration} ease-out;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x2};
    padding-right: ${({ theme }) => theme.spacing.x2};
    min-height: ${INPUT_HEIGHT};
`;

const InteractiveRow = styled(Row)<{ isActive: boolean }>`
    ${({ isActive }) => isActive && "padding-right: 0;"}
    background-color: ${({ isActive, theme }) => (isActive ? theme.colors.secondary.blue : "transparent")};
    transition: background-color ${({ theme }) => theme.transitionDuration} ease-out;

    ${EventTime} {
        color: ${({ isActive, theme }) => (isActive ? theme.colors.text.text : theme.colors.text.text200)};
    }

    :hover {
        padding-right: 0;
        background-color: ${({ isActive, theme }) =>
            isActive ? theme.colors.secondary.blue : theme.colors.interactive.textbuttonHover};

        ${ActionButtons} {
            width: auto;
            opacity: 1;
        }

        svg {
            fill: ${({ theme }) => theme.colors.text.text} !important;
        }

        ${EventTime} {
            color: ${({ theme }) => theme.colors.text.text};
        }
    }
`;

const EventLabel = styled.span`
    ${BASE_TEXT_STYLE};
    padding-left: ${({ theme }) => theme.spacing.x2};
    color: ${({ theme }) => theme.colors.text.text};
    text-transform: uppercase;
    font-weight: 500;
`;

const EventDetails = styled.div`
    ${BASE_TEXT_STYLE};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x3};
    min-height: ${INPUT_HEIGHT};
`;

interface ActiveEventProps {
    eventData: AlarmData;
}

export const ActiveEvent = ({ eventData }: ActiveEventProps): JSX.Element => {
    return (
        <Row>
            <EventDetails>
                <Icon color="danger" iconType="CLASSIFICATION_DRONE" />
                {generateAlarmMessage(eventData)}
            </EventDetails>
        </Row>
    );
};

interface HistoricEventProps {
    checked: boolean;
    eventData: AlarmData;
    interactionMode: InteractionMode;
    isReplaying: boolean;
    onCheckedChange: (checked: boolean) => void;
    onDelete: () => void;
    onRequestReplay: () => void;
    startTime: string;
}

export const HistoricEvent = ({
    checked,
    eventData,
    interactionMode,
    isReplaying,
    onCheckedChange,
    onDelete,
    onRequestReplay,
    startTime,
}: HistoricEventProps): JSX.Element => {
    const theme = useTheme();
    return (
        <InteractiveRow isActive={isReplaying}>
            {interactionMode === InteractionMode.SELECTION && (
                <CheckBox
                    color={theme.colors.status.danger}
                    value={checked}
                    onChange={(isOn) => onCheckedChange(isOn)}
                />
            )}
            {isReplaying ? (
                <EventLabel>{generateAlarmMessage(eventData)}</EventLabel>
            ) : (
                <Button
                    color="default"
                    disabled={interactionMode === InteractionMode.SELECTION}
                    label={generateAlarmMessage(eventData)}
                    layout="inline"
                    onClick={onRequestReplay}
                    tooltip={t("eventList.replayThisEvent")}
                />
            )}
            <EventTime>{startTime}</EventTime>
            {interactionMode === InteractionMode.NORMAL && (
                <ActionButtons>
                    {!isReplaying && (
                        <Button iconType="CROSS" layout="inline" onClick={onDelete} tooltip={t("general.delete")} />
                    )}
                </ActionButtons>
            )}
        </InteractiveRow>
    );
};
