import React from "react";
import { encodeSvg } from "../../utils/MapUtils";
import { Colors, OldColors } from "./Colors";

export enum ArrowDirection {
    UP,
    DOWN,
    LEFT,
    RIGHT,
}

function arrowTransform(direction: ArrowDirection): string | undefined {
    switch (direction) {
        case ArrowDirection.UP:
            return "matrix(-1, 0, 0, -1, 16, 16.6)";
        case ArrowDirection.DOWN:
            return undefined;
        case ArrowDirection.LEFT:
            return "matrix(0, 1, -1, 0, 16.300001, 0.300001)";
        case ArrowDirection.RIGHT:
            return "matrix(0, -1, 1, 0, -0.3, 16.3)";
    }
}

interface ArrowIconProps {
    color: string;
    direction: ArrowDirection;
    alt?: string;
}

export function generateArrowIcon(props: ArrowIconProps): React.ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M 12.6 4.6 L 8 9.2 L 3.4 4.6 L 2 6 L 8 12 L 14 6 L 12.6 4.6 Z"
                transform={arrowTransform(props.direction)}
            />
        </svg>
    );
}

export function generateFatArrowIcon(props: ArrowIconProps): React.ReactElement {
    return (
        <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5108 4.74722C22.1631 4.09494 22.1631 3.03739 21.5108 2.38511L20.3297 1.20405C19.6775 0.551774 18.6199 0.551775 17.9676 1.20405L12.1811 6.99062C11.5288 7.6429 10.4712 7.6429 9.81894 6.99062L4.03237 1.20405C3.38009 0.551774 2.32254 0.551774 1.67026 1.20405L0.489209 2.38511C-0.16307 3.03739 -0.163069 4.09494 0.489209 4.74722L9.81894 14.077C10.4712 14.7292 11.5288 14.7292 12.1811 14.077L21.5108 4.74722Z"
                fill={props.color}
                transform={arrowTransform(props.direction)}
            />
        </svg>
    );
}

export function generateArrowIconSource(props: ArrowIconProps, isFat?: boolean): string {
    return encodeSvg(isFat ? generateFatArrowIcon(props) : generateArrowIcon(props));
}

export const ArrowDownIcon = generateArrowIconSource({ color: Colors.text.text, direction: ArrowDirection.DOWN });

export const ArrowLeftIcon = generateArrowIconSource({ color: Colors.secondary.blue, direction: ArrowDirection.LEFT });

export const ArrowRightIcon = generateArrowIconSource({
    color: Colors.secondary.blue,
    direction: ArrowDirection.RIGHT,
});

export const ArrowRightIconGrey = generateArrowIconSource({ color: OldColors.grey, direction: ArrowDirection.RIGHT });

export const Arrow = (props: ArrowIconProps): React.ReactElement => (
    <img src={generateArrowIconSource(props)} alt={props.alt || ""} />
);
