import { DeterrenceDeviceUpdate as DeterrenceDeviceUpdateProto } from "./proto/generated/deterrencedevices3_pb";

export class DeterrenceDeviceUpdate {
    // Static functions

    public static fromProto(model: DeterrenceDeviceUpdateProto): DeterrenceDeviceUpdate {
        return new DeterrenceDeviceUpdate(
            model.getId(),
            model.getActive(),
            model.getConnected(),
            model.getUsedToday(),
            model.getRemaining(),
            model.getStatuscode(),
            model.getStatusmessage(),
        );
    }

    public constructor(
        public readonly id: int,
        public readonly isActive: boolean,
        public readonly connected: boolean,
        public readonly usedToday: int,
        public readonly remaining: int,
        public readonly statusCode: int,
        public readonly statusMessage: string,
    ) {}
}
