import React from "react";
import { TYPES } from "../../../di/Types";
import { ToolbarIconButton } from "../../appearance/ToolbarIconButton";
import { useViewModel } from "../../../hooks/useViewModel";
import { RunwayTrafficButtonViewModel } from "./RunwayTrafficButtonViewModel";
import { useObservable } from "../../../hooks";

export const RunwayTrafficButton = (): JSX.Element | null => {
    // Properties

    const viewModel: RunwayTrafficButtonViewModel = useViewModel(TYPES.RunwayTrafficButtonViewModel);
    const hasRunways = useObservable(viewModel.hasRunways, false);
    const isRunwayTrafficBottomSheetVisible = useObservable<boolean>(
        viewModel.getIsUIComponentVisible("isRunwayTrafficBottomSheetVisible"),
        false,
    );

    // Render

    if (!hasRunways) {
        return null;
    }

    return (
        <ToolbarIconButton
            iconType="LINE_GRAPH"
            active={isRunwayTrafficBottomSheetVisible}
            onClick={() => viewModel.toggleRunwayTrafficBottomSheet(!isRunwayTrafficBottomSheetVisible)}
        />
    );
};
