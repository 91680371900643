import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import React from "react";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { AlarmViewModel } from "./AlarmViewModel";
import AlarmSound from "./../../res/sounds/alarm.mp3";
import { AlertViewContainer } from "../appearance/AlertView";
import MouseTrap from "mousetrap";
import Shortcuts from "../Shortcuts";
import { REPLAY_CONTROLS_HEIGHT } from "../replay/controls/ReplayControls";
import { AlarmData, AlarmDataType } from "../../domain/model";
import _isEqual from "lodash/isEqual";
import AlarmRow from "./AlarmRow";
import { generateAlarmMessage } from "../../utils/MessageUtils";

interface Props {
    position: "top" | "bottom";
}

interface State {
    alarms: AlarmData[];
    isTestingAlarm: boolean;
    isReplay: boolean;
    enabledAlarmTypes: AlarmDataType[];
    soundEnabledAlarmTypes: AlarmDataType[];
}

export class AlarmView extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: AlarmViewModel = DI.get(TYPES.AlarmViewModel);
    private soundToggleShortcut: string;

    public constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            alarms: [],
            isTestingAlarm: false,
            isReplay: false,
            enabledAlarmTypes: [],
            soundEnabledAlarmTypes: [],
        };

        this.soundToggleShortcut = Shortcuts.getBinding(Shortcuts.actions.TOGGLE_ALARM_SOUND)!;
    }

    // Public functions

    public componentDidMount(): void {
        this.subscribeToIsAlarming();

        MouseTrap.bind(this.soundToggleShortcut, () => this.viewModel.toggleSound());
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();

        this.viewModel.onMouseOutAlertBox();
        MouseTrap.unbind(this.soundToggleShortcut);
    }

    public render(): React.ReactNode {
        const enabledAlarms = this.filterAlarmsToShow(this.state.alarms);
        const isAlarming = enabledAlarms.length > 0;
        const playSound = this.state.alarms.some((a) => this.state.soundEnabledAlarmTypes.includes(a.type));
        //                              ^ should be `enabledAlarms` instead of `alarms`?

        return (
            <>
                <AlertViewContainer
                    visible={isAlarming}
                    position={this.props.position}
                    bottomInset={this.state.isReplay ? REPLAY_CONTROLS_HEIGHT : undefined}
                >
                    {enabledAlarms.map((alarmData, index) => (
                        <AlarmRow
                            viewModel={this.viewModel}
                            alarmType={alarmData.type}
                            message={generateAlarmMessage(alarmData)}
                            key={`${alarmData.type}-${index}`}
                        />
                    ))}
                </AlertViewContainer>
                {playSound || this.state.isTestingAlarm ? <audio src={AlarmSound} loop={true} autoPlay={true} /> : null}
            </>
        );
    }

    // Private functions

    private subscribeToIsAlarming(): void {
        this.collectSubscriptions(
            this.viewModel.alarms.subscribe((alarms) => this.setState({ alarms })),
            this.viewModel.isTestingAlarm.subscribe((isTestingAlarm) => this.setState({ isTestingAlarm })),
            this.viewModel.isReplay.subscribe((isReplay) => this.setState({ isReplay })),
            this.viewModel.enabledAlarmTypes.subscribe((enabledAlarmTypes) => this.setState({ enabledAlarmTypes })),
            this.viewModel.soundEnabledForAlarmType.subscribe((soundEnabledAlarmTypes) =>
                this.setState({ soundEnabledAlarmTypes }),
            ),
        );
    }

    private filterAlarmsToShow(allAlarms: AlarmData[]): AlarmData[] {
        const output: AlarmData[] = [];
        if (this.state.enabledAlarmTypes.length === 0) {
            return output;
        }
        const enabledAlarms = allAlarms.filter((alarm) => this.state.enabledAlarmTypes.includes(alarm.type));
        enabledAlarms.forEach((alarm) => {
            switch (alarm.type) {
                case AlarmDataType.DRONE:
                    // If more than one drone is detected, only show one popup
                    if (output.some((a) => a.type == alarm.type)) {
                        return;
                    }

                    output.push(alarm);
                    break;
                case AlarmDataType.AREA_ENTRY:
                    // Don't show duplicate alarms with same overlays. sometimes multiple tracks enter the same area
                    // at the same time and it can produce duplicate alarm views
                    if (
                        output.some(
                            (a) => a.type == alarm.type && _isEqual(a.overlayIds.sort(), alarm.overlayIds.sort()),
                        )
                    ) {
                        return;
                    }

                    output.push(alarm);
                    break;
                case AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD:
                case AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD:
                    output.push(alarm);
                    break;
            }
        });
        return output;
    }
}
