import styled from "styled-components";
import { RoundInput } from "./RoundInput";
import { OldColors } from "./Colors";

/**
 * @deprecated Use `forms/InputField` instead.
 */
export const FormRoundInput = styled(RoundInput).attrs({
    borderRadius: 6,
    alwaysShowBorder: true,
    borderColor: OldColors.primaryTint + "BA",
})`
    width: 100%;
`;
