import * as Rx from "rxjs";
import { GeolocationPosition } from "../model";
import { BaseRepository } from ".";

export enum UserLocationState {
    INACTIVE,
    TRACKING,
    BACKGROUND,
}

export interface UserLocationRepository extends BaseRepository {
    readonly userLocationPosition: Rx.Observable<GeolocationPosition | undefined>;
    readonly userLocationState: Rx.Observable<UserLocationState>;
    readonly userAltitude: Rx.Observable<number | null>;

    setUserLocationPosition(position: GeolocationPosition | undefined): void;
    setUserLocationState(state: UserLocationState): void;
}
