import * as Rx from "rxjs";
import { VersionRepository } from "../../../../domain/repositories/VersionRepository";
import { VersionInfo } from "../../../../domain/model";
import { ServerConfigRepository } from "../../../../domain/repositories";

export class BirdViewerVersionRepository implements VersionRepository {
    public constructor(private readonly serverConfigRepository: ServerConfigRepository) {}

    // Public functions

    public getVersionInfo(): Rx.Observable<VersionInfo> {
        return this.serverConfigRepository.serverVersions.pipe(
            Rx.map((serverVersions) => new VersionInfo(serverVersions.radarSoftwareVersion)),
        );
    }
}
