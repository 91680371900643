import styled from "styled-components";
import CloseImage from "../../res/images/close_accent.svg";

/**
 * @deprecated Use `Button` instead
 */
export const CloseButton = styled.img.attrs({ src: CloseImage })`
    position: absolute;
    right: 0px;
    cursor: pointer;
    :hover {
        opacity: 0.6;
    }
`;

/**
 * @deprecated Use `Button` instead
 */
export const CloseButtonWithMargin = styled(CloseButton)`
    right: 20px;
`;
