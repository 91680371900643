import React from "react";
import { encodeSvg } from "../../../utils/MapUtils";

export function generateObservationSymbol(props: { color: string }): string {
    const svg: React.ReactElement = (
        <svg xmlns={"http://www.w3.org/2000/svg"} width={49} height={49} viewBox={"0 0 49 49"}>
            <g fill={"none"} stroke={props.color} strokeWidth={"4"} strokeDasharray={"12"}>
                <circle cx={"24.5"} cy={"24.5"} r={"24.5"} stroke={"none"} />
                <circle cx={"24.5"} cy={"24.5"} r={"22.5"} fill={"none"} />
            </g>
        </svg>
    );
    return encodeSvg(svg);
}

export function generateObservedTrackSymbol(props: { color: string }): string {
    const svg: React.ReactElement = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
            <g fill="none" fillRule="evenodd">
                <g fill={props.color}>
                    <g>
                        <g>
                            <path
                                d="M8 0C4.364 0 1.236 2.255 0 5.455c1.236 3.2 4.364 5.454 8 5.454s6.764-2.254 8-5.454C14.764 2.255 11.636 0 8 0zm0 9.09c-2.036 0-3.636-1.6-3.636-3.635 0-2.037 1.6-3.637 3.636-3.637s3.636 1.6 3.636 3.637c0 2.036-1.6 3.636-3.636 3.636zm0-5.817c-1.236 0-2.182.945-2.182 2.182 0 1.236.946 2.181 2.182 2.181s2.182-.945 2.182-2.181c0-1.237-.946-2.182-2.182-2.182z"
                                transform="translate(-686 -401) translate(686 401) translate(0 .545)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
    return encodeSvg(svg);
}
