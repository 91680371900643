import { SettingsSectionsProvider } from "../../../../components/settings/SettingsSectionsProvider";
import { SettingsItemSection } from "../../../../components/settings/SettingsItemSection";
import * as Rx from "rxjs";
import { BirdViewerFilterSettingsSection } from "./BirdViewerFilterSettingsSection";
import {
    LocalPreferencesRepository,
    MapRepository,
    SessionRepository,
    ServerPreferencesRepository,
    AlarmRepository,
    RunwayTrafficRepository,
    LocationInfoRepository,
    RadarRepository,
    UserLocationRepository,
    ServerConfigRepository,
} from "../../../../domain/repositories";
import { CommonClassificationSettingsSection } from "../../../../components/settings/sections/CommonClassificationSettingsSection";
import { CommonMapSettingsSection } from "../../../../components/settings/sections/CommonMapSettingsSection";
import { CommonSessionManagementSection } from "../../../../components/settings/sections/CommonSessionManagementSection";
import { ChartsSettingsSection } from "./ChartsSettingsSection";
import { CommonUserManagementSection } from "../../../../components/settings/sections/CommonUserManagementSection";
import { CommonInterfaceSettingsSection } from "../../../../components/settings/sections/CommonInterfaceSettingsSection";
import { BirdViewerAlarmSettingsSection } from "./BirdViewerAlarmSettingsSection";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";
import { CommonRangeRingsSettingsSection } from "../../../../components/settings/sections/CommonRangeRingsSettingsSection";

export class BirdViewerSettingsSectionsProvider implements SettingsSectionsProvider {
    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly serverPreferencesRepository: ServerPreferencesRepository,
        private readonly mapRepository: MapRepository,
        private readonly sessionRepository: SessionRepository,
        private readonly alarmRepository: AlarmRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly userLocationRepository: UserLocationRepository,
        private readonly radarRepository: RadarRepository,
        private readonly flavorConfig: FlavorConfig,
        private readonly serverConfigRepository: ServerConfigRepository,
    ) {}

    // Public functions

    public provide(): Rx.Observable<SettingsItemSection[]> {
        const sections = [
            new BirdViewerFilterSettingsSection(
                this.localPreferencesRepository,
                this.locationInfoRepository,
                this.radarRepository,
                this.userLocationRepository,
                this.serverPreferencesRepository,
            ),
            new CommonMapSettingsSection(
                this.localPreferencesRepository,
                this.mapRepository,
                this.locationInfoRepository,
                this.radarRepository,
                this.flavorConfig,
                this.serverConfigRepository,
            ),
            new CommonClassificationSettingsSection(),
            new ChartsSettingsSection(
                this.localPreferencesRepository,
                this.runwayTrafficRepository,
                this.locationInfoRepository,
            ),
            new CommonInterfaceSettingsSection(this.localPreferencesRepository),
            new CommonUserManagementSection(),
            new BirdViewerAlarmSettingsSection(
                this.localPreferencesRepository,
                this.alarmRepository,
                this.runwayTrafficRepository,
                this.locationInfoRepository,
            ),
            new CommonRangeRingsSettingsSection(this.localPreferencesRepository, this.radarRepository),
            new CommonSessionManagementSection(this.sessionRepository),
        ];
        return Rx.of(sections);
    }
}
