import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { SettingItemIcon } from "../../SettingItemIcon";
import { combinedBooleanObservable, nonNullObservable } from "../../../../utils/RxUtils";
import { ReactComponent as AlertIconComponent } from "../../../../res/images/settings/sound.svg";
import { default as AlarmIcon } from "../../../../res/images/settings/alarm.svg";
import { ReactComponent as EyeIcon } from "../../../../res/images/icons/eye.svg";
import { IconSwitchItemViewModel, IconSwitchActionProps } from "../../SettingItemViewModel";
import { t } from "i18next";

export interface ModifiedIconSwitchActionProps extends IconSwitchActionProps {
    key: string;
}

export class AlarmToggleViewModel extends IconSwitchItemViewModel {
    public constructor(
        localPreferencesRepository: LocalPreferencesRepository,
        settingsTranslationKey: string,
        enableKey: string,
        soundKey: string,
    ) {
        const actions = [
            {
                key: enableKey,
                onChange: (value: boolean) => localPreferencesRepository.setPreference(enableKey, value),
                icon: EyeIcon,
                observableValue: nonNullObservable(localPreferencesRepository.observePreference<boolean>(enableKey)),
            },
            {
                key: soundKey,
                onChange: (value: boolean) => localPreferencesRepository.setPreference(soundKey, value),
                icon: AlertIconComponent,
                observableValue: nonNullObservable(localPreferencesRepository.observePreference<boolean>(soundKey)),
            },
        ];
        super(t(`settings.${settingsTranslationKey}`), SettingItemIcon.withUrl(AlarmIcon), actions, [], undefined);
    }
}

export class MultipleAlarmToggleViewModel extends IconSwitchItemViewModel {
    public constructor(
        localPreferencesRepository: LocalPreferencesRepository,
        settingsTranslationKey: string,
        alarmsEnabledKeys: string[],
        alarmSoundEnabledKeys: string[],
    ) {
        const actions: IconSwitchActionProps[] = [
            {
                key: "alarmsEnabled",
                onChange: (value) => localPreferencesRepository.setPreferences(alarmsEnabledKeys, value),
                icon: EyeIcon,
                observableValue: combinedBooleanObservable(
                    localPreferencesRepository.observePreferences<boolean>(alarmsEnabledKeys),
                ),
            },
            {
                key: "alarmsSoundEnabled",
                onChange: (value) => localPreferencesRepository.setPreferences(alarmSoundEnabledKeys, value),
                icon: AlertIconComponent,
                observableValue: combinedBooleanObservable(
                    localPreferencesRepository.observePreferences<boolean>(alarmSoundEnabledKeys),
                ),
            },
        ];

        super(t(`settings.${settingsTranslationKey}`), SettingItemIcon.withUrl(AlarmIcon), actions, [], undefined);
    }
}
