import { DeterrenceDeviceState, Location } from ".";
import {
    DeterrenceDevice as DeterrenceDeviceProto,
    DeterrenceDeviceType as DeterrenceDeviceTypeProto,
} from "./proto/generated/deterrencedevices3_pb";

export enum DeterrenceDeviceType {
    ACOUSTIC_PANIC_YELL,
    ACOUSTIC_AIR_CANNON,
    FLASHLIGHT,
    PISTOL_CARTRIDGE,
    CAR,
    LASER,
    CAR_HORN,
    NO_DETERRENCE,
    UNRECOGNIZED,
}

export class DeterrenceDevice {
    // Static functions

    public static deviceTypeFrom(model: DeterrenceDeviceTypeProto): DeterrenceDeviceType {
        switch (model) {
            case DeterrenceDeviceTypeProto.ACOUSTIC_PANIC_YELL:
                return DeterrenceDeviceType.ACOUSTIC_PANIC_YELL;
            case DeterrenceDeviceTypeProto.ACOUSTIC_AIR_CANNON:
                return DeterrenceDeviceType.ACOUSTIC_AIR_CANNON;
            case DeterrenceDeviceTypeProto.FLASHLIGHT:
                return DeterrenceDeviceType.FLASHLIGHT;
            case DeterrenceDeviceTypeProto.PISTOL_CARTRIDGE:
                return DeterrenceDeviceType.PISTOL_CARTRIDGE;
            case DeterrenceDeviceTypeProto.CAR:
                return DeterrenceDeviceType.CAR;
            case DeterrenceDeviceTypeProto.LASER:
                return DeterrenceDeviceType.LASER;
            case DeterrenceDeviceTypeProto.CAR_HORN:
                return DeterrenceDeviceType.CAR_HORN;
            case DeterrenceDeviceTypeProto.NO_DETERRENCE:
                return DeterrenceDeviceType.NO_DETERRENCE;
            default:
                return DeterrenceDeviceType.UNRECOGNIZED;
        }
    }

    public static fromProto(model: DeterrenceDeviceProto): DeterrenceDevice {
        return new DeterrenceDevice(
            model.getBearing(),
            model.getId(),
            model.getName(),
            model.getPrettyname() || model.getName(),
            Location.fromProto(model.getPosition()),
            DeterrenceDevice.deviceTypeFrom(model.getDevicetype()),
            model.getRange(),
            model.getActive(),
            model.getRemaining(),
            model.getUsedToday(),
            model.getConnected(),
            model.getStatuscode(),
        );
    }

    public constructor(
        public readonly bearing: double,
        public readonly id: int,
        public readonly name: string,
        public readonly prettyName: string,
        public readonly position: Location,
        public readonly deviceType: DeterrenceDeviceType,
        public readonly range: double,
        public readonly active: boolean,
        public readonly remaining: int,
        public readonly used: int,
        public readonly connected: boolean,
        public readonly statusCode: int,
    ) {}
}

export function stateForDevice(device: DeterrenceDevice, selectedDeviceIds: int[]): DeterrenceDeviceState {
    if (!device.connected) {
        return DeterrenceDeviceState.Disabled;
    }
    if (device.remaining === 0 || device.statusCode >= 400) {
        return DeterrenceDeviceState.Error;
    }
    if (device.active && device.statusCode < 400) {
        return device.statusCode === 301 ? DeterrenceDeviceState.Pending : DeterrenceDeviceState.Activated;
    }
    if (selectedDeviceIds.includes(device.id)) {
        return DeterrenceDeviceState.Selected;
    }
    return DeterrenceDeviceState.Available;
}
