import React from "react";
import { RadarOperatingModeStatus } from "../../../../domain/model";
import { t } from "i18next";
import { ComponentStatus } from "./ComponentStatus";
import { useTheme } from "styled-components";

interface Props {
    status: RadarOperatingModeStatus;
}

export const OperatingModeStatus = ({ status }: Props): JSX.Element => {
    const theme = useTheme();
    // Render

    return (
        <ComponentStatus
            color={status.ready ? theme.colors.status.success : theme.colors.status.danger}
            hasMessage={false}
            label={t("radarStatus.operatingModeStatus")}
            tooltip={status.ready ? t("radarStatus.stateReady") : status.errorDetail ?? ""}
        />
    );
};
