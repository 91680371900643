import React from "react";
import styled, { withTheme } from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import Texts from "../appearance/Texts";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { FunnelViewViewModel } from "./FunnelViewViewModel";
import { getRunwayDirectionLabels, Runway } from "../../domain/model";
import { fillRoundRect } from "../../utils/CanvasUtils";
import { FunnelViewSectorRates } from "./FunnelViewSectorRates";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { ResizeObserver } from "@juggle/resize-observer";
import { CanvasContainer } from "../appearance/CanvasContainer";
import { t } from "i18next";
import { setContextColorBasedOnSectorRate } from "./FunnelViewUtils";
import { Theme } from "../appearance/theme/Theme";

const Root = styled.div<{ hasOnClick: boolean }>`
    position: relative;
    background: ${({ theme }) => theme.colors.backgrounds.panel};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 36px 12px 36px;
    text-align: center;
    cursor: pointer;
    ${({ hasOnClick }) => hasOnClick && `cursor: pointer;`}
`;

interface Props {
    runway: Runway;
    onClick?: () => void;
    theme: Theme;
}

interface State {
    threshold: number;
    rates: FunnelViewSectorRates;
    leftDirectionLabel: string;
    rightDirectionLabel: string;
    showParkingCorridor: boolean;
}

class FunnelViewComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: FunnelViewViewModel = DI.get(TYPES.FunnelViewViewModel);
    private canvasElement = React.createRef<HTMLCanvasElement>();
    private canvasContext: CanvasRenderingContext2D | null = null;
    private resizeSubject = new Rx.Subject<null>();

    // Vertical ratios. All ratios are in comparison with the view height
    private barsHeightRatio = 0.2666; // 16/60
    private edgesSpaceRatio = 0.05; // 3/60
    private bottomBarsSpaceRatio = 0.033; // 2/60
    private topAndBottomBarSpaceRatio = 0.333; // 20/60

    private cornerRadius = 4; // 4px
    private canvasResizeObserver?: ResizeObserver;

    // Lifecycle

    public constructor(props: Readonly<Props>) {
        super(props);

        const runwayDirectionLabels = getRunwayDirectionLabels(props.runway);
        this.state = {
            threshold: 0,
            rates: { top: 0, bottomLeft: 0, bottomMiddleLeft: 0, bottomMiddleRight: 0, bottomRight: 0 },
            leftDirectionLabel: runwayDirectionLabels.left,
            rightDirectionLabel: runwayDirectionLabels.right,
            showParkingCorridor: false,
        };
    }

    public componentDidMount(): void {
        // Setup the canvas
        const canvas = this.canvasElement.current!;
        this.resizeCanvas();
        this.canvasResizeObserver = new ResizeObserver(() => this.resizeSubject.next(null));
        this.canvasResizeObserver.observe(canvas);
        this.canvasContext = canvas.getContext("2d")!;

        // Setup subscriptions
        this.collectSubscriptions(
            this.viewModel.funnelViewThreshold.subscribe((threshold) => this.setThreshold(threshold)),
            this.viewModel.observeRunwayTraffic(this.props.runway.id).subscribe({
                next: (rates) => this.setRates(rates),
                error: (e) => console.error(e),
            }),
            this.viewModel
                .getShowParkingCorridor(this.props.runway.id)
                .subscribe((showParkingCorridor) => this.setState({ showParkingCorridor })),
            this.resizeSubject.pipe(RxOperators.debounceTime(10)).subscribe(() => this.resizeCanvas()),
        );
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.canvasResizeObserver && this.canvasResizeObserver.disconnect();
    }

    public componentDidUpdate(): void {
        this.redrawCanvas();
    }

    public render(): React.ReactNode {
        return (
            <Root onClick={this.props.onClick} hasOnClick={this.props.onClick !== undefined}>
                <Texts.BottomSheetTitles>{t("funnelViewRunwayCrossings.funnelView")}</Texts.BottomSheetTitles>

                <CanvasContainer>
                    <canvas data-testid="funnelview-canvas" ref={this.canvasElement} />
                </CanvasContainer>
            </Root>
        );
    }

    // Private functions

    private setThreshold(threshold: number): void {
        this.setState({ threshold });
    }

    private setRates(rates: FunnelViewSectorRates): void {
        this.setState({ rates });
    }

    private redrawCanvas(): void {
        const ctx = this.canvasContext;
        if (!ctx) {
            return;
        }
        const dpr = window.devicePixelRatio || 1;

        const canvas = ctx.canvas;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.font = `normal ${16 * dpr}px 'Roboto'`;

        const ch = canvas.height;
        const cw = canvas.width;
        // Funnels total height
        const fh = ch - parseInt(ctx.font);
        // Funnels total width
        const fw = cw;
        // Funnel corner radius
        const fcr = this.cornerRadius * dpr;

        const barHeight = fh * this.barsHeightRatio;
        const edgeSpace = fh * this.edgesSpaceRatio;
        const bottomFunnelsSpace = fh * this.bottomBarsSpaceRatio;
        const bottomFunnelsWidth = (fw - 3 * bottomFunnelsSpace) / 4;
        const topBarBottomBarSpace = fh * this.topAndBottomBarSpaceRatio;

        // Clear the canvas
        ctx.clearRect(0, 0, cw, ch);
        ctx.imageSmoothingEnabled = false;

        // Top funnel
        if (this.state.showParkingCorridor) {
            this.setContextColorBasedOnSector(ctx, "top");
            fillRoundRect(ctx, 0, 0, fw, barHeight, fcr);
        }

        const leftSlantBarStartY = barHeight + edgeSpace;
        const leftSlantBarStartX = 0;

        // Left funnel
        let p = new Path2D();
        p.moveTo(leftSlantBarStartX, leftSlantBarStartY);
        p.lineTo(leftSlantBarStartX, leftSlantBarStartY + barHeight - fcr);
        p.quadraticCurveTo(
            leftSlantBarStartX,
            leftSlantBarStartY + barHeight,
            leftSlantBarStartX + fcr,
            leftSlantBarStartY + barHeight,
        );
        p.lineTo(leftSlantBarStartX + bottomFunnelsWidth, leftSlantBarStartY + barHeight + topBarBottomBarSpace);
        p.lineTo(leftSlantBarStartX + bottomFunnelsWidth, leftSlantBarStartY + topBarBottomBarSpace);
        p.lineTo(leftSlantBarStartX + fcr, leftSlantBarStartY);
        p.quadraticCurveTo(leftSlantBarStartX, leftSlantBarStartY, leftSlantBarStartX, leftSlantBarStartY + fcr);
        p.closePath();
        this.setContextColorBasedOnSector(ctx, "bottomLeft");
        ctx.fill(p);

        p = new Path2D();
        // Two bottom center funnels
        const bottomLinesY = leftSlantBarStartY + topBarBottomBarSpace;
        const leftMiddleBottomBarX = leftSlantBarStartX + bottomFunnelsWidth + bottomFunnelsSpace;
        this.setContextColorBasedOnSector(ctx, "bottomMiddleLeft");
        fillRoundRect(ctx, leftMiddleBottomBarX, bottomLinesY, bottomFunnelsWidth, barHeight, fcr);
        const rightMiddleBottomBarX = leftMiddleBottomBarX + bottomFunnelsWidth + bottomFunnelsSpace;
        this.setContextColorBasedOnSector(ctx, "bottomMiddleRight");
        fillRoundRect(ctx, rightMiddleBottomBarX, bottomLinesY, bottomFunnelsWidth, barHeight, fcr);

        // Right funnel
        p = new Path2D();
        const rightSlantBarStartX = rightMiddleBottomBarX + bottomFunnelsWidth + bottomFunnelsSpace;
        const rightSlantBarEndY = barHeight + edgeSpace;
        p.moveTo(rightSlantBarStartX, bottomLinesY);
        p.lineTo(rightSlantBarStartX + bottomFunnelsWidth - fcr, rightSlantBarEndY);
        p.quadraticCurveTo(
            rightSlantBarStartX + bottomFunnelsWidth,
            rightSlantBarEndY,
            rightSlantBarStartX + bottomFunnelsWidth,
            rightSlantBarEndY + fcr,
        );
        p.lineTo(rightSlantBarStartX + bottomFunnelsWidth, rightSlantBarEndY + barHeight - fcr);
        p.quadraticCurveTo(
            rightSlantBarStartX + bottomFunnelsWidth,
            rightSlantBarEndY + barHeight,
            rightSlantBarStartX + bottomFunnelsWidth - fcr,
            rightSlantBarEndY + barHeight,
        );
        p.lineTo(rightSlantBarStartX, bottomLinesY + barHeight);
        p.closePath();
        this.setContextColorBasedOnSector(ctx, "bottomRight");
        ctx.fill(p);

        // Bottom line - light, 1px, full width
        const bottomLineTop = fh - 8 * dpr;
        ctx.fillStyle = this.props.theme.colors.text.text200;
        ctx.fillRect(0, bottomLineTop + 1 * dpr, fw, 1 * dpr);
        // Bottom line - black, 2px, the size of two bottom center funnel sections
        ctx.fillStyle = this.props.theme.colors.secondary.blue;
        ctx.fillRect(
            bottomFunnelsWidth + bottomFunnelsSpace,
            bottomLineTop,
            bottomFunnelsWidth * 2 + bottomFunnelsSpace,
            2 * dpr,
        );

        // Draw runway direction identifier texts
        ctx.fillStyle = this.props.theme.colors.text.text;
        ctx.textBaseline = "bottom";
        ctx.textAlign = "left";
        ctx.fillText(this.state.leftDirectionLabel, 0, ch);
        ctx.textAlign = "right";
        ctx.fillText(this.state.rightDirectionLabel, cw, ch);
        ctx.restore();
    }

    private setContextColorBasedOnSector(ctx: CanvasRenderingContext2D, sector: keyof FunnelViewSectorRates): void {
        setContextColorBasedOnSectorRate(
            ctx,
            this.state.rates[sector],
            this.state.threshold,
            this.props.theme.colors.text.text200,
            this.props.theme.colors.secondary.red,
        );
    }

    private resizeCanvas(): void {
        const canvas = this.canvasElement.current!;
        // Make it visually fill the positioned parent
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        // Get the device pixel ratio, falling back to 1.
        const dpr = window.devicePixelRatio || 1;
        // ...then set the internal size to matchxw
        canvas.width = canvas.offsetWidth * dpr;
        canvas.height = canvas.offsetHeight * dpr;
        this.redrawCanvas();
    }
}

export const FunnelView = withTheme(FunnelViewComponent);
