import { SelectOption } from ".";
import { t } from "i18next";

export enum TrackLabel {
    ALTITUDE = "altitude",
    GROUND_SPEED = "ground_speed",
    NONE = "none",
}

export const convertLabelToOption = (trackLabel: TrackLabel): SelectOption => {
    let titleKey;
    switch (trackLabel) {
        case TrackLabel.ALTITUDE:
            titleKey = "map.altitude";
            break;
        case TrackLabel.GROUND_SPEED:
            titleKey = "map.velocity";
            break;
        case TrackLabel.NONE:
        default:
            titleKey = "general.none";
            break;
    }

    return {
        id: trackLabel,
        title: t(titleKey),
    };
};
