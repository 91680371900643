import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../../BaseViewModel";
import { UIControlOptions, UIControlRepository } from "../../../domain/repositories";
import { UIState } from "../../../domain/model";

export class ToolbarButtonViewModel extends BaseViewModel {
    // Properties

    public getIsUIComponentVisible(key: keyof UIState): Rx.Observable<boolean> {
        return this.uiControlRepository.uiState.pipe(RxOperators.map((uiState) => uiState[key]));
    }

    constructor(protected readonly uiControlRepository: UIControlRepository) {
        super();
    }

    // Public functions

    public toggleUIComponent(key: keyof UIState, options: UIControlOptions): void {
        this.uiControlRepository.toggleUIComponent(key, options);
    }
}
