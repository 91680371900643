import { SettingItemIcon } from "../../../../components/settings/SettingItemIcon";
import { SettingItemViewModel, SwitchItemViewModel } from "../../../../components/settings/SettingItemViewModel";
import { ServerPreferencesRepository } from "../../../../domain/repositories/ServerPreferencesRepository";
import { LocalPreferencesRepository } from "../../../../domain/repositories/LocalPreferencesRepository";
import GridIcon from "../../../../res/images/settings/grid.svg";
import { ServerUserPreferenceKeys } from "../../../../domain/model/PreferencesData";
import { SettingsErrorHandler } from "../../../../components/settings/SettingsErrorHandler";
import * as Rx from "rxjs";
import * as RxUtils from "../../../../utils/RxUtils";
import * as RxOperators from "rxjs/operators";
import { LocationInfoRepository, RadarRepository, UserLocationRepository } from "../../../../domain/repositories";
import { t } from "i18next";

import { CommonFilterSettingsSection } from "../../../../components/settings/sections/CommonFilterSettingsSection";

export class BirdViewerFilterSettingsSection extends CommonFilterSettingsSection {
    // Properties

    public constructor(
        localPreferencesRepository: LocalPreferencesRepository,
        locationInfoRepository: LocationInfoRepository,
        radarRepository: RadarRepository,
        userLocationRepository: UserLocationRepository,
        private readonly serverPreferencesRepository: ServerPreferencesRepository,
    ) {
        super(localPreferencesRepository, locationInfoRepository, radarRepository, userLocationRepository);
    }

    // Public functions

    public generate(errorHandler: SettingsErrorHandler): Rx.Observable<SettingItemViewModel[]> {
        return super.generate(errorHandler).pipe(
            RxOperators.map((settings) => {
                const showHeatmapSwitch = new SwitchItemViewModel(
                    t("settings.showHeatmap"),
                    SettingItemIcon.withUrl(GridIcon),
                    RxUtils.nonNullObservable(
                        this.observeServerPreference<boolean>(ServerUserPreferenceKeys.filters.showGrid),
                    ),
                    (value) => {
                        this.setServerPreference(
                            ServerUserPreferenceKeys.filters.showGrid,
                            value.toString(),
                            errorHandler.onError,
                        );
                    },
                );
                return [showHeatmapSwitch, ...settings];
            }),
        );
    }

    // Private functions

    private setServerPreference(key: string, newValue: string, onError: (error: Error) => void): void {
        this.serverPreferencesRepository.setPreference(key, newValue).subscribe({
            error: (error) => {
                error.message = t("settings.errorSavingSettings");
                onError(error);
            },
            complete: () => console.info(`Server preference '${key}' updated successfully`),
        });
    }

    private observeServerPreference<T>(key: string): Rx.Observable<T | null> {
        return this.serverPreferencesRepository.observePreference(key);
    }
}
