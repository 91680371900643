import React from "react";
import styled, { StyledComponent, withTheme } from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Colors } from "../appearance/Colors";
import Texts from "../appearance/Texts";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import {
    Classification,
    Estimate,
    getRunwayDirectionLabels,
    Location,
    locationToCoord,
    Runway,
    RunwayFunnel,
    Track,
} from "../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { ResizeObserver } from "@juggle/resize-observer";
import {
    DEFAULT_FUNNEL_GUIDELINE_DISTANCES_METERS,
    DEFAULT_FUNNEL_MAX_HEIGHT_METERS,
    DetailedFunnelViewViewModel,
} from "./DetailedFunnelViewViewModel";
import turfBearing from "@turf/bearing";
import turfDistance from "@turf/distance";
import * as turfHelpers from "@turf/helpers";
import turfMidpoint from "@turf/midpoint";
import { fillRoundRectRelative } from "../../utils/CanvasUtils";
import { FunnelViewGuideline } from "./FunnelViewGuideline";
import { FunnelViewSectorRates, getFunnelViewSectorRatesFromArray } from "../funnelview/FunnelViewSectorRates";
import { TrackSymbolGenerator } from "../map/layers/TrackSymbolGenerator";
import { Rect } from "../../utils/Rect";
import { Vector2 } from "../../utils/Vector2";
import { CanvasContainer } from "../appearance/CanvasContainer";
import { getPointFromEvent, Point } from "../../utils/UserInteractionEventsUtils";
import { t } from "i18next";
import { setContextColorBasedOnSectorRate } from "../funnelview/FunnelViewUtils";
import { Theme } from "../appearance/theme/Theme";

const Root = styled.div<{ hasOnClick: boolean }>`
    position: relative;
    background: ${({ theme }) => theme.colors.backgrounds.panel};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 36px 12px 36px;
    text-align: center;
    ${({ hasOnClick }) => hasOnClick && `cursor: pointer;`}
`;

// eslint-disable-next-line
const TitleText = styled(Texts.BottomSheetTitles as StyledComponent<"span", any, {}, never>)`
    margin-bottom: -30px;
    z-index: 1;
`;

interface Props {
    runway: Runway;
    funnel: RunwayFunnel;
    onClick?: () => void;
    theme: Theme;
}

interface State {
    threshold: number;
    leftDirectionLabel: string;
    rightDirectionLabel: string;
    funnelRect: Rect;
    maxHeight: number;
    guidelineDistanceMeters: number;
    referenceAltitude: number;
    sectorRates: FunnelViewSectorRates;
    guidelines: FunnelViewGuideline[];
    tracks: Track[];
    timestamp: long;
    trailLength: long;
    showParkingCorridor: boolean;
}

const COMMON_TURF_OPTIONS = { units: "meters" as turfHelpers.Units };
const MIDDLE_FUNNELS_COUNT = 2;
const CLICK_RADIUS_PX = 20;
const HOVER_SIZE_MULTIPLIER = 1.5;

class DetailedFunnelViewComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: DetailedFunnelViewViewModel = DI.get(TYPES.DetailedFunnelViewViewModel);
    private readonly trackSymbolGenerator: TrackSymbolGenerator = DI.get(TYPES.TrackSymbolGenerator);
    private canvasElement = React.createRef<HTMLCanvasElement>();
    private canvasContext: CanvasRenderingContext2D | null = null;
    private resizeSubject = new Rx.Subject<null>();
    private icons = new Map<string, HTMLImageElement>(); // key is the classification name

    private canvasResizeObserver?: ResizeObserver;
    private totalFunnelLengthMeters = Math.max(this.props.funnel.circuitLength, this.getCombinedFunnelLengthMeters());

    // Dimens, in px
    private cornerRadius = 4;
    private bottomLineStrokeWidth = 2;
    private spaceBetweenFunnels = 4;

    private trackIdToPosition = new Map<number, Point>();
    private hoveredTrackId: number | undefined;

    // Lifecycle

    public constructor(props: Readonly<Props>) {
        super(props);

        const runwayDirectionLabels = getRunwayDirectionLabels(props.runway);

        this.state = {
            threshold: 0,
            leftDirectionLabel: runwayDirectionLabels.left,
            rightDirectionLabel: runwayDirectionLabels.right,
            funnelRect: new Rect(),
            maxHeight: DEFAULT_FUNNEL_MAX_HEIGHT_METERS,
            guidelineDistanceMeters: DEFAULT_FUNNEL_GUIDELINE_DISTANCES_METERS,
            referenceAltitude: 0,
            guidelines: [],
            sectorRates: { top: 0, bottomLeft: 0, bottomMiddleLeft: 0, bottomMiddleRight: 0, bottomRight: 0 },
            tracks: [],
            timestamp: 0,
            trailLength: 0,
            showParkingCorridor: false,
        };
    }

    public componentDidMount(): void {
        // Setup the canvas
        const canvas = this.canvasElement.current!;
        this.resizeCanvas();
        this.canvasResizeObserver = new ResizeObserver(() => this.resizeSubject.next(null));
        this.canvasResizeObserver.observe(canvas);
        this.canvasContext = canvas.getContext("2d")!;

        // Setup subscriptions
        this.collectSubscriptions(
            this.viewModel.funnelViewThreshold.subscribe((threshold) => this.setThreshold(threshold)),
            this.viewModel.referenceAltitude.subscribe((referenceAltitude) => this.setState({ referenceAltitude })),
            this.viewModel.observeRunwayTraffic(this.props.runway.id).subscribe(([timestamp, traffic, tracks]) =>
                this.setState({
                    timestamp,
                    sectorRates: getFunnelViewSectorRatesFromArray(traffic.sectorTrafficRate),
                    tracks,
                }),
            ),
            this.resizeSubject.pipe(RxOperators.debounceTime(10)).subscribe(() => this.resizeCanvas()),
            this.viewModel
                .generateFunnelViewGuideLines(this.props.funnel.funnelHeightMax)
                .subscribe((guidelines) => this.setState({ guidelines })),
            this.viewModel.classifications.subscribe((classifications) => this.loadImages(classifications)),
            this.viewModel.trailLength.subscribe((trailLength) => this.setState({ trailLength })),
            this.viewModel
                .getShowParkingCorridor(this.props.runway.id)
                .subscribe((showParkingCorridor) => this.setState({ showParkingCorridor })),
            Rx.merge(Rx.fromEvent(canvas, "click"), Rx.fromEvent(canvas, "touchend"))
                .pipe(
                    RxOperators.switchMap((event) =>
                        this.findClosestTrackToMousePosition(event as MouseEvent | TouchEvent).pipe(
                            // If we found a track, run this side effect to prevrnt click event from
                            // bubbling and closing the detailed funnel view
                            RxOperators.tap((output) => output && event.stopPropagation()),
                        ),
                    ),
                    RxOperators.filter((output) => output != null),
                    RxOperators.map((output) => output!),
                )
                .subscribe((trackId) => this.viewModel.selectTrack(trackId)),
            Rx.fromEvent(canvas, "mousemove")
                .pipe(
                    // We don't need to do this for every single movement. updating every 100ms feels natural enough
                    RxOperators.bufferTime(100),
                    RxOperators.filter((events) => events.length > 0),
                    RxOperators.switchMap((events) =>
                        this.findClosestTrackToMousePosition(events[events.length - 1] as MouseEvent),
                    ),
                )
                .subscribe((trackId) => {
                    this.hoveredTrackId = trackId;
                    this.redrawCanvas();
                }),
        );

        // Get height of funnel
        this.setState({ maxHeight: this.viewModel.calculateFunnelViewHeight(this.props.funnel.funnelHeightMax) });
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.canvasResizeObserver && this.canvasResizeObserver.disconnect();
    }

    public componentDidUpdate(): void {
        this.redrawCanvas();
    }

    public render(): React.ReactNode {
        return (
            <Root onClick={this.props.onClick} hasOnClick={this.props.onClick !== undefined}>
                <TitleText>{t("funnelViewRunwayCrossings.funnelViewDetails")}</TitleText>
                <CanvasContainer>
                    <canvas ref={this.canvasElement} />
                </CanvasContainer>
            </Root>
        );
    }

    // Private functions

    private setThreshold(threshold: number): void {
        this.setState({ threshold });
    }

    /**
     * This function clears the canvas and draws it again.
     * It is called on every update of the component.
     */
    private redrawCanvas(): void {
        const ctx = this.canvasContext;
        if (!ctx) {
            return;
        }

        const dpr = window.devicePixelRatio || 1;

        const canvas = ctx.canvas;
        ctx.fillStyle = this.props.theme.colors.backgrounds.panel;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.font = `normal ${16 * dpr}px 'Roboto'`;

        /*
         * Optimization idea: Separate funnels and tracks into different canvases
         * and don't redraw the whole funnel on each update
         */

        this.drawFunnel(ctx, dpr);
        this.drawGuidelines(ctx, dpr);
        this.drawTracks(ctx, dpr);
        this.drawCountsLabels(ctx, dpr);
        this.drawDirectionLabels(ctx, dpr);

        ctx.restore();
    }

    /**
     * Draws the funnel shape outline
     * @param ctx Canvas context
     * @param dpr Device pixel ratio
     */
    private drawFunnel(ctx: CanvasRenderingContext2D, dpr: number): void {
        const funnelLengthRunwaySection = this.getSingleFunnelLengthPixels(true);
        const funnelLengthOuterSection = this.getSingleFunnelLengthPixels(false);

        const funnel = this.props.funnel;
        const funnelRect = this.state.funnelRect;
        const fcr = this.cornerRadius * dpr;
        const sbf = this.spaceBetweenFunnels * dpr;

        ctx.fillStyle = this.props.theme.colors.text.text;

        // Bottom line
        ctx.fillRect(
            funnelRect.left + funnelLengthOuterSection,
            funnelRect.bottom,
            funnelRect.right - funnelRect.left - funnelLengthOuterSection * 2,
            -this.bottomLineStrokeWidth * dpr,
        );

        ctx.globalCompositeOperation = "source-over";

        // Top bar
        if (this.state.showParkingCorridor) {
            this.setContextColorBasedOnSector(ctx, "top");
            fillRoundRectRelative(
                ctx,
                funnelRect.left,
                this.metersToScreenY(funnel.circuitHeightMax),
                funnelRect.right,
                this.metersToScreenY(funnel.circuitHeightMin),
                fcr,
            );
        }

        // Path for left diagonal bar
        const diagonalBarsMinY = this.metersToScreenY(funnel.funnelHeightMin);
        const diagonalBarsMaxY = this.metersToScreenY(funnel.funnelHeightMax);
        const leftDiagonalBottomBarPath = new Path2D();
        leftDiagonalBottomBarPath.moveTo(funnelRect.left, diagonalBarsMinY);
        leftDiagonalBottomBarPath.lineTo(funnelRect.left, diagonalBarsMaxY);
        leftDiagonalBottomBarPath.lineTo(
            funnelRect.left + funnelLengthOuterSection - sbf,
            this.metersToScreenY(funnel.runwayHeight),
        );
        leftDiagonalBottomBarPath.lineTo(funnelRect.left + funnelLengthOuterSection - sbf, this.metersToScreenY(0));
        leftDiagonalBottomBarPath.closePath();
        this.setContextColorBasedOnSector(ctx, "bottomLeft");
        ctx.fill(leftDiagonalBottomBarPath);

        // Path for two middle bottom bar
        let lastX = funnelRect.left + funnelLengthOuterSection;
        const drawMiddleFunnel = (sector: keyof FunnelViewSectorRates): void => {
            this.setContextColorBasedOnSector(ctx, sector);
            fillRoundRectRelative(
                ctx,
                lastX,
                this.metersToScreenY(funnel.runwayHeight),
                lastX + funnelLengthRunwaySection - sbf / 2,
                this.metersToScreenY(0),
                fcr,
            );
            lastX += funnelLengthRunwaySection + sbf / 2;
        };

        drawMiddleFunnel("bottomMiddleLeft");
        drawMiddleFunnel("bottomMiddleRight");

        // Path for right diagonal bar
        const rightDiagonalBarStartX = funnelRect.right - funnelLengthOuterSection + sbf;
        const rightDiagonalBottomBarPath = new Path2D();
        rightDiagonalBottomBarPath.moveTo(rightDiagonalBarStartX, this.metersToScreenY(funnel.runwayHeight));
        rightDiagonalBottomBarPath.lineTo(rightDiagonalBarStartX + funnelLengthOuterSection - sbf, diagonalBarsMaxY);
        rightDiagonalBottomBarPath.lineTo(rightDiagonalBarStartX + funnelLengthOuterSection - sbf, diagonalBarsMinY);
        rightDiagonalBottomBarPath.lineTo(rightDiagonalBarStartX, this.metersToScreenY(0));
        rightDiagonalBottomBarPath.closePath();
        this.setContextColorBasedOnSector(ctx, "bottomRight");
        ctx.fill(rightDiagonalBottomBarPath);

        // Reset what this function changed
        ctx.globalCompositeOperation = "source-over";
        ctx.globalAlpha = 1;
    }

    /**
     * Draws track estimates as icons
     * @param ctx Canvas context
     * @param dpr Device pixel ratio
     */
    private drawTracks(ctx: CanvasRenderingContext2D, dpr: number): void {
        this.trackIdToPosition.clear();
        this.state.tracks.forEach((t) => {
            const e = t.getClosestEstimateTo(this.state.timestamp) || t.lastEstimate;
            if (this.estimateIsExpired(e, this.state.timestamp, this.state.trailLength)) {
                return;
            }
            this.drawTrackEstimate(ctx, dpr, t.id, e);
        });
    }

    /**
     * Draw a single track estimate as an icon
     * @param ctx Canvas context
     * @param dpr Device pixel ratio
     * @param trackId Track ID
     * @param estimate Track estimate object
     */
    private drawTrackEstimate(ctx: CanvasRenderingContext2D, dpr: number, trackId: number, estimate: Estimate): void {
        if (estimate.classification == null) {
            return;
        }
        const trackIcon = this.icons.get(estimate.classification.name);
        if (trackIcon == null) {
            return;
        }

        const headPosition = this.geoLocationToScreenPoint(estimate.location);
        if (
            !this.state.funnelRect.contains(headPosition.x, headPosition.y) &&
            Math.abs(headPosition.y - this.state.funnelRect.bottom) > 1
        ) {
            return;
        }

        ctx.save();
        ctx.shadowColor = this.props.theme.colors.backgrounds.panel;
        ctx.shadowBlur = 4;
        ctx.shadowOffsetY = 1;
        const rotation = this.getPointingDirectionRadians(estimate.bearing);
        ctx.translate(headPosition.x, headPosition.y);
        ctx.rotate(rotation);
        const hoverSizeMultiplier = this.hoveredTrackId === trackId ? HOVER_SIZE_MULTIPLIER : 1;
        const w = trackIcon.width * hoverSizeMultiplier * dpr;
        const h = trackIcon.height * hoverSizeMultiplier * dpr;
        ctx.drawImage(trackIcon, -w / 2, -h / 2, w, h);
        ctx.rotate(-rotation);
        ctx.translate(-headPosition.x, -headPosition.y);
        ctx.restore();

        this.trackIdToPosition.set(trackId, { x: headPosition.x, y: headPosition.y });
    }

    /**
     * Draw counts labels on the x-axis of the funnel
     * @param ctx Canvas context
     * @param dpr Device pixel ratio
     */
    private drawCountsLabels(ctx: CanvasRenderingContext2D, dpr: number): void {
        ctx.save();
        ctx.textAlign = "center";
        ctx.font = `normal ${20 * dpr}px 'Roboto'`;

        const points: Map<keyof FunnelViewSectorRates, Vector2> = new Map();
        const funnelRect = this.state.funnelRect;
        const fontSize = parseInt(ctx.font);
        const y = funnelRect.bottom + (fontSize * 3) / 2;

        points.set("top", { x: funnelRect.centerX(), y });

        const funnelLengthRunwaySection = this.getSingleFunnelLengthPixels(true);
        const funnelLengthOuterSection = this.getSingleFunnelLengthPixels(false);

        points.set("bottomLeft", { x: funnelRect.left + funnelLengthOuterSection / 2, y });

        const offsetLast = (x = 0, y = 0): Vector2 => {
            const values = Array.from(points.values());
            const last: Vector2 = values[values.length - 1] || { x: 0, y: 0 };
            return { x: last.x + x, y: last.y + y };
        };

        points.set("bottomMiddleLeft", offsetLast(funnelLengthOuterSection / 2 + funnelLengthRunwaySection / 2));

        points.set("bottomMiddleRight", offsetLast(funnelLengthRunwaySection));

        points.set("bottomRight", offsetLast(funnelLengthOuterSection / 2 + funnelLengthRunwaySection / 2));

        const rates = this.state.sectorRates;
        points.forEach((p, key) => {
            const rate = rates[key] || 0;
            ctx.fillStyle =
                rate >= this.state.threshold
                    ? this.props.theme.colors.secondary.red
                    : this.props.theme.colors.text.text;
            ctx.fillText(rate.toString(), p.x, p.y);
        });

        ctx.restore();
    }

    /**
     * Draw Y-axis, labels and horizontal guidelines
     * @param ctx Canvas context
     * @param dpr Device pixel ratio
     */
    private drawGuidelines(ctx: CanvasRenderingContext2D, dpr: number): void {
        ctx.save();
        this.state.guidelines.forEach((guideline, index) => {
            const color = index % 2 === 0 ? this.props.theme.colors.text.text : this.props.theme.colors.text.text200;

            // Y-axis label
            ctx.fillStyle = color;
            const point = { x: 0, y: this.metersToScreenY(guideline.height) };
            ctx.fillText(guideline.label, point.x, point.y);

            ctx.fillStyle = this.props.theme.colors.text.text;
            ctx.globalAlpha = index % 2 === 0 ? 0.5 : 0.3;

            // Horizontal guideline
            const path = new Path2D();
            const y = this.metersToScreenY(guideline.height);
            path.moveTo(this.state.funnelRect.left, y);
            path.lineTo(this.state.funnelRect.right, y);
            ctx.strokeStyle = color;
            ctx.setLineDash([5 * dpr, 3 * dpr]);
            ctx.stroke(path);

            // Reset
            ctx.globalAlpha = 1;
        });
        ctx.restore();
    }

    /**
     * Draw runway direction identifier texts
     * @param ctx Canvas context
     * @param dpr Device pixel ratio
     */
    private drawDirectionLabels(ctx: CanvasRenderingContext2D, dpr: number): void {
        ctx.save();
        ctx.font = `normal ${16 * dpr}px 'Roboto'`;
        const fontSize = parseInt(ctx.font);
        ctx.fillStyle = Colors.text.text300;
        ctx.textAlign = "left";
        ctx.fillText(this.state.leftDirectionLabel, this.state.funnelRect.left, fontSize);
        ctx.textAlign = "right";
        ctx.fillText(this.state.rightDirectionLabel, this.state.funnelRect.right, fontSize);
        ctx.restore();
    }

    private estimateIsExpired(estimate: Estimate, snapshotTimestamp: long, lifeTime: long): boolean {
        return estimate.timestamp <= snapshotTimestamp - lifeTime;
    }

    private setContextColorBasedOnSector(ctx: CanvasRenderingContext2D, sector: keyof FunnelViewSectorRates): void {
        setContextColorBasedOnSectorRate(
            ctx,
            this.state.sectorRates[sector],
            this.state.threshold,
            this.props.theme.colors.text.text200,
            this.props.theme.colors.secondary.red200,
        );
    }

    private getPointingDirectionRadians(bearing?: number): number {
        if (bearing == null) {
            return 0;
        }

        const pointAToBBearing = turfHelpers.degreesToRadians(
            turfBearing(locationToCoord(this.props.funnel.pointA), locationToCoord(this.props.funnel.pointB)),
        );
        const angle = Math.abs(bearing - pointAToBBearing);
        return (angle > Math.PI / 2 ? -1 : 1) * (Math.PI / 2);
    }

    private geoLocationToScreenPoint(location: Location): Vector2 {
        const funnel = this.props.funnel;

        const locationCoord = locationToCoord(location);
        const pA = locationToCoord(funnel.pointA);
        const pB = locationToCoord(funnel.pointB);
        const center = turfMidpoint(pA, pB);
        // The angle between the runway path(pointA to pointB) and the line that connects center of the runway to the given location
        const angle = turfHelpers.degreesToRadians(turfBearing(center, locationCoord) - turfBearing(pA, pB));
        const x = Math.cos(angle) * turfDistance(locationCoord, center, COMMON_TURF_OPTIONS);
        return {
            x: this.state.funnelRect.centerX() + this.metersToScreenX(x),
            y: this.metersToScreenY(location.getRelativeAltitude(this.state.referenceAltitude) || 0),
        };
    }

    private getCombinedFunnelLengthMeters(): number {
        const sideFunnelLength = this.getSingleFunnelLengthMeters(false);
        const middleFunnelLength = this.getSingleFunnelLengthMeters(true);
        return sideFunnelLength * 2 + middleFunnelLength * MIDDLE_FUNNELS_COUNT;
    }

    private getSingleFunnelLengthMeters(isRunwaySection: boolean): number {
        const funnel = this.props.funnel;
        if (isRunwaySection) {
            return (
                turfDistance(locationToCoord(funnel.pointA), locationToCoord(funnel.pointB), COMMON_TURF_OPTIONS) /
                MIDDLE_FUNNELS_COUNT
            );
        }
        return funnel.funnelLength;
    }

    private getSingleFunnelLengthPixels(isRunwaySection: boolean): number {
        return this.metersToScreenX(this.getSingleFunnelLengthMeters(isRunwaySection));
    }

    private metersToScreenX(x: number): number {
        return (x / this.totalFunnelLengthMeters) * this.state.funnelRect.width();
    }

    private metersToScreenY(y: number): number {
        return this.state.funnelRect.bottom - (y / this.state.maxHeight) * this.state.funnelRect.height();
    }

    private loadImages(classifications: Map<string, Classification>): void {
        classifications.forEach((c) => this.loadImage(c));
    }

    private loadImage(classification: Classification): void {
        const iconData = this.trackSymbolGenerator.generateSymbolBase64(classification, 1);

        if (iconData == null) {
            return;
        }

        const image = new Image();
        image.onload = () => {
            this.icons.set(classification.name, image);
        };
        image.src = iconData;
    }

    private resizeCanvas(): void {
        const canvas = this.canvasElement.current!;
        // Make it visually fill the positioned parent
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        // Get the device pixel ratio, falling back to 1.
        const dpr = window.devicePixelRatio || 1;
        // ...then set the internal size to matchxw
        canvas.width = canvas.offsetWidth * dpr;
        canvas.height = canvas.offsetHeight * dpr;

        const funnelsHorizontalPadding = 72 * dpr; // px
        const funnelsTopPadding = 42 * dpr; // px
        const funnelsBottomPadding = 48 * dpr; // px
        const funnelsViewWidth = canvas.width - funnelsHorizontalPadding * 2;
        const funnelsViewHeight = canvas.height - funnelsTopPadding - funnelsBottomPadding;
        const funnelRect = new Rect(
            funnelsHorizontalPadding,
            funnelsTopPadding,
            funnelsHorizontalPadding + funnelsViewWidth,
            funnelsTopPadding + funnelsViewHeight,
        );
        this.setState({ funnelRect: funnelRect });
    }

    private findClosestTrackToMousePosition(event: MouseEvent | TouchEvent): Rx.Observable<number | undefined> {
        return new Rx.Observable((emitter) => {
            let closestTrackId: number | undefined;
            let closestTrackDistance: number = Number.MAX_VALUE;
            const mousePosition = this.mousePositionToCanvasPoint(event);
            this.trackIdToPosition.forEach((point, trackId) => {
                const d = Math.sqrt(Math.pow(mousePosition.x - point.x, 2) + Math.pow(mousePosition.y - point.y, 2));
                if (d < CLICK_RADIUS_PX && d < closestTrackDistance) {
                    closestTrackDistance = d;
                    closestTrackId = trackId;
                }
            });

            if (!closestTrackId) {
                emitter.next(undefined);
                emitter.complete();
                return;
            }

            emitter.next(closestTrackId);
            emitter.complete();
        });
    }

    private mousePositionToCanvasPoint(event: MouseEvent | TouchEvent): Point {
        const dpr = window.devicePixelRatio || 1;
        const rect = (event.target! as HTMLCanvasElement).getBoundingClientRect();
        const point = getPointFromEvent(event);
        return {
            x: (point.x - rect.left) * dpr,
            y: (point.y - rect.top) * dpr,
        };
    }
}

export const DetailedFunnelView = withTheme(DetailedFunnelViewComponent);
