import React from "react";
import { TYPES } from "../../../../../di/Types";
import { ClassificationFilterStatusIndicatorViewModel } from "./ClassificationFilterStatusIndicatorViewModel";
import { t } from "i18next";
import { useViewModel } from "../../../../../hooks/useViewModel";
import { useObservable } from "../../../../../hooks";
import { Icon } from "../../../../appearance/Icon";

export const ClassificationFilterStatusIndicator = (): JSX.Element | null => {
    // Properties

    const viewModel: ClassificationFilterStatusIndicatorViewModel = useViewModel(
        TYPES.ClassificationFilterStatusIndicatorViewModel,
    );
    const isActive = useObservable(viewModel.isClassificationFilterActive, false);

    // Render

    if (!isActive) {
        return null;
    }

    return (
        <Icon
            color="danger"
            iconType="SHAPES"
            large={true}
            tooltip={t("status.classificationFilterIsActive")}
            tooltipPosition="left"
        />
    );
};
