import {
    ReplayRepository,
    TrackRepository,
    LocalPreferencesRepository,
    LocationInfoRepository,
    TrackObservationRepository,
    GroundObservationRepository,
    RadarRepository,
    UserLocationRepository,
    MeasurementPointsRepository,
    AlarmRepository,
} from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    Classification,
    getClassificationAltitudeFilterKey,
    getClassificationVisibilityKey,
    LocalUserPreferenceKeys,
    Track,
    TrackObservationMode,
    TracksSnapshotDiff,
} from "../../../../domain/model";
import { ClassificationNameAndBooleanPair } from "../../ClassificationNameAndBooleanPair";
import { BaseTracksModuleViewModel } from "./BaseTracksModuleViewModel";
import { TracksSnapshotDiffCalculator } from "../../../../domain/TracksSnapshotDiffCalculator";

export class TracksModuleViewModel extends BaseTracksModuleViewModel<Track> {
    // Properties

    public get tracksSnapshotDiff(): Rx.Observable<TracksSnapshotDiff> {
        return this.snapshotDiffCalculator.snapshotDiff;
    }

    public get classifications(): Rx.Observable<Map<string, Classification>> {
        // Don't emit a new value when in replay mode to prevent the map from re-rendering unnecessarily
        return Rx.combineLatest([
            this.trackRepository.classificationsMap,
            this.replayRepository.currentPlaybackScene,
        ]).pipe(
            RxOperators.filter(([, scene]) => scene == null),
            RxOperators.map(([classifications]) => classifications),
        );
    }

    public get visibleClassifications(): Rx.Observable<Classification[]> {
        return this.trackRepository.classificationsMap.pipe(
            RxOperators.switchMap((c) => this.getVisibleClassificationsObservable(Array.from(c.values()))),
        );
    }

    public get applyAltitudeFilterClassifications(): Rx.Observable<string[]> {
        return this.trackRepository.classificationsMap.pipe(
            RxOperators.map((c) => Array.from(c.keys())),
            RxOperators.switchMap((names) =>
                Rx.combineLatest(names.map((name) => this.getClassificationApplyAltitudeFilterObservable(name))),
            ),
            RxOperators.map((pairs) => pairs.filter((pair) => pair.value).map((pair) => pair.name)),
        );
    }

    public get trackObservationMode(): Rx.Observable<TrackObservationMode> {
        return this.trackObservationRepository.mode;
    }

    public get selectedTracksInObservation(): Rx.Observable<Track[]> {
        return this.trackObservationRepository.selectedTracks;
    }

    public get observedTrackIds(): Rx.Observable<number[]> {
        return this.trackObservationRepository.observedTrackIds;
    }

    public get suppressClickForObservation(): Rx.Observable<boolean> {
        if (!this.groundObservationRepository) {
            return Rx.of(false);
        }
        return this.groundObservationRepository.shouldSuppressClickEvent;
    }

    public getSelectedTrackId(): Rx.Observable<number | null> {
        return this.trackRepository.selectedTrackId;
    }

    public get alarmBoxHovering(): Rx.Observable<string | null> {
        if (this.alarmRepository) {
            return this.alarmRepository.alarmBoxHovering;
        }
        return Rx.EMPTY;
    }

    private replaySceneServerTime?: long;

    public constructor(
        localPreferencesRepository: LocalPreferencesRepository,
        replayRepository: ReplayRepository,
        radarRepository: RadarRepository,
        userLocationRepository: UserLocationRepository,
        locationInfoRepository: LocationInfoRepository,
        measurementPointsRepository: MeasurementPointsRepository,
        alarmRepository: AlarmRepository,
        private readonly trackRepository: TrackRepository,
        protected readonly snapshotDiffCalculator: TracksSnapshotDiffCalculator,
        private readonly trackObservationRepository: TrackObservationRepository,
        private readonly groundObservationRepository?: GroundObservationRepository,
    ) {
        super(
            localPreferencesRepository,
            replayRepository,
            radarRepository,
            userLocationRepository,
            locationInfoRepository,
            measurementPointsRepository,
            alarmRepository,
        );
    }

    // Public functions

    public setup(): void {
        super.setup();
        this.collectSubscriptions(
            this.replayRepository.currentPlaybackScene
                .pipe(
                    RxOperators.switchMap((s) => (s == null ? Rx.of(null) : s.tracks)),
                    RxOperators.map((t) => (t == null ? undefined : t.timestamp)),
                )
                .subscribe((t) => (this.replaySceneServerTime = t)),
        );
    }

    public selectTrack(trackId: number | null): void {
        this.trackRepository.toggleSelectedTrackId(trackId);
    }

    public toggleTrack(track: Track): void {
        this.trackObservationRepository.toggleTrack(track, this.replaySceneServerTime);
        if (this.groundObservationRepository) {
            // Remove any existing selections in ground observation
            this.groundObservationRepository.clearSelection();
            // Temporarily suppress clicks in ground observation in case there is an existing ground observation at the same location
            this.groundObservationRepository.suppressClickEvent(300);
        }
    }

    public shouldFilterTracksByAltitudeRange(classification: Classification): boolean {
        return (
            this.localPreferencesRepository.getPreference(getClassificationAltitudeFilterKey(classification.name)) ||
            false
        );
    }

    public showVrOnlyTracks(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.appearance.showVrOnlyTracks,
            ),
            false,
        );
    }

    // Private functions

    private getVisibleClassificationsObservable(classifications: Classification[]): Rx.Observable<Classification[]> {
        return Rx.combineLatest(
            classifications.map((c) => {
                const key = getClassificationVisibilityKey(c.name);
                return nonNullObservable(this.localPreferencesRepository.observePreference<boolean>(key), true).pipe(
                    RxOperators.map((value) => ({ classification: c, value })),
                );
            }),
        ).pipe(RxOperators.map((pairs) => pairs.filter((pair) => pair.value).map((pair) => pair.classification)));
    }

    private getClassificationApplyAltitudeFilterObservable(
        classificationName: string,
    ): Rx.Observable<ClassificationNameAndBooleanPair> {
        const key = getClassificationAltitudeFilterKey(classificationName);
        return nonNullObservable(this.localPreferencesRepository.observePreference<boolean>(key), true).pipe(
            RxOperators.map((v) => ({ name: classificationName, value: v })),
        );
    }
}
