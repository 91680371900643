import React from "react";

export interface Point {
    x: int;
    y: int;
}

export function getPointFromEvent(event: MouseEvent | TouchEvent): Point {
    if ((event as TouchEvent).touches) {
        const e = event as TouchEvent;
        const touch = e.touches[0] || e.changedTouches[0];
        return { x: touch.clientX, y: touch.clientY };
    } else {
        const e = event as MouseEvent;
        return { x: e.clientX, y: e.clientY };
    }
}

export function getPointFromReactEvent(event: React.MouseEvent | React.TouchEvent): Point {
    if ((event as React.TouchEvent).touches) {
        const e = event as React.TouchEvent;
        const touch = e.touches[0] || e.changedTouches[0];
        return { x: touch.clientX, y: touch.clientY };
    } else {
        const e = event as React.MouseEvent;
        return { x: e.clientX, y: e.clientY };
    }
}
