import React from "react";
import { TYPES } from "../../../../../di/Types";
import { AlarmStatusIndicatorViewModel } from "./AlarmStatusIndicatorViewModel";
import { t } from "i18next";
import { useViewModel } from "../../../../../hooks/useViewModel";
import { useObservable } from "../../../../../hooks";
import { Icon } from "../../../../appearance/Icon";

export const AlarmStatusIndicator = (): JSX.Element => {
    // Properties

    const viewModel: AlarmStatusIndicatorViewModel = useViewModel(TYPES.AlarmStatusIndicatorViewModel);
    const showNotificationStatusIndicator = useObservable(viewModel.isAnyAlarmNotificationDisabled, true);
    const showSoundStatusIndicator = useObservable(viewModel.isAnyAlarmSoundDisabled, true);

    // Render

    return (
        <>
            {showNotificationStatusIndicator && (
                <Icon
                    color="danger"
                    iconType="EYE_DISABLED"
                    large={true}
                    tooltip={t("status.alarmIsDisabled")}
                    tooltipPosition="left"
                />
            )}
            {showSoundStatusIndicator && (
                <Icon
                    color="danger"
                    iconType="BELL_DISABLED"
                    large={true}
                    tooltip={t("status.alarmIsMuted")}
                    tooltipPosition="left"
                />
            )}
        </>
    );
};
