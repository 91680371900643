import { LocalPreferencesRepository, ReplayRepository, TrackRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Estimate, LocalUserPreferenceKeys, Track, TracksSnapshot } from "../../domain/model";
import { BaseViewModel } from "../BaseViewModel";
import { nonNullObservable } from "../../utils/RxUtils";
import { DistanceFormatFunction, DistanceFormatter, DistanceFormatType } from "../../domain/DistanceFormatter";
import { DistanceUnit } from "../../domain/model/DistanceUnit";
import _dropRightWhile from "lodash/dropRightWhile";
import { AltitudeSliderConfig, FlavorConfig } from "../../infrastructure/FlavorConfig";
import { HandleType } from "./HandleType";
import { TrackDensityIndicatorsData } from "./AltitudeSliderGraph";

// We only want to count the tracks which their last estimate is from "just a moment" ago.
// This 2 seconds here makes up for possible connection problems.
const VALID_TIME_OFFSET_OF_LAST_ALTITUDE_MILLIS = 2000;

export interface AltitudeGuide {
    altitude: number;
    label: string;
}

/**
 * MIN AND MAX VARIABLES EXPLAINED:
 *
 * - Upper boundary
 * - |
 * - Max altitude of interest (top slider handle)
 * - |
 * - |
 * - Min altitude of interest (bottom slider handle)
 * - |
 * - Lower boundary
 */
export class AltitudeSliderViewModel extends BaseViewModel {
    // Properties

    /**
     * Whether or not to show the altitude filter guidelines
     * Guidelines are indicators of how many tracks are at a given altitude
     */
    public get shouldShowGuideline(): boolean {
        return this.trackRepository.shouldShowAltitudeFilterGuideline;
    }

    /**
     * Get the min value of the altitude filter from the flavor config, converted to metric units
     * FYI: Flavor config always uses the current units
     */
    public get lowerBoundaryMetric(): Rx.Observable<number> {
        return this.flavorConfigObservable.pipe(
            RxOperators.map((config) =>
                this.distanceFormatter.convertValueFromCurrentUnit(
                    config.lowerBoundary,
                    DistanceUnit.METRIC,
                    DistanceFormatFunction.ROUND_DECIMAL_2,
                ),
            ),
        );
    }

    /**
     * Get the min value of the altitude filter from the flavor config
     * FYI: Flavor config always uses the current units
     */
    public get lowerBoundaryInCurrentUnits(): Rx.Observable<number> {
        return this.flavorConfigObservable.pipe(RxOperators.map((config) => config.lowerBoundary));
    }

    /**
     * Get the max value of the altitude filter from the flavor config, converted to metric units
     * FYI: Flavor config always uses the current units
     */
    public get upperBoundaryMetric(): Rx.Observable<number> {
        return this.flavorConfigObservable.pipe(
            RxOperators.map((config) =>
                this.distanceFormatter.convertValueFromCurrentUnit(
                    config.upperBoundary,
                    DistanceUnit.METRIC,
                    DistanceFormatFunction.ROUND_DECIMAL_2,
                ),
            ),
        );
    }

    /**
     * Get the max value of the altitude filter from the flavor config
     * FYI: Flavor config always uses the current units
     */
    public get upperBoundaryInCurrentUnits(): Rx.Observable<number> {
        return this.flavorConfigObservable.pipe(RxOperators.map((config) => config.upperBoundary));
    }

    /**
     * Get the step value of the altitude filter from the flavor config, converted to metric units
     * FYI: Flavor config always uses the current units
     */
    public get stepSizeMetric(): Rx.Observable<number> {
        return this.flavorConfigObservable.pipe(
            RxOperators.map((config) =>
                this.distanceFormatter.convertValueFromCurrentUnit(
                    config.stepSize,
                    DistanceUnit.METRIC,
                    DistanceFormatFunction.ROUND_DECIMAL_2,
                ),
            ),
        );
    }

    /**
     * Get the configured step value from the flavor config
     * FYI: Flavor config always uses the current units
     */
    public get stepSize(): Rx.Observable<number> {
        return this.flavorConfigObservable.pipe(RxOperators.map((config) => config.stepSize));
    }

    /**
     * Get the current altitude slider config from the flavor config
     * FYI: Flavor config always uses the current units
     */
    public get currentFlavorConfig(): AltitudeSliderConfig {
        return this.distanceFormatter.selectedDistanceUnit === DistanceUnit.METRIC
            ? this.flavorConfig.altitudeSliderConfig.metric
            : this.flavorConfig.altitudeSliderConfig.imperial;
    }

    /**
     * Get the currently user defined min value of the altitude filter in metric units
     */
    public get userDefinedMinMetric(): Rx.Observable<number> {
        return this.localPreferencesRepository
            .observePreference<number>(LocalUserPreferenceKeys.filters.minAltitudeOfInterest)
            .pipe(RxOperators.switchMap((value) => (value == null ? this.lowerBoundaryMetric : Rx.of(value))));
    }

    /**
     * Get the currently set max value of the altitude filter in metric units
     */
    public get userDefinedMaxMetric(): Rx.Observable<number> {
        return this.localPreferencesRepository
            .observePreference<number>(LocalUserPreferenceKeys.filters.maxAltitudeOfInterest)
            .pipe(RxOperators.switchMap((value) => (value == null ? this.upperBoundaryMetric : Rx.of(value))));
    }

    /**
     * Get the currently set max value of the altitude filter in the currently selected units
     */
    public get userDefinedMinInCurrentUnits(): Rx.Observable<number> {
        return this.distanceFormatter
            .formatObservable(this.userDefinedMinMetric, (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .pipe(RxOperators.map((value) => Number(value)));
    }

    /**
     * Get the currently set min value of the altitude filter in the currently selected units
     */
    public get userDefinedMaxInCurrentUnits(): Rx.Observable<number> {
        return this.distanceFormatter
            .formatObservable(this.userDefinedMaxMetric, (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .pipe(RxOperators.map((value) => Number(value)));
    }

    /**
     * Returns an observable with a map of the number of tracks at that altitude
     */
    public get altitudeToTrackDensity(): Rx.Observable<Map<number, number>> {
        return this.altitudeToTrackDensityMapSubject.asObservable().pipe(RxOperators.distinctUntilChanged());
    }

    /**
     * Get an array of altitudes of all aircraft
     */
    public get aircraftAltitudes(): Rx.Observable<number[]> {
        return this.aircraftAltitudesSubject.asObservable().pipe(RxOperators.distinctUntilChanged());
    }

    /**
     * The current distance unit (metric or imperial) from the flavor config
     */
    private readonly flavorConfigObservable = this.distanceFormatter.selectedDistanceUnitObservable.pipe(
        RxOperators.map((unit) =>
            unit === DistanceUnit.METRIC
                ? this.flavorConfig.altitudeSliderConfig.metric
                : this.flavorConfig.altitudeSliderConfig.imperial,
        ),
    );
    private altitudeToTrackDensityMapSubject = new Rx.BehaviorSubject<Map<number, number>>(new Map());
    private aircraftAltitudesSubject = new Rx.BehaviorSubject<number[]>([]);

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly trackRepository: TrackRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly distanceFormatter: DistanceFormatter,
        private readonly flavorConfig: FlavorConfig,
    ) {
        super();
        this.subscribeToTrackUpdates();
        this.subscribeToDistanceUnitChange();
    }

    // Public functions

    /**
     * Assumes values are in current unit
     * @param min Required. New minimum altitude of interest in the current units
     * @param max Required. New maximum altitude of interest in the current units
     */
    public setAltitudeRangeFromCurrentUnitValues(min: number, max: number): void {
        const formattedMin = this.distanceFormatter.convertValueFromCurrentUnit(
            min,
            DistanceUnit.METRIC,
            DistanceFormatFunction.ROUND_DECIMAL_2,
        );
        const formattedMax = this.distanceFormatter.convertValueFromCurrentUnit(
            max,
            DistanceUnit.METRIC,
            DistanceFormatFunction.ROUND_DECIMAL_2,
        );
        this.setMinAltitudeOfInterestMetric(formattedMin);
        this.setMaxAltitudeOfInterestMetric(formattedMax);
    }

    /**
     * Validates and submits a min or max altitude of interest value in the current units
     * @param newValue Required. New value of the altitude of interest in the current units
     * @param handle Required. The handle that was moved (top or bottom)
     */
    public validateMinOrMaxAltitudeOfInterest(newValue: number, handle: HandleType): void {
        Rx.combineLatest([
            this.lowerBoundaryMetric,
            this.upperBoundaryMetric,
            this.stepSize,
            this.userDefinedMinMetric,
            this.userDefinedMaxMetric,
        ])
            .subscribe(([lowerBoundary, upperBoundary, stepSize, userDefinedMinMetric, userDefinedMaxMetric]) => {
                let min: number = userDefinedMinMetric;
                let max: number | null = userDefinedMaxMetric;

                if (handle === HandleType.Top) {
                    if (min >= newValue && min > lowerBoundary && newValue > lowerBoundary) {
                        min = newValue - stepSize;
                    }
                    if (min < newValue) {
                        max = newValue;
                    }
                } else if (handle === HandleType.Bottom) {
                    if (max <= newValue && max < upperBoundary && newValue < upperBoundary) {
                        max = newValue + stepSize;
                    }
                    if (max > newValue) {
                        min = newValue;
                    }
                }
                // If the top limit is at or above the maximum value, set it to null (it means we don't have any limitation on top)
                if (max >= upperBoundary) {
                    max = null;
                }
                // If the bottom limit has gone below the minimum value, set it to the minimum value
                if (min < lowerBoundary) {
                    min = lowerBoundary;
                }

                this.setMaxAltitudeOfInterestMetric(max);
                this.setMinAltitudeOfInterestMetric(min);
            })
            .unsubscribe();
    }

    /**
     * Stores the given value as the minimum altitude of interest (metric units) in local preferences
     * @param value Required. New minimum altitude of interest
     */
    public setMinAltitudeOfInterestMetric(value: number | null): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.filters.minAltitudeOfInterest, value);
    }

    /**
     * Stores the given value as the maximum altitude of interest (metric units) in local preferences
     * @param value Required. New maximum altitude of interest
     */
    public setMaxAltitudeOfInterestMetric(value: number | null): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.filters.maxAltitudeOfInterest, value);
    }

    /**
     * Get the handle labels for the altitude slider in current units
     * @returns An observable with the labels for the handles as strings of the altitude slider
     */
    public getHandleLabels(): Rx.Observable<string[]> {
        return Rx.combineLatest([this.userDefinedMaxMetric, this.upperBoundaryMetric, this.userDefinedMinMetric]).pipe(
            RxOperators.map(([maxAltitudeOfInterest, max, minAltitudeOfInterest]) => {
                const formatType =
                    maxAltitudeOfInterest === max ? DistanceFormatType.ABOVE_SPACED : DistanceFormatType.SPACED;
                const formatFunction = DistanceFormatFunction.ROUND_DECIMAL_2;
                const top = this.distanceFormatter.formatValueWithCurrentUnit(
                    maxAltitudeOfInterest,
                    DistanceUnit.METRIC,
                    { formatType, formatFunction },
                );

                const bottom = this.distanceFormatter.formatValueWithCurrentUnit(
                    minAltitudeOfInterest,
                    DistanceUnit.METRIC,
                    { formatFunction },
                );
                return [top, bottom];
            }),
        );
    }

    /**
     * Get the altitudes of the vertical guides of the altitude slider in metric units
     * @description Gets the axisLabelsStep from the flavor config, generates an array of numbers
     * from the lowerLimit to the upperLimit and converts that into an array of AltitudeGuide objects
     * @returns An observable with an array of AltitudeGuide objects
     */
    public getAltitudeGuides(): Rx.Observable<AltitudeGuide[]> {
        const getGuideAltitudes: (config: AltitudeSliderConfig) => number[] = (config) => {
            const guideAltitudes: number[] = [];
            for (
                let altitude = config.lowerBoundary;
                altitude <= config.upperBoundary;
                altitude += config.axisLabelsStep
            ) {
                guideAltitudes.push(altitude);
            }
            return guideAltitudes;
        };
        return this.flavorConfigObservable.pipe(
            RxOperators.map((config) => getGuideAltitudes(config)),
            RxOperators.map((guideAltitudes) => {
                return guideAltitudes.map((altitude) => ({
                    altitude: this.distanceFormatter.convertValueFromCurrentUnit(
                        altitude,
                        DistanceUnit.METRIC,
                        DistanceFormatFunction.ROUND_DECIMAL_2,
                    ),
                    label: this.distanceFormatter.formatValueWithCurrentUnit(
                        altitude,
                        this.distanceFormatter.selectedDistanceUnit,
                    ),
                }));
            }),
        );
    }

    /**
     * Get a map of the number of tracks at that altitude and
     * calculates the track density data for the slider graph
     *
     * ##### LEAVE THIS FUNCTION THE WAY IT IS #####
     *
     * If you decide to move this, make sure that you keep using the parameters in the same way
     * There are massive performance issues if you don't ;)
     *
     * @param altitudesDensityMap The map of altitudes to track density
     * @param step The configured step size for the slider handles
     * @returns An array of TrackDensityIndicatorsData items
     */
    public getTrackDensityIndicatorsData(
        altitudesDensityMap: Map<number, number>,
        step: number,
    ): TrackDensityIndicatorsData[] {
        const graphData = new Array<TrackDensityIndicatorsData>();
        altitudesDensityMap.forEach((value, altitudeIndex) => {
            const altitude = altitudeIndex * step + step / 2;
            graphData.push({ altitude, value });
        });
        return graphData;
    }

    // Private functions

    private subscribeToTrackUpdates(): void {
        const subscription = this.replayRepository.currentPlaybackScene
            .pipe(
                RxOperators.switchMap((scene) =>
                    scene == null ? nonNullObservable(this.trackRepository.tracksSnapshot) : scene.tracks,
                ),
            )
            .subscribe((snapshot) => this.processTrackSnapshot(snapshot));
        this.collectSubscription(subscription);
    }

    private subscribeToDistanceUnitChange(): void {
        const subscription = Rx.combineLatest([
            this.lowerBoundaryMetric,
            this.upperBoundaryMetric,
            this.stepSizeMetric,
        ]).subscribe(([min, max, step]) => {
            this.snapPreferenceToStep(LocalUserPreferenceKeys.filters.minAltitudeOfInterest, min, max, step);
            this.snapPreferenceToStep(LocalUserPreferenceKeys.filters.maxAltitudeOfInterest, min, max, step);
        });
        this.collectSubscription(subscription);
    }

    private snapPreferenceToStep(preferenceKey: string, min: number, max: number, step: number): void {
        const currentValue = this.localPreferencesRepository.getPreference<number>(preferenceKey);
        if (currentValue) {
            const newValue = Math.round(currentValue / step) * step;
            if (min <= newValue && newValue <= max) {
                this.localPreferencesRepository.setPreference(preferenceKey, newValue);
            } else {
                this.localPreferencesRepository.setPreference(preferenceKey, null);
            }
        }
    }

    private processTrackSnapshot(snapshot: TracksSnapshot): void {
        const tracks = Array.from(snapshot.tracks.values())
            .filter((value) => this.getClosestEstimateTo(value, snapshot.timestamp).classification != null)
            .filter((value) => value.hasRangeAndAzimuthCompatible());
        this.updateAltitudeToBirdDensity(tracks, snapshot.timestamp);
        this.updateAircraftAltitudes(tracks);
    }

    private updateAltitudeToBirdDensity(processedTracks: Track[], timestamp: long): void {
        const minAltitudeStep = Math.max(this.currentFlavorConfig.stepSize, 1);
        const altitudes = processedTracks
            .filter((value) => {
                const c = this.getClosestEstimateTo(value, timestamp).classification;
                return c && c.isBird;
            })
            .map((track) => this.getLatestAltitude(track, timestamp, VALID_TIME_OFFSET_OF_LAST_ALTITUDE_MILLIS))
            .filter((value) => value != null && !isNaN(value))
            .map((value) => Math.round(value!));

        const map = new Map<number, number>(); //<altitude, number of tracks>
        altitudes.forEach((altitude) => {
            const key = Math.round(altitude / minAltitudeStep);
            const current = map.get(key) || 0;
            map.set(key, current + 1);
        });
        this.altitudeToTrackDensityMapSubject.next(map);
    }

    private updateAircraftAltitudes(processedTracks: Track[]): void {
        const altitudes = processedTracks
            .filter((track) => track.isAirCraft)
            .map((track) => {
                const location = track.getCurrentLocation();
                if (location == null) {
                    return 0;
                }
                return Math.round(location.altitude);
            })
            .filter((altitude) => altitude > 0);
        this.aircraftAltitudesSubject.next(altitudes);
    }

    private getLatestAltitude(track: Track, snapshotTimestamp: long, validTimeOffset: long): float | null {
        const processedEstimates = _dropRightWhile(
            Array.from(track.estimates),
            (estimate) => estimate.timestamp > snapshotTimestamp,
        ).filter((estimate) => estimate != null);

        if (processedEstimates.length === 0) {
            return null;
        }

        const lastEstimation = processedEstimates[processedEstimates.length - 1];
        if (Math.abs(lastEstimation.timestamp - snapshotTimestamp) > validTimeOffset) {
            return null;
        }

        const lastLocation = lastEstimation.location;
        if (lastLocation == null) {
            return null;
        }
        return lastLocation.altitude;
    }

    private getClosestEstimateTo(track: Track, timestamp: long): Estimate {
        return track.getClosestEstimateTo(timestamp) || track.lastEstimate;
    }
}
