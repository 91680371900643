import * as mapboxgl from "mapbox-gl";
import "./LogoControl.css";

export class LogoControl implements mapboxgl.IControl {
    // Properties

    private container: HTMLElement | undefined;

    // Public functions

    public onAdd(): HTMLElement {
        this.container = document.createElement("div");
        this.container.className = "logo-control";
        return this.container;
    }

    public onRemove(): void {
        if (this.container && this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
        }
    }
}
