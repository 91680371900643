/**
 * @fileoverview gRPC-Web generated client stub for robin.proto3.rpcservice
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


import * as grpcWeb from 'grpc-web';

import * as common3_pb from './common3_pb';
import * as serverconfig3_pb from './serverconfig3_pb';
import * as loginresult3_pb from './loginresult3_pb';
import * as users3_pb from './users3_pb';
import * as deterrencedevices3_pb from './deterrencedevices3_pb';
import * as logger3_pb from './logger3_pb';
import * as userpreferences3_pb from './userpreferences3_pb';
import * as background3_pb from './background3_pb';
import * as tracklist3_pb from './tracklist3_pb';
import * as adsbflightlist3_pb from './adsbflightlist3_pb';
import * as clientlist3_pb from './clientlist3_pb';
import * as classificationlist3_pb from './classificationlist3_pb';
import * as overlaylist3_pb from './overlaylist3_pb';
import * as gridanalysis3_pb from './gridanalysis3_pb';
import * as atcinfo3_pb from './atcinfo3_pb';
import * as blankingsectors3_pb from './blankingsectors3_pb';
import * as radar3_pb from './radar3_pb';

export class RpcServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: string; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: string; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfogetServerConfig = new grpcWeb.AbstractClientBase.MethodInfo(
    serverconfig3_pb.ServerConfig,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    serverconfig3_pb.ServerConfig.deserializeBinary
  );

  getServerConfig(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: serverconfig3_pb.ServerConfig) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getServerConfig',
      request,
      metadata || {},
      this.methodInfogetServerConfig,
      callback);
  }

  methodInfologin = new grpcWeb.AbstractClientBase.MethodInfo(
    loginresult3_pb.LoginResult,
    (request: loginresult3_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    loginresult3_pb.LoginResult.deserializeBinary
  );

  login(
    request: loginresult3_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: loginresult3_pb.LoginResult) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/login',
      request,
      metadata || {},
      this.methodInfologin,
      callback);
  }

  methodInfologinBasicAuth = new grpcWeb.AbstractClientBase.MethodInfo(
    loginresult3_pb.BasicAuthLoginResult,
    (request: loginresult3_pb.BasicAuthLoginRequest) => {
      return request.serializeBinary();
    },
    loginresult3_pb.BasicAuthLoginResult.deserializeBinary
  );

  loginBasicAuth(
    request: loginresult3_pb.BasicAuthLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: loginresult3_pb.BasicAuthLoginResult) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/loginBasicAuth',
      request,
      metadata || {},
      this.methodInfologinBasicAuth,
      callback);
  }

  methodInfologinOpenIdConnect = new grpcWeb.AbstractClientBase.MethodInfo(
    loginresult3_pb.OpenIdConnectLoginResult,
    (request: loginresult3_pb.OpenIdConnectLoginRequest) => {
      return request.serializeBinary();
    },
    loginresult3_pb.OpenIdConnectLoginResult.deserializeBinary
  );

  loginOpenIdConnect(
    request: loginresult3_pb.OpenIdConnectLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: loginresult3_pb.OpenIdConnectLoginResult) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/loginOpenIdConnect',
      request,
      metadata || {},
      this.methodInfologinOpenIdConnect,
      callback);
  }

  methodInfologout = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.EmptyMsg,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    common3_pb.EmptyMsg.deserializeBinary
  );

  logout(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.EmptyMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/logout',
      request,
      metadata || {},
      this.methodInfologout,
      callback);
  }

  methodInfoaddUser = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.EmptyMsg,
    (request: users3_pb.User) => {
      return request.serializeBinary();
    },
    common3_pb.EmptyMsg.deserializeBinary
  );

  addUser(
    request: users3_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.EmptyMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/addUser',
      request,
      metadata || {},
      this.methodInfoaddUser,
      callback);
  }

  methodInfoeditUser = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.EmptyMsg,
    (request: users3_pb.User) => {
      return request.serializeBinary();
    },
    common3_pb.EmptyMsg.deserializeBinary
  );

  editUser(
    request: users3_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.EmptyMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/editUser',
      request,
      metadata || {},
      this.methodInfoeditUser,
      callback);
  }

  methodInfodeleteUser = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.EmptyMsg,
    (request: users3_pb.User) => {
      return request.serializeBinary();
    },
    common3_pb.EmptyMsg.deserializeBinary
  );

  deleteUser(
    request: users3_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.EmptyMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/deleteUser',
      request,
      metadata || {},
      this.methodInfodeleteUser,
      callback);
  }

  methodInfogetUsers = new grpcWeb.AbstractClientBase.MethodInfo(
    users3_pb.UserList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    users3_pb.UserList.deserializeBinary
  );

  getUsers(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: users3_pb.UserList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getUsers',
      request,
      metadata || {},
      this.methodInfogetUsers,
      callback);
  }

  methodInfogetUserRoles = new grpcWeb.AbstractClientBase.MethodInfo(
    users3_pb.UserRoleList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    users3_pb.UserRoleList.deserializeBinary
  );

  getUserRoles(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: users3_pb.UserRoleList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getUserRoles',
      request,
      metadata || {},
      this.methodInfogetUserRoles,
      callback);
  }

  methodInfosetUserPreference = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.StatusMsg,
    (request: userpreferences3_pb.UserPreferenceItem) => {
      return request.serializeBinary();
    },
    common3_pb.StatusMsg.deserializeBinary
  );

  setUserPreference(
    request: userpreferences3_pb.UserPreferenceItem,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.StatusMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/setUserPreference',
      request,
      metadata || {},
      this.methodInfosetUserPreference,
      callback);
  }

  methodInfogetUserPreferences = new grpcWeb.AbstractClientBase.MethodInfo(
    userpreferences3_pb.UserPreferences,
    (request: userpreferences3_pb.UserPreferencesRequest) => {
      return request.serializeBinary();
    },
    userpreferences3_pb.UserPreferences.deserializeBinary
  );

  getUserPreferences(
    request: userpreferences3_pb.UserPreferencesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: userpreferences3_pb.UserPreferences) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getUserPreferences',
      request,
      metadata || {},
      this.methodInfogetUserPreferences,
      callback);
  }

  methodInfogetTileProviderList = new grpcWeb.AbstractClientBase.MethodInfo(
    background3_pb.TileProviderResponse,
    (request: background3_pb.TileProviderRequest) => {
      return request.serializeBinary();
    },
    background3_pb.TileProviderResponse.deserializeBinary
  );

  getTileProviderList(
    request: background3_pb.TileProviderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: background3_pb.TileProviderResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getTileProviderList',
      request,
      metadata || {},
      this.methodInfogetTileProviderList,
      callback);
  }

  methodInfogetBackgroundTile = new grpcWeb.AbstractClientBase.MethodInfo(
    background3_pb.BackgroundTileResponse,
    (request: background3_pb.BackgroundTileRequest) => {
      return request.serializeBinary();
    },
    background3_pb.BackgroundTileResponse.deserializeBinary
  );

  getBackgroundTile(
    request: background3_pb.BackgroundTileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: background3_pb.BackgroundTileResponse) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getBackgroundTile',
      request,
      metadata || {},
      this.methodInfogetBackgroundTile,
      callback);
  }

  methodInfogetOverlayList = new grpcWeb.AbstractClientBase.MethodInfo(
    overlaylist3_pb.OverlayList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    overlaylist3_pb.OverlayList.deserializeBinary
  );

  getOverlayList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: overlaylist3_pb.OverlayList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getOverlayList',
      request,
      metadata || {},
      this.methodInfogetOverlayList,
      callback);
  }

  methodInfogetInitialTrackData = new grpcWeb.AbstractClientBase.MethodInfo(
    tracklist3_pb.TrackData,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    tracklist3_pb.TrackData.deserializeBinary
  );

  getInitialTrackData(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: tracklist3_pb.TrackData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getInitialTrackData',
      request,
      metadata || {},
      this.methodInfogetInitialTrackData,
      callback);
  }

  methodInfogetTrackDataUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    tracklist3_pb.TrackDataUpdates,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    tracklist3_pb.TrackDataUpdates.deserializeBinary
  );

  getTrackDataUpdates(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: tracklist3_pb.TrackDataUpdates) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getTrackDataUpdates',
      request,
      metadata || {},
      this.methodInfogetTrackDataUpdates,
      callback);
  }

  methodInfogetReplayTrackData = new grpcWeb.AbstractClientBase.MethodInfo(
    tracklist3_pb.TrackData,
    (request: tracklist3_pb.ReplayTrackDataRequest) => {
      return request.serializeBinary();
    },
    tracklist3_pb.TrackData.deserializeBinary
  );

  getReplayTrackData(
    request: tracklist3_pb.ReplayTrackDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: tracklist3_pb.TrackData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getReplayTrackData',
      request,
      metadata || {},
      this.methodInfogetReplayTrackData,
      callback);
  }

  methodInfogetTracksHistogram = new grpcWeb.AbstractClientBase.MethodInfo(
    tracklist3_pb.TracksHistogram,
    (request: tracklist3_pb.TracksHistogramRequest) => {
      return request.serializeBinary();
    },
    tracklist3_pb.TracksHistogram.deserializeBinary
  );

  getTracksHistogram(
    request: tracklist3_pb.TracksHistogramRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: tracklist3_pb.TracksHistogram) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getTracksHistogram',
      request,
      metadata || {},
      this.methodInfogetTracksHistogram,
      callback);
  }

  methodInfogetInitialFlightList = new grpcWeb.AbstractClientBase.MethodInfo(
    adsbflightlist3_pb.AdsbFlightList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    adsbflightlist3_pb.AdsbFlightList.deserializeBinary
  );

  getInitialFlightList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: adsbflightlist3_pb.AdsbFlightList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getInitialFlightList',
      request,
      metadata || {},
      this.methodInfogetInitialFlightList,
      callback);
  }

  methodInfogetFlightListUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    adsbflightlist3_pb.AdsbFlightUpdatesCollection,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    adsbflightlist3_pb.AdsbFlightUpdatesCollection.deserializeBinary
  );

  getFlightListUpdates(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: adsbflightlist3_pb.AdsbFlightUpdatesCollection) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getFlightListUpdates',
      request,
      metadata || {},
      this.methodInfogetFlightListUpdates,
      callback);
  }

  methodInfogetClassificationList = new grpcWeb.AbstractClientBase.MethodInfo(
    classificationlist3_pb.ClassificationList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    classificationlist3_pb.ClassificationList.deserializeBinary
  );

  getClassificationList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: classificationlist3_pb.ClassificationList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getClassificationList',
      request,
      metadata || {},
      this.methodInfogetClassificationList,
      callback);
  }

  methodInfogetClientList = new grpcWeb.AbstractClientBase.MethodInfo(
    clientlist3_pb.ClientList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    clientlist3_pb.ClientList.deserializeBinary
  );

  getClientList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: clientlist3_pb.ClientList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getClientList',
      request,
      metadata || {},
      this.methodInfogetClientList,
      callback);
  }

  methodInfosetDevicePosition = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.EmptyMsg,
    (request: clientlist3_pb.DevicePosition) => {
      return request.serializeBinary();
    },
    common3_pb.EmptyMsg.deserializeBinary
  );

  setDevicePosition(
    request: clientlist3_pb.DevicePosition,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.EmptyMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/setDevicePosition',
      request,
      metadata || {},
      this.methodInfosetDevicePosition,
      callback);
  }

  methodInfogetGridAnalysisList = new grpcWeb.AbstractClientBase.MethodInfo(
    gridanalysis3_pb.GridAnalysisList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    gridanalysis3_pb.GridAnalysisList.deserializeBinary
  );

  getGridAnalysisList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: gridanalysis3_pb.GridAnalysisList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getGridAnalysisList',
      request,
      metadata || {},
      this.methodInfogetGridAnalysisList,
      callback);
  }

  methodInfogetGridAnalysisData = new grpcWeb.AbstractClientBase.MethodInfo(
    gridanalysis3_pb.GridAnalysisData,
    (request: gridanalysis3_pb.GridAnalysisDataRequest) => {
      return request.serializeBinary();
    },
    gridanalysis3_pb.GridAnalysisData.deserializeBinary
  );

  getGridAnalysisData(
    request: gridanalysis3_pb.GridAnalysisDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: gridanalysis3_pb.GridAnalysisData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getGridAnalysisData',
      request,
      metadata || {},
      this.methodInfogetGridAnalysisData,
      callback);
  }

  methodInfohandleDeterrenceCommand = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.StatusMsg,
    (request: deterrencedevices3_pb.DeterrenceCommandMsg) => {
      return request.serializeBinary();
    },
    common3_pb.StatusMsg.deserializeBinary
  );

  handleDeterrenceCommand(
    request: deterrencedevices3_pb.DeterrenceCommandMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.StatusMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/handleDeterrenceCommand',
      request,
      metadata || {},
      this.methodInfohandleDeterrenceCommand,
      callback);
  }

  methodInfogetDeterrenceDeviceList = new grpcWeb.AbstractClientBase.MethodInfo(
    deterrencedevices3_pb.DeterrenceDeviceList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    deterrencedevices3_pb.DeterrenceDeviceList.deserializeBinary
  );

  getDeterrenceDeviceList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: deterrencedevices3_pb.DeterrenceDeviceList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getDeterrenceDeviceList',
      request,
      metadata || {},
      this.methodInfogetDeterrenceDeviceList,
      callback);
  }

  methodInfogetDeterrenceDeviceUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    deterrencedevices3_pb.DeterrenceDeviceUpdateList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    deterrencedevices3_pb.DeterrenceDeviceUpdateList.deserializeBinary
  );

  getDeterrenceDeviceUpdates(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: deterrencedevices3_pb.DeterrenceDeviceUpdateList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getDeterrenceDeviceUpdates',
      request,
      metadata || {},
      this.methodInfogetDeterrenceDeviceUpdates,
      callback);
  }

  methodInfogetDeterrenceEventsList = new grpcWeb.AbstractClientBase.MethodInfo(
    deterrencedevices3_pb.DeterrenceEventsList,
    (request: deterrencedevices3_pb.DeterrenceEventsListRequest) => {
      return request.serializeBinary();
    },
    deterrencedevices3_pb.DeterrenceEventsList.deserializeBinary
  );

  getDeterrenceEventsList(
    request: deterrencedevices3_pb.DeterrenceEventsListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: deterrencedevices3_pb.DeterrenceEventsList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getDeterrenceEventsList',
      request,
      metadata || {},
      this.methodInfogetDeterrenceEventsList,
      callback);
  }

  methodInfogetDeterrenceEventUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    deterrencedevices3_pb.DeterrenceEventsList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    deterrencedevices3_pb.DeterrenceEventsList.deserializeBinary
  );

  getDeterrenceEventUpdates(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: deterrencedevices3_pb.DeterrenceEventsList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getDeterrenceEventUpdates',
      request,
      metadata || {},
      this.methodInfogetDeterrenceEventUpdates,
      callback);
  }

  methodInfohandleLoggerCommand = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.StatusMsg,
    (request: logger3_pb.LoggerCommandMsg) => {
      return request.serializeBinary();
    },
    common3_pb.StatusMsg.deserializeBinary
  );

  handleLoggerCommand(
    request: logger3_pb.LoggerCommandMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.StatusMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/handleLoggerCommand',
      request,
      metadata || {},
      this.methodInfohandleLoggerCommand,
      callback);
  }

  methodInfogetLoggerSettingsList = new grpcWeb.AbstractClientBase.MethodInfo(
    logger3_pb.LoggerSettingsList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    logger3_pb.LoggerSettingsList.deserializeBinary
  );

  getLoggerSettingsList(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: logger3_pb.LoggerSettingsList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getLoggerSettingsList',
      request,
      metadata || {},
      this.methodInfogetLoggerSettingsList,
      callback);
  }

  methodInfogetLoggerInspectionTimerUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    logger3_pb.InspectionTimerUpdates,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    logger3_pb.InspectionTimerUpdates.deserializeBinary
  );

  getLoggerInspectionTimerUpdates(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: logger3_pb.InspectionTimerUpdates) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getLoggerInspectionTimerUpdates',
      request,
      metadata || {},
      this.methodInfogetLoggerInspectionTimerUpdates,
      callback);
  }

  methodInfogetLoggerReportList = new grpcWeb.AbstractClientBase.MethodInfo(
    logger3_pb.LoggerReportList,
    (request: logger3_pb.LoggerReportRequest) => {
      return request.serializeBinary();
    },
    logger3_pb.LoggerReportList.deserializeBinary
  );

  getLoggerReportList(
    request: logger3_pb.LoggerReportRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: logger3_pb.LoggerReportList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getLoggerReportList',
      request,
      metadata || {},
      this.methodInfogetLoggerReportList,
      callback);
  }

  methodInfogetLoggerReportUpdates = new grpcWeb.AbstractClientBase.MethodInfo(
    logger3_pb.LoggerReportList,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    logger3_pb.LoggerReportList.deserializeBinary
  );

  getLoggerReportUpdates(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: logger3_pb.LoggerReportList) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getLoggerReportUpdates',
      request,
      metadata || {},
      this.methodInfogetLoggerReportUpdates,
      callback);
  }

  methodInfogetServerTime = new grpcWeb.AbstractClientBase.MethodInfo(
    logger3_pb.TimeMsg,
    (request: common3_pb.EmptyMsg) => {
      return request.serializeBinary();
    },
    logger3_pb.TimeMsg.deserializeBinary
  );

  getServerTime(
    request: common3_pb.EmptyMsg,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: logger3_pb.TimeMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getServerTime',
      request,
      metadata || {},
      this.methodInfogetServerTime,
      callback);
  }

  methodInfogetAtcAlertData = new grpcWeb.AbstractClientBase.MethodInfo(
    atcinfo3_pb.AtcAlertData,
    (request: atcinfo3_pb.AtcAlertRequest) => {
      return request.serializeBinary();
    },
    atcinfo3_pb.AtcAlertData.deserializeBinary
  );

  getAtcAlertData(
    request: atcinfo3_pb.AtcAlertRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: atcinfo3_pb.AtcAlertData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getAtcAlertData',
      request,
      metadata || {},
      this.methodInfogetAtcAlertData,
      callback);
  }

  methodInfogetAirbaseData = new grpcWeb.AbstractClientBase.MethodInfo(
    atcinfo3_pb.AirbaseData,
    (request: atcinfo3_pb.AirbaseRequest) => {
      return request.serializeBinary();
    },
    atcinfo3_pb.AirbaseData.deserializeBinary
  );

  getAirbaseData(
    request: atcinfo3_pb.AirbaseRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: atcinfo3_pb.AirbaseData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getAirbaseData',
      request,
      metadata || {},
      this.methodInfogetAirbaseData,
      callback);
  }

  methodInfogetRunwayTrafficData = new grpcWeb.AbstractClientBase.MethodInfo(
    atcinfo3_pb.RunwayTrafficData,
    (request: atcinfo3_pb.RunwayTrafficRequest) => {
      return request.serializeBinary();
    },
    atcinfo3_pb.RunwayTrafficData.deserializeBinary
  );

  getRunwayTrafficData(
    request: atcinfo3_pb.RunwayTrafficRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: atcinfo3_pb.RunwayTrafficData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getRunwayTrafficData',
      request,
      metadata || {},
      this.methodInfogetRunwayTrafficData,
      callback);
  }

  methodInfogetTracksOnRunwayData = new grpcWeb.AbstractClientBase.MethodInfo(
    atcinfo3_pb.TracksOnRunwayData,
    (request: atcinfo3_pb.TracksOnRunwayRequest) => {
      return request.serializeBinary();
    },
    atcinfo3_pb.TracksOnRunwayData.deserializeBinary
  );

  getTracksOnRunwayData(
    request: atcinfo3_pb.TracksOnRunwayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: atcinfo3_pb.TracksOnRunwayData) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getTracksOnRunwayData',
      request,
      metadata || {},
      this.methodInfogetTracksOnRunwayData,
      callback);
  }

  methodInfogetRadarStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    radar3_pb.Status,
    (request: radar3_pb.GetStatusRequest) => {
      return request.serializeBinary();
    },
    radar3_pb.Status.deserializeBinary
  );

  getRadarStatus(
    request: radar3_pb.GetStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: radar3_pb.Status) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getRadarStatus',
      request,
      metadata || {},
      this.methodInfogetRadarStatus,
      callback);
  }

  methodInfogetBlankingSectors = new grpcWeb.AbstractClientBase.MethodInfo(
    blankingsectors3_pb.List,
    (request: blankingsectors3_pb.GetRequest) => {
      return request.serializeBinary();
    },
    blankingsectors3_pb.List.deserializeBinary
  );

  getBlankingSectors(
    request: blankingsectors3_pb.GetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: blankingsectors3_pb.List) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/getBlankingSectors',
      request,
      metadata || {},
      this.methodInfogetBlankingSectors,
      callback);
  }

  methodInfosetBlankingSectors = new grpcWeb.AbstractClientBase.MethodInfo(
    common3_pb.EmptyMsg,
    (request: blankingsectors3_pb.SetRequest) => {
      return request.serializeBinary();
    },
    common3_pb.EmptyMsg.deserializeBinary
  );

  setBlankingSectors(
    request: blankingsectors3_pb.SetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: common3_pb.EmptyMsg) => void) {
    return this.client_.rpcCall(
      this.hostname_ +
        '/robin.proto3.rpcservice.RpcService/setBlankingSectors',
      request,
      metadata || {},
      this.methodInfosetBlankingSectors,
      callback);
  }

}

