import React, { useEffect, useRef, useState } from "react";
import * as Rx from "rxjs";
import { TYPES } from "../../../../../di/Types";
import { useViewModel } from "../../../../../hooks/useViewModel";
import {
    NO_UPDATES_NOTIFICATION_THRESHOLD_SECONDS,
    TimeDifferenceUpdate,
    TimeSyncStatusIndicatorViewModel,
} from "./TimeSyncStatusIndicatorViewModel";
import { t } from "i18next";
import { showWarning } from "../../../../../utils/MessageUtils";
import { Icon } from "../../../../appearance/Icon";

enum TimeSyncState {
    outOfSync,
    inSync,
}

// this is used to change and update the same notification.
const toastId = "timeSyncStatusIndicator";

export const TimeSyncStatusIndicator = (): JSX.Element | null => {
    // Properties

    const viewModel: TimeSyncStatusIndicatorViewModel = useViewModel(TYPES.TimeSyncStatusIndicatorViewModel);
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const [lastUpdateTimeDifferenceText, setLastUpdateTimeDifferenceText] = useState("");
    const [timeSyncState, setTimeSyncState] = useState<TimeSyncState | null>(null);

    useEffect(() => {
        const subscription = new Rx.Subscription();
        subscription.add(
            viewModel.lastUpdateTimeDifferenceText.subscribe({
                next: (update) => {
                    setLastUpdateTimeDifferenceText(update.text);
                    handleTimeSyncState(update.difference > 2);
                    toggleTimeDiffNotification(update);
                },
                error: (error) => console.error(error),
            }),
        );
        return () => {
            subscription.unsubscribe();
            if (timerRef.current) {
                clearTimeout(timerRef.current!);
            }
        };
    }, []);

    // Private functions

    const toggleTimeDiffNotification = (update: TimeDifferenceUpdate): void => {
        if (update.difference < NO_UPDATES_NOTIFICATION_THRESHOLD_SECONDS) {
            return;
        }
        showWarning({
            id: toastId,
            message: t("status.notReceivingTracksNotification", { timeDiffText: update.text }),
            duration: Infinity,
            onClick: () => window.location.reload(),
            label: t("status.clickToRefresh"),
            showCloseButton: true,
        });
    };

    const handleTimeSyncState = (isOutOfSync: boolean): void => {
        if (isOutOfSync) {
            setTimeSyncState(TimeSyncState.outOfSync);
            // Cancel running timers if necessary
            clearTimeout(timerRef.current!);
        } else {
            if (timeSyncState === TimeSyncState.outOfSync) {
                setTimeSyncState(TimeSyncState.inSync);
                // Hide icon after 3 seconds
                timerRef.current = setTimeout(() => setTimeSyncState(null), 3000);
            }
        }
    };

    // Render

    if (!timeSyncState) {
        return null;
    }

    return (
        <Icon
            color={timeSyncState === TimeSyncState.inSync ? "info" : "danger"}
            iconType="TRACK"
            large={true}
            tooltip={
                timeSyncState === TimeSyncState.inSync
                    ? t("status.receivingTracks")
                    : t("status.notReceivingTracks", { timeDiffText: lastUpdateTimeDifferenceText })
            }
            tooltipPosition="left"
        />
    );
};
