import React from "react";
import { FullscreenBrandedPage } from "../appearance/FullscreenBrandedPage";
import { Login } from "./Login";

export const LoginPage = (): JSX.Element | null => {
    // Render

    return (
        <FullscreenBrandedPage>
            <Login />
        </FullscreenBrandedPage>
    );
};
