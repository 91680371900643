import { LatLonAltPosition as GeoLocationProto } from "./proto/generated/common3_pb";
import { CoordinateJSON } from "./json";
import { Coord } from "@turf/helpers";
import { LngLatLike } from "mapbox-gl";

export class Location {
    // Static functions

    public static fromProto(model: GeoLocationProto | undefined): Location {
        if (model === undefined) {
            return new Location();
        }
        return new Location(model.getLatitude(), model.getLongitude(), model.getAltitude());
    }

    public static fromJson(model: CoordinateJSON): Location {
        return new Location(model[1], model[0], model[2]);
    }

    // Public functions

    public constructor(
        public readonly latitude: double = 0.0,
        public readonly longitude: double = 0.0,
        public readonly altitude: float = 0.0,
    ) {}

    public getRelativeAltitude(reference: float): float {
        return Math.max(0, this.altitude - reference);
    }

    public toGeoJSONLocation(): number[] {
        return [this.longitude, this.latitude, this.altitude];
    }

    public equals(location: Location): boolean {
        return (
            location &&
            this.latitude === location.latitude &&
            this.longitude === location.longitude &&
            this.altitude === location.altitude
        );
    }
}

export function locationToJson(location: Location): CoordinateJSON {
    return [location.longitude, location.latitude, location.altitude];
}

export function locationToCoord(location: Location): Coord {
    return locationToJson(location);
}

export function locationToGeoLocationProto(location: Location): GeoLocationProto {
    const position = new GeoLocationProto();
    position.setLatitude(location.latitude);
    position.setLongitude(location.longitude);
    position.setAltitude(isNaN(location.altitude) ? 0 : location.altitude);
    return position;
}

export function locationToLngLatLike(location: Location): LngLatLike {
    return [location.longitude, location.latitude];
}
