import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    UserPreferenceItem,
    UserPreferences as UserPreferencesProto,
    UserPreferencesRequest,
} from "../../../../domain/model/proto/generated/userpreferences3_pb";
import { AbstractStartableRepository } from "../../../../domain/repositories";
import { PreferencesMap } from "../../../../domain/model";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { ServerPreferencesRepository } from "../../../../domain/repositories/ServerPreferencesRepository";

export class BirdViewerServerPreferencesRepository
    extends AbstractStartableRepository
    implements ServerPreferencesRepository
{
    // Properties

    public readonly preferencesMap: Rx.Observable<PreferencesMap>;
    private readonly preferencesMapSubject: Rx.BehaviorSubject<PreferencesMap | null> = new Rx.BehaviorSubject(
        null as PreferencesMap | null,
    );
    private subscriptions: Rx.Subscription | undefined;

    public constructor(private readonly api: BirdViewerAPI) {
        super();
        this.preferencesMap = nonNullObservable(this.preferencesMapSubject.asObservable());
    }

    // Public functions

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.fetchPreferences();
    }

    public stop(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    public getPreferencesMap(): PreferencesMap {
        return this.preferencesMapSubject.getValue() || new Map();
    }

    public setPreference(name: string, value: string): Rx.Observable<void> {
        const request = new UserPreferenceItem();
        request.setName(name);
        request.setValue(value);
        return this.api.setUserPreference(request).pipe(
            RxOperators.tap(() => {
                const preferencesMap = this.getPreferencesMap();
                preferencesMap.set(name, value);
                this.preferencesMapSubject.next(preferencesMap);
            }),
            RxOperators.ignoreElements(),
        );
    }

    public observePreference<T>(name: string): Rx.Observable<T> {
        return nonNullObservable(
            this.preferencesMap.pipe(
                RxOperators.filter((map) => map.has(name)),
                RxOperators.map((item) => {
                    const value = item.get(name).toLowerCase();
                    if (value == null || value === "") {
                        return null;
                    }
                    return JSON.parse(value) as T;
                }),
            ),
        );
    }

    // Private functions

    private fetchPreferences(): void {
        const request = new UserPreferencesRequest();
        const subscription = this.api.getUserPreferences(request).subscribe(
            (response) => this.handleFetchPreferencesResponse(response),
            (error) => console.error(error),
        );
        this.subscriptions!.add(subscription);
    }

    private handleFetchPreferencesResponse(response: UserPreferencesProto): void {
        const items = response.getPreferenceitemList();
        const newMap: PreferencesMap = new Map();
        items.forEach((item) => newMap.set(item.getName(), item.getValue()));
        this.preferencesMapSubject.next(newMap);
    }
}
