import { Colors } from "../../appearance/Colors";

const ICON_RADIUS = 8;
const ICON_BORDER_WIDTH = 3;

export class UserLocationImage {
    public width = (ICON_RADIUS + ICON_BORDER_WIDTH) * 2;
    public height = (ICON_RADIUS + ICON_BORDER_WIDTH) * 2;
    public data: Uint8Array | Uint8ClampedArray = new Uint8ClampedArray(this.width * this.height * 4);

    private context: CanvasRenderingContext2D | null = null;
    private cx = this.width / 2;
    private cy = this.height / 2;

    private isRendered = false;

    public constructor(private map: mapboxgl.Map) {}

    public onAdd(): void {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
    }

    // Called once before every frame where the icon will be used
    public render(): boolean {
        const ctx = this.context;
        if (ctx == null || this.isRendered) {
            return false;
        }

        // Clear previous render
        ctx.clearRect(0, 0, this.width, this.height);

        // Draw location icon
        ctx.beginPath();
        ctx.arc(this.cx, this.cy, ICON_RADIUS, 0, Math.PI * 2);
        ctx.strokeStyle = Colors.secondary.white;
        ctx.lineWidth = ICON_BORDER_WIDTH;
        ctx.stroke();
        ctx.fillStyle = Colors.secondary.blue;
        ctx.fill();

        this.data = ctx.getImageData(0, 0, this.width, this.height).data;

        // Set isRendered true so we don't re-render
        this.isRendered = true;

        // Return `true` to let the map know that the image was updated
        return true;
    }
}
