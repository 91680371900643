import React, { FunctionComponent } from "react";
import { Redirect } from "react-router";

export function DevRedirectHoC<T>(Component: React.ComponentType<T>, redirectPath = "/"): React.ComponentType<T> {
    const WrappedComponent: FunctionComponent<T> = (props: T) => {
        if (process.env.NODE_ENV === "development") {
            return <Component {...props} />;
        }
        return <Redirect to={redirectPath} />;
    };
    // This is needed to set the name of the component so that devtools can display the correct name
    WrappedComponent.displayName = `DevRedirectHoC(${Component.name})`;

    return WrappedComponent;
}
