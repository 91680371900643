import React from "react";
import { FormatterFunction } from "../../../../domain/DistanceFormatter";
import { MeasurementPoint, Track } from "../../../../domain/model";
import { getComponentFromClassification } from "../../../legend/LegendIcon";
import { AttributeList, AttributeListItem, AttributeListItemWithIcon, AttributeListSpace } from "./Attributes";
import { DistanceToMeasurementPoints } from "./DistanceToMeasurementPoints";
import { t } from "i18next";

interface RadarTrackInfoProps {
    formatAltitude(altitude: number): string | null;
    formatVelocity(velocity: number): string;
    formatValueWithCurrentUnit: FormatterFunction;
    track: Track;
    measurementPoints: MeasurementPoint[];
    lastSnapshotTime: number;
    radarGroundLevel: null | number;
    userAltitude: null | number;
}

export const RadarTrackInfo = ({
    formatAltitude,
    formatVelocity,
    formatValueWithCurrentUnit,
    track,
    measurementPoints,
    lastSnapshotTime,
    radarGroundLevel,
    userAltitude,
}: RadarTrackInfoProps): JSX.Element | null => {
    const lastEstimate = track.getClosestEstimateTo(lastSnapshotTime) || track.lastEstimate;
    const location = lastEstimate.location;
    if (location == null) {
        return null;
    }

    const lat = location.latitude.toFixed(7);
    const lon = location.longitude.toFixed(7);
    const alt = formatAltitude(location.altitude) ?? undefined;
    const altGround =
        (radarGroundLevel !== null ? formatAltitude(location.altitude - radarGroundLevel) : null) ?? undefined;
    const altUser = (userAltitude !== null ? formatAltitude(location.altitude - userAltitude) : null) ?? undefined;
    const c = lastEstimate.classification;

    const velocity = formatVelocity(lastEstimate.velocity);

    const alarmRepresentation = lastEstimate.alarms.length > 0 ? t("general.booleanTrue") : t("general.booleanFalse");

    return (
        <AttributeList>
            <AttributeListSpace />
            {c && (
                <AttributeListItemWithIcon
                    label={`${c.prettyName} ${t("general.track")} ${
                        track.isFinished ? `(${t("general.finished")})` : ""
                    }`}
                    icon={getComponentFromClassification(c, 1)!}
                    boldLabel
                />
            )}
            <AttributeListItem label={t("map.id")} value={track.id} />

            <AttributeListSpace />
            <AttributeListItem label={t("map.position")} boldLabel />
            <AttributeList>
                <AttributeListItem label={t("map.latitude_shorthand")} value={lat} />
                <AttributeListItem label={t("map.longitude_shorthand")} value={lon} />
            </AttributeList>
            {alt && <AttributeListSpace />}
            <AttributeListItem condition={alt !== undefined} label={t("map.altitudeRelative")} boldLabel />
            <AttributeList>
                <AttributeListItem condition={alt !== undefined} label={t("altitudeExplanation.wgs84")} value={alt} />
                <AttributeListItem
                    condition={altGround !== undefined}
                    label={t("altitudeExplanation.groundLevel")}
                    value={altGround}
                />
                <AttributeListItem
                    condition={altUser !== undefined}
                    label={t("altitudeExplanation.userLocation")}
                    value={altUser}
                />
            </AttributeList>
            <DistanceToMeasurementPoints
                formatValueWithCurrentUnit={formatValueWithCurrentUnit}
                trackPosition={location.toGeoJSONLocation()}
                measurementPoints={measurementPoints}
            />

            <AttributeListSpace />
            <AttributeListItem condition={velocity != null} label={t("map.velocity")} value={velocity} />
            <AttributeListItem label={t("map.alarm")} value={alarmRepresentation} />
            <AttributeListItem condition={c != null} label={t("map.classification")} value={() => c!.name} />
            <AttributeListItem
                condition={lastEstimate.rcs != null}
                label={t("map.rcs")}
                value={() => lastEstimate.rcs!.toFixed(1)}
            />

            <RadarTrackADSBInfo track={track} />
        </AttributeList>
    );
};

interface RadarTrackADSBInfoProps {
    track: Track;
}

const RadarTrackADSBInfo = ({ track }: RadarTrackADSBInfoProps): JSX.Element => {
    const flightInfo = track.flightInfo;
    if (!flightInfo) {
        if (!track.icao) {
            return <></>;
        }

        return (
            <>
                <AttributeListSpace />
                <AttributeListItem label={t("map.adsb")} boldLabel />
                <AttributeListItem label={t("map.adsbICAO")} value={track.icao} />
            </>
        );
    }

    const isFriendlyText = flightInfo.isFriendly ? t("general.yes") : t("general.no");
    return (
        <>
            <AttributeListSpace />
            <AttributeListItem label={t("map.adsb")} boldLabel />
            <AttributeListItem label={t("map.adsbICAO")} value={flightInfo.icao} />
            <AttributeListItem label={t("map.adsbFlightId")} value={flightInfo.flightId} />
            <AttributeListItem label={t("map.adsbDescription")} value={flightInfo.adsbDescription} />
            <AttributeListItem label={t("map.adsbIsFriendly")} value={isFriendlyText} />
        </>
    );
};
