import { AlarmRepository } from "../../../../domain/repositories";
import { SettingItemIcon } from "../../SettingItemIcon";
import { ActionItemViewModel } from "../../SettingItemViewModel";
import { t } from "i18next";
import AlertIcon from "../../../../res/images/settings/sound.svg";
import * as RxOperators from "rxjs/operators";

export class AlarmSoundTestViewModel extends ActionItemViewModel {
    public constructor(alarmRepository: AlarmRepository) {
        super(
            t("settings.alarmTestSound"),
            SettingItemIcon.withUrl(AlertIcon),
            () => alarmRepository.test(),
            alarmRepository.activeTest.pipe(
                RxOperators.map((playing) =>
                    playing ? t("settings.alarmTestSoundIsPlaying") : t("settings.alarmPlayTestSound"),
                ),
                RxOperators.map((value) => value.toUpperCase()),
            ),
        );
    }
}
