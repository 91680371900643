import { AdsbFlight } from "./proto/generated/adsbflightlist3_pb";

export enum EmitterCategory {
    // AIRCRAFT
    LIGHTAIRCRAFT = 1,
    SMALLAIRCRAFT = 2,
    LARGEAIRCRAFT = 3,
    HIGHVORTEXLARGEAIRCRAFT = 4,
    HEAVYAIRCRAFT = 5,
    HIGHPERFORMANCEAIRCRAFT = 6,
    LIGHTERTHANAIR = 12,
    AIRCRAFT = 31,
    // ROTORCRAFT
    ROTORCRAFT = 10,
    // VEHICLE
    SURFACEEMERGENCYVEHICLE = 20,
    SURFACESERVICEVEHICLE = 21,
    KNOWN_VEHICLE = 33,
    VEHICLE = 32,
    // DRONE
    UNMANNEDAERIALVEHICLE = 13,
    // OTHER
    GLIDER = 11,
    SPACEVEHICLE = 14,
    ULTRALIGHT = 15,
    PARACHUTIST = 16,
    POINTOBSTACLE = 22,
    CLUSTEROBSTACLE = 23,
    LINEOBSTACLE = 24,
    NONE = 0,
}

export function emitterCategoryFromProto(emitterCategory: AdsbFlight.EmitterCategory | null): EmitterCategory {
    if (emitterCategory == null) {
        return EmitterCategory.NONE;
    }
    const keys = Object.keys(EmitterCategory).filter((key) => isNaN(Number(key))) as Array<
        keyof typeof EmitterCategory
    >;

    for (const key of keys) {
        if (EmitterCategory[key] === emitterCategory.valueOf()) {
            return EmitterCategory[key];
        }
    }
    return EmitterCategory.NONE;
}

export function emitterCategoryFromJSON(emitterCategory: number | null): EmitterCategory {
    if (emitterCategory == null || emitterCategory < 0 || emitterCategory >= Object.keys(EmitterCategory).length / 2) {
        return EmitterCategory.NONE;
    }

    return emitterCategory as EmitterCategory;
}

export function getAllEmitterCategories(): EmitterCategory[] {
    return Object.keys(EmitterCategory)
        .map((key) => key as keyof typeof EmitterCategory)
        .map((ec) => EmitterCategory[ec])
        .filter((v) => !isNaN(v));
}

export enum EmitterCategoryGroup {
    AircraftEmitterCategory,
    VehicleEmitterCategory,
    RotorcraftEmitterCategory,
    DroneEmitterCategory,
    OtherEmitterCategory,
}

export function getEmitterCategoryGroup(category: EmitterCategory): EmitterCategoryGroup {
    switch (category) {
        case EmitterCategory.LIGHTAIRCRAFT:
        case EmitterCategory.SMALLAIRCRAFT:
        case EmitterCategory.LARGEAIRCRAFT:
        case EmitterCategory.HIGHVORTEXLARGEAIRCRAFT:
        case EmitterCategory.HEAVYAIRCRAFT:
        case EmitterCategory.HIGHPERFORMANCEAIRCRAFT:
        case EmitterCategory.AIRCRAFT:
            return EmitterCategoryGroup.AircraftEmitterCategory;
        case EmitterCategory.ROTORCRAFT:
            return EmitterCategoryGroup.RotorcraftEmitterCategory;
        case EmitterCategory.SURFACEEMERGENCYVEHICLE:
        case EmitterCategory.SURFACESERVICEVEHICLE:
        case EmitterCategory.KNOWN_VEHICLE:
        case EmitterCategory.VEHICLE:
            return EmitterCategoryGroup.VehicleEmitterCategory;
        case EmitterCategory.UNMANNEDAERIALVEHICLE:
            return EmitterCategoryGroup.DroneEmitterCategory;
        case EmitterCategory.GLIDER:
        case EmitterCategory.SPACEVEHICLE:
        case EmitterCategory.ULTRALIGHT:
        case EmitterCategory.PARACHUTIST:
        case EmitterCategory.POINTOBSTACLE:
        case EmitterCategory.CLUSTEROBSTACLE:
        case EmitterCategory.LINEOBSTACLE:
        default:
            return EmitterCategoryGroup.OtherEmitterCategory;
    }
}
