import React from "react";
import { ADSBFlight, EmitterCategory, MeasurementPoint } from "../../../../domain/model";
import { getADSBFlightIconProps, getComponentFromProps } from "../../../legend/LegendIcon";
import { AttributeList, AttributeListItem, AttributeListItemWithIcon, AttributeListSpace } from "./Attributes";
import { DistanceToMeasurementPoints } from "./DistanceToMeasurementPoints";
import { t } from "i18next";
import { ADSBUtils } from "../../../../utils/ADSBUtils";

interface ADSBFlightInfoProps {
    formatValueWithCurrentUnit(value: number): string;
    flight: ADSBFlight;
    measurementPoints: MeasurementPoint[];
    lastSnapshotTime: number;
}

export const ADSBFlightInfo = ({
    formatValueWithCurrentUnit,
    flight: flight,
    measurementPoints,
    lastSnapshotTime,
}: ADSBFlightInfoProps): JSX.Element => {
    const lastEstimate = ADSBUtils.getClosestEstimateTo(flight, lastSnapshotTime) || ADSBUtils.getLastEstimate(flight);
    const location = lastEstimate.location;
    if (!location) {
        return <></>;
    }

    const lat = location.latitude.toFixed(7);
    const lon = location.longitude.toFixed(7);
    const alt = isNaN(location.altitude) ? "-" : formatValueWithCurrentUnit(location.altitude);

    const velocity = flight.velocity;

    const ec = flight.emitterCategory;
    const iconProps = getADSBFlightIconProps(ec, 1);

    const tf = (value: boolean): string => (value ? t("general.booleanTrue") : t("general.booleanFalse"));

    return (
        <AttributeList>
            <AttributeListSpace />
            <AttributeListItemWithIcon label={t("map.adsbFlight")} icon={getComponentFromProps(iconProps)} boldLabel />
            <AttributeListItem label={t("map.adsbICAO")} value={flight.icao} />
            <AttributeListItem label={t("map.adsbFlightId")} value={flight.flightId} />

            <AttributeListSpace />
            <AttributeListItem label={t("map.position")} boldLabel />
            <AttributeList>
                <AttributeListItem label={t("map.latitude_shorthand")} value={lat} />
                <AttributeListItem label={t("map.longitude_shorthand")} value={lon} />
                <AttributeListItem label={t("map.altitude_shorthand")} value={alt} />
            </AttributeList>

            <DistanceToMeasurementPoints
                formatValueWithCurrentUnit={formatValueWithCurrentUnit}
                trackPosition={location.toGeoJSONLocation()}
                measurementPoints={measurementPoints}
            />

            <AttributeListSpace />
            <AttributeListItem
                label={t("map.velocity")}
                value={`${velocity.toFixed(1)} ${t("unit.meter_shorthand")}`}
                condition={velocity != null}
            />
            <AttributeListItem
                condition={ec != null}
                label={t("map.adsbEmitterCategory")}
                value={() => EmitterCategory[ec]}
            />
            <AttributeListItem label={t("map.adsbSPI")} value={tf(flight.hasSPI)} />
            <AttributeListItem label={t("map.adsbAlert")} value={tf(flight.hasAlert)} />
            <AttributeListItem label={t("map.adsbEmergency")} value={tf(flight.hasEmergency)} />
            <AttributeListItem label={t("map.adsbSquawk")} value={flight.squawk} />
        </AttributeList>
    );
};
