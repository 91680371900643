import mapboxgl from "mapbox-gl";
import * as MapUtils from "../../../utils/MapUtils";
import { Feature } from "geojson";
import { Colors } from "../../appearance/Colors";
import { GroundObservationMode } from "../../../domain/repositories";
import { GroundObservationState, Location } from "../../../domain/model";
import GroundObservationIcon from "../../../res/images/ground_observation.svg";
import GroundObservationIconSelected from "../../../res/images/ground_observation_selected.svg";
import { generateObservationSymbol } from "./ObservationSymbols";
import { InteractiveMapLayer } from "./InteractiveMapLayer";

const GROUND_OBSERVATIONS_LAYER_ID = "layer-ground-observations";
const GROUND_OBSERVATIONS_SOURCE_ID = "source-ground-observations";
const GROUND_OBSERVATION_SYMBOL_ID = "symbol-ground-observation";
const GROUND_OBSERVATIONS_HOVER_LAYER_ID = "layer-ground-observations_hover";
const GROUND_OBSERVATION_SELECTED_SYMBOL_ID = "symbol-ground-observation-selected";
const GROUND_OBSERVATION_SELECTED_LOCATIONS_LAYER_ID = "layer-ground-observations-selected-locations";
const GROUND_OBSERVATION_SELECTED_LOCATIONS_SOURCE_ID = "source-ground-observations-selected-locations";
const GROUND_OBSERVATION_SELECTED_LOCATION_SYMBOL_ID = "symbol-ground-observation-selected-location";

export class GroundObservationsLayer extends InteractiveMapLayer {
    // Static functions

    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        onGroundObservationClicked: ((id: int) => void) | null,
        onMapLongPressedInObservation: ((location: Location) => void) | null,
        onCancelLongPress: ((longPressTriggered: boolean) => void) | null,
    ): GroundObservationsLayer {
        return new GroundObservationsLayer(
            map,
            orderLayer,
            onGroundObservationClicked,
            onMapLongPressedInObservation,
            onCancelLongPress,
        );
    }

    // Properties

    public shouldSuppressClick = false;
    private groundObservationMode: GroundObservationMode = GroundObservationMode.None;
    private hoveredGroundObservationId?: number | string;
    private longPressTimeout?: NodeJS.Timeout;
    private longPressTriggered = false;

    private constructor(
        readonly map: mapboxgl.Map,
        private readonly orderLayer: string,
        private readonly onGroundObservationClicked: ((id: int) => void) | null,
        private readonly onMapLongPressedInObservation: ((location: Location) => void) | null,
        private readonly onCancelLongPress: ((longPressTriggered: boolean) => void) | null,
    ) {
        super(map);
        this.setup();
    }

    // Public functions

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(GROUND_OBSERVATIONS_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(GROUND_OBSERVATIONS_HOVER_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(GROUND_OBSERVATION_SELECTED_LOCATIONS_LAYER_ID, "visibility", visibility);
    }

    public setGroundObservationMode(mode: GroundObservationMode): void {
        this.groundObservationMode = mode;
    }

    public setGroundObservationStates(groundObservationStates: GroundObservationState[]): void {
        const source = this.map.getSource(GROUND_OBSERVATIONS_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        source.setData({
            type: "FeatureCollection",
            features: groundObservationStates.map((o) => this.getFeatureFromGroundObservationState(o)),
        });
    }

    public setSelectedLocations(locations: Location[]): void {
        const source = this.map.getSource(GROUND_OBSERVATION_SELECTED_LOCATIONS_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        source.setData({
            type: "FeatureCollection",
            features: locations.map((l, i) => this.getFeatureFromLocation(l, i)),
        });
    }

    // Private functions

    private setup(): void {
        this.addGroundObservationsLayer();
        this.addSelectedLocationsLayer();

        // On click for logged ground observations
        this.addLayerEventListener({ type: "click", layer: GROUND_OBSERVATIONS_LAYER_ID, listener: this.onClick });
        // On hover for logged ground observations
        this.addLayerEventListener({
            type: "mousemove",
            layer: GROUND_OBSERVATIONS_LAYER_ID,
            listener: this.onMouseMove,
        });
        this.addLayerEventListener({
            type: "mouseleave",
            layer: GROUND_OBSERVATIONS_LAYER_ID,
            listener: this.onMouseLeave,
        });
        // On long press for selecting new ground observation location
        this.addEventListener({ type: "mousedown", listener: this.startLongPressTimer });
        this.addEventListener({ type: "touchstart", listener: this.startLongPressTimer });
        this.addEventListener({ type: "mouseup", listener: this.clearLongPressTimer });
        this.addEventListener({ type: "touchend", listener: this.clearLongPressTimer });
        this.addEventListener({ type: "mouseout", listener: this.clearLongPressTimer });
        this.addEventListener({ type: "dragstart", listener: this.clearLongPressTimer });
    }

    private addGroundObservationsLayer(): void {
        this.map.addSource(GROUND_OBSERVATIONS_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);
        MapUtils.loadImageFromSVG(GroundObservationIcon, (image) =>
            this.map.addImage(GROUND_OBSERVATION_SYMBOL_ID, image),
        );
        MapUtils.loadImageFromSVG(GroundObservationIconSelected, (image) =>
            this.map.addImage(GROUND_OBSERVATION_SELECTED_SYMBOL_ID, image),
        );

        const groundObservationsLayer = this.createGroundObservationsLayer();
        const groundObservationsHoverLayer = this.createGroundObservationsHoverLayer(groundObservationsLayer);
        this.map.addLayer(groundObservationsLayer, this.orderLayer);
        this.map.addLayer(groundObservationsHoverLayer, this.orderLayer);
    }

    private createGroundObservationsLayer(): mapboxgl.SymbolLayer {
        return {
            id: GROUND_OBSERVATIONS_LAYER_ID,
            type: "symbol",
            source: GROUND_OBSERVATIONS_SOURCE_ID,
            filter: ["==", "$type", "Point"],
            layout: {
                "icon-image": [
                    "case",
                    ["==", ["get", "selected"], true],
                    GROUND_OBSERVATION_SELECTED_SYMBOL_ID,
                    GROUND_OBSERVATION_SYMBOL_ID,
                ],
                "icon-allow-overlap": true,
                "icon-pitch-alignment": "map",
            },
            paint: {
                "icon-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0, 1],
            },
        };
    }

    private createGroundObservationsHoverLayer(base: mapboxgl.SymbolLayer): mapboxgl.SymbolLayer {
        return {
            ...base,
            id: GROUND_OBSERVATIONS_HOVER_LAYER_ID,
            layout: {
                ...base.layout,
                "icon-size": 1.25,
            },
            paint: {
                ...base.paint,
                "icon-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0],
            },
        };
    }

    private addSelectedLocationsLayer(): void {
        this.map.addSource(GROUND_OBSERVATION_SELECTED_LOCATIONS_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        MapUtils.loadImageFromSVG(generateObservationSymbol({ color: Colors.text.text }), (image) =>
            this.map.addImage(GROUND_OBSERVATION_SELECTED_LOCATION_SYMBOL_ID, image),
        );

        this.map.addLayer(
            {
                id: GROUND_OBSERVATION_SELECTED_LOCATIONS_LAYER_ID,
                type: "symbol",
                source: GROUND_OBSERVATION_SELECTED_LOCATIONS_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                layout: {
                    "icon-image": GROUND_OBSERVATION_SELECTED_LOCATION_SYMBOL_ID,
                    "icon-allow-overlap": true,
                },
            },
            this.orderLayer,
        );
    }

    private getFeatureFromGroundObservationState(groundObservationState: GroundObservationState): Feature {
        return {
            id: groundObservationState.id,
            type: "Feature",
            properties: {
                selected: groundObservationState.selected,
            },
            geometry: {
                type: "Point",
                coordinates: [groundObservationState.location.longitude, groundObservationState.location.latitude],
            },
        };
    }

    private getFeatureFromLocation(location: Location, index: int): Feature {
        return {
            id: index,
            type: "Feature",
            properties: {},
            geometry: {
                type: "Point",
                coordinates: [location.longitude, location.latitude],
            },
        };
    }

    private onClick = (
        event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void => {
        if (
            this.shouldSuppressClick ||
            this.onGroundObservationClicked == null ||
            !event.features ||
            event.features.length === 0
        ) {
            return;
        }
        this.onGroundObservationClicked(event.features[0].id as int);
    };

    private onMouseMove = (
        event: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void => {
        if (event.features && event.features.length > 0) {
            this.setGroundObservationHoverState(false);
            this.hoveredGroundObservationId = event.features[0].id;
            this.setGroundObservationHoverState(true);
        }
    };

    private onMouseLeave = (): void => {
        this.setGroundObservationHoverState(false);
        this.hoveredGroundObservationId = undefined;
    };

    private setGroundObservationHoverState(hover: boolean): void {
        if (!this.hoveredGroundObservationId) {
            return;
        }

        this.map.setFeatureState(
            { source: GROUND_OBSERVATIONS_SOURCE_ID, id: this.hoveredGroundObservationId },
            { hover: hover },
        );
    }

    private startLongPressTimer = (
        event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void => {
        this.longPressTimeout = setTimeout(() => {
            this.onLongPress(event);
            this.longPressTriggered = true;
        }, 500);
    };

    private clearLongPressTimer = (): void => {
        this.longPressTimeout && clearTimeout(this.longPressTimeout);
        this.onCancelLongPress && this.onCancelLongPress(this.longPressTriggered);
        this.longPressTriggered = false;
    };

    private onLongPress = (
        event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void => {
        switch (this.groundObservationMode) {
            case GroundObservationMode.SingleGroundObservation:
                this.handleLongPressForObservation(event);
                break;
        }
    };

    private handleLongPressForObservation(event: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent): void {
        this.onMapLongPressedInObservation &&
            this.onMapLongPressedInObservation(new Location(event.lngLat.lat, event.lngLat.lng, 0.0));
    }
}
