import React from "react";
import { Location, Radar } from "../../../domain/model";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { showError } from "../../../utils/MessageUtils";
import { DistanceUnit } from "../../../domain/model/DistanceUnit";
import { DistanceFormatFunction, DistanceFormatter, DistanceFormatType } from "../../../domain/DistanceFormatter";
import { Observable } from "rxjs";
import { parseNumberOrZero } from "../../../utils/StringUtils";
import { PanelDescription } from "../../appearance/panels/PanelText";
import { t } from "i18next";
import { Button } from "../../appearance/button/Button";
import { Form } from "../../appearance/forms/Form";
import { ButtonGroup } from "../../appearance/button/ButtonGroup";
import { FormControl } from "../../appearance/forms/FormControl";
import { InputField } from "../../appearance/forms/InputField";

interface Props {
    altitudeUnitObservable: Observable<DistanceUnit>;
    distanceFormatter: DistanceFormatter;
    isAutoPositioning: boolean;
    onRequestPreviewNewPosition: (position: Location, groundLevel: number | null) => void;
    onRequestSaveNewPosition: (position: Location, groundLevel: number | null) => Rx.Observable<void>;
    previewLocationFromMapObservable: Rx.Observable<Location>;
    radar: Radar;
    showAltitudeExplanationModal: () => void;
}

interface State {
    altitudeDisplayValue: string;
    altitudeUnitShortName: string;
    canApplyChanges: boolean;
    groundLevelDisplayValue: string;
    hasGroundLevelConfig: boolean;
    isLoading: boolean;
    latitude: number;
    longitude: number;
}

export class RadarReposition extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private initialGroundLevel: number;

    private readonly latitudeInput: React.RefObject<HTMLInputElement>;
    private readonly longitudeInput: React.RefObject<HTMLInputElement>;
    private readonly altitudeInput: React.RefObject<HTMLInputElement>;
    private readonly groundLevelInput: React.RefObject<HTMLInputElement>;

    private readonly altitudeSubject: Rx.BehaviorSubject<number>;
    private readonly groundLevelSubject: Rx.BehaviorSubject<number>;

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.latitudeInput = React.createRef();
        this.longitudeInput = React.createRef();
        this.altitudeInput = React.createRef();
        this.groundLevelInput = React.createRef();

        this.initialGroundLevel = (props.radar.status && props.radar.status.groundLevel) || 0;

        this.altitudeSubject = new Rx.BehaviorSubject<number>(props.radar.position.altitude);
        this.groundLevelSubject = new Rx.BehaviorSubject<number>(this.initialGroundLevel);

        const radar = props.radar;
        this.state = {
            isLoading: false,
            canApplyChanges: false,
            altitudeDisplayValue: "",
            altitudeUnitShortName: "",
            latitude: radar.position.latitude,
            longitude: radar.position.longitude,
            groundLevelDisplayValue: "",
            hasGroundLevelConfig: (radar.status && radar.status.groundLevel != null) || false,
        };
    }

    public componentDidMount(): void {
        this.subscribeToMapChanges();
        this.subscribeToAltitudeSubject();
        this.subscribeToGroundLevelSubject();
        this.subscribeToDistanceUnit();
        this.props.onRequestPreviewNewPosition(this.props.radar.position, this.groundLevelSubject.value);
    }

    public render(): React.ReactNode {
        return (
            <Form vertical>
                <PanelDescription>{t("radarControlPanel.descriptionRepositioning")}</PanelDescription>
                <FormControl title={`${t("radarControlPanel.latitude")} ${t("unit.lat_lon_unit_bracketed")}`}>
                    <InputField
                        disabled={this.state.isLoading || this.props.isAutoPositioning}
                        onChange={this.onLatitudeChange.bind(this)}
                        ref={this.latitudeInput}
                        type="number"
                        value={this.state.latitude.toString()}
                    />
                </FormControl>
                <FormControl title={`${t("radarControlPanel.longitude")} ${t("unit.lat_lon_unit_bracketed")}`}>
                    <InputField
                        disabled={this.state.isLoading || this.props.isAutoPositioning}
                        onChange={this.onLongitudeChange.bind(this)}
                        ref={this.longitudeInput}
                        type="number"
                        value={this.state.longitude.toString()}
                    />
                </FormControl>
                <FormControl title={`${t("radarControlPanel.altitude")} (${this.state.altitudeUnitShortName})`}>
                    <InputField
                        disabled={this.state.isLoading || this.props.isAutoPositioning}
                        onChange={this.onAltitudeChange.bind(this)}
                        ref={this.altitudeInput}
                        type="number"
                        value={this.state.altitudeDisplayValue}
                    />
                </FormControl>
                {this.state.hasGroundLevelConfig && (
                    <FormControl title={`${t("radarControlPanel.groundLevel")} (${this.state.altitudeUnitShortName})`}>
                        <InputField
                            disabled={this.state.isLoading || this.props.isAutoPositioning}
                            onChange={this.onGroundLevelChange.bind(this)}
                            ref={this.groundLevelInput}
                            type="number"
                            value={this.state.groundLevelDisplayValue}
                        />
                    </FormControl>
                )}
                <FormControl>
                    <Button
                        label={t("radarControlPanel.toggleAltitudeFilterExplanation")}
                        layout="inline"
                        onClick={() => this.props.showAltitudeExplanationModal()}
                    />
                </FormControl>
                <ButtonGroup>
                    <Button
                        disabled={this.state.isLoading || !this.state.canApplyChanges || this.props.isAutoPositioning}
                        loading={this.state.isLoading}
                        label={t("radarControlPanel.applyChanges")}
                        onClick={this.saveChanges.bind(this)}
                    />
                </ButtonGroup>
            </Form>
        );
    }

    // Private functions

    private saveChanges(): void {
        const newLocation = new Location(this.state.latitude, this.state.longitude, this.altitudeSubject.value);
        this.setState({ isLoading: true });
        const groundLevel = this.state.hasGroundLevelConfig ? this.groundLevelSubject.value : null;
        const subscription = this.props.onRequestSaveNewPosition(newLocation, groundLevel).subscribe({
            complete: () => {
                this.setState({ isLoading: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isLoading: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private onLatitudeChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newValue = parseNumberOrZero(e.target.value);
        if (this.state.latitude !== newValue) {
            this.onChange(this.altitudeSubject.value, newValue, this.state.longitude, this.groundLevelSubject.value);
        }
    }

    private onLongitudeChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newValue = parseNumberOrZero(e.target.value);
        if (this.state.longitude !== newValue) {
            this.onChange(this.altitudeSubject.value, this.state.latitude, newValue, this.groundLevelSubject.value);
        }
    }

    private onAltitudeChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let newValue = parseNumberOrZero(e.target.value);
        newValue = this.props.distanceFormatter.convertValueFromCurrentUnit(
            newValue,
            DistanceUnit.METRIC,
            DistanceFormatFunction.NONE,
        );
        if (this.altitudeSubject.value !== newValue) {
            this.onChange(newValue, this.state.latitude, this.state.longitude, this.groundLevelSubject.value);
        }
    }

    private onGroundLevelChange(e: React.ChangeEvent<HTMLInputElement>): void {
        let newValue = parseNumberOrZero(e.target.value);
        newValue = this.props.distanceFormatter.convertValueFromCurrentUnit(
            newValue,
            DistanceUnit.METRIC,
            DistanceFormatFunction.NONE,
        );
        if (this.groundLevelSubject.value !== newValue) {
            this.onChange(this.altitudeSubject.value, this.state.latitude, this.state.longitude, newValue);
        }
    }

    private onChange(
        altitude: number,
        latitude: number,
        longitude: number,
        groundLevel: number,
        fromMap = false,
    ): void {
        let canApplyChanges = false;
        if (
            altitude !== this.props.radar.position.altitude ||
            latitude !== this.props.radar.position.latitude ||
            longitude !== this.props.radar.position.longitude ||
            groundLevel !== this.initialGroundLevel
        ) {
            canApplyChanges = true;
        }
        this.setState({
            latitude,
            longitude,
            canApplyChanges,
        });
        this.altitudeSubject.next(altitude);
        this.groundLevelSubject.next(groundLevel);
        if (!fromMap) {
            this.props.onRequestPreviewNewPosition(new Location(latitude, longitude, altitude), groundLevel);
        }
    }

    private subscribeToMapChanges(): void {
        const subscription = this.props.previewLocationFromMapObservable.subscribe((location) =>
            this.onChange(
                this.altitudeSubject.value,
                location.latitude,
                location.longitude,
                this.groundLevelSubject.value,
                true,
            ),
        );
        this.collectSubscription(subscription);
    }

    private subscribeToAltitudeSubject(): void {
        const subscription = this.props.distanceFormatter
            .formatObservable(this.altitudeSubject.asObservable(), (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .subscribe((altitude) => this.setState({ altitudeDisplayValue: altitude }));
        this.collectSubscription(subscription);
    }

    private subscribeToGroundLevelSubject(): void {
        const subscription = this.props.distanceFormatter
            .formatObservable(this.groundLevelSubject.asObservable(), (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .subscribe((groundLevel) => this.setState({ groundLevelDisplayValue: groundLevel }));
        this.collectSubscription(subscription);
    }

    private subscribeToDistanceUnit(): void {
        const subscription = this.props.altitudeUnitObservable.subscribe((unit) => {
            this.setState({
                altitudeUnitShortName:
                    unit === DistanceUnit.METRIC ? t("unit.meter_shorthand") : t("unit.foot_shorthand"),
            });
        });
        this.collectSubscription(subscription);
    }
}
