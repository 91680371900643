import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { RunwayTrafficData, RunwayTrafficRequest } from "../../../../domain/model/proto/generated/atcinfo3_pb";
import { TracksOnRunwayData } from "../../../../domain/model/proto/generated/atcinfo3_pb";
import { RunwayCrossingData } from "../../../../domain/model/RunwayCrossingData";
import { RunwayTraffic } from "../../../../domain/model/RunwayTraffic";
import { RunwayTrafficRepository } from "../../../../domain/repositories/RunwayTrafficRepository";
import { MINUTE } from "../../../../utils/DateTimeUtils";
import { AbstractStartableRepository, LocalPreferencesRepository } from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { Cache, cachedRequest } from "../../../../utils/Cache";
import { DateTimeRange } from "../../../../domain/model/proto/generated/common3_pb";
import { isUnimplementedError } from "../../infrastructure/api/BirdViewerGrpcAPI";

const RUNWAY_CROSSING_HISTORY_MINUTES = 120; // The last 2 hours

export class BirdViewerRunwayTrafficRepository extends AbstractStartableRepository implements RunwayTrafficRepository {
    // Properties

    public readonly crossingThreshold: Rx.Observable<number>;
    public readonly funnelThreshold: Rx.Observable<number>;
    public readonly runwayCrossingHistoryDuration: number = RUNWAY_CROSSING_HISTORY_MINUTES * MINUTE;

    public readonly runwayCrossingHistory: Rx.Observable<Map<number, RunwayCrossingData[]>>;
    public readonly runwayCrossingCurrent: Rx.Observable<Map<number, RunwayCrossingData>>;
    public readonly sectorFunnelTrackIDs: Rx.Observable<number[][]>;

    public get hasRunwayCrossingData(): Rx.Observable<boolean> {
        return this.runwayCrossingCurrent.pipe(
            RxOperators.map((c) => c.size !== 0),
            RxOperators.distinctUntilChanged(),
        );
    }

    private readonly runwayTrafficSubject = new Rx.BehaviorSubject<RunwayTraffic[]>([]);
    private subscriptions = new Rx.Subscription();

    // prettier-ignore
    private readonly runwayCrossingHistorySubject = new Rx.BehaviorSubject<Map<number, RunwayCrossingData[]>>(new Map());
    private readonly runwayCrossingCurrentSubject = new Rx.BehaviorSubject<Map<number, RunwayCrossingData>>(new Map());
    private readonly sectorFunnelTrackSubject = new Rx.BehaviorSubject<number[][]>([]);

    public constructor(private readonly api: BirdViewerAPI, localPreferencesRepository: LocalPreferencesRepository) {
        super();

        this.crossingThreshold = nonNullObservable(
            localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.charts.runwayCrossingsThreshold,
            ),
        );
        this.funnelThreshold = nonNullObservable(
            localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.charts.funnelViewThreshold),
        );

        this.runwayCrossingHistory = this.runwayCrossingHistorySubject.asObservable();
        this.runwayCrossingCurrent = this.runwayCrossingCurrentSubject.asObservable();
        this.sectorFunnelTrackIDs = this.sectorFunnelTrackSubject.asObservable();
    }

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.startFetchingTracksOnRunwayData();
    }

    public stop(): void {
        this.subscriptions.unsubscribe();
    }

    public observeRunwayTraffic(runwayIds?: int[]): Rx.Observable<RunwayTraffic[]> {
        return this.runwayTrafficSubject.pipe(
            RxOperators.filter((traffics) => traffics.length > 0),
            RxOperators.map((traffics) =>
                traffics
                    .filter((t) => t != null && (!runwayIds || runwayIds.includes(t.runwayId)))
                    .map((traffic) => ({
                        ...traffic!,
                        sectorTrafficRate: traffic!.sectorTrafficRate.map(Math.floor),
                    })),
            ),
            RxOperators.tap((traffics) => {
                const filledSectors = traffics
                    .filter((traffic) => traffic.sectorMessages.length > 0)
                    .map((traffic) =>
                        traffic.sectorMessages.flatMap((message) => [...message.trackIds, ...message.aircraftIds]),
                    );

                this.sectorFunnelTrackSubject.next(filledSectors);
            }),
        );
    }

    public loadReplayRunwayTraffic(
        startTimestamp: long,
        endTimestamp: long,
        cache: Cache<RunwayTraffic[]>,
    ): Rx.Observable<RunwayTraffic[]> {
        const date = new DateTimeRange();
        date.setStartmsec(startTimestamp);
        date.setEndmsec(endTimestamp);
        const request = new RunwayTrafficRequest();
        request.setTracksinsectors(true);
        request.setDate(date);
        return cachedRequest(
            this.api.getRunwayTrafficData(request).pipe(
                RxOperators.map((t) => this.getRunwayTrafficArray(t)),
                RxOperators.catchError((error) => {
                    if (isUnimplementedError(error)) {
                        // We can allow replay to continue even if runway traffic fails to load
                        return Rx.of([]);
                    } else {
                        throw error;
                    }
                }),
            ),
            cache,
            `${startTimestamp}-${endTimestamp}`,
        );
    }

    public loadReplayRunwayCrossingsHistory(
        startTimestamp: long,
        endTimestamp: long,
        cache: Cache<Map<number, RunwayCrossingData[]>>,
    ): Rx.Observable<Map<number, RunwayCrossingData[]>> {
        const request = new RunwayTrafficRequest();
        request.setTracksinsectors(true);
        const end = endTimestamp;
        const start = startTimestamp - this.runwayCrossingHistoryDuration;
        return cachedRequest(
            this.fetchRunwayCrossingsHistory(start, end).pipe(
                RxOperators.map((result) => this.handleTracksOnRunwayResult(result)),
                // We can allow replay to continue even if runway crossings history fails to load
                RxOperators.catchError((error) => {
                    if (isUnimplementedError(error)) {
                        return Rx.of(new Map<number, RunwayCrossingData[]>());
                    } else {
                        throw error;
                    }
                }),
            ),
            cache,
            `${startTimestamp}-${endTimestamp}`,
        );
    }

    // Private functions

    private getRunwayTrafficArray(data: RunwayTrafficData): RunwayTraffic[] {
        return data.getRunwaytrafficList().map((r) => RunwayTraffic.from(r));
    }

    private startFetchingTracksOnRunwayData(): void {
        this.subscriptions.add(
            this.fetchInitialTracksOnRunwayData().subscribe({
                next: (data) => {
                    const runwayCrossingsHistory = this.handleTracksOnRunwayResult(data);
                    this.runwayCrossingHistorySubject.next(runwayCrossingsHistory);
                    this.subscribeToRunwayTrafficData();
                },
                error: (error) =>
                    this.retryIfStartedAndImplemented(() => this.startFetchingTracksOnRunwayData(), error),
            }),
        );
    }

    private fetchInitialTracksOnRunwayData(): Rx.Observable<TracksOnRunwayData> {
        const end = Date.now();
        const start = end - this.runwayCrossingHistoryDuration;
        return this.fetchRunwayCrossingsHistory(start, end);
    }

    private fetchRunwayCrossingsHistory(start: number, end: number): Rx.Observable<TracksOnRunwayData> {
        return this.api.getTracksOnRunwayData(start, end);
    }

    private subscribeToRunwayTrafficData(): void {
        // The runway crossing data is always fetched (not just when funnel view is open)
        // because we need to have accurate data for RUNWAY_CROSSING_HISTORY_MINUTES even
        // if funnel view is only opened briefly
        const request = new RunwayTrafficRequest();
        request.setTracksinsectors(true);
        const subscription = this.api
            .getRunwayTrafficData(request)
            .subscribe((data) => this.handleRunwayTrafficDataResult(data));
        this.subscriptions.add(subscription);
    }

    private handleTracksOnRunwayResult(data: TracksOnRunwayData): Map<number, RunwayCrossingData[]> {
        // Get new TracksOnRunwayData items
        const newRunwayTrafficList = data.getTracksonrunwaysList();
        const newRunwayMap = new Map<number, RunwayCrossingData[]>();
        for (const rt of newRunwayTrafficList) {
            const rwList = rt.getTracksonrunwayitemsList().map((item) => {
                return new RunwayCrossingData(item.getTimestampMsec(), rt.getRunwayid(), item.getTracksonrunway());
            });
            newRunwayMap.set(rt.getRunwayid(), rwList);
        }
        return newRunwayMap;
    }

    private handleRunwayTrafficDataResult(data: RunwayTrafficData): void {
        const runwayCrossingsMap = this.runwayCrossingHistorySubject.getValue();
        const newRunwayTrafficList = data.getRunwaytrafficList();
        const mostRecentRcMap: Map<number, number> = new Map();
        const timeLimit = Date.now() - this.runwayCrossingHistoryDuration;
        let historyChanged = false;
        let currentChanged = false;

        // Collect the TreacksOnRunway aggregated full minute values and the most recent value
        for (const [index, rt] of newRunwayTrafficList.entries()) {
            let rwList = runwayCrossingsMap.get(rt.getRunwayid());
            if (rwList === undefined) {
                rwList = new Array<RunwayCrossingData>();
                runwayCrossingsMap.set(rt.getRunwayid(), rwList);
                historyChanged = true;
            }
            const lastMinuteTm = (Math.floor(rt.getTimestampMsec() / MINUTE) - 1) * MINUTE;
            if (rwList.length == 0 || rwList[rwList.length - 1].timestamp < lastMinuteTm) {
                // Check if a gap in data must be filled
                if (rwList.length > 0) {
                    const prevItem = rwList[rwList.length - 1];
                    const gap = lastMinuteTm - prevItem.timestamp;
                    if (gap > MINUTE) {
                        // Could finish last known 'recent' value first at this point. Map also to zero for now.
                        let nextMinuteTm = prevItem.timestamp;
                        do {
                            // add zero value for each missing minute
                            nextMinuteTm += MINUTE;
                            const value = new RunwayCrossingData(nextMinuteTm, rt.getRunwayid(), 0);
                            rwList.push(value);
                        } while (nextMinuteTm < lastMinuteTm);
                    }
                }

                const value = new RunwayCrossingData(lastMinuteTm, rt.getRunwayid(), rt.getTracksonrunwaylastminute());
                rwList.push(value);
                historyChanged = true;
            }

            // Not able to store ref to rt. Using index instead.
            mostRecentRcMap.set(rt.getRunwayid(), index);
        }

        const currentRcMap = this.runwayCrossingCurrentSubject.getValue();
        for (const [runwayId, rwList] of runwayCrossingsMap) {
            // Limit the history
            for (let i = 0; i < rwList.length; ++i) {
                if (rwList[i].timestamp > timeLimit) {
                    if (i > 0) {
                        // Remove outdated items before this point
                        rwList.splice(0, i - 1);
                        historyChanged = true;
                    }
                    // else, all items are fine and up-to-date
                    break;
                }
            }

            // Add the most recent 'current' value
            const index = mostRecentRcMap.get(runwayId);
            if (index !== undefined) {
                const rt = newRunwayTrafficList[index];
                const value = new RunwayCrossingData(rt.getTimestampMsec(), rt.getRunwayid(), rt.getTracksonrunway());
                currentRcMap.set(rt.getRunwayid(), value);
                currentChanged = true;
            }
        }

        if (historyChanged) {
            this.runwayCrossingHistorySubject.next(runwayCrossingsMap);
        }
        if (currentChanged) {
            this.runwayCrossingCurrentSubject.next(currentRcMap);
        }

        // handle funnel view data
        this.runwayTrafficSubject.next(this.getRunwayTrafficArray(data));
    }
}
