import { BaseViewModel } from "../../../../BaseViewModel";
import { LocalPreferencesRepository } from "../../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export class AltitudeFilterStatusIndicatorViewModel extends BaseViewModel {
    // Properties

    public get isAltitudeFilterActive(): Rx.Observable<boolean> {
        return Rx.combineLatest([
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.filters.minAltitudeOfInterest,
            ),
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.filters.maxAltitudeOfInterest,
            ),
        ]).pipe(RxOperators.map(([min, max]) => (min != null && min > 0) || max != null));
    }

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {
        super();
    }
}
