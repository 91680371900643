import * as Rx from "rxjs";
import { LocalUserPreferenceKeys } from "../../../domain/model";
import { LocalPreferencesRepository, LocationInfoRepository, UIControlRepository } from "../../../domain/repositories";
import { ToolbarButtonViewModel } from "./ToolbarButtonViewModel";

export class RunwayTrafficButtonViewModel extends ToolbarButtonViewModel {
    // Properties

    public get hasRunways(): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasRunways;
    }

    constructor(
        uiControlRepository: UIControlRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super(uiControlRepository);
    }

    // Public functions

    public toggleRunwayTrafficBottomSheet(isVisible: boolean): void {
        this.toggleUIComponent("isRunwayTrafficBottomSheetVisible", { isVisible });
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.charts.showRunwayTrafficBottomSheet,
            isVisible,
        );
    }
}
