export enum FlightUpdateType {
    SPI_CHANGED = 0,
    EMERGENCY_CHANGED,
    ALERT_CHANGED,
    SQUAWK_CHANGED,
    ISONSURFACE_CHANGED,
    EMITTER_CATEGORY_CHANGED,
    FLIGHT_ID_CHANGED,
    NEW_POSITION,
    NEW_COURSE,
    NEW_FLIGHT,
    POSITION_ACCURACY_CHANGED,
    DROPPED_FLIGHT,
    LASTSEEN_FLIGHT,
}
