import { Colors } from "../Colors";
import { S } from "./Spacing";

export interface Theme {
    colors: typeof Colors;
    spacing: typeof S;
    text: Record<string, unknown>;
    transitionDuration: string;
}

export enum ColorMode {
    Dark = "dark",
    Light = "light",
}

export const DarkTheme: Theme = {
    colors: Colors,
    spacing: S,
    text: {},
    transitionDuration: "0.2s",
};

export const LightTheme = DarkTheme;

export function getTheme(mode: ColorMode): Theme {
    switch (mode) {
        case ColorMode.Light:
            return LightTheme;
        default:
            return DarkTheme;
    }
}
