import { Runway as RunwayProto } from "./proto/generated/atcinfo3_pb";

export class Runway {
    // Static functions
    public static from(model: RunwayProto): Runway {
        return new Runway(model.getId(), model.getAirbaseid(), model.getRunwayname(), model.getRunwayshapeid());
    }

    public constructor(
        public readonly id: int,
        public readonly airbaseId: int,
        public readonly name: string,
        public readonly runwayShapeId: string,
    ) {}
}

export function getRunwayDirectionLabels(runway: Runway): { left: string; right: string } {
    const runwayNameSpaceSplit = runway.name.split(" ");
    const runwayNameSlashSplit = runway.name.split("/");
    const left = runwayNameSpaceSplit[runwayNameSpaceSplit.length - 1].split("/")[0];
    const right = runwayNameSlashSplit[runwayNameSlashSplit.length - 1];
    return { left, right };
}
