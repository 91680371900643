import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import * as RxUtils from "../../../../utils/RxUtils";
import { AirBase, AirBaseData, Location } from "../../../../domain/model";
import { AirbaseData as AirbaseDataProto, AirbaseRequest } from "../../../../domain/model/proto/generated/atcinfo3_pb";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { AbstractStartableRepository } from "../../../../domain/repositories";
import { LocationInfoRepository } from "../../../../domain/repositories/LocationInfoRepository";

export class BirdViewerLocationInfoRepository extends AbstractStartableRepository implements LocationInfoRepository {
    // Properties

    public readonly airBaseData: Rx.Observable<AirBaseData>;
    public readonly selectedAirBase: Rx.Observable<AirBase>;
    public readonly referenceLocation: Rx.Observable<Location>;
    public readonly hasReliableAltitudeInfo: Rx.Observable<boolean>;
    public readonly isSimulation: Rx.Observable<boolean>;

    public get hasRunways(): Rx.Observable<boolean> {
        return this.airBaseData.pipe(
            RxOperators.map(({ runways }) => runways.length > 0),
            RxOperators.distinctUntilChanged(),
        );
    }

    public get hasFunnelTrafficData(): Rx.Observable<boolean> {
        return this.airBaseData.pipe(
            RxOperators.map(({ runwayFunnel }) => runwayFunnel && runwayFunnel.length !== 0),
            RxOperators.distinctUntilChanged(),
        );
    }

    private readonly airBaseDataSubject = new Rx.BehaviorSubject<AirBaseData | null>(null);
    private readonly selectedAirBaseSubject = new Rx.BehaviorSubject<AirBase | null>(null);
    private subscriptions: Rx.Subscription | undefined;

    public constructor(private readonly api: BirdViewerAPI) {
        super();
        this.airBaseData = RxUtils.nonNullObservable(this.airBaseDataSubject.asObservable());
        this.selectedAirBase = RxUtils.nonNullObservable(this.selectedAirBaseSubject.asObservable());
        this.referenceLocation = this.selectedAirBase.pipe(RxOperators.map((airbase) => airbase.referencePosition));
        this.hasReliableAltitudeInfo = Rx.of(true);
        // This is not yet enabled in BV, so always returns false
        this.isSimulation = Rx.of(false);
    }

    // Public functions

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.fetchAirbaseData();
        const subscription = this.airBaseData.subscribe((airBaseData) =>
            this.selectedAirBaseSubject.next(airBaseData.airbases[0]),
        );
        this.subscriptions.add(subscription);
    }

    public stop(): void {
        this.subscriptions!.unsubscribe();
    }

    public hasParkingCorridorData(runwayId?: int): Rx.Observable<boolean> {
        return this.airBaseData.pipe(
            RxOperators.map(({ runwayFunnel }) =>
                runwayFunnel
                    .filter((r) => runwayId === undefined || r.runwayId === runwayId)
                    .some((r) => r.circuitLength > 0),
            ),
            RxOperators.distinctUntilChanged(),
        );
    }

    // Private functions

    private fetchAirbaseData(): void {
        const request = new AirbaseRequest();
        const subscription = this.api.getAirbaseData(request).subscribe({
            next: (result) => {
                if (result.getAirbaseList().length < 1) {
                    console.warn(new Error("There is no airbase data for this account."));
                }
                this.handleFetchAirbaseDataResult(result);
            },
            error: (error) => {
                console.warn(
                    new Error("An error occurred while fetching airbases. Please go back and try again."),
                    error,
                );
            },
        });
        this.subscriptions!.add(subscription);
    }

    private handleFetchAirbaseDataResult(response: AirbaseDataProto): void {
        const airBaseData = AirBaseData.from(response);
        this.airBaseDataSubject.next(airBaseData);
    }
}
