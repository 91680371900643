import { Classification } from "../../domain/model";

export interface TrackListEntryViewModel {
    id: number;
    isAlarm?: boolean;
    isSelected?: boolean;
    classification?: Classification;
}

export const TrackListEntryViewModelUtils = {
    sort(a: TrackListEntryViewModel, b: TrackListEntryViewModel): 1 | -1 {
        // Sort entries with alarm first
        if (a.isAlarm !== b.isAlarm) {
            return a.isAlarm ? -1 : 1;
        }
        // Sort by classification order next (lower number is higher priority)
        const aOrder = (a.classification && a.classification.zOrder) || 1000;
        const bOrder = (b.classification && b.classification.zOrder) || 1000;
        if (aOrder !== bOrder) {
            return aOrder > bOrder ? 1 : -1;
        }
        // Sort ascending by track id last
        return a.id > b.id ? 1 : -1;
    },
};
