import * as RxOperators from "rxjs/operators";
import { UIControlOptions, UIControlRepository } from "../domain/repositories";
import { useObservable } from "./useObservable";
import { TYPES } from "../di/Types";
import DI from "../di/DI";
import { UIState } from "../domain/model";

interface UIStateHookReturnValue {
    isVisible: boolean;
    toggleVisibility: (options?: UIControlOptions) => void;
}

export function useUIState(key: keyof UIState): UIStateHookReturnValue {
    const uiControlRepository = DI.get<UIControlRepository>(TYPES.UIControlRepository);

    const isVisible = useObservable(uiControlRepository.uiState.pipe(RxOperators.map((state) => state[key])), false);
    const toggleVisibility = (options?: UIControlOptions): void => uiControlRepository.toggleUIComponent(key, options);

    return { isVisible, toggleVisibility };
}
