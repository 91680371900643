import React from "react";
import { TYPES } from "../../di/Types";
import styled from "styled-components";
import { StatusColorsType } from "../appearance/Colors";
import { useViewModel } from "../../hooks/useViewModel";
import { EdgeGlowViewModel } from "./EdgeGlowViewModel";
import { useObservable } from "../../hooks";

const Glimmer = styled.div<{ statusColor: StatusColorsType }>`
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 10px ${({ theme, statusColor }) => theme.colors.status[statusColor]};
    z-index: 10;
`;

export const EdgeGlow = (): JSX.Element | null => {
    // Properties

    const viewModel: EdgeGlowViewModel = useViewModel(TYPES.EdgeGlowViewModel);
    const hasActiveAlarms = useObservable(viewModel.hasActiveAlarms, false);
    const isDynamicPositioningEnabled = useObservable(viewModel.isDynamicPositioningEnabled, false);

    // Render

    if (!hasActiveAlarms && !isDynamicPositioningEnabled) {
        return null;
    }

    return <Glimmer statusColor={hasActiveAlarms ? "danger" : "info"} />;
};
