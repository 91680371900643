import React from "react";
import { ComponentState, RadarComponentState } from "../../../../domain/model";
import { Colors } from "../../../appearance/Colors";
import { t } from "i18next";
import { ComponentStatus } from "./ComponentStatus";
import { uppercasedFirstLetter } from "../../../../utils/StringUtils";

interface Props {
    radarStatusList: RadarComponentState[];
}

export const RadarStatusComponentList = ({ radarStatusList }: Props): JSX.Element | null => {
    // Local functions

    const getColorFromComponentState = (componentState: ComponentState): string => {
        switch (componentState) {
            case ComponentState.Ok:
                return Colors.status.success;
            case ComponentState.Error:
                return Colors.status.danger;
            case ComponentState.Idle:
                return Colors.status.disabled;
        }
    };

    const getTooltipFromRadarComponentState = (radarState: RadarComponentState): string => {
        const stateText = getTextForComponentState(radarState.state);
        if (!radarState.message) {
            return stateText;
        }
        return uppercasedFirstLetter(radarState.message) + " (" + stateText + ")";
    };

    const getTextForComponentState = (state: ComponentState): string => {
        switch (state) {
            case ComponentState.Ok:
                return t("radarStatus.stateOk");
            case ComponentState.Error:
                return t("radarStatus.stateError");
            case ComponentState.Idle:
                return t("radarStatus.stateIdle");
        }
    };

    // Render

    if (radarStatusList.length === 0) {
        return null;
    }

    return (
        <>
            {radarStatusList.map((radarStatus) => (
                <ComponentStatus
                    color={getColorFromComponentState(radarStatus.state)}
                    hasMessage={radarStatus.message !== undefined}
                    key={radarStatus.name}
                    label={radarStatus.name}
                    tooltip={getTooltipFromRadarComponentState(radarStatus)}
                />
            ))}
        </>
    );
};
