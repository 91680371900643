import styled from "styled-components";
import React, { Component } from "react";

const animationDurationInMS = 300;

const Container = styled.div<{ isVisible: boolean }>`
    background-color: black;
    width: 360px;
    position: absolute;
    right: ${(props) => (props.isVisible ? 0 : "-360px")};
    bottom: 0;
    top: 0;
    transition: right ${animationDurationInMS}ms ease-out;
    overflow-y: auto;
    overflow-x: hidden;
`;

export class SettingsDrawer extends Component<{}, State> {
    // Lifecycle

    public constructor(props: {}) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }
    // Public functions

    public componentDidMount(): void {
        setTimeout(() => {
            this.setState({ isVisible: true });
        });
    }

    public render(): JSX.Element {
        return <Container isVisible={this.state.isVisible} {...this.props} />;
    }

    public close(completion: () => void): void {
        this.setState({ isVisible: false });
        setTimeout(() => {
            completion();
        }, animationDurationInMS);
    }
}

interface State {
    isVisible: boolean;
}
