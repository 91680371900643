import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { EditMode, OverlaySelection } from "../../../domain/repositories";
import React from "react";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import { OverlayShapeRemoverViewModel } from "./OverlayShapeRemoverViewModel";
import { t } from "i18next";
import { withTheme } from "styled-components";
import { Theme } from "../../appearance/theme/Theme";
import { Form } from "../../appearance/forms/Form";
import { Button } from "../../appearance/button/Button";
import { FormControl } from "../../appearance/forms/FormControl";

interface Props {
    theme: Theme;
}

interface State {
    selection: OverlaySelection[];
    confirmingDelete: boolean;
}

export class OverlayShapeRemoverComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: OverlayShapeRemoverViewModel = DI.get(TYPES.OverlayShapeRemoverViewModel);
    private confirmingDeleteTimeoutRef: NodeJS.Timeout | null = null;

    // Public functions

    public constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            selection: [],
            confirmingDelete: false,
        };
    }

    public componentDidMount(): void {
        this.subscribeToEditState();
    }

    public render(): React.ReactNode {
        return this.state.selection.length > 0 ? (
            <Form>
                <FormControl title={t("overlays.deleteSelectedAreas")} vertical>
                    <Button
                        color="error"
                        label={this.state.confirmingDelete ? t("general.deleteConfirm") : t("general.delete")}
                        onClick={() => this.deleteSelection()}
                    />
                </FormControl>
            </Form>
        ) : null;
    }

    // Private functions

    private subscribeToEditState(): void {
        const subscription = this.viewModel.editState.subscribe({
            next: (state) =>
                this.setState({
                    selection: state.mode === EditMode.SELECT ? (state.payload as OverlaySelection[]) : [],
                }),
            error: (error) => console.error(error),
        });

        this.collectSubscription(subscription);
    }

    private deleteSelection(): void {
        if (!this.state.confirmingDelete) {
            this.setState({ confirmingDelete: true });
            this.confirmingDeleteTimeoutRef = setTimeout(() => this.setState({ confirmingDelete: false }), 3000);
            return;
        }

        if (this.confirmingDeleteTimeoutRef != null) {
            clearTimeout(this.confirmingDeleteTimeoutRef);
        }
        this.performDeleteSelection();
        this.setState({ confirmingDelete: false });
    }

    private performDeleteSelection(): void {
        this.viewModel.deleteSelection(this.state.selection);
    }
}

export const OverlayShapeRemover = withTheme(OverlayShapeRemoverComponent);
