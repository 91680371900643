import { ComponentStateJSON } from "./json";

export enum ComponentState {
    Error,
    Ok,
    Idle,
}

export class RadarComponentState {
    public static fromJson(model: ComponentStateJSON): RadarComponentState {
        return new RadarComponentState(model.name, model.state, model.message);
    }
    public constructor(
        public readonly name: string,
        public readonly state: ComponentState,
        public readonly message?: string,
    ) {}
}
