import React from "react";
import { Position } from "geojson";
import { FormatterFunction } from "../../../../domain/DistanceFormatter";
import { DistanceUnit, MeasurementPoint } from "../../../../domain/model";
import turfDistance from "@turf/distance";
import * as turfHelpers from "@turf/helpers";
import { AttributeList, AttributeListItem, AttributeListSpace } from "./Attributes";
import { t } from "i18next";

interface InfoMeasurementsRepresentationProps {
    formatValueWithCurrentUnit: FormatterFunction;
    trackPosition: Position;
    measurementPoints: MeasurementPoint[];
}

export const DistanceToMeasurementPoints = ({
    formatValueWithCurrentUnit,
    trackPosition,
    measurementPoints,
}: InfoMeasurementsRepresentationProps): JSX.Element => {
    if (!measurementPoints.length) {
        return <></>;
    }

    const measurementPointDistances = measurementPoints
        .map(({ name, position }) => {
            // Shorten the name with some simple rules so that it fits in the panel
            let displayName = name;
            if (name.length > 10 && name.length <= 15) {
                displayName = name.slice(0, 5) + "..." + name.slice(10);
            } else if (name.length > 15) {
                displayName = name.substring(0, 10) + "...";
            }

            // Calculate the distance between the track and the measurement point
            const distance = turfDistance(turfHelpers.point(position), turfHelpers.point(trackPosition), {
                units: "meters",
            });
            const formattedDistance = formatValueWithCurrentUnit(distance, DistanceUnit.METRIC, {
                simplifyLargeValues: true,
            });

            return { label: displayName, value: formattedDistance };
        })
        .map(({ label, value }, index) => <AttributeListItem key={index} label={label} value={value} />);

    return (
        <>
            <AttributeListSpace />
            <AttributeListItem label={t("map.distance")} boldLabel />
            <AttributeList>{measurementPointDistances}</AttributeList>
        </>
    );
};
