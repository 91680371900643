import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CanvasContainer } from "../../appearance/CanvasContainer";
import { Colors, CustomColors } from "../../appearance/Colors";
import { t } from "i18next";
import { Modal, SharedModalProps } from "../../appearance/modal/Modal";

const CANVAS_WIDTH = "550";
const CANVAS_HEIGHT = "190";

const Container = styled.div`
    width: ${CANVAS_WIDTH}px;
    height: ${CANVAS_HEIGHT}px;
`;

export const AltitudeExplanation = ({ onClosed }: SharedModalProps): JSX.Element => {
    // Properties

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isCanvasReady, setIsCanvasReady] = useState(false);

    useEffect(() => {
        /**
         * Canvas ref current is null when the component is first rendered.
         * That's why we use a state variable to indicate when the canvas is ready.
         * This works in combination with `setIsCanvasReady(!!el)` in the canvas ref callback.
         */
        const context = canvasRef.current?.getContext("2d");
        if (!context) {
            // Canvas not ready yet
            return;
        }

        drawCanvas(context);
    }, [isCanvasReady]);

    const drawCanvas = (ctx: CanvasRenderingContext2D): void => {
        /**
         * base styles
         */
        ctx.fillStyle = CustomColors.altitudeExplanation.fillColor;
        ctx.lineWidth = 1;
        ctx.lineJoin = "miter";
        ctx.setLineDash([0]);
        ctx.font = "16pt Roboto";

        /**
         * draw terrain (polygon)
         */
        ctx.strokeStyle = CustomColors.altitudeExplanation.lineColorShade;

        ctx.beginPath();
        ctx.moveTo(0, 143);
        ctx.bezierCurveTo(0, 143, 30, 134, 40, 133);
        ctx.bezierCurveTo(57, 132, 90, 129, 107, 128);
        ctx.bezierCurveTo(114, 128, 129, 133, 136, 132);
        ctx.bezierCurveTo(141, 131, 150, 126, 154, 123);
        ctx.bezierCurveTo(166, 112, 189, 77, 200, 75);
        ctx.bezierCurveTo(208, 74, 231, 99, 239, 101);
        ctx.bezierCurveTo(242, 102, 248, 98, 251, 97);
        ctx.bezierCurveTo(256, 96, 266, 98, 271, 98);
        ctx.bezierCurveTo(281, 99, 303, 102, 314, 103);
        ctx.bezierCurveTo(316, 103, 320, 103, 322, 104);
        ctx.bezierCurveTo(330, 105, 346, 105, 353, 108);
        ctx.bezierCurveTo(359, 110, 372, 121, 379, 123);
        ctx.bezierCurveTo(385, 125, 397, 136, 404, 138);
        ctx.bezierCurveTo(406, 139, 410, 141, 413, 142);
        ctx.bezierCurveTo(418, 144, 433, 146, 439, 148);
        ctx.lineTo(550, 156);
        ctx.lineTo(0, 156);
        ctx.fill();
        ctx.closePath();

        /**
         * reset strokeStyle
         */
        ctx.strokeStyle = CustomColors.altitudeExplanation.lineColor;

        /**
         * draw radar (circle)
         */
        ctx.beginPath();
        ctx.arc(282.2, 36.6, 14.5, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        /**
         * draw radar tripod (triangle)
         */
        const tripodBottomY = 59.3;
        ctx.beginPath();
        ctx.moveTo(273.9, tripodBottomY);
        ctx.lineTo(282, 52.1);
        ctx.lineTo(289.4, tripodBottomY);
        ctx.stroke();
        ctx.closePath();

        /**
         * draw radar crossing lines
         */
        ctx.beginPath();
        ctx.moveTo(271.9, 26.3);
        ctx.lineTo(292.5, 46.9);
        ctx.moveTo(271.9, 46.9);
        ctx.lineTo(292.5, 26.3);
        ctx.stroke();
        ctx.closePath();

        /**
         * draw building outline (rectangle)
         */
        ctx.beginPath();
        ctx.moveTo(296.7, 15.1);
        ctx.stroke();
        ctx.closePath();

        /**
         * draw building (rectangle)
         */
        ctx.beginPath();
        ctx.rect(265.7, tripodBottomY, 33.1, 55.8);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();

        /**
         * reset lineWidth and line style
         */
        ctx.lineWidth = 3;
        ctx.setLineDash([14, 10]);

        /**
         * horizontal anchor point definitions
         */
        const leftSide = 12;
        const rightSide = 538;
        const radarCenterX = 282.2;
        const radarCenterY = 36.6;

        /**
         * draw the radar altitude line
         */
        ctx.strokeStyle = Colors.secondary.red;
        ctx.beginPath();
        ctx.moveTo(radarCenterX, radarCenterY);
        ctx.lineTo(rightSide, radarCenterY);
        ctx.stroke();
        ctx.closePath();

        /**
         * draw the reference plane line
         */
        const referencePlaneY = 130;
        ctx.strokeStyle = Colors.secondary.blue;
        ctx.beginPath();
        ctx.moveTo(leftSide, referencePlaneY);
        ctx.lineTo(160, referencePlaneY);
        ctx.stroke();
        ctx.closePath();

        /**
         * draw WGS84 line + text
         */
        const wgs84Y = 150;
        ctx.strokeStyle = Colors.secondary.green;
        ctx.beginPath();
        ctx.moveTo(leftSide, wgs84Y);
        ctx.lineTo(rightSide, wgs84Y);
        ctx.stroke();
        ctx.closePath();

        ctx.fillStyle = Colors.secondary.green;
        ctx.textAlign = "right";
        ctx.fillText(t("altitudeExplanation.wgs84"), 530, wgs84Y + 20);

        /**
         * reset styles for indicators
         */
        ctx.fillStyle = Colors.text.text200;
        ctx.setLineDash([0]);
        ctx.strokeStyle = Colors.text.text200;

        /**
         * draw ground level arrow + text
         */
        const groundLevelArrowX = 150;
        ctx.beginPath();
        ctx.moveTo(groundLevelArrowX, referencePlaneY);
        ctx.lineTo(groundLevelArrowX, wgs84Y);

        // draw arrowhead at start of line
        let angle = Math.atan2(referencePlaneY - wgs84Y, 0);
        ctx.moveTo(
            groundLevelArrowX - 10 * Math.cos(angle - Math.PI / 6),
            referencePlaneY - 10 * Math.sin(angle - Math.PI / 6),
        );
        ctx.lineTo(groundLevelArrowX, referencePlaneY);
        ctx.lineTo(
            groundLevelArrowX - 10 * Math.cos(angle + Math.PI / 6),
            referencePlaneY - 10 * Math.sin(angle + Math.PI / 6),
        );

        // draw arrowhead at end of line
        angle = Math.atan2(wgs84Y - 59.3, 0);
        ctx.moveTo(groundLevelArrowX - 10 * Math.cos(angle - Math.PI / 6), wgs84Y - 10 * Math.sin(angle - Math.PI / 6));
        ctx.lineTo(groundLevelArrowX, wgs84Y);
        ctx.lineTo(groundLevelArrowX - 10 * Math.cos(angle + Math.PI / 6), wgs84Y - 10 * Math.sin(angle + Math.PI / 6));

        ctx.stroke();
        ctx.closePath();

        // draw text
        ctx.textAlign = "left";
        ctx.fillText(t("altitudeExplanation.groundLevel"), groundLevelArrowX + 15, wgs84Y - 10);

        /**
         * draw radar altitude arrow + text
         */
        const radarAltitudeArrowX = 380;
        ctx.beginPath();
        ctx.moveTo(radarAltitudeArrowX, radarCenterY);
        ctx.lineTo(radarAltitudeArrowX, wgs84Y);

        // draw arrowhead at start of line
        angle = Math.atan2(radarCenterY - wgs84Y, 0);
        ctx.moveTo(
            radarAltitudeArrowX - 10 * Math.cos(angle - Math.PI / 6),
            radarCenterY - 10 * Math.sin(angle - Math.PI / 6),
        );
        ctx.lineTo(radarAltitudeArrowX, radarCenterY);
        ctx.lineTo(
            radarAltitudeArrowX - 10 * Math.cos(angle + Math.PI / 6),
            radarCenterY - 10 * Math.sin(angle + Math.PI / 6),
        );

        // draw arrowhead at end of line
        angle = Math.atan2(wgs84Y - radarCenterY, 0);
        ctx.moveTo(
            radarAltitudeArrowX - 10 * Math.cos(angle - Math.PI / 6),
            wgs84Y - 10 * Math.sin(angle - Math.PI / 6),
        );
        ctx.lineTo(radarAltitudeArrowX, wgs84Y);
        ctx.lineTo(
            radarAltitudeArrowX - 10 * Math.cos(angle + Math.PI / 6),
            wgs84Y - 10 * Math.sin(angle + Math.PI / 6),
        );

        ctx.stroke();
        ctx.closePath();

        // draw vertical line
        ctx.beginPath();
        ctx.moveTo(radarAltitudeArrowX, radarCenterY);
        ctx.lineTo(radarAltitudeArrowX, wgs84Y);
        ctx.stroke();
        ctx.closePath();

        // draw text
        ctx.textAlign = "left";
        ctx.fillText(t("altitudeExplanation.radarOffset"), radarAltitudeArrowX + 10, 90.85);
    };

    // Render

    return (
        <Modal title={t("altitudeExplanation.altitudeExplanation")} onClosed={onClosed}>
            <Container>
                <CanvasContainer>
                    <canvas
                        ref={(el) => {
                            canvasRef.current = el;
                            setIsCanvasReady(!!el);
                        }}
                        width={CANVAS_WIDTH}
                        height={CANVAS_HEIGHT}
                    />
                </CanvasContainer>
            </Container>
        </Modal>
    );
};
