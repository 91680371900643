import mapboxgl from "mapbox-gl";
import { MapModule } from "../MapModule";
import { RadarBlankingSectorsModuleViewModel } from "./RadarBlankingSectorsModuleViewModel";
import { ORDER_LAYER_RADAR_BLANKING_SECTORS, ORDER_LAYER_RADAR_BLANKING_SECTORS_PREVIEW } from "../Orders";
import {
    RadarBlankingSectorsLayer,
    RadarBlankingSectorsPreviewLayer,
    BLANKING_SECTORS_LAYER_ID,
    BLANKING_SECTOR_RADAR_ICON_LAYER_ID,
    BLANKING_SECTOR_HANDLES_LAYER_ID,
} from "../../layers";
import { BlankingSectorsState, Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";
import { LAYER_ID_BACKGROUND_DIM } from "../dimcolor/DimColorModule";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export class RadarBlankingSectorsModule extends MapModule<RadarBlankingSectorsModuleViewModel> {
    // Properties

    private onBlankingSectorsUpdated = new Rx.Subject<boolean>();

    private lastBlankingSectorState: BlankingSectorsState | undefined;

    public constructor(protected readonly viewModel: RadarBlankingSectorsModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const blankingSectorsLayer = RadarBlankingSectorsLayer.attachedTo(
            map,
            ORDER_LAYER_RADAR_BLANKING_SECTORS.id,
            (indexOfMapFeature, updatedSector) => {
                const sectorsBeforeUpdate = this.lastBlankingSectorState?.blankingSectors;
                const sectorsAfterUpdate = sectorsBeforeUpdate ? [...sectorsBeforeUpdate] : [];
                let indexOfUpdatedSector = indexOfMapFeature;
                if (sectorsBeforeUpdate) {
                    let increment = 0;
                    sectorsBeforeUpdate.forEach((sector, index) => {
                        /**
                         * We are looking for the index of the sector to update in the `sectorsBeforeUpdate` array and `sectorsBeforeUpdate` includes disabled sectors.
                         * However, the array of blanking sector features that the Map is using does not include disabled sectors.
                         * For example, if we have 2 sectors, the first disabled and second enabled, the Map will only have 1 feature and `indexOfMapFeature` will be 0 while we are looking for sector with index 1.
                         */
                        if (sector.enabled && index === indexOfMapFeature + increment) {
                            indexOfUpdatedSector = index;
                        } else {
                            increment++;
                        }
                    });
                }
                sectorsAfterUpdate[indexOfUpdatedSector] = updatedSector;
                this.viewModel.setBlankingSectorsState({
                    ...this.lastBlankingSectorState!,
                    blankingSectors: sectorsAfterUpdate,
                });
            },
        );
        const blankingSectorsPreviewLayer = RadarBlankingSectorsPreviewLayer.attachedTo(
            map,
            ORDER_LAYER_RADAR_BLANKING_SECTORS_PREVIEW.id,
        );

        // Subscribe to radars
        let subscription = this.viewModel.radars.subscribe((value) => blankingSectorsLayer.setRadars(value));
        this.collectSubscription(subscription);

        // Subscribe to blanking sectors state and update blanking sectors layer
        subscription = this.viewModel.blankingSectorsState.subscribe((value) => {
            this.lastBlankingSectorState = value;
            blankingSectorsLayer.setBlankingSectorState(value.isEditModeActive, value.radarId, value.blankingSectors);
            if (value.isEditModeActive) {
                layerManager.setAllEnabled(
                    false,
                    [
                        BLANKING_SECTORS_LAYER_ID,
                        BLANKING_SECTOR_RADAR_ICON_LAYER_ID,
                        BLANKING_SECTOR_HANDLES_LAYER_ID,
                        LAYER_ID_BACKGROUND_DIM,
                    ],
                    true,
                );
            } else {
                layerManager.restoreState();
            }
            this.onBlankingSectorsUpdated.next(true);
        });
        this.collectSubscription(subscription);

        // Subscribe to edit mode state and update blanking sectors layer state
        subscription = this.viewModel.blankingSectorsState
            .pipe(
                RxOperators.map((b) => b.isEditModeActive),
                RxOperators.distinctUntilChanged(),
            )
            .subscribe((value) => blankingSectorsLayer.setEnabled(value));

        // Subscribe to blanking sectors state and update blanking sectors layer
        subscription = this.viewModel.radars
            .pipe(
                RxOperators.take(1),
                RxOperators.repeat({ delay: () => this.onBlankingSectorsUpdated }),
                RxOperators.debounceTime(1000),
                RxOperators.mergeMap((radars) =>
                    Rx.from(radars).pipe(
                        RxOperators.mergeMap((r) => Rx.zip(Rx.of(r), this.viewModel.getBlankingSectors(r.id))),
                        RxOperators.toArray(),
                    ),
                ),
                RxOperators.distinctUntilChanged(),
            )
            .subscribe((radarsAndSectors) => blankingSectorsPreviewLayer.setRadarsAndSectors(radarsAndSectors));

        this.collectSubscription(subscription);

        // Subscribe to local preferences and update preview layer state
        subscription = this.viewModel.previewEnabled.subscribe((value) => {
            blankingSectorsPreviewLayer.setEnabled(value);
        });
        this.collectSubscription(subscription);
    }
}
