// source: tracklist3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
goog.exportSymbol('proto.robin.proto3.tracklist.AreaEntryExit', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.AxisType', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.ClassificationHistoryItem', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.DroneAlarmHistory', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.DroneAlarmType', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.Estimate', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.FlightPhaseType', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.Observation', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.Radar', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.RadarOrientation', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.RadarUpdate', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.ReplayTrackDataRequest', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.Track', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackAlertType', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackData', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackDataUpdates', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackListUpdate', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackPlotType', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.AddEstimate', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.AddObservation', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.Classification', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.DropTrack', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.FlightInfo', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.FuseTrack', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.NewTrack', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TrackUpdate.UpdatetypeCase', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TracksHistogram', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TracksHistogramRequest', null, global);
goog.exportSymbol('proto.robin.proto3.tracklist.TracksOnAirstripUpdate', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.Estimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.Estimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.Estimate.displayName = 'proto.robin.proto3.tracklist.Estimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.Observation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.Observation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.Observation.displayName = 'proto.robin.proto3.tracklist.Observation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.AreaEntryExit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.AreaEntryExit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.AreaEntryExit.displayName = 'proto.robin.proto3.tracklist.AreaEntryExit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.DroneAlarmHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.DroneAlarmHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.DroneAlarmHistory.displayName = 'proto.robin.proto3.tracklist.DroneAlarmHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.ClassificationHistoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.ClassificationHistoryItem.displayName = 'proto.robin.proto3.tracklist.ClassificationHistoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.Track = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.Track.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.Track, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.Track.displayName = 'proto.robin.proto3.tracklist.Track';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.displayName = 'proto.robin.proto3.tracklist.TrackUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.NewTrack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.NewTrack.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.NewTrack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.AddEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.AddEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.AddObservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.AddObservation.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.AddObservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.DropTrack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.DropTrack.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.DropTrack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.Classification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.Classification.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.Classification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.FuseTrack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.FuseTrack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.FlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.FlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.displayName = 'proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.TrackData.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackData.displayName = 'proto.robin.proto3.tracklist.TrackData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackListUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.TrackListUpdate.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackListUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackListUpdate.displayName = 'proto.robin.proto3.tracklist.TrackListUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TrackDataUpdates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.TrackDataUpdates.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.TrackDataUpdates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TrackDataUpdates.displayName = 'proto.robin.proto3.tracklist.TrackDataUpdates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.RadarOrientation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.RadarOrientation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.RadarOrientation.displayName = 'proto.robin.proto3.tracklist.RadarOrientation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.RadarUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.RadarUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.RadarUpdate.displayName = 'proto.robin.proto3.tracklist.RadarUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.Radar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.Radar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.Radar.displayName = 'proto.robin.proto3.tracklist.Radar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TracksOnAirstripUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TracksOnAirstripUpdate.displayName = 'proto.robin.proto3.tracklist.TracksOnAirstripUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.ReplayTrackDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.ReplayTrackDataRequest.displayName = 'proto.robin.proto3.tracklist.ReplayTrackDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TracksHistogramRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.tracklist.TracksHistogramRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TracksHistogramRequest.displayName = 'proto.robin.proto3.tracklist.TracksHistogramRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.tracklist.TracksHistogram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.tracklist.TracksHistogram.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.tracklist.TracksHistogram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.tracklist.TracksHistogram.displayName = 'proto.robin.proto3.tracklist.TracksHistogram';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.Estimate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.Estimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.Estimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Estimate.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    velocity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    plottype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    rssiteid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.Estimate}
 */
proto.robin.proto3.tracklist.Estimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.Estimate;
  return proto.robin.proto3.tracklist.Estimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.Estimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.Estimate}
 */
proto.robin.proto3.tracklist.Estimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVelocity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBearing(value);
      break;
    case 6:
      var value = /** @type {!proto.robin.proto3.tracklist.TrackPlotType} */ (reader.readEnum());
      msg.setPlottype(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRssiteid(value);
      break;
    case 10:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.Estimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.Estimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.Estimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Estimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVelocity();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getPlottype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRssiteid();
  if (f !== 0) {
    writer.writeSint32(
      7,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.Estimate.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
 */
proto.robin.proto3.tracklist.Estimate.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float velocity = 4;
 * @return {number}
 */
proto.robin.proto3.tracklist.Estimate.prototype.getVelocity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
 */
proto.robin.proto3.tracklist.Estimate.prototype.setVelocity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float bearing = 5;
 * @return {number}
 */
proto.robin.proto3.tracklist.Estimate.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
 */
proto.robin.proto3.tracklist.Estimate.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional TrackPlotType plotType = 6;
 * @return {!proto.robin.proto3.tracklist.TrackPlotType}
 */
proto.robin.proto3.tracklist.Estimate.prototype.getPlottype = function() {
  return /** @type {!proto.robin.proto3.tracklist.TrackPlotType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackPlotType} value
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
 */
proto.robin.proto3.tracklist.Estimate.prototype.setPlottype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional sint32 rsSiteId = 7;
 * @return {number}
 */
proto.robin.proto3.tracklist.Estimate.prototype.getRssiteid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
 */
proto.robin.proto3.tracklist.Estimate.prototype.setRssiteid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 10;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.tracklist.Estimate.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 10));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
*/
proto.robin.proto3.tracklist.Estimate.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.Estimate} returns this
 */
proto.robin.proto3.tracklist.Estimate.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.Estimate.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.Observation.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.Observation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.Observation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Observation.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    user: jspb.Message.getFieldWithDefault(msg, 5, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    speciesid: jspb.Message.getFieldWithDefault(msg, 7, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.Observation}
 */
proto.robin.proto3.tracklist.Observation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.Observation;
  return proto.robin.proto3.tracklist.Observation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.Observation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.Observation}
 */
proto.robin.proto3.tracklist.Observation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSpeciesid(value);
      break;
    case 10:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.Observation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.Observation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.Observation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Observation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSpeciesid();
  if (f !== 0) {
    writer.writeSint64(
      7,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.Observation.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Observation} returns this
 */
proto.robin.proto3.tracklist.Observation.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user = 5;
 * @return {string}
 */
proto.robin.proto3.tracklist.Observation.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.Observation} returns this
 */
proto.robin.proto3.tracklist.Observation.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.robin.proto3.tracklist.Observation.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.Observation} returns this
 */
proto.robin.proto3.tracklist.Observation.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional sint64 speciesId = 7;
 * @return {number}
 */
proto.robin.proto3.tracklist.Observation.prototype.getSpeciesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Observation} returns this
 */
proto.robin.proto3.tracklist.Observation.prototype.setSpeciesid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 10;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.tracklist.Observation.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 10));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.tracklist.Observation} returns this
*/
proto.robin.proto3.tracklist.Observation.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.Observation} returns this
 */
proto.robin.proto3.tracklist.Observation.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.Observation.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.AreaEntryExit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.AreaEntryExit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.AreaEntryExit.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shapetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isin: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit}
 */
proto.robin.proto3.tracklist.AreaEntryExit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.AreaEntryExit;
  return proto.robin.proto3.tracklist.AreaEntryExit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.AreaEntryExit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit}
 */
proto.robin.proto3.tracklist.AreaEntryExit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaname(value);
      break;
    case 5:
      var value = /** @type {!proto.robin.proto3.ShapeType} */ (reader.readEnum());
      msg.setShapetype(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.AreaEntryExit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.AreaEntryExit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.AreaEntryExit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShapetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsin();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string areaName = 1;
 * @return {string}
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.getAreaname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit} returns this
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.setAreaname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional robin.proto3.ShapeType shapeType = 5;
 * @return {!proto.robin.proto3.ShapeType}
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.getShapetype = function() {
  return /** @type {!proto.robin.proto3.ShapeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.robin.proto3.ShapeType} value
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit} returns this
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.setShapetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool isIn = 6;
 * @return {boolean}
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.getIsin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit} returns this
 */
proto.robin.proto3.tracklist.AreaEntryExit.prototype.setIsin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.DroneAlarmHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.DroneAlarmHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampStartMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestampEndMsec: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.DroneAlarmHistory}
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.DroneAlarmHistory;
  return proto.robin.proto3.tracklist.DroneAlarmHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.DroneAlarmHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.DroneAlarmHistory}
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampStartMsec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampEndMsec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.DroneAlarmHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.DroneAlarmHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampStartMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTimestampEndMsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp_start_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.prototype.getTimestampStartMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.DroneAlarmHistory} returns this
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.prototype.setTimestampStartMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timestamp_end_msec = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.prototype.getTimestampEndMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.DroneAlarmHistory} returns this
 */
proto.robin.proto3.tracklist.DroneAlarmHistory.prototype.setTimestampEndMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.ClassificationHistoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.ClassificationHistoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    classification: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.ClassificationHistoryItem}
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.ClassificationHistoryItem;
  return proto.robin.proto3.tracklist.ClassificationHistoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.ClassificationHistoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.ClassificationHistoryItem}
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.ClassificationHistoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.ClassificationHistoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getClassification();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.ClassificationHistoryItem} returns this
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string classification = 2;
 * @return {string}
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.prototype.getClassification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.ClassificationHistoryItem} returns this
 */
proto.robin.proto3.tracklist.ClassificationHistoryItem.prototype.setClassification = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.Track.repeatedFields_ = [3,23,24,25,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.Track.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.Track.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.Track} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Track.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    icao: jspb.Message.getFieldWithDefault(msg, 2, 0),
    estimatesList: jspb.Message.toObjectList(msg.getEstimatesList(),
    proto.robin.proto3.tracklist.Estimate.toObject, includeInstance),
    classification: jspb.Message.getFieldWithDefault(msg, 4, ""),
    flightphase: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tracktype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    alerttype: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dronealarmtype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    databaseid: jspb.Message.getFieldWithDefault(msg, 12, 0),
    classificationhistoryList: jspb.Message.toObjectList(msg.getClassificationhistoryList(),
    proto.robin.proto3.tracklist.ClassificationHistoryItem.toObject, includeInstance),
    observationsList: jspb.Message.toObjectList(msg.getObservationsList(),
    proto.robin.proto3.tracklist.Observation.toObject, includeInstance),
    areaentryexitsList: jspb.Message.toObjectList(msg.getAreaentryexitsList(),
    proto.robin.proto3.tracklist.AreaEntryExit.toObject, includeInstance),
    dronealarmhistoryList: jspb.Message.toObjectList(msg.getDronealarmhistoryList(),
    proto.robin.proto3.tracklist.DroneAlarmHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.Track}
 */
proto.robin.proto3.tracklist.Track.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.Track;
  return proto.robin.proto3.tracklist.Track.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.Track} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.Track}
 */
proto.robin.proto3.tracklist.Track.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setIcao(value);
      break;
    case 3:
      var value = new proto.robin.proto3.tracklist.Estimate;
      reader.readMessage(value,proto.robin.proto3.tracklist.Estimate.deserializeBinaryFromReader);
      msg.addEstimates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassification(value);
      break;
    case 5:
      var value = /** @type {!proto.robin.proto3.tracklist.FlightPhaseType} */ (reader.readEnum());
      msg.setFlightphase(value);
      break;
    case 6:
      var value = /** @type {!proto.robin.proto3.tracklist.TrackPlotType} */ (reader.readEnum());
      msg.setTracktype(value);
      break;
    case 7:
      var value = /** @type {!proto.robin.proto3.tracklist.TrackAlertType} */ (reader.readEnum());
      msg.setAlerttype(value);
      break;
    case 8:
      var value = /** @type {!proto.robin.proto3.tracklist.DroneAlarmType} */ (reader.readEnum());
      msg.setDronealarmtype(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setDatabaseid(value);
      break;
    case 23:
      var value = new proto.robin.proto3.tracklist.ClassificationHistoryItem;
      reader.readMessage(value,proto.robin.proto3.tracklist.ClassificationHistoryItem.deserializeBinaryFromReader);
      msg.addClassificationhistory(value);
      break;
    case 24:
      var value = new proto.robin.proto3.tracklist.Observation;
      reader.readMessage(value,proto.robin.proto3.tracklist.Observation.deserializeBinaryFromReader);
      msg.addObservations(value);
      break;
    case 25:
      var value = new proto.robin.proto3.tracklist.AreaEntryExit;
      reader.readMessage(value,proto.robin.proto3.tracklist.AreaEntryExit.deserializeBinaryFromReader);
      msg.addAreaentryexits(value);
      break;
    case 26:
      var value = new proto.robin.proto3.tracklist.DroneAlarmHistory;
      reader.readMessage(value,proto.robin.proto3.tracklist.DroneAlarmHistory.deserializeBinaryFromReader);
      msg.addDronealarmhistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.Track.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.Track.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.Track} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Track.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getIcao();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getEstimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.tracklist.Estimate.serializeBinaryToWriter
    );
  }
  f = message.getClassification();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFlightphase();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTracktype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAlerttype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDronealarmtype();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDatabaseid();
  if (f !== 0) {
    writer.writeSint64(
      12,
      f
    );
  }
  f = message.getClassificationhistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.robin.proto3.tracklist.ClassificationHistoryItem.serializeBinaryToWriter
    );
  }
  f = message.getObservationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.robin.proto3.tracklist.Observation.serializeBinaryToWriter
    );
  }
  f = message.getAreaentryexitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.robin.proto3.tracklist.AreaEntryExit.serializeBinaryToWriter
    );
  }
  f = message.getDronealarmhistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.robin.proto3.tracklist.DroneAlarmHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.Track.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 icao = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.Track.prototype.getIcao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setIcao = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Estimate estimates = 3;
 * @return {!Array<!proto.robin.proto3.tracklist.Estimate>}
 */
proto.robin.proto3.tracklist.Track.prototype.getEstimatesList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.Estimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.Estimate, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.Estimate>} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
*/
proto.robin.proto3.tracklist.Track.prototype.setEstimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.Estimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.Estimate}
 */
proto.robin.proto3.tracklist.Track.prototype.addEstimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.tracklist.Estimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.clearEstimatesList = function() {
  return this.setEstimatesList([]);
};


/**
 * optional string classification = 4;
 * @return {string}
 */
proto.robin.proto3.tracklist.Track.prototype.getClassification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setClassification = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional FlightPhaseType flightPhase = 5;
 * @return {!proto.robin.proto3.tracklist.FlightPhaseType}
 */
proto.robin.proto3.tracklist.Track.prototype.getFlightphase = function() {
  return /** @type {!proto.robin.proto3.tracklist.FlightPhaseType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.FlightPhaseType} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setFlightphase = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TrackPlotType trackType = 6;
 * @return {!proto.robin.proto3.tracklist.TrackPlotType}
 */
proto.robin.proto3.tracklist.Track.prototype.getTracktype = function() {
  return /** @type {!proto.robin.proto3.tracklist.TrackPlotType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackPlotType} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setTracktype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional TrackAlertType alertType = 7;
 * @return {!proto.robin.proto3.tracklist.TrackAlertType}
 */
proto.robin.proto3.tracklist.Track.prototype.getAlerttype = function() {
  return /** @type {!proto.robin.proto3.tracklist.TrackAlertType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackAlertType} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setAlerttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional DroneAlarmType droneAlarmType = 8;
 * @return {!proto.robin.proto3.tracklist.DroneAlarmType}
 */
proto.robin.proto3.tracklist.Track.prototype.getDronealarmtype = function() {
  return /** @type {!proto.robin.proto3.tracklist.DroneAlarmType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.DroneAlarmType} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setDronealarmtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional sint64 databaseid = 12;
 * @return {number}
 */
proto.robin.proto3.tracklist.Track.prototype.getDatabaseid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.setDatabaseid = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated ClassificationHistoryItem classificationHistory = 23;
 * @return {!Array<!proto.robin.proto3.tracklist.ClassificationHistoryItem>}
 */
proto.robin.proto3.tracklist.Track.prototype.getClassificationhistoryList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.ClassificationHistoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.ClassificationHistoryItem, 23));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.ClassificationHistoryItem>} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
*/
proto.robin.proto3.tracklist.Track.prototype.setClassificationhistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.ClassificationHistoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.ClassificationHistoryItem}
 */
proto.robin.proto3.tracklist.Track.prototype.addClassificationhistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.robin.proto3.tracklist.ClassificationHistoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.clearClassificationhistoryList = function() {
  return this.setClassificationhistoryList([]);
};


/**
 * repeated Observation observations = 24;
 * @return {!Array<!proto.robin.proto3.tracklist.Observation>}
 */
proto.robin.proto3.tracklist.Track.prototype.getObservationsList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.Observation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.Observation, 24));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.Observation>} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
*/
proto.robin.proto3.tracklist.Track.prototype.setObservationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.Observation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.Observation}
 */
proto.robin.proto3.tracklist.Track.prototype.addObservations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.robin.proto3.tracklist.Observation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.clearObservationsList = function() {
  return this.setObservationsList([]);
};


/**
 * repeated AreaEntryExit areaentryexits = 25;
 * @return {!Array<!proto.robin.proto3.tracklist.AreaEntryExit>}
 */
proto.robin.proto3.tracklist.Track.prototype.getAreaentryexitsList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.AreaEntryExit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.AreaEntryExit, 25));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.AreaEntryExit>} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
*/
proto.robin.proto3.tracklist.Track.prototype.setAreaentryexitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.AreaEntryExit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit}
 */
proto.robin.proto3.tracklist.Track.prototype.addAreaentryexits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.robin.proto3.tracklist.AreaEntryExit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.clearAreaentryexitsList = function() {
  return this.setAreaentryexitsList([]);
};


/**
 * repeated DroneAlarmHistory dronealarmhistory = 26;
 * @return {!Array<!proto.robin.proto3.tracklist.DroneAlarmHistory>}
 */
proto.robin.proto3.tracklist.Track.prototype.getDronealarmhistoryList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.DroneAlarmHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.DroneAlarmHistory, 26));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.DroneAlarmHistory>} value
 * @return {!proto.robin.proto3.tracklist.Track} returns this
*/
proto.robin.proto3.tracklist.Track.prototype.setDronealarmhistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.DroneAlarmHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.DroneAlarmHistory}
 */
proto.robin.proto3.tracklist.Track.prototype.addDronealarmhistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.robin.proto3.tracklist.DroneAlarmHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.Track} returns this
 */
proto.robin.proto3.tracklist.Track.prototype.clearDronealarmhistoryList = function() {
  return this.setDronealarmhistoryList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_ = [[5,6,7,8,9,11,13,14,15,16,17]];

/**
 * @enum {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.UpdatetypeCase = {
  UPDATETYPE_NOT_SET: 0,
  NEWTRACK: 5,
  ADDESTIMATE: 6,
  ADDOBSERVATION: 7,
  DROPTRACK: 8,
  CLASSIFICATION: 9,
  RESYNCTRACKLIST: 11,
  FUSETRACK: 13,
  DATABASEIDCHANGE: 14,
  FLIGHTINFO: 15,
  AREAENTRYEXITCHANGE: 16,
  DRONEALARMCHANGE: 17
};

/**
 * @return {proto.robin.proto3.tracklist.TrackUpdate.UpdatetypeCase}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getUpdatetypeCase = function() {
  return /** @type {proto.robin.proto3.tracklist.TrackUpdate.UpdatetypeCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    newtrack: (f = msg.getNewtrack()) && proto.robin.proto3.tracklist.TrackUpdate.NewTrack.toObject(includeInstance, f),
    addestimate: (f = msg.getAddestimate()) && proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.toObject(includeInstance, f),
    addobservation: (f = msg.getAddobservation()) && proto.robin.proto3.tracklist.TrackUpdate.AddObservation.toObject(includeInstance, f),
    droptrack: (f = msg.getDroptrack()) && proto.robin.proto3.tracklist.TrackUpdate.DropTrack.toObject(includeInstance, f),
    classification: (f = msg.getClassification()) && proto.robin.proto3.tracklist.TrackUpdate.Classification.toObject(includeInstance, f),
    resynctracklist: (f = msg.getResynctracklist()) && proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.toObject(includeInstance, f),
    fusetrack: (f = msg.getFusetrack()) && proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.toObject(includeInstance, f),
    databaseidchange: (f = msg.getDatabaseidchange()) && proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.toObject(includeInstance, f),
    flightinfo: (f = msg.getFlightinfo()) && proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.toObject(includeInstance, f),
    areaentryexitchange: (f = msg.getAreaentryexitchange()) && proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.toObject(includeInstance, f),
    dronealarmchange: (f = msg.getDronealarmchange()) && proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate}
 */
proto.robin.proto3.tracklist.TrackUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate;
  return proto.robin.proto3.tracklist.TrackUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate}
 */
proto.robin.proto3.tracklist.TrackUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrackid(value);
      break;
    case 5:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.NewTrack;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.NewTrack.deserializeBinaryFromReader);
      msg.setNewtrack(value);
      break;
    case 6:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.AddEstimate;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.deserializeBinaryFromReader);
      msg.setAddestimate(value);
      break;
    case 7:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.AddObservation;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.AddObservation.deserializeBinaryFromReader);
      msg.setAddobservation(value);
      break;
    case 8:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.DropTrack;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.DropTrack.deserializeBinaryFromReader);
      msg.setDroptrack(value);
      break;
    case 9:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.Classification;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.Classification.deserializeBinaryFromReader);
      msg.setClassification(value);
      break;
    case 11:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.deserializeBinaryFromReader);
      msg.setResynctracklist(value);
      break;
    case 13:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.FuseTrack;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.deserializeBinaryFromReader);
      msg.setFusetrack(value);
      break;
    case 14:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.deserializeBinaryFromReader);
      msg.setDatabaseidchange(value);
      break;
    case 15:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.FlightInfo;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.deserializeBinaryFromReader);
      msg.setFlightinfo(value);
      break;
    case 16:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.deserializeBinaryFromReader);
      msg.setAreaentryexitchange(value);
      break;
    case 17:
      var value = new proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.deserializeBinaryFromReader);
      msg.setDronealarmchange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getNewtrack();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.NewTrack.serializeBinaryToWriter
    );
  }
  f = message.getAddestimate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.serializeBinaryToWriter
    );
  }
  f = message.getAddobservation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.AddObservation.serializeBinaryToWriter
    );
  }
  f = message.getDroptrack();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.DropTrack.serializeBinaryToWriter
    );
  }
  f = message.getClassification();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.Classification.serializeBinaryToWriter
    );
  }
  f = message.getResynctracklist();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.serializeBinaryToWriter
    );
  }
  f = message.getFusetrack();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.serializeBinaryToWriter
    );
  }
  f = message.getDatabaseidchange();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.serializeBinaryToWriter
    );
  }
  f = message.getFlightinfo();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getAreaentryexitchange();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.serializeBinaryToWriter
    );
  }
  f = message.getDronealarmchange();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.NewTrack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.toObject = function(includeInstance, msg) {
  var f, obj = {
    track: (f = msg.getTrack()) && proto.robin.proto3.tracklist.Track.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.NewTrack;
  return proto.robin.proto3.tracklist.TrackUpdate.NewTrack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.tracklist.Track;
      reader.readMessage(value,proto.robin.proto3.tracklist.Track.deserializeBinaryFromReader);
      msg.setTrack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.NewTrack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrack();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.robin.proto3.tracklist.Track.serializeBinaryToWriter
    );
  }
};


/**
 * optional Track track = 1;
 * @return {?proto.robin.proto3.tracklist.Track}
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.prototype.getTrack = function() {
  return /** @type{?proto.robin.proto3.tracklist.Track} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.Track, 1));
};


/**
 * @param {?proto.robin.proto3.tracklist.Track|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.prototype.setTrack = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.NewTrack} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.prototype.clearTrack = function() {
  return this.setTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.NewTrack.prototype.hasTrack = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimate: (f = msg.getEstimate()) && proto.robin.proto3.tracklist.Estimate.toObject(includeInstance, f),
    tracktype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.AddEstimate;
  return proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.tracklist.Estimate;
      reader.readMessage(value,proto.robin.proto3.tracklist.Estimate.deserializeBinaryFromReader);
      msg.setEstimate(value);
      break;
    case 2:
      var value = /** @type {!proto.robin.proto3.tracklist.TrackPlotType} */ (reader.readEnum());
      msg.setTracktype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.robin.proto3.tracklist.Estimate.serializeBinaryToWriter
    );
  }
  f = message.getTracktype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Estimate estimate = 1;
 * @return {?proto.robin.proto3.tracklist.Estimate}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.getEstimate = function() {
  return /** @type{?proto.robin.proto3.tracklist.Estimate} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.Estimate, 1));
};


/**
 * @param {?proto.robin.proto3.tracklist.Estimate|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.setEstimate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.clearEstimate = function() {
  return this.setEstimate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.hasEstimate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TrackPlotType trackType = 2;
 * @return {!proto.robin.proto3.tracklist.TrackPlotType}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.getTracktype = function() {
  return /** @type {!proto.robin.proto3.tracklist.TrackPlotType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackPlotType} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.AddEstimate.prototype.setTracktype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.AddObservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.toObject = function(includeInstance, msg) {
  var f, obj = {
    observation: (f = msg.getObservation()) && proto.robin.proto3.tracklist.Observation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.AddObservation;
  return proto.robin.proto3.tracklist.TrackUpdate.AddObservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.tracklist.Observation;
      reader.readMessage(value,proto.robin.proto3.tracklist.Observation.deserializeBinaryFromReader);
      msg.setObservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.AddObservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObservation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.robin.proto3.tracklist.Observation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Observation observation = 1;
 * @return {?proto.robin.proto3.tracklist.Observation}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.prototype.getObservation = function() {
  return /** @type{?proto.robin.proto3.tracklist.Observation} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.Observation, 1));
};


/**
 * @param {?proto.robin.proto3.tracklist.Observation|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.prototype.setObservation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AddObservation} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.prototype.clearObservation = function() {
  return this.setObservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.AddObservation.prototype.hasObservation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.DropTrack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DropTrack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DropTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.DropTrack;
  return proto.robin.proto3.tracklist.TrackUpdate.DropTrack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DropTrack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DropTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrackid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.DropTrack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DropTrack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
};


/**
 * optional sint32 trackid = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.prototype.getTrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DropTrack} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.DropTrack.prototype.setTrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.Classification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.Classification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.toObject = function(includeInstance, msg) {
  var f, obj = {
    classification: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.Classification}
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.Classification;
  return proto.robin.proto3.tracklist.TrackUpdate.Classification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.Classification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.Classification}
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.Classification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.Classification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassification();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string classification = 1;
 * @return {string}
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.prototype.getClassification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.Classification} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.Classification.prototype.setClassification = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList}
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList;
  return proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList}
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fusingtrackid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.FuseTrack;
  return proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrackid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setFusingtrackid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getFusingtrackid();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
};


/**
 * optional sint32 trackid = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.prototype.getTrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.prototype.setTrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 fusingtrackid = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.prototype.getFusingtrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FuseTrack} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.FuseTrack.prototype.setFusingtrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dbtrackid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange;
  return proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrackid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setDbtrackid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackid();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getDbtrackid();
  if (f !== 0) {
    writer.writeSint64(
      2,
      f
    );
  }
};


/**
 * optional sint32 trackid = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.prototype.getTrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.prototype.setTrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint64 dbtrackid = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.prototype.getDbtrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange.prototype.setDbtrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    icao: jspb.Message.getFieldWithDefault(msg, 2, 0),
    flightphase: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo}
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.FlightInfo;
  return proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo}
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setIcao(value);
      break;
    case 3:
      var value = /** @type {!proto.robin.proto3.tracklist.FlightPhaseType} */ (reader.readEnum());
      msg.setFlightphase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcao();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getFlightphase();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional sint32 icao = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.prototype.getIcao = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.prototype.setIcao = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FlightPhaseType flightPhase = 3;
 * @return {!proto.robin.proto3.tracklist.FlightPhaseType}
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.prototype.getFlightphase = function() {
  return /** @type {!proto.robin.proto3.tracklist.FlightPhaseType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.FlightPhaseType} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.FlightInfo} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.FlightInfo.prototype.setFlightphase = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaentryexitsList: jspb.Message.toObjectList(msg.getAreaentryexitsList(),
    proto.robin.proto3.tracklist.AreaEntryExit.toObject, includeInstance),
    alerttype: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange;
  return proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.tracklist.AreaEntryExit;
      reader.readMessage(value,proto.robin.proto3.tracklist.AreaEntryExit.deserializeBinaryFromReader);
      msg.addAreaentryexits(value);
      break;
    case 7:
      var value = /** @type {!proto.robin.proto3.tracklist.TrackAlertType} */ (reader.readEnum());
      msg.setAlerttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAreaentryexitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.tracklist.AreaEntryExit.serializeBinaryToWriter
    );
  }
  f = message.getAlerttype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * repeated AreaEntryExit areaEntryExits = 1;
 * @return {!Array<!proto.robin.proto3.tracklist.AreaEntryExit>}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.getAreaentryexitsList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.AreaEntryExit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.AreaEntryExit, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.AreaEntryExit>} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.setAreaentryexitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.AreaEntryExit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.AreaEntryExit}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.addAreaentryexits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.tracklist.AreaEntryExit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.clearAreaentryexitsList = function() {
  return this.setAreaentryexitsList([]);
};


/**
 * optional TrackAlertType alertType = 7;
 * @return {!proto.robin.proto3.tracklist.TrackAlertType}
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.getAlerttype = function() {
  return /** @type {!proto.robin.proto3.tracklist.TrackAlertType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackAlertType} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange.prototype.setAlerttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    proto.robin.proto3.tracklist.DroneAlarmHistory.toObject, includeInstance),
    alarmtype: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange;
  return proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.tracklist.DroneAlarmHistory;
      reader.readMessage(value,proto.robin.proto3.tracklist.DroneAlarmHistory.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 7:
      var value = /** @type {!proto.robin.proto3.tracklist.DroneAlarmType} */ (reader.readEnum());
      msg.setAlarmtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.tracklist.DroneAlarmHistory.serializeBinaryToWriter
    );
  }
  f = message.getAlarmtype();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * repeated DroneAlarmHistory history = 1;
 * @return {!Array<!proto.robin.proto3.tracklist.DroneAlarmHistory>}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.DroneAlarmHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.DroneAlarmHistory, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.DroneAlarmHistory>} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.DroneAlarmHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.DroneAlarmHistory}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.tracklist.DroneAlarmHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional DroneAlarmType alarmType = 7;
 * @return {!proto.robin.proto3.tracklist.DroneAlarmType}
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.getAlarmtype = function() {
  return /** @type {!proto.robin.proto3.tracklist.DroneAlarmType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.DroneAlarmType} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange.prototype.setAlarmtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional sint32 trackid = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getTrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.setTrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional NewTrack newtrack = 5;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.NewTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getNewtrack = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.NewTrack} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.NewTrack, 5));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.NewTrack|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setNewtrack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearNewtrack = function() {
  return this.setNewtrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasNewtrack = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AddEstimate addestimate = 6;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.AddEstimate}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getAddestimate = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.AddEstimate} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.AddEstimate, 6));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.AddEstimate|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setAddestimate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearAddestimate = function() {
  return this.setAddestimate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasAddestimate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AddObservation addobservation = 7;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.AddObservation}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getAddobservation = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.AddObservation} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.AddObservation, 7));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.AddObservation|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setAddobservation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearAddobservation = function() {
  return this.setAddobservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasAddobservation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DropTrack droptrack = 8;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.DropTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getDroptrack = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.DropTrack} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.DropTrack, 8));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.DropTrack|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setDroptrack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearDroptrack = function() {
  return this.setDroptrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasDroptrack = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Classification classification = 9;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.Classification}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getClassification = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.Classification} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.Classification, 9));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.Classification|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setClassification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearClassification = function() {
  return this.setClassification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasClassification = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ResyncTrackList resynctracklist = 11;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getResynctracklist = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList, 11));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.ResyncTrackList|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setResynctracklist = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearResynctracklist = function() {
  return this.setResynctracklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasResynctracklist = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional FuseTrack fusetrack = 13;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.FuseTrack}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getFusetrack = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.FuseTrack} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.FuseTrack, 13));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.FuseTrack|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setFusetrack = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearFusetrack = function() {
  return this.setFusetrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasFusetrack = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DatabaseIdChange databaseidchange = 14;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getDatabaseidchange = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange, 14));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.DatabaseIdChange|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setDatabaseidchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearDatabaseidchange = function() {
  return this.setDatabaseidchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasDatabaseidchange = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional FlightInfo flightInfo = 15;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.FlightInfo}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getFlightinfo = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.FlightInfo} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.FlightInfo, 15));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.FlightInfo|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setFlightinfo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearFlightinfo = function() {
  return this.setFlightinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasFlightinfo = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional AreaEntryExitChange areaentryexitchange = 16;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getAreaentryexitchange = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange, 16));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.AreaEntryExitChange|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setAreaentryexitchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearAreaentryexitchange = function() {
  return this.setAreaentryexitchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasAreaentryexitchange = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional DroneAlarmChange dronealarmchange = 17;
 * @return {?proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.getDronealarmchange = function() {
  return /** @type{?proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange, 17));
};


/**
 * @param {?proto.robin.proto3.tracklist.TrackUpdate.DroneAlarmChange|undefined} value
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackUpdate.prototype.setDronealarmchange = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.robin.proto3.tracklist.TrackUpdate.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.TrackUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.clearDronealarmchange = function() {
  return this.setDronealarmchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackUpdate.prototype.hasDronealarmchange = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.TrackData.repeatedFields_ = [3,6,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackData.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageTimestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rainPercentage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trackList: jspb.Message.toObjectList(msg.getTrackList(),
    proto.robin.proto3.tracklist.Track.toObject, includeInstance),
    lastupdateid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    radarList: jspb.Message.toObjectList(msg.getRadarList(),
    proto.robin.proto3.tracklist.Radar.toObject, includeInstance),
    recentradardata: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    tracksonairstripthreshold: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tracksonairstripupdateList: jspb.Message.toObjectList(msg.getTracksonairstripupdateList(),
    proto.robin.proto3.tracklist.TracksOnAirstripUpdate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackData}
 */
proto.robin.proto3.tracklist.TrackData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackData;
  return proto.robin.proto3.tracklist.TrackData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackData}
 */
proto.robin.proto3.tracklist.TrackData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImageTimestampMsec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRainPercentage(value);
      break;
    case 3:
      var value = new proto.robin.proto3.tracklist.Track;
      reader.readMessage(value,proto.robin.proto3.tracklist.Track.deserializeBinaryFromReader);
      msg.addTrack(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setLastupdateid(value);
      break;
    case 6:
      var value = new proto.robin.proto3.tracklist.Radar;
      reader.readMessage(value,proto.robin.proto3.tracklist.Radar.deserializeBinaryFromReader);
      msg.addRadar(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecentradardata(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTracksonairstripthreshold(value);
      break;
    case 11:
      var value = new proto.robin.proto3.tracklist.TracksOnAirstripUpdate;
      reader.readMessage(value,proto.robin.proto3.tracklist.TracksOnAirstripUpdate.deserializeBinaryFromReader);
      msg.addTracksonairstripupdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRainPercentage();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getTrackList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.tracklist.Track.serializeBinaryToWriter
    );
  }
  f = message.getLastupdateid();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getRadarList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.robin.proto3.tracklist.Radar.serializeBinaryToWriter
    );
  }
  f = message.getRecentradardata();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTracksonairstripthreshold();
  if (f !== 0) {
    writer.writeSint32(
      10,
      f
    );
  }
  f = message.getTracksonairstripupdateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.robin.proto3.tracklist.TracksOnAirstripUpdate.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 image_timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getImageTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.setImageTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 rain_percentage = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getRainPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.setRainPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Track track = 3;
 * @return {!Array<!proto.robin.proto3.tracklist.Track>}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getTrackList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.Track>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.Track, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.Track>} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
*/
proto.robin.proto3.tracklist.TrackData.prototype.setTrackList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.Track=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.Track}
 */
proto.robin.proto3.tracklist.TrackData.prototype.addTrack = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.tracklist.Track, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.clearTrackList = function() {
  return this.setTrackList([]);
};


/**
 * optional sint32 lastupdateid = 5;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getLastupdateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.setLastupdateid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Radar radar = 6;
 * @return {!Array<!proto.robin.proto3.tracklist.Radar>}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getRadarList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.Radar>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.Radar, 6));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.Radar>} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
*/
proto.robin.proto3.tracklist.TrackData.prototype.setRadarList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.Radar=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.Radar}
 */
proto.robin.proto3.tracklist.TrackData.prototype.addRadar = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.robin.proto3.tracklist.Radar, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.clearRadarList = function() {
  return this.setRadarList([]);
};


/**
 * optional bool recentradardata = 7;
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getRecentradardata = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.setRecentradardata = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional sint32 tracksOnAirstripthreshold = 10;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getTracksonairstripthreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.setTracksonairstripthreshold = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated TracksOnAirstripUpdate tracksOnAirstripUpdate = 11;
 * @return {!Array<!proto.robin.proto3.tracklist.TracksOnAirstripUpdate>}
 */
proto.robin.proto3.tracklist.TrackData.prototype.getTracksonairstripupdateList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.TracksOnAirstripUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.TracksOnAirstripUpdate, 11));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.TracksOnAirstripUpdate>} value
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
*/
proto.robin.proto3.tracklist.TrackData.prototype.setTracksonairstripupdateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate}
 */
proto.robin.proto3.tracklist.TrackData.prototype.addTracksonairstripupdate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.robin.proto3.tracklist.TracksOnAirstripUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackData} returns this
 */
proto.robin.proto3.tracklist.TrackData.prototype.clearTracksonairstripupdateList = function() {
  return this.setTracksonairstripupdateList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.TrackListUpdate.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackListUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackListUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackListUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackupdateList: jspb.Message.toObjectList(msg.getTrackupdateList(),
    proto.robin.proto3.tracklist.TrackUpdate.toObject, includeInstance),
    updateid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackListUpdate}
 */
proto.robin.proto3.tracklist.TrackListUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackListUpdate;
  return proto.robin.proto3.tracklist.TrackListUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackListUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackListUpdate}
 */
proto.robin.proto3.tracklist.TrackListUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.tracklist.TrackUpdate;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackUpdate.deserializeBinaryFromReader);
      msg.addTrackupdate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setUpdateid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackListUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackListUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackListUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackupdateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.tracklist.TrackUpdate.serializeBinaryToWriter
    );
  }
  f = message.getUpdateid();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
};


/**
 * repeated TrackUpdate trackUpdate = 1;
 * @return {!Array<!proto.robin.proto3.tracklist.TrackUpdate>}
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.getTrackupdateList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.TrackUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.TrackUpdate, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.TrackUpdate>} value
 * @return {!proto.robin.proto3.tracklist.TrackListUpdate} returns this
*/
proto.robin.proto3.tracklist.TrackListUpdate.prototype.setTrackupdateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.TrackUpdate}
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.addTrackupdate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.tracklist.TrackUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackListUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.clearTrackupdateList = function() {
  return this.setTrackupdateList([]);
};


/**
 * optional sint32 updateid = 5;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.getUpdateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackListUpdate} returns this
 */
proto.robin.proto3.tracklist.TrackListUpdate.prototype.setUpdateid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.TrackDataUpdates.repeatedFields_ = [3,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TrackDataUpdates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TrackDataUpdates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackDataUpdates.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageTimestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rainPercentage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tracklistupdateList: jspb.Message.toObjectList(msg.getTracklistupdateList(),
    proto.robin.proto3.tracklist.TrackListUpdate.toObject, includeInstance),
    recentradardata: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    radarupdatesList: jspb.Message.toObjectList(msg.getRadarupdatesList(),
    proto.robin.proto3.tracklist.RadarUpdate.toObject, includeInstance),
    tracksonairstripupdatesList: jspb.Message.toObjectList(msg.getTracksonairstripupdatesList(),
    proto.robin.proto3.tracklist.TracksOnAirstripUpdate.toObject, includeInstance),
    refreshrunwayinspectiontimers: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    pendingloggerreports: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    hintnextcallms: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TrackDataUpdates;
  return proto.robin.proto3.tracklist.TrackDataUpdates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TrackDataUpdates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImageTimestampMsec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRainPercentage(value);
      break;
    case 3:
      var value = new proto.robin.proto3.tracklist.TrackListUpdate;
      reader.readMessage(value,proto.robin.proto3.tracklist.TrackListUpdate.deserializeBinaryFromReader);
      msg.addTracklistupdate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecentradardata(value);
      break;
    case 7:
      var value = new proto.robin.proto3.tracklist.RadarUpdate;
      reader.readMessage(value,proto.robin.proto3.tracklist.RadarUpdate.deserializeBinaryFromReader);
      msg.addRadarupdates(value);
      break;
    case 8:
      var value = new proto.robin.proto3.tracklist.TracksOnAirstripUpdate;
      reader.readMessage(value,proto.robin.proto3.tracklist.TracksOnAirstripUpdate.deserializeBinaryFromReader);
      msg.addTracksonairstripupdates(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshrunwayinspectiontimers(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingloggerreports(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setHintnextcallms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TrackDataUpdates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TrackDataUpdates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TrackDataUpdates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRainPercentage();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getTracklistupdateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.tracklist.TrackListUpdate.serializeBinaryToWriter
    );
  }
  f = message.getRecentradardata();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRadarupdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.robin.proto3.tracklist.RadarUpdate.serializeBinaryToWriter
    );
  }
  f = message.getTracksonairstripupdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.robin.proto3.tracklist.TracksOnAirstripUpdate.serializeBinaryToWriter
    );
  }
  f = message.getRefreshrunwayinspectiontimers();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPendingloggerreports();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getHintnextcallms();
  if (f !== 0) {
    writer.writeSint32(
      11,
      f
    );
  }
};


/**
 * optional int64 image_timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getImageTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setImageTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 rain_percentage = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getRainPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setRainPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated TrackListUpdate trackListUpdate = 3;
 * @return {!Array<!proto.robin.proto3.tracklist.TrackListUpdate>}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getTracklistupdateList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.TrackListUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.TrackListUpdate, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.TrackListUpdate>} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
*/
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setTracklistupdateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.TrackListUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.TrackListUpdate}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.addTracklistupdate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.tracklist.TrackListUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.clearTracklistupdateList = function() {
  return this.setTracklistupdateList([]);
};


/**
 * optional bool recentradardata = 5;
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getRecentradardata = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setRecentradardata = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated RadarUpdate radarUpdates = 7;
 * @return {!Array<!proto.robin.proto3.tracklist.RadarUpdate>}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getRadarupdatesList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.RadarUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.RadarUpdate, 7));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.RadarUpdate>} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
*/
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setRadarupdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.RadarUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.RadarUpdate}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.addRadarupdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.robin.proto3.tracklist.RadarUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.clearRadarupdatesList = function() {
  return this.setRadarupdatesList([]);
};


/**
 * repeated TracksOnAirstripUpdate tracksOnAirstripUpdates = 8;
 * @return {!Array<!proto.robin.proto3.tracklist.TracksOnAirstripUpdate>}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getTracksonairstripupdatesList = function() {
  return /** @type{!Array<!proto.robin.proto3.tracklist.TracksOnAirstripUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.tracklist.TracksOnAirstripUpdate, 8));
};


/**
 * @param {!Array<!proto.robin.proto3.tracklist.TracksOnAirstripUpdate>} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
*/
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setTracksonairstripupdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.addTracksonairstripupdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.robin.proto3.tracklist.TracksOnAirstripUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.clearTracksonairstripupdatesList = function() {
  return this.setTracksonairstripupdatesList([]);
};


/**
 * optional bool refreshRunwayInspectionTimers = 9;
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getRefreshrunwayinspectiontimers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setRefreshrunwayinspectiontimers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool pendingLoggerReports = 10;
 * @return {boolean}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getPendingloggerreports = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setPendingloggerreports = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional sint32 hintNextCallMs = 11;
 * @return {number}
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.getHintnextcallms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TrackDataUpdates} returns this
 */
proto.robin.proto3.tracklist.TrackDataUpdates.prototype.setHintnextcallms = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.RadarOrientation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.RadarOrientation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.RadarOrientation.toObject = function(includeInstance, msg) {
  var f, obj = {
    axistype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    azimuth: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    elevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.RadarOrientation}
 */
proto.robin.proto3.tracklist.RadarOrientation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.RadarOrientation;
  return proto.robin.proto3.tracklist.RadarOrientation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.RadarOrientation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.RadarOrientation}
 */
proto.robin.proto3.tracklist.RadarOrientation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.robin.proto3.tracklist.AxisType} */ (reader.readEnum());
      msg.setAxistype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAzimuth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setElevation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.RadarOrientation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.RadarOrientation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.RadarOrientation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAxistype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAzimuth();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getElevation();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional AxisType axisType = 1;
 * @return {!proto.robin.proto3.tracklist.AxisType}
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.getAxistype = function() {
  return /** @type {!proto.robin.proto3.tracklist.AxisType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.robin.proto3.tracklist.AxisType} value
 * @return {!proto.robin.proto3.tracklist.RadarOrientation} returns this
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.setAxistype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional float azimuth = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.getAzimuth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.RadarOrientation} returns this
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.setAzimuth = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float elevation = 3;
 * @return {number}
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.getElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.RadarOrientation} returns this
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.setElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float range = 4;
 * @return {number}
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.RadarOrientation} returns this
 */
proto.robin.proto3.tracklist.RadarOrientation.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.RadarUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.RadarUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.RadarUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    siteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orientation: (f = msg.getOrientation()) && proto.robin.proto3.tracklist.RadarOrientation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.RadarUpdate}
 */
proto.robin.proto3.tracklist.RadarUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.RadarUpdate;
  return proto.robin.proto3.tracklist.RadarUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.RadarUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.RadarUpdate}
 */
proto.robin.proto3.tracklist.RadarUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSiteId(value);
      break;
    case 2:
      var value = new proto.robin.proto3.tracklist.RadarOrientation;
      reader.readMessage(value,proto.robin.proto3.tracklist.RadarOrientation.deserializeBinaryFromReader);
      msg.setOrientation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.RadarUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.RadarUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.RadarUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getOrientation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.robin.proto3.tracklist.RadarOrientation.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint32 site_id = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.RadarUpdate} returns this
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RadarOrientation orientation = 2;
 * @return {?proto.robin.proto3.tracklist.RadarOrientation}
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.getOrientation = function() {
  return /** @type{?proto.robin.proto3.tracklist.RadarOrientation} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.RadarOrientation, 2));
};


/**
 * @param {?proto.robin.proto3.tracklist.RadarOrientation|undefined} value
 * @return {!proto.robin.proto3.tracklist.RadarUpdate} returns this
*/
proto.robin.proto3.tracklist.RadarUpdate.prototype.setOrientation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.RadarUpdate} returns this
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.clearOrientation = function() {
  return this.setOrientation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.RadarUpdate.prototype.hasOrientation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.Radar.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.Radar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.Radar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Radar.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    siteId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    orientation: (f = msg.getOrientation()) && proto.robin.proto3.tracklist.RadarOrientation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.Radar}
 */
proto.robin.proto3.tracklist.Radar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.Radar;
  return proto.robin.proto3.tracklist.Radar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.Radar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.Radar}
 */
proto.robin.proto3.tracklist.Radar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSiteId(value);
      break;
    case 4:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = new proto.robin.proto3.tracklist.RadarOrientation;
      reader.readMessage(value,proto.robin.proto3.tracklist.RadarOrientation.deserializeBinaryFromReader);
      msg.setOrientation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.Radar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.Radar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.Radar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.Radar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteId();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getOrientation();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.robin.proto3.tracklist.RadarOrientation.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint64 id = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.Radar.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
 */
proto.robin.proto3.tracklist.Radar.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.tracklist.Radar.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
 */
proto.robin.proto3.tracklist.Radar.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional sint32 site_id = 3;
 * @return {number}
 */
proto.robin.proto3.tracklist.Radar.prototype.getSiteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
 */
proto.robin.proto3.tracklist.Radar.prototype.setSiteId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 4;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.tracklist.Radar.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 4));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
*/
proto.robin.proto3.tracklist.Radar.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
 */
proto.robin.proto3.tracklist.Radar.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.Radar.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RadarOrientation orientation = 5;
 * @return {?proto.robin.proto3.tracklist.RadarOrientation}
 */
proto.robin.proto3.tracklist.Radar.prototype.getOrientation = function() {
  return /** @type{?proto.robin.proto3.tracklist.RadarOrientation} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.tracklist.RadarOrientation, 5));
};


/**
 * @param {?proto.robin.proto3.tracklist.RadarOrientation|undefined} value
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
*/
proto.robin.proto3.tracklist.Radar.prototype.setOrientation = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.tracklist.Radar} returns this
 */
proto.robin.proto3.tracklist.Radar.prototype.clearOrientation = function() {
  return this.setOrientation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.tracklist.Radar.prototype.hasOrientation = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TracksOnAirstripUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numberoftracks: jspb.Message.getFieldWithDefault(msg, 2, 0),
    airstrip: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TracksOnAirstripUpdate;
  return proto.robin.proto3.tracklist.TracksOnAirstripUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setNumberoftracks(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirstrip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TracksOnAirstripUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNumberoftracks();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getAirstrip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate} returns this
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 numberOfTracks = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.getNumberoftracks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate} returns this
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.setNumberoftracks = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string airstrip = 3;
 * @return {string}
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.getAirstrip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.tracklist.TracksOnAirstripUpdate} returns this
 */
proto.robin.proto3.tracklist.TracksOnAirstripUpdate.prototype.setAirstrip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.ReplayTrackDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.ReplayTrackDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdateMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    enddateMsec: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.ReplayTrackDataRequest}
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.ReplayTrackDataRequest;
  return proto.robin.proto3.tracklist.ReplayTrackDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.ReplayTrackDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.ReplayTrackDataRequest}
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartdateMsec(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnddateMsec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.ReplayTrackDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.ReplayTrackDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdateMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEnddateMsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 startDate_msec = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.prototype.getStartdateMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.ReplayTrackDataRequest} returns this
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.prototype.setStartdateMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 endDate_msec = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.prototype.getEnddateMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.ReplayTrackDataRequest} returns this
 */
proto.robin.proto3.tracklist.ReplayTrackDataRequest.prototype.setEnddateMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TracksHistogramRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TracksHistogramRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    starttimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endtimestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TracksHistogramRequest}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TracksHistogramRequest;
  return proto.robin.proto3.tracklist.TracksHistogramRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TracksHistogramRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TracksHistogramRequest}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStarttimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndtimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TracksHistogramRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TracksHistogramRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarttimestamp();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getEndtimestamp();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint64 startTimestamp = 1;
 * @return {number}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.getStarttimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TracksHistogramRequest} returns this
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.setStarttimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 endTimestamp = 2;
 * @return {number}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.getEndtimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TracksHistogramRequest} returns this
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.setEndtimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 interval = 3;
 * @return {number}
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.tracklist.TracksHistogramRequest} returns this
 */
proto.robin.proto3.tracklist.TracksHistogramRequest.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.tracklist.TracksHistogram.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.tracklist.TracksHistogram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.tracklist.TracksHistogram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TracksHistogram.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    countList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.tracklist.TracksHistogram}
 */
proto.robin.proto3.tracklist.TracksHistogram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.tracklist.TracksHistogram;
  return proto.robin.proto3.tracklist.TracksHistogram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.tracklist.TracksHistogram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.tracklist.TracksHistogram}
 */
proto.robin.proto3.tracklist.TracksHistogram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTimestamp(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCount(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.tracklist.TracksHistogram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.tracklist.TracksHistogram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.tracklist.TracksHistogram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getCountList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
};


/**
 * repeated uint64 timestamp = 1;
 * @return {!Array<number>}
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.getTimestampList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.tracklist.TracksHistogram} returns this
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.setTimestampList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.TracksHistogram} returns this
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.addTimestamp = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TracksHistogram} returns this
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.clearTimestampList = function() {
  return this.setTimestampList([]);
};


/**
 * repeated uint32 count = 2;
 * @return {!Array<number>}
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.getCountList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.tracklist.TracksHistogram} returns this
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.setCountList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.tracklist.TracksHistogram} returns this
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.addCount = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.tracklist.TracksHistogram} returns this
 */
proto.robin.proto3.tracklist.TracksHistogram.prototype.clearCountList = function() {
  return this.setCountList([]);
};


/**
 * @enum {number}
 */
proto.robin.proto3.tracklist.FlightPhaseType = {
  TAXIING: 0,
  TAKEOFF: 1,
  DEPARTURE: 2,
  ENROUTE: 3,
  APPROACH: 4,
  LANDING: 5,
  STATIONARY: 6,
  UNKNOWN: 100
};

/**
 * @enum {number}
 */
proto.robin.proto3.tracklist.TrackAlertType = {
  NO_ASSESSMENT: 0,
  NO_ALARM: 1,
  ALARM: 2,
  IN_SECTOR: 3
};

/**
 * @enum {number}
 */
proto.robin.proto3.tracklist.DroneAlarmType = {
  NO_DRONE_ALARM_ASSESSMENT: 0,
  NO_DRONE_ALARM: 1,
  DRONE_ALARM: 2
};

/**
 * @enum {number}
 */
proto.robin.proto3.tracklist.TrackPlotType = {
  UNKNOWN: 0,
  RAAZEL: 1,
  RAAZ: 2,
  RAEL: 3,
  RA: 4
};

/**
 * @enum {number}
 */
proto.robin.proto3.tracklist.AxisType = {
  AXISUNKNOWN: 0,
  AXISPHI: 1,
  AXISTHETA: 2,
  AXISTIME: 3
};

goog.object.extend(exports, proto.robin.proto3.tracklist);
