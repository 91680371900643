import { ClassificationJSON } from "./json";

export enum ClassificationIcon {
    CIRCLE,
    SQUARE,
    TRIANGLE,
    RING,
    DRONE,
    FIXED_WING,
    AIRCRAFT,
    QUESTION_MARK,
    HELICOPTER,
}

export function classificationIconfromProto(name: string): ClassificationIcon {
    switch (name) {
        case "square":
            return ClassificationIcon.SQUARE;
        case "triangle":
            return ClassificationIcon.TRIANGLE;
        case "ring":
            return ClassificationIcon.RING;
        case "drone":
            return ClassificationIcon.DRONE;
        case "fixed_wing":
            return ClassificationIcon.FIXED_WING;
        case "aircraft":
            return ClassificationIcon.AIRCRAFT;
        case "helicopter":
            return ClassificationIcon.HELICOPTER;
        case "other":
        case "circle":
        case "unknown":
        // Case "question_mark" and default used to return `ClassificationIcon.QUESTION_MARK`
        // Question mark has been deprecated and replaced with circle icon
        default:
            return ClassificationIcon.CIRCLE;
    }
}

export function classificationIconFromClassificationJson(model: ClassificationJSON): ClassificationIcon {
    switch (model.classification) {
        case "DRONE":
        case "SUSPECTED_DRONE":
            return ClassificationIcon.DRONE;
        case "FIXED_WING":
        case "SUSPECTED_FIXED_WING":
            return ClassificationIcon.FIXED_WING;
        case "HELICOPTER":
            return ClassificationIcon.HELICOPTER;
        case "AIRCRAFT":
            return ClassificationIcon.AIRCRAFT;
        case "VEHICLE":
            return ClassificationIcon.SQUARE;
        case "OTHER":
        case "UNKNOWN":
        case "UNCLASSIFIED":
        // Case "QUESTION_MARK" and default used to return `ClassificationIcon.QUESTION_MARK`
        // Question mark has been deprecated and replaced with circle icon
        default:
            return ClassificationIcon.CIRCLE;
    }
}
