import React from "react";
import * as Rx from "rxjs";
import { ServerConfig, SessionState, AuthMethod, LocalUserPreferenceKeys } from "../domain/model";
import { BaseSubscriptionHandlerComponent } from "./BaseSubscriptionHandlerComponent";
import styled, { ThemeProvider } from "styled-components";
import {
    StartableRepository,
    SessionRepository,
    ServerConfigRepository,
    AbstractStartableRepository,
    UIControlRepository,
    LocalPreferencesRepository,
} from "../domain/repositories";
import DI from "../di/DI";
import { TYPES } from "../di/Types";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { History } from "history";
import { BrowserDetect } from "./browserdetect/BrowserDetect";
import GlobalStyles from "./appearance/theme/GlobalStyles";
import { FullscreenLoading } from "./appearance/FullscreenLoading";
import { Home } from "./home/Home";
import { LoginPage } from "./login/LoginPage";
import { t } from "i18next";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../infrastructure/AppConfig";
import { ColorMode, getTheme } from "./appearance/theme/Theme";
import { FlavorConfig } from "../infrastructure/FlavorConfig";
import { FullscreenError } from "./appearance/FullscreenError";
import { checkIfScreenIsTooSmall } from "../utils/ScreenUtils";
import { OpenIdConnectRedirect } from "./login/OpenIdConnectRedirect";
import "@radix-ui/themes/styles.css";
import { Playground } from "../playground";
import { Toaster } from "sonner";

const Container = styled.div`
    display: flex;
    flex: 1;
`;

interface State {
    apiError: Error | null;
    isConfigLoaded: boolean;
    isLoading: boolean;
    mode: ColorMode;
    screenTooSmall: boolean;
}

export abstract class BaseApp extends BaseSubscriptionHandlerComponent<{}, State> {
    // Properties

    private readonly sessionRepository = DI.get<SessionRepository>(TYPES.SessionRepository);
    private readonly uiControlRepository = DI.get<UIControlRepository>(TYPES.UIControlRepository);
    private readonly serverConfigRepository = DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository);
    private readonly localPreferencesRepository = DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository);
    private readonly router: React.RefObject<Router>;
    protected get history(): History {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.router.current as any).history as History;
    }
    protected get appName(): string {
        return DI.get<FlavorConfig>(TYPES.FlavorConfig).appName;
    }
    protected abstract repositories: StartableRepository[];
    protected abstract renderHead(): JSX.Element;

    /**
     * This function is called when the window is resized and sets the CSS variable --dvh-unit to 1% of the current window height.
     * This is a fallback for browsers that do not support the CSS unit dvh (e.g. Chrome <107).
     * @todo Remove this function when our minimum supported Chrome version is > 107.
     */
    private legacyResizeListener = (): void => {
        const dvhUnit = window.innerHeight * 0.01;
        document.getElementById("root")?.style.setProperty("--dvh-unit", `${dvhUnit}px`);
    };

    private resizeListener = (): void => {
        this.setState({ screenTooSmall: checkIfScreenIsTooSmall() });
    };

    // Lifecycle

    public constructor(props: {}) {
        super(props);
        this.router = React.createRef();
        this.state = {
            apiError: null,
            isConfigLoaded: false,
            isLoading: true,
            mode: ColorMode.Dark,
            screenTooSmall: checkIfScreenIsTooSmall(),
        };
    }

    public componentDidMount(): void {
        this.subscribeToServerConfig();
        this.subscribeToThemeChanges();

        // Only add the resize listener if the browser does not support the CSS unit dvh
        if (!CSS.supports("height", "1dvh")) {
            this.legacyResizeListener();
            window.addEventListener("resize", this.legacyResizeListener);
        }
        window.addEventListener("resize", this.resizeListener);
    }

    public componentWillUnmount(): void {
        if (!CSS.supports("height", "1dvh")) {
            window.removeEventListener("resize", this.legacyResizeListener);
        }
        window.removeEventListener("resize", this.resizeListener);
    }

    public render(): JSX.Element {
        const theme = getTheme(this.state.mode);
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    <Toaster
                        theme={this.state.mode}
                        position={"top-right"}
                        duration={4000}
                        pauseWhenPageIsHidden
                        style={{ width: "500px" }}
                        toastOptions={{
                            style: {
                                color: theme.colors.text.text,
                                border: "0",
                                borderRadius: theme.spacing.x3,
                                width: "500px",
                            },
                        }}
                        visibleToasts={5}
                    />
                    {this.renderHead()}
                    <GlobalStyles />
                    <Router ref={this.router}>
                        <Route exact path="/" component={FullscreenLoading} />
                        <Route exact path="/home">
                            <Home />
                            <FullscreenLoading isLoading={this.state.isLoading} />
                        </Route>
                        {this.state.isConfigLoaded ? (
                            <>
                                <Route exact path="/login" component={LoginPage} />
                                <Route exact path="/oauth">
                                    <OpenIdConnectRedirect onError={() => this.navigateToLogin()} />
                                </Route>
                            </>
                        ) : (
                            <Route path="/">
                                <FullscreenLoading isLoading={this.state.apiError == null} />
                                {this.state.apiError && (
                                    <FullscreenError
                                        primaryText={t("messages.noConnectionToRadar")}
                                        secondaryText={t("messages.checkConnectionInstruction")}
                                    />
                                )}
                            </Route>
                        )}
                        <Route component={Playground} exact path="/playground" />
                    </Router>
                    <BrowserDetect />
                    {this.state.screenTooSmall && (
                        <FullscreenError
                            primaryText={
                                t("messages.screenTooSmall") + ", " + window.innerWidth + ", " + window.innerHeight
                            }
                            secondaryText={t("messages.screenTooSmallInstruction")}
                        />
                    )}
                </Container>
            </ThemeProvider>
        );
    }

    // Private functions

    private shouldRedirectForLoggedOutState(serverConfig: ServerConfig): boolean {
        switch (serverConfig.authMethod) {
            case AuthMethod.BasicAuth:
            case AuthMethod.NoAuth:
                return this.history.location.pathname !== "/login";
            case AuthMethod.OpenIdConnect:
                return !["/login", "/oauth"].includes(this.history.location.pathname);
        }
    }

    private subscribeToSessionState(serverConfig: ServerConfig | null): void {
        const subscription = this.sessionRepository.session.subscribe((session) => {
            this.handleNewSessionState(session.state, serverConfig);
        });
        this.collectSubscription(subscription);
    }

    private handleNewSessionState(state: SessionState, serverConfig: ServerConfig | null): void {
        // Start or stop the startable repositories
        this.repositories.forEach((repository) => {
            if (repository instanceof AbstractStartableRepository) {
                repository.safelyUpdateStateWithSessionState(state);
            }
        });
        // Change the route
        if (state === SessionState.LoggedIn && !this.history.location.pathname.startsWith("/home")) {
            this.history.push("/home");
        } else if (state === SessionState.LoggedOut && serverConfig) {
            if (this.shouldRedirectForLoggedOutState(serverConfig)) {
                this.navigateToLogin();
            }
        } else if (state === SessionState.ExternalLogout) {
            // Logout needs to be done by an external system when auth method is 'NoAuth'
            const externalLogoutUrl = getRuntimeConfig<string>(APP_CONFIG_KEYS.EXTERNAL_LOGOUT_URL);
            if (externalLogoutUrl) {
                this.uiControlRepository.resetUI();
                window.location.href = externalLogoutUrl;
            }
        }
    }

    private navigateToLogin(): void {
        this.uiControlRepository.resetUI();
        this.history.push("/login");
    }

    private subscribeToServerConfig(): void {
        this.collectSubscriptions(
            // Get server config
            this.serverConfigRepository.config.subscribe({
                next: (serverConfig) => this.subscribeToSessionState(serverConfig),
                error: (error) => this.setState({ apiError: error }),
            }),
            // Check if server config is loaded and uiState is ready
            Rx.combineLatest([
                this.serverConfigRepository.isConfigLoaded,
                this.uiControlRepository.isUIReady,
            ]).subscribe(([isConfigLoaded, isUIReady]) =>
                this.setState({ isLoading: !isConfigLoaded || !isUIReady, isConfigLoaded }),
            ),
        );
    }

    private subscribeToThemeChanges(): void {
        const subscription = this.localPreferencesRepository
            .observePreference<ColorMode>(LocalUserPreferenceKeys.appearance.theme)
            .subscribe((theme) => {
                this.setState({ mode: theme || ColorMode.Dark });
            });
        this.collectSubscription(subscription);
    }
}
