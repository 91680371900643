import React from "react";
import styled from "styled-components";
import { PanelTitle } from "../../../appearance/panels/PanelTitle";
import { t } from "i18next";
import { useViewModel } from "../../../../hooks/useViewModel";
import { TYPES } from "../../../../di/Types";
import { EventListViewModel } from "./EventListViewModel";
import { useObservable } from "../../../../hooks";
import { PanelDescription } from "../../../appearance/panels/PanelText";
import { ActiveEvent } from "./Event";
import { ScrollableSidebarContent } from "../../../appearance/Sidebar";
import { AlarmHistory } from "./AlarmHistory";

const Container = styled(ScrollableSidebarContent)`
    gap: ${({ theme }) => theme.spacing.x5};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    gap: ${({ theme }) => theme.spacing.x2};

    ${PanelDescription} {
        margin-top: 0;
    }
`;

const ColumnTight = styled(Column)`
    gap: ${({ theme }) => theme.spacing.x1};
    align-items: stretch;
`;

export const EventList = (): JSX.Element => {
    // Properties

    const viewModel: EventListViewModel = useViewModel(TYPES.EventListViewModel);
    const activeEvents = useObservable(viewModel.activeAlarms, []);

    // Render

    return (
        <Container>
            <Column>
                <PanelTitle>
                    {t("eventList.activeEvents")} ({activeEvents.length})
                </PanelTitle>
                {activeEvents.length === 0 ? (
                    <PanelDescription>{t("eventList.noResults")}</PanelDescription>
                ) : (
                    <ColumnTight>
                        {activeEvents.map((event) => (
                            <ActiveEvent eventData={event} key={Math.random().toString(36)} />
                        ))}
                    </ColumnTight>
                )}
            </Column>
            {viewModel.isAlarmHistoryAvailable && (
                <Column>
                    <PanelTitle>{t("eventList.historicEvents")}</PanelTitle>
                    <AlarmHistory />
                </Column>
            )}
        </Container>
    );
};
