import { SettingItemViewModel } from "../../../../components/settings/SettingItemViewModel";
import { LocalPreferencesRepository } from "../../../../domain/repositories/LocalPreferencesRepository";
import { SettingsItemSection } from "../../../../components/settings/SettingsItemSection";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { RunwayCrossingThresholdSettingItemViewModel } from "../../../../components/settings/sections/items/RunwayCrossingSettingItemViewModel";
import { FunnelViewThresholdSettingItemViewModel } from "../../../../components/settings/sections/items/FunnelViewThresholdSettingItemViewModel";
import { LocationInfoRepository, RunwayTrafficRepository } from "../../../../domain/repositories";
import { t } from "i18next";
import { FunnelViewParkingCorridorSettingItemViewModel } from "../../../../components/settings/sections/items/FunnelViewParkingCorridorSettingItemViewModel";

export class ChartsSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "charts-settings";
    public readonly label: string | null = t("settings.runways");

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {}

    // Public functions

    public generate(): Rx.Observable<SettingItemViewModel[]> {
        return Rx.combineLatest([
            this.runwayTrafficRepository.hasRunwayCrossingData,
            this.locationInfoRepository.hasFunnelTrafficData,
            this.locationInfoRepository.hasParkingCorridorData(),
        ]).pipe(
            RxOperators.map(([hasCrossingData, hasFunnelData, hasParkingCorridorData]) => {
                const items: SettingItemViewModel[] = [];
                if (hasParkingCorridorData) {
                    items.push(new FunnelViewParkingCorridorSettingItemViewModel(this.localPreferencesRepository));
                }
                if (hasFunnelData) {
                    items.push(new FunnelViewThresholdSettingItemViewModel(this.localPreferencesRepository));
                }
                if (hasCrossingData) {
                    items.push(new RunwayCrossingThresholdSettingItemViewModel(this.localPreferencesRepository));
                }
                return items;
            }),
        );
    }
}
