import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { VersionInfo } from "../../domain/model";
import { VersionRepository } from "../../domain/repositories/VersionRepository";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";

export class AboutViewModel extends BaseViewModel {
    // Properties

    public get versionInfo(): Rx.Observable<VersionInfo> {
        return this.versionRepository.getVersionInfo();
    }

    public get webviewerVersion(): string {
        if (!this.commitHash) {
            return this.versionString;
        }
        return `${this.versionString} (${this.commitHash})`;
    }

    public get hasVersionMismatch(): Rx.Observable<boolean> {
        return this.versionInfo.pipe(
            RxOperators.map((versionInfo) => {
                const versionParts = this.versionString.split(".");
                const apiVersionParts = versionInfo.versionTag.split(".");
                // We only care about the major and minor version
                return versionParts[0] !== apiVersionParts[0] || versionParts[1] !== apiVersionParts[1];
            }),
        );
    }

    public get appName(): string {
        return this.flavorConfig.appName;
    }

    private versionString = process.env.REACT_APP_VERSION!;
    private commitHash = process.env.REACT_APP_COMMIT_HASH;

    public constructor(
        private readonly versionRepository: VersionRepository,
        private readonly flavorConfig: FlavorConfig,
    ) {
        super();
    }
}
