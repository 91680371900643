// source: background3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.robin.proto3.background.BackgroundTileRequest', null, global);
goog.exportSymbol('proto.robin.proto3.background.BackgroundTileResponse', null, global);
goog.exportSymbol('proto.robin.proto3.background.ImageFormat', null, global);
goog.exportSymbol('proto.robin.proto3.background.MapType', null, global);
goog.exportSymbol('proto.robin.proto3.background.TileProvider', null, global);
goog.exportSymbol('proto.robin.proto3.background.TileProviderRequest', null, global);
goog.exportSymbol('proto.robin.proto3.background.TileProviderResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.background.TileProviderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.background.TileProviderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.background.TileProviderRequest.displayName = 'proto.robin.proto3.background.TileProviderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.background.TileProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.background.TileProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.background.TileProvider.displayName = 'proto.robin.proto3.background.TileProvider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.background.TileProviderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.background.TileProviderResponse.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.background.TileProviderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.background.TileProviderResponse.displayName = 'proto.robin.proto3.background.TileProviderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.background.BackgroundTileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.background.BackgroundTileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.background.BackgroundTileRequest.displayName = 'proto.robin.proto3.background.BackgroundTileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.background.BackgroundTileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.background.BackgroundTileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.background.BackgroundTileResponse.displayName = 'proto.robin.proto3.background.BackgroundTileResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.background.TileProviderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.background.TileProviderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.background.TileProviderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.TileProviderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    options: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.background.TileProviderRequest}
 */
proto.robin.proto3.background.TileProviderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.background.TileProviderRequest;
  return proto.robin.proto3.background.TileProviderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.background.TileProviderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.background.TileProviderRequest}
 */
proto.robin.proto3.background.TileProviderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.background.TileProviderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.background.TileProviderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.background.TileProviderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.TileProviderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptions();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string options = 1;
 * @return {string}
 */
proto.robin.proto3.background.TileProviderRequest.prototype.getOptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.background.TileProviderRequest} returns this
 */
proto.robin.proto3.background.TileProviderRequest.prototype.setOptions = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.background.TileProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.background.TileProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.background.TileProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.TileProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thumbnailurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    apikey: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.background.TileProvider}
 */
proto.robin.proto3.background.TileProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.background.TileProvider;
  return proto.robin.proto3.background.TileProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.background.TileProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.background.TileProvider}
 */
proto.robin.proto3.background.TileProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.background.TileProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.background.TileProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.background.TileProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.TileProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThumbnailurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.background.TileProvider.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.background.TileProvider} returns this
 */
proto.robin.proto3.background.TileProvider.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.background.TileProvider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.background.TileProvider} returns this
 */
proto.robin.proto3.background.TileProvider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.robin.proto3.background.TileProvider.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.background.TileProvider} returns this
 */
proto.robin.proto3.background.TileProvider.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thumbnailUrl = 4;
 * @return {string}
 */
proto.robin.proto3.background.TileProvider.prototype.getThumbnailurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.background.TileProvider} returns this
 */
proto.robin.proto3.background.TileProvider.prototype.setThumbnailurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string apikey = 5;
 * @return {string}
 */
proto.robin.proto3.background.TileProvider.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.background.TileProvider} returns this
 */
proto.robin.proto3.background.TileProvider.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.background.TileProviderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.background.TileProviderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.background.TileProviderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.background.TileProviderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.TileProviderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tileproviderList: jspb.Message.toObjectList(msg.getTileproviderList(),
    proto.robin.proto3.background.TileProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.background.TileProviderResponse}
 */
proto.robin.proto3.background.TileProviderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.background.TileProviderResponse;
  return proto.robin.proto3.background.TileProviderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.background.TileProviderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.background.TileProviderResponse}
 */
proto.robin.proto3.background.TileProviderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.background.TileProvider;
      reader.readMessage(value,proto.robin.proto3.background.TileProvider.deserializeBinaryFromReader);
      msg.addTileprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.background.TileProviderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.background.TileProviderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.background.TileProviderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.TileProviderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTileproviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.background.TileProvider.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TileProvider tileProvider = 1;
 * @return {!Array<!proto.robin.proto3.background.TileProvider>}
 */
proto.robin.proto3.background.TileProviderResponse.prototype.getTileproviderList = function() {
  return /** @type{!Array<!proto.robin.proto3.background.TileProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.background.TileProvider, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.background.TileProvider>} value
 * @return {!proto.robin.proto3.background.TileProviderResponse} returns this
*/
proto.robin.proto3.background.TileProviderResponse.prototype.setTileproviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.background.TileProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.background.TileProvider}
 */
proto.robin.proto3.background.TileProviderResponse.prototype.addTileprovider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.background.TileProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.background.TileProviderResponse} returns this
 */
proto.robin.proto3.background.TileProviderResponse.prototype.clearTileproviderList = function() {
  return this.setTileproviderList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.background.BackgroundTileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.background.BackgroundTileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.BackgroundTileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    maptype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoom: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tilex: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tiley: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reqNr: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.background.BackgroundTileRequest}
 */
proto.robin.proto3.background.BackgroundTileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.background.BackgroundTileRequest;
  return proto.robin.proto3.background.BackgroundTileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.background.BackgroundTileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.background.BackgroundTileRequest}
 */
proto.robin.proto3.background.BackgroundTileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.robin.proto3.background.MapType} */ (reader.readEnum());
      msg.setMaptype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setZoom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTilex(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTiley(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReqNr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.background.BackgroundTileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.background.BackgroundTileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.BackgroundTileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaptype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getZoom();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getTilex();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getTiley();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getReqNr();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional MapType mapType = 1;
 * @return {!proto.robin.proto3.background.MapType}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.getMaptype = function() {
  return /** @type {!proto.robin.proto3.background.MapType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.robin.proto3.background.MapType} value
 * @return {!proto.robin.proto3.background.BackgroundTileRequest} returns this
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.setMaptype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sint32 zoom = 2;
 * @return {number}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.getZoom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.background.BackgroundTileRequest} returns this
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.setZoom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 tileX = 3;
 * @return {number}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.getTilex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.background.BackgroundTileRequest} returns this
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.setTilex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional sint32 tileY = 4;
 * @return {number}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.getTiley = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.background.BackgroundTileRequest} returns this
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.setTiley = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 req_nr = 5;
 * @return {number}
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.getReqNr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.background.BackgroundTileRequest} returns this
 */
proto.robin.proto3.background.BackgroundTileRequest.prototype.setReqNr = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.background.BackgroundTileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.background.BackgroundTileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.BackgroundTileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    format: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imagedata: msg.getImagedata_asB64(),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.background.BackgroundTileResponse}
 */
proto.robin.proto3.background.BackgroundTileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.background.BackgroundTileResponse;
  return proto.robin.proto3.background.BackgroundTileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.background.BackgroundTileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.background.BackgroundTileResponse}
 */
proto.robin.proto3.background.BackgroundTileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.robin.proto3.background.ImageFormat} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImagedata(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.background.BackgroundTileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.background.BackgroundTileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.background.BackgroundTileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getImagedata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional ImageFormat format = 1;
 * @return {!proto.robin.proto3.background.ImageFormat}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.getFormat = function() {
  return /** @type {!proto.robin.proto3.background.ImageFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.robin.proto3.background.ImageFormat} value
 * @return {!proto.robin.proto3.background.BackgroundTileResponse} returns this
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes imageData = 2;
 * @return {!(string|Uint8Array)}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.getImagedata = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes imageData = 2;
 * This is a type-conversion wrapper around `getImagedata()`
 * @return {string}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.getImagedata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImagedata()));
};


/**
 * optional bytes imageData = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImagedata()`
 * @return {!Uint8Array}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.getImagedata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImagedata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.robin.proto3.background.BackgroundTileResponse} returns this
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.setImagedata = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional uint32 status = 3;
 * @return {number}
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.background.BackgroundTileResponse} returns this
 */
proto.robin.proto3.background.BackgroundTileResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.robin.proto3.background.MapType = {
  ROADMAP: 0,
  SATELLITE: 1,
  TERRAIN: 2,
  HYBRID: 3
};

/**
 * @enum {number}
 */
proto.robin.proto3.background.ImageFormat = {
  UNKNOWN: 0,
  PNG: 1,
  JPG: 2,
  GIF: 3,
  TIF: 4
};

goog.object.extend(exports, proto.robin.proto3.background);
