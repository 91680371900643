import React from "react";
import { Login } from "./Login";
import { t } from "i18next";
import { Modal, SharedModalProps } from "../appearance/modal/Modal";

export const LoginModal = ({ onClosed }: SharedModalProps): JSX.Element => {
    // Render

    return (
        <Modal isBlocking message={t("login.loginModalMessage")} title={t("login.loginModalTitle")} onClosed={onClosed}>
            <Login />
        </Modal>
    );
};
