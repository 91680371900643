import styled from "styled-components";
import React, { Component, PropsWithChildren, ReactNode } from "react";
import { ScreenDimmer } from "../dimmer/ScreenDimmer";
import { clamp } from "../../utils/MathUtils";
import { SIDEBAR_WIDTH } from "../appearance/Sidebar";

export const BOTTOM_SHEET_ANIMATION_DURATION_MS = 300;

const Container = styled.div<{ height: string; isVisible: boolean }>`
    display: flex;
    margin-bottom: ${(props) => (props.isVisible ? 0 : "-" + props.height)};
    width: calc(100vw - ${SIDEBAR_WIDTH});
    height: ${({ height }) => height};
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.backgrounds.panel};
    transition: margin-bottom ${BOTTOM_SHEET_ANIMATION_DURATION_MS}ms ease-out,
        height ${BOTTOM_SHEET_ANIMATION_DURATION_MS}ms ease-out;
`;

interface ResizeIndicatorProps {
    isResizing: boolean;
    resizeYPosition?: number;
}

const ResizeIndicator = styled.div.attrs(({ resizeYPosition }: ResizeIndicatorProps) => ({
    style: {
        top: resizeYPosition ? resizeYPosition - 5 : undefined,
    },
}))<ResizeIndicatorProps>`
    width: 100%;
    height: 5px;
    cursor: row-resize;
    background-color: ${({ theme }) => theme.colors.secondary.blue};
    :hover {
        background-color: ${({ theme }) => theme.colors.secondary.blue200};
    }
    ${({ isResizing, theme }) =>
        isResizing &&
        `
        z-index: 1000;
        background-color: ${theme.colors.secondary.blue};
        position: absolute;
        :hover {
            background-color: ${theme.colors.secondary.blue};
        }
    `}
`;

const StyledScreenDimmer = styled(ScreenDimmer)`
    z-index: 11;
`;

interface ResizableProps {
    onResize?: (height: number) => void;
    minHeight?: number;
    maxHeight?: number;
}

interface Props {
    height: string;
    openOnMount?: boolean;
    resizable?: boolean | ResizableProps;
    insetY?: number;
}

interface State {
    isVisible: boolean;
    isResizing: boolean;
    resizeYPosition?: number;
}

export class BottomSheet extends Component<PropsWithChildren<Props>, State> {
    private readonly screenDimmer = React.createRef<ScreenDimmer>();
    private get insetY(): number {
        return this.props.insetY || 0;
    }

    public constructor(props: Props) {
        super(props);
        this.state = {
            isVisible: false,
            isResizing: false,
        };
    }

    public componentDidMount(): void {
        if (this.props.openOnMount) {
            setTimeout(() => this.setState({ isVisible: true }));
        }
    }

    public render(): ReactNode {
        const { height, children, resizable } = this.props;
        return (
            <>
                {resizable && (
                    <ResizeIndicator
                        isResizing={this.state.isResizing}
                        resizeYPosition={this.state.resizeYPosition}
                        onMouseDown={() => this.onStartResize()}
                        onTouchStart={() => this.onStartResize()}
                    />
                )}
                {this.state.isResizing && (
                    <StyledScreenDimmer
                        ref={this.screenDimmer}
                        animationDurationMillis={BOTTOM_SHEET_ANIMATION_DURATION_MS}
                    />
                )}
                <Container isVisible={this.state.isVisible} height={height}>
                    {children}
                </Container>
            </>
        );
    }

    public open(completion: () => void = () => {}): void {
        if (this.state.isVisible) {
            completion();
            return;
        }

        this.setState({ isVisible: true });
        setTimeout(() => {
            completion();
        }, BOTTOM_SHEET_ANIMATION_DURATION_MS);
    }

    public close(completion: () => void = () => {}): void {
        if (!this.state.isVisible) {
            completion();
            return;
        }

        this.setState({ isVisible: false });
        setTimeout(() => {
            completion();
        }, BOTTOM_SHEET_ANIMATION_DURATION_MS);
    }

    private onStartResize(): void {
        this.setState({ isResizing: true });
        document.addEventListener("mousemove", this.onMouseResize);
        document.addEventListener("touchmove", this.onTouchResize);
        document.addEventListener("mouseup", this.onEndResize);
        document.addEventListener("touchend", this.onEndResize);
        document.addEventListener("touchcancel", this.onEndResize);
    }

    private onEndResize = (): void => {
        document.removeEventListener("mousemove", this.onMouseResize);
        document.removeEventListener("touchmove", this.onTouchResize);
        document.removeEventListener("mouseup", this.onEndResize);
        document.removeEventListener("touchend", this.onEndResize);
        document.removeEventListener("touchcancel", this.onEndResize);

        this.state.resizeYPosition && this.onResize(window.innerHeight - this.state.resizeYPosition - this.insetY);

        this.screenDimmer.current && this.screenDimmer.current.hide();

        setTimeout(() => {
            this.setState({ isResizing: false, resizeYPosition: undefined });
        }, BOTTOM_SHEET_ANIMATION_DURATION_MS);
    };

    private onMouseResize = (event: MouseEvent): void => {
        this.setResizeYPosition(event.pageY);
    };

    private onTouchResize = (event: TouchEvent): void => {
        this.setResizeYPosition(event.touches[0].pageY);
    };

    private setResizeYPosition(y: number): void {
        this.setState({ resizeYPosition: clamp(y, this.minYPosition(), this.maxYPosition()) });
    }

    private onResize = (height: number): void => {
        if (this.props.resizable && typeof this.props.resizable !== "boolean" && this.props.resizable.onResize) {
            this.props.resizable.onResize(height);
        }
    };

    private minYPosition = (): number => {
        if (this.props.resizable && typeof this.props.resizable !== "boolean" && this.props.resizable.maxHeight) {
            return window.innerHeight - this.props.resizable.maxHeight - this.insetY;
        } else {
            return this.insetY;
        }
    };

    private maxYPosition = (): number => {
        if (this.props.resizable && typeof this.props.resizable !== "boolean" && this.props.resizable.minHeight) {
            return window.innerHeight - this.props.resizable.minHeight - this.insetY;
        } else {
            return window.innerHeight - this.insetY;
        }
    };
}
