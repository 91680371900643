import React, { useEffect, useRef, useState } from "react";
import { InputField } from "./InputField";
import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { t } from "i18next";
import { LINE_HEIGHT_LARGE } from "../theme/Spacing";

const Component = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    label {
        ${BASE_TEXT_STYLE}
        color: ${({ theme }) => theme.colors.text.text300};
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.x2};
`;

const HorizontalLine = styled.div`
    margin-top: ${LINE_HEIGHT_LARGE};
    width: ${({ theme }) => theme.spacing.x6};
    height: 2px;
    background-color: ${({ theme }) => theme.colors.interactive.input};
`;

interface Props {
    allowEmptyValues?: boolean;
    onChange: (min: number, max: number) => void;
    step?: number;
    upperBoundary?: number;
    lowerBoundary?: number;

    max?: number;
    maxLabel?: string;
    maxPlaceholder?: string;

    min?: number;
    minLabel?: string;
    minPlaceholder?: string;
}

/**
 * Two input fields for min and max values in a horizontal layout
 * @param allowEmptyValues Optional. Allow empty values in the input fields
 * @param onChange Required. Callback for when the min and max values change
 * @param step Optional. Set a step value for both input fields, in the current unit
 * @param upperBoundary Optional. Set the upper range limit
 * @param lowerBoundary Optional. Set the lower range limit
 *
 * @param max Required. The current value for the max input field
 * @param maxLabel Optional. Set a label for the max input field. Uses "Max" when not set
 * @param maxPlaceholder Optional. Set a placeholder for the max input field
 *
 * @param min Required. The current value for the min input field
 * @param minLabel Optional. Set a label for the min input field. Uses "Min" when not set
 * @param minPlaceholder Optional. Set a placeholder for the min input field
 */
export const FormMinMax = ({
    allowEmptyValues,
    onChange,
    step = 1,
    upperBoundary = 100,
    lowerBoundary = 0,

    max,
    maxLabel,
    maxPlaceholder,

    min,
    minLabel,
    minPlaceholder,
}: Props): JSX.Element => {
    // Properties & hooks

    const minRef = useRef<HTMLInputElement>(null);
    const maxRef = useRef<HTMLInputElement>(null);

    // Keep track of which input field the user is typing in
    const [userIsUpdatingInput, setUserIsUpdatingInput] = useState<"min" | "max" | false>(false);

    useEffect(() => {
        if (userIsUpdatingInput) {
            const userInputEndTimer = setTimeout(() => {
                handleChange();
            }, 500);
            return () => clearTimeout(userInputEndTimer);
        }

        if (minRef.current!.valueAsNumber !== min || maxRef.current!.valueAsNumber !== max) {
            const userInputEndTimer = setTimeout(() => updateFromProps(), 250);
            return () => clearTimeout(userInputEndTimer);
        }
    }, [min, max, userIsUpdatingInput]);

    // Local functions

    /**
     * Update the input fields with new prop values
     * This is called after the parent component has updated the props
     */
    const updateFromProps = (): void => {
        if (min !== undefined) {
            minRef.current!.value = min.toString();
        } else if (allowEmptyValues) {
            minRef.current!.value = "";
        }
        if (max !== undefined) {
            maxRef.current!.value = max.toString();
        } else if (allowEmptyValues) {
            maxRef.current!.value = "";
        }
    };

    /**
     * Validate and set the values once the user's done typing
     */
    const handleChange = (): void => {
        if (!userIsUpdatingInput || !minRef.current || !maxRef.current) {
            return;
        }

        const finishUpdate = (newMin: string, newMax: string): void => {
            minRef.current!.value = newMin;
            maxRef.current!.value = newMax;
            setUserIsUpdatingInput(false);
        };

        let newMin = minRef.current.valueAsNumber;
        let newMax = maxRef.current.valueAsNumber;

        // If the user has typed in an invalid value, set it to the limit
        // (max to upperBoundary and min to lowerBoundary)
        // If allowEmptyValues is true, stop further validation
        // FYI: When typing "e" in the input field, the valueAsNumber is NaN
        if (isNaN(newMin) && userIsUpdatingInput === "min") {
            if (allowEmptyValues) {
                finishUpdate("", newMax.toString());
                onChange(newMin, newMax);
                return;
            } else {
                newMin = lowerBoundary;
            }
        }
        if (isNaN(newMax) && userIsUpdatingInput === "max") {
            if (allowEmptyValues) {
                finishUpdate(newMin.toString(), "");
                onChange(newMin, newMax);
                return;
            } else {
                newMax = upperBoundary;
            }
        }

        // Set boundaries for both fields
        const minLowerBoundary = lowerBoundary;
        const minUpperBoundary = upperBoundary - 1;
        const maxLowerBoundary = lowerBoundary + 1;
        const maxUpperBoundary = upperBoundary;

        // Min value can't be smaller than it's lower boundary
        newMin = Math.max(minLowerBoundary, newMin);
        // Max value can't be greater than it's upper boundary
        newMax = Math.min(maxUpperBoundary, newMax);

        if (userIsUpdatingInput === "min") {
            // If min value is greater than it's upper boundary, set both values to their upper boundaries
            if (newMin > minUpperBoundary) {
                newMin = minUpperBoundary;
                newMax = maxUpperBoundary;
            } else if (newMin >= newMax) {
                // Max value can't be smaller than or equal to min value
                newMax = newMin + 1;
            }
        } else {
            // If max value is smaller than it's lower boundary, set both values to their lower boundaries
            if (newMax < maxLowerBoundary) {
                newMax = maxLowerBoundary;
                newMin = minLowerBoundary;
            } else if (newMax <= newMin) {
                // Min value can't be greater than or equal to max value
                newMin = newMax - 1;
            }
        }

        finishUpdate(newMin.toString(), newMax.toString());
        onChange(newMin, newMax);
    };

    // Render

    return (
        <Component>
            <Column>
                <label>{minLabel || t("general.min")}</label>
                <InputField
                    data-testid="min-input"
                    defaultValue={min || ""}
                    max={upperBoundary - 1}
                    min={lowerBoundary}
                    onChange={() => setUserIsUpdatingInput("min")}
                    onKeyDown={(e) => e.key.match("[\\.+]") && !e.key.match("e") && e.preventDefault()}
                    placeholder={minPlaceholder}
                    ref={minRef}
                    step={step}
                    type="number"
                />
            </Column>
            <HorizontalLine />
            <Column>
                <label>{maxLabel || t("general.max")}</label>
                <InputField
                    data-testid="max-input"
                    defaultValue={max || ""}
                    max={upperBoundary}
                    min={lowerBoundary + 1}
                    onChange={() => setUserIsUpdatingInput("max")}
                    onKeyDown={(e) => e.key.match("[\\.+]") && !e.key.match("e") && e.preventDefault()}
                    placeholder={maxPlaceholder}
                    ref={maxRef}
                    step={step}
                    type="number"
                />
            </Column>
        </Component>
    );
};
