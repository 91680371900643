export function validateEmail(email: string): boolean {
    if (!email) {
        return false;
    }

    if (email.length < 5) {
        return false;
    }

    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(mailformat)) {
        return true;
    }
    return false;
}
