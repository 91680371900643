import React from "react";
import { HomeViewModel } from "./HomeViewModel";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { Toolbar } from "../toolbar/Toolbar";
import { MapView } from "../map/MapView";
import { Settings } from "../settings/Settings";
import styled from "styled-components";
import { OverlayViewGroup } from "../appearance/overlays/OverlayViewGroup";
import { Legend } from "../legend/Legend";
import { AltitudeSlider } from "../altitude/AltitudeSlider";
import { OverlayManagement } from "../overlays/OverlayManagement";
import { PlaybackState } from "../../domain/PlaybackScene";
import { ReplayRequest } from "../replay/requestview/ReplayRequest";
import { ReplayControls } from "../replay/controls/ReplayControls";
import { SettingsUIActionRequest } from "../settings/SettingsUIActionRequest";
import { UserManagement } from "../usermanagement/UserManagement";
import { RadarControlPanel } from "../radarcontrolpanel/RadarControlPanel";
import { UserPermission } from "../../domain/model/UserPermission";
import { AlarmView } from "../alarm/AlarmView";
import { About } from "../about/About";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";
import { Observation } from "../observation/Observation";
import { Deterrence } from "../deterrence/Deterrence";
import {
    RunwayTrafficBottomSheet,
    RunwayTrafficBottomSheetComponent,
    RunwayTrafficViewMode,
} from "../charts/RunwayTrafficBottomSheet";
import { BOTTOM_SHEET_ANIMATION_DURATION_MS } from "../charts/BottomSheet";
import { TrackList } from "../tracklist/TrackList";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../infrastructure/AppConfig";
import { RadarLocation } from "../../domain/model/RadarLocation";
import { RadarLocationList } from "../radarlocationlist/RadarLocationList";
import { AltitudeFilterSettings } from "../altitude/AltitudeFilterSettings";
import { LoginModal } from "../login/LoginModal";
import { Sidebar } from "../sidebar/Sidebar";
import { INITIAL_HOME_UI_STATE, HomeUIState } from "../../domain/model";
import { OverlaysRoot } from "../appearance/overlays/OverlaysRoot";
import {
    OverlayVerticalContainerLeft,
    OverlayVerticalContainerRight,
} from "../appearance/overlays/OverlayVerticalContainer";
import { OverlayHorizontalContainer } from "../appearance/overlays/OverlayHorizontalContainer";
import { TileProviders } from "../tileproviders/TileProviders";
import { LanguageSelection } from "../languageSelection/LanguageSelection";
import { AltitudeExplanation } from "../radarcontrolpanel/modules/AltitudeExplanation";

const HomeRoot = styled.div`
    display: flex;
    flex: 1;
`;

const BackgroundViewsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
`;

interface HomeState extends HomeUIState {
    hasRunways: boolean;
    hasSelectedTrack: boolean;
    permissions: UserPermission[];
    playbackState: PlaybackState | null;
}

export class Home extends BaseSubscriptionHandlerComponent<{}, HomeState> {
    // Properties

    private runwayTrafficBottomSheet = React.createRef<RunwayTrafficBottomSheetComponent>();
    private mapView = React.createRef<MapView>();
    private readonly viewModel: HomeViewModel = DI.get(TYPES.HomeViewModel);
    private readonly flavorConfig: FlavorConfig = DI.get(TYPES.FlavorConfig);

    // Directly using getRuntimeConfig() here instead of getAppConfig(), because we need the return value to be an array
    private readonly radarLocationList =
        getRuntimeConfig<RadarLocation[]>(APP_CONFIG_KEYS.API_MULTIPLE_RADAR_LOCATIONS) || [];

    // Lifecycle

    public constructor(props: {}) {
        super(props);
        this.state = {
            ...INITIAL_HOME_UI_STATE,
            hasRunways: false,
            hasSelectedTrack: false,
            permissions: [],
            playbackState: null,
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservables();
    }

    public render(): React.ReactNode {
        return (
            <HomeRoot>
                <BackgroundViewsContainer>
                    <MapView ref={this.mapView} />
                    {this.state.hasRunways && (
                        <RunwayTrafficBottomSheet
                            ref={this.runwayTrafficBottomSheet}
                            onVisibilityChange={(isVisible) => {
                                this.viewModel.toggleRunwayTrafficBottomSheet(isVisible);
                                this.animateMapViewResize(BOTTOM_SHEET_ANIMATION_DURATION_MS);
                            }}
                            onModeChange={(mode) => {
                                this.animateMapViewResize(BOTTOM_SHEET_ANIMATION_DURATION_MS);
                                if (mode === RunwayTrafficViewMode.DETAILED_VIEW) {
                                    this.viewModel.closeAllPanels();
                                }
                            }}
                            onHeightChange={() => {
                                this.animateMapViewResize(BOTTOM_SHEET_ANIMATION_DURATION_MS);
                            }}
                        />
                    )}
                    {this.state.playbackState && this.state.playbackState !== PlaybackState.STOPPED && (
                        <ReplayControls />
                    )}
                </BackgroundViewsContainer>
                <OverlaysRoot>
                    <OverlayHorizontalContainer>
                        <OverlayVerticalContainerRight>
                            <OverlayViewGroup isVisible={this.state.isLegendVisible} side="right">
                                <Legend />
                            </OverlayViewGroup>
                            <OverlayViewGroup isVisible={this.state.isRadarControlPanelVisible} side="right">
                                <RadarControlPanel onClose={() => this.viewModel.hideRadarControlPanel()} />
                            </OverlayViewGroup>
                        </OverlayVerticalContainerRight>
                    </OverlayHorizontalContainer>
                    <OverlayHorizontalContainer>
                        <OverlayVerticalContainerLeft>
                            <OverlayViewGroup isVisible={this.state.isDeterrenceVisible} side="left">
                                <Deterrence onClose={() => this.viewModel.hideDeterrence()} />
                            </OverlayViewGroup>
                            <OverlayViewGroup isVisible={this.state.isObservationVisible} side="left">
                                <Observation onClose={() => this.viewModel.hideObservation()} />
                            </OverlayViewGroup>
                            <OverlayViewGroup isVisible={this.state.isTrackListVisible} side="left">
                                <TrackList onClose={() => this.viewModel.hideTrackList()} />
                            </OverlayViewGroup>
                            <OverlayViewGroup isVisible={this.state.isOverlaySettingsVisible} side="left">
                                <OverlayManagement
                                    editEnabled={
                                        this.flavorConfig.overlayEditingEnabled &&
                                        this.state.permissions.includes(UserPermission.CanManageOverlays)
                                    }
                                />
                            </OverlayViewGroup>
                            <OverlayViewGroup isVisible={this.state.isAltitudeFilterVisible} side="left">
                                <AltitudeSlider
                                    isAltitudeFilterSettingsVisible={this.state.isAltitudeFilterSettingsVisible}
                                    onClose={() => this.viewModel.hideAltitudeFilter()}
                                    onToggleSettingsPanel={() =>
                                        this.viewModel.toggleAltitudeFilterSettings(
                                            !this.state.isAltitudeFilterSettingsVisible,
                                        )
                                    }
                                />
                            </OverlayViewGroup>
                            <OverlayViewGroup isVisible={this.state.isRequestingReplayVisible} side="left">
                                <ReplayRequest
                                    onRequestSubmitted={(start, end) => this.viewModel.requestReplay(start, end)}
                                />
                            </OverlayViewGroup>
                        </OverlayVerticalContainerLeft>

                        <OverlayVerticalContainerLeft>
                            <OverlayViewGroup isVisible={this.state.isAltitudeFilterSettingsVisible} side="left">
                                <AltitudeFilterSettings
                                    onClose={() => this.viewModel.toggleAltitudeFilterSettings(false)}
                                />
                            </OverlayViewGroup>
                        </OverlayVerticalContainerLeft>
                    </OverlayHorizontalContainer>
                </OverlaysRoot>
                <Toolbar />
                <Sidebar />
                {this.state.isSettingsVisible && (
                    <Settings
                        onClosed={() => this.viewModel.hideSettings()}
                        onUIActionRequested={this.handleUIActionRequest.bind(this)}
                    />
                )}
                {this.renderModals()}
                <AlarmView position={this.state.isRunwayTrafficBottomSheetVisible ? "top" : "bottom"} />
            </HomeRoot>
        );
    }

    // Private functions

    private subscribeToObservables(): void {
        this.collectSubscriptions(
            this.viewModel.playbackState.subscribe((playbackState) => this.setState({ playbackState })),
            this.viewModel.currentUser.subscribe((user) => this.setState({ permissions: user.role.permissions })),
            this.viewModel.hasSelectedTrack.subscribe((hasSelectedTrack) => this.setState({ hasSelectedTrack })),
            this.viewModel.isSessionExpiredObservable.subscribe((isSessionExpired) =>
                this.viewModel.toggleLoginModal(isSessionExpired),
            ),
            this.viewModel.hasRunways.subscribe((hasRunways) => this.setState({ hasRunways })),
            this.viewModel.UIState.subscribe((uiState) => this.setState({ ...uiState })),
        );
    }

    private renderModals(): React.ReactNode {
        return (
            <>
                {this.state.isTileProviderSelectionModalVisible && (
                    <TileProviders onClosed={() => this.viewModel.toggleTileProviderSelectionModal(false)} />
                )}
                {this.state.isRadarLocationListModalVisible && (
                    <RadarLocationList
                        locations={this.radarLocationList}
                        onClosed={() => this.viewModel.hideRadarLocationList()}
                    />
                )}
                {this.state.isLanguageSelectionModalVisible && (
                    <LanguageSelection onClosed={() => this.viewModel.toggleLanguageSelectionModal(false)} />
                )}
                {this.state.isAboutModalVisible && <About onClosed={() => this.viewModel.toggleAboutModal(false)} />}
                {this.state.isAltitudeExplanationModalVisible && (
                    <AltitudeExplanation onClosed={() => this.viewModel.hideAltitudeExplanationModal()} />
                )}
                {this.state.isUserManagementModalVisible && (
                    <UserManagement onClosed={() => this.viewModel.toggleUserManagementModal(false)} />
                )}
                {this.state.isLoginModalVisible && (
                    <LoginModal onClosed={() => this.viewModel.toggleLoginModal(false)} />
                )}
            </>
        );
    }

    private handleUIActionRequest(action: SettingsUIActionRequest): void {
        switch (action) {
            case SettingsUIActionRequest.SHOW_TILE_PROVIDER_DIALOG:
                this.viewModel.toggleTileProviderSelectionModal(true);
                break;
            case SettingsUIActionRequest.SHOW_USER_MANAGEMENT_DIALOG:
                this.viewModel.toggleUserManagementModal(true);
                break;
            case SettingsUIActionRequest.SHOW_LANGUAGE_DIALOG:
                this.viewModel.toggleLanguageSelectionModal(true);
                break;
            case SettingsUIActionRequest.SHOW_ABOUT_DIALOG:
                this.viewModel.toggleAboutModal(true);
                break;
        }
    }

    /*
     * When the size of the map container changes, we need to call resize on the mapboxgl.Map object to make it redraw.
     * This is to avoid edge-cases in which the map produces empty canvas areas.
     */
    private animateMapViewResize(duration: number): void {
        const mapView = this.mapView.current;
        if (!mapView) {
            return;
        }

        /*
         * Resize the map in 10+1 steps.
         * This speed is fast enough to not look laggy and slow enough to not cause performance drop.
         */
        const interval = setInterval(() => mapView.resize(), duration / 10);
        /*
         * Here we cancel the interval and call the resize(), one last time to make sure the map fills all the
         * remaining space.
         *
         * Sometimes, due to a lag or performance drop, the interval will not exactly match the css animation.
         * Setting the timeout a bit after the last interval tick (extendedDuration) will make sure that the last
         * map resize, happens after the other views are fully opened/closed.
         */
        const extendedDuration = duration + 100;
        setTimeout(() => {
            clearInterval(interval);
            mapView.resize();
        }, extendedDuration);
    }
}
