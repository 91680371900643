import * as mapboxgl from "mapbox-gl";
import { GridsSnapshot, Grid } from "../../../domain/model";
import { Feature } from "geojson";
import * as MapUtils from "../../../utils/MapUtils";

const HEATMAP_LAYER_ID = "layer-bird-heatmap";
const HEATMAP_SOURCE_ID = "source-bird-heatmap";

const VISIBILITY_MODIFIER = 0.3;

export class HeatmapLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): HeatmapLayer {
        return new HeatmapLayer(map, orderLayer);
    }

    // Properties

    private constructor(private readonly map: mapboxgl.Map, private orderLayer: string) {
        this.setup();
    }

    // Public functions

    public update(gridsSnapshot: GridsSnapshot): void {
        const source = this.map.getSource(HEATMAP_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        source.setData({
            type: "FeatureCollection",
            features: this.getFeaturesFromGrids(gridsSnapshot.grids),
        });
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(HEATMAP_LAYER_ID, "visibility", visibility);
    }

    // Private functions

    private setup(): void {
        this.map.addSource(HEATMAP_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);
        this.map.addLayer(
            {
                id: HEATMAP_LAYER_ID,
                type: "heatmap",
                source: HEATMAP_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                layout: {},
                paint: {
                    // Increase the heatmap color weight weight by zoom level
                    "heatmap-weight": {
                        property: "density",
                        type: "exponential",
                        stops: [
                            [0, 0],
                            [1, 1],
                        ],
                    },
                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                    // Begin color ramp at 0-stop with a 0-transparancy color
                    // to create a blur-like effect.
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0,
                        "rgba(255, 235, 59, 0)",
                        0.1,
                        "rgba(253, 216, 55, 255)",
                        0.2,
                        "rgba(250, 196, 51, 255)",
                        0.3,
                        "rgba(248, 177, 46, 255)",
                        0.4,
                        "rgba(245, 158, 42, 255)",
                        0.5,
                        "rgba(243, 138, 38, 255)",
                        0.6,
                        "rgba(240, 119, 33, 255)",
                        0.7,
                        "rgba(238, 99, 29, 255)",
                        0.8,
                        "rgba(235, 80, 25, 255)",
                        0.9,
                        "rgba(233, 61, 21, 255)",
                        1,
                        "rgba(230, 42, 16, 255)",
                    ],
                    // increase radius as zoom increases
                    "heatmap-radius": {
                        stops: [
                            [0, 10],
                            [2, 10],
                            [4, 10],
                            [6, 10],
                            [8, 10],
                            [10, 10],
                            [12, 15],
                            [14, 40],
                            [22, 300],
                        ],
                    },
                    // decrease opacity to transition into the circle layer
                    "heatmap-opacity": {
                        stops: [
                            [0, VISIBILITY_MODIFIER],
                            [14, VISIBILITY_MODIFIER],
                            [22, 0],
                        ],
                    },
                },
            },
            this.orderLayer,
        );
    }

    private getFeaturesFromGrids(grids: Map<long, Grid>): Feature[] {
        const features: Feature[] = [];
        if (grids.size === 0) {
            return [];
        }
        const grid = Array.from(grids.entries())[grids.size - 1][1];
        const gridCenterLngLat = [grid.center.longitude, grid.center.latitude];
        const gridSize = grid.cellResolution / 1000;
        grid.data.forEach((chunk) => {
            const density = Math.max(0, Math.min((chunk.trackCountValue * VISIBILITY_MODIFIER) / grid.maxValue, 1));
            const dx = chunk.col * gridSize;
            const dy = chunk.row * gridSize;
            const point = MapUtils.lngLatPlusKM(gridCenterLngLat, dx + gridSize / 2, dy + gridSize / 2);

            features.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: point,
                },
                properties: {
                    density: density,
                },
            });
        });
        return features;
    }
}
