import React from "react";
import styled from "styled-components";
import { OldColors } from "./Colors";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";

const ValueLabel = styled.label`
    font-size: 14px;
    color: ${OldColors.primaryTint};
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    :hover {
        color: ${OldColors.primaryHoverTint};
    }
`;

interface Props {
    readonly observableValue: Rx.Observable<string>;
}

interface State {
    value: string;
}

export class TextController extends BaseSubscriptionHandlerComponent<Props, State> {
    public state = {
        value: "",
    };

    // Public functions

    public render(): React.ReactNode {
        return <ValueLabel>{this.state.value}</ValueLabel>;
    }

    public componentDidMount(): void {
        const subscription = this.props.observableValue.subscribe((value) => this.setState({ value: value }));
        this.collectSubscription(subscription);
    }
}
