import { ADSBFlight, Track, TrackObservationMode } from "../../model";
import { TrackSelectionRepository } from "../TrackSelectionRepository";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    ADSBFlightRepository,
    RadarRepository,
    ReplayRepository,
    TrackObservationRepository,
    TrackRepository,
} from "..";
import { TracksSnapshotDiffCalculator } from "../../TracksSnapshotDiffCalculator";
import { ADSBFlightsSnapshotDiffCalculator } from "../../ADSBFlightsSnapshotDiffCalculator";
import { PlaybackState } from "../../PlaybackScene";

export class CommonTrackSelectionRepository implements TrackSelectionRepository {
    // Properties

    private readonly selectedTrackSubject = new Rx.BehaviorSubject<Track | ADSBFlight | undefined>(undefined);
    public readonly selectedTrack: Rx.Observable<Track | ADSBFlight | undefined> =
        this.selectedTrackSubject.asObservable();
    public readonly hasSelectedTrack: Rx.Observable<boolean> = this.selectedTrackSubject.pipe(
        RxOperators.map((trackOrFlight) => trackOrFlight !== undefined),
        RxOperators.distinctUntilChanged(),
    );
    public readonly selectedTrackId: Rx.Observable<number | undefined> = this.selectedTrackSubject.pipe(
        RxOperators.distinctUntilChanged((previous, current) => previous?.id === current?.id),
        RxOperators.map((trackOrFlight) => (trackOrFlight && trackOrFlight.id) || undefined),
    );

    private readonly lastSnapshotTimeSubject = new Rx.BehaviorSubject<number>(0);
    public readonly lastSnapshotTime: Rx.Observable<number> = this.lastSnapshotTimeSubject.asObservable();

    private lastSelectedTrackId?: number;

    private get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((s) => (s == null ? Rx.of(null) : s.state)),
            RxOperators.distinctUntilChanged(),
            RxOperators.debounceTime(500),
            RxOperators.skip(1),
        );
    }

    constructor(
        private trackRepository: TrackRepository,
        private adsbFlightRepository: ADSBFlightRepository | undefined,
        private replayRepository: ReplayRepository,
        private radarRepository: RadarRepository,
        private observationRepository: TrackObservationRepository,
        private trackSnapshotDiffCalculator: TracksSnapshotDiffCalculator,
        private adsbFlightSnapshotDiffCalculator: ADSBFlightsSnapshotDiffCalculator | undefined,
    ) {
        this.setupSubscriptions();
    }

    // Public functions

    public deselectTracks(): void {
        this.trackRepository.toggleSelectedTrackId(null);
        this.adsbFlightRepository?.toggleSelectedFlightId(null);
    }

    // Private functions

    private setupSubscriptions(): void {
        Rx.combineLatest([
            this.trackRepository.selectedTrackId,
            this.adsbFlightRepository?.selectedFlightId || Rx.of(undefined),
            this.trackSnapshotDiffCalculator.snapshotDiff,
            this.adsbFlightSnapshotDiffCalculator?.snapshotDiff || Rx.of(undefined),
        ]).subscribe({
            next: ([selectedRadarTrackId, selectedADSBFlightId, radarSnapshot, adsbSnapshot]) => {
                const radarTracks = [...radarSnapshot.finishedTracks, ...radarSnapshot.snapshotTracksWithEstimates];
                const adsbFlights = adsbSnapshot
                    ? [...adsbSnapshot.finishedTracks, ...adsbSnapshot.snapshotTracksWithEstimates]
                    : [];
                const selectedRadarTrack = radarTracks.find((t) => t.id === selectedRadarTrackId);
                const selectedADSBFlight = adsbFlights.find((t) => t.id === selectedADSBFlightId);
                if (selectedRadarTrack && selectedADSBFlight) {
                    // If both tracks are selected, deselect the previously selected track
                    if (selectedRadarTrackId === this.lastSelectedTrackId) {
                        this.trackRepository.toggleSelectedTrackId(null);
                        // It's not possible to cover the branch where selectedADSBFlightId !== this.lastSelectedTrackId
                        // because if both are selected, and selectedRadarTrackId !== this.lastSelectedTrackId,
                        // then ALWAYS selectedADSBFlightId would be equal to this.lastSelectedTrackId
                    } else if (selectedADSBFlightId === this.lastSelectedTrackId) {
                        this.adsbFlightRepository?.toggleSelectedFlightId(null);
                    }
                }

                const selectedTrackToDisplay = selectedRadarTrack ?? selectedADSBFlight;
                this.lastSelectedTrackId = selectedTrackToDisplay?.id;
                this.selectedTrackSubject.next(selectedTrackToDisplay);

                // Update the last snapshot time based on selected track
                if (selectedTrackToDisplay instanceof Track) {
                    this.lastSnapshotTimeSubject.next(radarSnapshot.snapshotTimestamp);
                } else if (selectedTrackToDisplay instanceof ADSBFlight) {
                    this.lastSnapshotTimeSubject.next(adsbSnapshot!.snapshotTimestamp);
                }
            },
        });
        this.playbackState.subscribe({
            next: () => this.deselectTracks(),
        });
        this.radarRepository.alignmentState
            .pipe(
                RxOperators.distinctUntilKeyChanged("isActive"),
                RxOperators.filter((s) => s.isActive),
            )
            .subscribe({ next: () => this.deselectTracks() });
        this.radarRepository.blankingSectorsState
            .pipe(
                RxOperators.distinctUntilKeyChanged("isEditModeActive"),
                RxOperators.filter((s) => s.isEditModeActive),
            )
            .subscribe({ next: () => this.deselectTracks() });
        this.radarRepository.repositioningState
            .pipe(
                RxOperators.distinctUntilKeyChanged("isActive"),
                RxOperators.filter((s) => s.isActive),
            )
            .subscribe({ next: () => this.deselectTracks() });
        this.observationRepository.mode
            .pipe(
                RxOperators.distinctUntilChanged(),
                RxOperators.filter((mode) => mode !== TrackObservationMode.None),
            )
            .subscribe({
                next: () => this.deselectTracks(),
            });
    }
}
