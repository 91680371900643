import mapboxgl from "mapbox-gl";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { MapModule } from "../MapModule";
import { DeterrenceDevicesModuleViewModel } from "./DeterrenceDevicesModuleViewModel";
import { LayerManager } from "../LayerManager";
import { Location } from "../../../../domain/model";
import { ORDER_LAYER_DETERRENCE_DEVICES } from "../Orders";
import { DeterrenceDevicesLayer } from "../../layers";

export class DeterrenceDevicesModule extends MapModule<DeterrenceDevicesModuleViewModel> {
    public constructor(protected readonly viewModel: DeterrenceDevicesModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const deterrenceDevicesLayer = DeterrenceDevicesLayer.attachedTo(
            map,
            ORDER_LAYER_DETERRENCE_DEVICES.id,
            this.onDeterrenceDeviceClicked,
        );

        this.collectSubscriptions(
            this.viewModel.deterrenceDeviceStates.subscribe(
                (deterrenceDeviceStates) => deterrenceDevicesLayer.setDeterrenceDeviceStates(deterrenceDeviceStates),
                (error) => console.error(error),
            ),
            this.viewModel.showDeterrenceDevices.subscribe(
                (showDeterrenceDevices) => deterrenceDevicesLayer.setEnabled(showDeterrenceDevices),
                (error) => console.error(error),
            ),
            Rx.NEVER.pipe(RxOperators.finalize(() => deterrenceDevicesLayer.onModuleDispose())).subscribe(),
        );
    }

    // Private functions

    private onDeterrenceDeviceClicked = (deviceId: int): void => {
        this.viewModel.toggleSelectedState(deviceId);
    };
}
