import * as Rx from "rxjs";
import { SettingsItemSection } from "../SettingsItemSection";
import { SettingItemViewModel, ActionItemViewModel } from "../SettingItemViewModel";
import { SettingItemIcon } from "../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../domain/repositories/LocalPreferencesRepository";
import { SettingsErrorHandler } from "../SettingsErrorHandler";
import { SettingsUIActionRequestHandler } from "../SettingsUIActionRequestHandler";
import LanguageIcon from "../../../res/images/settings/language.svg";
import { SettingsUIActionRequest } from "../SettingsUIActionRequest";
import { t } from "i18next";
import { LocalUserPreferenceKeys } from "../../../domain/model";

export class CommonInterfaceSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "interface-settings";
    public readonly label: string | null = t("settings.interface");

    public constructor(protected readonly localPreferencesRepository: LocalPreferencesRepository) {}

    // Public functions

    public generate(
        _: SettingsErrorHandler,
        uiActionRequestHandler: SettingsUIActionRequestHandler,
    ): Rx.Observable<SettingItemViewModel[]> {
        const viewModels: SettingItemViewModel[] = [
            new ActionItemViewModel(
                t("settings.language"),
                SettingItemIcon.withUrl(LanguageIcon),
                () => uiActionRequestHandler.onUIActionRequested(SettingsUIActionRequest.SHOW_LANGUAGE_DIALOG),
                undefined,
            ),
            new ActionItemViewModel(
                "Reset language", // Don't translate this line!
                SettingItemIcon.withUrl(LanguageIcon),
                () => this.resetToDefaultLanguage(),
                undefined,
            ),
        ];
        return Rx.of(viewModels);
    }

    // Private functions

    private resetToDefaultLanguage(): void {
        this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.language);
        window.location.reload();
    }
}
