import { Classification, Location, AlarmData, AlarmDataType, FlightInfo } from ".";
import {
    Track as TrackProto,
    Estimate as EstimateProto,
    TrackPlotType as TrackPlotTypeProto,
    FlightPhaseType as FlightPhaseTypeProto,
    ClassificationHistoryItem as ClassificationHistoryItemProto,
} from "./proto/generated/tracklist3_pb";
import { EstimateJSON, TrackJSON, TrackStateJSON, TrackUpdateNewTrackJSON } from "./json";
import _minBy from "lodash/minBy";
import _reverse from "lodash/reverse";
import _takeWhile from "lodash/takeWhile";
import _dropWhile from "lodash/dropWhile";
import { BaseEstimate } from "./BaseEstimate";

export interface Trackable {
    id: int;
    name: string | null;
    icao: int;

    isFinished: boolean;

    getCurrentLocation(): Location | null;
}

export enum FlightPhase {
    TAXIING = 1,
    TAKEOFF,
    DEPARTURE,
    ENROUTE,
    APPROACH,
    LANDING,
    STATIONARY,
    UNKNOWN,
}

export enum TrackPlotType {
    UNKNOWN = 1,
    RANGE_AZIMUTH_ELEVATION,
    RANGE_AZIMUTH,
    RANGE_ELEVATION,
    RANGE,
}

export class Estimate implements BaseEstimate {
    // Static functions

    public static plotTypeFrom(model: TrackPlotTypeProto): TrackPlotType {
        switch (model) {
            case TrackPlotTypeProto.RA:
                return TrackPlotType.RANGE;
            case TrackPlotTypeProto.RAAZ:
                return TrackPlotType.RANGE_AZIMUTH;
            case TrackPlotTypeProto.RAAZEL:
                return TrackPlotType.RANGE_AZIMUTH_ELEVATION;
            case TrackPlotTypeProto.RAEL:
                return TrackPlotType.RANGE_ELEVATION;
            case TrackPlotTypeProto.UNKNOWN:
            default:
                return TrackPlotType.UNKNOWN;
        }
    }

    public static fromProto(
        model: EstimateProto,
        classification: Classification | null,
        alarms: AlarmData[],
    ): Estimate {
        return new Estimate(
            model.getTimestampMsec(),
            Location.fromProto(model.getPosition()),
            model.getVelocity(),
            model.getBearing(),
            model.getRssiteid(),
            Estimate.plotTypeFrom(model.getPlottype()),
            null,
            classification,
            alarms,
        );
    }

    public static fromTrackStateJson(model: TrackStateJSON, classificationMap: Map<string, Classification>): Estimate {
        return new Estimate(
            model.timestampMsec,
            Location.fromJson(model.coordinate),
            model.velocity,
            model.bearing,
            0,
            TrackPlotType.UNKNOWN,
            model.rcs,
            classificationMap.get(model.classification)!,
            model.alarm ? [{ type: AlarmDataType.DRONE, trackId: model.trackid }] : [],
        );
    }

    public static fromEstimateJson(
        model: EstimateJSON,
        rcs: number | null,
        classification: Classification | null,
    ): Estimate {
        return new Estimate(
            model.timestamp * 1000,
            Location.fromJson(model.position),
            model.velocity,
            model.bearing,
            0,
            TrackPlotType.UNKNOWN,
            rcs,
            classification,
            [],
        );
    }

    public constructor(
        public readonly timestamp: long,
        public readonly location: Location,
        public readonly velocity: float,
        public readonly bearing: float,
        public readonly siteId: int,
        public readonly plotType: TrackPlotType,
        public readonly rcs: number | null,
        public readonly classification: Classification | null,
        public readonly alarms: AlarmData[],
        public readonly flightInfo?: FlightInfo,
    ) {}
}

export class Track implements Trackable {
    // Static functions

    public static flightPhaseFrom(model: FlightPhaseTypeProto): FlightPhase {
        switch (model) {
            case FlightPhaseTypeProto.APPROACH:
                return FlightPhase.APPROACH;
            case FlightPhaseTypeProto.DEPARTURE:
                return FlightPhase.DEPARTURE;
            case FlightPhaseTypeProto.ENROUTE:
                return FlightPhase.ENROUTE;
            case FlightPhaseTypeProto.LANDING:
                return FlightPhase.LANDING;
            case FlightPhaseTypeProto.STATIONARY:
                return FlightPhase.STATIONARY;
            case FlightPhaseTypeProto.TAKEOFF:
                return FlightPhase.TAKEOFF;
            case FlightPhaseTypeProto.TAXIING:
                return FlightPhase.TAXIING;
            case FlightPhaseTypeProto.UNKNOWN:
            default:
                return FlightPhase.UNKNOWN;
        }
    }

    public static fromProto(model: TrackProto, classificationMap: Map<string, Classification>): Track {
        const estimates = model.getEstimatesList();

        const classifications = new Map<number, string>();
        const droneAlarms = new Map<number, boolean>();

        const isBetween = (value: number, min: number, max: number): boolean =>
            value >= min && (max < 0 || value < max);

        model.getEstimatesList().forEach((estimate) => {
            const et = estimate.getTimestampMsec();
            const alarm = model
                .getDronealarmhistoryList()
                .some((entry) => isBetween(et, entry.getTimestampStartMsec(), entry.getTimestampEndMsec()));
            droneAlarms.set(estimate.getTimestampMsec(), alarm);

            const classification = this.getClassificationForEstimateTime(model, et);
            classifications.set(estimate.getTimestampMsec(), classification);
        });

        return new Track(
            model.getId(),
            model.getIcao(),
            estimates.map((value) =>
                Estimate.fromProto(
                    value,
                    classificationMap.get(classifications.get(value.getTimestampMsec())!)!,
                    droneAlarms.get(value.getTimestampMsec())
                        ? [{ type: AlarmDataType.DRONE, trackId: model.getId() }]
                        : [],
                ),
            ),
            Track.flightPhaseFrom(model.getFlightphase()),
            Estimate.plotTypeFrom(model.getTracktype()),
            null as string | null,
            model.getDatabaseid(),
        );
    }

    public static fromJson(model: TrackJSON, classificationMap: Map<string, Classification>): Track {
        const lastUpdate = model.trackUpdates[model.trackUpdates.length - 1];
        return new Track(
            model.trackid,
            0,
            model.trackUpdates.map((m) => Estimate.fromTrackStateJson(m, classificationMap)),
            FlightPhase.UNKNOWN,
            TrackPlotType.UNKNOWN,
            null as string | null,
            model.databaseId,
            lastUpdate.flightInfo,
        );
    }

    public static newEmpty(id: number): Track {
        return new Track(id, -1, [], FlightPhase.UNKNOWN, TrackPlotType.UNKNOWN, null, -1);
    }

    public static fromTrackUpdateJson(
        model: TrackUpdateNewTrackJSON,
        classificationMap: Map<string, Classification>,
    ): Track {
        return new Track(
            model.trackId,
            0,
            model.track.estimates.map((estimate, i) =>
                Estimate.fromEstimateJson(
                    estimate,
                    model.track.plots[i]?.rcs,
                    classificationMap.get(model.track.classification)!,
                ),
            ),
            FlightPhase.UNKNOWN,
            TrackPlotType.UNKNOWN,
            null as string | null,
            undefined, // NOTE: Database id is not relevant for track updates, only in replay
        );
    }

    // Properties

    public isFinished = false;

    public get isVehicle(): boolean {
        const c = this.lastEstimate.classification;
        if (c == null) {
            return false;
        }
        return c.isVehicle;
    }

    public get isAirCraft(): boolean {
        const c = this.lastEstimate.classification;
        if (c == null) {
            return false;
        }
        return c.isAircraft;
    }

    public get isDrone(): boolean {
        const c = this.lastEstimate.classification;
        if (c == null) {
            return false;
        }
        return c.isDrone;
    }

    public get isBird(): boolean {
        const c = this.lastEstimate.classification;
        if (c == null) {
            return false;
        }
        return c.isBird;
    }

    public get isVehicleOrAirCraft(): boolean {
        return this.isAirCraft || this.isVehicle;
    }

    public get startTime(): long | null {
        if (this.estimates.length === 0) {
            return null;
        }
        return this.estimates[0].timestamp;
    }

    public get endTime(): long | null {
        if (this.estimates.length === 0) {
            return null;
        }
        return this.estimates[this.estimates.length - 1].timestamp;
    }

    public get lastEstimate(): Estimate {
        return this.estimates[this.estimates.length - 1];
    }

    // Lifecycle

    public constructor(
        public readonly id: int,
        public readonly icao: int,
        public readonly estimates: Estimate[],
        public readonly flightPhase: FlightPhase,
        public readonly trackPlotType: TrackPlotType,
        public name: string | null,
        public readonly databaseId: int = -1,
        public flightInfo?: FlightInfo,
    ) {}

    // Public functions

    public clone(overrideParams: Partial<Track> = {}): Track {
        const params = { ...this, ...overrideParams };
        const newTrack = new Track(
            params.id,
            params.icao,
            params.estimates,
            params.flightPhase,
            params.trackPlotType,
            params.name,
            params.databaseId,
            params.flightInfo,
        );
        return newTrack;
    }

    public getCurrentLocation(): Location | null {
        if (this.estimates.length === 0) {
            return null;
        }
        return this.estimates[this.estimates.length - 1].location;
    }

    public hasRangeAndAzimuthCompatible(): boolean {
        return (
            this.trackPlotType === TrackPlotType.RANGE_AZIMUTH_ELEVATION ||
            this.trackPlotType === TrackPlotType.RANGE_AZIMUTH ||
            this.trackPlotType === TrackPlotType.UNKNOWN // Ensure backwards compatibility
        );
    }

    public getClosestEstimateTo(timestamp: long): Estimate | null {
        return _minBy(this.estimates, (e) => Math.abs(e.timestamp - timestamp)) || null;
    }

    // private functions

    private static getClassificationForEstimateTime(track: TrackProto, estimate_time_msec: number): string {
        const classificationHistory = track.getClassificationhistoryList();
        if (classificationHistory.length === 0) {
            // Old backend that does not provide a classification history.
            return track.getClassification();
        }
        let classification: ClassificationHistoryItemProto | null = null;
        for (const entry of classificationHistory) {
            if (entry.getTimestampMsec() < estimate_time_msec) {
                classification = entry;
            } else {
                break;
            }
        }
        return classification === null ? "UNCLASSIFIED" : classification.getClassification();
    }
}

export function checkEstimateExpiration(estimate: BaseEstimate, timestamp: long, lifeTime: long): boolean {
    return estimate.timestamp > timestamp - lifeTime;
}

export function getTrackEstimatesWithinPeriod(track: Track, timestamp: long, lifeTime: number): Estimate[] {
    const reversedEstimates = _reverse(Array.from(track.estimates));
    const endTrimmedEstimates = _takeWhile(reversedEstimates, (estimate) =>
        checkEstimateExpiration(estimate, timestamp, lifeTime),
    );
    const startTrimmedEstimates = _dropWhile(endTrimmedEstimates, (estimate) => estimate.timestamp > timestamp);
    const processedEstimates = startTrimmedEstimates.filter((estimate) => estimate != null);
    return processedEstimates;
}
