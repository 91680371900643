import React from "react";
import styled from "styled-components";
import { TYPES } from "../../di/Types";
import { useObservable } from "../../hooks";
import { useViewModel } from "../../hooks/useViewModel";
import { BirdTabButton } from "./modules/BirdTabButton";
import { RunwayTrafficButton } from "./modules/RunwayTrafficButton";
import { ToolbarButton } from "./modules/ToolbarButton";
import { ToolbarViewModel } from "./ToolbarViewModel";
import { OVERLAY_SPACING } from "../appearance/theme/Spacing";

export const TOOLBAR_WIDTH = "62px";

export const Container = styled.section`
    width: ${TOOLBAR_WIDTH};
    z-index: 100;
    display: table;
    left: 0;
    position: absolute;
    top: ${OVERLAY_SPACING};
`;

const ToolbarGroup = styled.div<{ vertical?: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.x1};
    margin: 0;
    padding: ${({ theme }) => theme.spacing.x1} ${({ theme }) => theme.spacing.x2};
    background-color: rgba(0, 0, 0, 0.6);
    border-top-right-radius: ${({ theme }) => theme.spacing.x2};
    border-bottom-right-radius: ${({ theme }) => theme.spacing.x2};
    transition: background-color ${({ theme }) => theme.transitionDuration} ease-in-out;

    &:hover {
        background-color: rgba(0, 0, 0, 1);
    }
`;

export const Toolbar = (): JSX.Element => {
    // Properties

    const viewModel: ToolbarViewModel = useViewModel(TYPES.ToolbarViewModel);
    const isTrackListEnabled = useObservable(viewModel.isTrackListEnabled, false);
    const hasReliableAltitudeInfo = useObservable(viewModel.hasReliableAltitudeInfo, false);
    const isDeterrenceActionAllowed = useObservable(viewModel.isDeterrenceActionAllowed, false);

    // Render

    return (
        <Container id="toolbar-container">
            <ToolbarGroup vertical>
                <BirdTabButton />
                {viewModel.isRadarLocationListAvailable && (
                    <ToolbarButton iconType="LIST_RADAR" uiStateKey="isRadarLocationListModalVisible" />
                )}
                <RunwayTrafficButton />
                {isTrackListEnabled && (
                    <ToolbarButton iconType="LIST" positionOnScreen="left" uiStateKey="isTrackListVisible" />
                )}
                {hasReliableAltitudeInfo && (
                    <ToolbarButton
                        iconType="ALTITUDE_FILTER"
                        positionOnScreen="left"
                        uiStateKey="isAltitudeFilterVisible"
                    />
                )}
                {isDeterrenceActionAllowed && (
                    <ToolbarButton iconType="SCARECROW" positionOnScreen="left" uiStateKey="isDeterrenceVisible" />
                )}
                <ToolbarButton iconType="OVERLAYS" positionOnScreen="left" uiStateKey="isOverlaySettingsVisible" />
                <ToolbarButton iconType="EYE" positionOnScreen="left" uiStateKey="isObservationVisible" />
                <ToolbarButton iconType="REPLAY" positionOnScreen="cover" uiStateKey="isRequestingReplayVisible" />
            </ToolbarGroup>
        </Container>
    );
};
