import mapboxgl from "mapbox-gl";
import { MapModule } from "../MapModule";
import { ORDER_LAYER_OVERLAYS } from "../Orders";
import { OverlaysModuleViewModel } from "./OverlaysModuleViewModel";
import { OverlaysLayer } from "../../layers";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";
import { EditMode, OverlaySelection } from "../../../../domain/repositories";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";

export class OverlaysModule extends MapModule<OverlaysModuleViewModel> {
    public constructor(
        protected readonly viewModel: OverlaysModuleViewModel,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const overlaysLayer = OverlaysLayer.attachedTo(map, ORDER_LAYER_OVERLAYS.id, this.distanceFormatter);

        this.collectSubscriptions(
            this.viewModel.overlayUpdates.subscribe({
                next: (overlays) => overlaysLayer.setOverlays(overlays),
                error: (error) => console.error(error),
            }),
            this.viewModel.overlayUpdates
                .pipe(
                    RxOperators.flatMap((o) => Rx.from(o.keys())),
                    RxOperators.flatMap((name) =>
                        this.viewModel
                            .observeOverlayVisibility(name)
                            .pipe(RxOperators.map((visible) => ({ name: name, visible: visible }))),
                    ),
                )
                .subscribe({
                    next: (overlay) => overlaysLayer.setOverlayVisibility(overlay.name, overlay.visible),
                    error: (error) => console.error(error),
                }),
            Rx.NEVER.pipe(RxOperators.finalize(() => overlaysLayer.onModuleDispose())).subscribe(),
        );

        if (this.viewModel.isOverlayEditingEnabled) {
            this.setupEditMode(overlaysLayer);
        }
    }

    // Private functions

    private setupEditMode(overlaysLayer: OverlaysLayer): void {
        let subscription = this.viewModel.editState.pipe(RxOperators.distinctUntilKeyChanged("mode")).subscribe(
            (state) => overlaysLayer.setSelectionEnabled(state.mode === EditMode.SELECT),
            (error) => console.error(error),
        );
        this.collectSubscription(subscription);

        subscription = this.viewModel.isolatedOverlays.subscribe((o) => overlaysLayer.isolateOverlays(o));
        this.collectSubscription(subscription);

        subscription = this.viewModel.editState.subscribe((s) => {
            if (s.mode === EditMode.SELECT && s.payload) {
                overlaysLayer.setShapeSelection(s.payload as OverlaySelection[]);
            } else {
                overlaysLayer.setShapeSelection([]);
            }
        });
        this.collectSubscription(subscription);

        overlaysLayer.onShapeClicked = (selection: OverlaySelection, isSelected: boolean) => {
            this.viewModel.selectShape(selection, !isSelected);
        };
    }
}
