import styled from "styled-components";
import React, { Component } from "react";
import { getPointFromReactEvent, getPointFromEvent } from "../../../utils/UserInteractionEventsUtils";
import { BASE_TEXT_STYLE } from "../../appearance/theme/GlobalStyles";
import { DateFormatter } from "../../../infrastructure/DateFormatter";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";

const PIN_SIZE = 16;

const Container = styled.div`
    flex: 1;
    height: ${PIN_SIZE}px;
    position: relative;
`;

const Track = styled.div`
    width: 100%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    background-color: white;
    opacity: 0.5;
    position: absolute;
`;

const FilledTrack = styled.div`
    position: absolute;
    background-color: white;
    opacity: 1;
    height: 4px;
    top: 50%;
    margin-top: -2px;
`;

const Pin = styled.div`
    position: absolute;
    background-color: white;
    top: 0;
    margin-left: -${PIN_SIZE / 2}px;
    width: ${PIN_SIZE}px;
    height: ${PIN_SIZE}px;
    border-radius: ${PIN_SIZE / 2}px;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.colors.secondary.white200};

        + div {
            display: flex;
        }
    }
`;

const PopupContainer = styled.div<{ isDragging: boolean }>`
    position: absolute;
    display: ${({ isDragging }) => (isDragging ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-content: center;
    top: -45px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
    padding: 0 ${({ theme }) => theme.spacing.x2};
    height: ${({ theme }) => theme.spacing.x9};
    background-color: ${({ theme }) => theme.colors.backgrounds.panelSection};
    border-radius: ${({ theme }) => theme.spacing.x2};
    text-align: center;
    z-index: 2000;
`;

const DateTimeText = styled.label`
    ${BASE_TEXT_STYLE}
    font-size: 12px;
    font-weight: 500;
    color: white;
    white-space: pre-wrap;
    text-align: "center";
`;

interface State {
    pinPositionPercentage: number;
}

interface Props {
    dateTime: Date;
    percentage: number;
    onChange: (percentage: number) => void;
    onSubmit: () => void;
}

export class ReplaySeekbar extends Component<Props, State> {
    // Properties

    private readonly container: React.RefObject<HTMLDivElement>;
    private readonly dateFormatter: DateFormatter = DI.get(TYPES.DateFormatter);
    private isDragging = false;

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.container = React.createRef();
        this.state = {
            pinPositionPercentage: this.props.percentage,
        };
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <Container
                ref={this.container}
                onMouseDown={(event: React.MouseEvent) => this.onDragStart(event)}
                onTouchStart={(event: React.TouchEvent) => this.onDragStart(event)}
            >
                <Track />
                <FilledTrack style={{ width: this.state.pinPositionPercentage + "%" }} />
                <Pin style={{ left: this.state.pinPositionPercentage + "%" }} />
                <PopupContainer isDragging={this.isDragging} style={{ left: this.state.pinPositionPercentage + "%" }}>
                    <DateTimeText>{this.dateFormatter.formatFullDate(this.props.dateTime)}</DateTimeText>
                </PopupContainer>
            </Container>
        );
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.percentage !== this.props.percentage && !this.isDragging) {
            this.setState({ pinPositionPercentage: this.props.percentage });
        }
    }

    public componentDidMount(): void {
        window.addEventListener("mouseup", this.onDragEnd);
        window.addEventListener("touchend", this.onDragEnd);
        window.addEventListener("mousemove", this.onDrag);
        window.addEventListener("touchmove", this.onDrag);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
        window.removeEventListener("mousemove", this.onDrag);
        window.removeEventListener("touchmove", this.onDrag);
    }

    // Private functions

    private onDragStart(event: React.MouseEvent | React.TouchEvent): void {
        this.isDragging = true;
        this.adjustPinPositionByX(getPointFromReactEvent(event).x);
    }

    private onDragEnd = (): void => {
        if (this.isDragging) {
            this.props.onSubmit();
        }
        this.isDragging = false;
    };

    private onDrag = (event: MouseEvent | TouchEvent): void => {
        if (!this.isDragging) {
            return;
        }
        this.adjustPinPositionByX(getPointFromEvent(event).x);
    };

    private adjustPinPositionByX(x: number): void {
        if (this.container.current == null) {
            return;
        }
        const containerFrame = this.container.current.getBoundingClientRect();
        const position = x - containerFrame.left;
        let percentage = (position / containerFrame.width) * 100;
        if (percentage < 0) {
            percentage = 0;
        }
        if (percentage > 100) {
            percentage = 100;
        }
        this.setState({ pinPositionPercentage: percentage });
        this.props.onChange(percentage);
    }
}
