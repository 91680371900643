import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { PropsWithTooltip } from "../Tooltip";

const Input = styled.input`
    display: none;
    + label {
        cursor: pointer;
        position: relative;
        height: 22px;
        line-height: 22px;
        padding-left: 49px; // 43px + (22px - 16px)
        transition: all 0.4s ease-in-out 0s;
    }
    + label:after,
    + label:before {
        top: 25%;
        content: "";
        position: absolute;
        transition: inherit;
    }
    + label:before {
        top: 50%;
        left: 16px;
        z-index: 1;
        width: 22px;
        content: "";
        height: 22px;
        border-radius: 50%;
        position: absolute;
        margin-top: -7.5px;
        transition: inherit;
        background: ${({ theme }) => theme.colors.text.text};
    }
    + label:after {
        left: 0;
        width: 43px;
        margin-left: 20px;
        height: 16px;
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.text.text300};
    }
    :checked + label:before {
        left: 46px; // 43px + (22px - 16px) / 2
    }
    :checked + label:after {
        background: ${({ theme }) => theme.colors.secondary.blue};
    }
    + label:is(:hover, :focus):after {
        background-color: ${({ theme }) => theme.colors.secondary.blue200};
    }
    :not(:checked) + label:is(:hover, :focus):after {
        background-color: ${({ theme }) => theme.colors.secondary.blue};
    }
`;
const TOOLTIP_ID = "switch-tooltip";

interface Props {
    isActive: boolean;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const Switch = ({
    isActive,
    onToggle,
    disabled,
    tooltip,
    tooltipPosition,
}: PropsWithTooltip<Props>): JSX.Element => {
    // Properties

    const switchId = React.useMemo(() => Math.random().toString(36).substring(2), []);

    // Render

    return (
        <div>
            <Input
                id={switchId}
                disabled={disabled}
                checked={isActive}
                type={"checkbox"}
                onChange={onToggle}
                data-for={TOOLTIP_ID}
                data-tip={tooltip}
                data-iscapture="true"
            />
            <label htmlFor={switchId} />
            <ReactTooltip id={TOOLTIP_ID} effect={"solid"} place={tooltipPosition ?? "bottom"} />
        </div>
    );
};
