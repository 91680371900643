import { BaseViewModel } from "../BaseViewModel";
import { LocationInfoRepository, RunwayTrafficRepository, LocalPreferencesRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { AirBaseData, Runway, LocalUserPreferenceKeys } from "../../domain/model";
import { PlaybackState } from "../../domain/PlaybackScene";
import { ReplayRepository } from "../../domain/repositories/ReplayRepository";
import { nonNullObservable } from "../../utils/RxUtils";
import { RunwayTrafficViewMode } from "./RunwayTrafficBottomSheet";

export class RunwayTrafficBottomSheetViewModel extends BaseViewModel {
    // Properties

    public get shouldShowBottomSheet(): Rx.Observable<boolean> {
        return this.showRunwayTrafficBottomSheet;
    }

    public get mode(): Rx.Observable<RunwayTrafficViewMode> {
        return this.modeSubject.asObservable();
    }

    public get isLiveMode(): Rx.Observable<boolean> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
            RxOperators.map((state) => state == null || state === PlaybackState.STOPPED),
        );
    }

    public get hasRunwayCrossingData(): Rx.Observable<boolean> {
        return this.runwayTrafficRepository.hasRunwayCrossingData;
    }

    public get hasFunnelTrafficData(): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasFunnelTrafficData;
    }

    public get selectedRunway(): Rx.Observable<Runway> {
        return nonNullObservable(
            Rx.combineLatest([this.selectedRunwayId, this.locationData]).pipe(
                RxOperators.filter(([, locationData]) => locationData.runways.length > 0),
                RxOperators.map(([selectedRunwayId, locationData]) => {
                    if (selectedRunwayId) {
                        const selectedRunway = locationData.runways.find((r) => r.id === selectedRunwayId);
                        if (selectedRunway) {
                            return selectedRunway;
                        }
                    }
                    // Runway is not found, remove the preference and return the first runway
                    this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.charts.selectedRunwayId);
                    return locationData.runways[0];
                }),
            ),
        );
    }

    public get locationData(): Rx.Observable<AirBaseData> {
        return this.locationInfoRepository.airBaseData;
    }

    private get selectedRunwayId(): Rx.Observable<number | null> {
        return this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.charts.selectedRunwayId);
    }

    private get showRunwayTrafficBottomSheet(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(
                LocalUserPreferenceKeys.charts.showRunwayTrafficBottomSheet,
            ),
            true,
        );
    }

    private readonly modeSubject = new Rx.BehaviorSubject<RunwayTrafficViewMode>(RunwayTrafficViewMode.SIMPLE_VIEW);

    public constructor(
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
        this.collectSubscriptions(this.handleOpenBottomSheetInReplay());
    }

    // Public functions

    public setSelectedRunway(runway: Runway): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.charts.selectedRunwayId, runway.id);
    }

    public toggleMode(): void {
        switch (this.modeSubject.value) {
            case RunwayTrafficViewMode.SIMPLE_VIEW:
                this.modeSubject.next(RunwayTrafficViewMode.DETAILED_VIEW);
                break;
            case RunwayTrafficViewMode.DETAILED_VIEW:
                this.modeSubject.next(RunwayTrafficViewMode.SIMPLE_VIEW);
                break;
        }
    }

    // If the user requests the runway traffic sheet during replay, switch to detailed funnel view
    private handleOpenBottomSheetInReplay(): Rx.Subscription {
        return this.showRunwayTrafficBottomSheet
            .pipe(
                RxOperators.filter((showRunwayTrafficBottomSheet) => showRunwayTrafficBottomSheet),
                RxOperators.withLatestFrom(this.isLiveMode, this.mode),
            )
            .subscribe({
                next: ([, isLiveMode, mode]) => {
                    if (!isLiveMode && mode === RunwayTrafficViewMode.SIMPLE_VIEW) {
                        this.toggleMode();
                    }
                },
            });
    }
}
