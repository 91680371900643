import React from "react";
import ReactSelect, { components } from "react-select";
import styled, { useTheme } from "styled-components";
import { ArrowDirection, generateArrowIcon } from "./Arrow";
import { OldColors } from "./Colors";

export const StyledSelect = styled(ReactSelect)`
    width: 100%;
`;

export interface SelectItem {
    value: string;
    label: string;
}

export interface GroupedSelectItem {
    label: string | null;
    options: Array<SelectItem>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any): JSX.Element => {
    const theme = useTheme();
    return (
        <components.DropdownIndicator {...props}>
            {generateArrowIcon({
                direction: props.selectProps.menuIsOpen ? ArrowDirection.UP : ArrowDirection.DOWN,
                color: theme.colors.text.text,
            })}
        </components.DropdownIndicator>
    );
};

interface Props {
    groupedItems: GroupedSelectItem[];
    onItemSelected?: (item?: SelectItem) => void;
    placeholder?: string;
    initialValue?: SelectItem;
    isSearchable?: boolean;
}

export const Select = ({
    groupedItems,
    initialValue,
    placeholder,
    onItemSelected,
    isSearchable,
}: Props): JSX.Element => {
    const borderRadius = "6px";
    const theme = useTheme();
    return (
        <StyledSelect
            options={groupedItems}
            className={"select"}
            placeholder={placeholder}
            isSearchable={isSearchable || false}
            onChange={(item: SelectItem) =>
                item && onItemSelected && onItemSelected({ value: item.value, label: item.label })
            }
            value={initialValue}
            styles={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                groupHeading: (base: unknown) => ({
                    ...(base as Object),
                    paddingTop: "3px",
                    paddingBottom: "2px",
                    color: OldColors.whiteInactive,
                    background: OldColors.backgroundPrimaryDark,
                    fontSize: "14px",
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                group: (base: unknown) => ({
                    ...(base as Object),
                    height: "100%",
                    background: OldColors.backgroundPrimaryDark,
                    padding: "1px",
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                option: (base: unknown, state: any) => ({
                    ...(base as Object),
                    minHeight: "38px",
                    cursor: "pointer",
                    color: theme.colors.text.text,
                    fontSize: "14px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    background: state.isFocused ? OldColors.primaryTint : OldColors.black,
                    borderBottom: "none",
                    ":not(:last-child)": {
                        borderBottom: `1px solid ${OldColors.backgroundPrimaryDark}`,
                    },
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                control: (base: unknown, state: any) => ({
                    ...(base as Object),
                    height: "40px",
                    boxShadow: "none",
                    borderRadius: state.menuIsOpen ? `${borderRadius} ${borderRadius} 0 0` : borderRadius,
                    padding: "0px 10px",
                    backgroundColor: OldColors.black,
                    // color doesn't if i used the named color defined in theme.ts
                    border: `1px solid ${OldColors.primaryTint}`,
                    borderBottom: `1px solid ${state.menuIsOpen ? OldColors.primaryTint : OldColors.primaryTint}`,
                    cursor: "pointer",
                    "&:hover": {},
                }),
                singleValue: (base: unknown) => ({
                    ...(base as Object),
                    color: OldColors.primaryTint,
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: "pointer",
                }),
                placeholder: (base: unknown) => ({
                    ...(base as Object),
                    color: OldColors.primaryHoverTint,
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                }),
                menuList: (base: unknown) => ({
                    ...(base as Object),
                    padding: "0px",
                    maxHeight: "250px",
                    width: "100%",
                    borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
                }),
                menu: (base: unknown) => ({
                    ...(base as Object),
                    margin: "0",
                    width: "100%",
                    borderColor: OldColors.primaryTint,
                    borderWidth: "1px",
                    borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
                    borderStyle: "solid",
                }),
                indicatorSeparator: () => ({}),
                dropdownIndicator: (base: unknown) => ({
                    ...(base as Object),
                    height: "100%",
                    opacity: "0.5",
                    alignItems: "center",
                    cursor: "pointer",
                }),
                noOptionsMessage: (base: unknown) => ({
                    ...(base as Object),
                    backgroundColor: OldColors.black,
                    color: theme.colors.text.text,
                }),
                input: (base: unknown) => ({
                    ...(base as Object),
                    color: theme.colors.text.text,
                }),
            }}
            components={{ DropdownIndicator }}
        />
    );
};
