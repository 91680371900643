import { Colors } from "../../appearance/Colors";

const ANIMATION_DURATION_MILLIS = 1000;

export class AlarmTrackImage {
    // Properties
    public width = this.radius * 2;
    public height = this.radius * 2;
    public data: Uint8Array | Uint8ClampedArray = new Uint8ClampedArray(this.width * this.height * 4);

    private context: CanvasRenderingContext2D | null = null;
    private cx = this.width / 2;
    private cy = this.height / 2;
    private color = Colors.status.danger;

    public constructor(private map: mapboxgl.Map, private readonly radius: int, color?: string) {
        if (color) {
            this.color = color;
        }
    }

    // Public functions

    public onAdd(): void {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d", { willReadFrequently: true });
    }

    // Called once before every frame where the icon will be used
    public render(): boolean {
        const ctx = this.context;
        if (ctx == null) {
            return false;
        }

        const t = (performance.now() % ANIMATION_DURATION_MILLIS) / ANIMATION_DURATION_MILLIS;

        const r = this.radius * t;

        // Draw outer circle
        ctx.clearRect(0, 0, this.width, this.height);
        ctx.beginPath();
        ctx.arc(this.cx, this.cy, r, 0, Math.PI * 2);
        ctx.globalAlpha = 1 - t;
        ctx.fillStyle = this.color;
        ctx.fill();

        this.data = ctx.getImageData(0, 0, this.width, this.height).data;

        // For a smooth animation
        this.map.triggerRepaint();

        // Return `true` to let the map know that the image was updated
        return true;
    }
}
