import { Position } from "geojson";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { ADSBFlightsSnapshotDiffCalculator } from "../../../../domain/ADSBFlightsSnapshotDiffCalculator";
import { GeolocationPosition, LocalUserPreferenceKeys, Trackable, DistanceUnit } from "../../../../domain/model";
import { BaseEstimate } from "../../../../domain/model/BaseEstimate";
import {
    LocalPreferencesRepository,
    RadarRepository,
    TrackRepository,
    UserLocationRepository,
    ADSBFlightRepository,
} from "../../../../domain/repositories";
import { TrackableSnapshotDiff } from "../../../../domain/SnapshotDiffCalculator";
import { TracksSnapshotDiffCalculator } from "../../../../domain/TracksSnapshotDiffCalculator";
import { cleanupRadarsByDistance } from "../../../../utils/CleanUpRadarsByDistance";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { MapModuleViewModel } from "../MapModuleViewModel";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { ADSBUtils } from "../../../../utils/ADSBUtils";

export interface MeasurementsData {
    trackPosition: Position;
    radarPositions: Position[];
    userPosition?: Position;
    formatDistance: (meters: number) => string;
}

export class MeasurementsModuleViewModel extends MapModuleViewModel {
    public get shouldShowMeasurements(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.appearance.showTrackMeasurements),
            false,
        );
    }

    public get measurementsData(): Rx.Observable<MeasurementsData | null> {
        return Rx.combineLatest([
            this.selectedTrackPosition,
            this.selectedADSBFlightPosition,
            this.radarPositions,
            this.userPosition,
            this.distanceFormatter.selectedDistanceUnitObservable,
        ]).pipe(
            RxOperators.map(([selectedTrackPosition, selectedADSBFlightPosition, radarPositions, userPosition]) => {
                const trackPosition = selectedTrackPosition || selectedADSBFlightPosition;
                if (!trackPosition) {
                    return null;
                }
                return {
                    trackPosition,
                    radarPositions,
                    userPosition,
                    formatDistance: (meters) =>
                        this.distanceFormatter.formatValueWithCurrentUnit(meters, DistanceUnit.METRIC, {
                            simplifyLargeValues: true,
                        }),
                };
            }),
        );
    }

    private get selectedTrackPosition(): Rx.Observable<Position | undefined> {
        return this.getTrackPositionObservable(
            this.trackRepository.selectedTrackId,
            this.tracksSnapshotDiffCalculator.snapshotDiff,
            (track, timestamp) => track.getClosestEstimateTo(timestamp),
        );
    }

    private get selectedADSBFlightPosition(): Rx.Observable<Position | undefined> {
        if (!this.adsbFlightRepository || !this.adsbFlightsSnapshotDiffCalculator) {
            return Rx.of(undefined);
        }
        return this.getTrackPositionObservable(
            this.adsbFlightRepository.selectedFlightId,
            this.adsbFlightsSnapshotDiffCalculator.snapshotDiff,
            (track, timestamp) => ADSBUtils.getClosestEstimateTo(track, timestamp),
        );
    }

    private get radarPositions(): Rx.Observable<Position[]> {
        return this.radarRepository.radars.pipe(
            RxOperators.map((radars) => cleanupRadarsByDistance(radars)),
            RxOperators.map((radars) => radars.map((r) => r.position.toGeoJSONLocation())),
        );
    }

    private get userPosition(): Rx.Observable<Position | undefined> {
        return this.userLocationRepository.userLocationPosition.pipe(
            RxOperators.startWith(undefined),
            RxOperators.map((userLocationPosition) => this.getUserPosition(userLocationPosition)),
        );
    }

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly trackRepository: TrackRepository,
        private readonly tracksSnapshotDiffCalculator: TracksSnapshotDiffCalculator,
        private readonly radarRepository: RadarRepository,
        private readonly userLocationRepository: UserLocationRepository,
        private readonly distanceFormatter: DistanceFormatter,
        private readonly adsbFlightRepository?: ADSBFlightRepository,
        private readonly adsbFlightsSnapshotDiffCalculator?: ADSBFlightsSnapshotDiffCalculator,
    ) {
        super();
    }

    private getTrackPositionObservable<TrackType extends Trackable, EstimateType extends BaseEstimate>(
        selectedTrackIdObservable: Rx.Observable<number | null>,
        snapshotDiffObservable: Rx.Observable<TrackableSnapshotDiff<TrackType>>,
        getEstimateForTrack: (track: TrackType, timestamp: number) => EstimateType | null,
    ): Rx.Observable<Position | undefined> {
        return selectedTrackIdObservable.pipe(
            RxOperators.switchMap((selectedTrackId) => {
                if (selectedTrackId == null) {
                    return Rx.of(undefined);
                }
                return snapshotDiffObservable.pipe(
                    RxOperators.map((snapshotDiff) =>
                        this.getTrackPosition(selectedTrackId, snapshotDiff, getEstimateForTrack),
                    ),
                );
            }),
        );
    }

    private getTrackPosition<TrackType extends Trackable, EstimateType extends BaseEstimate>(
        selectedTrackId: number | null,
        tracksSnapshotDiff: TrackableSnapshotDiff<TrackType> | null,
        getEstimateForTrack: (track: TrackType, timestamp: number) => EstimateType | null,
    ): Position | undefined {
        if (!tracksSnapshotDiff || !selectedTrackId) {
            return undefined;
        }
        const liveTrack = tracksSnapshotDiff.snapshotTracksWithEstimates.find((t) => t.id === selectedTrackId);
        const track = liveTrack ? liveTrack : tracksSnapshotDiff.finishedTracks.find((t) => t.id === selectedTrackId);
        if (!track) {
            return undefined;
        }
        const timestamp = liveTrack
            ? tracksSnapshotDiff.snapshotTimestamp
            : tracksSnapshotDiff.finishedTracksDeathTimes.get(selectedTrackId)!;
        const estimate = getEstimateForTrack(track, timestamp);
        if (!estimate) {
            return undefined;
        }
        return estimate.location.toGeoJSONLocation();
    }

    private getUserPosition(userLocationPosition?: GeolocationPosition): Position | undefined {
        if (!userLocationPosition) {
            return undefined;
        }
        const position = [userLocationPosition.coords.longitude, userLocationPosition.coords.latitude];
        if (userLocationPosition.coords.altitude) {
            position.push(userLocationPosition.coords.altitude);
        }
        return position;
    }
}
