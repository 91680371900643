import * as mapboxgl from "mapbox-gl";
import * as MapUtils from "../../../utils/MapUtils";
import { Feature, Position } from "geojson";
import RadarIcon from "../../../res/images/radar_outlined.svg";
import RadarHeadingIcon from "../../../res/images/compass.svg";
import { RadarUpdate } from "../modules/radars/RadarsModuleViewModel";

const RADARS_LAYER_ID = "layer-radars";
const RADARS_HEADING_LAYER_ID = "layer-radars-heading";
const RADARS_SOURCE_ID = "source-radars";
const RADARS_SYMBOL_ID = "symbol-radars";
const RADARS_HEADING_SYMBOL_ID = "symbol-radars-heading";

export class RadarsLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): RadarsLayer {
        return new RadarsLayer(map, orderLayer);
    }

    // Properties

    private constructor(private readonly map: mapboxgl.Map, private orderLayer: string) {
        this.setup();
    }

    // Public functions

    public setRadarUpdates(updates: RadarUpdate[]): void {
        const source = this.map.getSource(RADARS_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }

        source.setData({
            type: "FeatureCollection",
            features: updates.map((update) => this.getFeatureFromRadar(update.position, update.motionData?.headingDeg)),
        });
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(RADARS_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(RADARS_HEADING_LAYER_ID, "visibility", visibility);
    }

    // Private functions

    private setup(): void {
        this.addRadarLayer();
    }

    private addRadarLayer(): void {
        this.map.addSource(RADARS_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        // Radar icon layer
        MapUtils.loadImageFromSVG(RadarIcon, (image) => this.map.addImage(RADARS_SYMBOL_ID, image));
        this.map.addLayer(
            {
                id: RADARS_LAYER_ID,
                type: "symbol",
                source: RADARS_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                layout: {
                    "icon-size": {
                        stops: [
                            [0, 0.1],
                            [10, 0.4],
                            [16, 1],
                        ],
                    },
                    "icon-allow-overlap": true,
                    "icon-image": RADARS_SYMBOL_ID,
                    "icon-pitch-alignment": "map",
                },
                paint: {},
            },
            this.orderLayer,
        );

        // Radar heading icon layer
        MapUtils.loadImageFromSVG(RadarHeadingIcon, (image) => this.map.addImage(RADARS_HEADING_SYMBOL_ID, image));
        this.map.addLayer(
            {
                id: RADARS_HEADING_LAYER_ID,
                type: "symbol",
                source: RADARS_SOURCE_ID,
                filter: ["has", "heading"],
                layout: {
                    "icon-size": {
                        stops: [
                            [0, 0.1],
                            [10, 0.4],
                            [16, 1],
                        ],
                    },
                    "icon-offset": [0, -22],
                    "icon-rotate": ["get", "heading"],
                    "icon-rotation-alignment": "map",
                    "icon-allow-overlap": true,
                    "icon-image": RADARS_HEADING_SYMBOL_ID,
                    "icon-pitch-alignment": "map",
                },
                paint: {},
            },
            this.orderLayer,
        );
    }

    private getFeatureFromRadar(position: Position, headingDeg?: number): Feature {
        return {
            type: "Feature",
            properties: {
                heading: headingDeg,
            },
            geometry: {
                type: "Point",
                coordinates: position,
            },
        };
    }
}
