import React from "react";
import { TYPES } from "../../../di/Types";
import { ToolbarIconButton } from "../../appearance/ToolbarIconButton";
import { useViewModel } from "../../../hooks/useViewModel";
import { useObservable } from "../../../hooks";
import { ToolbarButtonViewModel } from "./ToolbarButtonViewModel";
import { HomeUIState, SidebarUIState } from "../../../domain/model";
import { PositionOnScreen } from "../../../domain/repositories";
import { IconType } from "../../appearance/Icon";

interface Props {
    iconType: IconType;
    positionOnScreen?: PositionOnScreen;
    uiStateKey: keyof HomeUIState | keyof SidebarUIState;
}

export const ToolbarButton = ({ iconType, positionOnScreen, uiStateKey }: Props): JSX.Element => {
    // Properties

    const viewModel: ToolbarButtonViewModel = useViewModel(TYPES.ToolbarButtonViewModel);
    const isUIComponentVisible = useObservable<boolean>(viewModel.getIsUIComponentVisible(uiStateKey), false);

    // Render

    return (
        <ToolbarIconButton
            active={isUIComponentVisible}
            iconType={iconType}
            onClick={() => viewModel.toggleUIComponent(uiStateKey, { hideHomeComponentSet: positionOnScreen })}
        />
    );
};
