import { BaseViewModel } from "../../../../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SessionRepository, TrackRepository } from "../../../../../domain/repositories";
import { SessionState } from "../../../../../domain/model";
import { t } from "i18next";
import { nonNullObservable } from "../../../../../utils/RxUtils";

const TIME_INTERVAL_STEPS = [15, 20, 30, 60, 300, 600, 1800, 3600];
export const NO_UPDATES_NOTIFICATION_THRESHOLD_SECONDS = 30;

export interface TimeDifferenceUpdate {
    difference: number;
    text: string;
}

export class TimeSyncStatusIndicatorViewModel extends BaseViewModel {
    // Properties

    private lastUpdateTime: long = 0;
    private lastUpdateTimeDifferenceSecondsSubject = new Rx.BehaviorSubject<long>(0);

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly sessionRepository: SessionRepository,
    ) {
        super();

        this.collectSubscriptions(
            this.subscribeLastUpdateTime(),
            Rx.interval(1000).subscribe(() => this.broadcastLastUpdateTimeDifference()),
        );
    }

    // Public functions

    public get lastUpdateTimeDifferenceText(): Rx.Observable<TimeDifferenceUpdate> {
        const textObservable = this.lastUpdateTimeDifferenceSecondsSubject.pipe(
            RxOperators.filter((diff) => diff <= 10 || TIME_INTERVAL_STEPS.includes(diff)),
            RxOperators.distinctUntilChanged(),
            RxOperators.map<number, TimeDifferenceUpdate>((diff) => ({
                difference: diff,
                text: this.getTimerIntervalText(diff),
            })),
            RxOperators.distinctUntilChanged(),
        );
        const emptyObservable = Rx.of({ difference: 0, text: "" });
        // Only show the text when the session is not logged out due to unauthorised response
        return this.sessionRepository.session.pipe(
            RxOperators.switchMap((session) =>
                session.state === SessionState.LoggingOutDueToUnauthorisedResponse ? emptyObservable : textObservable,
            ),
        );
    }

    // Private functions

    private getTimerIntervalText(diff: long): string {
        let diffText = diff > 10 ? `${t("status.moreThan")} ` : "";

        // Format the text
        if (diff < 60) {
            diffText += diff + ` ${t("unit.second_other")}`;
        } else if (diff === 60) {
            diffText += `1 ${t("unit.minute_one")}`;
        } else if (diff > 60 && diff < 3600) {
            diffText += diff / 60 + ` ${t("unit.minute_other")}`;
        } else if (diff === 3600) {
            diffText += `1 ${t("unit.hour_one")}`;
        } else {
            diffText += diff / 3600 + ` ${t("unit.hour_other")}`;
        }

        return diffText;
    }

    private subscribeLastUpdateTime(): Rx.Subscription {
        return nonNullObservable(this.trackRepository.tracksSnapshot).subscribe({
            next: () => this.setLastUpdateTime(),
            error: (error) => console.error(error),
        });
    }

    private setLastUpdateTime(): void {
        this.lastUpdateTime = Date.now();
        this.broadcastLastUpdateTimeDifference();
    }

    private broadcastLastUpdateTimeDifference(): void {
        const diff = Date.now() - this.lastUpdateTime;
        this.lastUpdateTimeDifferenceSecondsSubject.next(Math.floor(diff / 1000));
    }
}
