import React from "react";
import { SidebarSection } from "./Sidebar";
import { IconColors } from "../appearance/Icon";
import { Tab } from "../appearance/Tabbar";
import { t } from "i18next";

interface Props {
    hasActiveAlarms: boolean;
    isActive: boolean;
    isDynamicPositioningEnabled: boolean;
    section: SidebarSection;
    showSidebarSection: () => void;
}

export const SidebarTab = ({
    hasActiveAlarms,
    isActive,
    isDynamicPositioningEnabled,
    section,
    showSidebarSection,
}: Props): JSX.Element | null => {
    if (!isDynamicPositioningEnabled && section.key === "isVehicleInfoVisible") {
        return null;
    }

    let iconColor: IconColors = "disabled";
    if (hasActiveAlarms && section.key === "isEventListVisible") {
        iconColor = "danger";
    }

    return (
        <Tab
            {...section}
            active={isActive}
            iconColor={iconColor}
            iconType={section.iconType}
            label={t(section.labelTranslationKey)}
            onClick={() => showSidebarSection()}
            key={section.key}
        />
    );
};
