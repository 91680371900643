import React from "react";
import styled from "styled-components";
import { useViewModel } from "../../../../hooks/useViewModel";
import { TYPES } from "../../../../di/Types";
import { RadarStatusViewModel } from "./RadarStatusViewModel";
import { RadarStatusComponentList } from "./RadarStatusComponentList";
import { ImageQualityStatus } from "./ImageQualityStatus";
import { OperatingModeStatus } from "./OperatingModeStatus";
import { StatusContainer } from "./ComponentStatus";
import { RadarSensitivity } from "./RadarSensitivity";
import { useObservable } from "../../../../hooks";
import { PanelParagraph } from "../../../appearance/panels/PanelText";
import { t } from "i18next";
import { ScrollableSidebarContent } from "../../../appearance/Sidebar";
import { RadarStatusImageQuality } from "../../../../domain/model";

const StatusListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StatusValueContainer = styled(StatusContainer)`
    justify-content: space-between;
    label,
    strong {
        padding-left: 0;
    }
`;

export const RadarStatus = (): JSX.Element | null => {
    // Properties

    const viewModel: RadarStatusViewModel = useViewModel(TYPES.RadarStatusViewModel);
    const selectedRadar = useObservable(viewModel.selectedRadar, null);

    // Render

    if (!selectedRadar) {
        return <PanelParagraph>{t("radarStatus.noRadarSelected")}</PanelParagraph>;
    }

    if (!selectedRadar.status) {
        return <PanelParagraph>{t("radarStatus.noRadarStatusAvailable")}</PanelParagraph>;
    }

    return (
        <ScrollableSidebarContent>
            <StatusListContainer>
                <RadarStatusComponentList radarStatusList={selectedRadar.status.componentStates} />
                {selectedRadar.status.imageQualityState !== RadarStatusImageQuality.Unknown && (
                    <ImageQualityStatus status={selectedRadar.status.imageQualityState} />
                )}
                {selectedRadar.status.operatingMode && viewModel.hasMultipleOperatingModes && (
                    <OperatingModeStatus status={selectedRadar.status.operatingMode} />
                )}
            </StatusListContainer>
            <StatusListContainer>
                {selectedRadar.status.sensorStatus.entities.map((entity) => (
                    <StatusValueContainer key={entity.name}>
                        <label>{entity.name}</label>
                        <strong>{entity.value + " " + entity.units}</strong>
                    </StatusValueContainer>
                ))}
                {selectedRadar.status.sensitivity && (
                    <RadarSensitivity
                        onResetSensitivityRequest={() => viewModel.resetRadarSensitivity(selectedRadar.id)}
                        status={selectedRadar.status.sensitivity}
                    />
                )}
            </StatusListContainer>
        </ScrollableSidebarContent>
    );
};
