import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { ArrowDownIcon } from "../appearance/Arrow";
import { t } from "i18next";
import { BASE_TEXT_STYLE } from "./theme/GlobalStyles";

const DEFAULT_ANIMATION_DURATION_MILLIS = 250;

const HeaderLabel = styled.h4<{ hasChildren: boolean }>`
    ${BASE_TEXT_STYLE}
    text-transform: uppercase;
    font-size: 16px;
    margin: 0;
    cursor: pointer;
    padding: 16px 0px 10px 0px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    :hover {
        color: ${({ theme }) => theme.colors.secondary.white200};
    }
`;

const Arrow = styled.img<{ isOpen: boolean; hasChildren: boolean }>`
    opacity: 0.5;
    transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
    transition: transform ${DEFAULT_ANIMATION_DURATION_MILLIS}ms ease-out;
    ${({ hasChildren, theme }) =>
        hasChildren
            ? `
                margin-right: auto;
                margin-left: ${theme.spacing.x2};
            `
            : ""}
`;

interface Props {
    label: string;
    isOpen: boolean;
    onClick: (isOpen: boolean) => void;
}

export class SectionHeader extends React.Component<PropsWithChildren<Props>> {
    // Public functions
    public render(): React.ReactNode {
        return (
            <HeaderLabel hasChildren={!!this.props.children} onClick={() => this.props.onClick(this.props.isOpen)}>
                <span>{this.props.label}</span>
                <Arrow
                    hasChildren={!!this.props.children}
                    isOpen={this.props.isOpen}
                    src={ArrowDownIcon}
                    alt={t("general.toggleClickToExpandCollapse")}
                />
                {this.props.children}
            </HeaderLabel>
        );
    }
}
