import { ServerConfig as ServerConfigProto } from "./proto/generated/serverconfig3_pb";
import { ServerConfigJSON } from "./json";
import { AuthMethod } from "./AuthMethod";
import { UserManagementPermission } from "./UserPermission";
import { AppSockets } from "./AppSockets";
import { ServerURL } from "../../infrastructure/ServerURL";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";

export type SsoProvider = "GOOGLE" | "MICROSOFT_AZURE" | "MICROSOFT_ADFS" | undefined;
export type SsoVersion = number | undefined;

export interface ServerVersions {
    apiVersion: number;
    radarSoftwareVersion: string;
}

export const EMPTY_SERVER_VERSIONS: ServerVersions = {
    apiVersion: -1,
    radarSoftwareVersion: "-1",
};

export class ServerConfig {
    // Public functions

    public static fromProto(model: ServerConfigProto): ServerConfig {
        const authMethod = this.getAuthMethod(model.getAuthmethod());
        return new ServerConfig(
            authMethod,
            this.addProtocolToUrl(model.getAuthorizeurl()),
            model.getClientid(),
            model.getSsoprovider() as SsoProvider,
            this.convertSsoVersion(model.getSsoversion(), authMethod),
            model.getTokenissuer(),
            this.getUserManagementPermission(model.getUsermanagementpermission()),
        );
    }

    public static fromJson(model: ServerConfigJSON): ServerConfig {
        const authMethod = this.getAuthMethod(model.authMethod);
        return new ServerConfig(
            authMethod,
            this.addProtocolToUrl(model.authorizeUrl),
            model.clientId,
            model.ssoProvider,
            this.convertSsoVersion(model.ssoVersion, authMethod),
            model.tokenIssuer,
            this.getUserManagementPermission(model.userManagementPermission),
            model.websockets as AppSockets,
        );
    }

    /**
     * Returns a ServerVersions object from a ServerConfigProto object.
     * Returns -1 and "-1" if the api doesn't return api or software version respectively
     * or if the api returns `0` as a value for either version
     */
    public static serverVersionsfromProto(model: ServerConfigProto): ServerVersions {
        return {
            apiVersion: model.getApiversion() || EMPTY_SERVER_VERSIONS.apiVersion,
            radarSoftwareVersion: model.getSoftwareversion() || EMPTY_SERVER_VERSIONS.radarSoftwareVersion,
        };
    }

    /**
     * Returns a ServerVersions object from a ServerConfigJSON object.
     * Returns -1 and "-1" if the api doesn't return api or software version respectively
     * or if the api returns `0` as a value for either version
     */
    public static serverVersionsfromJson(model: ServerConfigJSON): ServerVersions {
        return {
            apiVersion: model.apiVersion || EMPTY_SERVER_VERSIONS.apiVersion,
            radarSoftwareVersion: model.softwareVersion || EMPTY_SERVER_VERSIONS.radarSoftwareVersion,
        };
    }

    public constructor(
        public readonly authMethod: AuthMethod = AuthMethod.BasicAuth as AuthMethod,
        public readonly authorizeUrl: string,
        public readonly clientId: string,
        public readonly ssoProvider: SsoProvider,
        public readonly ssoVersion: SsoVersion,
        public readonly tokenIssuer: string,
        public readonly userManagementPermission: UserManagementPermission,
        public readonly websockets?: AppSockets,
    ) {}

    // Private functions

    private static addProtocolToUrl(authorizeUrl: string): string {
        if (authorizeUrl && !authorizeUrl.startsWith("http")) {
            return DI.get<ServerURL>(TYPES.ServerURL).apiProtocol + authorizeUrl;
        }
        return authorizeUrl;
    }

    private static getAuthMethod(value?: string): AuthMethod {
        return AuthMethod[value as keyof typeof AuthMethod] ?? AuthMethod.BasicAuth;
    }

    private static getUserManagementPermission(value?: string): UserManagementPermission {
        return (
            UserManagementPermission[value as keyof typeof UserManagementPermission] ??
            UserManagementPermission.ReadWrite
        );
    }

    private static convertSsoVersion(versionString: string, authMethod: AuthMethod): SsoVersion {
        if (authMethod !== AuthMethod.OpenIdConnect) {
            return undefined;
        }
        const version = parseFloat(versionString);
        if (isNaN(version)) {
            // A warning is only necessary when using OpenIdConnect, because the version is not used for BasicAuth
            console.warn("SSO version error, assigning 1");
            return 1;
        }
        return version;
    }
}
