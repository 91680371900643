import React, { useEffect, useState } from "react";
import { LegendViewModel } from "./LegendViewModel";
import { TYPES } from "../../di/Types";
import { LegendEntrySection } from "./LegendEntrySection";
import { t } from "i18next";
import { useViewModel } from "../../hooks/useViewModel";
import { useObservable } from "../../hooks";
import { FormToggle } from "../appearance/forms/FormToggle";
import { Form, FormSection } from "../appearance/forms/Form";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { useUIState } from "../../hooks/useUIState";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelFooter, PanelFooterText } from "../appearance/panels/PanelFooter";
import { Panel } from "../appearance/panels/Panel";
import { SectionHeader } from "../appearance/SectionHeader";
import { LegendSection } from "./LegendSection";
import { Collapse } from "react-collapse";

export const Legend = (): JSX.Element => {
    // Properties

    const viewModel: LegendViewModel = useViewModel(TYPES.LegendViewModel);
    const { toggleVisibility } = useUIState("isLegendVisible");
    const hasReliableAltitudeInfo = useObservable(viewModel.hasReliableAltitudeInfo, false);
    const altitudeUnitName = useObservable(viewModel.altitudeUnitNameObservable, "");
    const allEntriesVisibleBySection = useObservable(viewModel.allSectionsEntriesVisible, {});
    const [sections, setSections] = useState<LegendSection[]>([]);

    useEffect(() => {
        const subscription = viewModel.getLegendSections().subscribe({
            next: (sections) => setSections(sections),
            error: (error) => console.error(error),
        });
        return () => subscription.unsubscribe();
    }, [viewModel]);

    // Render

    return (
        <Panel>
            <PanelHeader label={t("legend.legendAndInfo")} onClose={() => toggleVisibility({ isVisible: false })} />
            <PanelSection>
                <Form>
                    <LegendEntrySection legendEntries={viewModel.additionalLegendEntries} />
                    {sections.map((section) => (
                        <React.Fragment key={section.id}>
                            <SectionHeader
                                onClick={() => viewModel.setSectionOpen(section.id, !section.isOpen)}
                                isOpen={section.isOpen}
                                label={t(section.label)}
                            >
                                <FormToggle
                                    type="EYE"
                                    isActive={allEntriesVisibleBySection[section.id]}
                                    onToggle={() =>
                                        viewModel.setClassificationVisibility(
                                            section.id,
                                            !allEntriesVisibleBySection[section.id],
                                        )
                                    }
                                />
                            </SectionHeader>
                            <Collapse isOpened={section.isOpen}>
                                <FormSection>
                                    <LegendEntrySection legendEntries={section.entries} />
                                </FormSection>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </Form>
            </PanelSection>
            {hasReliableAltitudeInfo && (
                <PanelFooter>
                    <PanelFooterText showIcon={true} text={t("legend.altitudeInfo", { unit: altitudeUnitName })} />
                </PanelFooter>
            )}
        </Panel>
    );
};
