import { PlusMinusItemViewModel } from "../../SettingItemViewModel";
import RunwayCrossingThreholdIcon from "../../../../res/images/settings/runway_crossing_threshold.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { t } from "i18next";

export class RunwayCrossingThresholdSettingItemViewModel extends PlusMinusItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.alertThresholdRunway"),
            SettingItemIcon.withUrl(RunwayCrossingThreholdIcon),
            localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.charts.runwayCrossingsThreshold,
            ),
            [1, 100],
            1,
            (value) =>
                localPreferencesRepository.setPreference(
                    LocalUserPreferenceKeys.charts.runwayCrossingsThreshold,
                    value,
                ),
            (value) => value.toString(),
        );
    }
}
