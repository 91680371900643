import "./CenterToRadarControl.css";
import * as mapboxgl from "mapbox-gl";
import * as Rx from "rxjs";
import { t } from "i18next";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { MapViewViewModel } from "./MapViewViewModel";

const CONTAINER_CLASS_NAME = "mapboxgl-ctrl mapboxgl-ctrl-group";
const CLASS_NAME_BASE = "mapboxgl-ctrl-icon center-to-radar-control";
const CLASS_NAME_ACTIVE = CLASS_NAME_BASE + " center-to-radar-control-active";
const CLASS_NAME_BACKGROUND = CLASS_NAME_BASE + " center-to-radar-control-background";

enum TrackingState {
    ACTIVE = "center-to-radar-active",
    BACKGROUND = "center-to-radar-background",
}

interface CenterToRadarControlProps {
    onStartTracking: () => void;
    onStopTracking: () => void;
}

export class CenterToRadarControl implements mapboxgl.IControl {
    // Properties

    private map: mapboxgl.Map | undefined;
    private container: HTMLElement | undefined;
    private button: HTMLButtonElement | undefined;
    private trackingState = TrackingState.BACKGROUND;
    private trackingSubscription = new Rx.Subscription();
    private readonly viewModel: MapViewViewModel = DI.get(TYPES.MapViewViewModel);

    public constructor(readonly props: CenterToRadarControlProps) {}

    // Public functions

    public onAdd(map: mapboxgl.Map): HTMLElement {
        this.button = document.createElement("button");
        this.button.className = CLASS_NAME_BACKGROUND;
        this.button.title = t("map.centerToRadar");
        this.button.setAttribute("aria-label", t("map.centerToRadar"));
        this.button.addEventListener("click", () => this.handleClick());

        this.container = document.createElement("div");
        this.container.className = CONTAINER_CLASS_NAME;
        this.container.appendChild(this.button);

        this.map = map;

        if (this.viewModel.isDynamicPositioningEnabled) {
            // Disable default mapbox zooming and scrolling
            this.map.scrollZoom.disable();

            // Override default mapbox zooming and scrolling to keep radar as center of the screen on zoom
            this.map.getContainer().addEventListener("wheel", (event) => {
                event.preventDefault();
                event.stopPropagation();

                const zoomDirection = Math.sign(event.deltaY * -0.05);
                const zoomLevel = map.getZoom() + zoomDirection;
                const center = map.getCenter();
                map.easeTo({ zoom: zoomLevel, center });
            });
            // Disable the control if user drags the map
            this.map.on("dragstart", () => this.handleUserMapInteraction());
        } else {
            // If dynamic positioning is disabled both zooming and dragging will disable the control
            this.map.on("dragstart", () => this.handleUserMapInteraction());
            this.map.on("zoomstart", () => this.handleUserMapInteraction());
        }

        return this.container;
    }

    public onRemove(): void {
        if (this.container && this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
        }
        delete this.map;
    }

    // Private functions

    private startTracking(): void {
        this.trackingSubscription = new Rx.Subscription();
        this.props.onStartTracking();
    }

    private stopTracking(): void {
        this.props.onStopTracking();
        this.trackingSubscription.unsubscribe();
    }

    private handleClick(): void {
        this.setTrackingState(this.getNextTrackingState());
    }

    private getNextTrackingState(): TrackingState {
        switch (this.trackingState) {
            case TrackingState.ACTIVE:
                return TrackingState.BACKGROUND;
            case TrackingState.BACKGROUND:
                return TrackingState.ACTIVE;
        }
    }

    private setTrackingState(newTrackingState: TrackingState): void {
        this.trackingState = newTrackingState;
        switch (this.trackingState) {
            case TrackingState.ACTIVE:
                this.startTracking();
                break;
            case TrackingState.BACKGROUND:
                this.stopTracking();
                break;
        }
        this.updateButtonClassName();
    }

    private updateButtonClassName(): void {
        if (!this.button) {
            return;
        }
        switch (this.trackingState) {
            case TrackingState.ACTIVE:
                this.button.className = CLASS_NAME_ACTIVE;
                break;
            case TrackingState.BACKGROUND:
                this.button.className = CLASS_NAME_BACKGROUND;
                break;
        }
    }

    private handleUserMapInteraction(): void {
        if (this.trackingState === TrackingState.ACTIVE) {
            this.setTrackingState(TrackingState.BACKGROUND);
        }
    }
}
