import React from "react";
import { User } from "../../domain/model";
import { Button } from "../appearance/button/Button";
import { t } from "i18next";

interface UserProps {
    canEditUsers: boolean;
    onRequestEdit: (user: User) => void;
    user: User;
}

export const UserListItem = ({ canEditUsers, onRequestEdit, user }: UserProps): JSX.Element => {
    return (
        <tr key={user.id}>
            <td>{user.name}</td>
            <td>{user.username}</td>
            <td>{t(`userManagement.${user.role.name.toLowerCase()}`)}</td>
            <td>
                {canEditUsers && (
                    <Button label={t("general.edit")} layout="inline" onClick={() => onRequestEdit(user)} />
                )}
            </td>
        </tr>
    );
};
