import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { PrimitiveShape, DistanceUnit } from "../../../domain/model";
import { OverlayEditorRepository, EditMode, EditState } from "../../../domain/repositories";
import { BaseViewModel } from "../../BaseViewModel";
import { DistanceFormatter, DistanceFormatFunction } from "../../../domain/DistanceFormatter";
import { t } from "i18next";

export class OverlayEditorViewModel extends BaseViewModel {
    // Properties

    public readonly editState: Rx.Observable<EditState> = this.overlayEditorRepository.editState;

    public readonly widthUnit: Rx.Observable<string> = this.distanceFormatter.selectedDistanceUnitObservable.pipe(
        RxOperators.map((unit) => (unit === DistanceUnit.IMPERIAL ? t("unit.foot_other") : t("unit.meter_other"))),
    );

    public constructor(
        public readonly overlayEditorRepository: OverlayEditorRepository,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
    }

    // Public functions

    public setMode(editMode: EditMode): void {
        this.overlayEditorRepository.setMode(editMode, editMode === EditMode.SELECT ? [] : null);
    }

    public isolateOverlays(overlayNames: string[]): void {
        this.overlayEditorRepository.isolateOverlays(overlayNames);
    }

    public beginShape(): void {
        this.overlayEditorRepository.beginShape();
    }

    public updateActiveShape(update: PrimitiveShape): void {
        this.overlayEditorRepository.updateActiveShape(update);
    }

    public updateMinMaxAltitude(minAltitude?: number, maxAltitude?: number): void {
        this.overlayEditorRepository.updateMinMaxAltitude(minAltitude, maxAltitude);
    }

    public discardShape(): void {
        this.overlayEditorRepository.discardShape();
        this.setMode(EditMode.SELECT);
    }

    public endShape(overlay: string): void {
        const subscription = this.overlayEditorRepository.endShape(overlay).subscribe({
            complete: () => this.setMode(EditMode.SELECT),
        });
        this.collectSubscription(subscription);
    }

    public convertValueToMeters(text: string): number {
        const value = Math.max(parseFloat(text), 0);
        return this.convertNumericValueToMeters(value);
    }

    public convertNumericValueToMeters(value: number): number {
        return this.distanceFormatter.convertValueFromCurrentUnit(
            value,
            DistanceUnit.METRIC,
            DistanceFormatFunction.NONE,
        );
    }

    public getNumberInCurrentUnit(meters: number): number {
        return this.distanceFormatter.convertValueToCurrentUnit(meters, DistanceUnit.METRIC);
    }
}
