import styled from "styled-components";
import { OldColors } from "./Colors";
import { BASE_TEXT_STYLE } from "./theme/GlobalStyles";

/**
 * @todo should be replaced with new button component or cleaned up
 */
export const RadioSwitch = styled.span<{ active?: boolean; disabled?: boolean }>`
    ${BASE_TEXT_STYLE}
    padding: ${({ theme }) => theme.spacing.x2};
    min-height: 30px;
    line-height: 1;
    text-align: left;
    vertical-align: center;
    border-radius: ${({ theme }) => theme.spacing.x1};
    background-color: ${({ active, disabled, theme }) =>
        disabled
            ? ({ theme }) => theme.colors.status.disabled
            : active
            ? theme.colors.status.info
            : OldColors.primaryTint50};
    color: ${({ theme }) => theme.colors.text.text};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: ${({ active, disabled }) => (active || disabled ? "default" : "pointer")};

    :hover {
        background-color: ${({ active, disabled, theme }) =>
            disabled
                ? OldColors.disabledBackgroundColor
                : active
                ? theme.colors.status.info
                : OldColors.primaryHoverTint};
    }
`;

/**
 * @todo should be replaced with button group component or cleaned up
 */
export const RadioSwitchGroup = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.x5};

    ${RadioSwitch} {
        display: inline-block;
        margin-right: ${({ theme }) => theme.spacing.x2};
        ::last-child {
            margin-right: 0;
        }
    }
`;
