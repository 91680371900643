import { AlarmRepository, LocationInfoRepository, RunwayTrafficRepository, TrackRepository } from "..";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { nonNullObservable } from "../../../utils/RxUtils";
import { ReplayRepository } from "../ReplayRepository";
import { AlarmData, AlarmDataType } from "../../model";

const TEST_TIME_MILLISECONDS = 3000;

export class CommonAlarmRepository implements AlarmRepository {
    // Properties

    public activeRealtime: Rx.Observable<AlarmData[]>;
    public activeTest: Rx.Observable<boolean>;

    private activeTestSubject = new Rx.BehaviorSubject(false);
    private alarmBoxHoveringSubject = new Rx.BehaviorSubject<AlarmDataType | null>(null);

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly runwayTrafficRepository?: RunwayTrafficRepository,
    ) {
        this.activeRealtime = Rx.combineLatest([
            this.getTrackAlarms(),
            this.getRunwayCrossingsAlarms(),
            this.getFunnelAlarms(),
        ]).pipe(
            RxOperators.map(([trackAlarms, runwayCrossingsAlarm, funnelAlarms]) => {
                const alarms: AlarmData[] = [];
                trackAlarms && alarms.push(...trackAlarms);
                runwayCrossingsAlarm && alarms.push({ type: AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD });
                funnelAlarms && alarms.push(...funnelAlarms);
                return alarms;
            }),
            RxOperators.distinctUntilChanged(),
        );
        this.activeTest = this.activeTestSubject.asObservable();
    }

    public test(): void {
        Rx.interval(TEST_TIME_MILLISECONDS)
            .pipe(
                RxOperators.map(() => false),
                RxOperators.startWith(true),
                RxOperators.take(2),
            )
            .subscribe((value) => this.activeTestSubject.next(value));
    }

    public get alarmBoxHovering(): Rx.Observable<AlarmDataType | null> {
        return this.alarmBoxHoveringSubject.asObservable();
    }

    public setAlarmBoxHovering(value: AlarmDataType | null): void {
        return this.alarmBoxHoveringSubject.next(value);
    }

    /**
     * @returns Alarms for all tracks. If more than one track has an alarm, all of them will be returned
     */
    private getTrackAlarms(): Rx.Observable<AlarmData[]> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) =>
                scene == null ? nonNullObservable(this.trackRepository.tracksSnapshot) : scene.tracks,
            ),
            RxOperators.map((snapshot) =>
                Array.from(snapshot.tracks.values())
                    .map((track) => {
                        const e = track.getClosestEstimateTo(snapshot.timestamp);
                        const alarms =
                            e?.alarms.filter(
                                (a) => a.type === AlarmDataType.DRONE || a.type === AlarmDataType.AREA_ENTRY,
                            ) || [];
                        return alarms;
                    })
                    .filter((alarmsPerTrack) => alarmsPerTrack.length > 0)
                    .flat(),
            ),
        );
    }

    private getRunwayCrossingsAlarms(): Rx.Observable<boolean> {
        if (!this.runwayTrafficRepository) {
            return Rx.of(false);
        }

        const runwayCrossingsObservable = Rx.combineLatest([
            this.runwayTrafficRepository.crossingThreshold,
            this.runwayTrafficRepository.runwayCrossingCurrent,
        ]).pipe(
            RxOperators.map(([threshold, crossings]) => {
                const crossingValues = [...crossings.values()].map((crossing) => crossing.count);
                return crossingValues.some((value) => value >= threshold);
            }),
            RxOperators.distinctUntilChanged(),
        );

        // Only show runway crossing alarms if there are runways
        return this.locationInfoRepository.hasRunways.pipe(
            RxOperators.switchMap((hasRunways) => (hasRunways ? runwayCrossingsObservable : Rx.of(false))),
        );
    }

    private getFunnelAlarms(): Rx.Observable<AlarmData[]> {
        if (!this.runwayTrafficRepository) {
            return Rx.of([]);
        }

        const runwayTrafficObservable = Rx.combineLatest([
            this.runwayTrafficRepository.funnelThreshold,
            this.runwayTrafficRepository.observeRunwayTraffic(),
        ]).pipe(
            RxOperators.map(([threshold, traffics]) => {
                // Get all track ids that are in a sector with traffic rate above threshold
                const alarmingTrackIds: int[] = traffics.flatMap((traffic) =>
                    traffic.sectorTrafficRate.flatMap((rate, index) =>
                        rate >= threshold ? traffic.sectorMessages[index].trackIds : [],
                    ),
                );
                // De-duplicate track ids
                return [...new Set(alarmingTrackIds)];
            }),
            RxOperators.map((trackIds) => {
                const alarmData: AlarmData[] = [];
                if (trackIds.length > 0) {
                    alarmData.push({ type: AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD, trackIds });
                }
                return alarmData;
            }),
            RxOperators.distinctUntilChanged(),
        );

        // Only show funnel alarms if there are runways
        return this.locationInfoRepository.hasRunways.pipe(
            RxOperators.switchMap((hasRunways) => (hasRunways ? runwayTrafficObservable : Rx.of([]))),
        );
    }
}
