import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../../../BaseViewModel";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { getTimeDisplayMode, TimeDisplayMode } from "../../../../domain/model/TimeDisplayMode";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";

export class ClockViewModel extends BaseViewModel {
    // Properties

    public get timeDisplayMode(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository
                .observePreference<string>(LocalUserPreferenceKeys.appearance.timeDisplayMode)
                .pipe(RxOperators.map((value) => getTimeDisplayMode(value))),
        );
    }

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {
        super();
    }
}
