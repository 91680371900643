import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ACTIVE_LANGUAGE } from "./Translation";
import { LANGUAGE_RESOURCES, TranslationNode } from "./Language";

type resource = { [key: string]: TranslationNode };
function formatLanguageResources(): resource {
    const resources: resource = {
        [ACTIVE_LANGUAGE.code]: { translation: ACTIVE_LANGUAGE.translation },
    };
    if (ACTIVE_LANGUAGE.code !== "en-US") {
        resources["en-US"] = { translation: LANGUAGE_RESOURCES["en-US"].translation };
    }
    return resources;
}

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: process.env.NODE_ENV === "development",
        fallbackLng: "en-US",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        lng: ACTIVE_LANGUAGE.code,
        resources: formatLanguageResources(),
    });

export default i18n;
