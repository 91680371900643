import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { LocalPreferencesRepository, TrackRepository } from "../../domain/repositories";
import { LocalUserPreferenceKeys, Track } from "../../domain/model";
import { nonNullObservable } from "../../utils/RxUtils";
import { TrackListEntryViewModel, TrackListEntryViewModelUtils } from "./TrackListEntryViewModel";
import { TracksSnapshotDiffCalculator } from "../../domain/TracksSnapshotDiffCalculator";

export class TrackListViewModel extends BaseViewModel {
    // Properties

    public get tracklistEntries(): Rx.Observable<{
        live: TrackListEntryViewModel[];
        finished: TrackListEntryViewModel[];
    }> {
        return Rx.combineLatest([this.snapshotDiffCalculator.snapshotDiff, this.trackRepository.selectedTrackId]).pipe(
            RxOperators.map(([diff, selectedTrackId]) => ({
                live: diff.snapshotTracksWithEstimates
                    .map(this.viewModelForTrack)
                    .map((vm) => ({ ...vm, isSelected: vm.id === selectedTrackId }))
                    .sort(TrackListEntryViewModelUtils.sort),
                finished: diff.finishedTracks
                    .map(this.viewModelForTrack)
                    .map((vm) => ({ ...vm, isSelected: vm.id === selectedTrackId }))
                    .sort(TrackListEntryViewModelUtils.sort),
            })),
        );
    }

    public get finishedTracksEnabled(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository
                .observePreference<number>(LocalUserPreferenceKeys.appearance.finishedTrackLifetime)
                .pipe(RxOperators.map((lifetime) => lifetime != null && lifetime > 0)),
            true,
        );
    }

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly snapshotDiffCalculator: TracksSnapshotDiffCalculator,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
    }

    // Public functions

    public toggleTrackSelection(trackId: number): void {
        this.trackRepository.toggleSelectedTrackId(trackId);
    }

    // Private functions

    private viewModelForTrack: (track: Track) => TrackListEntryViewModel = (track) => {
        const estimate = track.getClosestEstimateTo(Date.now());
        if (!estimate) {
            return { id: track.id };
        }
        return {
            id: track.id,
            isAlarm: estimate.alarms.length > 0,
            classification: estimate.classification ? estimate.classification : undefined,
        };
    };
}
