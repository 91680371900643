import * as Rx from "rxjs";
import { GroundObservation, Location } from "../model";
import { StartableRepository } from "./BaseRepository";

export enum GroundObservationMode {
    None,
    SingleGroundObservation,
}

export interface GroundObservationRepository extends StartableRepository {
    mode: Rx.Observable<GroundObservationMode>;
    selectedLocations: Rx.Observable<Location[]>;
    groundObservations: Rx.Observable<GroundObservation[]>;
    selectedGroundObservationIds: Rx.Observable<long[]>;
    shouldSuppressClickEvent: Rx.Observable<boolean>;

    setMode(mode: GroundObservationMode): void;
    selectLocation(location: Location): void;
    toggleGroundObservation(id: long): void;
    clearSelection(): void;
    addObservationForSelectedLocations(notes: string): Rx.Observable<void>;
    removeSelectedGroundObservations(): Rx.Observable<void>;
    updateSelectedGroundObservations(notes: string): Rx.Observable<void>;
    fetchGroundObservationsUpdatesOnce(): void;
    suppressClickEvent(timeout: int): void;
}
