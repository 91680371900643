import { createGlobalStyle } from "styled-components";
import { Colors } from "../Colors";
import "./../../../res/fonts/fonts.css";

export const BASE_TEXT_STYLE = `
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: ${Colors.text.text};
`;

const ENCODED_PRIMARY_TINT = encodeURIComponent(Colors.secondary.blue);

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    ::selection {
        background: ${({ theme }) => theme.colors.secondary.blue200};
        color: ${({ theme }) => theme.colors.text.text};
    }
    div, label, p, span {
        user-select: none;
    }
    html {
        height: 100%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }
    body {
        background-color: ${({ theme }) => theme.colors.backgrounds.page};
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: hidden;
        display: flex;
    }
    body, button, input, select, textarea {
        font-family: 'Roboto';
    }
    input, button, textarea, :focus {
      outline: none;
    }
    #root {
        display: flex;
        flex: 1;
        --dvh-unit: 1vh;
        @supports (height: 1dvh) {
            --dvh-unit: 1dvh;
        }
    }

    /* React collapse styling overrides */
    .ReactCollapse--collapse {
        transition: height 250ms;
    }

    /* Mapbox styling overrides */
    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='${ENCODED_PRIMARY_TINT}'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3Ccircle cx='10' cy='10' r='2'/%3E%3C/svg%3E") !important;
    }
    .mapboxgl-ctrl button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-background .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='${ENCODED_PRIMARY_TINT}'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3C/svg%3E") !important;
    }
    .mapboxgl-ctrl-bottom-left {
        transition: all 0.3s;
        position: fixed;
    }
    .mapboxgl-popup-content {
        width: max-content;
    }
    .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
        background: transparent;
    }
    body.replay-controls .mapboxgl-ctrl-bottom-left {
        translate: 0 -62px;
    }

    /* React tooltip styling overrides */
    .__react_component_tooltip {
        line-height: ${({ theme }) => theme.spacing.x6};
        &.show {
            opacity: 1 !important;
        }
    }
`;
