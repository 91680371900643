import { EMPTY_SERVER_VERSIONS, LocalUserPreferenceKeys, ServerConfig, ServerVersions } from "../model";
import * as Rx from "rxjs";
import { BaseRepository } from "./BaseRepository";
import { LocalPreferencesRepository } from "./LocalPreferencesRepository";
import { nonNullObservable } from "../../utils/RxUtils";

export const SERVER_CONFIG_TIMEOUT_MSEC = 15000;

export abstract class ServerConfigRepository implements BaseRepository {
    // Properties

    protected subscriptions = new Rx.Subscription();
    protected readonly configSubject = new Rx.BehaviorSubject<ServerConfig | null>(null);
    protected readonly serverVersionsSubject = new Rx.BehaviorSubject<ServerVersions>(EMPTY_SERVER_VERSIONS);
    protected readonly isConfigLoadedSubject = new Rx.BehaviorSubject<boolean>(false);
    protected readonly radarTimezoneOffsetFromUTCSubject = new Rx.BehaviorSubject<number | null>(null);

    public readonly config = nonNullObservable(this.configSubject.asObservable());
    public readonly serverVersions = this.serverVersionsSubject.asObservable();
    public readonly isConfigLoaded = this.isConfigLoadedSubject.asObservable();
    public readonly radarTimezoneOffsetFromUTC = this.radarTimezoneOffsetFromUTCSubject.asObservable();

    constructor(protected readonly localPreferencesRepository: LocalPreferencesRepository) {}

    // Public functions

    /**
     * Emit new api version name through the serverVersionsSubject
     */
    public setRadarSoftwareVersionName(apiVersionName: string): void {
        const newVersions: ServerVersions = {
            apiVersion: this.serverVersionsSubject.value.apiVersion,
            radarSoftwareVersion: apiVersionName,
        };
        this.serverVersionsSubject.next(newVersions);

        // To support older backend versions (pre 32304000) we need to store the api version name in local preferences
        // This should be deprecated in the future
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.version.apiVersionName, apiVersionName);
    }
}
