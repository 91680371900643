import React from "react";
import { Box, Flex, Heading, Theme } from "@radix-ui/themes";
import { ButtonGroup } from "../components/appearance/button/ButtonGroup";
import { Button } from "../components/appearance/button/Button";
import { showError, showInfo, showErrorWithOptions, showWarning } from "../utils/MessageUtils";
import { DevRedirectHoC } from "../components/utils/DevRedirectHoC";

const DevPlayground: React.VFC = () => {
    return (
        <Theme
            appearance={"dark"}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1002,
                background: "white",
            }}
            panelBackground={"solid"}
            accentColor={"blue"}
        >
            <Box
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 10021,
                }}
            >
                <Flex direction={"column"} height={"100%"} justify={"center"} align={"center"}>
                    <Box>
                        <Heading>New Notification</Heading>
                        <ButtonGroup>
                            <Button
                                label={"Show Error"}
                                onClick={() => {
                                    showError({ message: "|| Show Error", name: "| error" });
                                }}
                            />
                            <Button
                                label={"Show Info"}
                                onClick={() => {
                                    showInfo({ message: "Show Info" });
                                }}
                            />
                            <Button
                                label={"Show Info with button"}
                                onClick={() => {
                                    showInfo({
                                        message: "Show Info",
                                        showCloseButton: true,
                                        onClick: () => {
                                            alert(
                                                "clicked, Automatically would be removed after click. Also neat thing is if you forget to provide label with onClick, it will throw typescript error.",
                                            );
                                        },
                                        label: "learn more",
                                    });
                                }}
                            />
                            <Button
                                label={"Show Info with Close button"}
                                onClick={() => {
                                    showInfo({
                                        title: "I can have title",
                                        message: "Info with close button",
                                        showCloseButton: true,
                                        duration: Infinity,
                                    });
                                }}
                            />
                            <Button
                                label={"Show Warning"}
                                onClick={() => {
                                    showWarning({ message: "Show Warning" });
                                }}
                            />

                            <Button
                                label={"Show Error With Options (Same as other errors)"}
                                onClick={() =>
                                    showErrorWithOptions({
                                        message: "Show Error With Options",
                                    })
                                }
                            />
                        </ButtonGroup>
                    </Box>
                </Flex>
            </Box>
        </Theme>
    );
};

export const Playground = DevRedirectHoC(DevPlayground);
