import React from "react";
import styled from "styled-components";
import { TYPES } from "../../di/Types";
import { AltitudeSliderGraph } from "./AltitudeSliderGraph";
import { AltitudeSliderViewModel } from "./AltitudeSliderViewModel";
import { t } from "i18next";
import { Panel } from "../appearance/panels/Panel";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { Button } from "../appearance/button/Button";
import { FormControl } from "../appearance/forms/FormControl";
import { FormMinMax } from "../appearance/forms/FormMinMax";
import { Form } from "../appearance/forms/Form";
import { PanelFooter } from "../appearance/panels/PanelFooter";
import { useViewModel } from "../../hooks/useViewModel";
import { useObservable } from "../../hooks";
import { getScreenOrientation } from "../../utils/ScreenUtils";

const ThinPanel = styled(Panel)`
    width: 200px;
`;

const GraphContainer = styled.div<{ isVertical: boolean }>`
    // 400px = 2x 20px margin, 102px header, 62px footer, 2x 20px body padding, 2x 20px flex gap, 85px form min max, 30px form clear button
    height: calc(100vh - 450px);
    width: 130px;

    // if screen orientation is vertical make graph container smaller
    ${({ isVertical }) => isVertical && `height: calc(100vh - 450px - 270px);`}

    // remove effect of scrolliing the background when dragging the handles on mobile devices
    touch-action: none;
`;

interface Props {
    isAltitudeFilterSettingsVisible: boolean;
    onClose: () => void;
    onToggleSettingsPanel: () => void;
}

export const AltitudeSlider = ({
    isAltitudeFilterSettingsVisible,
    onClose,
    onToggleSettingsPanel,
}: Props): JSX.Element => {
    // Properties

    const viewModel: AltitudeSliderViewModel = useViewModel(TYPES.AltitudeSliderViewModel);

    const lowerBoundaryMetric = useObservable(viewModel.lowerBoundaryMetric, 0);
    const lowerBoundaryInCurrentUnits = useObservable(viewModel.lowerBoundaryInCurrentUnits, 0);
    const upperBoundaryMetric = useObservable(viewModel.upperBoundaryMetric, 1);
    const upperBoundaryInCurrentUnits = useObservable(viewModel.upperBoundaryInCurrentUnits, 1);
    const stepSize = useObservable(viewModel.stepSize, 1);
    const stepSizeMetric = useObservable(viewModel.stepSizeMetric, 1);

    const userDefinedMinMetric = useObservable(viewModel.userDefinedMinMetric, 0);
    const userDefinedMinInCurrentUnits = useObservable(viewModel.userDefinedMinInCurrentUnits, 0);
    const userDefinedMaxMetric = useObservable(viewModel.userDefinedMaxMetric, 0);
    const userDefinedMaxInCurrentUnits = useObservable(viewModel.userDefinedMaxInCurrentUnits, 1);
    const handleLabels = useObservable(viewModel.getHandleLabels(), ["", ""]);

    const altitudeGuides = useObservable(viewModel.getAltitudeGuides(), []);
    const altitudeToTrackDensity = useObservable(viewModel.altitudeToTrackDensity, new Map<number, number>());
    const aircraftAltitudes = useObservable(viewModel.aircraftAltitudes, []);
    const clearButtonIsDisabled =
        lowerBoundaryMetric === userDefinedMinMetric && upperBoundaryMetric === userDefinedMaxMetric;
    const isVertical = getScreenOrientation() === "portrait";

    // Local functions

    /**
     * Clears the altitude filter
     */
    const clearSelection = (): void => {
        viewModel.setMaxAltitudeOfInterestMetric(null);
        viewModel.setMinAltitudeOfInterestMetric(null);
    };

    // Render

    return (
        <ThinPanel>
            <PanelHeader label={t("general.altitude")} onClose={() => onClose()} />
            <PanelSection>
                <Form vertical>
                    <GraphContainer isVertical={isVertical}>
                        <AltitudeSliderGraph
                            shouldShowGuideline={viewModel.shouldShowGuideline}
                            trackDensityIndicatorsData={viewModel.getTrackDensityIndicatorsData(
                                altitudeToTrackDensity,
                                stepSizeMetric,
                            )}
                            aircraftAltitudes={aircraftAltitudes}
                            minAltitudeOfInterest={userDefinedMinMetric}
                            maxAltitudeOfInterest={userDefinedMaxMetric}
                            lowerBoundary={lowerBoundaryMetric}
                            upperBoundary={upperBoundaryMetric}
                            altitudeStepSize={stepSizeMetric}
                            altitudeGuides={altitudeGuides}
                            topHandleLabel={handleLabels[0]}
                            bottomHandleLabel={handleLabels[1]}
                            onAltitudeRangeChange={(altitude, handleType) =>
                                viewModel.validateMinOrMaxAltitudeOfInterest(altitude, handleType)
                            }
                        />
                    </GraphContainer>
                    <FormControl title={t("altitudeFilter.setAltitudeRange")} vertical>
                        <FormMinMax
                            lowerBoundary={lowerBoundaryInCurrentUnits}
                            max={userDefinedMaxInCurrentUnits}
                            min={userDefinedMinInCurrentUnits}
                            onChange={(min, max) => viewModel.setAltitudeRangeFromCurrentUnitValues(min, max)}
                            step={stepSize}
                            upperBoundary={upperBoundaryInCurrentUnits}
                        />
                    </FormControl>
                    <FormControl vertical>
                        <Button
                            disabled={clearButtonIsDisabled}
                            label={t("altitudeFilter.clearFilter")}
                            layout="inline"
                            onClick={clearSelection.bind(this)}
                        />
                    </FormControl>
                </Form>
            </PanelSection>
            <PanelFooter>
                <Button
                    label={t("general.settings")}
                    layout={isAltitudeFilterSettingsVisible ? "inline-fill" : "inline"}
                    onClick={() => onToggleSettingsPanel()}
                />
            </PanelFooter>
        </ThinPanel>
    );
};
