import React from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import styled from "styled-components";

const TooltipWrapper = styled.div`
    [data-id="tooltip"] {
        padding: 10px;
    }
`;

export type PropsWithTooltip<P> = P & { tooltip?: string; tooltipPosition?: "top" | "bottom" | "left" | "right" };

export default function Tooltip(params: TooltipProps): React.ReactElement {
    return (
        <TooltipWrapper>
            <ReactTooltip {...params} />
        </TooltipWrapper>
    );
}
