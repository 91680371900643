import { InclinationJSON } from "./json";

export class Inclination {
    public static fromJson(model: InclinationJSON): Inclination {
        return new Inclination(model.pitch, model.roll, model.withinLimits);
    }

    public constructor(
        public readonly pitch: number,
        public readonly roll: number,
        public readonly withinLimits: boolean,
    ) {}
}
