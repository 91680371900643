import styled from "styled-components";
import React, { Component } from "react";
import { t } from "i18next";
import { OldColors } from "../../appearance/Colors";
import { ReactComponent as MinusIcon } from "../../../res/images/settings/minus.svg";
import { ReactComponent as PlusIcon } from "../../../res/images/settings/plus.svg";
import Tooltip from "../../appearance/Tooltip";

const SUPPORTED_SPEEDS = [1, 2, 4, 8, 16, 32];

const Container = styled.div`
    width: 94px;
    text-align: center;
    color: white;
    padding: 6px 16px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    transition: all 0.3s;
    border-radius: 4px;
    :hover {
        background-color: ${OldColors.primaryHoverTint};
    }
`;

interface State {
    speed: number;
}

interface Props {
    speed: number;
    onSpeedChange: (speed: number) => void;
}

enum ChangeSpeedCommand {
    increase,
    decrease,
}

const SpeedControlButton = styled.div`
    border-radius: 999px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.secondary.white};
    border: 2px solid ${({ theme }) => theme.colors.secondary.white};
    font-size: 20px;
    transition: all 0.2s;
    cursor: pointer;
    :hover {
        background-color: transparent;
        path {
            fill: ${({ theme }) => theme.colors.text.text};
        }
        circle {
            fill: transparent;
        }
    }
    path {
        transition: all 0.2s;
        fill: ${({ theme }) => theme.colors.secondary.blue};
    }
    circle {
        transition: all 0.2s;
        fill: ${({ theme }) => theme.colors.text.text};
    }
`;

const PLAYBACK_SPEED_TOOLTIP = "PLAYBACK_SPEED_TOOLTIP";

export class ReplaySpeedButton extends Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
    // Properties

    public constructor(props: Props) {
        super(props);
        this.state = {
            speed: props.speed,
        };
        this.handleSpeedChange = this.handleSpeedChange.bind(this);
    }

    // Public functions

    public render(): React.ReactNode {
        // Remove speed and onSpeedChange from props
        const { speed: _speed, onSpeedChange: _onSpeedChange, ...containerProps } = this.props;
        return (
            <>
                <Tooltip id={PLAYBACK_SPEED_TOOLTIP} effect={"solid"} multiline={true} />
                <Container {...containerProps} data-for={PLAYBACK_SPEED_TOOLTIP} data-tip={t("replay.playbackSpeed")}>
                    <SpeedControlButton onClick={() => this.handleSpeedChange(ChangeSpeedCommand.decrease)}>
                        <MinusIcon />
                    </SpeedControlButton>
                    {this.state.speed}x
                    <SpeedControlButton onClick={() => this.handleSpeedChange(ChangeSpeedCommand.increase)}>
                        <PlusIcon />
                    </SpeedControlButton>
                </Container>
            </>
        );
    }

    // Private functions
    private handleSpeedChange(cmd: ChangeSpeedCommand): void {
        const currentSpeedIndex = SUPPORTED_SPEEDS.indexOf(this.state.speed);
        switch (cmd) {
            case ChangeSpeedCommand.decrease:
                const prevSpeed = SUPPORTED_SPEEDS[Math.max(0, currentSpeedIndex - 1)];
                this.setState({ speed: prevSpeed });
                this.props.onSpeedChange(prevSpeed);
                break;
            default:
                const nextSpeed = SUPPORTED_SPEEDS[Math.min(currentSpeedIndex + 1, SUPPORTED_SPEEDS.length - 1)];
                this.setState({ speed: nextSpeed });
                this.props.onSpeedChange(nextSpeed);
                break;
        }
    }
}
