import { PlusMinusItemViewModel } from "../../SettingItemViewModel";
import FinishedTrackLifetimeIcon from "../../../../res/images/settings/finished_track_lifetime.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import * as RxOperators from "rxjs/operators";
import { t } from "i18next";

const STEPS = [0, 5000, 10000, 20000, 30000, 60000];

export class FinishedTrackLifetimeSettingItemViewModel extends PlusMinusItemViewModel {
    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.finishedTrackLifetime"),
            SettingItemIcon.withUrl(FinishedTrackLifetimeIcon),
            localPreferencesRepository
                .observePreference<number>(LocalUserPreferenceKeys.appearance.finishedTrackLifetime)
                .pipe(
                    RxOperators.tap((v) => {
                        if (!STEPS.includes(v!)) {
                            this.setValue(0);
                        }
                    }),
                    RxOperators.map((v) => Math.max(0, STEPS.indexOf(v || 0))),
                ),
            [0, STEPS.length - 1],
            1,
            (value) => this.setValue(value),
            (value) => STEPS[value] / 1000 + ` ${t("unit.second_shorthand")}`,
        );
    }

    // Private functions

    private setValue(value: number): void {
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.appearance.finishedTrackLifetime,
            STEPS[Math.round(value)],
        );
    }
}
