import { DAY } from "../utils/DateTimeUtils";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository } from "../domain/repositories";
import { DEFAULT_TIME_DISPLAY_MODE, getTimeDisplayMode, TimeDisplayMode } from "../domain/model/TimeDisplayMode";
import { LocalUserPreferenceKeys } from "../domain/model";
import { nonNullObservable } from "../utils/RxUtils";
import { ACTIVE_LANGUAGE } from "./localization";
import { t } from "i18next";

const DATE_TIME_POSTFIX_UTC = " UTC";

interface FormatOptions {
    timeDisplayMode?: TimeDisplayMode;
    postfixUTC?: boolean;
    timezoneOffset?: number | null; // Expected value is in milliseconds
}

export interface TimeFormatOptions extends FormatOptions {
    includeSeconds?: boolean;
}

export interface DateTimeFormatOptions extends FormatOptions {
    excludeDateToday?: boolean;
}

export type TimeFormatObservableOptions = Omit<TimeFormatOptions, "timeDisplayMode">;
export type DateTimeFormatObservableOptions = Omit<DateTimeFormatOptions, "timeDisplayMode">;

export interface DateFormatter {
    formatTime(date: Date, options?: TimeFormatOptions): string;
    formatTimeObservable(
        date: Date | Rx.Observable<Date>,
        options?: TimeFormatObservableOptions,
    ): Rx.Observable<string>;
    formatFullDate(date: Date, options?: DateTimeFormatOptions): string;
    formatFullDateObservable(
        date: Date | Rx.Observable<Date>,
        options?: DateTimeFormatObservableOptions,
    ): Rx.Observable<string>;
    formatRelativeDate(date: Date): string;
}

export class CommonDateFormatter implements DateFormatter {
    private timeDisplayMode = DEFAULT_TIME_DISPLAY_MODE;

    private get displayModeObservable(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository
                .observePreference<string>(LocalUserPreferenceKeys.appearance.timeDisplayMode)
                .pipe(RxOperators.map((value) => getTimeDisplayMode(value))),
        );
    }

    public constructor(private localPreferencesRepository: LocalPreferencesRepository) {
        this.displayModeObservable.subscribe((t) => (this.timeDisplayMode = t));
    }

    // Public functions

    /**
     * Formats a date to a time string
     * @param date Source date object
     * @param options Formatting options (TimeFormatOptions)
     * @returns Formatted time string (HH:mm:ss)
     */
    public formatTime(date: Date, options?: TimeFormatOptions): string {
        // Compansate for timezone offset if provided by the API
        date = this.getDateTimeWithTimezoneOffset(date, options?.timezoneOffset);

        const timeDisplayMode = (options && options.timeDisplayMode) || this.timeDisplayMode;
        const isUTC = timeDisplayMode === TimeDisplayMode.UTC;
        const hours = isUTC ? date.getUTCHours() : date.getHours();
        const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
        let result = this.appendLeadingZeroes(hours) + ":" + this.appendLeadingZeroes(minutes);
        if (options && options.includeSeconds) {
            result += ":" + this.appendLeadingZeroes(date.getUTCSeconds());
        }
        if (isUTC && options && options.postfixUTC) {
            result += DATE_TIME_POSTFIX_UTC;
        }

        return result;
    }

    /**
     * Calls formatTime, but accepts an observable as input
     * @param date Source date object
     * @param options Formatting options (TimeFormatOptions)
     * @returns Observable with formatted time string
     */
    public formatTimeObservable(
        date: Date | Rx.Observable<Date>,
        options?: TimeFormatObservableOptions,
    ): Rx.Observable<string> {
        const observable = date instanceof Date ? Rx.of(date) : date;
        return Rx.combineLatest([observable, this.displayModeObservable]).pipe(
            RxOperators.map(([date, displayMode]) =>
                this.formatTime(date, { ...options, timeDisplayMode: displayMode }),
            ),
        );
    }

    /**
     * Formats a date to a full date string
     * If excludeDateToday is true in options and if it is today, only the time will be returned
     * @param date Source date object
     * @param options Formatting options (DateTimeFormatOptions)
     * @returns Formatted full date string (dd-MM-yyyy HH:mm:ss)
     */
    public formatFullDate(date: Date, options?: DateTimeFormatOptions): string {
        // Compansate for timezone offset if provided by the API
        date = this.getDateTimeWithTimezoneOffset(date, options?.timezoneOffset);

        const timeDisplayMode = (options && options.timeDisplayMode) || this.timeDisplayMode;
        const isUTC = timeDisplayMode === TimeDisplayMode.UTC;
        const hours = isUTC ? date.getUTCHours() : date.getHours();
        const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
        const seconds = isUTC ? date.getUTCSeconds() : date.getSeconds();
        const postfix = (isUTC && options && options.postfixUTC && DATE_TIME_POSTFIX_UTC) || "";

        const alz = this.appendLeadingZeroes;
        const timeString = `${alz(hours)}:${alz(minutes)}:${alz(seconds)}${postfix}`;

        if (options?.excludeDateToday && this.getDayDifferenceToNow(date) < 1) {
            return timeString;
        }

        const year = isUTC ? date.getUTCFullYear() : date.getFullYear();
        const month = isUTC ? date.getUTCMonth() : date.getMonth();
        const day = isUTC ? date.getUTCDate() : date.getDate();
        return `${alz(day)}-${alz(month + 1)}-${year} ${timeString}`;
    }

    /**
     * Calls formatFullDate, but accepts an observable as input
     * If excludeDateToday is true in options and if it is today, only the time will be returned
     * @param date Source date object
     * @param options Formatting options (DateTimeFormatOptions)
     * @returns Observable with formatted full date string
     */
    public formatFullDateObservable(
        date: Rx.Observable<Date>,
        options?: DateTimeFormatObservableOptions,
    ): Rx.Observable<string> {
        const observable = date instanceof Date ? Rx.of(date) : date;
        return Rx.combineLatest([observable, this.displayModeObservable]).pipe(
            RxOperators.map(([date, displayMode]) =>
                this.formatFullDate(date, { ...options, timeDisplayMode: displayMode }),
            ),
        );
    }

    /**
     * Formats a date to a relative date string
     * @param date Source date object
     * @returns Formatted relative date string (Today, Yesterday, January 2020 or 1 January 2020)
     */
    public formatRelativeDate(date: Date): string {
        const deltaDays = this.getDayDifferenceToNow(date);
        const languageCode = ACTIVE_LANGUAGE.intlLocale;

        if (deltaDays < 1) {
            return t("unit.today");
        } else if (deltaDays < 2) {
            return t("unit.yesterday");
        } else if (deltaDays <= DAY * 365) {
            const dateTimeFormat = new Intl.DateTimeFormat(languageCode, { month: "long", day: "numeric" });
            const [{ value: month }, , { value: day }] = dateTimeFormat.formatToParts(date);
            return `${month} ${day}`;
        } else {
            const dateTimeFormat = new Intl.DateTimeFormat(languageCode, {
                month: "long",
                day: "numeric",
                year: "numeric",
            });
            const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
            return `${month} ${day} ${year}`;
        }
    }

    // Private functions

    private appendLeadingZeroes(n: number): string {
        if (n <= 9) {
            return "0" + n;
        }

        return n + "";
    }

    private getDayDifferenceToNow(date: Date): number {
        return Math.floor(new Date().getTime() / DAY) - Math.floor(date.getTime() / DAY);
    }

    /**
     * Returns the current date taking into account an optional timezone difference
     * @param date Source date object that needs conversion
     * @param timezoneOffset Timezone difference in miliseconds
     * @returns Formatted date object
     */
    private getDateTimeWithTimezoneOffset(date: Date, timezoneOffset: number | null = null): Date {
        // Get the UTC date and time of the provided date
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        // If a timezone offset is provided, add it to the UTC date to get the local time of the radar/backend
        return timezoneOffset != null ? new Date(utcDate.getTime() + timezoneOffset) : date;
    }
}
