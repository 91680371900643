import React from "react";
import { FullscreenBrandedPage } from "./FullscreenBrandedPage";
import { VerticalSpace } from "./Space";
import { LOADER_CONTAINER_HEIGHT } from "./FullscreenLoading";
import { ErrorView, ErrorViewProps } from "../error/ErrorView";

export const FullscreenError = (props: ErrorViewProps): JSX.Element => {
    return (
        <FullscreenBrandedPage>
            <VerticalSpace height={LOADER_CONTAINER_HEIGHT} />
            <ErrorView {...props} />
        </FullscreenBrandedPage>
    );
};
