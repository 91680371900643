import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { showWarning } from "../../../utils/MessageUtils";

const HoverableButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    ${Button} {
        opacity: 0;
        padding: ${({ theme }) => theme.spacing.x2};
        border-radius: 50%; // make it a circle
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover ${HoverableButtonWrapper} > ${Button} {
        opacity: 1;
        transition: opacity 0.3s;
    }
    &:hover ${HoverableButtonWrapper} > ${Button}:hover {
        border-radius: 50%;
        border: 0;
        padding: ${({ theme }) => theme.spacing.x2};
        transition: all 0.3s;
        background: rgba(0, 0, 0, 0.1);
    }
`;

interface Props {
    message: string;
}

export const CopyButtonWrapper = ({ message, children }: PropsWithChildren<Props>): JSX.Element => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, 2000);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [copied]);

    return (
        <Container>
            <HoverableButtonWrapper>
                {children}
                <Button
                    onClick={async (e) => {
                        e.stopPropagation();
                        try {
                            await window.navigator.clipboard.writeText(message);
                            setCopied(true);
                        } catch (err) {
                            showWarning({
                                message: "messages.failedToCopyToClipboard",
                            });
                        }
                    }}
                    iconType={copied ? "CHECK" : "COPY"}
                    iconPosition={"left"}
                    layout={"inline"}
                    color={"white"}
                />
            </HoverableButtonWrapper>
        </Container>
    );
};
