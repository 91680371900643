import { Subscription } from "rxjs";

export class BaseViewModel {
    // Properties

    private subscriptions = new Subscription();

    // Public functions

    protected collectSubscription(subscription: Subscription): void {
        this.subscriptions.add(subscription);
    }

    protected collectSubscriptions(...subscriptions: Subscription[]): void {
        subscriptions.forEach((subscription) => this.collectSubscription(subscription));
    }

    public unsubscribeFromObservables(): void {
        this.subscriptions.unsubscribe();
    }
}
