import { Snapshot, Track, Classification, TrackableSnapshot, getMaxTimestamp } from ".";
import { TrackData as TracksSnapshotProto } from "./proto/generated/tracklist3_pb";
import { TrackListJSON } from "./json";
import { TrackableSnapshotDiff } from "../SnapshotDiffCalculator";

export type TracksSnapshotDiff = TrackableSnapshotDiff<Track>;

type TracksMap = Map<int, Track>;

export class TracksSnapshot extends Snapshot implements TrackableSnapshot<Track> {
    // Static functions

    public static fromProto(
        model: TracksSnapshotProto,
        classificationMap: Map<string, Classification>,
    ): TracksSnapshot {
        const tracks: TracksMap = new Map();
        model.getTrackList().forEach((value) => tracks.set(value.getId(), Track.fromProto(value, classificationMap)));
        return new TracksSnapshot(tracks, model.getRainPercentage(), model.getImageTimestampMsec());
    }

    public static fromJson(model: TrackListJSON, classificationMap: Map<string, Classification>): TracksSnapshot {
        const tracks: TracksMap = new Map();
        model.forEach((track) => {
            tracks.set(track.trackid, Track.fromJson(track, classificationMap));
        });
        const snapshotTimestamp = getMaxTimestamp(...model.map((t) => t.timestampMsec));
        return new TracksSnapshot(tracks, 0, snapshotTimestamp);
    }

    public constructor(public readonly tracks: TracksMap, public readonly rainPercentage: int, timestamp?: long) {
        super(timestamp);
    }
}
