import { Container, interfaces } from "inversify";

class DI extends Container {
    public getOptional<T>(serviceIdentifier: interfaces.ServiceIdentifier<T>): T | undefined {
        return this.isBound(serviceIdentifier) ? this.get(serviceIdentifier) : undefined;
    }
}

const INSTANCE = new DI();

export default INSTANCE;
