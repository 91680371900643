import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SettingsItemSection } from "../SettingsItemSection";
import { t } from "i18next";
import {
    LocalPreferencesRepository,
    LocationInfoRepository,
    RadarRepository,
    UserLocationRepository,
} from "../../../domain/repositories";
import { SettingItemViewModel } from "../SettingItemViewModel";
import { TrailLengthSettingItemViewModel } from "./items/TrailLengthSettingItemViewModel";
import { FinishedTrackLifetimeSettingItemViewModel } from "./items/FinishedTrackLifetimeSettingItemViewModel";
import { FinishedTrackOpacitySettingItemViewModel } from "./items/FinishedTrackOpacitySettingItemViewModel";
import { TrackLabelSettingItemViewModel } from "./items/TrackLabelSettingItemViewModel";
import { ShowClassificationHistorySettingItemViewModel } from "./items/ShowClassificationHistorySettingItemViewModel";
import { AltitudeConfig } from "../../../domain/model/Altitude";
import { TrackAltitudeConfigSettingItemViewModel } from "./items/TrackAltitudeConfigSettingItemViewModel";
import { SettingsErrorHandler } from "../SettingsErrorHandler";
import { LocalUserPreferenceKeys } from "../../../domain/model";

export class CommonFilterSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "filter-settings";
    public readonly label: string | null = t("settings.tracks");

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly radarRepository: RadarRepository,
        private readonly userLocationRepository: UserLocationRepository,
    ) {}

    // Public functions

    public generate(_errorHandler: SettingsErrorHandler): Rx.Observable<SettingItemViewModel[]> {
        return Rx.combineLatest([
            this.locationInfoRepository.hasReliableAltitudeInfo,
            this.radarRepository.groundLevel,
            this.userLocationRepository.userAltitude,
        ]).pipe(
            RxOperators.map(([hasReliableAltitudeInfo, groundLevel, userAltitude]) => {
                const settings: SettingItemViewModel[] = [
                    new TrailLengthSettingItemViewModel(this.localPreferencesRepository),
                    new FinishedTrackLifetimeSettingItemViewModel(this.localPreferencesRepository),
                    new FinishedTrackOpacitySettingItemViewModel(this.localPreferencesRepository),
                    new TrackLabelSettingItemViewModel(this.localPreferencesRepository, hasReliableAltitudeInfo),
                    new ShowClassificationHistorySettingItemViewModel(this.localPreferencesRepository),
                ];
                // Check which altitude config options are available
                const altitudeConfigOptions = [AltitudeConfig.WGS84];
                if (groundLevel !== null) {
                    altitudeConfigOptions.push(AltitudeConfig.GROUND_LEVEL);
                }
                if (userAltitude !== null) {
                    altitudeConfigOptions.push(AltitudeConfig.USER_LOCATION);
                }
                const currentAltitudeConfig = this.localPreferencesRepository.getPreference<AltitudeConfig>(
                    LocalUserPreferenceKeys.appearance.altitudeConfig,
                );
                // Reset the config option to default if it is not available anymore
                if (!currentAltitudeConfig || altitudeConfigOptions.indexOf(currentAltitudeConfig) === -1) {
                    this.localPreferencesRepository.setPreference(
                        LocalUserPreferenceKeys.appearance.altitudeConfig,
                        AltitudeConfig.WGS84,
                    );
                }
                if (altitudeConfigOptions.length > 1) {
                    settings.push(
                        new TrackAltitudeConfigSettingItemViewModel(
                            this.localPreferencesRepository,
                            altitudeConfigOptions,
                        ),
                    );
                }
                return settings;
            }),
        );
    }
}
