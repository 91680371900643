import _sortBy from "lodash/sortBy";
import {
    AlarmData,
    AlarmDataType,
    Classification,
    Estimate,
    FlightInfo,
    FlightPhase,
    Track,
    TrackPlotType,
} from "../../model";

export class TrackUpdater {
    public constructor() {}

    public processNewTrack(tracks: Map<int, Track>, trackId: int, newTrack: Track): void {
        tracks.set(trackId, newTrack);
    }

    public processNewEstimation(
        tracks: Map<int, Track>,
        trackId: int,
        estimate: Estimate,
        trackPlotType?: TrackPlotType,
    ): void {
        const track = tracks.get(trackId);
        if (track == null) {
            return;
        }
        const lastEstimate = track.lastEstimate;
        const newEstimate = {
            ...estimate,
            rcs: lastEstimate.rcs,
            classification: lastEstimate.classification,
            alarms: lastEstimate.alarms,
        };
        this.updateTrackInMap(tracks, trackId, {
            trackPlotType: trackPlotType || track.trackPlotType,
            estimates: [...track.estimates, newEstimate],
        });
    }

    public processDropTrack(tracks: Map<int, Track>, trackId: int): void {
        tracks.delete(trackId);
    }

    public processClassificationChange(
        tracks: Map<int, Track>,
        trackId: int,
        classification: string,
        classificationMap: Map<string, Classification>,
    ): void {
        const c = classificationMap.get(classification);
        this.updateEstimateForTrackInMap(tracks, trackId, {
            classification: c ? c : (null as Classification | null),
        });
    }

    public processFusedTrack(tracks: Map<int, Track>, trackId: int, fusingTrackId: int): void {
        const track = tracks.get(trackId);
        if (track == null) {
            return;
        }
        const fusingTrack = tracks.get(fusingTrackId);
        if (fusingTrack == null) {
            return;
        }
        const estimatesTimestamps = track.estimates.map((value) => value.timestamp);
        let newEstimates = Array.from(track.estimates);
        fusingTrack.estimates.forEach((value) => {
            if (!estimatesTimestamps.includes(value.timestamp)) {
                newEstimates.push(value);
            }
        });
        newEstimates = _sortBy(newEstimates, (value) => value.timestamp);
        const newTrack = track.clone({
            estimates: newEstimates,
        });
        tracks.delete(fusingTrack.id);
        tracks.set(trackId, newTrack);
    }

    public processAddPlot(tracks: Map<int, Track>, trackId: int, rcs: number | null): void {
        this.updateEstimateForTrackInMap(tracks, trackId, { rcs });
    }

    public processFlightInfo(
        tracks: Map<int, Track>,
        trackId: int,
        icao?: number,
        flightPhase?: FlightPhase,
        flightInfo?: FlightInfo,
    ): void {
        this.updateTrackInMap(tracks, trackId, {
            icao,
            flightPhase,
            flightInfo,
        });
    }

    public processDroneAlarmChange(tracks: Map<int, Track>, trackId: int, isAlarm: boolean): void {
        this.updateAlarmForTrackInMap(tracks, trackId, isAlarm, { type: AlarmDataType.DRONE, trackId });
    }

    public processAreaExitEntryChange(
        tracks: Map<int, Track>,
        trackId: int,
        isAlarm: boolean,
        overlayIds: string[],
    ): void {
        this.updateAlarmForTrackInMap(tracks, trackId, isAlarm, {
            type: AlarmDataType.AREA_ENTRY,
            overlayIds,
            trackId,
        });
    }

    public processDatabaseIdChange(tracks: Map<int, Track>, trackId: int, databaseId: number): void {
        this.updateTrackInMap(tracks, trackId, {
            databaseId,
        });
    }

    private updateAlarmForTrackInMap(
        tracks: Map<int, Track>,
        trackId: int,
        isAlarm: boolean,
        alarmData: AlarmData,
    ): void {
        const track = tracks.get(trackId);
        if (!track) {
            return;
        }
        const newAlarms = this.toggleAlarm(track.lastEstimate.alarms, alarmData, isAlarm);
        this.updateEstimateForTrackInMap(tracks, track.id, { alarms: newAlarms });
    }

    private toggleAlarm(allAlarms: AlarmData[], alarmToToggle: AlarmData, enabled: boolean): AlarmData[] {
        const newAlarms = [...allAlarms];
        const indexOfAlarmToToggle = newAlarms.findIndex((a) => a.type == alarmToToggle.type);
        // Remove the old alarm if exists
        if (indexOfAlarmToToggle >= 0) {
            newAlarms.splice(indexOfAlarmToToggle, 1);
        }
        // Add the new/updated alarm
        if (enabled) {
            newAlarms.push(alarmToToggle);
        }
        return newAlarms;
    }

    private updateEstimateForTrackInMap(tracks: Map<int, Track>, trackId: int, update: Partial<Estimate>): void {
        const track = tracks.get(trackId);
        if (!track) {
            return;
        }
        const lastEstimate = track.lastEstimate;
        const newEstimate: Estimate = {
            ...lastEstimate,
            ...update,
            // Bring it closer to now so it gets picked with higher priority as the "last" estimate.
            timestamp: lastEstimate.timestamp + 1,
        };
        this.updateTrackInMap(tracks, trackId, {
            estimates: [...track.estimates, newEstimate],
        });
    }

    private updateTrackInMap(tracks: Map<int, Track>, trackId: int, update: Partial<Track>): void {
        const track = tracks.get(trackId);
        if (!track) {
            return;
        }
        const newTrack = track.clone(update);
        tracks.set(trackId, newTrack);
    }
}
