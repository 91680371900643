import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SidebarUIState } from "../../domain/model";
import { UserPermission } from "../../domain/model/UserPermission";
import {
    AlarmRepository,
    RadarRepository,
    ReplayRepository,
    SessionRepository,
    UIControlRepository,
} from "../../domain/repositories";
import { nonNullObservable } from "../../utils/RxUtils";
import { BaseViewModel } from "../BaseViewModel";

export class SidebarViewModel extends BaseViewModel {
    // Properties

    public get hasPlaybackState(): Rx.Observable<boolean> {
        return this.replayRepository.currentPlaybackScene.pipe(RxOperators.map((scene) => scene != null));
    }

    public get isRadarControlAllowed(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.sessionRepository.session.pipe(
                RxOperators.map((session) => session.user?.role.permissions.includes(UserPermission.CanManageRadar)),
            ),
        );
    }

    public get sidebarSectionState(): Rx.Observable<SidebarUIState> {
        return this.uiControlRepository.sidebarUIState;
    }

    public get isDynamicPositioningEnabled(): Rx.Observable<boolean> {
        return this.radarRepository.isDynamicPositioningEnabled;
    }

    public get hasActiveAlarms(): Rx.Observable<boolean> {
        return this.alarmRepository.activeRealtime.pipe(RxOperators.map((alarms) => alarms.length > 0));
    }

    public constructor(
        private readonly replayRepository: ReplayRepository,
        private readonly sessionRepository: SessionRepository,
        private readonly uiControlRepository: UIControlRepository,
        private readonly radarRepository: RadarRepository,
        private readonly alarmRepository: AlarmRepository,
    ) {
        super();
        this.collectSubscription(
            // Make sure to switch to track info when dynamic positioning is disabled and the vehicle info is visible
            Rx.combineLatest([this.isDynamicPositioningEnabled, this.sidebarSectionState])
                .pipe(RxOperators.distinctUntilChanged())
                .subscribe(
                    ([isDynamicPositioningEnabled, sidebarSectionState]) =>
                        !isDynamicPositioningEnabled &&
                        sidebarSectionState.isVehicleInfoVisible &&
                        this.showSidebarSection("isTrackInfoVisible"),
                ),
        );
    }

    // Public functions

    public showSidebarSection(key: keyof SidebarUIState): void {
        this.uiControlRepository.toggleSidebarUIComponent(key, { isVisible: true });
    }
}
