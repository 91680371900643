import { VersionInfoJSON } from "./json";

/**
 * @info license info is only available here, server version info now comes from ServerConfig
 */
export class VersionInfo {
    public static fromJson(model: VersionInfoJSON): VersionInfo {
        return new VersionInfo(model.versionTag, model.licenseExpiration);
    }

    public constructor(public readonly versionTag: string, public readonly licenseExpiration?: string) {}
}
