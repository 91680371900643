import React from "react";
import styled from "styled-components";
import { DateFormatter } from "../../../infrastructure/DateFormatter";
import { TYPES } from "../../../di/Types";
import { useDI } from "../../../hooks/useDI";

type AlignText = "flex-start" | "flex-end";

const Container = styled.div<{ alignText: AlignText }>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ alignText: align }) => align};
    ${({ alignText, theme }) => {
        if (alignText === "flex-start") {
            return `margin-left: ${theme.spacing.x3};`;
        } else {
            return `margin-right: ${theme.spacing.x3};`;
        }
    }};
    width: ${({ theme }) => theme.spacing.x20};
`;

const DateTimeText = styled.label<{ alignText?: AlignText }>`
    width: ${({ theme }) => theme.spacing.x20};
    font-size: 12px;
    font-weight: 500;
    color: white;
    text-align: ${({ alignText: align }) => (align === "flex-end" ? "right" : "left")};
`;

interface Props {
    alignText: AlignText;
    dateTime: Date;
}

export const ReplayTime = ({ alignText, dateTime }: Props): JSX.Element => {
    // Properties

    const dateFormatter = useDI<DateFormatter>(TYPES.DateFormatter);
    const [date, time] = dateFormatter.formatFullDate(dateTime).split(" ");

    // Render

    return (
        <Container alignText={alignText}>
            <DateTimeText alignText={alignText}>{date}</DateTimeText>
            <DateTimeText alignText={alignText}>{time}</DateTimeText>
        </Container>
    );
};
