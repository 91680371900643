import React from "react";
import { User } from "../../domain/model/User";
import * as Rx from "rxjs";
import { generateUUID } from "../../utils/UUID";
import { FormRoundInput } from "../appearance/FormRoundInput";
import { FormRoundSelect } from "../appearance/FormRoundSelect";
import styled, { withTheme } from "styled-components";
import { UserRole } from "../../domain/model/UserRole";
import { RoundInput } from "../appearance/RoundInput";
import { RoundSelect } from "../appearance/RoundSelect";
import { validateEmail } from "../../utils/ValidateEmail";
import { ToggleSwitch } from "../appearance/ToggleSwitch";
import { FormInputContainer } from "../appearance/FormInputContainer";
import { FormInputLabel } from "../appearance/FormInputLabel";
import { FormColumnHelper } from "../appearance/FormColumnHelper";
import { t } from "i18next";
import { Theme } from "../appearance/theme/Theme";
import { Form } from "../appearance/forms/Form";
import { FormControl } from "../appearance/forms/FormControl";
import { Button } from "../appearance/button/Button";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { ButtonGroup } from "../appearance/button/ButtonGroup";

const PasswordRepeat = styled(FormRoundInput)`
    margin-top: 5px;
`;

const ToggleSwitchSpacer = styled(FormInputContainer)`
    display: block;
`;

const FormHelpText = styled.p`
    margin-top: 12px;
    margin-bottom: 0;
    color: white;
    font-size: 13px;
    opacity: 0.7;
`;

interface FormData {
    name: string;
    loginName: string;
    roleId: string;
    password: string;
    email: string;
    isActive: boolean;
}

interface Props {
    isSelf: boolean;
    onClosed: () => void;
    onRequestSubmit: (user: User) => Rx.Observable<void>;
    onRequestDelete: () => Rx.Observable<void>;
    roles: UserRole[];
    user: User | null;
    theme: Theme;
}

interface State {
    isEditing: boolean;
    isLoading: boolean;
    confirmingDelete: boolean;
    isWaitingForDelete: boolean;
    isWaitingForSubmit: boolean;
    name: string;
    loginName: string;
    roleId: string | null;
    password: string;
    passwordRepeat: string;
    email: string;
    isActive: boolean;
}

export class AddEditUserComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly nameInput: React.RefObject<RoundInput>;
    private readonly loginNameInput: React.RefObject<RoundInput>;
    private readonly emailInput: React.RefObject<RoundInput>;
    private readonly roleSelect: React.RefObject<RoundSelect>;
    private readonly passwordInput: React.RefObject<RoundInput>;
    private readonly passwordRepeatInput: React.RefObject<RoundInput>;
    private confirmingDeleteTimeoutRef: NodeJS.Timeout | null = null;

    // Lifecycle

    public constructor(props: Readonly<Props>) {
        super(props);

        this.nameInput = React.createRef();
        this.loginNameInput = React.createRef();
        this.emailInput = React.createRef();
        this.roleSelect = React.createRef();
        this.passwordInput = React.createRef();
        this.passwordRepeatInput = React.createRef();

        let name = "";
        let loginName = "";
        let email = "";
        let roleId: string | null = null;
        let isActive = true;

        if (this.props.user) {
            name = this.props.user.name;
            loginName = this.props.user.username;
            email = this.props.user.email;
            roleId = this.props.user.role.id;
            isActive = this.props.user.isActive;
        }

        this.state = {
            isEditing: this.props.user != null,
            isLoading: false,
            confirmingDelete: false,
            isWaitingForSubmit: false,
            isWaitingForDelete: false,
            name,
            loginName,
            roleId,
            password: "",
            passwordRepeat: "",
            email,
            isActive,
        };
    }

    public render(): React.ReactNode {
        return (
            <Form>
                <FormInputContainer>
                    <FormInputLabel>{t("userManagement.name")}</FormInputLabel>
                    <FormColumnHelper>
                        <FormRoundInput
                            ref={this.nameInput}
                            disabled={this.state.isLoading}
                            text={this.state.name}
                            placeholder={t("userManagement.namePlaceholder")}
                        />
                    </FormColumnHelper>
                </FormInputContainer>
                <FormInputContainer>
                    <FormInputLabel>{t("userManagement.loginName")}</FormInputLabel>
                    <FormColumnHelper>
                        <FormRoundInput
                            ref={this.loginNameInput}
                            disabled={this.state.isLoading}
                            text={this.state.loginName}
                            placeholder={t("userManagement.loginNamePlaceholder")}
                            autoCapitalize="off"
                        />
                    </FormColumnHelper>
                </FormInputContainer>
                <FormInputContainer>
                    <FormInputLabel>{t("userManagement.email")}</FormInputLabel>
                    <FormColumnHelper>
                        <FormRoundInput
                            ref={this.emailInput}
                            disabled={this.state.isLoading}
                            text={this.state.email}
                            placeholder={t("userManagement.emailPlaceholder")}
                            type="email"
                        />
                    </FormColumnHelper>
                </FormInputContainer>
                <FormInputContainer>
                    <FormInputLabel>{t("userManagement.role")}</FormInputLabel>
                    <FormColumnHelper>
                        <FormRoundSelect
                            ref={this.roleSelect}
                            selectedOptionId={this.state.roleId}
                            disabled={this.state.isLoading || this.props.isSelf}
                            options={this.props.roles.map((role) => {
                                return {
                                    id: role.id,
                                    title: t(`userManagement.${role.name.toLowerCase()}`),
                                };
                            })}
                            placeholder={t("userManagement.rolePlaceholder")}
                        />
                    </FormColumnHelper>
                </FormInputContainer>
                <FormInputContainer>
                    <FormInputLabel>{t("userManagement.password")}</FormInputLabel>
                    <FormColumnHelper>
                        <FormRoundInput
                            type={"password"}
                            ref={this.passwordInput}
                            disabled={this.state.isLoading}
                            text={this.state.password}
                            placeholder={t("userManagement.passwordPlaceholder")}
                        />
                        <PasswordRepeat
                            type="password"
                            ref={this.passwordRepeatInput}
                            disabled={this.state.isLoading}
                            text={this.state.passwordRepeat}
                            placeholder={t("userManagement.passwordRepeatPlaceholder")}
                        />
                        <FormHelpText>{t("userManagement.passwordHelpText")}</FormHelpText>
                    </FormColumnHelper>
                </FormInputContainer>
                <FormInputContainer>
                    <FormInputLabel>{t("userManagement.active")}</FormInputLabel>
                    {!this.props.isSelf ? (
                        <ToggleSwitchSpacer>
                            <ToggleSwitch
                                disabled={this.props.isSelf}
                                onChange={(v) => this.setState({ isActive: v })}
                                value={this.state.isActive}
                            />
                        </ToggleSwitchSpacer>
                    ) : (
                        <FormColumnHelper>
                            <FormHelpText>{t("userManagement.activeDisabledForCurrentUser")}</FormHelpText>
                        </FormColumnHelper>
                    )}
                </FormInputContainer>
                <FormControl alignContents="space-between" fullWidth>
                    <ButtonGroup>
                        <Button
                            disabled={this.state.isLoading || this.state.isWaitingForSubmit}
                            label={t("general.save")}
                            onClick={this.submit.bind(this)}
                        />
                        <Button label={t("general.cancel")} layout="inline" onClick={() => this.props.onClosed()} />
                    </ButtonGroup>
                    {this.state.isEditing && (
                        <Button
                            color="error"
                            disabled={this.state.isLoading || this.state.isWaitingForDelete}
                            label={this.state.confirmingDelete ? t("general.deleteConfirm") : t("general.delete")}
                            layout="inline"
                            onClick={this.delete.bind(this)}
                        />
                    )}
                </FormControl>
            </Form>
        );
    }

    // Private functions

    private getRoleById(id: string | null): UserRole {
        return this.props.roles.find((role) => role.id === id)!;
    }

    private delete(): void {
        if (!this.state.confirmingDelete) {
            this.setState({ confirmingDelete: true });
            this.confirmingDeleteTimeoutRef = setTimeout(() => this.setState({ confirmingDelete: false }), 3000);
            return;
        }

        if (this.confirmingDeleteTimeoutRef != null) {
            clearTimeout(this.confirmingDeleteTimeoutRef);
        }
        this.performDelete();
    }

    private performDelete(): void {
        this.setState({
            isLoading: true,
            isWaitingForDelete: true,
        });
        const subscription = this.props.onRequestDelete().subscribe({
            error: () => {
                this.setState({
                    isLoading: false,
                    confirmingDelete: false,
                    isWaitingForDelete: false,
                });
            },
            complete: () =>
                this.setState({
                    isLoading: false,
                    isWaitingForDelete: false,
                }),
        });
        this.collectSubscription(subscription);
    }

    private submit(): void {
        const validatedData = this.validateFormData();
        if (!validatedData) {
            return;
        }

        const role = this.getRoleById(validatedData.roleId);

        let user: User;
        if (this.state.isEditing) {
            let password = null;
            if (validatedData.password.length > 0) {
                password = validatedData.password;
            }
            user = new User(
                this.props.user!.id,
                validatedData.name,
                validatedData.loginName,
                role,
                password,
                validatedData.email,
                validatedData.isActive,
            );
        } else {
            user = new User(
                generateUUID(),
                validatedData.name,
                validatedData.loginName,
                role,
                validatedData.password,
                validatedData.email,
                validatedData.isActive,
            );
        }

        this.startLoading();
        const subscription = this.props.onRequestSubmit(user).subscribe({
            error: this.stopLoading.bind(this),
            complete: this.stopLoading.bind(this),
        });
        this.collectSubscription(subscription);
    }

    private startLoading(): void {
        this.setState({
            isLoading: true,
            isWaitingForSubmit: true,
        });
    }

    private stopLoading(): void {
        this.setState({
            isLoading: false,
            isWaitingForSubmit: false,
        });
    }

    private validateFormData(): FormData | null {
        if (
            !this.nameInput.current ||
            !this.loginNameInput.current ||
            !this.emailInput.current ||
            !this.roleSelect.current ||
            !this.passwordInput.current ||
            !this.passwordRepeatInput.current
        ) {
            return null;
        }

        const name = this.nameInput.current.value;
        const loginName = this.loginNameInput.current.value;
        const email = this.emailInput.current.value;
        const roleSelectedOption = this.roleSelect.current.value;
        const password = this.passwordInput.current.value;
        const passwordRepeat = this.passwordRepeatInput.current.value;

        if (name.length < 3) {
            this.nameInput.current.showError();
            return null;
        }
        if (loginName.length < 3) {
            this.loginNameInput.current.showError();
            return null;
        }
        // Emailaddress is not required
        if (email.length > 0 && !validateEmail(email)) {
            this.emailInput.current.showError();
            return null;
        }
        if (!roleSelectedOption) {
            this.roleSelect.current.showError();
            return null;
        }
        // Password should be >= 6 chars, it allows empty password on editing mode which means no change
        if (password.length < 6 && !(this.state.isEditing && password.length === 0)) {
            this.passwordInput.current.showError();
            return null;
        }

        // Password should match password repeat
        if (password !== passwordRepeat) {
            this.passwordInput.current.showError();
            this.passwordRepeatInput.current.showError();
            return null;
        }

        return { name, loginName, roleId: roleSelectedOption.id, password, email, isActive: this.state.isActive };
    }
}

export const AddEditUser = withTheme(AddEditUserComponent);
