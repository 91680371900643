import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { SettingsViewModel } from "./SettingsViewModel";
import {
    SettingItemViewModel,
    ActionItemViewModel,
    SwitchItemViewModel,
    PlusMinusItemViewModel,
    SelectItemViewModel,
    IconSwitchItemViewModel,
} from "./SettingItemViewModel";
import React from "react";
import styled from "styled-components";
import { SettingsDrawer } from "./SettingsDrawer";
import { ScreenDimmer } from "../dimmer/ScreenDimmer";
import { ToggleSwitch } from "../appearance/ToggleSwitch";
import { PlusMinusController } from "../appearance/PlusMinusController";
import { TextController } from "../appearance/TextController";
import { SettingsUIActionRequest } from "./SettingsUIActionRequest";
import { TYPES } from "../../di/Types";
import DI from "./../../di/DI";
import { SettingsErrorHandler } from "./SettingsErrorHandler";
import { SettingsUIActionRequestHandler } from "./SettingsUIActionRequestHandler";
import { showError } from "../../utils/MessageUtils";
import { Collapse } from "react-collapse";
import { SettingsSection } from "./SettingsSection";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { CloseButtonWithMargin as CloseButtonOriginal } from "../appearance/CloseButton";
import { SectionHeader } from "../appearance/SectionHeader";
import { FormRoundSelect } from "../appearance/FormRoundSelect";
import IconSwitch from "../appearance/IconSwitch";

const Container = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 2000;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CloseButton = styled(CloseButtonOriginal)`
    top: 20px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 70px 30px 30px 30px;
    text-align: left;
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.text.text};
`;

const ListRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    justify-content: space-between;
    margin: 6px 0 0px 0;
    > span {
        display: flex;
        margin-right: 10px;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        label {
            margin-left: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const IconContainer = styled.span`
    width: 22px;
    height: 22px;
`;

const LabelContainer = styled.label`
    cursor: pointer;
    :hover {
        color: ${({ theme }) => theme.colors.secondary.white200};
    }
`;

const IconSwitchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        margin-left: 12px;
    }
`;

interface Props {
    onClosed: () => void;
    onUIActionRequested: (action: SettingsUIActionRequest) => void;
}

interface State {
    settingSections: SettingsSection[];
    settingSectionsVisibilityMap: Map<string, boolean>;
    switchIsOn: boolean;
}

export class Settings
    extends BaseSubscriptionHandlerComponent<Props, State>
    implements SettingsErrorHandler, SettingsUIActionRequestHandler
{
    // Properties

    private viewModel: SettingsViewModel = DI.get(TYPES.SettingsViewModel);
    private readonly drawer: React.RefObject<SettingsDrawer>;
    private readonly screenDimmer: React.RefObject<ScreenDimmer>;

    public constructor(props: Props) {
        super(props);
        this.drawer = React.createRef();
        this.screenDimmer = React.createRef();
        this.state = {
            settingSections: [],
            settingSectionsVisibilityMap: new Map(),
            switchIsOn: false,
        };
    }

    // Public functions

    public componentDidMount(): void {
        this.subscribeToSettingItems();
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.viewModel.unsubscribeFromObservables();
    }

    public render(): JSX.Element {
        return (
            <Container>
                <ScreenDimmer ref={this.screenDimmer} onClick={this.close.bind(this)} />
                <SettingsDrawer ref={this.drawer}>
                    <CloseButton onClick={this.close.bind(this)} />
                    <ContentContainer>{this.getSections()}</ContentContainer>
                </SettingsDrawer>
            </Container>
        );
    }

    public onUIActionRequested(action: SettingsUIActionRequest): void {
        switch (action) {
            case SettingsUIActionRequest.SHOW_TILE_PROVIDER_DIALOG:
            case SettingsUIActionRequest.SHOW_USER_MANAGEMENT_DIALOG:
            case SettingsUIActionRequest.SHOW_LANGUAGE_DIALOG:
            case SettingsUIActionRequest.SHOW_ABOUT_DIALOG:
                this.close();
                break;
        }
        this.props.onUIActionRequested(action);
    }

    public onError(error: Error): void {
        showError(error);
    }

    // Private functions

    private getSections(): React.ReactNode {
        return this.state.settingSections
            .filter((section) => section.items.length > 0)
            .map((section) => {
                const hasLabel = section.label !== null;
                return (
                    <ListContainer key={section.id + "-section"}>
                        {hasLabel && (
                            <SectionHeader
                                label={section.label!}
                                isOpen={this.state.settingSectionsVisibilityMap.get(section.id)!}
                                onClick={(isOpen) => this.viewModel.setSectionOpen(section.id, !isOpen)}
                            />
                        )}
                        <Collapse isOpened={!hasLabel || this.state.settingSectionsVisibilityMap.get(section.id)!}>
                            {this.getSettingItemViews(section.items)}
                        </Collapse>
                    </ListContainer>
                );
            });
    }

    private subscribeToSettingItems(): void {
        let subscription = this.viewModel
            .getSectionIds()
            .pipe(
                RxOperators.switchMap((ids) => Rx.of(ids)),
                RxOperators.switchMap((section) =>
                    Rx.zip(Rx.of(section), this.viewModel.getSettingItems(section.id, this, this)),
                ),
                RxOperators.toArray(),
            )
            .subscribe((sections) => {
                this.setState({
                    settingSections: sections.map(([section, items]) => ({
                        id: section.id,
                        label: section.label,
                        items: items.flatMap((m) => Array.from(m)),
                    })),
                });
            });
        this.collectSubscription(subscription);

        subscription = this.viewModel.settingSectionsVisibilities.subscribe((visibilities) => {
            this.setState({ settingSectionsVisibilityMap: visibilities });
        });
        this.collectSubscription(subscription);
    }

    private getSettingItemViews(items: SettingItemViewModel[]): JSX.Element[] {
        return items.map((item) => {
            return (
                <ListRow key={item.title} onClick={item instanceof ActionItemViewModel ? item.onClick : undefined}>
                    <span>
                        {item.icon && <IconContainer>{item.icon.render(item.title)}</IconContainer>}
                        {item instanceof ActionItemViewModel ? (
                            <LabelContainer title={item.tooltip}>{item.title}</LabelContainer>
                        ) : (
                            <label title={item.tooltip ?? item.title}>{item.title}</label>
                        )}
                    </span>
                    {item instanceof IconSwitchItemViewModel && (
                        <IconSwitchContainer>
                            {item.actions.map((action) => (
                                <IconSwitch {...action} key={action.key} />
                            ))}
                        </IconSwitchContainer>
                    )}
                    {item instanceof ActionItemViewModel && item.observableValue && (
                        <TextController observableValue={item.observableValue} />
                    )}
                    {item instanceof SwitchItemViewModel && (
                        <ToggleSwitch observableValue={item.observableValue} onChange={item.onChange} />
                    )}
                    {item instanceof PlusMinusItemViewModel && (
                        <PlusMinusController
                            observableValue={item.observableValue}
                            onChange={item.onChange}
                            step={item.step}
                            range={item.range}
                            formatter={item.formatter}
                        />
                    )}
                    {item instanceof SelectItemViewModel && (
                        <FormRoundSelect
                            options={item.options}
                            selectedOptionId={item.initialOption?.id || null}
                            observableSelectedOptionId={item.observableValue.pipe(
                                RxOperators.map((o) => o?.id ?? null),
                            )}
                            onChange={item.onChange}
                            truncate
                        />
                    )}
                </ListRow>
            );
        });
    }

    private close(): void {
        if (!this.drawer.current || !this.screenDimmer.current) {
            return;
        }
        this.screenDimmer.current.hide();
        this.drawer.current.close(() => {
            this.props.onClosed();
        });
    }
}
