import * as mapboxgl from "mapbox-gl";
import { Radar, BlankingSector } from "../../../domain/model";
import { BlankingSectorsPreviewImage } from "./BlankingSectorsPreviewImage";
import turfBbox from "@turf/bbox";
import turfCircle from "@turf/circle";

const BLANKING_SECTORS_PREVIEW_SOURCE_ID_PREFIX = "source-radar-blanking-sector-preview-";
export const BLANKING_SECTORS_PREVIEW_LAYER_ID_PREFIX = "layer-radar-blanking-sector-preview-";

const DEFAULT_RADAR_RANGE_METERS = 1000;

export class RadarBlankingSectorsPreviewLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): RadarBlankingSectorsPreviewLayer {
        return new RadarBlankingSectorsPreviewLayer(map, orderLayer);
    }

    // Properties

    private images = new Map<string, BlankingSectorsPreviewImage>();
    private isEnabled = false;

    private constructor(private readonly map: mapboxgl.Map, private orderLayer: string) {}

    // Public functions

    public setRadarsAndSectors(radarsAndSectors: [Radar, BlankingSector[]][]): void {
        radarsAndSectors.forEach(([radar, blankingSectors]) => this.addOrUpdateSourceForRadar(radar, blankingSectors));
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.isEnabled = enabled;
        this.images.forEach((_, key) => {
            this.map.setLayoutProperty(BLANKING_SECTORS_PREVIEW_LAYER_ID_PREFIX + key, "visibility", visibility);
        });
    }

    // Private functions

    private addOrUpdateSourceForRadar(radar: Radar, blankingSectors: BlankingSector[]): void {
        const radarId = radar.id.toString();
        const sourceId = BLANKING_SECTORS_PREVIEW_SOURCE_ID_PREFIX + radarId;
        const coords = this.getRadarShapeCoordinatesFromRadar(radar);
        // It's already setup, update the source
        if (this.images.has(radarId)) {
            this.images.get(radarId)?.update(blankingSectors);
            const source = this.map.getSource(sourceId) as mapboxgl.CanvasSource;
            source.setCoordinates(coords);
            source.play();
            source.pause();
            return;
        }

        // We don't have a source, create one and keep a reference to its image so we can update it later
        const image = new BlankingSectorsPreviewImage(this.getBlankingSectorImageSize() / 2);
        image.update(blankingSectors);
        this.images.set(radarId, image);

        this.map.addSource(sourceId, {
            type: "canvas",
            canvas: image.getCanvas(),
            coordinates: coords,
            animate: false,
        });
        this.map.addLayer(
            {
                id: BLANKING_SECTORS_PREVIEW_LAYER_ID_PREFIX + radar.id,
                type: "raster",
                source: sourceId,
                paint: {
                    "raster-fade-duration": 0, // Ensure no fade in/out on re-rendering
                },
                layout: {
                    visibility: this.isEnabled ? "visible" : "none",
                },
            },
            this.orderLayer,
        );
    }

    private getBlankingSectorImageSize(): number {
        return Math.floor(Math.min(window.innerWidth, window.innerHeight));
    }

    private getRadarShapeCoordinatesFromRadar(radar: Radar): number[][] {
        const radarRadiusCircle = turfCircle(
            [radar.position.longitude, radar.position.latitude],
            radar.orientation.range || DEFAULT_RADAR_RANGE_METERS,
            { units: "meters" },
        );
        const bbox = turfBbox(radarRadiusCircle);
        return [
            [bbox[0], bbox[3]],
            [bbox[2], bbox[3]],
            [bbox[2], bbox[1]],
            [bbox[0], bbox[1]],
        ];
    }
}
