// source: gridanalysis3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
goog.exportSymbol('proto.robin.proto3.gridanalysis.GridAnalysis', null, global);
goog.exportSymbol('proto.robin.proto3.gridanalysis.GridAnalysisData', null, global);
goog.exportSymbol('proto.robin.proto3.gridanalysis.GridAnalysisDataChunk', null, global);
goog.exportSymbol('proto.robin.proto3.gridanalysis.GridAnalysisDataRequest', null, global);
goog.exportSymbol('proto.robin.proto3.gridanalysis.GridAnalysisList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.gridanalysis.GridAnalysisDataChunk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.displayName = 'proto.robin.proto3.gridanalysis.GridAnalysisDataChunk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.gridanalysis.GridAnalysisList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.gridanalysis.GridAnalysisList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.gridanalysis.GridAnalysisList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.gridanalysis.GridAnalysisList.displayName = 'proto.robin.proto3.gridanalysis.GridAnalysisList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.gridanalysis.GridAnalysis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.gridanalysis.GridAnalysis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.gridanalysis.GridAnalysis.displayName = 'proto.robin.proto3.gridanalysis.GridAnalysis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.gridanalysis.GridAnalysisData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.gridanalysis.GridAnalysisData.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.gridanalysis.GridAnalysisData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.gridanalysis.GridAnalysisData.displayName = 'proto.robin.proto3.gridanalysis.GridAnalysisData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.gridanalysis.GridAnalysisDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.displayName = 'proto.robin.proto3.gridanalysis.GridAnalysisDataRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowindex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    colindex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trackcountValuesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    vectorValuesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    vectorLengthList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.gridanalysis.GridAnalysisDataChunk;
  return proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setRowindex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setColindex(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTrackcountValues(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVectorValues(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addVectorLength(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowindex();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getColindex();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getTrackcountValuesList();
  if (f.length > 0) {
    writer.writePackedSint32(
      3,
      f
    );
  }
  f = message.getVectorValuesList();
  if (f.length > 0) {
    writer.writePackedSint32(
      4,
      f
    );
  }
  f = message.getVectorLengthList();
  if (f.length > 0) {
    writer.writePackedSint32(
      5,
      f
    );
  }
};


/**
 * optional sint32 rowindex = 1;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.getRowindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.setRowindex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 colindex = 2;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.getColindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.setColindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated sint32 trackcount_values = 3;
 * @return {!Array<number>}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.getTrackcountValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.setTrackcountValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.addTrackcountValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.clearTrackcountValuesList = function() {
  return this.setTrackcountValuesList([]);
};


/**
 * repeated sint32 vector_values = 4;
 * @return {!Array<number>}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.getVectorValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.setVectorValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.addVectorValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.clearVectorValuesList = function() {
  return this.setVectorValuesList([]);
};


/**
 * repeated sint32 vector_length = 5;
 * @return {!Array<number>}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.getVectorLengthList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.setVectorLengthList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.addVectorLength = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.prototype.clearVectorLengthList = function() {
  return this.setVectorLengthList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.gridanalysis.GridAnalysisList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridanalysisList: jspb.Message.toObjectList(msg.getGridanalysisList(),
    proto.robin.proto3.gridanalysis.GridAnalysis.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisList}
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.gridanalysis.GridAnalysisList;
  return proto.robin.proto3.gridanalysis.GridAnalysisList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisList}
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.gridanalysis.GridAnalysis;
      reader.readMessage(value,proto.robin.proto3.gridanalysis.GridAnalysis.deserializeBinaryFromReader);
      msg.addGridanalysis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.gridanalysis.GridAnalysisList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGridanalysisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.gridanalysis.GridAnalysis.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GridAnalysis gridanalysis = 1;
 * @return {!Array<!proto.robin.proto3.gridanalysis.GridAnalysis>}
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.prototype.getGridanalysisList = function() {
  return /** @type{!Array<!proto.robin.proto3.gridanalysis.GridAnalysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.gridanalysis.GridAnalysis, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.gridanalysis.GridAnalysis>} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisList} returns this
*/
proto.robin.proto3.gridanalysis.GridAnalysisList.prototype.setGridanalysisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis}
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.prototype.addGridanalysis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.gridanalysis.GridAnalysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisList} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisList.prototype.clearGridanalysisList = function() {
  return this.setGridanalysisList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.gridanalysis.GridAnalysis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysis.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    center: (f = msg.getCenter()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    startTimestampMsec: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cellresolution: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timeresolution: jspb.Message.getFieldWithDefault(msg, 7, 0),
    trackcountGridcolor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    trackcountGridmaxvalue: jspb.Message.getFieldWithDefault(msg, 9, 0),
    trackcountGridcolormap: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.gridanalysis.GridAnalysis;
  return proto.robin.proto3.gridanalysis.GridAnalysis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 4:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setCenter(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartTimestampMsec(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setCellresolution(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTimeresolution(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackcountGridcolor(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrackcountGridmaxvalue(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrackcountGridcolormap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.gridanalysis.GridAnalysis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCenter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getStartTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCellresolution();
  if (f !== 0) {
    writer.writeSint32(
      6,
      f
    );
  }
  f = message.getTimeresolution();
  if (f !== 0) {
    writer.writeSint32(
      7,
      f
    );
  }
  f = message.getTrackcountGridcolor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTrackcountGridmaxvalue();
  if (f !== 0) {
    writer.writeSint32(
      9,
      f
    );
  }
  f = message.getTrackcountGridcolormap();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional sint64 id = 1;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string uuid = 3;
 * @return {string}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional robin.proto3.LatLonAltPosition center = 4;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getCenter = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 4));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
*/
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setCenter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.clearCenter = function() {
  return this.setCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.hasCenter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 start_timestamp_msec = 5;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getStartTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setStartTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional sint32 cellresolution = 6;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getCellresolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setCellresolution = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional sint32 timeresolution = 7;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getTimeresolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setTimeresolution = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string trackcount_gridcolor = 8;
 * @return {string}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getTrackcountGridcolor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setTrackcountGridcolor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional sint32 trackcount_gridmaxvalue = 9;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getTrackcountGridmaxvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setTrackcountGridmaxvalue = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool trackcount_gridcolormap = 10;
 * @return {boolean}
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.getTrackcountGridcolormap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysis} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysis.prototype.setTrackcountGridcolormap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.gridanalysis.GridAnalysisData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.toObject = function(includeInstance, msg) {
  var f, obj = {
    center: (f = msg.getCenter()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    pb_final: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    cellresolution: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    timeresolution: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minrowindex: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxrowindex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mincolindex: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxcolindex: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.gridanalysis.GridAnalysisData;
  return proto.robin.proto3.gridanalysis.GridAnalysisData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setCenter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRange(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCellresolution(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTimeresolution(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMinrowindex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMaxrowindex(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMincolindex(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setMaxcolindex(value);
      break;
    case 9:
      var value = new proto.robin.proto3.gridanalysis.GridAnalysisDataChunk;
      reader.readMessage(value,proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.gridanalysis.GridAnalysisData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getRange();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getFinal();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getCellresolution();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTimeresolution();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getMinrowindex();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getMaxrowindex();
  if (f !== 0) {
    writer.writeSint32(
      6,
      f
    );
  }
  f = message.getMincolindex();
  if (f !== 0) {
    writer.writeSint32(
      7,
      f
    );
  }
  f = message.getMaxcolindex();
  if (f !== 0) {
    writer.writeSint32(
      8,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.robin.proto3.gridanalysis.GridAnalysisDataChunk.serializeBinaryToWriter
    );
  }
};


/**
 * optional robin.proto3.LatLonAltPosition center = 1;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getCenter = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 1));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
*/
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setCenter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.clearCenter = function() {
  return this.setCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.hasCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float range = 2;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool final = 20;
 * @return {boolean}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getFinal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setFinal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional float cellresolution = 3;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getCellresolution = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setCellresolution = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional sint32 timeresolution = 4;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getTimeresolution = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setTimeresolution = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional sint32 minrowindex = 5;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getMinrowindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setMinrowindex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional sint32 maxrowindex = 6;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getMaxrowindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setMaxrowindex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional sint32 mincolindex = 7;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getMincolindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setMincolindex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional sint32 maxcolindex = 8;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getMaxcolindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setMaxcolindex = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated GridAnalysisDataChunk data = 9;
 * @return {!Array<!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk>}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.getDataList = function() {
  return /** @type{!Array<!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.gridanalysis.GridAnalysisDataChunk, 9));
};


/**
 * @param {!Array<!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk>} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
*/
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataChunk}
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.robin.proto3.gridanalysis.GridAnalysisDataChunk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisData} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisData.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    smoothingfactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.gridanalysis.GridAnalysisDataRequest;
  return proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSmoothingfactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSmoothingfactor();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int64 timestamp_msec = 1;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float smoothingfactor = 3;
 * @return {number}
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.getSmoothingfactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.gridanalysis.GridAnalysisDataRequest} returns this
 */
proto.robin.proto3.gridanalysis.GridAnalysisDataRequest.prototype.setSmoothingfactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


goog.object.extend(exports, proto.robin.proto3.gridanalysis);
