import React from "react";
import { TYPES } from "../../di/Types";
import { TileProvidersViewModel } from "./TileProvidersViewModel";
import { Modal, SharedModalProps } from "../appearance/modal/Modal";
import { t } from "i18next";
import { useObservable } from "../../hooks";
import { useViewModel } from "../../hooks/useViewModel";
import { Card, CardGroup } from "../appearance/Card";

export const TileProviders = ({ onClosed }: SharedModalProps): JSX.Element => {
    // Properties

    const viewModel: TileProvidersViewModel = useViewModel(TYPES.TileProvidersViewModel);
    const providers = useObservable(viewModel.tileProviders, []);
    const selectedProviderId = useObservable(viewModel.getSelectedTileProviderId(), 0);

    // Render

    return (
        <Modal title={t("tileProviderSelection.mapType")} onClosed={onClosed}>
            <CardGroup>
                {providers.map((tileProvider) => (
                    <Card
                        label={tileProvider.name}
                        key={tileProvider.id}
                        thumbnailUrl={tileProvider.thumbnailUrl}
                        selected={tileProvider.id === selectedProviderId}
                        onClick={() => viewModel.setSelectedTileProviderId(tileProvider.id)}
                    />
                ))}
            </CardGroup>
        </Modal>
    );
};
