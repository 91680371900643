import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import * as grpcWeb from "grpc-web";
import { ServerURL } from "../../../../infrastructure/ServerURL";
import { Metadata, Error as GrpcError, ClientReadableStream } from "grpc-web";
import {
    LoginRequest,
    LoginResult,
    BasicAuthLoginRequest,
    BasicAuthLoginResult,
    OpenIdConnectLoginRequest,
    OpenIdConnectLoginResult,
} from "../../../../domain/model/proto/generated/loginresult3_pb";
import {
    TrackData,
    TrackDataUpdates,
    ReplayTrackDataRequest,
    TracksHistogram,
    TracksHistogramRequest,
} from "../../../../domain/model/proto/generated/tracklist3_pb";
import {
    RunwayTrafficRequest,
    RunwayTrafficData,
    AirbaseRequest,
    AirbaseData,
    TracksOnRunwayRequest,
    TracksOnRunwayData,
} from "../../../../domain/model/proto/generated/atcinfo3_pb";
import { DateTimeRange, EmptyMsg, StatusMsg } from "../../../../domain/model/proto/generated/common3_pb";
import { RpcServiceClient } from "../../../../domain/model/proto/generated/Rpcservice3ServiceClientPb";
import { ClassificationList } from "../../../../domain/model/proto/generated/classificationlist3_pb";
import {
    GridAnalysisDataRequest,
    GridAnalysisData,
    GridAnalysisList,
} from "../../../../domain/model/proto/generated/gridanalysis3_pb";
import {
    UserPreferenceItem,
    UserPreferences,
    UserPreferencesRequest,
} from "../../../../domain/model/proto/generated/userpreferences3_pb";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { ClientDataProvider } from "../../../../domain/ClientDataProvider";
import { OverlayList } from "../../../../domain/model/proto/generated/overlaylist3_pb";
import { TileProviderResponse, TileProviderRequest } from "../../../../domain/model/proto/generated/background3_pb";
import { RepeatableCallSubscription } from "../../../../utils/RepeatableCallSubscription";
import { ServerConfig } from "../../../../domain/model/proto/generated/serverconfig3_pb";
import {
    DeterrenceCommandMsg,
    DeterrenceDeviceList,
    DeterrenceDeviceUpdateList,
    DeterrenceEventsList,
    DeterrenceEventsListRequest,
} from "../../../../domain/model/proto/generated/deterrencedevices3_pb";
import { LoggerCommandMsg, LoggerSettingsList, TimeMsg } from "../../../../domain/model/proto/generated/logger3_pb";
import { LoggerReportList } from "../../../../domain/model/proto/generated/logger3_pb";
import { LoggerReportRequest } from "../../../../domain/model/proto/generated/logger3_pb";
import {
    AdsbFlightUpdatesCollection,
    AdsbFlightList,
} from "../../../../domain/model/proto/generated/adsbflightlist3_pb";
import { User, UserList, UserRoleList } from "../../../../domain/model/proto/generated/users3_pb";
import {
    GetRequest as BlankingSectorsGetRequest,
    List as BlankingSectorsList,
    SetRequest as BlankingSectorsSetRequest,
} from "../../../../domain/model/proto/generated/blankingsectors3_pb";
import {
    GetStatusRequest as GetRadarStatusRequest,
    Status as RadarStatus,
} from "../../../../domain/model/proto/generated/radar3_pb";

const EMPTY_MESSAGE = new EmptyMsg();

export class BirdViewerGrpcAPI implements BirdViewerAPI {
    // Static properties

    private static readonly deviceType = "DV2-W/" + process.env.REACT_APP_GRPC_REVISION;
    private static readonly defaultCallRepeatTimeInMillisecond = 1_000;
    private static readonly loggerReportListUpdatesCallRepeatInterval = 20_000;
    private static readonly minTimeForNonFixedRepeatInMillisecond = 1_000;
    private static readonly sessionIdHeaderKey = "session-id";

    // Properties

    public get errorObservable(): Rx.Observable<GrpcError> {
        return this.errorSubject.asObservable();
    }
    public get hasSessionId(): boolean {
        return this.metaData != null && this.metaData[BirdViewerGrpcAPI.sessionIdHeaderKey].length > 0;
    }
    private metaData: Metadata = {};
    private readonly client: RpcServiceClient;
    private errorSubject = new Rx.Subject<GrpcError>();

    // Lifecycle

    public constructor(
        url: ServerURL,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly clientDataProvider: ClientDataProvider,
    ) {
        this.client = new RpcServiceClient(url.serverAddress);
        this.setMetadata();
        this.loadSessionId();
    }

    // Public functions

    public clearSessionId(): void {
        this.setMetadata();
        this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.user.sessionId);
    }

    /**
     * @deprecated This method is deprecated and will be removed in the future. Use 'loginBasicAuth' instead.
     */
    public login(request: LoginRequest): Rx.Observable<LoginResult> {
        return this.call((client, metaData, callback) => client.login(request, metaData, callback));
    }

    public loginBasicAuth(request: BasicAuthLoginRequest): Rx.Observable<BasicAuthLoginResult> {
        return this.call((client, metaData, callback) => client.loginBasicAuth(request, metaData, callback));
    }

    public loginOpenIdConnect(request: OpenIdConnectLoginRequest): Rx.Observable<OpenIdConnectLoginResult> {
        return this.call((client, metaData, callback) => client.loginOpenIdConnect(request, metaData, callback));
    }

    public logout(): Rx.Observable<EmptyMsg> {
        return this.call((client, metaData, callback) => client.logout(EMPTY_MESSAGE, metaData, callback));
    }

    public getServerConfig(): Rx.Observable<ServerConfig> {
        return this.call((client, metaData, callback) => client.getServerConfig(EMPTY_MESSAGE, metaData, callback));
    }

    public getClassifications(): Rx.Observable<ClassificationList> {
        return this.call((client, metaData, callback) =>
            client.getClassificationList(EMPTY_MESSAGE, metaData, callback),
        );
    }

    public getInitialTrackData(): Rx.Observable<TrackData> {
        return this.call((client, metaData, callback) => client.getInitialTrackData(EMPTY_MESSAGE, metaData, callback));
    }

    public getTrackDataUpdates(subscription: RepeatableCallSubscription): Rx.Observable<TrackDataUpdates> {
        return this.repeatCallWithNonFixedInterval(
            (client, metaData, callback) => client.getTrackDataUpdates(EMPTY_MESSAGE, metaData, callback),
            subscription,
            (response) => response.getHintnextcallms(),
        );
    }

    public getRunwayTrafficData(request: RunwayTrafficRequest): Rx.Observable<RunwayTrafficData> {
        return this.repeatCall((client, metaData, callback) =>
            client.getRunwayTrafficData(request, metaData, callback),
        );
    }

    public getTracksOnRunwayData(startTimestamp: long, endTimestamp: long): Rx.Observable<TracksOnRunwayData> {
        const request = new TracksOnRunwayRequest();
        if (startTimestamp > 0 && endTimestamp > 0) {
            const date = new DateTimeRange();
            date.setStartmsec(startTimestamp);
            date.setEndmsec(endTimestamp);
            request.setDate(date);
        }
        return this.call((client, metaData, callback) => client.getTracksOnRunwayData(request, metaData, callback));
    }

    public getInitialADSBFlightData(): Rx.Observable<AdsbFlightList> {
        return this.call((client, metaData, callback) =>
            client.getInitialFlightList(EMPTY_MESSAGE, metaData, callback),
        );
    }

    public getADSBFlightDataUpdates(): Rx.Observable<AdsbFlightUpdatesCollection> {
        return this.repeatCall((client, metaData, callback) =>
            client.getFlightListUpdates(EMPTY_MESSAGE, metaData, callback),
        );
    }

    public getAirbaseData(request: AirbaseRequest): Rx.Observable<AirbaseData> {
        return this.call((client, metaData, callback) => client.getAirbaseData(request, metaData, callback));
    }

    public getGridAnalysisData(request: GridAnalysisDataRequest): Rx.Observable<GridAnalysisData> {
        return this.call((client, metaData, callback) => client.getGridAnalysisData(request, metaData, callback));
    }

    public getGridAnalysisList(): Rx.Observable<GridAnalysisList> {
        return this.repeatCall(
            (client, metadata, callback) => client.getGridAnalysisList(EMPTY_MESSAGE, metadata, callback),
            { interval: 60_000 },
        );
    }

    public getUserPreferences(request: UserPreferencesRequest): Rx.Observable<UserPreferences> {
        return this.call((client, metaData, callback) => client.getUserPreferences(request, metaData, callback));
    }

    public setUserPreference(request: UserPreferenceItem): Rx.Observable<StatusMsg> {
        return this.call((client, metaData, callback) => client.setUserPreference(request, metaData, callback));
    }

    public getOverlayList(): Rx.Observable<OverlayList> {
        return this.call((client, metaData, callback) => client.getOverlayList(EMPTY_MESSAGE, metaData, callback));
    }

    public getReplayData(startTimestamp: long, endTimestamp: long): Rx.Observable<TrackData> {
        const request = new ReplayTrackDataRequest();
        request.setStartdateMsec(startTimestamp);
        request.setEnddateMsec(endTimestamp);
        return this.call((client, metaData, callback) => client.getReplayTrackData(request, metaData, callback));
    }

    public getTileProviders(): Rx.Observable<TileProviderResponse> {
        const request = new TileProviderRequest();
        return this.call((client, metaData, callback) => client.getTileProviderList(request, metaData, callback));
    }

    public getDeterrenceDeviceList(): Rx.Observable<DeterrenceDeviceList> {
        return this.call((client, metaData, callback) =>
            client.getDeterrenceDeviceList(EMPTY_MESSAGE, metaData, callback),
        );
    }

    public getDeterrenceDeviceUpdates(
        interval?: int,
        trigger?: Rx.Observable<void>,
    ): Rx.Observable<DeterrenceDeviceUpdateList> {
        return this.repeatCall(
            (client, metaData, callback) => client.getDeterrenceDeviceUpdates(EMPTY_MESSAGE, metaData, callback),
            { interval, trigger },
        );
    }

    public getDeterrenceEventsList(request: DeterrenceEventsListRequest): Rx.Observable<DeterrenceEventsList> {
        return this.call((client, metaData, callback) => client.getDeterrenceEventsList(request, metaData, callback));
    }

    public getDeterrenceEventUpdates(interval?: int): Rx.Observable<DeterrenceEventsList> {
        return this.repeatCall(
            (client, metaData, callback) => client.getDeterrenceEventUpdates(EMPTY_MESSAGE, metaData, callback),
            { interval },
        );
    }

    public handleDeterrenceCommand(request: DeterrenceCommandMsg): Rx.Observable<StatusMsg> {
        return this.call((client, metaData, callback) => client.handleDeterrenceCommand(request, metaData, callback));
    }

    public handleLoggerCommand(request: LoggerCommandMsg): Rx.Observable<StatusMsg> {
        return this.call((client, metaData, callback) => client.handleLoggerCommand(request, metaData, callback));
    }

    public getLoggerSettingsList(): Rx.Observable<LoggerSettingsList> {
        return this.call((client, metaData, callback) =>
            client.getLoggerSettingsList(EMPTY_MESSAGE, metaData, callback),
        );
    }

    public getServerTime(): Rx.Observable<TimeMsg> {
        return this.call((client, metaData, callback) => client.getServerTime(EMPTY_MESSAGE, metaData, callback));
    }

    public getLoggerReportList(request: LoggerReportRequest): Rx.Observable<LoggerReportList> {
        return this.call((client, metaData, callback) => client.getLoggerReportList(request, metaData, callback));
    }

    public getLoggerReportUpdates(): Rx.Observable<LoggerReportList> {
        return this.repeatCall(
            (client, metaData, callback) => client.getLoggerReportUpdates(EMPTY_MESSAGE, metaData, callback),
            { interval: BirdViewerGrpcAPI.loggerReportListUpdatesCallRepeatInterval },
        );
    }

    public getUserList(): Rx.Observable<UserList> {
        return this.call((client, metaData, callback) => client.getUsers(EMPTY_MESSAGE, metaData, callback));
    }
    public getRolesList(): Rx.Observable<UserRoleList> {
        return this.call((client, metaData, callback) => client.getUserRoles(EMPTY_MESSAGE, metaData, callback));
    }
    public addUser(user: User): Rx.Observable<EmptyMsg> {
        return this.call((client, metaData, callback) => client.addUser(user, metaData, callback));
    }
    public editUser(user: User): Rx.Observable<EmptyMsg> {
        return this.call((client, metaData, callback) => client.editUser(user, metaData, callback));
    }
    public deleteUser(user: User): Rx.Observable<EmptyMsg> {
        return this.call((client, metaData, callback) => client.deleteUser(user, metaData, callback));
    }

    public getBlankingSectors(request: BlankingSectorsGetRequest): Rx.Observable<BlankingSectorsList> {
        return this.call((client, metaData, callback) => client.getBlankingSectors(request, metaData, callback));
    }
    public setBlankingSectors(request: BlankingSectorsSetRequest): Rx.Observable<EmptyMsg> {
        return this.call((client, metaData, callback) => client.setBlankingSectors(request, metaData, callback));
    }

    public getTracksHistogram(request: TracksHistogramRequest): Rx.Observable<TracksHistogram> {
        return this.call((client, metaData, callback) => client.getTracksHistogram(request, metaData, callback));
    }

    public getRadarStatus(request: GetRadarStatusRequest): Rx.Observable<RadarStatus> {
        return this.call((client, metaData, callback) => client.getRadarStatus(request, metaData, callback));
    }

    // Private functions

    private call<ResponseType>(action: CallAction<ResponseType>): Rx.Observable<ResponseType> {
        return new Rx.Observable((subscriber) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const stream: any = action(this.client, this.metaData, (error, response) => {
                if (error != null || response == null) {
                    subscriber.error(error);
                    this.errorSubject.next(error);
                } else {
                    this.processResponseHeaders(stream.a.a);
                    subscriber.next(response);
                    subscriber.complete();
                }
            });
        });
    }

    private repeatCall<ResponseType>(
        action: CallAction<ResponseType>,
        options?: {
            interval?: int;
            trigger?: Rx.Observable<void>;
        },
    ): Rx.Observable<ResponseType> {
        const period = options?.interval || BirdViewerGrpcAPI.defaultCallRepeatTimeInMillisecond;
        const intervalSource = Rx.interval(period).pipe(RxOperators.startWith(0));
        const source = options?.trigger ? Rx.merge(intervalSource, options.trigger) : intervalSource;
        return source.pipe(RxOperators.mergeMap(() => this.call(action)));
    }

    private repeatCallWithNonFixedInterval<ResponseType>(
        action: CallAction<ResponseType>,
        subscription: RepeatableCallSubscription,
        getDelayClosure: (response: ResponseType) => int,
    ): Rx.Observable<ResponseType> {
        const subjectSource = new Rx.Subject<ResponseType>();
        this.repeatTask(subjectSource, 0, action, subscription, getDelayClosure);
        return subjectSource.asObservable();
    }

    private repeatTask<ResponseType>(
        source: Rx.Subject<ResponseType>,
        delay: int,
        action: CallAction<ResponseType>,
        subscription: RepeatableCallSubscription,
        getDelayClosure: (response: ResponseType) => int,
    ): void {
        subscription.counter = Rx.asyncScheduler.schedule(() => {
            subscription.apiCall = this.call(action).subscribe(
                (value) => {
                    source.next(value);
                    const delay = Math.max(
                        BirdViewerGrpcAPI.minTimeForNonFixedRepeatInMillisecond,
                        getDelayClosure(value),
                    );
                    this.repeatTask(source, delay, action, subscription, getDelayClosure);
                },
                (error) => source.error(error),
            );
        }, delay);
    }

    private processResponseHeaders(xhr: XMLHttpRequest): void {
        const sessionId = xhr.getResponseHeader(BirdViewerGrpcAPI.sessionIdHeaderKey);
        if (sessionId) {
            this.saveSessionId(sessionId);
            this.loadSessionId();
        }
    }

    private loadSessionId(): void {
        const sessionId = this.localPreferencesRepository.getPreference<string>(LocalUserPreferenceKeys.user.sessionId);
        if (sessionId) {
            this.setMetadata(sessionId);
        }
    }

    private saveSessionId(sessionId: string): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.sessionId, sessionId);
    }

    private setMetadata(sessionId: string | null = null): void {
        this.metaData = this.getHeaderMetadata(sessionId);
    }

    private getHeaderMetadata(sessionId: string | null = null): Metadata {
        const metaData: Metadata = {
            "device-type": BirdViewerGrpcAPI.deviceType,
            "device-id": this.clientDataProvider.getDeviceId(),
            "client-id": this.clientDataProvider.getClientId(),
        };
        metaData[BirdViewerGrpcAPI.sessionIdHeaderKey] = sessionId ? sessionId : "";
        return metaData;
    }
}

type CallAction<T> = (
    client: RpcServiceClient,
    metaData: Metadata,
    callback: (error: GrpcError, response: T) => void,
) => ClientReadableStream<T>;

export function isUnimplementedError(error: grpcWeb.Error): boolean {
    return error.code === grpcWeb.StatusCode.UNIMPLEMENTED;
}
