import React from "react";
import styled from "styled-components";
import { TYPES } from "../../../../di/Types";
import { PanelSubtitle } from "../../../appearance/panels/PanelSubtitle";
import { PanelParagraph } from "../../../appearance/panels/PanelText";
import { t } from "i18next";
import { VehicleInfoViewModel } from "./VehicleInfoViewModel";
import { DEFAULT_VEHICLE_VELOCITY_UNIT } from "../../../../domain/model/VelocityUnit";
import { formatVelocity } from "../../../../utils/Presentation";
import { Compass } from "./Compass";
import { useViewModel } from "../../../../hooks/useViewModel";
import { RadioSwitch, RadioSwitchGroup } from "../../../appearance/RadioSwitch";
import { useObservable } from "../../../../hooks";
import { ScrollableSidebarContent } from "../../../appearance/Sidebar";

const RootContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 240px;
    overflow: hidden;
`;

const ColumnContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-gap: 10px;
    align-items: center;
`;

const VehicleInfoStyle = styled.div`
    width: 170px;
`;

export const VehicleInfo = (): JSX.Element => {
    // Properties

    const viewModel: VehicleInfoViewModel = useViewModel(TYPES.VehicleInfoViewModel);
    const radarMotionData = useObservable(viewModel.radarMotionData, undefined);
    const vehicleVelocityUnit = useObservable(viewModel.vehicleVelocityUnit, DEFAULT_VEHICLE_VELOCITY_UNIT);
    const lockMapToVehicleOrientation = useObservable(viewModel.isMapOrientationLockedToVehicle, false);

    // Local functions

    const roundValue = (value: number): number => Math.round(value * 100) / 100;

    // Render

    return (
        <ScrollableSidebarContent>
            <RootContainer>
                <ColumnContainer>
                    <VehicleInfoStyle>
                        <PanelSubtitle>{t("vehicleInfo.vehicleInfo")}</PanelSubtitle>
                        {radarMotionData ? (
                            <PanelParagraph>
                                {t("vehicleInfo.speed")}:{" "}
                                {formatVelocity(radarMotionData.speed, {
                                    velocityUnit: vehicleVelocityUnit,
                                    displayUnit: true,
                                })}
                                <br />
                                {t("vehicleInfo.pitchAndRoll")}: {roundValue(radarMotionData.pitchDeg)}
                                {t("unit.degreeSymbol")} / {roundValue(radarMotionData.rollDeg)}
                                {t("unit.degreeSymbol")}
                                <br />
                                {t("vehicleInfo.heading")}: {roundValue(radarMotionData.headingDeg)}
                                {t("unit.degreeSymbol")}
                            </PanelParagraph>
                        ) : (
                            <PanelParagraph>{t("vehicleInfo.notReceivingMotionData")}</PanelParagraph>
                        )}
                    </VehicleInfoStyle>
                    <Compass rotation={radarMotionData ? radarMotionData.headingDeg : undefined} />
                </ColumnContainer>
            </RootContainer>
            <PanelSubtitle>{t("vehicleInfo.mapOrientation")}</PanelSubtitle>
            <RadioSwitchGroup>
                <RadioSwitch
                    active={!lockMapToVehicleOrientation}
                    onClick={() => viewModel.changeMapOrientationLock(false)}
                >
                    {t("vehicleInfo.mapOrientationManual")}
                </RadioSwitch>
                <RadioSwitch
                    active={lockMapToVehicleOrientation}
                    onClick={() => viewModel.changeMapOrientationLock(true)}
                >
                    {t("vehicleInfo.mapOrientationVehicle")}
                </RadioSwitch>
            </RadioSwitchGroup>
        </ScrollableSidebarContent>
    );
};
