import React from "react";
import ReactDOM from "react-dom";
import { App } from "./apps/birdviewer/App";
import { BirdViewerDependencyProvider } from "./apps/birdviewer/BirdViewerDependencyProvider";
import reportWebVitals from "./reportWebVitals";

// import i18n (needs to be bundled ;))
import "./infrastructure/localization/i18n";

new BirdViewerDependencyProvider().setup();
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
