import { SelectItemViewModel } from "../../SettingItemViewModel";
import TrackLabelIcon from "../../../../res/images/settings/track_label.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { convertLabelToOption, LocalUserPreferenceKeys, TrackLabel } from "../../../../domain/model";
import * as RxOperators from "rxjs";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { t } from "i18next";

export class TrackLabelSettingItemViewModel extends SelectItemViewModel {
    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        altitudeInfoAvailable: boolean,
    ) {
        super(
            t("settings.trackLabel"),
            SettingItemIcon.withUrl(TrackLabelIcon),
            nonNullObservable(
                localPreferencesRepository.observePreference<TrackLabel>(LocalUserPreferenceKeys.filters.trackLabel),
            ).pipe(RxOperators.map(convertLabelToOption)),
            (altitudeInfoAvailable
                ? [TrackLabel.ALTITUDE, TrackLabel.GROUND_SPEED, TrackLabel.NONE]
                : [TrackLabel.GROUND_SPEED, TrackLabel.NONE]
            ).map(convertLabelToOption),
            localPreferencesRepository.getPreference(LocalUserPreferenceKeys.filters.trackLabel),
            (value) => this.setValue(value),
        );
    }

    // Private functions

    private setValue(value: string): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.filters.trackLabel, value);
    }
}
