export enum AlarmDataType {
    AREA_ENTRY = "area-entry",
    DRONE = "drone",
    RUNWAY_CROSSING_REACHED_THRESHOLD = "runway-crossing-reached-threshold",
    FUNNEL_TRAFFIC_REACHED_THRESHOLD = "funnel-traffic-reached-threshold",
}

interface TrackAreaEntryAlarmData {
    readonly type: AlarmDataType.AREA_ENTRY;
    readonly overlayIds: string[];
    readonly trackId: int;
}

interface TrackIsDroneAlarmData {
    readonly type: AlarmDataType.DRONE;
    readonly trackId: int;
}

interface RunwayCrossingReachedThresholdAlarmData {
    readonly type: AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD;
}

interface FunnelTrafficReachedThreshold {
    readonly type: AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD;
    readonly trackIds: int[];
}

export type AlarmData =
    | TrackAreaEntryAlarmData
    | TrackIsDroneAlarmData
    | RunwayCrossingReachedThresholdAlarmData
    | FunnelTrafficReachedThreshold;
