import React from "react";
import { RadarLocation } from "../../domain/model/RadarLocation";
import { t } from "i18next";
import { Modal, SharedModalProps } from "../appearance/modal/Modal";
import { Card, CardGroup } from "../appearance/Card";

interface Props extends SharedModalProps {
    locations: RadarLocation[];
}

export const RadarLocationList = ({ locations, onClosed }: Props): JSX.Element => {
    // Properties

    // Local functions

    const navigateToLocation = (url: string): void => {
        if (url.length > 0) {
            window.location.href = url;
        }
    };

    const checkIfLocationIsCurrent = (url: string): boolean => {
        return window.location.href.startsWith(url);
    };

    // Public functions

    return (
        <Modal title={t("radarLocationList.radarLocations")} onClosed={onClosed}>
            <CardGroup>
                {locations.length > 0 ? (
                    locations.map((location, index) => (
                        <Card
                            label={`${t("radarLocationList.location")} ${index + 1}`}
                            onClick={() => navigateToLocation(location.url)}
                            selected={checkIfLocationIsCurrent(location.url)}
                            smallLabel={location.name}
                            key={index}
                        />
                    ))
                ) : (
                    <p>No locations configured</p>
                )}
            </CardGroup>
        </Modal>
    );
};
