import * as Rx from "rxjs";

export class MapModuleViewModel {
    // Properties

    private subscriptions: Rx.Subscription = new Rx.Subscription();

    // Public functions

    public setup(): void {}

    public dispose(): void {
        this.subscriptions.unsubscribe();
    }

    public collectSubscription(subscription: Rx.Subscription): void {
        if (this.subscriptions.closed) {
            this.subscriptions = new Rx.Subscription();
        }
        this.subscriptions.add(subscription);
    }

    public collectSubscriptions(...subscriptions: Rx.Subscription[]): void {
        subscriptions.forEach((subscription) => this.collectSubscription(subscription));
    }
}
