export function parseNumberOrZero(string: string): number {
    const value = parseFloat(string);
    if (isNaN(value)) {
        return 0;
    }
    return value;
}

export function parseNumberOrNull(string: string): number | null {
    const value = parseFloat(string);
    if (isNaN(value)) {
        return null;
    }
    return value;
}

export function kebabify(s: string): string {
    return replaceAll(s.toLowerCase(), "_", "-");
}

export function replaceAll(s: string, search: string, replacement: string): string {
    return s.replace(new RegExp(search, "g"), replacement);
}

export function uppercasedFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
}
