import { SwitchItemViewModel } from "../../SettingItemViewModel";
import FunnelViewParkingCorridorIcon from "../../../../res/images/settings/funnel_view_parking_corridor.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { t } from "i18next";
import { nonNullObservable } from "../../../../utils/RxUtils";

export class FunnelViewParkingCorridorSettingItemViewModel extends SwitchItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.showFunnelParkingCorridor"),
            SettingItemIcon.withUrl(FunnelViewParkingCorridorIcon),
            nonNullObservable(
                localPreferencesRepository.observePreference<boolean>(
                    LocalUserPreferenceKeys.charts.showFunnelViewParkingCorridor,
                ),
            ),
            (value) => {
                localPreferencesRepository.setPreference(
                    LocalUserPreferenceKeys.charts.showFunnelViewParkingCorridor,
                    value,
                );
            },
            [],
            undefined,
        );
    }
}
