import { ADSBUtils } from "../utils/ADSBUtils";
import { SnapshotDiffCalculator } from "./SnapshotDiffCalculator";
import { ADSBFlight } from "./model";
import { LocalPreferencesRepository } from "./repositories";
import { ADSBFlightRepository } from "./repositories/ADSBFlightRepository";

export class ADSBFlightsSnapshotDiffCalculator extends SnapshotDiffCalculator<ADSBFlight> {
    public constructor(
        adsbFlightRepository: ADSBFlightRepository,
        localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super(
            adsbFlightRepository.flightsSnapshot,
            adsbFlightRepository.selectedFlightId,
            localPreferencesRepository,
            (t) => ADSBUtils.getFlightEndTime(t),
            (t, timestamp, lifeTime) => ADSBUtils.getADSBFlightEstimatesWithinPeriod(t, timestamp, lifeTime).length > 0,
        );
    }
}
