import * as mapboxgl from "mapbox-gl";
import { Feature, Position } from "geojson";
import { Colors, OldColors } from "../../appearance/Colors";
import { MeasurementsData } from "../modules/measurements/MeasurementsModuleViewModel";
import {
    EMPTY_FEATURE_COLLECTION,
    EMPTY_GEOJSON_SOURCE,
    getMetersPerPixel,
    loadImageFromSVG,
} from "../../../utils/MapUtils";
import turfAlong from "@turf/along";
import turfDistance from "@turf/distance";
import * as turfHelpers from "@turf/helpers";
import { generateObservationSymbol } from "./ObservationSymbols";
import { InteractiveMapLayer } from "./InteractiveMapLayer";

const MEASUREMENTS_LAYER_ID = "layer-measurements";
const MEASUREMENTS_SHADOW_LAYER_ID = "layer-measurements-shadow";
const MEASUREMENTS_DISTANCE_LAYER_ID = "layer-measurements-distance";
const MEASUREMENTS_NODES_LAYER_ID = "layer-measurements-nodes";
const MEASUREMENTS_NODES_SHADOW_LAYER_ID = "layer-measurements-nodes-shadow";
const MEASUREMENTS_NODES_SYMBOL_ID = "symbol-measurements-nodes";
const MEASUREMENTS_SOURCE_ID = "source-measurements";
const NODE_CIRCLE_RADIUS = 18;

interface Styling {
    lineWidth: number;
    lineShadowWidth: number;
    lineOpacityDefault: number;
    lineColor: string;
    lineShadowColor: string;
    labelTextSizePx: number;
    labelTextColor: string;
}

const defaultStyling = {
    lineWidth: 1,
    lineShadowWidth: 2,
    lineOpacityDefault: 0.6,
    lineColor: Colors.secondary.white,
    lineShadowColor: OldColors.black,
    labelTextSizePx: 14,
    labelTextColor: OldColors.textSecondary,
};

export class MeasurementsLayer extends InteractiveMapLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): MeasurementsLayer {
        return new MeasurementsLayer(map, orderLayer);
    }

    // Properties

    private measurementsData: MeasurementsData | null = null;

    private constructor(
        readonly map: mapboxgl.Map,
        private orderLayer: string,
        private readonly styling: Styling = defaultStyling,
    ) {
        super(map);
        this.setup();
    }

    // Public functions

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        [
            MEASUREMENTS_LAYER_ID,
            MEASUREMENTS_SHADOW_LAYER_ID,
            MEASUREMENTS_DISTANCE_LAYER_ID,
            MEASUREMENTS_NODES_LAYER_ID,
            MEASUREMENTS_NODES_SHADOW_LAYER_ID,
        ].forEach((layerId) => this.map.setLayoutProperty(layerId, "visibility", visibility));
    }

    public updateMeasurementsData(measurementsData: MeasurementsData | null): void {
        this.measurementsData = measurementsData;
        const source = this.map.getSource(MEASUREMENTS_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        if (measurementsData == null) {
            source.setData(EMPTY_FEATURE_COLLECTION);
            return;
        }
        source.setData({
            type: "FeatureCollection",
            features: this.getFeaturesFromMeasurementsData(measurementsData),
        });
    }

    // Private functions

    private setup(): void {
        this.map.addSource(MEASUREMENTS_SOURCE_ID, EMPTY_GEOJSON_SOURCE);
        loadImageFromSVG(generateObservationSymbol({ color: this.styling.lineShadowColor }), (image) =>
            this.map.addImage(MEASUREMENTS_NODES_SYMBOL_ID, image),
        );
        this.map.addLayer(
            {
                id: MEASUREMENTS_SHADOW_LAYER_ID,
                type: "line",
                source: MEASUREMENTS_SOURCE_ID,
                filter: ["==", "$type", "LineString"],
                layout: {
                    visibility: "none",
                },
                paint: {
                    "line-color": this.styling.lineShadowColor,
                    "line-width": this.styling.lineWidth + this.styling.lineShadowWidth,
                    "line-opacity": this.styling.lineOpacityDefault,
                },
            },
            this.orderLayer,
        );
        this.map.addLayer(
            {
                id: MEASUREMENTS_LAYER_ID,
                type: "line",
                source: MEASUREMENTS_SOURCE_ID,
                filter: ["==", "$type", "LineString"],
                layout: {
                    visibility: "none",
                },
                paint: {
                    "line-color": this.styling.lineColor,
                    "line-width": this.styling.lineWidth,
                    "line-opacity": this.styling.lineOpacityDefault,
                },
            },
            this.orderLayer,
        );
        this.map.addLayer(
            {
                id: MEASUREMENTS_DISTANCE_LAYER_ID,
                type: "symbol",
                source: MEASUREMENTS_SOURCE_ID,
                filter: ["==", "$type", "LineString"],
                layout: {
                    visibility: "none",
                    "text-field": ["get", "distance"],
                    "symbol-placement": "line-center",
                    "text-size": this.styling.labelTextSizePx,
                    "text-allow-overlap": true,
                    "text-anchor": "top",
                    "text-letter-spacing": ["interpolate", ["linear"], ["zoom"], 0, 0, 10, 0.1, 16, 0.15],
                    "text-justify": "center",
                },
                paint: {
                    "text-color": this.styling.labelTextColor,
                },
            },
            this.orderLayer,
        );
        this.map.addLayer(
            {
                id: MEASUREMENTS_NODES_SHADOW_LAYER_ID,
                type: "circle",
                source: MEASUREMENTS_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                paint: {
                    "circle-opacity": 0,
                    "circle-radius": NODE_CIRCLE_RADIUS - (this.styling.lineWidth + this.styling.lineShadowWidth) / 2,
                    "circle-stroke-color": this.styling.lineShadowColor,
                    "circle-stroke-width": this.styling.lineWidth + this.styling.lineShadowWidth,
                    "circle-stroke-opacity": this.styling.lineOpacityDefault,
                    "circle-pitch-alignment": "map",
                },
            },
            this.orderLayer,
        );
        this.map.addLayer(
            {
                id: MEASUREMENTS_NODES_LAYER_ID,
                type: "circle",
                source: MEASUREMENTS_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                paint: {
                    "circle-opacity": 0,
                    "circle-radius": NODE_CIRCLE_RADIUS - this.styling.lineWidth / 2,
                    "circle-stroke-color": this.styling.lineColor,
                    "circle-stroke-width": this.styling.lineWidth,
                    "circle-stroke-opacity": this.styling.lineOpacityDefault,
                    "circle-pitch-alignment": "map",
                },
            },
            this.orderLayer,
        );
        this.addEventListener({
            type: "zoomend",
            listener: this.onZoomEventListener.bind(this),
        });
    }

    private onZoomEventListener(): void {
        if (this.measurementsData) {
            this.getFeaturesFromMeasurementsData(this.measurementsData);
        }
    }

    private getFeaturesFromMeasurementsData(measurementsData: MeasurementsData): Feature[] {
        const metersPerPixel = getMetersPerPixel(this.map);
        const features: Feature[] = measurementsData.radarPositions.map((radarPosition) =>
            this.getLineFeatureFromPositions(
                radarPosition,
                measurementsData.trackPosition,
                measurementsData.formatDistance,
                metersPerPixel,
            ),
        );
        features.push(
            ...[...measurementsData.radarPositions, measurementsData.trackPosition].map((p) =>
                this.getNodeFeatureFromPosition(p),
            ),
        );
        if (measurementsData.userPosition) {
            features.push(
                this.getLineFeatureFromPositions(
                    measurementsData.userPosition,
                    measurementsData.trackPosition,
                    measurementsData.formatDistance,
                    metersPerPixel,
                ),
            );
            features.push(this.getNodeFeatureFromPosition(measurementsData.userPosition));
        }
        return features;
    }

    private getLineFeatureFromPositions(
        from: Position,
        to: Position,
        formatDistance: (meters: number) => string,
        metersPerPixel: number,
    ): Feature {
        const distance = turfDistance(turfHelpers.point(from), turfHelpers.point(to), { units: "meters" });
        // Offset start and end of line by radius of the node circles
        const lineStart = turfAlong(turfHelpers.lineString([from, to]), metersPerPixel * NODE_CIRCLE_RADIUS, {
            units: "meters",
        });
        const lineEnd = turfAlong(turfHelpers.lineString([to, from]), metersPerPixel * NODE_CIRCLE_RADIUS, {
            units: "meters",
        });
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: [lineStart.geometry.coordinates, lineEnd.geometry.coordinates],
            },
            properties: {
                distance: formatDistance(distance),
            },
        };
    }

    private getNodeFeatureFromPosition(position: Position): Feature {
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: position,
            },
            properties: {},
        };
    }
}
