import * as mapboxgl from "mapbox-gl";
import { Feature, Position } from "geojson";
import * as MapUtils from "../../../utils/MapUtils";
import * as Rx from "rxjs";
import * as turf from "@turf/turf";
import { Colors } from "../../appearance/Colors";
import { DistanceUnit } from "../../../domain/model";
import { DistanceFormatter } from "../../../domain/DistanceFormatter";
import turfCircle from "@turf/circle";

const PROPERTY_FEATURE_LABEL = "feature-label";
const PROPERTY_FEATURE_ID = "feature-id";

interface Styling {
    lineWidth: number;
    lineOpacityDefault: number;
    lineColorLight: string;
    lineColorDark: string;
    labelTextSizePx: number;
    labelTextColor: string;
}

const defaultStyling = {
    lineWidth: 1,
    lineOpacityDefault: 0.6,
    lineColorLight: Colors.secondary.white,
    lineColorDark: Colors.backgrounds.panel,
    labelTextSizePx: 12,
    labelTextColor: Colors.text.text,
};

/**
 * Each range center position has an id so it can be filtered out if necessary.
 */
interface RangeCenterPosition {
    id: number;
    position: Position;
}

export class RangeLayer {
    // Properties

    public readonly symbolLayerId = `${this.layerId}-symbol`;
    public readonly lightLineLayerId = `${this.layerId}-line`;
    public readonly darkLineLayerId = `${this.layerId}-line-dk`;
    private readonly positionsSubject = new Rx.BehaviorSubject<RangeCenterPosition[]>([]);
    private readonly zoomSubject = new Rx.BehaviorSubject<number>(0);
    private unit: DistanceUnit = DistanceUnit.METRIC;

    protected constructor(
        private readonly map: mapboxgl.Map,
        private readonly orderLayer: string,
        readonly sourceId: string,
        private readonly layerId: string,
        private readonly styling: Styling = defaultStyling,
    ) {
        this.setup();
        this.subscribeToParameterUpdates();

        // Update rings after zooming
        this.map.on("zoomend", () => {
            this.updateFeatures(this.positionsSubject.value);
        });

        this.map.on("moveend", () => {
            this.updateFeatures(this.positionsSubject.value);
        });
    }

    // Public functions

    public setDistanceUnit(unit: DistanceUnit): void {
        this.unit = unit;
        this.updateFeatures(this.positionsSubject.value);
    }

    public setCenterPositions(...positions: RangeCenterPosition[]): void {
        this.positionsSubject.next(positions);
    }

    /**
     * Sets the visibility of either all range rings or a subset of range rings.
     * @param enabled If true, show all range rings. If false, hide all range rings. If an array of ids, show only the range rings with those ids.
     */
    public setEnabled(enabled: boolean | number[]): void {
        const isFilterEnabled = Array.isArray(enabled) && enabled.length > 0;
        const filter = isFilterEnabled ? ["match", ["get", PROPERTY_FEATURE_ID], enabled, true, false] : null;
        const visibility = enabled === true || isFilterEnabled ? "visible" : "none";

        this.setLayerVisibilityAndFilter(this.symbolLayerId, visibility, filter);
        this.setLayerVisibilityAndFilter(this.lightLineLayerId, visibility, filter);
        this.setLayerVisibilityAndFilter(this.darkLineLayerId, visibility, filter);
    }

    public setOpacity(opacity: number): void {
        if (opacity < 0.1 || opacity > 0.9) {
            opacity = this.styling.lineOpacityDefault;
        }

        if (this.map.getLayer(this.lightLineLayerId)) {
            this.map.setPaintProperty(this.lightLineLayerId, "line-opacity", opacity);
        }

        if (this.map.getLayer(this.darkLineLayerId)) {
            this.map.setPaintProperty(this.darkLineLayerId, "line-opacity", opacity);
        }

        if (this.map.getLayer(this.symbolLayerId)) {
            this.map.setPaintProperty(this.symbolLayerId, "text-opacity", opacity);
        }
    }

    // Private functions

    private setup(): void {
        this.map.addSource(this.sourceId, MapUtils.EMPTY_GEOJSON_SOURCE);

        this.map.addLayer(
            {
                id: this.lightLineLayerId,
                type: "line",
                source: this.sourceId,
                layout: {
                    visibility: "none",
                },
                paint: {
                    "line-color": this.styling.lineColorLight,
                    "line-width": this.styling.lineWidth,
                    "line-opacity": this.styling.lineOpacityDefault,
                },
            },
            this.orderLayer,
        );

        this.map.addLayer(
            {
                id: this.darkLineLayerId,
                type: "line",
                source: this.sourceId,
                layout: {
                    visibility: "none",
                },
                paint: {
                    "line-color": this.styling.lineColorDark,
                    "line-width": this.styling.lineWidth,
                    "line-opacity": this.styling.lineOpacityDefault,
                    "line-offset": this.styling.lineWidth,
                },
            },
            this.orderLayer,
        );

        this.map.addLayer(
            {
                id: this.symbolLayerId,
                type: "symbol",
                source: this.sourceId,
                layout: {
                    visibility: "none",
                    "text-field": ["get", PROPERTY_FEATURE_LABEL],
                    "symbol-placement": "line",
                    "text-size": this.styling.labelTextSizePx,
                    "text-allow-overlap": true,
                    "text-anchor": "top",
                },
                paint: {
                    "text-color": this.styling.labelTextColor,
                },
            },
            this.orderLayer,
        );
    }

    private subscribeToParameterUpdates(): void {
        Rx.combineLatest([this.positionsSubject, this.zoomSubject]).subscribe(([positions]) => {
            this.updateFeatures(positions);
        });
    }

    private updateFeatures(centers: RangeCenterPosition[]): void {
        const source = this.map.getSource(this.sourceId) as mapboxgl.GeoJSONSource;
        if (!source) {
            return;
        }

        source.setData({
            type: "FeatureCollection",
            features: this.makeMultipleRangeFeatures(centers),
        });
    }

    private mapDistanceUnitToTurfUnit(unit: DistanceUnit): turf.Units {
        return unit === DistanceUnit.METRIC ? "meters" : "feet";
    }

    private makeRangeCircles(center: RangeCenterPosition): Feature[] {
        const units = this.mapDistanceUnitToTurfUnit(this.unit);
        const mapDiagonal = MapUtils.getMinMapDiagonal(this.map, units);
        const maxRadiusStep = mapDiagonal / 6;
        const radiusStep = MapUtils.getRangeRadiusStep(maxRadiusStep, units);
        const radiusArray = MapUtils.getRangeRadiusArrayWithinBounds(this.map, center.position, radiusStep, units);

        return radiusArray
            .map(({ radius, units }) => {
                const label = DistanceFormatter.performFormat(radius, this.unit, this.unit, {
                    simplifyLargeValues: true,
                });
                const feature = turfCircle(center.position, radius, { units });
                const properties = feature.properties || {};
                properties[PROPERTY_FEATURE_LABEL] = label;
                properties[PROPERTY_FEATURE_ID] = center.id;
                return feature;
            })
            .filter((feature) => feature.geometry != null) as Feature[];
    }

    private makeMultipleRangeFeatures(centers: RangeCenterPosition[]): Feature[] {
        return centers.map((center) => this.makeRangeCircles(center)).flat();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private setLayerVisibilityAndFilter(layerId: string, visibility: "visible" | "none", filter: any[] | null): void {
        if (!this.map.getLayer(this.symbolLayerId)) {
            return;
        }
        this.map.setLayoutProperty(layerId, "visibility", visibility);
        this.map.setFilter(layerId, filter);
    }
}
