import React from "react";
import styled from "styled-components";
import { BlankingSector, BlankingSectorsState, Radar } from "../../../domain/model";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { showError } from "../../../utils/MessageUtils";
import { parseNumberOrNull } from "../../../utils/StringUtils";
import { ToggleSwitch } from "../../appearance/ToggleSwitch";
import { Collapse } from "react-collapse";
import _take from "lodash/take";
import _isEqual from "lodash/isEqual";
import Loading from "../../appearance/Loading";
import { InputGroupItem } from "../../appearance/InputGroup";
import { t } from "i18next";
import { Button } from "../../appearance/button/Button";
import { Form } from "../../appearance/forms/Form";
import { ButtonGroup } from "../../appearance/button/ButtonGroup";
import { FormControl } from "../../appearance/forms/FormControl";
import { InputField } from "../../appearance/forms/InputField";

const RADAR_BLANKING_SECTOR_DEFAULT_SPAN = 15;

const ErrorMessage = styled.span`
    font-size: 12px;
    line-height: 1.83;
    color: ${({ theme }) => theme.colors.text.text};
    padding: 8px 0px;
`;

const Sector = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0 0 0;
`;

const SectorToggle = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.x2} 0;
    label {
        flex-grow: 1;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-size: 14px;
        color: white;
    }
`;

const LoadingContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1001;
`;

interface Props {
    maxSectors: Rx.Observable<number>;
    blankingSectors: Rx.Observable<BlankingSector[]>;
    blankingSectorsState: Rx.Observable<BlankingSectorsState>;
    onRequestPreview: (blankingSectors: BlankingSector[]) => void;
    onRequestSave: (blankingSectors: BlankingSector[]) => Rx.Observable<void>;
    radar: Radar;
}

interface State {
    isLoading: boolean;
    canApplyChanges: boolean;
    maxSectors: number;
    blankingSectors: BlankingSector[];
    enabledSectorIndices: number[];
    blankingSectorsError?: string;
}

export class RadarBlankingSectors extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private sectorInputs: React.RefObject<HTMLInputElement>[][];
    private initialBlankingSectors: BlankingSector[] | null = null;

    public constructor(props: Props) {
        super(props);
        this.sectorInputs = [];
        this.state = {
            isLoading: false,
            canApplyChanges: false,
            maxSectors: 0,
            blankingSectors: [],
            enabledSectorIndices: [],
            blankingSectorsError: undefined,
        };
    }

    // Public functions

    public render(): React.ReactNode {
        this.sectorInputs = [];
        if (this.state.blankingSectorsError) {
            return <ErrorMessage>{this.state.blankingSectorsError}</ErrorMessage>;
        }
        if (this.state.blankingSectors.length === 0) {
            return (
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            );
        }
        return this.renderSectors();
    }

    public componentDidMount(): void {
        this.collectSubscriptions(
            Rx.combineLatest([this.props.maxSectors, this.props.blankingSectors]).subscribe({
                next: ([maxSectors, sectors]) => {
                    this.initialBlankingSectors = sectors;
                    this.updateSectors(maxSectors, sectors);
                },
                error: (error) =>
                    this.setState({
                        blankingSectorsError: `${t("radarControlPanel.errorFailedToLoadBlankingSector")}: ${
                            error.message || t("radarControlPanel.errorServerError")
                        }`,
                    }),
            }),
            Rx.combineLatest([this.props.maxSectors, this.props.blankingSectorsState]).subscribe({
                next: ([maxSectors, state]) => this.updateSectors(maxSectors, state.blankingSectors || [], false),
                error: (error) => console.error(error),
            }),
        );
    }

    // Private functions

    private renderSectors(): React.ReactNode {
        return (
            <Form action={"#"} vertical>
                {this.state.blankingSectors.map((sector, index) => this.renderBlankingSectorView(sector, index))}
                {this.state.canApplyChanges && (
                    <ButtonGroup>
                        <Button
                            disabled={this.state.isLoading}
                            loading={this.state.isLoading}
                            label={t("radarControlPanel.applyChanges")}
                            onClick={this.saveChanges.bind(this)}
                        />
                    </ButtonGroup>
                )}
            </Form>
        );
    }

    private renderBlankingSectorView(sector: BlankingSector, index: number): React.ReactNode {
        const startAngleInput: React.RefObject<HTMLInputElement> = React.createRef();
        const endAngleInput: React.RefObject<HTMLInputElement> = React.createRef();

        this.sectorInputs[index] = [];
        this.sectorInputs[index].push(startAngleInput);
        this.sectorInputs[index].push(endAngleInput);

        return (
            <Sector key={index}>
                <SectorToggle>
                    <label>
                        {t("radarControlPanel.sector")} {index + 1}
                    </label>
                    <ToggleSwitch value={sector.enabled} onChange={(checked) => this.toggleSector(index, checked)} />
                </SectorToggle>
                <Collapse isOpened={sector.enabled}>
                    <FormControl>
                        <InputGroupItem label={t("radarControlPanel.blankingSectorStartingAngle")}>
                            <InputField
                                key={index * 10 + 1}
                                disabled={this.state.isLoading}
                                ref={this.sectorInputs[index][0]}
                                value={sector.startAngle.toFixed()}
                                onChange={(e) => this.onSectorStartAngleChanged(e.target.value, index)}
                                type={"number"}
                            />
                        </InputGroupItem>
                        <InputGroupItem label={t("radarControlPanel.blankingSectorEndingAngle")}>
                            <InputField
                                key={index * 10 + 2}
                                disabled={this.state.isLoading}
                                ref={this.sectorInputs[index][1]}
                                value={this.getEndAngle(sector.startAngle, sector.span).toFixed()}
                                onChange={(e) => this.onSectorEndAngleChanged(e.target.value, index)}
                                type={"number"}
                            />
                        </InputGroupItem>
                    </FormControl>
                </Collapse>
            </Sector>
        );
    }

    private updateSectors(maxSectors: number, blankingSectors: BlankingSector[], broadcast = true): void {
        if (maxSectors < 1) {
            this.setState({ blankingSectorsError: t("radarControlPanel.errorRadarHasNoBlankingSectors") });
            return;
        }

        const toBeAdded = maxSectors - blankingSectors.length;
        for (let i = 0; i < toBeAdded; i++) {
            blankingSectors.push(new BlankingSector(0, RADAR_BLANKING_SECTOR_DEFAULT_SPAN, false));
        }
        // In case the server returned more than [maxSectors] number of sectors, remove the extra sectors
        blankingSectors = _take(blankingSectors, maxSectors);
        this.setState({
            maxSectors,
            blankingSectors,
        });

        this.validate(blankingSectors, broadcast);
    }

    private saveChanges(): void {
        const sectors: BlankingSector[] = [...this.state.blankingSectors];
        this.setState({ isLoading: true });
        const subscription = this.props.onRequestSave(sectors).subscribe({
            complete: () => {
                this.setState({ isLoading: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isLoading: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private getEndAngle(startAngle: number, span: number): number {
        const endAngle = Math.floor(startAngle + span);
        return endAngle > 360 ? endAngle % 360 : endAngle;
    }

    private getSpan(startAngle: number, endAngle: number): number {
        const span = endAngle - startAngle;
        return span < 0 ? span + 360 : span;
    }

    private onSectorStartAngleChanged(text: string, sectorIndex: number): void {
        const blankingSectors = [...this.state.blankingSectors];
        let startAngle = parseNumberOrNull(text);
        if (startAngle === null) {
            return;
        }
        startAngle = startAngle < 0 ? startAngle + 360 : startAngle;
        startAngle = startAngle % 360;
        if (startAngle >= 0 && startAngle <= 360) {
            blankingSectors[sectorIndex] = new BlankingSector(startAngle, blankingSectors[sectorIndex].span, true);
        }
        this.setState({ blankingSectors });
        this.validate(blankingSectors);
    }

    private onSectorEndAngleChanged(text: string, sectorIndex: number): void {
        const blankingSectors = [...this.state.blankingSectors];
        let endAngle = parseNumberOrNull(text);
        if (endAngle === null) {
            return;
        }
        endAngle = endAngle < 0 ? endAngle + 360 : endAngle;
        endAngle = endAngle % 360;
        const span = this.getSpan(blankingSectors[sectorIndex].startAngle, endAngle);
        if (span > 0 && span <= 360) {
            blankingSectors[sectorIndex] = new BlankingSector(blankingSectors[sectorIndex].startAngle, span, true);
        }
        this.setState({ blankingSectors });
        this.validate(blankingSectors);
    }

    private validate(sectors: BlankingSector[], requestPreview = true): void {
        const canApplyChanges = !_isEqual(sectors, this.initialBlankingSectors) && this.initialBlankingSectors != null;
        this.setState({ canApplyChanges });

        if (requestPreview) {
            this.props.onRequestPreview(sectors);
        }
    }

    /**
     * Toggle the enabled state of a sector
     * @param index Index of the sector to toggle
     * @param enabled Whether the sector should be enabled or not
     */
    private toggleSector(index: number, enabled: boolean): void {
        const blankingSectors = this.state.blankingSectors;
        const targetSector = blankingSectors[index];
        // Make sure the sector never has a span of 0 (to migrate from old versions)
        const span = targetSector.span === 0 ? RADAR_BLANKING_SECTOR_DEFAULT_SPAN : targetSector.span;
        blankingSectors[index] = new BlankingSector(targetSector.startAngle, span, enabled);

        // Check if there are changes to be saved
        this.setState({ blankingSectors });
        this.validate(blankingSectors);
    }
}
