import * as Rx from "rxjs";
import { OverlayEditorRepository, EditMode, EditState, OverlaySelection } from "../../../domain/repositories";
import { BaseViewModel } from "../../BaseViewModel";

export class OverlayShapeRemoverViewModel extends BaseViewModel {
    // Properties

    public readonly editState: Rx.Observable<EditState> = this.overlayEditorRepository.editState;

    public constructor(public readonly overlayEditorRepository: OverlayEditorRepository) {
        super();
    }

    // Public functions

    public setMode(editMode: EditMode): void {
        this.overlayEditorRepository.setMode(editMode, editMode === EditMode.SELECT ? [] : null);
    }

    public deleteSelection(selection: OverlaySelection[]): void {
        const subscription = this.overlayEditorRepository.deleteShapes(selection).subscribe({
            complete: () => this.setMode(EditMode.SELECT),
        });
        this.collectSubscription(subscription);
    }
}
