import React, { useState } from "react";
import styled from "styled-components";
import { PanelTitle } from "../../../appearance/panels/PanelTitle";
import { generateRadarIcon } from "../../../legend/LegendIcon";
import ReactTooltip from "react-tooltip";
import { t } from "i18next";
import { useViewModel } from "../../../../hooks/useViewModel";
import { TYPES } from "../../../../di/Types";
import { RadarNameViewModel } from "./RadarNameViewModel";
import { useObservable } from "../../../../hooks";
import { INPUT_HEIGHT } from "../../../appearance/theme/Spacing";
import { Icon } from "../../../appearance/Icon";
import { Button } from "../../../appearance/button/Button";
import { InputField } from "../../../appearance/forms/InputField";
import { showError } from "../../../../utils/MessageUtils";

const TOOLTIP_ID = "radar-name-tooltip";

enum RadarNameEditState {
    IDLE,
    ISEDITING,
    ISSAVING,
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x2};
    margin-bottom: ${({ theme }) => theme.spacing.x4};
    width: 240px;
`;

const IconContainer = styled.span`
    margin-left: ${({ theme }) => theme.spacing.x4};
    display: none;
`;

const LeftContainer = styled.div<{ horizontalPadding: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.x1}
        ${({ horizontalPadding, theme }) => (horizontalPadding ? theme.spacing.x2 : "0")};
    gap: ${({ theme }) => theme.spacing.x2};
    width: 171px;
    height: ${INPUT_HEIGHT};
    border-radius: ${({ theme }) => theme.spacing.x2};
    cursor: pointer;

    :hover {
        background-color: ${({ theme }) => theme.colors.interactive.textbuttonHover};

        ${IconContainer} {
            display: inline-block;
        }
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    height: ${INPUT_HEIGHT};
`;

const NameIconContainer = styled(PanelTitle)`
    display: inline-block;
    max-width: 155px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const RadarName = (): JSX.Element | null => {
    // Properties

    const viewModel: RadarNameViewModel = useViewModel(TYPES.RadarNameViewModel);
    const selectedRadar = useObservable(viewModel.selectedRadar, undefined);
    const [radarNameEditState, setRadarNameEditState] = useState<RadarNameEditState>(RadarNameEditState.IDLE);
    const [editValue, setEditValue] = useState<string>("");
    const hasMultipleRadars = useObservable(viewModel.hasMultipleRadars, false);
    const isFirstRadar = useObservable(viewModel.isFirstRadar, false);
    const isLastRadar = useObservable(viewModel.isLastRadar, false);

    // Local functions

    const setIsEditingName = (): void => {
        setEditValue(selectedRadar!.name || "");
        setRadarNameEditState(RadarNameEditState.ISEDITING);
    };

    const exitEditMode = (): void => {
        setRadarNameEditState(RadarNameEditState.IDLE);
        setEditValue("");
    };

    const saveNewName = (): void => {
        if (editValue.length === 0) {
            showError(new Error(t("radarName.errorNameCannotBeEmpty")));
            return;
        }
        const newName = editValue.trim().replaceAll(" ", "_");
        if (newName.length > 50) {
            showError(new Error(t("radarName.errorNameTooLong")));
            return;
        }
        setRadarNameEditState(RadarNameEditState.ISSAVING);
        viewModel.saveRadarName(selectedRadar!.id, newName).subscribe({
            complete: () => exitEditMode(),
            error: (error) => {
                showError(error);
                exitEditMode();
            },
        });
    };

    const RadarIcon = (): React.ReactElement => generateRadarIcon({ isRadarIconProps: true, scale: 0.5 });

    // Render

    if (!selectedRadar) {
        return null;
    }

    const isPreviousButtonDisabled = !hasMultipleRadars || isFirstRadar;
    const isNextButtonDisabled = !hasMultipleRadars || isLastRadar;

    const renderDefaultState = (): JSX.Element => {
        return (
            <>
                <LeftContainer
                    horizontalPadding={true}
                    onClick={() => setIsEditingName()}
                    data-for={TOOLTIP_ID}
                    data-tip={selectedRadar.name}
                    data-iscapture="true"
                >
                    <NameIconContainer>
                        <RadarIcon /> {selectedRadar.name}{" "}
                    </NameIconContainer>
                    <IconContainer>
                        <Icon color="neutral" iconType="PENCIL" />
                    </IconContainer>
                    <ReactTooltip id={TOOLTIP_ID} effect={"solid"} place={"left"} />
                </LeftContainer>
                <RightContainer>
                    <Button
                        disabled={isPreviousButtonDisabled}
                        iconType="CHEVRON_LEFT"
                        color="white"
                        layout="inline"
                        onClick={() => viewModel.goToPreviousRadar()}
                        tooltip={t("radarName.showPreviousRadar")}
                    />
                    <Button
                        disabled={isNextButtonDisabled}
                        iconType="CHEVRON_RIGHT"
                        color="white"
                        layout="inline"
                        onClick={() => viewModel.goToNextRadar()}
                        tooltip={t("radarName.showNextRadar")}
                    />
                </RightContainer>
            </>
        );
    };

    const renderEditState = (): JSX.Element => {
        return (
            <>
                <LeftContainer horizontalPadding={false}>
                    <InputField
                        autoFocus={true}
                        disabled={radarNameEditState === RadarNameEditState.ISSAVING}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditValue(e.target.value)}
                        placeholder={t("radarName.radarName")}
                        type="text"
                        value={editValue}
                    />
                </LeftContainer>
                <RightContainer>
                    <Button
                        disabled={radarNameEditState === RadarNameEditState.ISSAVING}
                        iconType="CHECK"
                        layout="inline"
                        onClick={saveNewName}
                        tooltip={t("radarName.saveRadarName")}
                    />
                    <Button
                        disabled={radarNameEditState === RadarNameEditState.ISSAVING}
                        iconType="CROSS"
                        layout="inline"
                        onClick={exitEditMode}
                        tooltip={t("general.cancel")}
                    />
                </RightContainer>
            </>
        );
    };

    return (
        <Container>
            {radarNameEditState === RadarNameEditState.IDLE ? renderDefaultState() : renderEditState()}
        </Container>
    );
};
