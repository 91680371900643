import React from "react";
import { User } from "../../domain/model/User";
import styled from "styled-components";
import { t } from "i18next";
import { Button } from "../appearance/button/Button";
import { Form } from "../appearance/forms/Form";
import { FormControl } from "../appearance/forms/FormControl";
import { UserListItem } from "./UserListItem";

const ListContainer = styled.div`
    width: 500px;
    min-height: 100px;
    max-height: var(--dvh-unit) * 60;
    overflow: auto;
    table {
        width: 100%;
    }
    th,
    td {
        color: white;
        padding-right: 20px;
        text-align: left;
        min-width: 50px;
    }
    th {
        text-transform: uppercase;
        font-size: 16px;
    }
    td {
        font-size: 14px;
        opacity: 0.87;

        button {
            padding: 6px 40px 6px 40px;
        }
    }
    tbody:before {
        content: "@";
        display: block;
        line-height: 20px;
        text-indent: -99999px;
    }
`;

interface Props {
    canEditUsers: boolean;
    onClosed: () => void;
    onRequestEdit: (user: User) => void;
    onRequestAdd: () => void;
    users: User[];
}

export const UserList = (props: Props): JSX.Element => {
    // Render

    return (
        <Form vertical>
            <ListContainer>
                <table>
                    <thead>
                        <tr>
                            <th>{t("userManagement.name")}</th>
                            <th>{t("userManagement.loginName")}</th>
                            <th>{t("userManagement.role")}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {props.users.map((user) => (
                            <UserListItem
                                canEditUsers={props.canEditUsers}
                                onRequestEdit={props.onRequestEdit}
                                user={user}
                                key={user.id}
                            />
                        ))}
                    </tbody>
                </table>
            </ListContainer>
            <FormControl alignContents="flex-start" vertical>
                {props.canEditUsers && <Button label={t("userManagement.addNewUser")} onClick={props.onRequestAdd} />}
            </FormControl>
        </Form>
    );
};
