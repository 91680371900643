import * as Rx from "rxjs";
import { PlaybackScene, ChunkedLoaderPlaybackScene, PlaybackState } from "../../PlaybackScene";
import { TrackRepository } from "..";
import { ReplayRepository } from "../ReplayRepository";
import { RunwayTrafficRepository } from "../RunwayTrafficRepository";

export class CommonReplayRepository implements ReplayRepository {
    // Properties

    private currentPlaybackSceneSubject = new Rx.BehaviorSubject<PlaybackScene | null>(null);
    public currentPlaybackScene = this.currentPlaybackSceneSubject.asObservable();

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly runwayTrafficRepository?: RunwayTrafficRepository,
    ) {}

    // Public functions

    public makeNewPlaybackScene(startTimestamp: long, endTimestamp: long, onError?: (e: Error) => void): PlaybackScene {
        // Stop active scene if exists
        const activeScene = this.currentPlaybackSceneSubject.value;
        if (activeScene) {
            activeScene.stop();
        }

        const chunkSize = process.env.REACT_APP_REPLAY_CHUNK_SIZE_MILLIS;
        const stepSize = process.env.REACT_APP_REPLAY_STEP_SIZE_MILLIS;
        const cacheSize = process.env.REACT_APP_REPLAY_CACHE_SIZE;
        const scene = new ChunkedLoaderPlaybackScene(
            startTimestamp,
            endTimestamp,
            this.trackRepository,
            onError,
            this.runwayTrafficRepository,
            chunkSize ? parseInt(chunkSize) : undefined,
            stepSize ? parseInt(stepSize) : undefined,
            cacheSize ? parseInt(cacheSize) : undefined,
        );
        scene.state.subscribe((state) => {
            if (state === PlaybackState.STOPPED) {
                this.currentPlaybackSceneSubject.next(null);
            }
        });
        this.currentPlaybackSceneSubject.next(scene);
        return scene;
    }
}
