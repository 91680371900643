import { ServerURLError } from "./ServerURLError";
import { APP_CONFIG_KEYS, getAppConfig, getRuntimeConfig } from "./AppConfig";

export class ServerURL {
    // Private properties

    private static app: "BirdViewer" | "DroneViewer";

    private static readonly portSeparator = ":";
    private static readonly httpProtocol = "http://";
    private static readonly httpsProtocol = "https://";
    private static readonly wsProtocol = "ws://";
    private static readonly wssProtocol = "wss://";

    private static get bvHost(): string {
        return this.getHost(APP_CONFIG_KEYS.BV_API_HOST);
    }
    private static get dvHost(): string {
        return this.getHost(APP_CONFIG_KEYS.DV_API_HOST);
    }
    private static get bvPort(): string {
        return this.getPort(APP_CONFIG_KEYS.BV_API_PORT);
    }
    private static get dvPort(): string {
        return this.getPort(APP_CONFIG_KEYS.DV_API_PORT);
    }
    private static get dvWsPort(): string {
        return this.getWsPort(APP_CONFIG_KEYS.DV_API_WS_PORT);
    }
    private static get isBvApiSecure(): boolean {
        return this.getIsApiSecure(APP_CONFIG_KEYS.BV_API_SECURE);
    }
    private static get isDvApiSecure(): boolean {
        return this.getIsApiSecure(APP_CONFIG_KEYS.DV_API_SECURE);
    }
    private static get isDvWsSecure(): boolean {
        return this.getIsApiSecure(APP_CONFIG_KEYS.DV_API_WS_SECURE, true);
    }

    // Public properties

    public get serverAddress(): string {
        const protocol = this.isSecure ? ServerURL.httpsProtocol : ServerURL.httpProtocol;
        return protocol + this.host + ServerURL.portSeparator + this.port + this.apiPathPrefix;
    }

    public get websocketAddress(): string {
        const protocol = this.isWebsocketSecure ? ServerURL.wssProtocol : ServerURL.wsProtocol;
        return protocol + this.host + ServerURL.portSeparator + this.wsPort + this.apiWsPathPrefix;
    }

    public get apiProtocol(): string {
        return this.isSecure ? ServerURL.httpsProtocol : ServerURL.httpProtocol;
    }

    // Lifecycle

    public constructor(
        public readonly host: string,
        public readonly port: string,
        public readonly isSecure: boolean,
        public readonly apiPathPrefix: string,
        public readonly wsPort: string,
        public readonly isWebsocketSecure: boolean,
        public readonly apiWsPathPrefix: string,
    ) {}

    // Public functions

    public static forBirdViewer(): ServerURL {
        const apiPathPrefix = this.getPathPrefix(APP_CONFIG_KEYS.BV_API_PATH_PREFIX, APP_CONFIG_KEYS.API_PATH_PREFIX);

        return new ServerURL(ServerURL.bvHost, ServerURL.bvPort, ServerURL.isBvApiSecure, apiPathPrefix, "", false, "");
    }

    public static forDroneViewer(): ServerURL {
        const apiPathPrefix = this.getPathPrefix(
            APP_CONFIG_KEYS.DV_API_WS_PATH_PREFIX,
            APP_CONFIG_KEYS.API_PATH_PREFIX,
        );
        const apiWsPathPrefix = this.getPathPrefix(
            APP_CONFIG_KEYS.DV_API_PATH_PREFIX,
            APP_CONFIG_KEYS.API_WS_PATH_PREFIX,
        );

        return new ServerURL(
            ServerURL.dvHost,
            ServerURL.dvPort,
            ServerURL.isDvApiSecure,
            apiPathPrefix,
            ServerURL.dvWsPort,
            ServerURL.isDvWsSecure,
            apiWsPathPrefix,
        );
    }

    // Private functions

    private static getHost(hostConfigKey: string): string {
        // Try to get the app specific API host
        let host = getAppConfig(hostConfigKey);

        if (host.length === 0) {
            // Nothing found, so try to get the general API host
            host = getAppConfig(APP_CONFIG_KEYS.API_HOST);
        }

        if (host.length === 0 || host === "AUTO") {
            host = window.location.hostname;
        }

        return host;
    }

    private static getPort(portConfigKey: string): string {
        const port = getAppConfig(portConfigKey);
        this.checkPortString(port);
        return port;
    }

    private static getWsPort(portConfigKey: string): string {
        const port = getAppConfig(portConfigKey);
        if (port) {
            this.checkPortString(port);
        }
        return port;
    }

    private static checkPortString(port: string): void {
        const numericPort = parseInt(port);
        if (numericPort < 1 || numericPort > 32767) {
            throw ServerURLError.invalidPort;
        }
    }

    // As of release 22.07, path prefixes can no longer be set in .env and HAS to be configured in config.js
    private static getPathPrefix(flavorConfigKey: string, defaultConfigKey: string): string {
        let prefixFromConfig = ServerURL.getAppConfigForFlavor<string>(flavorConfigKey, defaultConfigKey);
        if (prefixFromConfig === undefined) {
            prefixFromConfig = "";
        }
        return ServerURL.formatPathPrefix(prefixFromConfig);
    }

    private static formatPathPrefix(prefixFromConfig: string): string {
        let prefix = "";
        if (prefixFromConfig != null && prefixFromConfig !== `${null}` && prefixFromConfig.length > 0) {
            if (!prefixFromConfig.startsWith("/")) {
                prefix = "/";
            }
            prefix += prefixFromConfig;
        }
        return prefix;
    }

    // As of release 22.07, secure api can no longer be configured in .env. This now has to be done in config.js
    // Also, all variants of legacy configs API_SCHEME and API_WS_SCHEME have been removed
    private static getIsApiSecure(appSecureConfigKey: string, isWebsocket = false): boolean {
        // Try app specific key
        const generalSecureConfigKey = isWebsocket ? APP_CONFIG_KEYS.API_WS_SECURE : APP_CONFIG_KEYS.API_SECURE;
        const isSecure = ServerURL.getAppConfigForFlavor<boolean>(appSecureConfigKey, generalSecureConfigKey);

        if (isSecure === undefined) {
            return false;
        }

        return isSecure;
    }

    private static getAppConfigForFlavor<T>(flavorConfigKey: string, defaultConfigKey: string): T | undefined {
        let appConfig = getRuntimeConfig<T>(flavorConfigKey);
        if (appConfig === undefined) {
            appConfig = getRuntimeConfig<T>(defaultConfigKey);
        }
        return appConfig;
    }
}
