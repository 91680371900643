import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository, ReplayRepository, RunwayTrafficRepository } from "../../domain/repositories";
import { LocalUserPreferenceKeys, RunwayCrossingData } from "../../domain/model";
import { nonNullObservable } from "../../utils/RxUtils";
import { TimeDisplayMode } from "../../domain/model/TimeDisplayMode";
import { replayHandler } from "../../utils/ReplayUtils";

export class RunwayCrossingChartViewModel extends BaseViewModel {
    // Properties

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly replayRepository: ReplayRepository,
    ) {
        super();
    }

    // Public functions

    public observeCurrentCount(runwayId: number): Rx.Observable<number> {
        return this.runwayCrossingCurrent.pipe(
            RxOperators.map((data) => {
                return data.get(runwayId)?.count ?? 0;
            }),
        );
    }

    public get runwayCrossingHistory(): Rx.Observable<Map<number, RunwayCrossingData[]>> {
        return replayHandler(
            this.replayRepository,
            this.runwayTrafficRepository.runwayCrossingHistory,
            (scene) => scene.runwayCrossingHistory,
        );
    }

    public get runwayCrossingThreshold(): Rx.Observable<int> {
        return this.runwayTrafficRepository.crossingThreshold;
    }

    public get timeDisplayMode(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.appearance.timeDisplayMode),
        );
    }

    // Private functions

    private get runwayCrossingCurrent(): Rx.Observable<Map<number, RunwayCrossingData>> {
        return replayHandler(this.replayRepository, this.runwayTrafficRepository.runwayCrossingCurrent, (scene) =>
            scene.runwayTraffic.pipe(
                RxOperators.map((runwayTrafficArray) => {
                    const newRunwayMap = new Map<number, RunwayCrossingData>();
                    runwayTrafficArray.forEach((rt) => {
                        newRunwayMap.set(
                            rt.runwayId,
                            new RunwayCrossingData(rt.timestamp, rt.runwayId, rt.tracksOnRunway),
                        );
                    });
                    return newRunwayMap;
                }),
            ),
        );
    }
}
