// HOME

export const ALL_LEFT_PANELS_CLOSED = {
    isAltitudeFilterVisible: false,
    isAltitudeFilterSettingsVisible: false,
    isDeterrenceVisible: false,
    isOverlaySettingsVisible: false,
    isObservationVisible: false,
    isRequestingReplayVisible: false,
    isTrackListVisible: false,
};

export const ALL_RIGHT_PANELS_CLOSED = {
    isLegendVisible: false,
    isRadarControlPanelVisible: false,
};

export const ALL_MODALS_CLOSED = {
    isAboutModalVisible: false,
    isAltitudeExplanationModalVisible: false,
    isLanguageSelectionModalVisible: false,
    isLoginModalVisible: false,
    isRadarLocationListModalVisible: false,
    isTileProviderSelectionModalVisible: false,
    isUserManagementModalVisible: false,
};

export const INITIAL_HOME_UI_STATE = {
    ...ALL_LEFT_PANELS_CLOSED,
    ...ALL_RIGHT_PANELS_CLOSED,
    ...ALL_MODALS_CLOSED,
    isRunwayTrafficBottomSheetVisible: false,
    isSettingsVisible: false,
};

export type HomeUIState = typeof INITIAL_HOME_UI_STATE;

// SIDEBAR

export const ALL_SIDEBAR_SECTIONS_HIDDEN = {
    isDetailedRadarStatusVisible: false,
    isVehicleInfoVisible: false,
    isEventListVisible: false,
    isTrackInfoVisible: false,
};

export const INITIAL_SIDEBAR_UI_STATE = {
    ...ALL_SIDEBAR_SECTIONS_HIDDEN,
    isTrackInfoVisible: true,
};

export type SidebarUIState = typeof INITIAL_SIDEBAR_UI_STATE;

export type UIState = HomeUIState & SidebarUIState;
