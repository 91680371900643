import mapboxgl from "mapbox-gl";
import { HeatmapLayer } from "../../layers";
import { MapModule } from "../MapModule";
import { HeatmapModuleViewModel } from "./HeatmapModuleViewModel";
import { ORDER_LAYER_HEATMAP } from "../Orders";
import { Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";

export class HeatmapModule extends MapModule<HeatmapModuleViewModel> {
    // Properties

    public constructor(protected readonly viewModel: HeatmapModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const heatmapLayer = HeatmapLayer.attachedTo(map, ORDER_LAYER_HEATMAP.id);

        this.collectSubscriptions(
            this.viewModel.shouldShowGrids.subscribe((value) => heatmapLayer.setEnabled(value)),
            this.viewModel.gridUpdates.subscribe({
                next: (value) => heatmapLayer.update(value),
                error: (error) => console.error(error),
            }),
        );
    }
}
