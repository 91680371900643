import { Colors } from "../../appearance/Colors";
import { BlankingSector } from "../../../domain/model";
import { degToRad, radToDeg } from "../../../utils/MathUtils";

const GUIDELINES_MINOR_SPACING_RADIANS = Math.PI / 6;
const GUIDELINES_MAJOR_SPACING_RADIANS = Math.PI / 2;
export const SECTORS_ROTATION_OFFSET_RADIANS = -Math.PI / 2;
export const EXTRA_SPACE_AROUND_SHAPE = 100;

export class BlankingSectorsImage {
    // Properties

    private imageSize = (this.radius + EXTRA_SPACE_AROUND_SHAPE) * 2;
    private context: CanvasRenderingContext2D;
    private innerCircleRadius = this.radius / 6;
    private clipPath?: Path2D;
    private blankingSectors: BlankingSector[] = [];

    public constructor(private readonly radius: number) {
        const canvas = document.createElement("canvas");
        canvas.width = this.imageSize;
        canvas.height = this.imageSize;
        this.context = canvas.getContext("2d")!;
    }

    // Public functions

    public getCanvas(): HTMLCanvasElement {
        return this.context.canvas;
    }

    public update(blankingSectors?: BlankingSector[]): void {
        this.blankingSectors = blankingSectors || [];
        this.render();
    }

    public getImageSizeToRadarRangeRadiusRatio(): number {
        return this.imageSize / 2 / this.radius;
    }

    /**
     * Called once before every frame where the icon will be used
     */
    private render(): void {
        const context = this.context;

        const r = this.radius;
        const cx = this.imageSize / 2;
        const cy = this.imageSize / 2;

        // Clip everything inside the inner circle
        if (this.clipPath == null) {
            this.clipPath = new Path2D();
            this.clipPath.rect(0, 0, this.imageSize, this.imageSize);
            this.clipPath.arc(cx, cy, this.innerCircleRadius - 1, 0, Math.PI * 2);
        }
        context.clip(this.clipPath, "evenodd");

        // Draw outer circle
        context.clearRect(0, 0, this.imageSize, this.imageSize);
        context.beginPath();
        context.arc(cx, cy, r - 1, 0, Math.PI * 2);
        context.strokeStyle = Colors.secondary.white;
        context.lineWidth = 1;
        context.stroke();

        // Draw inner circle
        context.beginPath();
        context.arc(cx, cy, this.innerCircleRadius, 0, Math.PI * 2);
        context.strokeStyle = Colors.secondary.white;
        context.lineWidth = 1;
        context.stroke();

        this.drawBlankingSectors(context, cx, cy, r);
        this.drawGuidelines(context, r, GUIDELINES_MINOR_SPACING_RADIANS, 0.5);
        this.drawGuidelines(context, r, GUIDELINES_MAJOR_SPACING_RADIANS, 1);
        this.drawGuideDegreeTexts(context, r);
    }

    private drawBlankingSectors(context: CanvasRenderingContext2D, cx: number, cy: number, r: number): void {
        this.blankingSectors.forEach((sector) => {
            if (!sector.enabled) {
                return;
            }

            context.beginPath();
            context.moveTo(cx, cy);
            context.arc(
                cx,
                cy,
                r,
                degToRad(sector.startAngle) + SECTORS_ROTATION_OFFSET_RADIANS,
                degToRad(sector.startAngle + sector.span) + SECTORS_ROTATION_OFFSET_RADIANS,
            );
            context.lineTo(cx, cy);
            context.fillStyle = "rgba(86, 119, 252, 0.5)";
            context.fill();
        });
    }

    private drawGuidelines(context: CanvasRenderingContext2D, r: number, spacing: number, lineWidth: number): void {
        const cx = this.imageSize / 2;
        const cy = this.imageSize / 2;
        for (
            let rad = SECTORS_ROTATION_OFFSET_RADIANS;
            rad < Math.PI * 2 + SECTORS_ROTATION_OFFSET_RADIANS;
            rad += spacing
        ) {
            context.beginPath();
            context.moveTo(cx, cy);
            context.lineTo(cx + Math.cos(rad) * r, cy + Math.sin(rad) * r);
            context.strokeStyle = Colors.secondary.white;
            context.lineWidth = lineWidth;
            context.stroke();
        }
    }

    private drawGuideDegreeTexts(context: CanvasRenderingContext2D, r: number): void {
        const cx = this.imageSize / 2;
        const cy = this.imageSize / 2;
        for (
            let rad = SECTORS_ROTATION_OFFSET_RADIANS;
            rad < Math.PI * 2 + SECTORS_ROTATION_OFFSET_RADIANS;
            rad += GUIDELINES_MAJOR_SPACING_RADIANS
        ) {
            context.fillStyle = Colors.secondary.white;
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.font = "14px Roboto";
            context.fillText(
                Math.round(radToDeg(rad - SECTORS_ROTATION_OFFSET_RADIANS)).toString(),
                cx + Math.cos(rad) * r * 1.05,
                cy + Math.sin(rad) * r * 1.05,
            );
        }
    }
}
