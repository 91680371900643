import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export class TrackSelector {
    public readonly selectedTrackId: Rx.Observable<number | null>;

    private readonly selectedTrackIdSubject = new Rx.BehaviorSubject<number | null>(null);

    public constructor() {
        this.selectedTrackId = this.selectedTrackIdSubject.asObservable().pipe(RxOperators.distinctUntilChanged());
    }

    public toggleSelectedTrackId(trackId: number | null): void {
        this.selectedTrackIdSubject.next(this.selectedTrackIdSubject.value === trackId ? null : trackId);
    }
}
