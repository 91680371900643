import { FullscreenControl, FullscreenControlOptions } from "mapbox-gl";

/*
 * This class overrides the original behavior of the fullscreen control so that it is
 * aware of the initial fullscreen state when getting added as a control.
 */
export class CustomFullScreenControl extends FullscreenControl {
    public constructor(options: FullscreenControlOptions) {
        super(options);
    }

    public onAdd(map: mapboxgl.Map): HTMLElement {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const container = super.onAdd(map);
        if (window.document.fullscreenElement) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            super._changeIcon();
        }
        return container;
    }
}
