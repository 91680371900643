import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { EditMode, OverlayEditorRepository, OverlayRepository, UIControlRepository } from "../../domain/repositories";

export class OverlayManagementViewModel extends BaseViewModel {
    // Properties

    public constructor(
        private readonly overlayRepository: OverlayRepository,
        private readonly overlayEditorRepository: OverlayEditorRepository,
        private readonly uiControlRepository: UIControlRepository,
    ) {
        super();
    }

    // Public functions

    public setEditMode(editMode: EditMode): void {
        this.overlayEditorRepository.setMode(editMode, editMode === EditMode.SELECT ? [] : null);
    }

    public getOverlays(): Rx.Observable<string[]> {
        return this.overlayRepository.overlays.pipe(RxOperators.map((map) => Array.from(map.keys())));
    }

    public observeVisibility(overlayName: string): Rx.Observable<boolean> {
        return this.overlayRepository.observeOverlayVisibility(overlayName);
    }

    public toggleVisibility(overlayName: string, visible?: boolean): void {
        const visibility = visible || !this.overlayRepository.getOverlayVisibility(overlayName);
        this.overlayRepository.setOverlayVisibility(overlayName, visibility);
    }

    public closeOverlayPanel(): void {
        this.uiControlRepository.toggleHomeUIComponent("isOverlaySettingsVisible", { isVisible: false });
    }
}
