import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";

/**
 * @description Can be used to display a text value in a form control component
 */
export const FormText = styled.span<{ uppercase?: boolean }>`
    ${BASE_TEXT_STYLE}
    color: ${({ theme }) => theme.colors.text.text};
    line-height: 1.43;
    ${({ uppercase }) => uppercase && `text-transform: uppercase;`}
`;

/**
 * @description Styling of description texts in forms
 */
export const FormDescription = styled(FormText)`
    color: ${({ theme }) => theme.colors.text.text300};
`;

/**
 * @description Styling of error messages in forms
 */
export const FormErrorText = styled(FormText)`
    margin-top: ${({ theme }) => theme.spacing.x1};
    color: ${({ theme }) => theme.colors.status.danger};
    font-size: 12px;
`;
