import { BaseViewModel } from "../../../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Radar } from "../../../../domain/model";
import { RadarRepository } from "../../../../domain/repositories";

export class RadarStatusViewModel extends BaseViewModel {
    // Properties

    public get hasMultipleOperatingModes(): Rx.Observable<boolean> {
        return this.radarRepository
            .getAvailableRadarOperatingModes()
            .pipe(RxOperators.map((operatingModes) => operatingModes.length > 0));
    }

    public get selectedRadar(): Rx.Observable<Radar | null> {
        return this.selectedRadarSubject.asObservable();
    }

    private selectedRadarSubject = new Rx.BehaviorSubject<Radar | null>(null);

    constructor(private readonly radarRepository: RadarRepository) {
        super();
        this.subscribeToRadarObservable();
    }

    // Public functions

    public resetRadarSensitivity(radarId: number): Rx.Observable<void> {
        return this.radarRepository.resetSensitivity(radarId);
    }

    // Private functions

    private subscribeToRadarObservable(): void {
        this.collectSubscription(
            Rx.combineLatest([this.radarRepository.radars, this.radarRepository.selectedRadarId]).subscribe(
                ([radars, selectedRadarId]) => {
                    const selectedRadar = radars.find((radar) => radar.id === selectedRadarId);
                    this.selectedRadarSubject.next(selectedRadar ?? null);
                },
            ),
        );
    }
}
