import { BaseViewModel } from "../../../../BaseViewModel";
import { TrackRepository, ReplayRepository } from "../../../../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export class RainStatusIndicatorViewModel extends BaseViewModel {
    // Properties

    public get isRaining(): Rx.Observable<boolean> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? Rx.of(false) : this.calculateRainingStatus())),
            RxOperators.distinctUntilChanged(),
        );
    }

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly replayRepository: ReplayRepository,
    ) {
        super();
    }

    // Private functions

    private calculateRainingStatus(): Rx.Observable<boolean> {
        return this.trackRepository.tracksSnapshot.pipe(
            RxOperators.map((snapshot) => snapshot != null && snapshot.rainPercentage > 0),
            RxOperators.distinctUntilChanged(),
        );
    }
}
