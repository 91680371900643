import React from "react";
import { Button } from "../appearance/button/Button";
import { FormControl } from "../appearance/forms/FormControl";
import { InputField } from "../appearance/forms/InputField";
import { t } from "i18next";
import { FormDescription, FormText } from "../appearance/forms/FormText";
import { LoginFormContainer } from "./LoginFormContainer";
import { useOauth } from "./useOauth";

interface Props {
    currentUsername?: string;
}

/**
 * Renders an OpenIdConnect login button, optionally showing the current user's username.
 * @param currentUsername If set, this username will be shown in a disabled input.
 */
export const OpenIdConnectForm = ({ currentUsername }: Props): JSX.Element => {
    // Properties

    const { triggerOauthRedirect, getHasOauthError, isBusy } = useOauth();
    const isError = getHasOauthError();

    // Local functions

    const submitLoginForm = (e: React.MouseEvent): void => {
        e.preventDefault();
        triggerOauthRedirect();
    };

    // Render

    return (
        <LoginFormContainer vertical action={"#"}>
            <FormText>{isError ? t("login.loginFailed") : t("login.loginToContinue")}</FormText>
            {currentUsername && (
                <FormControl fullWidth vertical>
                    <InputField disabled={true} large value={currentUsername} />
                </FormControl>
            )}
            <Button
                disabled={isBusy}
                label={isError ? t("login.tryAgain") : t("login.goToLogin")}
                onClick={(e) => submitLoginForm(e)}
            />
            {isError && <FormDescription>{t("login.loginFailedContactSupport")}</FormDescription>}
        </LoginFormContainer>
    );
};
