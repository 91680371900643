import React from "react";
import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { Icon } from "../Icon";

export const PanelFooter = styled.div`
    padding: ${({ theme }) => theme.spacing.x4};
    background-color: ${({ theme }) => theme.colors.backgrounds.panel};
`;

const PanelFooterTextStyle = styled.p`
    ${BASE_TEXT_STYLE}
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x2};
    margin: 0 0 ${({ theme }) => theme.spacing.x4} 0;
    color: ${({ theme }) => theme.colors.text.text200};
`;

interface PanelFooterTextProps {
    showIcon?: boolean;
    text: string;
}

export const PanelFooterText = ({ showIcon, text }: PanelFooterTextProps): JSX.Element => {
    return (
        <PanelFooterTextStyle>
            {showIcon && <Icon iconType="INFO" color="disabled" />}
            <span>{text}</span>
        </PanelFooterTextStyle>
    );
};
