import styled from "styled-components";

/**
 * @deprecated Use `PanelHeader` instead
 */
export const PanelTitle = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text.text};
    text-transform: uppercase;
`;
