import { TileProvider as TileProviderProto } from "./proto/generated/background3_pb";
import { TileProviderJSON } from "./json";
import { DEFAULT_MAP_STYLE_URL } from "../../utils/MapUtils";

export class TileProvider {
    // Static functions

    public static DEFAULT = new TileProvider(0, "Default", DEFAULT_MAP_STYLE_URL, "", "");

    public static fromProto(model: TileProviderProto): TileProvider {
        return new TileProvider(
            model.getId(),
            model.getName(),
            model.getUrl(),
            model.getThumbnailurl(),
            model.getApikey(),
        );
    }

    public static fromJson(model: TileProviderJSON): TileProvider {
        return new TileProvider(model.id, model.name, model.url, model.thumbnailUrl, model.apiKey);
    }

    public constructor(
        public id: int,
        public name: string,
        public url: string,
        public thumbnailUrl: string,
        public apiKey: string,
    ) {}
}
