import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { AlarmData } from "../../../../domain/model";
import { AlarmHistoryRepository, AlarmRepository } from "../../../../domain/repositories";
import { BaseViewModel } from "../../../BaseViewModel";

export class EventListViewModel extends BaseViewModel {
    // Properties

    public get activeAlarms(): Rx.Observable<AlarmData[]> {
        return this.alarmRepository.activeRealtime.pipe(RxOperators.distinctUntilChanged());
    }

    public get isAlarmHistoryAvailable(): boolean {
        return this.alarmHistoryRepository !== undefined;
    }

    public constructor(
        private readonly alarmRepository: AlarmRepository,
        private readonly alarmHistoryRepository?: AlarmHistoryRepository,
    ) {
        super();
    }
}
