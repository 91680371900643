import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { GeolocationPosition } from "../../model";
import { UserLocationRepository, UserLocationState } from "../";
import { showWarning } from "../../../utils/MessageUtils";
import { t } from "i18next";

export class CommonUserLocationRepository implements UserLocationRepository {
    public readonly userLocationPosition: Rx.Observable<GeolocationPosition | undefined>;
    public readonly userLocationState: Rx.Observable<UserLocationState>;
    public readonly userAltitude: Rx.Observable<number | null>;

    private readonly userLocationPositionSubject = new Rx.Subject<GeolocationPosition | undefined>();
    private readonly userLocationStateSubject = new Rx.BehaviorSubject<UserLocationState>(UserLocationState.INACTIVE);

    public constructor() {
        this.userLocationPosition = this.userLocationPositionSubject.asObservable();
        this.userLocationState = this.userLocationStateSubject.asObservable();
        this.userAltitude = this.createUserAltitudeObservable();
    }

    public setUserLocationPosition(position: GeolocationPosition | undefined): void {
        if (this.userLocationStateSubject.value !== UserLocationState.INACTIVE) {
            this.userLocationPositionSubject.next(position);
        }
    }

    public setUserLocationState(state: UserLocationState): void {
        this.userLocationStateSubject.next(state);
        if (state === UserLocationState.INACTIVE) {
            this.userLocationPositionSubject.next(undefined);
        }
    }

    private createUserAltitudeObservable(): Rx.Observable<number | null> {
        if (!("geolocation" in navigator)) {
            console.warn("User altitude unavailable: Geolocation api is not available.");
            return Rx.of(null);
        }
        return new Rx.Observable<number | null>((observer) => {
            const onSuccess: PositionCallback = (position) => {
                if (position.coords.altitude === null) {
                    console.warn("User altitude unavailable: Geolocation api returned null altitude value.");
                }
                observer.next(position.coords.altitude);
            };
            const onError: PositionErrorCallback = (error) => {
                console.warn(`User altitude unavailable: [${error.code}] ` + error.message);
                this.showGeolocationWarning(error);
                // In case of error, we don't want to emit any value, so we emit null.
                observer.next(null);
            };

            const watcherId = window.navigator.geolocation.watchPosition(onSuccess, onError, {
                maximumAge: 500,
            });

            return () => {
                window.navigator.geolocation.clearWatch(watcherId);
            };
        }).pipe(
            RxOperators.startWith<number | null>(null),
            RxOperators.share({
                connector: () => new Rx.ReplaySubject(1),
                resetOnError: false,
                resetOnRefCountZero: false, // location would not go in cold state, regardless of subs.
            }),
        );
    }

    private showGeolocationWarning(error: GeolocationPositionError): void {
        switch (error.code) {
            case error.PERMISSION_DENIED:
            case error.POSITION_UNAVAILABLE:
                showWarning({
                    message: t("messages.geolocationPermissionDenied"),
                    duration: Infinity,
                    onClick: () => {
                        window.open("https://support.google.com/chrome/answer/142065?hl=en");
                    },
                    label: t("general.learnMore"),
                    showCloseButton: true,
                });
                break;
            case error.TIMEOUT:
            default:
                showWarning({
                    message: t("messages.geolocationUnavailable"),
                });
                break;
        }
    }
}
