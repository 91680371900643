import mapboxgl from "mapbox-gl";

export interface EventListener<T extends keyof mapboxgl.MapEventType> {
    type: T;
    listener: (ev: mapboxgl.MapEventType[T] & mapboxgl.EventData) => void;
}

export interface LayerEventListener<T extends keyof mapboxgl.MapLayerEventType> {
    type: T;
    layer: string;
    listener: (ev: mapboxgl.MapLayerEventType[T] & mapboxgl.EventData) => void;
}

export abstract class InteractiveMapLayer {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private eventListeners: EventListener<any>[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private layerEventListeners: LayerEventListener<any>[] = [];

    protected constructor(protected readonly map: mapboxgl.Map) {}

    public addEventListener<T extends keyof mapboxgl.MapEventType>(el: EventListener<T>): void {
        // Remove first in case it was already added
        this.removeEventListener(el);
        if (!this.map) {
            return;
        }
        // Add the event listener to the map
        this.map.on(el.type, el.listener);
        // Keep a reference to the event listener so we can remove it from the map later
        this.eventListeners.push(el);
    }

    public removeEventListener<T extends keyof mapboxgl.MapEventType>(el: EventListener<T>): void {
        if (!this.map) {
            return;
        }
        // Remove the listener from the map
        this.map.off(el.type, el.listener);
        // Remove the event listener from the array if it's already added
        const index = this.eventListeners.indexOf(el);
        if (index >= 0) {
            this.layerEventListeners.splice(index, 1);
        }
    }

    public addLayerEventListener<T extends keyof mapboxgl.MapLayerEventType>(el: LayerEventListener<T>): void {
        // Remove first in case it was already added
        this.removeLayerEventListener(el);
        if (!this.map) {
            return;
        }
        // Add the event listener to the map
        this.map.on(el.type, el.layer, el.listener);
        // Keep a reference to the event listener so we can remove it from the map later
        this.layerEventListeners.push(el);
    }

    public removeLayerEventListener<T extends keyof mapboxgl.MapLayerEventType>(el: LayerEventListener<T>): void {
        if (!this.map) {
            return;
        }
        // Remove the listener from the map
        this.map.off(el.type, el.layer, el.listener);
        // Remove the event listener from the array if it's already added
        const index = this.layerEventListeners.indexOf(el);
        if (index >= 0) {
            this.layerEventListeners.splice(index, 1);
        }
    }

    public onModuleDispose(): void {
        // This is called when the style of the map is changed to prevent any event listeners remaining after the layers are removed
        // NOTE: The map.off function must receive the same object as was passed to map.on
        if (this.map) {
            for (const el of this.eventListeners) {
                this.map.off(el.type, el.listener);
            }
            for (const el of this.layerEventListeners) {
                this.map.off(el.type, el.layer, el.listener);
            }
        }
        this.eventListeners = [];
        this.layerEventListeners = [];
    }
}
