import React from "react";
import * as Rx from "rxjs";
import { FormToggle } from "../appearance/forms/FormToggle";
import { useObservable } from "../../hooks";
import { FormControl } from "../appearance/forms/FormControl";
import { IconType } from "../appearance/Icon";

export interface VisibilityProps {
    isVisible: boolean;
    isVisibleObservable: Rx.Observable<boolean>;
    setIsVisible: (value: boolean) => void;
}

export interface LegendEntryProps {
    /**
     * @todo name should be traslated same as in altitude filter settings
     */
    name: string;
    iconType: IconType;
    // Lower number -> higher priority
    order: int;
    visibility?: VisibilityProps;
}

export const LegendEntry = ({ name, iconType, visibility }: LegendEntryProps): JSX.Element => {
    // Properties

    const initialIsVisible = visibility?.isVisible ?? true;
    const isVisible = useObservable(visibility?.isVisibleObservable ?? Rx.of(initialIsVisible), initialIsVisible);

    // Render

    return (
        <FormControl title={name} iconType={iconType}>
            {visibility && (
                <FormToggle type="EYE" isActive={isVisible} onToggle={() => visibility.setIsVisible(!isVisible)} />
            )}
        </FormControl>
    );
};
