import React from "react";
import styled from "styled-components";
import { Button } from "../button/Button";
import { t } from "i18next";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { PANEL_HEADER_HEIGHT } from "../theme/Spacing";

const Component = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.x3};
    padding: ${({ theme }) => theme.spacing.x4};
    background-color: ${({ theme }) => theme.colors.backgrounds.panel};
    min-height: ${PANEL_HEADER_HEIGHT};
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.x2};
`;

const Label = styled.div`
    ${BASE_TEXT_STYLE}
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
`;

interface Props {
    label: string;
    onBack?: () => void;
    onBackLabel?: string;
    onClose?: () => void;
}

export const PanelHeader = ({ label, onBack, onBackLabel, onClose }: Props): JSX.Element => {
    // Render

    return (
        <Component>
            <ButtonContainer>
                {onBack ? (
                    <Button
                        iconType="CHEVRON_LEFT_DOUBLE"
                        iconPosition="left"
                        label={onBackLabel || t("general.back")}
                        layout="inline"
                        onClick={onBack}
                    />
                ) : (
                    <div />
                )}
                {onClose && <Button color="white" iconType="CROSS" layout="inline" onClick={onClose} />}
            </ButtonContainer>
            <Label>{label}</Label>
        </Component>
    );
};
