import { useEffect, useRef, useState } from "react";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { TYPES } from "../../di/Types";
import { SessionRepository } from "../../domain/repositories";
import { useDI } from "../../hooks/useDI";

interface Props {
    onError?: (error: Error) => void;
}

interface Result {
    login: (username: string, password: string) => void;
    isBusy: boolean;
}

export const useBasicAuth = ({ onError }: Props): Result => {
    // Properties

    const sessionRepository = useDI<SessionRepository>(TYPES.SessionRepository);
    const subscriptionRef = useRef<Rx.Subscription>();
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        const subscription = new Rx.Subscription();
        subscriptionRef.current = subscription;
        return () => subscription.unsubscribe();
    }, []);

    // Local functions

    function login(username: string, password: string): void {
        setIsBusy(true);
        subscriptionRef.current!.add(
            sessionRepository
                .loginBasicAuth(username, password)
                .pipe(
                    RxOperators.finalize(() => setIsBusy(false)),
                    RxOperators.ignoreElements(),
                )
                .subscribe({
                    error: (error) => onError?.(error),
                }),
        );
    }

    // Result

    return { login, isBusy };
};
