import React from "react";

import { t } from "i18next";
import { AlarmData, AlarmDataType } from "../domain/model";
import { CopyButtonWrapper } from "../components/appearance/button/CopyButtonWrapper";
import { toast, ExternalToast } from "sonner";
import { Colors } from "../components/appearance/Colors";
import { AllOrNone } from "./TypeUtils";

interface ApiError extends Error {
    messageId?: string;
}

interface BaseOptions {
    title?: string;
    message: string;
    showCloseButton?: boolean;
    duration?: number;
    id?: ExternalToast["id"];
}

// either both or none of the action options should be provided
interface ActionOptions {
    label: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

type Options = BaseOptions & AllOrNone<ActionOptions>;

function translateApiMessage(messageString: string, messageId?: string): string {
    const key = `apiMessage_${messageId || messageString}`;
    try {
        const text = t(`messages.${key}`);
        if (text === messageId) {
            return messageString;
        }
        return text;
    } catch (error) {
        return messageString;
    }
}

const adaptDuration = (duration: number | undefined): number | undefined => (duration ? duration * 1000 : undefined);
const getMessageAndDescription = ({
    message,
    title,
}: Pick<Options, "message" | "title">): { toastMessage: React.ReactNode; description?: React.ReactNode } => {
    // The reason we're using an array here is because the toastLibrary don't render icons and buttons when the message is a valid ReactElement
    // The library uses React.isValidElement to check if the message is a valid ReactElement, and if it is, it will render the message as is, without the icons and buttons
    // See https://react.dev/reference/react/isValidElement#caveats for more info
    // Ref to the original code: https://github.com/emilkowalski/sonner/blob/c7740f749af9e906adeaf3039cc82462d80b4977/src/index.tsx#L362C20-L362C54
    // There's a discussion open about this issue as well: https://github.com/emilkowalski/sonner/issues/221
    const copiableMessage = [
        <CopyButtonWrapper message={message} key={"copyWrapper"}>
            {message}
        </CopyButtonWrapper>,
    ];

    return {
        toastMessage: title ?? copiableMessage,
        description: title ? copiableMessage : undefined,
    };
};

const adaptOptions = (options: Options): { message: React.ReactNode } & ExternalToast => {
    const { toastMessage, description } = getMessageAndDescription(options);
    const duration = adaptDuration(options.duration);
    const action = options.onClick && options.label ? { label: options.label, onClick: options.onClick } : undefined;
    // if showCloseButton is undefined, it will default to true
    const closeButton = options.showCloseButton ?? true;
    return {
        ...options,
        message: toastMessage,
        description,
        closeButton,
        duration,
        action,
    };
};

const sonnerToasts = {
    // ---------------------
    // standard toasts
    error: (options: Options) => {
        const { message, ...sonnerOptions } = adaptOptions(options);
        toast.error(message, {
            ...sonnerOptions,
            style: {
                backgroundColor: Colors.secondary.red,
            },
        });
    },
    warning: (options: Options) => {
        console.warn(options.message);
        const { message, ...sonnerOptions } = adaptOptions(options);
        toast.warning(message, {
            ...sonnerOptions,
            style: {
                backgroundColor: Colors.secondary.orange,
            },
        });
    },
    info: (options: Options) => {
        console.info(options.message);
        const { message, ...sonnerOptions } = adaptOptions(options);
        toast.info(message, {
            ...sonnerOptions,
            style: {
                backgroundColor: Colors.secondary.blue,
            },
        });
    },
    success: (options: Options) => {
        const { message, ...sonnerOptions } = adaptOptions(options);
        toast.success(message, {
            ...sonnerOptions,
            style: {
                backgroundColor: Colors.secondary.green,
            },
        });
    },

    // ---------------------
    // api error toast
    apiError: (error: ApiError, overrideOptions?: Options) => {
        const translatedMessage = translateApiMessage(error.message, error.messageId);
        return sonnerToasts.error({
            ...(overrideOptions ?? {}),
            message: overrideOptions?.message ?? translatedMessage ?? error.message,
        });
    },
};

export function showErrorWithOptions(options: Options): void {
    return sonnerToasts.error(options);
}

export function showError(error: ApiError, overrideOptions?: Options): void {
    return sonnerToasts.apiError(error, overrideOptions);
}

export function showWarning(options: Options): void {
    return sonnerToasts.warning(options);
}

export function showInfo(options: Options): void {
    return sonnerToasts.info(options);
}

export function generateAlarmMessage(alarmData: AlarmData): string {
    switch (alarmData.type) {
        case AlarmDataType.AREA_ENTRY:
            return `${t("messages.trackEnteredArea")}: ${alarmData.overlayIds.join(", ")}`;
        case AlarmDataType.DRONE:
            return t("messages.droneDetected");
        case AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD:
            return t("messages.runwayCrossingReachedThreshold");
        case AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD:
            return t("messages.funnelTrafficReachedThreshold");
    }
}
