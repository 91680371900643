import { SettingsItemSection } from "../../../../components/settings/SettingsItemSection";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SettingItemViewModel } from "../../../../components/settings/SettingItemViewModel";
import {
    AlarmRepository,
    LocalPreferencesRepository,
    LocationInfoRepository,
    RunwayTrafficRepository,
} from "../../../../domain/repositories";
import { AlarmSoundTestViewModel } from "../../../../components/settings/sections/items/AlarmSoundTestViewModel";
import {
    AlarmToggleViewModel,
    MultipleAlarmToggleViewModel,
} from "../../../../components/settings/sections/items/AlarmToggleViewModel";
import { ALARMS_ENABLED_KEYS, ALARM_SOUND_ENABLED_KEYS, LocalUserPreferenceKeys } from "../../../../domain/model";
import { t } from "i18next";

export class BirdViewerAlarmSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "alarm-settings";
    public readonly label: string | null = t("settings.alarm");

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly alarmRepository: AlarmRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {}

    // Public functions

    public generate(): Rx.Observable<SettingItemViewModel[]> {
        return Rx.combineLatest([
            this.runwayTrafficRepository.hasRunwayCrossingData,
            this.locationInfoRepository.hasFunnelTrafficData,
        ]).pipe(
            RxOperators.map(([hasCrossingData, hasFunnelData]) => {
                const filterPreferenceKeys = (key: string): boolean => {
                    switch (key) {
                        case LocalUserPreferenceKeys.alarm.alarmRunwayCrossing:
                        case LocalUserPreferenceKeys.alarm.soundEnabledRunwayCrossing:
                            return hasCrossingData;
                        case LocalUserPreferenceKeys.alarm.alarmFunnelTraffic:
                        case LocalUserPreferenceKeys.alarm.soundEnabledFunnelTraffic:
                            return hasFunnelData;
                        default:
                            return true;
                    }
                };

                const items: SettingItemViewModel[] = [];
                items.push(
                    new MultipleAlarmToggleViewModel(
                        this.localPreferencesRepository,
                        "alarmsActivation",
                        ALARMS_ENABLED_KEYS.filter(filterPreferenceKeys),
                        ALARM_SOUND_ENABLED_KEYS.filter(filterPreferenceKeys),
                    ),
                    new AlarmToggleViewModel(
                        this.localPreferencesRepository,
                        "alarmToggleForTracks",
                        LocalUserPreferenceKeys.alarm.alarmTrack,
                        LocalUserPreferenceKeys.alarm.soundEnabledTrack,
                    ),
                    new AlarmToggleViewModel(
                        this.localPreferencesRepository,
                        "alarmToggleForAreaEntry",
                        LocalUserPreferenceKeys.alarm.areaEntry,
                        LocalUserPreferenceKeys.alarm.soundEnabledAreaEntry,
                    ),
                );
                hasFunnelData &&
                    items.push(
                        new AlarmToggleViewModel(
                            this.localPreferencesRepository,
                            "alarmToggleForFunnelTraffic",
                            LocalUserPreferenceKeys.alarm.alarmFunnelTraffic,
                            LocalUserPreferenceKeys.alarm.soundEnabledFunnelTraffic,
                        ),
                    );
                hasCrossingData &&
                    items.push(
                        new AlarmToggleViewModel(
                            this.localPreferencesRepository,
                            "alarmToggleForRunwayCrossing",
                            LocalUserPreferenceKeys.alarm.alarmRunwayCrossing,
                            LocalUserPreferenceKeys.alarm.soundEnabledRunwayCrossing,
                        ),
                    );
                items.push(new AlarmSoundTestViewModel(this.alarmRepository));
                return items;
            }),
        );
    }
}
