import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";

type TextAlign = "left" | "center" | "right";

/**
 * Can be used to display a text value in a panel component
 */
export const PanelDescription = styled.p<{ textAlign?: TextAlign }>`
    ${BASE_TEXT_STYLE}
    line-height: 1.43;
    color: ${({ theme }) => theme.colors.text.text200};
    ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

    :last-of-type {
        margin-bottom: 0;
    }
`;

/**
 * PanelDescription component, but without margin-top
 */
export const PanelParagraph = styled(PanelDescription)`
    margin-top: 0;
`;
