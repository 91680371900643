import * as Rx from "rxjs";
import { MapModuleViewModel } from "../MapModuleViewModel";
import { DeterrenceRepository } from "../../../../domain/repositories";
import { DeterrenceDevice, DeterrenceDeviceState } from "../../../../domain/model";

export class DeterrenceDevicesModuleViewModel extends MapModuleViewModel {
    // Properties

    public get deterrenceDeviceStates(): Rx.Observable<[DeterrenceDevice, DeterrenceDeviceState][]> {
        return this.showDeterrenceDevices.pipe(
            Rx.switchMap((enabled) => (enabled ? this.deterrenceRepository.observeDeterrenceDevices() : Rx.EMPTY)),
        );
    }

    public get showDeterrenceDevices(): Rx.Observable<boolean> {
        return this.deterrenceRepository.showDeterrenceDevicesObservable;
    }

    public constructor(private readonly deterrenceRepository: DeterrenceRepository) {
        super();
    }

    // Public functions

    public toggleSelectedState(deviceId: int): void {
        this.deterrenceRepository.toggleSelectedState(deviceId);
    }
}
