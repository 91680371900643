import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Root = styled.div`
    height: 100%;
    width: 100%;
    padding-top: 8px;

    /* 
     * This is some weird css workaround to get the canvas not grow in height!
     * If you are to change it, make sure to test the resizing of the funnel view and check how it looks on screens with
     * higher pixel density, such as Retina, in comparison to normal screens.
     */
    div {
        position: relative;
        width: 100%;
        height: 100%;

        * {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
`;

/**
 * This wrapper component is a workaround against letting canvas elements grow in size.
 * Simply wrap your canvas inside this view. It will then aim to fit the parent.
 *
 * @example
 * <CanvasContainer>
 *   <canvas />
 * </CanvasContainer>
 */
export const CanvasContainer = (props: PropsWithChildren<{}>): JSX.Element => {
    return (
        <Root>
            <div>{props.children}</div>
        </Root>
    );
};
