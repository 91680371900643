import React from "react";
import { TYPES } from "../../di/Types";
import { SidebarViewModel } from "./SidebarViewModel";
import { useViewModel } from "../../hooks/useViewModel";
import { useObservable } from "../../hooks";
import { ReplayTimeView } from "./modules/timestatusview/ReplayTimeView";
import { TimeStatusView } from "./modules/timestatusview/TimeView";
import { ToolbarButton } from "../toolbar/modules/ToolbarButton";
import { TrackInfo } from "./modules/trackinfo/TrackInfo";
import { INITIAL_SIDEBAR_UI_STATE, SidebarUIState } from "../../domain/model";
import { RadarName } from "./modules/radarname/RadarName";
import { VehicleInfo } from "./modules/vehicleinfo/VehicleInfo";
import { RadarStatus } from "./modules/radarstatus/RadarStatus";
import { IconType } from "../appearance/Icon";
import { TabBar } from "../appearance/Tabbar";
import { SidebarContentContainer, SidebarStyle, SidebarToolbar } from "../appearance/Sidebar";
import { SidebarTab } from "./SidebarTab";
import { EventList } from "./modules/eventlist/EventList";

export type SidebarSection = {
    iconType: IconType;
    key: keyof SidebarUIState;
    labelTranslationKey: string;
};

// Currently a maximum of 4 sections are supported visually
const SIDEBAR_SECTIONS: SidebarSection[] = [
    { iconType: "TRACK", key: "isTrackInfoVisible", labelTranslationKey: "sidebar.trackInfo" },
    { iconType: "EXCLAMATION_TRIANGLE", key: "isEventListVisible", labelTranslationKey: "sidebar.eventList" },
    { iconType: "RADAR", key: "isDetailedRadarStatusVisible", labelTranslationKey: "sidebar.radarStatus" },
    { iconType: "CAR", key: "isVehicleInfoVisible", labelTranslationKey: "vehicleInfo.vehicleInfo" },
];

export const Sidebar = (): JSX.Element => {
    // Properties

    const viewModel: SidebarViewModel = useViewModel(TYPES.SidebarViewModel);
    const hasPlaybackState = useObservable(viewModel.hasPlaybackState, null);
    const isRadarControlAllowed = useObservable(viewModel.isRadarControlAllowed, false);
    const sidebarSectionState = useObservable<SidebarUIState>(viewModel.sidebarSectionState, INITIAL_SIDEBAR_UI_STATE);
    const isDynamicPositioningEnabled = useObservable(viewModel.isDynamicPositioningEnabled, false);
    const hasActiveAlarms = useObservable(viewModel.hasActiveAlarms, false);

    // Render

    return (
        <SidebarStyle>
            {hasPlaybackState ? <ReplayTimeView /> : <TimeStatusView />}
            <SidebarContentContainer largeClock={false}>
                <RadarName />
                <TabBar>
                    {SIDEBAR_SECTIONS.map((section) => (
                        <SidebarTab
                            hasActiveAlarms={hasActiveAlarms}
                            isActive={sidebarSectionState[section.key]}
                            isDynamicPositioningEnabled={isDynamicPositioningEnabled}
                            section={section}
                            showSidebarSection={() => viewModel.showSidebarSection(section.key)}
                            key={section.key}
                        />
                    ))}
                </TabBar>
                {sidebarSectionState.isTrackInfoVisible && <TrackInfo />}
                {sidebarSectionState.isEventListVisible && <EventList />}
                {sidebarSectionState.isDetailedRadarStatusVisible && <RadarStatus />}
                {sidebarSectionState.isVehicleInfoVisible && <VehicleInfo />}
            </SidebarContentContainer>
            <SidebarToolbar>
                <ToolbarButton iconType="QUESTION" positionOnScreen="right" uiStateKey="isLegendVisible" />
                {isRadarControlAllowed && (
                    <ToolbarButton iconType="RADAR" positionOnScreen="right" uiStateKey="isRadarControlPanelVisible" />
                )}
                <ToolbarButton iconType="COG" uiStateKey="isSettingsVisible" />
                <ToolbarButton iconType="INFO" uiStateKey="isAboutModalVisible" />
            </SidebarToolbar>
        </SidebarStyle>
    );
};
