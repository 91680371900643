import { PlusMinusItemViewModel } from "../../SettingItemViewModel";
import TrailLengthIcon from "../../../../res/images/settings/trail_length.svg";
import { SettingItemIcon } from "../../SettingItemIcon";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { LocalUserPreferenceKeys } from "../../../../domain/model";
import { t } from "i18next";

export class TrailLengthSettingItemViewModel extends PlusMinusItemViewModel {
    public constructor(localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.trailLength"),
            SettingItemIcon.withUrl(TrailLengthIcon),
            localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.trailLength),
            [10000, Number.MAX_VALUE],
            10000,
            (value) => localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.trailLength, value),
            (value) => value / 1000 + ` ${t("unit.second_shorthand")}`,
            [],
            undefined,
        );
    }
}
