/**
 * Returns the screen orientation, either "landscape" or "portrait".
 * Uses the Screen Orientation API if available, otherwise falls back to window.innerWidth and window.innerHeight.
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Screen_Orientation_API
 * @returns The screen orientation, either "landscape" or "portrait"
 */
export function getScreenOrientation(): "landscape" | "portrait" {
    const orientation = window.screen.orientation;
    if (orientation) {
        // Convert from OrientationType to Orientation
        return orientation.type.startsWith("landscape") ? "landscape" : "portrait";
    }
    // Fall back to window.innerWidth and window.innerHeight for Chrome/Safari on iOS < 16.4
    return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
}

/**
 * Checks if the screen is too small for the app to be displayed properly.
 * @returns true if the screen is too small, false otherwise
 */
export function checkIfScreenIsTooSmall(): boolean {
    const orientation = getScreenOrientation();
    if (orientation === "portrait") {
        return window.innerWidth < 650;
    }
    return window.innerWidth < 992 || window.innerHeight < 650;
}
