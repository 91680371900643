import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { AlarmDataType, ALARMS_ENABLED_KEYS } from "../../domain/model";
import { AlarmRepository, LocalPreferencesRepository, RadarRepository } from "../../domain/repositories";
import { BaseViewModel } from "../BaseViewModel";

export class EdgeGlowViewModel extends BaseViewModel {
    // Properties

    public get hasActiveAlarms(): Rx.Observable<boolean> {
        return Rx.combineLatest([this.alarmRepository.activeRealtime, this.enabledAlarmTypes]).pipe(
            RxOperators.map(
                ([alarms, enabledAlarms]) => alarms.filter((alarm) => enabledAlarms.includes(alarm.type)).length > 0,
            ),
        );
    }

    public get isDynamicPositioningEnabled(): Rx.Observable<boolean> {
        return this.radarRepository.isDynamicPositioningEnabled;
    }

    constructor(
        private readonly alarmRepository: AlarmRepository,
        private readonly radarRepository: RadarRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
    }

    // Private functions

    private get enabledAlarmTypes(): Rx.Observable<AlarmDataType[]> {
        return Rx.combineLatest(
            ALARMS_ENABLED_KEYS.map((key) => this.localPreferencesRepository.observePreference<boolean>(key)),
        ).pipe(
            RxOperators.map(([trackEnabled, areaEntryEnabled, crossingEnabled, funnelEnabled]) => {
                const enabledAlarmTypes: AlarmDataType[] = [];
                trackEnabled && enabledAlarmTypes.push(AlarmDataType.DRONE);
                areaEntryEnabled && enabledAlarmTypes.push(AlarmDataType.AREA_ENTRY);
                crossingEnabled && enabledAlarmTypes.push(AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD);
                funnelEnabled && enabledAlarmTypes.push(AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD);
                return enabledAlarmTypes;
            }),
        );
    }
}
