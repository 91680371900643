import { SensorStatusJSON } from "./json/SensorStatusJSON";

interface RadarSensorStatusEntity {
    name: string;
    value: string;
    units: string;
}
export class RadarSensorStatus {
    public static fromJson(json: SensorStatusJSON[]): RadarSensorStatus {
        return new RadarSensorStatus(json);
    }
    public constructor(public readonly entities: RadarSensorStatusEntity[]) {}
}
