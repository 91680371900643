import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";
import {
    GroundObservationMode,
    GroundObservationRepository,
    LocalPreferencesRepository,
    ReplayRepository,
    TrackObservationRepository,
} from "../../domain/repositories";
import { PlaybackState } from "../../domain/PlaybackScene";
import { SelectionState } from "./Observation";
import { LocalUserPreferenceKeys, TrackObservationMode } from "../../domain/model";
import { t } from "i18next";

export class ObservationViewModel extends BaseViewModel {
    // Properties

    public get selectionStateObservable(): Rx.Observable<SelectionState> {
        return Rx.combineLatest([
            this.hasSelectedTracksObservable,
            this.hasSelectedLocationObservable,
            this.hasSelectedGroundObservationObservable,
        ]).pipe(
            RxOperators.map(([hasSelectedTracks, hasSelectedLocation, hasSelectedGroundObservation]) => {
                if (hasSelectedTracks) {
                    return SelectionState.Tracks;
                } else if (hasSelectedLocation) {
                    return SelectionState.Location;
                } else if (hasSelectedGroundObservation) {
                    return SelectionState.GroundObservation;
                } else {
                    return SelectionState.None;
                }
            }),
        );
    }

    public get subtitleTextObservable(): Rx.Observable<string | undefined> {
        return this.trackObservationRepository.selectedTracks.pipe(
            RxOperators.map((track) =>
                track.length > 0 ? t("observation.trackSelected", { count: track.length }) : undefined,
            ),
        );
    }

    public get birdCountEnabled(): boolean {
        return this.flavorConfig.observationsConfig.birdCountEnabled;
    }

    public get speciesSelectionEnabled(): boolean {
        return this.flavorConfig.observationsConfig.speciesSelectionEnabled;
    }

    public get speciesListObservable(): Rx.Observable<{ id: number; name: string }[]> {
        return this.trackObservationRepository.speciesList;
    }

    public get groundObservationNotesObservable(): Rx.Observable<string | undefined> {
        if (!this.groundObservationRepository) {
            return Rx.of(undefined);
        }
        return this.groundObservationRepository.selectedGroundObservationIds.pipe(
            RxOperators.filter((ids) => ids.length > 0),
            RxOperators.distinctUntilChanged(),
            RxOperators.map((ids) => ids[0]),
            RxOperators.mergeMap((selectedId) =>
                this.groundObservationRepository!.groundObservations.pipe(
                    RxOperators.take(1),
                    RxOperators.map((observations) => observations.find((o) => o.id === selectedId)),
                ),
            ),
            RxOperators.map((observation) => (observation ? observation.notes : undefined)),
        );
    }

    public get observationShouldEnd(): Rx.Observable<boolean> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
            RxOperators.filter(
                (state) => state == null || state === PlaybackState.PLAYING || state === PlaybackState.STOPPED,
            ),
            RxOperators.distinctUntilChanged(),
            RxOperators.skip(1),
            RxOperators.map(() => true),
        );
    }

    public get hasGroundObservations(): boolean {
        return this.groundObservationRepository !== undefined;
    }

    private get hasSelectedTracksObservable(): Rx.Observable<boolean> {
        return this.trackObservationRepository.selectedTracks.pipe(RxOperators.map((t) => t.length > 0));
    }

    private get hasSelectedLocationObservable(): Rx.Observable<boolean> {
        if (!this.groundObservationRepository) {
            return Rx.of(false);
        }
        return this.groundObservationRepository.selectedLocations.pipe(RxOperators.map((l) => l.length > 0));
    }

    private get hasSelectedGroundObservationObservable(): Rx.Observable<boolean> {
        if (!this.groundObservationRepository) {
            return Rx.of(false);
        }
        return this.groundObservationRepository.selectedGroundObservationIds.pipe(RxOperators.map((o) => o.length > 0));
    }

    // Public functions

    public constructor(
        private readonly replayRepository: ReplayRepository,
        private readonly trackObservationRepository: TrackObservationRepository,
        private readonly flavorConfig: FlavorConfig,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly groundObservationRepository?: GroundObservationRepository,
    ) {
        super();
    }

    public setObservationIsEnabled(isEnabled: boolean): void {
        this.trackObservationRepository.setMode(
            isEnabled
                ? this.flavorConfig.observationsConfig.multiTrackEnabled
                    ? TrackObservationMode.MultiTrackObservation
                    : TrackObservationMode.SingleTrackObservation
                : TrackObservationMode.None,
        );
        this.groundObservationRepository &&
            this.groundObservationRepository.setMode(
                isEnabled ? GroundObservationMode.SingleGroundObservation : GroundObservationMode.None,
            );
    }

    public sendObservations(notes: string, birdCount?: number, speciesId?: number): Rx.Observable<void> {
        return Rx.zip(
            this.hasSelectedTracksObservable,
            this.hasSelectedLocationObservable,
            this.hasSelectedGroundObservationObservable,
        ).pipe(
            RxOperators.take(1),
            RxOperators.switchMap(([hasSelectedTracks, hasSelectedLocation, hasSelectedGroundObservation]) => {
                if (hasSelectedTracks) {
                    return this.trackObservationRepository.submitSelectedTrackObservation({
                        notes,
                        birdCount,
                        speciesId,
                    });
                } else if (hasSelectedLocation) {
                    return this.groundObservationRepository!.addObservationForSelectedLocations(notes).pipe(
                        RxOperators.ignoreElements(),
                    );
                } else if (hasSelectedGroundObservation) {
                    return this.groundObservationRepository!.updateSelectedGroundObservations(notes);
                } else {
                    return Rx.EMPTY;
                }
            }),
        );
    }

    public deleteObservations(): Rx.Observable<void> {
        return this.groundObservationRepository!.removeSelectedGroundObservations();
    }

    public clearSelection(): void {
        this.trackObservationRepository.clearSelectedTracks();
        this.groundObservationRepository && this.groundObservationRepository.clearSelection();
    }

    public refreshGroundObservations(): void {
        this.groundObservationRepository && this.groundObservationRepository.fetchGroundObservationsUpdatesOnce();
    }

    public getRecentlyUsedSpeciesIds(): number[] {
        return (
            this.localPreferencesRepository.getPreference<number[]>(LocalUserPreferenceKeys.observation.recentlyUsed) ||
            []
        );
    }

    public incrementSpeciesListItemUsage(speciesId: number): void {
        const currentData = this.getRecentlyUsedSpeciesIds();
        const newData = [speciesId, ...currentData.filter((itemId) => itemId !== speciesId)].slice(0, 5);
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.observation.recentlyUsed, newData);
    }
}
