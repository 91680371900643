import styled from "styled-components";
import React from "react";

const BottomSheetTitle = styled.span`
    color: ${({ theme }) => theme.colors.text.text};
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
`;

class Texts extends React.Component {
    public static BottomSheetTitles: Function;
}

Texts.BottomSheetTitles = BottomSheetTitle;

export default Texts;
