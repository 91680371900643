import React from "react";
import { LegendEntry, LegendEntryProps } from "./LegendEntry";

interface LegendEntrySectionProps {
    legendEntries: LegendEntryProps[];
}

/**
 * @todo can we not add this map to the Legend component?
 */
export const LegendEntrySection = ({ legendEntries }: LegendEntrySectionProps): JSX.Element => {
    return (
        <>
            {legendEntries.map((p, index) => (
                <LegendEntry key={index} {...p} />
            ))}
        </>
    );
};
