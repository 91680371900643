import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { Icon, IconColors, IconType } from "../Icon";
import { ReactComponent as InlineHelpIcon } from "../../../res/images/ic_help_inline.svg";
import ReactTooltip from "react-tooltip";

type AlignContents = "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly";

interface ComponentProps {
    alignContents?: AlignContents;
    fullWidth?: boolean;
    vertical?: boolean;
}

const Component = styled.span<ComponentProps>`
    display: flex;
    flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
    ${({ alignContents }) => alignContents && `justify-content: ${alignContents};`}
    align-items: ${({ vertical }) => (vertical ? "space-between" : "center")};
    gap: ${({ theme, vertical }) => (vertical ? theme.spacing.x3 : theme.spacing.x2)};
    ${({ fullWidth }) => fullWidth && "width: 100%;"};
`;

const TitleComponent = styled.span<ComponentProps>`
    ${BASE_TEXT_STYLE}
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.x2};
    color: ${({ theme }) => theme.colors.text.text200};
    font-size: 16px;
    font-weight: 400;
`;

const VerticalLayout = styled.div<{ alignContents?: AlignContents }>`
    display: flex;
    flex-direction: column;
    ${({ alignContents }) => alignContents && `align-items: ${alignContents};`}
    gap: ${({ theme }) => theme.spacing.x1};
`;

interface Props {
    alignContents?: AlignContents;
    fullWidth?: boolean;
    helpText?: string;
    iconColor?: IconColors;
    iconType?: IconType;
    title?: string;
    vertical?: boolean;
}

/**
 * @description Component that wraps a form input element with a title and an optional icon
 * @param alignContents Optional. Determine where the contents of the form control should align. Values correspond to flexbox align-items/justify-content property
 * @param fullWidth Optional. Determines if the form control should take up the full width of the parent container
 * @param helpText Optional. Help text to display right after the title (only works when there's a title)
 * @param iconColor Optional. Color of the icon
 * @param iconType Optional. Icon to display next to the title
 * @param title Optional. Title of the form control
 * @param vertical Optional. Change to a vertical alignment and adds more vertical spacing between child elements
 */
export const FormControl = ({
    alignContents,
    children,
    fullWidth,
    helpText,
    iconType,
    iconColor = "neutral",
    title = "",
    vertical,
}: PropsWithChildren<Props>): JSX.Element => {
    return (
        <Component alignContents={alignContents} fullWidth={fullWidth} vertical={vertical}>
            {title && (
                <TitleComponent vertical={vertical}>
                    {iconType && <Icon color={iconColor} iconType={iconType} />}
                    {title}
                    {helpText && (
                        <span data-for="helptext-tooltip" data-tip={helpText} data-iscapture="true">
                            <InlineHelpIcon />
                        </span>
                    )}
                    <ReactTooltip id="helptext-tooltip" effect={"solid"} place="bottom" />
                </TitleComponent>
            )}
            {vertical ? <VerticalLayout alignContents={alignContents}>{children}</VerticalLayout> : children}
        </Component>
    );
};
