import styled from "styled-components";
import { OVERLAY_SPACING } from "../theme/Spacing";

export const OverlayVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${OVERLAY_SPACING};
    z-index: 1000;
    pointer-events: none;
`;

export const OverlayVerticalContainerRight = styled(OverlayVerticalContainer)`
    align-items: flex-end;
`;

export const OverlayVerticalContainerLeft = styled(OverlayVerticalContainer)`
    align-items: flex-start;
`;
