import React from "react";
import styled from "styled-components";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";

const Input = styled.input`
    display: none;
    + label {
        cursor: pointer;
        position: relative;
        height: 16px;
        line-height: 16px;
        padding-left: 55px;
        transition: all 0.4s ease-in-out 0s;
    }
    + label:after,
    + label:before {
        top: 25%;
        content: "";
        position: absolute;
        transition: inherit;
    }
    + label:before {
        top: 50%;
        left: 14px;
        z-index: 1;
        width: 16px;
        content: "";
        height: 16px;
        border-radius: 50%;
        position: absolute;
        margin-top: -11.5px;
        transition: inherit;
        background: ${({ theme }) => theme.colors.secondary.white};
    }
    + label:after {
        left: 0;
        width: 30px;
        margin-left: 20px;
        height: 2px;
        background: ${({ theme }) => theme.colors.secondary.white};
    }
    :checked + label:after {
        background: ${({ theme }) => theme.colors.secondary.blue};
    }
    :checked + label:before {
        left: 40px;
        background: ${({ theme }) => theme.colors.secondary.blue};
    }
    :not(:checked) + label:hover:after,
    :not(:checked) + label:hover:before {
        background-color: ${({ theme }) => theme.colors.secondary.white200};
    }
`;

interface Props {
    style?: {};
    disabled: boolean;
    value: boolean | null;
    observableValue: Rx.Observable<boolean> | null;
    onChange: (isOn: boolean) => void;
}

export class ToggleSwitch extends BaseSubscriptionHandlerComponent<Props, State> {
    // Static properties

    public static defaultProps = {
        disabled: false,
        value: null,
        observableValue: null,
    };

    // Properties

    private randomId = "ToggleSwitch" + Math.random().toString(36);

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservableValueIfNeeded();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    // Public functions

    public render(): JSX.Element {
        return (
            <div style={this.props.style}>
                <Input
                    disabled={this.props.disabled}
                    checked={this.state.value === true}
                    type={"checkbox"}
                    id={this.randomId}
                    onChange={this.onChange.bind(this)}
                />
                <label htmlFor={this.randomId} />
            </div>
        );
    }

    // Private functions

    private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.onChange(event.target.checked);
        this.setState({ value: event.target.checked });
    }

    private subscribeToObservableValueIfNeeded(): void {
        if (this.props.observableValue == null) {
            return;
        }
        const subscription = this.props.observableValue.subscribe((value) => {
            this.setState({ value: value });
        });
        this.collectSubscription(subscription);
    }
}

interface State {
    value: boolean | null;
}
