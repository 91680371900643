import mapboxgl from "mapbox-gl";
import { MapModule } from "../MapModule";
import { UserLocationModuleViewModel } from "./UserLocationModuleViewModel";
import { LayerManager } from "../LayerManager";
import { Location } from "../../../../domain/model";
import { ORDER_LAYER_USER_LOCATION } from "../Orders";
import { UserLocationLayer, UserLocationRangeLayer } from "../../layers";

export class UserLocationModule extends MapModule<UserLocationModuleViewModel> {
    // Properties

    public constructor(protected readonly viewModel: UserLocationModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const userLocationLayer = UserLocationLayer.attachedTo(map, ORDER_LAYER_USER_LOCATION.id);
        const userLocationRangeLayer = UserLocationRangeLayer.attachedTo(map, ORDER_LAYER_USER_LOCATION.id);

        this.collectSubscriptions(
            this.viewModel.shouldShowUserLocation.subscribe((shouldShowUserLocation) =>
                userLocationLayer.setEnabled(shouldShowUserLocation),
            ),
            this.viewModel.userLocationPosition.subscribe({
                next: (userLocationPosition) => {
                    if (!userLocationPosition) {
                        userLocationRangeLayer.setCenterPositions();
                        return;
                    }
                    userLocationLayer.setUserLocation(userLocationPosition.coords);
                    userLocationRangeLayer.setCenterPositions({
                        id: 0,
                        position: [userLocationPosition.coords.longitude, userLocationPosition.coords.latitude],
                    });
                },
                error: (error) => console.error(error),
            }),
            this.viewModel.shouldShowUserLocationRange.subscribe((value) => userLocationRangeLayer.setEnabled(value)),
            this.viewModel.userLocationRangeRingsOpacity.subscribe((value) => userLocationRangeLayer.setOpacity(value)),
            this.viewModel.distanceUnit.subscribe((value) => userLocationRangeLayer.setDistanceUnit(value)),
        );
    }
}
