import React from "react";
import styled from "styled-components";
import { OldColors } from "./Colors";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import MinusIcon from "./../../res/images/settings/minus.svg";
import PlusIcon from "./../../res/images/settings/plus.svg";
import { Range } from "../../domain/model";

const PlusMinusImage = styled.img`
    cursor: pointer;
    opacity: 1;
    :hover {
        opacity: 0.8;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    label {
        font-size: 14px;
        color: ${OldColors.primaryTint};
        width: 65px;
        text-align: center;
        font-weight: 500;
    }
    img:active {
        user-drag: none;
        opacity: 0.8;
    }
`;

interface Props {
    style?: {};
    step: number;
    range: Range;
    value: number | null;
    formatter: (value: number) => string;
    observableValue: Rx.Observable<number | null> | null;
    onChange: (value: number) => void;
}

interface State {
    value: number;
}

export class PlusMinusController extends BaseSubscriptionHandlerComponent<Props, State> {
    // Static properties

    public static defaultProps = {
        value: null,
        observableValue: null,
    };

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value || this.props.range[0],
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservableValueIfNeeded();
    }

    // Public functions

    public render(): JSX.Element {
        return (
            <Container style={this.props.style}>
                <PlusMinusImage src={MinusIcon} alt={"Minus"} onClick={this.onMinusClick.bind(this)} />
                <label>{this.props.formatter(this.state.value)}</label>
                <PlusMinusImage src={PlusIcon} alt={"Plus"} onClick={this.onPlusClick.bind(this)} />
            </Container>
        );
    }

    // Private functions

    private onMinusClick(): void {
        this.onChange(this.state.value - this.props.step);
    }

    private onPlusClick(): void {
        this.onChange(this.state.value + this.props.step);
    }

    private onChange(value: number): void {
        let newValue = value;
        if (newValue < this.props.range[0]) {
            newValue = this.props.range[0];
        } else if (newValue > this.props.range[1]) {
            newValue = this.props.range[1];
        }
        if (newValue === this.state.value) {
            return;
        }
        this.setState({ value: newValue });
        this.props.onChange(newValue);
    }

    private subscribeToObservableValueIfNeeded(): void {
        if (this.props.observableValue == null) {
            return;
        }
        const subscription = this.props.observableValue.subscribe((value) => {
            if (value == null) {
                return;
            }
            this.setState({ value: value });
        });
        this.collectSubscription(subscription);
    }
}
