import { ADSBFlight, TrackableSnapshot, getMaxTimestamp } from ".";
import { Snapshot } from "./Snapshot";
import { TrackableSnapshotDiff } from "../SnapshotDiffCalculator";
import { FlightListJSON } from "./json";
import { AdsbFlightList } from "./proto/generated/adsbflightlist3_pb";

export type ADSBFlightsSnapshotDiff = TrackableSnapshotDiff<ADSBFlight>;

export type ADSBFlightsMap = Map<int, ADSBFlight>;

export class ADSBFlightsSnapshot extends Snapshot implements TrackableSnapshot<ADSBFlight> {
    public static fromProto(model: AdsbFlightList): ADSBFlightsSnapshot {
        const flights: ADSBFlightsMap = new Map();
        model.getFlightList().forEach((flight) => {
            flights.set(flight.getIcao(), ADSBFlight.fromProto(flight));
        });
        const timestamps = model
            .getFlightList()
            .flatMap((flight) => flight.getPositionList().map((p) => p.getTimestampMsec()));
        return new ADSBFlightsSnapshot(model.getLastupdateid(), flights, getMaxTimestamp(...timestamps));
    }

    public static fromJSON(model: FlightListJSON): ADSBFlightsSnapshot {
        const flights: ADSBFlightsMap = new Map();
        model.flights.forEach((flight) => {
            flights.set(flight.icao, ADSBFlight.fromJSON(flight));
        });
        const timestamps = model.flights.flatMap((flight) => flight.positions.map((p) => p.timestamp * 1000));
        const lastUpdateId = 0; // No update ID in JSON. The internal model in the Viewer seems not to use it.
        return new ADSBFlightsSnapshot(lastUpdateId, flights, getMaxTimestamp(...timestamps));
    }

    public static newEmpty(): ADSBFlightsSnapshot {
        return new ADSBFlightsSnapshot(0, new Map());
    }

    public constructor(public readonly lastUpdateId: int, public readonly tracks: ADSBFlightsMap, timestamp?: long) {
        super(timestamp);
    }
}
