import { SettingItemIcon } from "../SettingItemIcon";
import { SettingItemViewModel, ActionItemViewModel } from "../SettingItemViewModel";
import { SessionRepository } from "../../../domain/repositories/SessionRepository";
import LogoutIcon from "../../../res/images/settings/logout.svg";
import { SettingsItemSection } from "../SettingsItemSection";
import { SettingsErrorHandler } from "../SettingsErrorHandler";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { User } from "../../../domain/model/User";
import { SessionState } from "../../../domain/model";
import { t } from "i18next";

export class CommonSessionManagementSection implements SettingsItemSection {
    //Properties

    public readonly id: string = "session-management";
    public readonly label: string | null = null;

    public constructor(private readonly sessionRepository: SessionRepository) {}

    // Public functions

    public generate(errorHandler: SettingsErrorHandler): Rx.Observable<SettingItemViewModel[]> {
        return this.sessionRepository.session.pipe(
            RxOperators.filter((session) => session.state === SessionState.LoggedIn),
            RxOperators.take(1),
            RxOperators.map((session) => this.getLogoutButton(errorHandler, session.user!)),
            RxOperators.toArray(),
        );
    }

    public getLogoutButton(errorHandler: SettingsErrorHandler, user: User): ActionItemViewModel {
        const username = user.name.length > 0 ? ` (${user.name})` : "";
        return new ActionItemViewModel(t("settings.logout") + username, SettingItemIcon.withUrl(LogoutIcon), () => {
            this.sessionRepository.logout().subscribe({
                error: (error) => {
                    error.message = t("settings.logoutFailed");
                    errorHandler.onError(error);
                },
            });
        });
    }
}
