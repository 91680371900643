import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export interface Cache<T> {
    get(key: string): T | undefined;
    set(key: string, value: T): void;
    delete(key: string): void;
}

export const newMemoryCache = <T>(maxLength: number): Cache<T> => {
    const cache: Record<string, T> = {};
    return {
        get: (key: string): T | undefined => cache[key],
        set: (key: string, value: T): void => {
            if (maxLength === 0) {
                return;
            }
            const keys = Object.keys(cache);
            if (keys.length === maxLength) {
                delete cache[keys[0]];
            }
            cache[key] = value;
        },
        delete: (key: string): void => {
            delete cache[key];
        },
    };
};

export const cachedRequest = <T>(observable: Rx.Observable<T>, cache: Cache<T>, key: string): Rx.Observable<T> => {
    const cachedValue = cache.get(key);
    if (cachedValue) {
        return Rx.of(cachedValue);
    }
    return observable.pipe(RxOperators.tap((value) => cache.set(key, value)));
};
