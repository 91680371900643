import { Subscription } from "rxjs";

export class SerialDisposable {
    // properties

    private _disposable: Subscription | null = null;

    // public functions

    public set disposable(value: Subscription | null) {
        this.dispose();
        this._disposable = value;
    }

    public dispose(): void {
        if (this._disposable == null || this._disposable.closed) {
            return;
        }
        this._disposable.unsubscribe();
        this._disposable = null;
    }
}
