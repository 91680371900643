import mapboxgl from "mapbox-gl";
import { TracksLayer } from "../../layers";
import { ORDER_LAYER_TRACKS } from "../Orders";
import { TracksModuleViewModel } from "./TracksModuleViewModel";
import { Location, Track } from "../../../../domain/model";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { LayerManager } from "../LayerManager";
import { TrackLayerOptions } from "../../layers/tracks/BaseTracksLayer";
import { BaseTracksModule } from "./BaseTracksModule";

export class TracksModule extends BaseTracksModule<TracksModuleViewModel, Track> {
    // Properties

    public constructor(
        protected readonly viewModel: TracksModuleViewModel,
        private readonly distanceFormatter: DistanceFormatter,
        private readonly tracksLayerOptions: TrackLayerOptions<Track>,
    ) {
        super(viewModel);
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const layer = TracksLayer.attachedTo(
            map,
            ORDER_LAYER_TRACKS.id,
            this.distanceFormatter,
            this.tracksLayerOptions,
            this.onTrackSelected,
            this.onTrackClickedInObservation,
        );

        layer.referenceAltitude = referenceLocation.altitude;
        const vm = this.viewModel;
        this.collectSubscriptions(
            vm.finishedTrackOpacity.subscribe((value) => layer.setFinishedTrackOpacity(value)),
            vm.showClassificationHistoryInTrajectory.subscribe((value) =>
                layer.setClassificationHistoryOnTrajectoryEnabled(value),
            ),
            vm.classifications.subscribe({
                next: (update) => layer.setClassifications(update),
                error: (error) => console.error(error),
            }),
            vm.visibleClassifications.subscribe((visibleClassifications) =>
                layer.setVisibleClassifications(visibleClassifications),
            ),
            vm.applyAltitudeFilterClassifications.subscribe((classificationNames) =>
                layer.setApplyAltitudeFilterClassifications(classificationNames),
            ),
            vm.trackObservationMode.subscribe((mode) => layer.setTrackObservationMode(mode)),
            vm.hasReliableAltitudeInfo.subscribe((value) => (layer.hasReliableAltitudeInfo = value)),
            vm.selectedTracksInObservation.subscribe((tracks) => layer.setSelectedTracksInObservation(tracks)),
            vm.observedTrackIds.subscribe((trackIds) => layer.setObservedTracks(trackIds)),
            vm.suppressClickForObservation.subscribe((value) => (layer.shouldSuppressClickForObservation = value)),
            vm.getSelectedTrackId().subscribe((trackId) => layer.focusOnTrack(trackId)),
            vm.showVrOnlyTracks().subscribe((value) => (layer.showVrOnlyTracks = value)),
        );
        this.subscribeToCommonEvents(layer);
    }

    // Private functions

    private onTrackClickedInObservation = (track: Track): void => {
        this.viewModel.toggleTrack(track);
    };
}
