import { enumValueOrDefault } from "../../utils/EnumUtils";

export enum DistanceUnit {
    METRIC = "metric",
    IMPERIAL = "imperial",
}

export const DEFAULT_DISTANCE_UNIT = DistanceUnit.METRIC;

export const getDistanceUnit = (value: string | null): DistanceUnit =>
    enumValueOrDefault(DistanceUnit, value, DEFAULT_DISTANCE_UNIT);
