import React from "react";
import styled, { css } from "styled-components";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { DeterrenceDeviceViewModel, DeterrenceEventViewModel, DeterrenceViewModel } from "./DeterrenceViewModel";
import { OldColors } from "../appearance/Colors";
import { VerticalSpace } from "../appearance/Space";
import { DeterrenceDeviceState } from "../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { t } from "i18next";
import { BASE_TEXT_STYLE } from "../appearance/theme/GlobalStyles";
import { RadioSwitch } from "../appearance/RadioSwitch";
import { Panel } from "../appearance/panels/Panel";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { PanelFooter } from "../appearance/panels/PanelFooter";
import { Button } from "../appearance/button/Button";
import { PanelSection } from "../appearance/panels/PanelSection";

enum Mode {
    Overview,
    Log,
}

const interactiveStates = [DeterrenceDeviceState.Available, DeterrenceDeviceState.Selected];
const deterrenceDeviceHoverCss = css`
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`;

const DeterrenceDeviceNameSpan = styled(RadioSwitch)<{ state: DeterrenceDeviceState }>`
    min-width: 30px;
    max-width: 150px;
    font-size: 16px;
    background-color: ${({ theme }) => theme.colors.status.info};
    ${({ state, theme }) => {
        switch (state) {
            case DeterrenceDeviceState.Disabled:
                return `
                    background-color: ${theme.colors.status.disabled};
                    color: ${theme.colors.text.text90};
                    hover {
                        opacity: 1;
                        background-color: ${theme.colors.status.disabled};
                        color: ${theme.colors.text.text90};
                    }
                `;
            case DeterrenceDeviceState.Error:
                return `
                    background-color: ${theme.colors.status.danger};
                `;
            case DeterrenceDeviceState.Available:
                return `
                    background-color: ${OldColors.primaryTint50};
                `;
            case DeterrenceDeviceState.Pending:
            case DeterrenceDeviceState.Activated:
                return `
                    background-color: ${theme.colors.status.success};
                `;
        }
    }}
    ${({ state }) => interactiveStates.includes(state) && deterrenceDeviceHoverCss}
`;

const DeterrenceDeviceRowItem = styled.span<{ textAlign: "left" | "right" }>`
    ${BASE_TEXT_STYLE}
    font-size: 16px;
    text-align: ${({ textAlign }) => textAlign};
`;

const HeaderItem = styled.span<{ textAlign: "left" | "right" }>`
    ${BASE_TEXT_STYLE}
    flex-grow: 1;
    text-align: ${({ textAlign }) => textAlign};
    :first-letter {
        text-transform: uppercase;
    }
`;

const ActivatedText = styled.span`
    text-transform: uppercase;
    text-align: center;
    position: relative;
    padding: 11px 30px 11px 30px;
    width: 100%;
    color: ${({ theme }) => theme.colors.status.success};
    flex-grow: 1;
    font-weight: 500;
    font-size: 14px;
`;

const DeviceOverviewContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-gap: 10px;
    align-items: center;
`;

const DeterrenceLogContainer = styled.div`
    flex-grow: 1;
    border: 1px solid ${OldColors.whiteInactive};
    border-radius: 4px;
    padding: 9px 12px;
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-color: ${OldColors.whiteInactive};
    height: 450px;
`;

const DeterrenceEventMessage = styled.div<{ isError: boolean }>`
    ${BASE_TEXT_STYLE}
    line-height: 1.57;
    ${({ isError, theme }) => `color: ${isError ? theme.colors.status.danger : theme.colors.text.text};`}
`;

interface Props {
    onClose: () => void;
}

interface State {
    mode: Mode;
    devices: DeterrenceDeviceViewModel[];
    isActivateDeviceButtonDisabled: boolean;
    activateDeviceButtonText: string;
    activatedDeviceText: string | null;
    isDeviceActivationInProgress: boolean;
    events: DeterrenceEventViewModel[];
}

export class Deterrence extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: DeterrenceViewModel = DI.get(TYPES.DeterrenceViewModel);

    public constructor(props: Props) {
        super(props);

        this.state = {
            mode: Mode.Overview,
            devices: [],
            isActivateDeviceButtonDisabled: false,
            activateDeviceButtonText: ``,
            activatedDeviceText: null,
            isDeviceActivationInProgress: false,
            events: [],
        };
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <Panel>
                <PanelHeader label={t("deterrence.birdDeterrence")} onClose={() => this.props.onClose()} />
                <PanelSection>
                    {this.state.mode === Mode.Overview ? this.renderOverview() : this.renderLog()}
                </PanelSection>
                <PanelFooter>
                    <Button
                        label={t(this.state.mode === Mode.Overview ? "deterrence.viewLog" : "deterrence.closeLog")}
                        layout="inline"
                        onClick={() => this.setLogModeEnabled(this.state.mode !== Mode.Log)}
                    />
                </PanelFooter>
            </Panel>
        );
    }

    public componentDidMount(): void {
        this.viewModel.setDeterrenceVisible(true);
        this.collectSubscriptions(
            this.viewModel.deviceViewModelsObservable.subscribe({
                next: (devices) => this.setState({ devices }),
                error: (error) => console.error(error),
            }),
            this.viewModel.activateDeviceButtonDisabledObservable.subscribe({
                next: (isActivateDeviceButtonDisabled) => this.setState({ isActivateDeviceButtonDisabled }),
                error: (error) => console.error(error),
            }),
            this.viewModel.activateDeviceButtonTextObservable.subscribe({
                next: (activateDeviceButtonText) => this.setState({ activateDeviceButtonText }),
                error: (error) => console.error(error),
            }),
            this.viewModel.activatedDeviceTextObservable.subscribe({
                next: (activatedDeviceText) => this.setState({ activatedDeviceText }),
                error: (error) => console.error(error),
            }),
            this.viewModel.deterrenceEventsObservable.subscribe({
                next: (events) => this.setState({ events }),
                error: (error) => console.error(error),
            }),
        );
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.viewModel.setDeterrenceVisible(false);
    }

    // Private functions

    private renderOverview(): React.ReactNode {
        return (
            <>
                <DeviceOverviewContainer>
                    <HeaderItem textAlign="left">{t("deterrence.device_other")}</HeaderItem>
                    <HeaderItem textAlign="right">{t("general.today")}</HeaderItem>
                    <HeaderItem textAlign="right">{t("deterrence.remaining")}</HeaderItem>
                    {this.state.devices.length > 0 &&
                        this.state.devices.map((device) => (
                            <React.Fragment key={device.id}>
                                <DeterrenceDeviceNameSpan
                                    state={device.state}
                                    onClick={() => this.viewModel.onDeterrenceDeviceClicked(device)}
                                    title={device.prettyName}
                                >
                                    {device.prettyName}
                                </DeterrenceDeviceNameSpan>
                                <DeterrenceDeviceRowItem textAlign="right">{device.used}</DeterrenceDeviceRowItem>
                                <DeterrenceDeviceRowItem textAlign="right">{device.remaining}</DeterrenceDeviceRowItem>
                            </React.Fragment>
                        ))}
                </DeviceOverviewContainer>
                {this.state.devices.length > 0 && (
                    <>
                        <VerticalSpace height={20} />
                        {this.state.activatedDeviceText ? (
                            <ActivatedText>{this.state.activatedDeviceText}</ActivatedText>
                        ) : (
                            <Button
                                disabled={
                                    this.state.isActivateDeviceButtonDisabled || this.state.isDeviceActivationInProgress
                                }
                                loading={this.state.isDeviceActivationInProgress}
                                label={this.state.activateDeviceButtonText}
                                onClick={() => this.activateSelectedDevices()}
                            />
                        )}
                    </>
                )}
            </>
        );
    }

    private renderLog(): React.ReactNode {
        return (
            <DeterrenceLogContainer>
                {this.state.events.map((event) => (
                    <DeterrenceEventMessage key={event.id} isError={event.isError}>
                        {event.message}
                    </DeterrenceEventMessage>
                ))}
            </DeterrenceLogContainer>
        );
    }

    private setLogModeEnabled(isEnabled: boolean): void {
        this.setState({ mode: isEnabled ? Mode.Log : Mode.Overview });
    }

    private activateSelectedDevices(): void {
        this.setState({ isDeviceActivationInProgress: true });
        const subscription = this.viewModel
            .activateSelectedDevices()
            .pipe(
                RxOperators.catchError((error) => {
                    console.error(error);
                    return Rx.EMPTY;
                }),
            )
            .subscribe({
                complete: () => {
                    this.setState({ isDeviceActivationInProgress: false });
                },
            });
        this.collectSubscription(subscription);
    }
}
