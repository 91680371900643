import styled from "styled-components";

/**
 * @deprecated Use `forms/InputField` instead.
 */
export const FormInputLabel = styled.label`
    display: inline-block;
    margin-top: 12px;
    margin-right: 25px;
    width: 200px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.5;
    text-transform: uppercase;
`;
