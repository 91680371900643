import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../../../BaseViewModel";
import { DateFormatter } from "../../../../infrastructure/DateFormatter";
import { ReplayRepository, ServerConfigRepository } from "../../../../domain/repositories";
import { TracksSnapshot } from "../../../../domain/model";
import { PlaybackState } from "../../../../domain/PlaybackScene";

export class ReplayTimeViewModel extends BaseViewModel {
    // Properties

    /**
     * Combines the timeSubject and the radarTimezoneOffsetFromUTC to a formatted time string
     */
    public get time(): Rx.Observable<string> {
        return Rx.combineLatest([this.timeSubject, this.serverConfigRepository.radarTimezoneOffsetFromUTC]).pipe(
            RxOperators.map(([time, timezoneOffset]) => {
                const date = time ? new Date(time) : new Date();
                return this.dateFormatter.formatFullDate(date, {
                    excludeDateToday: true,
                    timezoneOffset,
                });
            }),
        );
    }

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
        );
    }

    public get radarTimezoneOffsetFromUTC(): Rx.Observable<number | null> {
        return this.serverConfigRepository.radarTimezoneOffsetFromUTC;
    }

    private timeSubject = new Rx.BehaviorSubject<Date | null>(null);

    public constructor(
        private readonly dateFormatter: DateFormatter,
        private readonly replayRepository: ReplayRepository,
        private readonly serverConfigRepository: ServerConfigRepository,
    ) {
        super();

        this.collectSubscriptions(this.subscribeToReplayTime());
    }

    // Private functions

    private subscribeToReplayTime(): Rx.Subscription {
        return this.replayRepository.currentPlaybackScene
            .pipe(RxOperators.switchMap((scene) => (scene ? scene.tracks : Rx.NEVER)))
            .subscribe({
                next: (snapshot) => this.broadcastTime(snapshot),
                error: (error) => console.error(error),
            });
    }

    private broadcastTime(snapshot: TracksSnapshot | null): void {
        if (snapshot == null) {
            return;
        }
        this.timeSubject.next(new Date(snapshot.timestamp));
    }
}
