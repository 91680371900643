import styled from "styled-components";
import { OVERLAY_SPACING } from "../theme/Spacing";

export const PanelSection = styled.div`
    padding: ${OVERLAY_SPACING};
    position: relative;
    z-index: 0;

    :first-of-type {
        border-top-left-radius: 10px;
    }

    :nth-of-type(2):after {
        content: "";
        position: absolute;
        display: "block";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: ${({ theme }) => theme.colors.backgrounds.panelSection};
    }

    :last-of-type {
        :after {
            border-bottom-left-radius: 10px;
        }
    }
`;
