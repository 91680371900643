export const APP_CONFIG_KEYS = {
    API_HOST: "API_HOST",
    BV_API_HOST: "BV_API_HOST",
    DV_API_HOST: "DV_API_HOST",

    BV_API_PORT: "BV_API_PORT",
    DV_API_PORT: "DV_API_PORT",
    DV_API_WS_PORT: "DV_API_WS_PORT",

    API_SECURE: "API_SECURE",
    BV_API_SECURE: "BV_API_SECURE",
    DV_API_SECURE: "DV_API_SECURE",
    API_WS_SECURE: "API_WS_SECURE",
    BV_API_WS_SECURE: "BV_API_WS_SECURE",
    DV_API_WS_SECURE: "DV_API_WS_SECURE",

    API_PATH_PREFIX: "API_PATH_PREFIX",
    BV_API_PATH_PREFIX: "BV_API_PATH_PREFIX",
    DV_API_PATH_PREFIX: "DV_API_PATH_PREFIX",
    API_WS_PATH_PREFIX: "API_WS_PATH_PREFIX",
    DV_API_WS_PATH_PREFIX: "DV_API_WS_PATH_PREFIX",
    API_WS_RECONNECT_TIMEOUT_MILLIS: "SOCKET_RECONNECT_TIMEOUT_MILLIS",

    API_MULTIPLE_RADAR_LOCATIONS: "API_MULTIPLE_RADAR_LOCATIONS",

    AVAILABLE_LANGUAGES: "AVAILABLE_LANGUAGES",
    DEFAULT_LANGUAGE_CODE: "DEFAULT_LANGUAGE_CODE",

    HIDE_ROBIN_BRANDING: "HIDE_ROBIN_BRANDING",

    BIRD_TAB_URL: "BIRD_TAB_URL",

    EXTERNAL_LOGOUT_URL: "EXTERNAL_LOGOUT_URL",
};

export function getRuntimeConfig<T>(key: string): T | undefined {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const w = window as { [key: string]: any };

    // Check if variable is set in runtime config file
    const configSet = "RUNTIME_CONFIG";
    if (w[configSet] === undefined || w[configSet][key] == null) {
        return undefined;
    }
    return w[configSet][key] as T;
}

function getEnvConfig(key: string): string {
    return process.env["REACT_APP_" + key] || "";
}

export function getAppConfig(key: string): string {
    return getRuntimeConfig<string>(key) || getEnvConfig(key);
}
