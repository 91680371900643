import {
    DeterrenceEvent as DeterrenceEventProto,
    DeterrenceEventsList as DeterrenceEventsListProto,
} from "./proto/generated/deterrencedevices3_pb";
import { Location } from ".";

export class DeterrenceEvent {
    // Static functions

    public static fromProto(model: DeterrenceEventProto): DeterrenceEvent {
        return new DeterrenceEvent(
            model.getId(),
            model.getTimestampMsec(),
            model.getUser(),
            Location.fromProto(model.getTriggerposition()),
            Location.fromProto(model.getBirdposition()),
            model.getDuration(),
            model.getDeviceid(),
            model.getErrormessage(),
            model.getErrormessage().length > 0,
        );
    }

    public static arrayFromProto(model: DeterrenceEventsListProto): DeterrenceEvent[] {
        return model.getEventList().map(DeterrenceEvent.fromProto);
    }

    public constructor(
        public readonly id: int,
        public readonly timestamp: int,
        public readonly user: string,
        public readonly triggerPosition: Location,
        public readonly birdPosition: Location,
        public readonly duration: int,
        public readonly deviceId: int,
        public readonly message: string,
        public readonly isError: boolean,
        public deviceName?: string,
    ) {}
}
