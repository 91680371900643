import * as Rx from "rxjs";
import { BaseRepository } from "./BaseRepository";
import { UserSession } from "../model";

export interface SessionRepository extends BaseRepository {
    session: Rx.Observable<UserSession>;
    usernameForReauth: string | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loginBasicAuth(username: string, password: string): Rx.Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loginOpenIdConnect(oauthCode: string, redirectUri: string, nonce: string): Rx.Observable<any>;
    logout(): Rx.Observable<void>;

    subscribeToNewAccessTokens(): void;

    getOauthError(): Error | undefined;
    setOauthError(error: Error): void;
    clearOauthError(): void;
}

export const UNAUTHORIZED_ERROR_CODE = 16;
