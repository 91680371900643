import React from "react";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../../infrastructure/AppConfig";
import { ToolbarIconButton } from "../../appearance/ToolbarIconButton";

export const BirdTabButton = (): JSX.Element | null => {
    // Properties

    const birdTabButtonUrl = getRuntimeConfig<string>(APP_CONFIG_KEYS.BIRD_TAB_URL);

    // Render

    if (!birdTabButtonUrl) {
        return null;
    }

    return <ToolbarIconButton iconType="BIRD" onClick={() => window.open(birdTabButtonUrl, "_blank")} />;
};
