import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { OldColors } from "../../../appearance/Colors";
import CompassIcon from "../../../../res/images/compass_vehicle.svg";
import { t } from "i18next";

const CompassRoot = styled.div`
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    width: 60px;
    height: 80px;
`;

const Canvas = styled.canvas`
    margin: 0;
    padding: 0;
    width: 60px;
    height: 80px;
    background: transparent;
`;

export const Needle = styled.img.attrs({ src: CompassIcon })<{ rotation: number }>`
    position: absolute;
    left: 15px;
    top: 24px;
    width: 30px;
    height: 30px;
    transform: rotate(${(props) => props.rotation + "deg"});
`;

interface CompassProps {
    rotation?: number;
}

export const Compass = ({ rotation }: CompassProps): JSX.Element => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        if (!context) {
            return;
        }

        // Outer circle
        context.beginPath();
        context.lineWidth = 2;
        context.strokeStyle = OldColors.textSecondaryLighter;
        context.arc(30, 40, 25, 0, Math.PI * 2, true);
        context.stroke();
        context.closePath();

        // North indicator
        context.font = "10pt Roboto";
        context.textAlign = "center";
        context.fillStyle = OldColors.primaryTint;
        context.fillText(t("vehicleInfo.compassNorthShorthand"), 30, 10);
        context.closePath();
    }, []);

    return (
        <CompassRoot>
            <Canvas ref={canvasRef} height={80} width={60} />
            {rotation ? <Needle rotation={rotation} /> : null}
        </CompassRoot>
    );
};
