import React, { FC, SVGProps } from "react";
import { Observable } from "rxjs";
import styled from "styled-components";
import { useObservable } from "../../hooks";
import { OldColors } from "./Colors";

interface SwitchContainerProps {
    readonly isActive: boolean | undefined;
    readonly onClick: (newValue: boolean) => void;
}

const IconContainer = styled.div<SwitchContainerProps>`
    opacity: ${(props) => (props.isActive ? "100%" : "75%")};
    cursor: pointer;
    pointer-events: all;
    transition: all 0.15s;
    & {
        path {
            transition: all 0.3s;
            fill: ${(props) => (props.isActive ? OldColors.primaryTint : OldColors.whiteInactive)};
        }
    }
    &:hover {
        opacity: 100%;
    }
`;

/**
 * @deprecated If possible, use `forms/FormToggle` instead.
 */
const IconSwitch = (props: {
    icon: FC<
        SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    >;
    observableValue: Observable<boolean>;
    onChange: (newValue: boolean) => void;
}): JSX.Element => {
    const { icon, observableValue, onChange } = props;
    const isActive = useObservable(observableValue, false);
    const IconElement = icon;
    return (
        <IconContainer isActive={isActive} onClick={() => onChange(!isActive)}>
            <IconElement />
        </IconContainer>
    );
};

export default IconSwitch;
