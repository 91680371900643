import styled from "styled-components";
import { Form } from "../appearance/forms/Form";

export const LoginFormContainer = styled(Form)`
    justify-content: space-between;
    align-items: center;
    min-width: 350px;
    width: auto;

    button {
        margin-top: ${({ theme }) => theme.spacing.x4};
    }
`;
