import { BaseViewModel } from "../../../../BaseViewModel";
import { TrackRepository, ReplayRepository, LocationInfoRepository } from "../../../../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { nonNullObservable } from "../../../../../utils/RxUtils";
import { t } from "i18next";
import { PlaybackState } from "../../../../../domain/PlaybackScene";

const TIME_ERROR_THRESHOLD_MSEC = 3_000;

export interface TimeStatus {
    isSimulation: boolean;
    errorText?: string;
}

export class DataSimulationIndicatorViewModel extends BaseViewModel {
    // Properties

    public get timeStatus(): Rx.Observable<TimeStatus> {
        return Rx.combineLatest([this.isSimulation, this.timeDifference]).pipe(
            RxOperators.map(([isSimulation, timeDifference]) => ({
                isSimulation,
                errorText: this.getErrorText(timeDifference),
            })),
            RxOperators.distinctUntilChanged(),
        );
    }

    private get isSimulation(): Rx.Observable<boolean> {
        return this.locationInfoRepository.isSimulation;
    }

    private get timeDifference(): Rx.Observable<number> {
        return Rx.combineLatest(
            [this.isSimulation, this.replayRepository.currentPlaybackScene],
            (isSimulation, scene) => !isSimulation && scene == null,
        ).pipe(
            RxOperators.switchMap((showTimeDiff) =>
                showTimeDiff ? this.calculateServerToSystemTimeDifference() : Rx.of(0),
            ),
            RxOperators.distinctUntilChanged(),
        );
    }

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
        );
    }

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {
        super();
    }

    // Private functions

    private calculateServerToSystemTimeDifference(): Rx.Observable<number> {
        // This observable is used to display a warning to the user about the difference between the api data timestamp and the system timestamp
        return nonNullObservable(this.trackRepository.tracksSnapshot).pipe(
            RxOperators.map((snapshot) => snapshot.systemTimestamp - snapshot.timestamp),
        );
    }

    private getErrorText(timeDifference: number): string | undefined {
        if (Math.abs(timeDifference) < TIME_ERROR_THRESHOLD_MSEC) {
            return undefined;
        }
        const seconds = Math.round(Math.abs(timeDifference / 1_000));

        const behindOrAhead = timeDifference > 0 ? t("general.behind") : t("general.ahead");
        const errorText = `${t("status.outOfSyncMessage", {
            behindOrAhead,
            seconds,
            unit: t("unit.second_shorthand"),
        })} `;
        return errorText;
    }
}
