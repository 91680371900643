import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../../../BaseViewModel";
import { AlarmHistoryRepository } from "../../../../domain/repositories/AlarmHistoryRepository";
import { Alarm, LocalUserPreferenceKeys } from "../../../../domain/model";
import { ReplayRepository, LocalPreferencesRepository } from "../../../../domain/repositories";
import { PlaybackState } from "../../../../domain/PlaybackScene";

export class AlarmHistoryViewModel extends BaseViewModel {
    // Properties

    public get history(): Rx.Observable<Alarm[]> {
        return this.alarmHistoryRepository?.history ?? Rx.EMPTY;
    }

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly alarmHistoryRepository?: AlarmHistoryRepository,
    ) {
        super();
    }

    // Public functions

    public deleteAlarms(databaseIds: number[]): Rx.Observable<void> {
        if (!this.alarmHistoryRepository) {
            return Rx.EMPTY;
        }
        return this.alarmHistoryRepository.deleteAlarms(databaseIds);
    }

    public requestReplay(alarm: Alarm): void {
        const start = alarm.track.startTime! - 1000;
        const end = alarm.track.endTime! + 1000;
        const scene = this.replayRepository.makeNewPlaybackScene(start, end);
        scene.playFrom(start, this.getReplaySpeed());
    }

    public get isNotReplaying(): Rx.Observable<boolean> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
            RxOperators.map((state) => state == null || state === PlaybackState.STOPPED),
        );
    }

    // Private functions

    private getReplaySpeed(): number {
        return this.localPreferencesRepository.getPreference<number>(LocalUserPreferenceKeys.replay.speed)!;
    }
}
