import { BaseViewModel } from "../../../../BaseViewModel";
import { LocalPreferencesRepository, LocationInfoRepository } from "../../../../../domain/repositories";
import { ALARMS_ENABLED_KEYS, ALARM_SOUND_ENABLED_KEYS, LocalUserPreferenceKeys } from "../../../../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { FlavorConfig } from "../../../../../infrastructure/FlavorConfig";

export class AlarmStatusIndicatorViewModel extends BaseViewModel {
    // Properties

    /**
     * Returns an observable that emits the alarm notification disabled status.
     * @returns true if any alarm notification is disabled, false otherwise.
     */
    public isAnyAlarmNotificationDisabled = this.isAnyAlarmDisabled(ALARMS_ENABLED_KEYS);

    /**
     * Returns an observable that emits the alarm sound disabled status.
     * @returns true if any alarm sound is disabled, false otherwise.
     */
    public isAnyAlarmSoundDisabled = this.isAnyAlarmDisabled(ALARM_SOUND_ENABLED_KEYS);

    private isAnyAlarmDisabled(preferenceKeys: string[]): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasRunways.pipe(
            RxOperators.switchMap((hasRunways) =>
                Rx.combineLatest(
                    preferenceKeys.map((key) => {
                        if (!this.checkIfAlarmIsAvailable(key, hasRunways)) {
                            return Rx.of(true);
                        }
                        return this.localPreferencesRepository.observePreference(key);
                    }),
                ),
            ),
            RxOperators.map((values) => values.some((value) => value === false)),
            RxOperators.distinctUntilChanged(),
        );
    }

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly flavorConfig: FlavorConfig,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {
        super();
    }

    // Private functions

    private checkIfAlarmIsAvailable(key: string, hasRunways: boolean): boolean {
        switch (key) {
            case LocalUserPreferenceKeys.alarm.alarmTrack:
            case LocalUserPreferenceKeys.alarm.soundEnabledTrack:
                return this.flavorConfig.availableAlarms.trackAlarm;
            case LocalUserPreferenceKeys.alarm.areaEntry:
            case LocalUserPreferenceKeys.alarm.soundEnabledAreaEntry:
                return this.flavorConfig.availableAlarms.areaAlarm;
            case LocalUserPreferenceKeys.alarm.alarmFunnelTraffic:
            case LocalUserPreferenceKeys.alarm.soundEnabledFunnelTraffic:
                return hasRunways && this.flavorConfig.availableAlarms.funnelTrafficAlarm;
            case LocalUserPreferenceKeys.alarm.alarmRunwayCrossing:
            case LocalUserPreferenceKeys.alarm.soundEnabledRunwayCrossing:
                return hasRunways && this.flavorConfig.availableAlarms.runwayCrossingsAlarm;
            default:
                return false;
        }
    }
}
