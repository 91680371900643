import { BaseViewModel } from "../BaseViewModel";
import { MapRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import { TileProvider } from "../../domain/model";
import * as RxOperators from "rxjs/operators";

export class TileProvidersViewModel extends BaseViewModel {
    // Properties

    public get tileProviders(): Rx.Observable<TileProvider[]> {
        return this.mapRepository.tileProviders;
    }

    public constructor(private readonly mapRepository: MapRepository) {
        super();
    }

    // Public functions

    public getSelectedTileProviderId(): Rx.Observable<int> {
        return this.mapRepository.getSelectedTileProvider().pipe(RxOperators.map((p) => p.id));
    }

    public setSelectedTileProviderId(id: int): void {
        this.mapRepository.setSelectedTileProviderId(id);
    }
}
