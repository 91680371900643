import React from "react";
import styled from "styled-components";
import { Colors, OldColors } from "./Colors";
import { ArrowDownIcon } from "./Arrow";
import { keyCodes } from "../../utils/KeyCodes";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { SelectOption } from "../../domain/model/SelectOption";
import { t } from "i18next";

const DEFAULT_ANIMATION_DURATION_MILLIS = 250;

const Container = styled.div<{ borderColor: string; borderRadius: number; truncate?: boolean }>`
    position: relative;
    display: flex;
    border: 1px solid ${(props) => props.borderColor};
    border-radius: ${(props) => props.borderRadius + "px"};
    color: white;
    font-weight: 500;
    font-size: 14px;
    background-color: black;
    height: 40px;
    label {
        cursor: pointer;
        padding: 11px 20px 11px 20px;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        ${({ truncate, theme }) =>
            truncate
                ? `
                max-width: ${theme.spacing.x32};
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                `
                : ""}
    }
`;

const OptionsContainer = styled.div<{ isOpen: boolean; borderRadius: number }>`
    transform-origin: 50% 0%;
    transform: scaleY(${(props) => (props.isOpen ? 1 : 0)});
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    transition: all ${DEFAULT_ANIMATION_DURATION_MILLIS}ms ease-out;
    z-index: 1004;
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    top: 33px;
    background-color: black;
    border-radius: 0 0 ${(props) => props.borderRadius + "px " + props.borderRadius + "px"};
    left: -1px;
    right: -1px;
    border: 1px solid ${({ theme }) => theme.colors.secondary.blue};
    border-top: 0;
    box-shadow: 0px 14px 16px 1px black;
    padding-top: 5px;
    div {
        border-radius: 0 0 ${(props) => props.borderRadius + "px " + props.borderRadius + "px"};
    }
`;

const OptionItem = styled.div<{ isSelected: boolean }>`
    padding: 11px 20px 11px 20px;
    min-height: 38px;
    background-color: ${(props) => (props.isSelected ? OldColors.backgroundPrimaryDark : "black")};
    border-top: 1px solid ${({ theme }) => theme.colors.secondary.blue};
    color: white;
    cursor: pointer;
    :hover {
        background-color: ${OldColors.backgroundPrimaryDark};
    }
`;

const Arrow = styled.img<{ isOpen: boolean }>`
    opacity: 0.5;
    transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
    transition: transform ${DEFAULT_ANIMATION_DURATION_MILLIS}ms ease-out;
`;

interface RoundSelectProps {
    disabled: boolean;
    options: SelectOption[];
    selectedOptionId: string | null;
    observableSelectedOptionId?: Rx.Observable<string | null>;
    placeholder: string;
    alwaysShowBorder: boolean;
    borderRadius: number;
    truncate?: boolean;
    onChange?: (option: string) => void;
}

interface State {
    selectedOptionId: string | null;
    text: string;
    isOpen: boolean;
    errorOccurred: boolean;
}

export class RoundSelect extends BaseSubscriptionHandlerComponent<RoundSelectProps, State> {
    // Properties

    public static defaultProps = {
        disabled: false,
        selectedOptionId: null,
        observableValue: null,
        placeholder: "",
        alwaysShowBorder: false,
        borderRadius: 100,
    };

    public get value(): SelectOption | null {
        return this.getSelectedOptionById(this.state.selectedOptionId);
    }

    // Lifecycle

    public constructor(props: RoundSelectProps) {
        super(props);

        let text = props.placeholder;
        const selectedOption = this.getSelectedOptionById(props.selectedOptionId);
        if (selectedOption) {
            text = selectedOption.title;
        }
        this.state = {
            selectedOptionId: props.selectedOptionId,
            text: text,
            isOpen: false,
            errorOccurred: false,
        };
    }

    public componentDidMount(): void {
        this.subscribeToObservableValueIfAvailable();
    }

    // Public functions

    public render(): JSX.Element {
        return (
            <Container
                borderColor={this.getBorderColor()}
                borderRadius={this.props.borderRadius}
                onClick={() => this.openClose(true)}
                onFocus={() => this.openClose(true)}
                onBlur={() => this.openClose(false)}
                onKeyDown={this.handleKeyPress}
                tabIndex={0}
                truncate={this.props.truncate}
            >
                <label>
                    <span
                        style={{
                            opacity: this.state.selectedOptionId === null || this.props.disabled ? 0.5 : 1,
                        }}
                    >
                        {this.state.text}
                    </span>
                    <Arrow
                        isOpen={this.state.isOpen}
                        src={ArrowDownIcon}
                        alt={t("general.imageAltTextRoundSelectArrow")}
                    />
                </label>
                <OptionsContainer
                    isOpen={this.state.isOpen && !this.props.disabled}
                    borderRadius={this.props.borderRadius}
                >
                    {this.props.options.map((option) => (
                        <OptionItem
                            key={option.id}
                            onClick={(e) => this.onSelectOption(e, option)}
                            isSelected={option.id === this.state.selectedOptionId}
                        >
                            {option.title}
                        </OptionItem>
                    ))}
                </OptionsContainer>
            </Container>
        );
    }

    public showError(): void {
        this.setState({ errorOccurred: true });
    }

    public hideError(): void {
        this.setState({ errorOccurred: false });
    }

    // Private functions

    private openClose = (isOpen: boolean): void => {
        if (this.props.disabled) {
            return;
        }
        this.hideError();
        this.setState({ isOpen });
    };

    private handleKeyPress = (e: React.KeyboardEvent): void => {
        switch (e.keyCode) {
            case keyCodes.DOM_VK_RETURN:
            case keyCodes.DOM_VK_ENTER:
            case keyCodes.DOM_VK_SPACE:
                this.openClose(!this.state.isOpen);
                break;
            case keyCodes.DOM_VK_UP:
                this.onKeyUpDown("up");
                break;
            case keyCodes.DOM_VK_DOWN:
                this.onKeyUpDown("down");
                break;
            default:
                return;
        }
    };

    private onKeyUpDown = (direction: "up" | "down"): void => {
        const options = this.props.options;
        let newOptionIndex = 0;
        options.forEach((option, index) => {
            if (option.id === this.state.selectedOptionId) {
                if (direction === "up") {
                    newOptionIndex = index - 1;
                } else {
                    newOptionIndex = index + 1;
                }
            }
        });

        const length = options.length;
        if (newOptionIndex === length) {
            // reached the end of the list, select the first item
            newOptionIndex = 0;
        } else if (newOptionIndex === -1) {
            // reached the start of the list, select the last item
            newOptionIndex = length - 1;
        }

        this.setState({
            selectedOptionId: options[newOptionIndex].id,
            text: options[newOptionIndex].title,
        });
    };

    private onSelectOption = (e: React.MouseEvent, option: SelectOption): void => {
        e.stopPropagation();
        this.hideError();
        if (this.props.onChange) {
            this.props.onChange(option.id);
        }
        this.setState({
            isOpen: false,
            selectedOptionId: option.id,
            text: option.title,
        });
    };

    private getSelectedOptionById = (id: string | null): SelectOption | null => {
        const selectedOption = this.props.options.find((option) => option.id === id);
        if (selectedOption) {
            return selectedOption;
        }
        return null;
    };

    private getBorderColor = (): string => {
        if (this.state.errorOccurred) {
            return Colors.status.danger;
        }
        if (!this.props.alwaysShowBorder) {
            return "black";
        }
        return Colors.status.info;
    };

    private subscribeToObservableValueIfAvailable(): void {
        if (this.props.observableSelectedOptionId == null) {
            return;
        }

        const subscription = this.props.observableSelectedOptionId.subscribe((v) =>
            this.setState({ selectedOptionId: v, text: this.props.options.find((o) => o.id == v)?.title || "" }),
        );
        this.collectSubscription(subscription);
    }
}
