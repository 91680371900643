import mapboxgl, { GeoJSONSourceRaw, ImageSourceRaw } from "mapbox-gl";
import turfDistance from "@turf/distance";
import * as turfHelpers from "@turf/helpers";
import { renderToStaticMarkup } from "react-dom/server";
import { Location } from "../domain/model";
import distance from "@turf/distance";
import midpoint from "@turf/midpoint";

const EARTH_RADIUS = 6378.137; // Earth radius at equator in km
const TILE_SIZE = 512; // Map tile size in pixels
export const FEET_PER_MILE = 5280; // Number of feet in a mile

export const EMPTY_FEATURE_COLLECTION: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: [],
};

export const EMPTY_GEOJSON_SOURCE: GeoJSONSourceRaw = {
    type: "geojson",
    data: EMPTY_FEATURE_COLLECTION,
};

export const EMPTY_IMAGE_SOURCE: ImageSourceRaw = {
    type: "image",
    // Empty image
    url: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    coordinates: [
        [0, 0],
        [1, 0],
        [1, 1],
        [0, 1],
    ],
};

export const DEFAULT_MAP_STYLE_URL = "/mapstyle.default.json";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateStops(): any[][] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stops: any[][] = [];
    for (let i = -360; i < 360; i++) {
        stops.push([i, i]);
    }
    return stops;
}

export const ROTATION_STOPS = generateStops();

export function latLngPlusKM(latLng: double[], dx: double, dy: double): double[] {
    const latitude = latLng[0];
    const longitude = latLng[1];
    const newLatitude = latitude + (dy / EARTH_RADIUS) * (180 / Math.PI);
    const newLongitude = longitude + ((dx / EARTH_RADIUS) * (180 / Math.PI)) / Math.cos((latitude * Math.PI) / 180);
    return [newLatitude, newLongitude];
}

export function lngLatPlusKM(lngLat: double[], dx: double, dy: double): double[] {
    const result = latLngPlusKM([lngLat[1], lngLat[0]], dx, dy);
    return [result[1], result[0]];
}

export function loadImageFromSVG(svg: string, onload: (image: HTMLImageElement) => void): void {
    const image = new Image();
    image.onload = () => {
        onload(image);
    };
    image.src = svg;
}

export function encodeSvg(reactElement: React.ReactElement): string {
    return "data:image/svg+xml," + escape(renderToStaticMarkup(reactElement));
}

export function flyToIfFarAway(
    map: mapboxgl.Map,
    target: Location,
    cameraCenter: Location,
    distanceThresholdMeters: number,
): void {
    const currentDistance = turfDistance(target.toGeoJSONLocation(), cameraCenter.toGeoJSONLocation(), {
        units: "meters",
    });
    if (currentDistance > distanceThresholdMeters) {
        map.flyTo({ center: [target.longitude, target.latitude] });
    }
}

export function getMetersPerPixel(map: mapboxgl.Map): number {
    const latitudeRadians = turfHelpers.degreesToRadians(map.getCenter().lat);
    const equatorialCircumferenceMeters = 2 * Math.PI * EARTH_RADIUS * 1_000;
    const zoomLevel = map.getZoom();
    // Formula taken from here: https://wiki.openstreetmap.org/wiki/Zoom_levels
    const metersPerTile = (equatorialCircumferenceMeters * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel);
    return metersPerTile / TILE_SIZE;
}

/**
 * Determines if the given position is within the bounds of the map.
 * @param map The mapbox map to check the position against.
 * @param position The position to check.
 * @returns True if the position is within the bounds of the map, false otherwise.
 */
export function isPositionInMapBounds(map: mapboxgl.Map, position: turfHelpers.Position): boolean {
    const bounds = map.getBounds();
    const isInside = bounds.contains([position[0], position[1]]);
    return isInside;
}

/**
 * Converts the corners of the map bounds to an object of Turf.js points.
 * @param map The mapbox map to get the corners of.
 * @returns The corners of the map as Turf.js points.
 */
function mapBoundsToTurfPoints(map: mapboxgl.Map): {
    topLeft: turfHelpers.Feature<turfHelpers.Point>;
    topRight: turfHelpers.Feature<turfHelpers.Point>;
    bottomLeft: turfHelpers.Feature<turfHelpers.Point>;
    bottomRight: turfHelpers.Feature<turfHelpers.Point>;
} {
    const bounds = map.getBounds();
    const topLeft = turfHelpers.point([bounds.getWest(), bounds.getNorth()]);
    const topRight = turfHelpers.point([bounds.getEast(), bounds.getNorth()]);
    const bottomLeft = turfHelpers.point([bounds.getWest(), bounds.getSouth()]);
    const bottomRight = turfHelpers.point([bounds.getEast(), bounds.getSouth()]);
    return { topLeft, topRight, bottomLeft, bottomRight };
}

/**
 * Get the shortest diagonal of the map in the given units.
 * @param map The mapbox map to get the diagonal of.
 * @param units The units to calculate the diagonal in.
 * @returns The shortest diagonal of the map in the given units.
 */
export function getMinMapDiagonal(map: mapboxgl.Map, units: turfHelpers.Units): number {
    const { topLeft, topRight, bottomLeft, bottomRight } = mapBoundsToTurfPoints(map);

    // Calculate distances from top-left to bottom-right corner of the map
    const distances = [distance(topLeft, bottomRight, { units }), distance(bottomLeft, topRight, { units })];

    // Determine the minimum distance between map corners
    const minDistance = Math.min(...distances);

    return minDistance;
}

/**
 * Get the maximum distance from the given position to any corner of the map in the given units.
 * @param map The mapbox map in which to calculate the distance.
 * @param position The position from which to calculate the distance.
 * @param units The units to calculate the distance in.
 * @returns The maximum distance from the given position to any corner of the map in the given units.
 */
export function getMaxDistanceFromPosition(
    map: mapboxgl.Map,
    position: turfHelpers.Position,
    units: turfHelpers.Units,
): number {
    const corners = mapBoundsToTurfPoints(map);

    // Calculate distances from position to each corner of the map
    const distances = Object.values(corners).map((corner) => distance(position, corner, { units }));

    // Determine the maximum distance from the position to a map corner
    const maxDistance = Math.max(...distances);

    return maxDistance;
}

/**
 * Get the radius step for the range circles.
 * @param maxStep Maximum value for the radius step.
 * @param units Units of the radius step.
 * @returns Returns a radius step that is a multiple of 0.5 and closest to the given max step.
 */
export function getRangeRadiusStep(maxStep: number, units: turfHelpers.Units): number {
    let maxRadiusStep = maxStep;

    // Convert max step to miles if units are feet and max step is greater than 1 mile
    if (units === "feet" && maxStep > FEET_PER_MILE) {
        maxRadiusStep /= FEET_PER_MILE;
    }

    // Reduce max step by 35% to get a more reasonable radius step
    maxRadiusStep = maxRadiusStep * 0.65;

    // Get power of 10 that is closest to the radius step but smaller than it
    const factor = Math.pow(10, Math.floor(Math.log10(maxRadiusStep))) * 0.5;

    // Calculate radius step as a multiple of the factor
    let radiusStep = Math.floor(maxRadiusStep / factor) * factor;

    // Convert radius step back to feet if we converted max step to miles
    if (units === "feet" && maxStep > FEET_PER_MILE) {
        radiusStep *= FEET_PER_MILE;
    }

    // Return the maximum of the radius step and 150 feet or 50 meters
    if (units === "feet") {
        return Math.max(radiusStep, 150);
    } else {
        return Math.max(radiusStep, 50);
    }
}

/**
 * Get an array of radius values for the range circles that are within the bounds of the map.
 * @param map The mapbox map in which to calculate the radius values.
 * @param position The position from which to calculate the radius values.
 * @param step The step between each radius value.
 * @param units The units of the radius values.
 * @returns An array of radius values for the range circles that are within the bounds of the map.
 */
export function getRangeRadiusArrayWithinBounds(
    map: mapboxgl.Map,
    position: turfHelpers.Position,
    step: number,
    units: turfHelpers.Units,
): { radius: number; units: turfHelpers.Units }[] {
    const rangeRadiusArray: { radius: number; units: turfHelpers.Units }[] = [];

    // If the position is within the bounds of the map, we generate rings from the step to the furthest corner
    if (isPositionInMapBounds(map, position)) {
        const maxDistanceFromPosition = getMaxDistanceFromPosition(map, position, units);
        for (let i = step; i < maxDistanceFromPosition; i += step) {
            rangeRadiusArray.push({ radius: i, units });
        }
        return rangeRadiusArray;
    }

    // If the position is outside the bounds of the map, we generate rings from the closest to the furthest corner or edge

    // Convert bounds to Turf.js points
    const corners = mapBoundsToTurfPoints(map);

    // Calculate distances from the position to each corner and edge of the map
    const distances = [
        ...Object.values(corners),
        midpoint(corners.topLeft, corners.topRight),
        midpoint(corners.topRight, corners.bottomRight),
        midpoint(corners.bottomRight, corners.bottomLeft),
        midpoint(corners.bottomLeft, corners.topLeft),
    ].map((corner) => distance(position, corner, { units }));

    // Determine the minimum and maximum distances from the position to a map corner
    const minDistance = Math.min(...distances);
    const maxDistance = Math.max(...distances);

    // Get first multiple of step that is greater than min distance
    const minDistanceRoundingFactor = Math.ceil(minDistance / step);
    const minDistanceRounded = minDistanceRoundingFactor * step;

    for (let i = minDistanceRounded; i < maxDistance; i += step) {
        rangeRadiusArray.push({ radius: i, units });
    }

    return rangeRadiusArray;
}
