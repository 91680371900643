import React from "react";
import { LoginViewModel } from "./LoginViewModel";
import { TYPES } from "../../di/Types";
import { FullscreenLoading } from "../appearance/FullscreenLoading";
import { useViewModel } from "../../hooks/useViewModel";
import { useObservable } from "../../hooks";
import { BasicAuthForm } from "./BasicAuthForm";
import { AuthMethod, ServerConfig } from "../../domain/model";
import { OpenIdConnectForm } from "./OpenIdConnectForm";
import { NoAuthForm } from "./NoAuthForm";

/**
 * Checks the auth method and renders the appropriate login form.
 */
export const Login = (): React.ReactElement => {
    // Properties

    const viewModel: LoginViewModel = useViewModel(TYPES.LoginViewModel);
    const serverConfig: ServerConfig | undefined = useObservable(viewModel.serverConfig, undefined);
    const currentUsername: string | undefined = useObservable(viewModel.currentUsername, undefined);

    // Render

    if (!serverConfig) {
        return <FullscreenLoading />;
    }

    // For noAuth just render a loading page while the call happens in the background
    if (serverConfig.authMethod === AuthMethod.NoAuth) {
        return <NoAuthForm />;
    }

    // For oAuth render simple button with link
    if (serverConfig.authMethod === AuthMethod.OpenIdConnect) {
        return <OpenIdConnectForm currentUsername={currentUsername} />;
    }

    // Default to basic auth
    return <BasicAuthForm currentUsername={currentUsername} />;
};
