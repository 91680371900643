import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../../BaseViewModel";
import {
    LocalPreferencesRepository,
    TrackRepository,
    TracksHistogramRepository,
    UIControlRepository,
} from "../../../domain/repositories";
import { isSameDay, setHours, setMinutes } from "date-fns";
import { LocalUserPreferenceKeys, Range, TracksHistogram } from "../../../domain/model";
import { debounceTimeExceptFirst } from "../../../utils/RxUtils";
import { ReplayAvailability } from "./ReplayAvailability";
import { TimeDisplayMode } from "../../../domain/model/TimeDisplayMode";

export const REPLAY_DURATIONS_INTERVAL_MINUTES = 15;
export const REPLAY_DURATIONS_TOTAL_MINUTES = 120;

export class ReplayRequestViewModel extends BaseViewModel {
    // Properties

    public readonly minDate: Date = setHours(setMinutes(new Date(), 0), 0);
    public readonly maxDate: Date = new Date();
    public readonly maxTimeObservable: Rx.Observable<Date>;
    public readonly selectedDateObservable: Rx.Observable<Date>;
    public readonly defaultDateObservable: Rx.Observable<Date>;
    public readonly replayAvailabilityObservable: Rx.Observable<ReplayAvailability>;
    public readonly timeDisplayMode: TimeDisplayMode = this.localPreferencesRepository.getPreference(
        LocalUserPreferenceKeys.appearance.timeDisplayMode,
    )!;

    private readonly timezoneOffset =
        this.timeDisplayMode === TimeDisplayMode.UTC ? new Date().getTimezoneOffset() * -60000 : 0;
    private readonly selectedDateSubject = new Rx.Subject<Date>();
    private readonly maxTimeSubject = new Rx.Subject<Date>();
    private readonly replayAvailabilitySubject = new Rx.BehaviorSubject(ReplayAvailability.CHECKING);
    private readonly replayAvailabilityRequestSubject = new Rx.Subject<{ range: Range; interval: number }>();

    public constructor(
        private readonly trackRepository: TrackRepository,
        private readonly histogramRepository: TracksHistogramRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly uiControlRepository: UIControlRepository,
    ) {
        super();

        this.maxDate.setTime(this.maxDate.getTime() - this.timezoneOffset);
        this.maxTimeObservable = this.maxTimeSubject.asObservable();
        this.selectedDateObservable = this.selectedDateSubject.asObservable();
        this.defaultDateObservable = this.trackRepository.tracksSnapshot.pipe(
            RxOperators.take(1),
            RxOperators.map((snapshot) => {
                const defaultDate = snapshot ? new Date(snapshot.timestamp) : new Date();
                return this.getDateTimeWithOffset(defaultDate);
            }),
        );
        this.replayAvailabilityObservable = this.replayAvailabilitySubject.asObservable();

        this.collectSubscriptions(
            // Update max time when selected date changes
            this.selectedDateObservable.subscribe((selectedDate) => this.updateMaxTime(selectedDate)),
            debounceTimeExceptFirst(this.replayAvailabilityRequestSubject, 500)
                .pipe(
                    // switchMap instead of mergeMap makes sure we stop an in-progress api call if a new request
                    // should be made.
                    RxOperators.switchMap(({ range, interval }) =>
                        this.histogramRepository.getTracksHistogram(range[0], range[1], interval).pipe(
                            // If one API call fails, the whole subscription will stop working because an error ends
                            // the emission of the observable. We avoid that by materializing the observable.
                            RxOperators.materialize(),
                        ),
                    ),
                )
                .subscribe((notification) => this.processRequestAvailabilityResponse(notification)),
        );
    }

    // Public functions

    public selectDate(date: Date | null): void {
        date && this.selectedDateSubject.next(date);
    }

    public checkReplayAvailability(timeRange: Range): void {
        this.replayAvailabilitySubject.next(ReplayAvailability.CHECKING);

        const [start, end]: Range = [Math.round(timeRange[0] / 1000), Math.round(timeRange[1] / 1000)];
        const interval = end - start;
        this.replayAvailabilityRequestSubject.next({ range: [start, end], interval });
    }

    public calculateRequestRange(selectedDate: Date, duration: number): Range {
        const now = Date.now();
        const startTimestamp = selectedDate.getTime() + this.timezoneOffset;
        const endTimestamp = Math.min(startTimestamp + duration, now);
        return [startTimestamp, endTimestamp];
    }

    public closeReplayRequestPanel(): void {
        this.uiControlRepository.toggleHomeUIComponent("isRequestingReplayVisible", { isVisible: false });
    }

    // Private functions

    private getDateTimeWithOffset(date: Date = new Date()): Date {
        date.setTime(date.getTime() - this.timezoneOffset - REPLAY_DURATIONS_INTERVAL_MINUTES * 60000);
        return date;
    }

    private updateMaxTime(selectedDate: Date): void {
        let maxAllowed: Date;
        if (isSameDay(selectedDate, new Date())) {
            maxAllowed = new Date();
            maxAllowed.setTime(maxAllowed.getTime() - this.timezoneOffset);
        } else {
            maxAllowed = setHours(setMinutes(new Date(), 59), 23);
        }
        this.maxTimeSubject.next(maxAllowed);
    }

    private processRequestAvailabilityResponse(notification: Rx.ObservableNotification<TracksHistogram>): void {
        switch (notification.kind) {
            case "N":
                const histogram = notification.value;
                // If all the counts are zero, there is no track activity in the requested period
                const isEmpty = histogram.counts.every((c) => c == 0);
                this.replayAvailabilitySubject.next(
                    isEmpty ? ReplayAvailability.NOT_AVAILABLE : ReplayAvailability.AVAILABLE,
                );
                break;
            case "E":
                this.replayAvailabilitySubject.next(ReplayAvailability.NOT_AVAILABLE);
                console.error(notification.error);
                break;
        }
    }
}
