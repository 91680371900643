import styled from "styled-components";
import React from "react";
import { t } from "i18next";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { ReplaySpeedButton } from "./ReplaySpeedButton";
import { ReplaySeekbar } from "./ReplaySeekbar";
import { ReplayTime } from "./ReplayTime";
import { PlaybackState } from "../../../domain/PlaybackScene";
import { ReplayControlsViewModel } from "./ReplayControlsViewModel";
import { TYPES } from "../../../di/Types";
import DI from "./../../../di/DI";
import { SIDEBAR_WIDTH } from "../../appearance/Sidebar";
import { Button } from "../../appearance/button/Button";

export const REPLAY_CONTROLS_HEIGHT = 62;
const ANIMATION_DURATION_IN_SEC = 0.3;

const Container = styled.div<{ height: number }>`
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: calc(100vw - ${SIDEBAR_WIDTH});
    height: ${(props) => props.height}px;
    background-color: ${({ theme }) => theme.colors.secondary.blue};
    transition: height ${ANIMATION_DURATION_IN_SEC}s ease-in-out;
    z-index: 1001;
`;

interface State {
    height: number;
    isPlaying: boolean;
    isLoading: boolean;
    currentTime: Date;
    startTime: Date;
    endTime: Date;
}

export class ReplayControls extends BaseSubscriptionHandlerComponent<{}, State> {
    // Properties

    private viewModel: ReplayControlsViewModel = DI.get(TYPES.ReplayControlsViewModel);

    public constructor(props: Readonly<{}>) {
        super(props);

        this.state = {
            height: 0,
            isPlaying: true,
            isLoading: false,
            currentTime: new Date(),
            startTime: new Date(),
            endTime: new Date(),
        };
    }

    public componentDidMount(): void {
        document.body.classList.add("replay-controls");
        setTimeout(() => {
            this.setState({ height: REPLAY_CONTROLS_HEIGHT });
        });

        this.collectSubscriptions(
            this.viewModel.time.subscribe((time) => {
                if (Math.abs(time - this.state.endTime.getTime()) < 1000) {
                    this.setState({ currentTime: new Date(time), isPlaying: false });
                } else {
                    this.setState({ currentTime: new Date(time) });
                }
            }),
            this.viewModel.playbackState.subscribe((state) =>
                this.setState({
                    isPlaying: state === PlaybackState.PLAYING,
                    isLoading: state === PlaybackState.LOADING,
                }),
            ),
            this.viewModel.replayRange.subscribe((range) =>
                this.setState({
                    startTime: new Date(range.startTimestamp),
                    endTime: new Date(range.endTimestamp),
                    currentTime: new Date(range.startTimestamp),
                }),
            ),
            this.viewModel.timeDisplayMode.subscribe(() => this.forceUpdate()),
        );
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <Container height={this.state.height}>
                <Button
                    iconType={this.state.isPlaying ? "PAUSE" : "PLAY"}
                    color="white"
                    layout={"inline"}
                    tooltip={this.state.isPlaying ? t("replay.pause") : t("replay.play")}
                    loading={this.state.isLoading}
                    tooltipPosition="top"
                    onClick={() => this.togglePlayPause()}
                />

                <ReplaySpeedButton
                    id="replayControlsReplaySpeedButton"
                    speed={this.viewModel.speed}
                    onSpeedChange={this.onSpeedChange.bind(this)}
                />
                <ReplayTime alignText="flex-end" dateTime={this.state.startTime} />
                <ReplaySeekbar
                    dateTime={this.state.currentTime}
                    percentage={this.getPercentageBasedOnCurrentTime()}
                    onChange={this.onSeek.bind(this)}
                    onSubmit={this.onSeekSubmit.bind(this)}
                />
                <ReplayTime alignText="flex-start" dateTime={this.state.endTime} />
                <Button
                    iconType={"CROSS"}
                    color="white"
                    layout={"inline"}
                    tooltip={t("general.close")}
                    tooltipPosition="top"
                    onClick={() => this.onClose()}
                />
            </Container>
        );
    }

    // Private functions

    private onClose(): void {
        this.setState({ height: 0 });
        document.body.classList.remove("replay-controls");
        setTimeout(() => {
            this.viewModel.stop();
        }, ANIMATION_DURATION_IN_SEC * 1000);
    }

    private onSeekSubmit(): void {
        this.viewModel.playFrom(this.state.currentTime.getTime());
    }

    private getPercentageBasedOnCurrentTime(): number {
        const endTime = this.state.endTime.getTime();
        const startTime = this.state.startTime.getTime();
        return 100 - ((endTime - this.state.currentTime.getTime()) / (endTime - startTime)) * 100;
    }

    private onSeek(percentage: number): void {
        const time = ((this.state.endTime.getTime() - this.state.startTime.getTime()) * percentage) / 100;
        const date = new Date(this.state.startTime.getTime() + time);
        this.setState({ currentTime: date });
        if (this.state.isPlaying) {
            this.togglePlayPause();
        }
    }

    private onSpeedChange(speed: number): void {
        this.viewModel.updateSpeed(speed);
        if (this.state.isPlaying) {
            this.viewModel.playFrom(this.state.currentTime.getTime());
        }
    }

    private togglePlayPause(): void {
        const isPlaying = !this.state.isPlaying;
        this.setState({ isPlaying: isPlaying });
        if (this.state.currentTime >= this.state.endTime) {
            this.setState({ currentTime: this.state.startTime });
        }
        if (isPlaying) {
            let playTime = this.state.currentTime.getTime();
            if (Math.abs(playTime - this.state.endTime.getTime()) < 1000) {
                playTime = this.state.startTime.getTime();
            }
            this.viewModel.playFrom(playTime);
        } else {
            this.viewModel.pause();
        }
    }
}
