import React from "react";
import { TYPES } from "../../../../../di/Types";
import { DataSimulationIndicatorViewModel } from "./DataSimulationIndicatorViewModel";
import { t } from "i18next";
import { useViewModel } from "../../../../../hooks/useViewModel";
import { useObservable } from "../../../../../hooks";
import { Icon } from "../../../../appearance/Icon";

export const DataSimulationIndicator = (): JSX.Element => {
    // Properties

    const viewModel: DataSimulationIndicatorViewModel = useViewModel(TYPES.TimeStatusIndicatorViewModel);
    const playbackState = useObservable(viewModel.playbackState, null);
    const { isSimulation, errorText } = useObservable(viewModel.timeStatus, {
        isSimulation: false,
        errorText: undefined,
    });

    // Render

    if (playbackState) {
        return (
            <Icon
                color="info"
                iconType="REPLAY"
                large={true}
                tooltip={t("status.replayActive")}
                tooltipPosition="left"
            />
        );
    }

    return (
        <>
            {isSimulation && (
                <Icon
                    color="info"
                    iconType="DATA_SIMULATION"
                    large={true}
                    tooltip={t("status.dataSimulationActive")}
                    tooltipPosition="left"
                />
            )}
            {errorText && (
                <Icon color="danger" iconType="CLOCK" large={true} tooltip={errorText} tooltipPosition="left" />
            )}
        </>
    );
};
