import { SettingItemIcon } from "./SettingItemIcon";
import * as Rx from "rxjs";
import { Range } from "../../domain/model";
import { UserPermission } from "../../domain/model/UserPermission";
import { SelectOption } from "../../domain/model/SelectOption";
import { FunctionComponent, SVGProps } from "react";

export interface SettingItemViewModel {
    title: string;
    icon?: SettingItemIcon;
    requiredPermissions: UserPermission[];
    tooltip: string | undefined;
}

export class SwitchItemViewModel implements SettingItemViewModel {
    public constructor(
        public readonly title: string,
        public readonly icon: SettingItemIcon,
        public readonly observableValue: Rx.Observable<boolean>,
        public readonly onChange: (newValue: boolean) => void,
        public readonly requiredPermissions: UserPermission[] = [],
        public readonly tooltip: string | undefined = undefined,
    ) {}
}

export class PlusMinusItemViewModel implements SettingItemViewModel {
    public constructor(
        public readonly title: string,
        public readonly icon: SettingItemIcon,
        public readonly observableValue: Rx.Observable<number | null>,
        public readonly range: Range,
        public readonly step: number,
        public readonly onChange: (newValue: number) => void,
        public readonly formatter: (value: number) => string,
        public readonly requiredPermissions: UserPermission[] = [],
        public readonly tooltip: string | undefined = undefined,
    ) {}
}

export class ActionItemViewModel implements SettingItemViewModel {
    public constructor(
        public readonly title: string,
        public readonly icon: SettingItemIcon,
        public readonly onClick: () => void,
        public readonly observableValue?: Rx.Observable<string>,
        public readonly requiredPermissions: UserPermission[] = [],
        public readonly tooltip: string | undefined = undefined,
    ) {}
}

export interface IconSwitchActionProps {
    observableValue: Rx.Observable<boolean>;
    key: string;
    onChange: (newValue: boolean) => void;
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
}

export class IconSwitchItemViewModel implements SettingItemViewModel {
    public constructor(
        public readonly title: string,
        public readonly icon: SettingItemIcon,
        public readonly actions: IconSwitchActionProps[],
        public readonly requiredPermissions: UserPermission[] = [],
        public readonly tooltip: string | undefined = undefined,
    ) {}
}

export class SelectItemViewModel implements SettingItemViewModel {
    public constructor(
        public readonly title: string,
        public readonly icon: SettingItemIcon,
        public readonly observableValue: Rx.Observable<SelectOption | null>,
        public readonly options: SelectOption[],
        public readonly initialOption: SelectOption | null,
        public readonly onChange: (option: string) => void,
        public readonly requiredPermissions: UserPermission[] = [],
        public readonly tooltip: string | undefined = undefined,
    ) {}
}

export class TextItemViewModel implements SettingItemViewModel {
    public constructor(
        public readonly title: string,
        public readonly icon: SettingItemIcon | undefined = undefined,
        public readonly requiredPermissions: UserPermission[] = [],
        public readonly tooltip: string | undefined = undefined,
    ) {}
}
