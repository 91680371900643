import { CommonDependencyProvider } from "../CommonDependencyProvider";
import DI from "./../../di/DI";
import { TYPES } from "../../di/Types";
import {
    TrackRepository,
    LocationInfoRepository,
    ServerPreferencesRepository,
    SessionRepository,
    MapRepository,
    GridAnalysisRepository,
    OverlayRepository,
    ReplayRepository,
    RadarRepository,
    AlarmRepository,
    OverlayEditorRepository,
    TrackObservationRepository,
    ServerConfigRepository,
    DeterrenceRepository,
    VersionRepository,
    GroundObservationRepository,
    UserLocationRepository,
    UserManagementRepository,
    LocalPreferencesRepository,
    RunwayTrafficRepository,
    ADSBFlightRepository,
    TracksHistogramRepository,
    MeasurementPointsRepository,
} from "../../domain/repositories";
import { CommonAlarmRepository, CommonReplayRepository } from "../../domain/repositories/impl";
import {
    BirdViewerSessionRepository,
    BirdViewerServerPreferencesRepository,
    BirdViewerTrackAndRadarRepository,
    BirdViewerLocationInfoRepository,
    BirdViewerGridAnalysisRepository,
    BirdViewerMapRepository,
    BirdViewerOverlayRepository,
    BirdViewerOverlayEditorRepository,
    BirdViewerTrackObservationRepository,
    BirdViewerGroundObservationRepository,
    BirdViewerUserManagementRepository,
    BirdViewerServerConfigRepository,
    BirdViewerDeterrenceRepository,
    BirdViewerVersionRepository,
    BirdViewerRunwayTrafficRepository,
    BirdViewerADSBFlightRepository,
    BirdViewerTracksHistogramRepository,
} from "./domain/repositories";
import { BirdViewerAPI } from "../../domain/BirdViewerAPI";
import { BirdViewerGrpcAPI } from "./infrastructure/api/BirdViewerGrpcAPI";
import { MapModuleViewModel } from "../../components/map/modules/MapModuleViewModel";
import { MapModule } from "../../components/map/modules/MapModule";
import { TracksModule } from "../../components/map/modules/tracks/TracksModule";
import { RadarsModule } from "../../components/map/modules/radars/RadarsModule";
import { DimColorModuleViewModel } from "../../components/map/modules/dimcolor/DimColorModuleViewModel";
import { DimColorModule } from "../../components/map/modules/dimcolor/DimColorModule";
import { RadarsModuleViewModel } from "../../components/map/modules/radars/RadarsModuleViewModel";
import { TracksModuleViewModel } from "../../components/map/modules/tracks/TracksModuleViewModel";
import { HeatmapModuleViewModel } from "../../components/map/modules/heatmap/HeatmapModuleViewModel";
import { HeatmapModule } from "../../components/map/modules/heatmap/HeatmapModule";
import { OverlaysModuleViewModel } from "../../components/map/modules/overlays/OverlaysModuleViewModel";
import { OverlaysModule } from "../../components/map/modules/overlays/OverlaysModule";
import { SettingsSectionsProvider } from "../../components/settings/SettingsSectionsProvider";
import { BirdViewerSettingsSectionsProvider } from "./components/settings/BirdViewerSettingsSectionsProvider";
import { ServerURL } from "../../infrastructure/ServerURL";
import { ClientDataProvider } from "../../domain/ClientDataProvider";
import { DistanceFormatter } from "../../domain/DistanceFormatter";
import {
    AIRCRAFT_ONLY_BEARING_VISUALIZATION,
    ADSB_AIRCRAFT_ONLY_BEARING_VISUALIZATION,
} from "../../components/map/layers";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";
import BirdViewerConfig from "./flavor-config.json";
import { DeterrenceDevicesModuleViewModel } from "../../components/map/modules/deterrence/DeterrenceDevicesModuleViewModel";
import { DeterrenceDevicesModule } from "../../components/map/modules/deterrence/DeterrenceDevicesModule";
import { GroundObservationsModule } from "../../components/map/modules/groundobservations/GroundObservationsModule";
import { GroundObservationsModuleViewModel } from "../../components/map/modules/groundobservations/GroundObservationsModuleViewModel";
import { ADSBFlightsModule as ADSBFlightsModule } from "../../components/map/modules/tracks/ADSBFlightsModule";
import { ADSBFlightsModuleViewModel as ADSBFlightsModuleViewModel } from "../../components/map/modules/tracks/ADSBFlightsModuleViewModel";
import { UserLocationModule } from "../../components/map/modules/userlocation/UserLocationModule";
import { UserLocationModuleViewModel } from "../../components/map/modules/userlocation/UserLocationModuleViewModel";
import { ADSBFlightsSnapshotDiffCalculator } from "../../domain/ADSBFlightsSnapshotDiffCalculator";
import { TracksSnapshotDiffCalculator } from "../../domain/TracksSnapshotDiffCalculator";
import { MeasurementsModule } from "../../components/map/modules/measurements/MeasurementsModule";
import { MeasurementsModuleViewModel } from "../../components/map/modules/measurements/MeasurementsModuleViewModel";
import { RadarBlankingSectorsModule } from "../../components/map/modules/radarblankingsectors/RadarBlankingSectorsModule";
import { RadarBlankingSectorsModuleViewModel } from "../../components/map/modules/radarblankingsectors/RadarBlankingSectorsModuleViewModel";

export class BirdViewerDependencyProvider extends CommonDependencyProvider {
    // Protected functions

    protected setupInfrastructureImplementations(): void {
        DI.bind<FlavorConfig>(TYPES.FlavorConfig).toConstantValue(BirdViewerConfig);
        DI.bind<ServerURL>(TYPES.ServerURL).toConstantValue(ServerURL.forBirdViewer());
        DI.bind<BirdViewerAPI>(TYPES.API).toConstantValue(
            new BirdViewerGrpcAPI(
                DI.get<ServerURL>(TYPES.ServerURL),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                DI.get<ClientDataProvider>(TYPES.ClientDataProvider),
            ),
        );
    }
    protected setupDomainImplementations(): void {
        DI.bind<ServerConfigRepository>(TYPES.ServerConfigRepository).toConstantValue(
            new BirdViewerServerConfigRepository(
                DI.get(TYPES.API),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
            ),
        );
        DI.bind<SessionRepository>(TYPES.SessionRepository).toConstantValue(
            new BirdViewerSessionRepository(
                DI.get(TYPES.API),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository),
            ),
        );
        DI.bind<ServerPreferencesRepository>(TYPES.ServerPreferencesRepository).toConstantValue(
            new BirdViewerServerPreferencesRepository(DI.get(TYPES.API)),
        );
        DI.bind<TrackRepository>(TYPES.TrackRepository).toConstantValue(
            new BirdViewerTrackAndRadarRepository(
                DI.get(TYPES.API),
                DI.get<ServerPreferencesRepository>(TYPES.ServerPreferencesRepository),
            ),
        );
        DI.bind<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository).toConstantValue(
            new BirdViewerRunwayTrafficRepository(
                DI.get(TYPES.API),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
            ),
        );
        DI.bind<RadarRepository>(TYPES.RadarRepository).toConstantValue(DI.get<RadarRepository>(TYPES.TrackRepository));
        DI.bind<LocationInfoRepository>(TYPES.LocationInfoRepository).toConstantValue(
            new BirdViewerLocationInfoRepository(DI.get(TYPES.API)),
        );
        DI.bind<GridAnalysisRepository>(TYPES.GridAnalysisRepository).toConstantValue(
            new BirdViewerGridAnalysisRepository(DI.get(TYPES.API)),
        );
        DI.bind<MapRepository>(TYPES.MapRepository).toConstantValue(
            new BirdViewerMapRepository(
                DI.get(TYPES.API),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
            ),
        );
        DI.bind<OverlayRepository>(TYPES.OverlayRepository).toConstantValue(
            new BirdViewerOverlayRepository(
                DI.get(TYPES.API),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
            ),
        );
        DI.bind<ReplayRepository>(TYPES.ReplayRepository).toConstantValue(
            new CommonReplayRepository(
                DI.get<TrackRepository>(TYPES.TrackRepository),
                DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
            ),
        );
        DI.bind<AlarmRepository>(TYPES.AlarmRepository).toConstantValue(
            new CommonAlarmRepository(
                DI.get<TrackRepository>(TYPES.TrackRepository),
                DI.get<ReplayRepository>(TYPES.ReplayRepository),
                DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
            ),
        );
        DI.bind<OverlayEditorRepository>(TYPES.OverlayEditorRepository).toConstantValue(
            new BirdViewerOverlayEditorRepository(),
        );
        DI.bind<TrackObservationRepository>(TYPES.TrackObservationRepository).toConstantValue(
            new BirdViewerTrackObservationRepository(DI.get(TYPES.API)),
        );
        DI.bind<DeterrenceRepository>(TYPES.DeterrenceRepository).toConstantValue(
            new BirdViewerDeterrenceRepository(DI.get(TYPES.API)),
        );
        DI.bind<VersionRepository>(TYPES.VersionRepository).toConstantValue(
            new BirdViewerVersionRepository(DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository)),
        );
        DI.bind<GroundObservationRepository>(TYPES.GroundObservationRepository).toConstantValue(
            new BirdViewerGroundObservationRepository(DI.get(TYPES.API)),
        );
        DI.bind<ADSBFlightRepository>(TYPES.ADSBFlightRepository).toConstantValue(
            new BirdViewerADSBFlightRepository(DI.get(TYPES.API)),
        );
        DI.bind<TracksSnapshotDiffCalculator>(TYPES.TracksSnapshotDiffCalculator).toConstantValue(
            new TracksSnapshotDiffCalculator(
                DI.get<TrackRepository>(TYPES.TrackRepository),
                DI.get<ReplayRepository>(TYPES.ReplayRepository),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
            ),
        );
        DI.bind<ADSBFlightsSnapshotDiffCalculator>(TYPES.ADSBFlightsSnapshotDiffCalculator).toConstantValue(
            new ADSBFlightsSnapshotDiffCalculator(
                DI.get<ADSBFlightRepository>(TYPES.ADSBFlightRepository),
                DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
            ),
        );
        DI.bind<UserManagementRepository>(TYPES.UserManagementRepository).toConstantValue(
            new BirdViewerUserManagementRepository(DI.get(TYPES.API)),
        );
        DI.bind<TracksHistogramRepository>(TYPES.TracksHistogramRepository).toConstantValue(
            new BirdViewerTracksHistogramRepository(DI.get(TYPES.API)),
        );
        DI.bind<MeasurementPointsRepository>(TYPES.MeasurementPointsRepository).toConstantValue(
            new MeasurementPointsRepository(
                DI.get<RadarRepository>(TYPES.RadarRepository),
                DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
            ),
        );
    }

    protected setupMapModules(): void {
        DI.bind<MapModule<MapModuleViewModel>[]>(TYPES.MapModules).toDynamicValue(() => [
            new RadarsModule(
                new RadarsModuleViewModel(
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                ),
            ),
            new DimColorModule(
                new DimColorModuleViewModel(DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository)),
            ),
            new TracksModule(
                new TracksModuleViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<MeasurementPointsRepository>(TYPES.MeasurementPointsRepository),
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<TracksSnapshotDiffCalculator>(TYPES.TracksSnapshotDiffCalculator),
                    DI.get<TrackObservationRepository>(TYPES.TrackObservationRepository),
                    DI.get<GroundObservationRepository>(TYPES.GroundObservationRepository),
                ),
                DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                {
                    shouldVisualizeBearingFor: AIRCRAFT_ONLY_BEARING_VISUALIZATION,
                },
            ),
            new ADSBFlightsModule(
                new ADSBFlightsModuleViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<MeasurementPointsRepository>(TYPES.MeasurementPointsRepository),
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                    DI.get<ADSBFlightRepository>(TYPES.ADSBFlightRepository),
                    DI.get<ADSBFlightsSnapshotDiffCalculator>(TYPES.ADSBFlightsSnapshotDiffCalculator),
                    DI.get<TrackObservationRepository>(TYPES.TrackObservationRepository),
                ),
                DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                {
                    shouldVisualizeBearingFor: ADSB_AIRCRAFT_ONLY_BEARING_VISUALIZATION,
                },
                DI.get<FlavorConfig>(TYPES.FlavorConfig),
            ),
            new HeatmapModule(
                new HeatmapModuleViewModel(
                    DI.get<ServerPreferencesRepository>(TYPES.ServerPreferencesRepository),
                    DI.get<GridAnalysisRepository>(TYPES.GridAnalysisRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                ),
            ),
            new OverlaysModule(
                new OverlaysModuleViewModel(
                    DI.get<OverlayRepository>(TYPES.OverlayRepository),
                    DI.get<OverlayEditorRepository>(TYPES.OverlayEditorRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                ),
                DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
            ),
            new DeterrenceDevicesModule(
                new DeterrenceDevicesModuleViewModel(DI.get<DeterrenceRepository>(TYPES.DeterrenceRepository)),
            ),
            new GroundObservationsModule(
                new GroundObservationsModuleViewModel(
                    DI.get<GroundObservationRepository>(TYPES.GroundObservationRepository),
                    DI.get<TrackObservationRepository>(TYPES.TrackObservationRepository),
                ),
            ),
            new RadarBlankingSectorsModule(
                new RadarBlankingSectorsModuleViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                ),
            ),
            new UserLocationModule(
                new UserLocationModuleViewModel(
                    DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                ),
            ),
            new MeasurementsModule(
                new MeasurementsModuleViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<TracksSnapshotDiffCalculator>(TYPES.TracksSnapshotDiffCalculator),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                    DI.get<ADSBFlightRepository>(TYPES.ADSBFlightRepository),
                    DI.get<ADSBFlightsSnapshotDiffCalculator>(TYPES.ADSBFlightsSnapshotDiffCalculator),
                ),
            ),
        ]);
    }

    protected setupSettingSectionsProvider(): void {
        DI.bind<SettingsSectionsProvider>(TYPES.SettingsSectionsProvider).toDynamicValue(
            () =>
                new BirdViewerSettingsSectionsProvider(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<ServerPreferencesRepository>(TYPES.ServerPreferencesRepository),
                    DI.get<MapRepository>(TYPES.MapRepository),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                    DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                    DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository),
                ),
        );
    }
}
