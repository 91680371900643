import {
    AxisType as AxisTypeProto,
    RadarOrientation as RadarOrientationProto,
    Radar as RadarProto,
    RadarUpdate as RadarUpdateProto,
} from "./proto/generated/tracklist3_pb";
import { Location, RadarStatus } from ".";
import { RadarStatusInfoJSON } from "./json";

export enum AxisType {
    UNKNOWN = 1,
    PHI,
    THETA,
    TIME,
}

export class RadarOrientation {
    // Static functions

    public static axisTypeFrom(model: AxisTypeProto): AxisType {
        switch (model) {
            case AxisTypeProto.AXISPHI:
                return AxisType.PHI;
            case AxisTypeProto.AXISTHETA:
                return AxisType.THETA;
            case AxisTypeProto.AXISTIME:
                return AxisType.TIME;
            case AxisTypeProto.AXISUNKNOWN:
            default:
                return AxisType.UNKNOWN;
        }
    }

    public static fromProto(model: RadarOrientationProto): RadarOrientation {
        return new RadarOrientation(
            model.getRange(),
            model.getAzimuth(),
            RadarOrientation.axisTypeFrom(model.getAxistype()),
        );
    }

    public static fromJson(rotationAngle: double): RadarOrientation {
        return new RadarOrientation(0.0, 0.0, AxisType.UNKNOWN, rotationAngle);
    }

    public constructor(
        public readonly range: float = 0.0,
        public readonly azimuth: float = 0.0,
        public readonly axisType: AxisType = AxisType.UNKNOWN,
        public readonly rotationAngle: double = 0.0,
    ) {}
}

export class Radar {
    // Static functions

    public static fromProto(model: RadarProto): Radar {
        const protoOrientation = model.getOrientation();
        let orientation: RadarOrientation;
        if (protoOrientation) {
            orientation = RadarOrientation.fromProto(protoOrientation);
        } else {
            orientation = new RadarOrientation();
        }
        // @todo: Get `groundLevel` from model once the proto api is updated
        // For now, if you want to fake it just set `groundLevel` to the desired value
        const groundLevel = undefined;
        return new Radar(
            model.getSiteId(),
            model.getName(),
            orientation,
            Location.fromProto(model.getPosition()),
            RadarStatus.fromProto(groundLevel),
        );
    }

    public static updateFromProto(radar: Radar, model: RadarUpdateProto): Radar {
        const protoOrientation = model.getOrientation();
        return {
            ...radar,
            orientation: protoOrientation ? RadarOrientation.fromProto(protoOrientation) : radar.orientation,
        };
    }

    public static fromJson(model: RadarStatusInfoJSON): Radar {
        return new Radar(
            model.radarId,
            model.locationName,
            RadarOrientation.fromJson(model.orientation),
            Location.fromJson(model.location),
            RadarStatus.fromJson(model),
        );
    }

    // Properties

    public constructor(
        public readonly id: long,
        public readonly name: string,
        public readonly orientation: RadarOrientation,
        public readonly position: Location,
        public readonly status: RadarStatus | null,
    ) {}
}

export function isVerticalRadar(radar: Radar): boolean {
    return radar.orientation.axisType === AxisType.THETA;
}
