import { DistanceUnit, getDistanceUnit } from "./model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository } from "./repositories/LocalPreferencesRepository";
import { LocalUserPreferenceKeys } from "./model/PreferencesData";
import * as turfHelpers from "@turf/helpers";
import { t } from "i18next";
import { FEET_PER_MILE } from "../utils/MapUtils";

export enum DistanceFormatType {
    NONE, // no postfix
    SPACED, // simple postfix with space: 100 -> 100 m
    ABOVE_SPACED, // with a plus and space: 100 -> 100+ m
}

export enum DistanceFormatFunction {
    NONE,
    CEIL,
    ROUND_DECIMAL_2,
}

export interface DistanceFormatOptions {
    formatType?: DistanceFormatType; // Defaults to SPACED.
    formatFunction?: DistanceFormatFunction; // Defaults to CEIL.
    simplifyLargeValues?: boolean; // e.g. use km for values > 1000m. Defaults to false.
}

export type FormatterFunction = (source: number, sourceUnit?: DistanceUnit, options?: DistanceFormatOptions) => string;

export class DistanceFormatter {
    // Properties

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {}

    public get selectedDistanceUnit(): DistanceUnit {
        const value = this.localPreferencesRepository.getPreference<string>(
            LocalUserPreferenceKeys.selections.selectedDistanceUnitName,
        );
        return getDistanceUnit(value);
    }

    public readonly selectedDistanceUnitObservable = this.localPreferencesRepository
        .observePreference<string>(LocalUserPreferenceKeys.selections.selectedDistanceUnitName)
        .pipe(RxOperators.map((name) => getDistanceUnit(name)));

    // Public functions

    /**
     * Converts a value from the given unit from the current unit to the specified unit.
     * @param value Value to convert.
     * @param toUnit Target unit to convert to.
     * @param func Optional. Formatting function to use, defaults to CEIL.
     * @returns The converted value as a number.
     */
    public convertValueFromCurrentUnit(
        value: number,
        toUnit: DistanceUnit,
        func: DistanceFormatFunction = DistanceFormatFunction.CEIL,
    ): number {
        return DistanceFormatter.convertValue(value, this.selectedDistanceUnit, toUnit, func);
    }

    /**
     * Converts a value from the given unit to the current unit.
     * @param value Value to convert.
     * @param fromUnit Source unit to convert from.
     * @param func Optional. Formatting function to use, defaults to CEIL.
     * @returns The converted value as a number.
     */
    public convertValueToCurrentUnit(
        value: number,
        fromUnit: DistanceUnit = DistanceUnit.METRIC,
        func: DistanceFormatFunction = DistanceFormatFunction.CEIL,
    ): number {
        return DistanceFormatter.convertValue(value, fromUnit, this.selectedDistanceUnit, func);
    }

    public formatValue<SourceType, ResultType>(
        source: SourceType,
        mapper: (source: SourceType, formatter: FormatterFunction) => ResultType,
    ): Rx.Observable<ResultType> {
        return this.formatObservable(Rx.of(source), mapper);
    }

    public formatObservable<SourceType, ResultType>(
        sourceObservable: Rx.Observable<SourceType>,
        mapper: (value: SourceType, formatter: FormatterFunction) => ResultType,
    ): Rx.Observable<ResultType> {
        return Rx.combineLatest([sourceObservable, this.selectedDistanceUnitObservable]).pipe(
            RxOperators.map(([value, toUnit]) => {
                const formatter: FormatterFunction = (source, fromUnit, options) =>
                    DistanceFormatter.performFormat(source, fromUnit, toUnit, options || {});
                return mapper(value, formatter);
            }),
        );
    }

    public formatValueWithCurrentUnit(value: number, fromUnit?: DistanceUnit, options?: DistanceFormatOptions): string {
        return DistanceFormatter.performFormat(value, fromUnit, this.selectedDistanceUnit, options || {});
    }

    // Static functions

    static performFormat(
        value: number,
        fromUnit: DistanceUnit = DistanceUnit.METRIC,
        toUnit: DistanceUnit,
        { formatType, formatFunction, simplifyLargeValues }: DistanceFormatOptions,
    ): string {
        const type = formatType === undefined ? DistanceFormatType.SPACED : formatType;
        const func = formatFunction === undefined ? DistanceFormatFunction.CEIL : formatFunction;
        const displayValue = DistanceFormatter.convertValue(value, fromUnit, toUnit, func);
        switch (toUnit) {
            case DistanceUnit.METRIC:
                if (simplifyLargeValues && displayValue >= 1000) {
                    return DistanceFormatter.constructString(
                        displayValue / 1000,
                        t("unit.kilometer_shorthand"),
                        2,
                        type,
                    );
                } else {
                    return DistanceFormatter.constructString(displayValue, t("unit.meter_shorthand"), 0, type);
                }
            case DistanceUnit.IMPERIAL:
                if (simplifyLargeValues && displayValue >= FEET_PER_MILE) {
                    return DistanceFormatter.constructString(
                        displayValue / FEET_PER_MILE,
                        t("unit.mile_shorthand"),
                        2,
                        type,
                    );
                } else {
                    return DistanceFormatter.constructString(displayValue, t("unit.foot_shorthand"), 0, type);
                }
        }
    }

    static convertValue(
        value: number,
        fromUnit: DistanceUnit,
        toUnit: DistanceUnit,
        func: DistanceFormatFunction,
    ): number {
        let convertedValue = value;
        if (fromUnit === DistanceUnit.METRIC && toUnit === DistanceUnit.IMPERIAL) {
            convertedValue = value * turfHelpers.unitsFactors.feet;
        } else if (fromUnit === DistanceUnit.IMPERIAL && toUnit === DistanceUnit.METRIC) {
            convertedValue = value / turfHelpers.unitsFactors.feet;
        }

        switch (func) {
            case DistanceFormatFunction.CEIL:
                convertedValue = Math.ceil(convertedValue);
                break;
            case DistanceFormatFunction.ROUND_DECIMAL_2:
                convertedValue = Math.round(convertedValue * 100) / 100;
                break;
            case DistanceFormatFunction.NONE:
                break;
        }

        return convertedValue;
    }

    static constructString(
        displayValue: number,
        unitString: string,
        fractionDigits: number,
        type: DistanceFormatType,
    ): string {
        switch (type) {
            case DistanceFormatType.SPACED:
                return displayValue.toFixed(fractionDigits) + " " + unitString;
            case DistanceFormatType.ABOVE_SPACED:
                return displayValue.toFixed(fractionDigits) + "+ " + unitString;
            case DistanceFormatType.NONE:
                return displayValue.toFixed(fractionDigits);
        }
    }
}
