import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { RadarRepository } from "../../../../domain/repositories";
import { MapModuleViewModel } from "../MapModuleViewModel";
import {
    BlankingSectorsState,
    RadarOrientation,
    RadarMotionData,
    isVerticalRadar,
    Radar,
    DistanceUnit,
} from "../../../../domain/model";
import { LocalPreferencesRepository } from "../../../../domain/repositories/LocalPreferencesRepository";
import { LocalUserPreferenceKeys } from "../../../../domain/model/PreferencesData";
import * as RxUtils from "../../../../utils/RxUtils";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { Position } from "geojson";
import { cleanupRadarsByDistance } from "../../../../utils/CleanUpRadarsByDistance";

export interface RadarUpdate {
    radarId: long;
    position: Position;
    isVertical: boolean;
    orientation: RadarOrientation;
    motionData?: RadarMotionData;
}

export class RadarsModuleViewModel extends MapModuleViewModel {
    // Properties

    public get radarUpdates(): Rx.Observable<RadarUpdate[]> {
        return Rx.combineLatest([this.radarRepository.radars, this.radarRepository.selectedRadarId]).pipe(
            RxOperators.mergeMap(([radars, selectedRadarId]) => {
                // Filter out radars by distance
                const radarsToDisplay = cleanupRadarsByDistance(radars);
                // For each radar, get a stream of updates
                const radarObservables = radarsToDisplay.map((radar) => {
                    // If this radar is not the selected radar, just return a single radar update as an observable
                    if (radar.id !== selectedRadarId) {
                        return Rx.of(this.getRadarUpdate(radar));
                    }
                    // If this is the selected radar, return an observable stream of updates including potential motion data
                    return this.radarRepository.motionData.pipe(
                        RxOperators.startWith(undefined),
                        RxOperators.map((motionData) => this.getRadarUpdate(radar, motionData)),
                    );
                });
                return Rx.combineLatest(radarObservables);
            }),
        );
    }

    public get shouldShowRadarRangeById(): Rx.Observable<number[]> {
        return this.radarUpdates.pipe(
            RxOperators.switchMap((radarUpdates) => {
                const keys = radarUpdates.map((u) => LocalUserPreferenceKeys.filters.showRadarRange + "-" + u.radarId);
                return this.localPreferencesRepository.observePreferences<boolean>(keys).pipe(
                    RxOperators.map((values) => {
                        const visibleRadarRangeIds: number[] = [];
                        values.forEach((value, index) => {
                            if (value) {
                                visibleRadarRangeIds.push(radarUpdates[index].radarId);
                            }
                        });
                        return visibleRadarRangeIds;
                    }),
                );
            }),
        );
    }

    public get shouldAddVerticalRadarAzimuthLayer(): boolean {
        return this.flavorConfig.vrAzimuthLayerEnabled;
    }

    public get shouldShowVerticalRadarAzimuthLayer(): Rx.Observable<boolean> {
        return RxUtils.nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.appearance.showVRAzimuth,
            ),
        );
    }

    public get radarRangeRingsOpacity(): Rx.Observable<number> {
        return RxUtils.nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.appearance.rangeRingsOpacity,
            ),
        );
    }

    public get blankingSectorsState(): Rx.Observable<BlankingSectorsState> {
        return this.radarRepository.blankingSectorsState;
    }

    public get distanceUnit(): Rx.Observable<DistanceUnit> {
        return this.distanceFormatter.selectedDistanceUnitObservable;
    }

    public constructor(
        private readonly radarRepository: RadarRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly flavorConfig: FlavorConfig,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
    }

    private getRadarUpdate(radar: Radar, motionData?: RadarMotionData): RadarUpdate {
        const update: RadarUpdate = {
            radarId: radar.id,
            position: radar.position.toGeoJSONLocation(),
            isVertical: isVerticalRadar(radar),
            orientation: radar.orientation,
        };
        if (!motionData) {
            return update;
        }
        return {
            ...update,
            position: motionData.position.toGeoJSONLocation(),
            motionData: motionData,
        };
    }
}
