import {
    LocalPreferencesRepository,
    LocationInfoRepository,
    MapRepository,
    RadarRepository,
    ReplayRepository,
    UIControlRepository,
    UserLocationRepository,
    UserLocationState,
} from "../../domain/repositories";
import { Track, TileProvider, Location, GeolocationPosition, LocalUserPreferenceKeys } from "../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { PlaybackState } from "../../domain/PlaybackScene";
import { nonNullObservable } from "../../utils/RxUtils";

export class MapViewViewModel extends BaseViewModel {
    // Properties

    public get tileProviderAndAirbaseInfo(): Rx.Observable<LocationAndMapInfo> {
        return Rx.combineLatest([
            this.locationInfoRepository.referenceLocation,
            this.mapRepository.getSelectedTileProvider(),
        ]).pipe(
            RxOperators.distinctUntilChanged(),
            RxOperators.map(([referenceLocation, tileProvider]) => ({
                referenceLocation: referenceLocation!,
                selectedTileProvider: tileProvider,
            })),
        );
    }

    public get isRepositioning(): Rx.Observable<boolean> {
        return this.radarRepository.repositioningState.pipe(RxOperators.map((state) => state.isActive));
    }

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
            RxOperators.distinctUntilChanged(),
        );
    }

    public get mapBearing(): Rx.Observable<number | null> {
        const lockMapToVehicleOrientationObservable = nonNullObservable(
            this.localPreferencesRepository.observePreference(
                LocalUserPreferenceKeys.filters.lockMapToVehicleOrientation,
            ),
            false,
        );

        // Only track the radar heading if the user has enabled the "lock map to vehicle orientation" preference _and_ dynamic positioning is enabled.
        // This is a belt and braces approach in case the user still has the preference enabled from a previous radar and the new radar doesn't support dynamic positioning.
        const trackRadarHeadingObservable = Rx.combineLatest([
            lockMapToVehicleOrientationObservable,
            this.radarRepository.isDynamicPositioningEnabled,
        ]).pipe(RxOperators.map(([a, b]) => a && b));

        const radarHeadingObservable = nonNullObservable(
            this.radarRepository.motionData.pipe(RxOperators.map((motionData) => motionData?.headingDeg)),
        );

        return trackRadarHeadingObservable.pipe(
            RxOperators.distinctUntilChanged(),
            RxOperators.switchMap((trackRadarHeading) => {
                if (trackRadarHeading) {
                    // If the user has enabled the "lock map to vehicle orientation" preference, then we want to track the radar heading.
                    return radarHeadingObservable;
                } else if (this.userDidMoveMap) {
                    // If "lock map to vehicle orientation" is set to false due to the user interacting with the map, don't emit any bearing.
                    this.userDidMoveMap = false;
                    return Rx.of(null);
                } else {
                    // If the user has deliberately set "lock map to vehicle orientation" false we emit 0 degrees once, to set the bearing back to due North.
                    return Rx.of(0);
                }
            }),
        );
    }

    public get mapCenter(): Rx.Observable<Location | null> {
        const trackRadarLocationObservable = this.trackRadarLocationSubject.asObservable();
        return trackRadarLocationObservable.pipe(
            RxOperators.switchMap((trackRadarLocation) =>
                trackRadarLocation ? this.radarLocationObservable : Rx.of(null),
            ),
        );
    }

    public get isDynamicPositioningEnabled(): Rx.Observable<boolean> {
        return this.radarRepository.isDynamicPositioningEnabled.pipe(RxOperators.first());
    }

    // Subject to track whether the user wants to track the radar location (via the center-to-radar control )
    private trackRadarLocationSubject = new Rx.BehaviorSubject(false);
    // Class variable to track if bearing tracking has stopped due to the user interacting with the map.
    private userDidMoveMap = false;

    private get radarLocationObservable(): Rx.Observable<Location> {
        return this.radarRepository.isDynamicPositioningEnabled.pipe(
            RxOperators.switchMap((isDynamic) =>
                isDynamic
                    ? this.radarRepository.motionData.pipe(RxOperators.map((motionData) => motionData?.position))
                    : this.locationInfoRepository.referenceLocation,
            ),
        );
    }

    public constructor(
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly mapRepository: MapRepository,
        private readonly radarRepository: RadarRepository,
        private readonly userLocationRepository: UserLocationRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly uiControlRepository: UIControlRepository,
    ) {
        super();
    }

    // Public functions

    public onGeolocateEvent(position: GeolocationPosition): void {
        this.userLocationRepository.setUserLocationPosition(position);
    }

    public onGeolocateStateChange(state: UserLocationState): void {
        this.userLocationRepository.setUserLocationState(state);
    }

    public onUserMapMovement(): void {
        this.userDidMoveMap = true;
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.filters.lockMapToVehicleOrientation,
            false,
        );
    }

    public onStartTrackingRadarLocation(): void {
        this.trackRadarLocationSubject.next(true);
    }

    public onStopTrackingRadarLocation(): void {
        this.trackRadarLocationSubject.next(false);
    }

    public setMapLoaded(isLoaded: boolean): void {
        this.uiControlRepository.setUIReady(isLoaded);
    }
}

export interface LocationAndMapInfo {
    selectedTileProvider: TileProvider;
    referenceLocation: Location;
}

export class TrackUpdate {
    public constructor(public tracks: Track[]) {}
}
