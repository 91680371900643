import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { Icon, IconType } from "./Icon";
import { PropsWithTooltip } from "./Tooltip";

const StyledToolbarButton = styled.button<{ active?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.x3};
    height: 44px;
    vertical-align: middle;
    background: ${({ active, theme }) => (active ? theme.colors.status.info : "transparent")};
    border-width: 0px;
    border-radius: ${({ theme }) => theme.spacing.x2};
    color: ${({ theme }) => theme.colors.text.text};
    cursor: pointer;

    :hover {
        background-color: ${({ active, theme }) =>
            active ? theme.colors.status.infoHover : theme.colors.interactive.textbuttonHover};
    }
`;

interface Props {
    active?: boolean;
    iconType: IconType;
}

export const ToolbarIconButton = ({
    iconType,
    tooltip,
    tooltipPosition,
    ...rest
}: PropsWithTooltip<Props & React.HTMLAttributes<HTMLButtonElement>>): JSX.Element => {
    // Properties

    const tooltipId = "image-button-tooltip-" + String(Math.random());

    // Render

    return (
        <>
            <StyledToolbarButton data-for={tooltipId} data-tip={tooltip} data-iscapture="true" {...rest}>
                <Icon iconType={iconType} large />
            </StyledToolbarButton>
            {tooltip && <ReactTooltip id={tooltipId} effect={"solid"} place={tooltipPosition ?? "bottom"} />}
        </>
    );
};
