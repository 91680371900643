import { enumValueOrDefault } from "../../utils/EnumUtils";

export enum VelocityUnit {
    MSEC = "msec",
    KMH = "kmh",
    MPH = "mph",
}

export const DEFAULT_VELOCITY_UNIT = VelocityUnit.MSEC;
export const DEFAULT_VEHICLE_VELOCITY_UNIT = VelocityUnit.KMH;

export const getVelocityUnit = (value: string | null): VelocityUnit =>
    enumValueOrDefault(VelocityUnit, value, DEFAULT_VELOCITY_UNIT);
