import React from "react";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { ObservationViewModel } from "./ObservationViewModel";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import styled from "styled-components";
import { Colors, OldColors } from "../appearance/Colors";
import { showError } from "../../utils/MessageUtils";
import { Subscription } from "rxjs";
import DeleteIcon from "./../../res/images/settings/delete.svg";
import { Select, SelectItem } from "../appearance/Select";
import { PanelSubtitle } from "../appearance/panels/PanelSubtitle";
import { t } from "i18next";
import { Panel } from "../appearance/panels/Panel";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelFooter } from "../appearance/panels/PanelFooter";
import { Button } from "../appearance/button/Button";
import { ButtonGroupFullWidth } from "../appearance/button/ButtonGroup";
import { PanelDescription, PanelParagraph } from "../appearance/panels/PanelText";
import { Form } from "../appearance/forms/Form";

const InputContainer = styled.div`
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    padding-bottom: 14px;
    span {
        font-size: 12px;
        line-height: 1.83;
        color: ${OldColors.primaryTint};
        padding-top: 8px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
    }
`;

const inputStyle = `
    width: 100%;
    padding: 11px 14px;
    border: 1px solid ${OldColors.primaryTint}BA;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    background-color: black;
    :focus {
        border-color: ${OldColors.primaryTint};
    }
`;

const SingleLineInput = styled.input`
    ${inputStyle}
    color: rgba(255, 255, 255, ${(props) => (props.disabled ? 0.6 : 1)});
`;

const MultiLineInput = styled.textarea`
    ${inputStyle}
    color: rgba(255, 255, 255, ${(props) => (props.disabled ? 0.6 : 1)});
    resize: vertical;
    height: 136px;
    min-height: 40px;
    min-width: 0;
    :invalid {
        box-shadow: none;
    }
`;

const DeleteButton = styled.img`
    float: right;
    cursor: pointer;
    opacity: 1;
    :hover {
        opacity: 0.8;
    }
`;

type SpeciesItem = { id: number; name: string };

export enum SelectionState {
    None,
    Tracks,
    Location,
    GroundObservation,
}

interface Props {
    onClose: () => void;
}

interface State {
    isLoading: boolean;
    selectionState: SelectionState;
    subtitleText?: string;
    isBirdCountEnabled: boolean;
    currentBirdCount?: number;
    isSpeciesSelectionEnabled: boolean;
    allSpeciesList: SpeciesItem[];
    recentSpeciesList: SpeciesItem[];
    isDeleteConfirmationVisible: boolean;
    currentNotes?: string;
    groundObservationNotes?: string;
    selectedSpeciesId?: number;
}

const UNDEFINED_SPECIES: SelectItem = { value: "", label: t("observation:selectSpecies") };

export class Observation extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: ObservationViewModel = DI.get(TYPES.ObservationViewModel);
    private readonly birdCountInput: React.RefObject<HTMLInputElement>;
    private get currentBirdCount(): number | undefined {
        if (this.birdCountInput.current == null || this.birdCountInput.current.value.length === 0) {
            return undefined;
        }
        const value = +this.birdCountInput.current.value;
        return isNaN(value) ? undefined : value;
    }

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.birdCountInput = React.createRef();
        this.state = {
            isLoading: false,
            selectionState: SelectionState.None,
            subtitleText: undefined,
            isBirdCountEnabled: false,
            isSpeciesSelectionEnabled: false,
            allSpeciesList: [],
            recentSpeciesList: [],
            isDeleteConfirmationVisible: false,
        };
    }

    public componentDidMount(): void {
        this.viewModel.setObservationIsEnabled(true);
        this.setState({ isBirdCountEnabled: this.viewModel.birdCountEnabled });
        this.setState({ isSpeciesSelectionEnabled: this.viewModel.speciesSelectionEnabled });
        this.collectSubscriptions(
            this.subscribeToSelectionState(),
            this.subscribeToSubtitleText(),
            this.subscribeToSpeciesList(),
            this.subscribeToGroundObservationNotes(),
            this.subscribeToPlaybackState(),
        );
    }

    public componentWillUnmount(): void {
        this.viewModel.setObservationIsEnabled(false);
        super.componentWillUnmount();
    }

    public render(): React.ReactNode {
        return (
            <Panel>
                <PanelHeader label={t("observation.observation")} onClose={() => this.props.onClose()} />
                <PanelSection>{this.renderPanelBody()}</PanelSection>
                {this.state.selectionState !== SelectionState.None && (
                    <PanelFooter>
                        <ButtonGroupFullWidth>
                            {!this.state.isDeleteConfirmationVisible && (
                                <Button
                                    disabled={!this.state.isLoading && !this.canSubmit()}
                                    loading={this.state.isLoading}
                                    label={t("general.save")}
                                    onClick={this.submit.bind(this)}
                                />
                            )}
                            <Button
                                label={t("general.clearSelection")}
                                layout="inline"
                                onClick={() => this.viewModel.clearSelection()}
                            />
                        </ButtonGroupFullWidth>
                    </PanelFooter>
                )}
            </Panel>
        );
    }

    // Private functions

    private renderPanelBody(): React.ReactNode {
        switch (this.state.selectionState) {
            case SelectionState.None:
                return this.renderNoSelection();
            case SelectionState.Tracks:
                return this.renderTrackObservationForm();
            case SelectionState.Location:
                return this.renderLocationSelection();
            case SelectionState.GroundObservation:
                return this.state.isDeleteConfirmationVisible
                    ? this.renderDeleteConfirmation()
                    : this.renderGroundObservationForm();
        }
    }

    private renderNoSelection(): React.ReactNode {
        return (
            <PanelParagraph>
                {this.viewModel.hasGroundObservations
                    ? t("observation.descriptionWithGroundObservation")
                    : t("observation.description")}
            </PanelParagraph>
        );
    }

    private renderTrackObservationForm(): React.ReactNode {
        return (
            <div>
                {this.state.isBirdCountEnabled || this.state.isSpeciesSelectionEnabled ? (
                    <PanelSubtitle>{this.state.subtitleText!}</PanelSubtitle>
                ) : (
                    <PanelDescription>{t("observation.descriptionTrackObservationNotesOnly")}</PanelDescription>
                )}
                <InputContainer>
                    {this.state.isBirdCountEnabled && (
                        <div>
                            <span>{t("observation.numberOfBirds")}</span>
                            <SingleLineInput
                                ref={this.birdCountInput}
                                type="number"
                                min={1}
                                onChange={(e) => this.handleBirdCountOnChange(e)}
                                onKeyDown={(e) => e.key.match("[\\.+-]") && e.preventDefault()}
                            />
                        </div>
                    )}
                    {this.state.isSpeciesSelectionEnabled && this.renderSpeciesSelectionInput()}
                    {this.renderNotesInput()}
                </InputContainer>
            </div>
        );
    }

    private handleBirdCountOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (this.currentBirdCount === 0) {
            e.target.value = "";
        }
        this.setState({ currentBirdCount: this.currentBirdCount });
    }

    private renderGroundObservationForm(): React.ReactNode {
        return (
            <>
                <PanelSubtitle color={Colors.secondary.white}>{t("observation.groundObservation")}</PanelSubtitle>
                <DeleteButton src={DeleteIcon} alt={t("general.delete")} onClick={this.onDeleteClicked.bind(this)} />
                <InputContainer>{this.renderNotesInput()}</InputContainer>
            </>
        );
    }

    private renderSpeciesSelectionInput(): React.ReactNode {
        const recentItems = this.viewModel
            .getRecentlyUsedSpeciesIds()
            .map((recentItemId) => this.state.allSpeciesList.find((s) => s.id === recentItemId))
            .filter((s) => s != undefined)
            .map((s) => s!);
        return (
            <div>
                <span>{t("observation.species")}</span>
                <Select
                    groupedItems={[
                        {
                            label: t("general.none"),
                            options: [UNDEFINED_SPECIES],
                        },
                        {
                            label: t("observation.listRecentSpecies"),
                            options: recentItems.map((s) => ({
                                value: `${s.id}`,
                                label: s.name,
                            })),
                        },
                        {
                            label: t("observation.listAllSpecies"),
                            options: [
                                ...this.state.allSpeciesList.map((s) => ({
                                    value: `${s.id}`,
                                    label: s.name,
                                })),
                            ],
                        },
                    ]}
                    onItemSelected={(item) => {
                        if (!item) {
                            this.setState({ selectedSpeciesId: undefined });
                            return;
                        }

                        const parsed = parseInt(item.value || "");
                        const id = parsed != null && !isNaN(parsed) ? parsed : undefined;
                        if (id !== undefined) {
                            this.viewModel.incrementSpeciesListItemUsage(id);
                        }
                        this.setState({ selectedSpeciesId: id });
                    }}
                    placeholder={t("observation.selectSpecies")}
                    isSearchable
                />
            </div>
        );
    }

    private renderNotesInput(): React.ReactNode {
        return (
            <div>
                <span>{t("observation.notes")}</span>
                <MultiLineInput
                    value={
                        this.state.selectionState === SelectionState.GroundObservation
                            ? this.state.currentNotes === undefined
                                ? this.state.groundObservationNotes
                                : this.state.currentNotes
                            : this.state.currentNotes
                    }
                    onChange={(e) => this.setState({ currentNotes: e.target.value })}
                />
            </div>
        );
    }

    private renderLocationSelection(): React.ReactNode {
        return (
            <div>
                <PanelParagraph>{t("observation.descriptionGroundObservation")}</PanelParagraph>
                <InputContainer>{this.renderNotesInput()}</InputContainer>
            </div>
        );
    }

    private renderDeleteConfirmation(): React.ReactNode {
        return (
            <Form>
                <PanelDescription>{t("observation.confirmDeleteGroundObservation")}</PanelDescription>
                <ButtonGroupFullWidth>
                    <Button
                        color="error"
                        disabled={this.state.isLoading}
                        loading={this.state.isLoading}
                        label={t("general.delete")}
                        onClick={this.onDeleteConfirmed.bind(this)}
                    />
                    <Button
                        disabled={this.state.isLoading}
                        label={t("general.cancel")}
                        onClick={() => this.setState({ isDeleteConfirmationVisible: false })}
                    />
                </ButtonGroupFullWidth>
            </Form>
        );
    }

    private canSubmit(): boolean {
        // Form is valid when either of the 3 fields are set
        let formIsEmpty = true;

        if (this.state.isBirdCountEnabled && this.currentBirdCount) {
            formIsEmpty = false;
        }

        // exlicit undefined check here, because 0 is a valid value
        if (this.state.isSpeciesSelectionEnabled && this.state.selectedSpeciesId !== undefined) {
            formIsEmpty = false;
        }

        if (this.state.currentNotes && this.state.currentNotes.length !== 0) {
            formIsEmpty = false;
        }

        return (
            !formIsEmpty &&
            (this.state.selectionState !== SelectionState.GroundObservation ||
                this.state.currentNotes !== this.state.groundObservationNotes)
        );
    }

    private subscribeToSelectionState(): Subscription {
        return this.viewModel.selectionStateObservable.subscribe((selectionState) => {
            this.setState({ selectionState });
            // When the selection state changes, clear the form
            this.setState({ currentNotes: undefined, selectedSpeciesId: undefined, currentBirdCount: undefined });
        });
    }

    private subscribeToSubtitleText(): Subscription {
        return this.viewModel.subtitleTextObservable.subscribe((subtitleText) => this.setState({ subtitleText }));
    }

    private subscribeToSpeciesList(): Subscription {
        return this.viewModel.speciesListObservable.subscribe((allSpeciesList) => this.setState({ allSpeciesList }));
    }

    private subscribeToGroundObservationNotes(): Subscription {
        return this.viewModel.groundObservationNotesObservable.subscribe((groundObservationNotes) => {
            this.setState({ groundObservationNotes });
        });
    }

    private subscribeToPlaybackState(): Subscription {
        return this.viewModel.observationShouldEnd.subscribe((observationShouldEnd) => {
            if (observationShouldEnd) {
                this.viewModel.setObservationIsEnabled(false);
                this.props.onClose();
            }
        });
    }

    private submit(): void {
        this.setState({ isLoading: true });
        this.viewModel
            .sendObservations(this.state.currentNotes!, this.currentBirdCount, this.state.selectedSpeciesId)
            .subscribe({
                complete: () => {
                    this.setState({ isLoading: false });
                    this.viewModel.refreshGroundObservations();
                },
                error: (error) => {
                    showError(error);
                    this.setState({ isLoading: false });
                },
            });
    }

    private onDeleteClicked(): void {
        this.setState({ isDeleteConfirmationVisible: true });
    }

    private onDeleteConfirmed(): void {
        this.setState({ isLoading: true });
        this.viewModel.deleteObservations().subscribe({
            complete: () => {
                this.setState({ isLoading: false, isDeleteConfirmationVisible: false });
                this.viewModel.refreshGroundObservations();
            },
            error: (error) => {
                showError(error);
                this.setState({ isLoading: false });
            },
        });
    }
}
