import React from "react";
import { useTranslation } from "react-i18next";
import { PanelParagraph } from "../../appearance/panels/PanelText";
import { Duration } from "./ReplayRequest";

export const DurationText = ({ hours, minutes }: Duration): JSX.Element => {
    const { t } = useTranslation();

    let durationText = "";
    if (hours > 0) {
        durationText += `${hours} ${t("unit.hour", { count: hours })}`;
    }
    if (minutes > 0) {
        if (hours > 0) {
            durationText += ` and `;
        }
        durationText += `${minutes} ${t("unit.minute", { count: minutes })}`;
    }

    return <PanelParagraph textAlign="center">{durationText}</PanelParagraph>;
};
