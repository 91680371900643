import { MapModuleViewModel } from "../MapModuleViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    GroundObservationMode,
    GroundObservationRepository,
    TrackObservationRepository,
} from "../../../../domain/repositories";
import { GroundObservationState, Location } from "../../../../domain/model";

export class GroundObservationsModuleViewModel extends MapModuleViewModel {
    // Properties

    public get groundObservationMode(): Rx.Observable<GroundObservationMode> {
        return this.groundObservationRepository.mode;
    }

    public get shouldShowGroundObservations(): Rx.Observable<boolean> {
        return this.groundObservationRepository.mode.pipe(
            RxOperators.map((mode) => mode !== GroundObservationMode.None),
        );
    }

    public get groundObservationStates(): Rx.Observable<GroundObservationState[]> {
        return Rx.combineLatest([
            this.groundObservationRepository.groundObservations,
            this.groundObservationRepository.selectedGroundObservationIds,
        ]).pipe(RxOperators.map((value) => value[0].map((o) => ({ ...o, selected: value[1].includes(o.id) }))));
    }

    public get selectedLocations(): Rx.Observable<Location[]> {
        return this.groundObservationRepository.selectedLocations;
    }

    public get shouldSuppressClick(): Rx.Observable<boolean> {
        return this.groundObservationRepository.shouldSuppressClickEvent;
    }

    public constructor(
        private readonly groundObservationRepository: GroundObservationRepository,
        private readonly trackObservationRepository: TrackObservationRepository,
    ) {
        super();
    }

    // Public functions

    public toggleGroundObservation(id: int): void {
        this.groundObservationRepository.toggleGroundObservation(id);
        this.trackObservationRepository.clearSelectedTracks();
    }

    public selectGroundObservationLocation(location: Location): void {
        this.groundObservationRepository.selectLocation(location);
        this.trackObservationRepository.clearSelectedTracks();
    }

    public suppressClickTemporarily(): void {
        this.groundObservationRepository.suppressClickEvent(300);
    }
}
