export abstract class Snapshot {
    public readonly systemTimestamp: long = Date.now();
    public readonly timestamp: long;

    constructor(timestamp?: long) {
        this.timestamp = timestamp || this.systemTimestamp;
    }
}

export function getMaxTimestamp(...timestamps: long[]): long | undefined {
    return timestamps.length > 0 ? Math.max(...timestamps) : undefined;
}
