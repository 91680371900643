import { Location } from ".";
import { Airbase as AirBaseProto } from "./proto/generated/atcinfo3_pb";

export class AirBase {
    // Static functions

    public static fromProto(model: AirBaseProto): AirBase {
        return new AirBase(model.getId(), model.getAirbasename(), Location.fromProto(model.getReferenceposition()));
    }

    public constructor(
        public readonly id: int,
        public readonly name: string | null,
        public readonly referencePosition: Location,
    ) {}
}
