import React from "react";
import styled from "styled-components";
import { OldColors } from "../../../appearance/Colors";
import { BASE_TEXT_STYLE } from "../../../appearance/theme/GlobalStyles";
import { TimeDisplayMode } from "../../../../domain/model/TimeDisplayMode";
import { useViewModel } from "../../../../hooks/useViewModel";
import { TYPES } from "../../../../di/Types";
import { useObservable } from "../../../../hooks";
import { ClockViewModel } from "./ClockViewModel";

const Title = styled.p`
    ${BASE_TEXT_STYLE}
    margin: 0;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text.text};
`;

const SubTitle = styled.span`
    ${BASE_TEXT_STYLE}
    font-size: 18px;
    font-weight: 700;
    color: ${OldColors.textSecondaryLighter};
`;

const TimezoneText = styled(SubTitle)`
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    text-transform: uppercase;
`;

interface Props {
    time: string;
    timezoneOffset: number | null;
}

export const Clock = ({ time, timezoneOffset }: Props): JSX.Element => {
    const viewModel: ClockViewModel = useViewModel(TYPES.ClockViewModel);
    const timeDisplayMode = useObservable<TimeDisplayMode>(viewModel.timeDisplayMode, TimeDisplayMode.UTC);
    const timeText = time.split(" ");
    const className = `${timeText.length > 1 ? "hasDateText" : ""} clock-title`;

    const timezoneText = (): string | null => {
        let text = null;
        if (timeDisplayMode === TimeDisplayMode.UTC) {
            text = "UTC";
        } else if (timezoneOffset !== null) {
            const timezoneOffsetMin = timezoneOffset / 1000 / 60;
            const hours = Math.floor(timezoneOffsetMin / 60);
            const minutes = String(timezoneOffsetMin % 60).padStart(2, "0");
            const sign = hours >= 0 ? "+" : "-";
            text = `UTC ${sign}${Math.abs(hours)}:${minutes}`;
        }
        return text;
    };

    return (
        <Title className={className}>
            <TimeText timeText={timeText} />
            {timezoneText() && <TimezoneText>{timezoneText()}</TimezoneText>}
        </Title>
    );
};

interface TimeTextProps {
    timeText: string[];
}

const TimeText = (props: TimeTextProps): JSX.Element => {
    if (props.timeText.length > 1) {
        return (
            <>
                <SubTitle>{props.timeText[0]}</SubTitle>
                <br />
                {props.timeText[1]}
            </>
        );
    } else {
        return <>{props.timeText[0]}</>;
    }
};
