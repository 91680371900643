import mapboxgl from "mapbox-gl";
import * as MapUtils from "../../../utils/MapUtils";
import { Feature } from "geojson";
import { DeterrenceDevice, DeterrenceDeviceState } from "../../../domain/model";
import { Colors, OldColors } from "../../appearance/Colors";
import { generateActivatedDeterrenceDeviceSymbol, generateDeterrenceDeviceSymbol } from "./DeterrenceDevicesSymbols";
import { InteractiveMapLayer } from "./InteractiveMapLayer";

const DETERRENCE_DEVICES_LAYER_ID = "layer-deterrence-devices";
const DETERRENCE_DEVICES_SOURCE_ID = "source-deterrence-devices";
const DETERRENCE_DEVICE_DISABLED_SYMBOL_ID = "symbol-deterrence-device-disabled";
const DETERRENCE_DEVICE_ERROR_SYMBOL_ID = "symbol-deterrence-device-error";
const DETERRENCE_DEVICE_AVAILABLE_SYMBOL_ID = "symbol-deterrence-device-available";
const DETERRENCE_DEVICE_AVAILABLE_UNSELECTED_SYMBOL_ID = "symbol-deterrence-device-available-unselected";
const DETERRENCE_DEVICE_SELECTED_SYMBOL_ID = "symbol-deterrence-device-selected";
const DETERRENCE_DEVICE_PENDING_SYMBOL_ID = "symbol-deterrence-device-pending";
const DETERRENCE_DEVICE_ACTIVATED_SYMBOL_ID = "symbol-deterrence-device-activated";

export class DeterrenceDevicesLayer extends InteractiveMapLayer {
    // Static functions

    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        onDeterrenceDeviceClicked: ((deviceId: int) => void) | null,
    ): DeterrenceDevicesLayer {
        return new DeterrenceDevicesLayer(map, orderLayer, onDeterrenceDeviceClicked);
    }

    // Properties

    private constructor(
        readonly map: mapboxgl.Map,
        private orderLayer: string,
        private readonly onDeterrenceDeviceClicked: ((deviceId: int) => void) | null,
    ) {
        super(map);
        this.setup();
    }

    // Public functions

    public setDeterrenceDeviceStates(deterrenceDeviceStates: [DeterrenceDevice, DeterrenceDeviceState][]): void {
        const source = this.map.getSource(DETERRENCE_DEVICES_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        source.setData({
            type: "FeatureCollection",
            features: deterrenceDeviceStates.map((deterrenceDeviceState) =>
                this.getFeatureFromDeterrenceDevice(deterrenceDeviceState),
            ),
        });
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(DETERRENCE_DEVICES_LAYER_ID, "visibility", visibility);
    }

    // Private functions

    private setup(): void {
        this.addDeterrenceDevicesLayer();
    }

    private addDeterrenceDevicesLayer(): void {
        this.map.addSource(DETERRENCE_DEVICES_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        this.loadDeterrenceDeviceImage(
            generateDeterrenceDeviceSymbol({ color: OldColors.disabledBackgroundColor }),
            DETERRENCE_DEVICE_DISABLED_SYMBOL_ID,
        );
        this.loadDeterrenceDeviceImage(
            generateDeterrenceDeviceSymbol({ color: Colors.secondary.red }),
            DETERRENCE_DEVICE_ERROR_SYMBOL_ID,
        );
        this.loadDeterrenceDeviceImage(
            generateDeterrenceDeviceSymbol({ color: OldColors.primaryTint50 }),
            DETERRENCE_DEVICE_AVAILABLE_SYMBOL_ID,
        );
        this.loadDeterrenceDeviceImage(
            generateDeterrenceDeviceSymbol({ color: OldColors.primaryTint50 }),
            DETERRENCE_DEVICE_AVAILABLE_UNSELECTED_SYMBOL_ID,
        );
        this.loadDeterrenceDeviceImage(
            generateDeterrenceDeviceSymbol({ color: Colors.secondary.blue }),
            DETERRENCE_DEVICE_SELECTED_SYMBOL_ID,
        );
        this.loadDeterrenceDeviceImage(
            generateDeterrenceDeviceSymbol({ color: Colors.secondary.green }),
            DETERRENCE_DEVICE_PENDING_SYMBOL_ID,
        );
        this.loadDeterrenceDeviceImage(
            generateActivatedDeterrenceDeviceSymbol({ color: Colors.secondary.red }),
            DETERRENCE_DEVICE_ACTIVATED_SYMBOL_ID,
            [[23, 33]],
            [[23, 33]],
            [14, 14, 42, 42],
        );

        this.map.addLayer(
            {
                id: DETERRENCE_DEVICES_LAYER_ID,
                type: "symbol",
                source: DETERRENCE_DEVICES_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                layout: {
                    "icon-image": [
                        "match",
                        ["get", "state"],
                        DeterrenceDeviceState.Error,
                        DETERRENCE_DEVICE_ERROR_SYMBOL_ID,
                        DeterrenceDeviceState.Available,
                        DETERRENCE_DEVICE_AVAILABLE_SYMBOL_ID,
                        DeterrenceDeviceState.Selected,
                        DETERRENCE_DEVICE_SELECTED_SYMBOL_ID,
                        DeterrenceDeviceState.Pending,
                        DETERRENCE_DEVICE_PENDING_SYMBOL_ID,
                        DeterrenceDeviceState.Activated,
                        DETERRENCE_DEVICE_ACTIVATED_SYMBOL_ID,
                        DETERRENCE_DEVICE_DISABLED_SYMBOL_ID,
                    ],
                    "icon-allow-overlap": true,
                    "icon-text-fit": "both",
                    "icon-text-fit-padding": [10, 10, 7, 10],
                    "text-field": ["get", "label"],
                    "text-font": ["Roboto Medium"],
                    "text-size": 14,
                    "text-allow-overlap": true,
                },
                paint: {
                    "text-color": [
                        "match",
                        ["get", "state"],
                        DeterrenceDeviceState.Disabled,
                        OldColors.disabledColor,
                        Colors.text.text,
                    ],
                },
            },
            this.orderLayer,
        );
        this.addLayerEventListener({ type: "click", layer: DETERRENCE_DEVICES_LAYER_ID, listener: this.onClick });
    }

    private getFeatureFromDeterrenceDevice(deterrenceDeviceState: [DeterrenceDevice, DeterrenceDeviceState]): Feature {
        const deterrenceDevice = deterrenceDeviceState[0];
        const state = deterrenceDeviceState[1];
        return {
            id: deterrenceDevice.id,
            type: "Feature",
            properties: {
                state: state,
                label: `${deterrenceDevice.prettyName}`,
            },
            geometry: {
                type: "Point",
                coordinates: [deterrenceDevice.position.longitude, deterrenceDevice.position.latitude],
            },
        };
    }

    private onClick = (
        event: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void => {
        if (this.onDeterrenceDeviceClicked == null || !event.features || event.features.length === 0) {
            return;
        }
        this.onDeterrenceDeviceClicked(event.features[0].id as int);
    };

    private loadDeterrenceDeviceImage(
        svg: string,
        id: string,
        stretchX: [number, number][] = [[10, 20]],
        stretchY: [number, number][] = [[10, 20]],
        content?: [number, number, number, number],
    ): void {
        MapUtils.loadImageFromSVG(svg, (image) =>
            this.map.addImage(id, image, {
                //@ts-ignore
                stretchX,
                stretchY,
                content,
            }),
        );
    }
}
