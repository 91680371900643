import { Location } from ".";
import { LoggerReport as LoggerReportProto } from "./proto/generated/logger3_pb";

export class GroundObservation {
    // Static functions

    public static fromProto(model: LoggerReportProto): GroundObservation {
        const loggerNote = model.getNote();
        return new GroundObservation(
            model.getReportid(),
            Location.fromProto(model.getPosition()),
            loggerNote && loggerNote.getText(),
        );
    }

    // Public functions

    public constructor(public readonly id: long, public readonly location: Location, public readonly notes?: string) {}
}
