import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../infrastructure/AppConfig";
import { ReactComponent as Logo } from "../../res/images/logo.svg";
import { useDI } from "../../hooks/useDI";
import { TYPES } from "../../di/Types";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";

const Container = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: ${({ theme }) => theme.colors.backgrounds.page};
    transition: all 0.5s ease-in-out;
`;

const Branding = styled(Logo)`
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.colors.text.text};
    opacity: 0.3;
`;

const Center = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
    font-size: 50px;
    line-height: 66px;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: uppercase;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.colors.text.text};
`;

interface Props {
    overrideTitle?: string;
}

export const FullscreenBrandedPage = ({
    overrideTitle,
    children,
    ...rest
}: PropsWithChildren<Props>): JSX.Element | null => {
    // Get the value of the HIDE_ROBIN_BRANDING config key
    const showBranding = !getRuntimeConfig<boolean>(APP_CONFIG_KEYS.HIDE_ROBIN_BRANDING);
    const { appName } = useDI<FlavorConfig>(TYPES.FlavorConfig);
    const title = overrideTitle ?? appName;

    return (
        <Container {...rest}>
            {showBranding && <Branding />}
            <Center>
                {title && <Title>{title}</Title>}
                {children}
            </Center>
        </Container>
    );
};
