import * as mapboxgl from "mapbox-gl";
import {
    ADSBFlight,
    EmitterCategory,
    ADSBFlightEstimate,
    ADSBFlightsSnapshotDiff,
    getAllEmitterCategories,
    CLASSIFICATION_MAX_Z_ORDER,
} from "../../../../domain/model";
import { Feature } from "geojson";
import * as MapUtils from "../../../../utils/MapUtils";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { Colors } from "../../../appearance/Colors";
import { BaseTracksLayer, BaseTrackPartLayers, TrackLayerOptions, LineInfo } from "./BaseTracksLayer";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";
import { ADSBUtils } from "../../../../utils/ADSBUtils";

const PREFIX_SYMBOL = "symbol-adsb-flights-";
const PREFIX_SYMBOL_SELECTED = "symbol-selected-";
const LAYER_ALARM = "layer-adsb-flights-alarm";
const LAYER_HEAD = "layer-adsb-flights-head";
const LAYER_HEAD_HOVER = "layer-adsb-flights-head-hover";
const LAYER_TRAJECTORY = "layer-adsb-flights-trajectory";
const SOURCE_FLIGHTS = "source-adsb-flights";
const LAYER_SELECTED_FLIGHT = "layer-adsb-selected-flight";

const SYMBOL_ALARM = "symbol-adsb-flights-alarm";

export const ADSB_AIRCRAFT_ONLY_BEARING_VISUALIZATION: (flight: ADSBFlight) => boolean = (flight) =>
    ADSBUtils.isAircraftADSBFlight(flight);
export const ADSB_ALL_FLIGHTS_BEARING_VISUALIZATION: (flight: ADSBFlight) => boolean = () => true;

export class ADSBFlightsLayer extends BaseTracksLayer<ADSBFlight> {
    // Static functions

    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        distanceFormatter: DistanceFormatter,
        options: TrackLayerOptions<ADSBFlight>,
        onFlightSelected: ((flightId: number | null) => void) | null,
        flavorConfig: FlavorConfig,
    ): ADSBFlightsLayer {
        return new ADSBFlightsLayer(map, orderLayer, distanceFormatter, options, onFlightSelected, flavorConfig);
    }

    // Properties

    private emitterCategories = getAllEmitterCategories();
    private visibleEmitterCategories: EmitterCategory[] = [];
    private applyAltitudeFilterEmitterCategories: EmitterCategory[] = [];

    protected get trackSource(): string {
        return SOURCE_FLIGHTS;
    }

    private constructor(
        map: mapboxgl.Map,
        private readonly orderLayer: string,
        distanceFormatter: DistanceFormatter,
        private readonly options: TrackLayerOptions<ADSBFlight>,
        onFlightSelected: ((flightId: number | null) => void) | null,
        private readonly flavorConfig: FlavorConfig,
    ) {
        super(map, distanceFormatter, onFlightSelected);

        this.setup();
    }

    // Public functions

    public updateTracks(diff: ADSBFlightsSnapshotDiff): void {
        // Step 1: Reorder, cleanup and group flights so that they are ready to be drawn in the next step
        this.snapshotDiff = diff;

        const allFlights = [...diff.snapshotTracksWithEstimates, ...diff.finishedTracks];

        // Step 2: Make features from tracks, assign them to their source and draw them
        this.updateFlightsSource(allFlights, diff.snapshotTimestamp);
    }

    public updateIconAndTrailScale(scale: number): void {
        super.updateIconAndTrailScale(scale);
        const layers = this.getFlightLayers();
        if (layers === null) {
            return;
        }

        // Icon scale: Images
        this.emitterCategories.forEach((ec) => {
            this.loadTrackOverlayIcon(this.map, ec, PREFIX_SYMBOL + this.getTag(ec));
            this.loadSelectedTrackIcon(this.map, ec, PREFIX_SYMBOL_SELECTED + this.getTag(ec));
        });
        this.loadAlarmTrackIcon(this.map, SYMBOL_ALARM);

        // Altitude label scale: SymbolLayout
        if (layers.headLayer && (layers.headLayer.layout as mapboxgl.SymbolLayout)["text-size"]) {
            this.map.setLayoutProperty(
                layers.headLayer.id,
                "text-size",
                (layers.headLayer.layout as mapboxgl.SymbolLayout)["text-size"],
            );
        }

        // Trail scale
        this.map.setPaintProperty(
            layers.trajectoryLayer.id,
            "line-width",
            (layers.trajectoryLayer.paint as mapboxgl.LinePaint)["line-width"],
        );
    }

    public setVisibleEmitterCategories(visibleCategories: EmitterCategory[]): void {
        this.visibleEmitterCategories = visibleCategories;
        this.requestRedraw();
    }

    public setVisibility(visible: boolean): void {
        this.setVisibleEmitterCategories(visible ? this.emitterCategories : []);
    }

    public setFinishedTrackOpacity(opacity: float): void {
        super.setFinishedTrackOpacity(opacity, LAYER_HEAD, LAYER_HEAD_HOVER, LAYER_TRAJECTORY);
    }

    public setClassificationHistoryOnTrajectoryEnabled(enabled: boolean): void {
        super.setClassificationHistoryOnTrajectoryEnabled(enabled, LAYER_TRAJECTORY);
    }

    public setApplyAltitudeFilterEmitterCategories(emitterCategories: EmitterCategory[]): void {
        this.applyAltitudeFilterEmitterCategories = emitterCategories;
        this.requestRedraw();
    }

    protected calculateEndTimeForTrack(flight: ADSBFlight): number | null {
        return ADSBUtils.getFlightEndTime(flight);
    }

    // Private functions

    private updateFlightsSource(flights: ADSBFlight[], timestamp: number): void {
        const source = this.map.getSource(SOURCE_FLIGHTS) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }

        // There are no tracks or tracks have been disabled
        if (!this.shouldShowTracks || flights.length === 0) {
            source.setData({
                type: "FeatureCollection",
                features: [],
            });
            return;
        }

        const finishedFlightIds = this.finishedTracks.map((flight) => flight.id);
        const features = this.getFeaturesFromFlights(flights, finishedFlightIds, timestamp);

        source.setData({
            type: "FeatureCollection",
            features: features,
        });
    }

    private shouldFilterTracksByAltitudeRange(emitterCategory: EmitterCategory): boolean {
        if (
            this.altitudeRangeOfInterest.top == null &&
            (this.altitudeRangeOfInterest.bottom == null || this.altitudeRangeOfInterest.bottom === 0)
        ) {
            return false;
        }
        return this.applyAltitudeFilterEmitterCategories.includes(emitterCategory);
    }

    private filterOutFlightByAltitudeRange(
        emitterCategory: EmitterCategory,
        flight: ADSBFlight,
        snapshotTimestamp: long,
    ): boolean {
        const shouldFilterByRange = this.shouldFilterTracksByAltitudeRange(emitterCategory);
        const range = this.altitudeRangeOfInterest;
        const lastAltitude = this.getLastKnownAltitude(flight.estimates, snapshotTimestamp);
        if (lastAltitude == null) {
            return !this.showTracksWithoutAltitude;
        }
        /*
         * Checking if altitude is below a constant max value has nothing to do with altitude filter settings.
         * For ADS-B tracks we never want to show the tracks that are above `MAX_VISIBLE_ADSB_FLIGHT_ALTITUDE`
         * no matter what altitude range is set to.
         */
        if (lastAltitude > this.flavorConfig.adsbFlight.maxAltitude) {
            return true;
        }
        if (!shouldFilterByRange) {
            return false;
        }
        const shouldFilter =
            (range.top != null && range.top < lastAltitude) || (range.bottom != null && range.bottom > lastAltitude);
        return shouldFilter;
    }

    private getFeaturesFromFlights(flights: ADSBFlight[], finishedFlightIds: int[], timestamp: long): Feature[] {
        return flights
            .map((flight) => this.makeFlightFeatures(flight, finishedFlightIds.includes(flight.id), timestamp))
            .flat();
    }

    private makeFlightFeatures(flight: ADSBFlight, isFinished: boolean, timestamp: long): Feature[] {
        const timestampForEstimates = isFinished ? this.finishedTracksDeathTimes.get(flight.id)! : timestamp;
        const processedEstimates = ADSBUtils.getADSBFlightEstimatesWithinPeriod(
            flight,
            timestampForEstimates,
            this.trailLength,
        );
        if (processedEstimates.length === 0) {
            if (!isFinished) {
                return [];
            }

            processedEstimates.push(ADSBUtils.getLastEstimate(flight));
        }

        // Check if this flight should be visible
        if (!this.visibleEmitterCategories.includes(flight.emitterCategory)) {
            return [];
        }

        // Check if this flight should be filtered out by altitude
        if (this.filterOutFlightByAltitudeRange(flight.emitterCategory, flight, timestamp)) {
            return [];
        }

        const headColor = Colors.secondary.blue;
        const lineInfo: LineInfo = {
            coordinates: processedEstimates.map((e) => [e.location.longitude, e.location.latitude]),
            color: headColor,
            zOrder: CLASSIFICATION_MAX_Z_ORDER,
        };

        const textOffsetY = flight.emitterCategory === EmitterCategory.AIRCRAFT ? 1.75 : 1.5;
        const alarmState = this.trackAlarmState(flight);
        return [
            this.makeHeadFeature(
                flight.id,
                flight.icao,
                flight.velocity,
                flight.flightId,
                processedEstimates[0].location,
                flight.bearing,
                isFinished,
                false,
                false,
                alarmState,
                this.options.shouldVisualizeBearingFor(flight),
                headColor,
                textOffsetY,
                this.getTag(flight.emitterCategory),
                CLASSIFICATION_MAX_Z_ORDER,
            ),
            ...this.makeTrajectoryFeature(flight.id, [lineInfo], isFinished, false, alarmState).features,
        ];
    }

    private setup(): void {
        const map = this.map;
        if (map.getLayer(LAYER_ALARM) != null) {
            map.removeLayer(LAYER_ALARM);
        }
        if (map.getLayer(LAYER_HEAD) != null) {
            map.removeLayer(LAYER_HEAD);
        }
        if (map.getLayer(LAYER_HEAD_HOVER) != null) {
            map.removeLayer(LAYER_HEAD_HOVER);
        }
        if (map.getLayer(LAYER_TRAJECTORY) != null) {
            map.removeLayer(LAYER_TRAJECTORY);
        }
        if (map.getSource(SOURCE_FLIGHTS) != null) {
            map.removeSource(SOURCE_FLIGHTS);
        }
        if (map.getSource(LAYER_SELECTED_FLIGHT) != null) {
            map.removeSource(LAYER_SELECTED_FLIGHT);
        }
        this.addSource(map);
        this.addLayer(map);
        this.finalizeSetup();
    }

    private addLayer(map: mapboxgl.Map): void {
        const layers = this.getFlightLayers();
        if (layers == null) {
            return;
        }
        map.addLayer(layers.headHoverLayer, this.orderLayer);
        map.addLayer(layers.headLayer, layers.headHoverLayer.id);
        map.addLayer(layers.trajectoryLayer, layers.headLayer.id);
        map.addLayer(layers.alarmLayer, layers.headLayer.id);
        map.addLayer(layers.selectedTrackLayer, layers.headLayer.id);

        this.addLayerEventListener({ type: "click", layer: LAYER_HEAD, listener: this.onTrackClicked() });
        this.addLayerEventListener({
            type: "mousemove",
            layer: LAYER_HEAD,
            listener: this.onMouseMove(SOURCE_FLIGHTS),
        });
        this.addLayerEventListener({
            type: "mouseleave",
            layer: LAYER_HEAD,
            listener: this.onMouseLeave(SOURCE_FLIGHTS),
        });
    }

    private addSource(map: mapboxgl.Map): void {
        map.addSource(SOURCE_FLIGHTS, MapUtils.EMPTY_GEOJSON_SOURCE);
    }

    private getFlightLayers(): BaseTrackPartLayers | null {
        return {
            alarmLayer: this.getTrackAlarmLayer(LAYER_ALARM, SOURCE_FLIGHTS, SYMBOL_ALARM),
            headLayer: this.getTrackHeadLayer(LAYER_HEAD, SOURCE_FLIGHTS, PREFIX_SYMBOL, this.labelScale),
            headHoverLayer: this.getTrackHeadHoverLayer(
                LAYER_HEAD_HOVER,
                SOURCE_FLIGHTS,
                PREFIX_SYMBOL,
                this.labelScale,
            ),
            trajectoryLayer: this.getTrajectoryLayer(LAYER_TRAJECTORY, SOURCE_FLIGHTS),
            selectedTrackLayer: this.getSelectedTrackLayer(
                LAYER_SELECTED_FLIGHT,
                SOURCE_FLIGHTS,
                PREFIX_SYMBOL_SELECTED,
                this.labelScale,
            ),
        };
    }

    private onTrackClicked =
        () =>
        (
            event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
            } & mapboxgl.EventData,
        ): void => {
            this.handleClickForTrackInfo(event);
        };

    private getClosestEstimateTo(track: ADSBFlight, timestamp: long): ADSBFlightEstimate {
        return ADSBUtils.getClosestEstimateTo(track, timestamp) || ADSBUtils.getLastEstimate(track);
    }

    protected findTrackById(trackId: number): ADSBFlight | null {
        return [...this.lastSnapshotTracks, ...this.finishedTracks].find((t) => t.id === trackId) || null;
    }

    protected flyToTrackIfFarAway(track: ADSBFlight): void {
        const lastEstimate = this.getClosestEstimateTo(track, this.lastSnapshotTime);
        lastEstimate.location && this.flyToLocationIfNecessary(lastEstimate.location);
    }
}
