import { ServerUserPreferenceKeys, GridsSnapshot } from "../../../../domain/model";
import { ReplayRepository, ServerPreferencesRepository, GridAnalysisRepository } from "../../../../domain/repositories";
import { MapModuleViewModel } from "../MapModuleViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export class HeatmapModuleViewModel extends MapModuleViewModel {
    // Properties

    public get shouldShowGrids(): Rx.Observable<boolean> {
        return Rx.combineLatest([
            this.serverPreferencesRepository.observePreference<boolean>(ServerUserPreferenceKeys.filters.showGrid),
            this.replayRepository.currentPlaybackScene,
        ]).pipe(
            RxOperators.map((value) => value[0] && value[1] == null),
            RxOperators.startWith(false),
        );
    }
    public get gridUpdates(): Rx.Observable<GridsSnapshot> {
        return this.gridAnalysisRepository.gridSnapshot;
    }

    public constructor(
        private readonly serverPreferencesRepository: ServerPreferencesRepository,
        private readonly gridAnalysisRepository: GridAnalysisRepository,
        private readonly replayRepository: ReplayRepository,
    ) {
        super();
    }
}
