import { ServerConfigRepository, SessionRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { ServerConfig, SessionState } from "../../domain/model";

export class LoginViewModel extends BaseViewModel {
    // Properties

    public get isBusy(): Rx.Observable<boolean> {
        return this.isBusySubject.asObservable();
    }

    public get serverConfig(): Rx.Observable<ServerConfig> {
        return this.serverConfigRepository.config;
    }

    public get currentUsername(): Rx.Observable<string | undefined> {
        return this.sessionRepository.session.pipe(
            RxOperators.map((session) => {
                if (session?.state === SessionState.LoggingOutDueToUnauthorisedResponse) {
                    return this.sessionRepository.usernameForReauth;
                }
                return undefined;
            }),
        );
    }

    private isBusySubject = new Rx.BehaviorSubject<boolean>(false);

    // Lifecycle

    public constructor(
        private readonly sessionRepository: SessionRepository,
        private readonly serverConfigRepository: ServerConfigRepository,
    ) {
        super();
    }

    // Public functions

    public login(username: string, password: string): Rx.Observable<void> {
        this.isBusySubject.next(true);
        return this.sessionRepository.loginBasicAuth(username, password).pipe(
            RxOperators.finalize(() => this.isBusySubject.next(false)),
            RxOperators.ignoreElements(),
        );
    }
}
