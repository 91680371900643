import { StatusResponse } from ".";

export class DeterrenceDeviceActivationStatus {
    // Properties

    public get isSuccess(): boolean {
        return this.status.isOk;
    }

    // Public functions

    public constructor(public readonly deterrenceDeviceId: int, public readonly status: StatusResponse) {}
}
