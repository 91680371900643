import * as Rx from "rxjs";
import { BaseRepository } from ".";
import { Shape, PrimitiveShape } from "../model";

export enum EditMode {
    NONE,
    ADD_POLYGON,
    ADD_POLYLINE,
    ADD_CIRCLE,
    SELECT,
}

export function isAddingMode(editMode: EditMode): boolean {
    return editMode !== EditMode.NONE && editMode !== EditMode.SELECT;
}

export type EditModePayload = OverlaySelection[] | Shape | null;

export class EditState {
    public constructor(public readonly mode: EditMode, public readonly payload: EditModePayload = null) {}
}

export interface OverlaySelection {
    overlayName: string;
    shape: Shape;
}

export interface OverlayEditorRepository extends BaseRepository {
    readonly editState: Rx.Observable<EditState>;
    readonly isolatedOverlays: Rx.Observable<string[]>;

    setMode(mode: EditMode, payload?: EditModePayload): void;

    beginShape(): void;
    updateActiveShape(update: PrimitiveShape): void;
    updateMinMaxAltitude(minAltitude?: number, maxAltitude?: number): void;
    discardShape(): void;
    endShape(overlay: string): Rx.Observable<never>;

    isolateOverlays(overlayIds: string[]): void;

    deleteShapes(selection: OverlaySelection[]): Rx.Observable<never>;
}
